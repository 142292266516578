import React from 'react';
import Dict from 'dict-lib';
import {Typeahead} from 'react-bootstrap-typeahead';
import {saveUsages} from './SenseAction';
import {LexemeUtilities} from './../../../page/editor/LexemeHelper';
import {Grid, Col, Row, InputGroup, FormControl, Button, Label} from 'react-bootstrap';
import {getLexemeProperties} from './../../LexemePage';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css'
import './../../../../_css/lexeme.css';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class SenseUsages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            registerUsages: [],
            domainUsages: [],
            frequencyUsages: [],
            chronologicalUsages: []
        };
    }

    componentWillMount() {
        Dict.call('/service/usages/getUsages/' + getLexemeProperties.language_id).then(result => {
            this.setState(result);
        });
    }

    _handleSetUsage(type, item) {
        var clone = JSON.parse(JSON.stringify(this.state.item));

        if (typeof clone[type.toLowerCase() + "_usage"] === 'undefined') {
            clone[type.toLowerCase() + "_usage"] = {};
        }

        if (item.length > 0) {
            clone[type.toLowerCase() + "_usage"]["id"] = item[0].id;
            clone[type.toLowerCase() + "_usage"]["description"] = item[0].label;
        } else
            clone[type.toLowerCase() + "_usage"] = {};
            //delete clone[type.toLowerCase() + "_usage"];

        this.setState({item: clone});
    }

    _saveSetUsages() {
        this.setState({btDisabled: true});
        saveUsages(this.state.item).then(result => {
            this.setState({btSaveState: "success", btDisabled: false}, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });
        });
    }

    render() {

        var selectedReg = LexemeUtilities.getTypeaheadItem(this.state.registerUsages, this.state.item.register_usage);
        var selectedDom = LexemeUtilities.getTypeaheadItem(this.state.domainUsages, this.state.item.domain_usage);
        var selectedFre = LexemeUtilities.getTypeaheadItem(this.state.frequencyUsages, this.state.item.frequency_usage);
        var selectedCro = LexemeUtilities.getTypeaheadItem(this.state.chronologicalUsages, this.state.item.chronological_usage);

        return <Grid className={stylesLexemeSenses['sense-usages-container']} fluid>
            <Row>
                <Col lg={3} md={3}>
                    <span className={stylesLexemeSenses['sense-usages__span-label']}>{Dict.t('REGISTER_USAGES')}</span>
                    <Typeahead
                        clearButton
                        ref={"registerUsageTypeahead"}
                        align={"justify"}
                        onChange={item => this._handleSetUsage("REGISTER", item)}
                        options={this.state.registerUsages}
                        placeholder={Dict.t('REGISTER_USAGES')}
                        selected={selectedReg}
                    /></Col>
                <Col lg={3} md={3}>
                    <span className={stylesLexemeSenses['sense-usages__span-label']}>{Dict.t('DOMAIN_USAGES')}</span>
                    <Typeahead
                        clearButton
                        ref={"domainUsageTypeahead"}
                        align={"justify"}
                        onChange={item => this._handleSetUsage("DOMAIN", item)}
                        options={this.state.domainUsages}
                        placeholder={Dict.t('DOMAIN_USAGES')}
                        selected={selectedDom}
                    />
                </Col>
                <Col lg={3} md={3}>
                    <span className={stylesLexemeSenses['sense-usages__span-label']}>{Dict.t('FREQUENCY_USAGES')}</span>
                    <Typeahead
                        clearButton
                        ref={"frequencyUsageTypeahead"}
                        align={"justify"}
                        onChange={item => this._handleSetUsage("FREQUENCY", item)}
                        options={this.state.frequencyUsages}
                        placeholder={Dict.t('FREQUENCY_USAGES')}
                        selected={selectedFre}
                    />
                </Col>
                <Col lg={3} md={3}>
                    <span className={stylesLexemeSenses['sense-usages__span-label']}>{Dict.t('CHRONOLOGICAL_USAGES')}</span><Typeahead
                    clearButton
                    ref={"cronologicalUsageTypeahead"}
                    align={"justify"}
                    onChange={item => this._handleSetUsage("CHRONOLOGICAL", item)}
                    options={this.state.chronologicalUsages}
                    placeholder={Dict.t('CHRONOLOGICAL_USAGES')}
                    selected={selectedCro}
                />
                </Col>
            </Row>
            <Row className={stylesLexemeSenses['sense-usages__row-bt']}>
                <SCSubmitButton onClick={() => this._saveSetUsages()} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>;
    }


}
