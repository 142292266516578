import React from 'react';
import Dict from 'dict-lib';
import './Treeview.css';
import {Grid, Row, Col, Button} from 'react-bootstrap';
import {LexemeUtilities} from './../../lexeme/page/editor/LexemeHelper';
import {LanguageTreeView, LanguageTypeahead} from './../components';
import {getLexemeProperties, getLexemeTreeView} from './../../lexeme/page/LexemePage';

import stylesLexemeSenses from './../../_css/dictionary/lexemeParts/lexemeSenses.module.css';


export class LanguageTreeViewPlus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: this.props.item.form,
            treeData: [],
            searchId: "", //"3", //"8",
            searchAncestors: "", //"2", //"2^3^4^5^6",
            rootEntryId: getLexemeProperties("root_entry_id"),
            openLevel: -1,
            addList: [],
            btSaveState: "primary",
            call: this.props.call,
            btName: (typeof this.props.btName !== 'undefined' ? this.props.btName : Dict.t('SAVE'))
        };


        this._handleAdd = this._handleAdd.bind(this);
        this._traverse = this._traverse.bind(this);
    }

    componentWillMount() {
        var treeData = JSON.parse(JSON.stringify(getLexemeTreeView()));//getLexemeTreeView();

        if (treeData.length === 0 || this.props.forceCall) {
            Dict.call(this.props.call).then(result => {
                //var treeLoading = result.ltvType === 'LANGUAGE_FILTERS';
                var treeData = [];
                treeData.push(result);
                this.setState({treeData, /*treeBelowLoading: false, treeLoading: false*/}, function () {


                    /*if (treeLoading) {
                        setTimeout(function () {
                                Dict.call('service/retrieveTreeLanguageLoading/' + encodeURIComponent(JSON.stringify(this.state.treeData[0])) + '/').then(result => {
                                    var treeData = this.state.treeData;
                                    this._traverse(treeData, result);
                                    this.setState({treeData, treeLoading: false});
                                });
                            }.bind(this)
                            , 10);
                    }*/
                }.bind(this));

            });
        } else {
            this.setState({treeData, /*treeLoading: false, treeBelowLoading: false*/});
        }
    }

    _traverse(tree, result) {
        tree.map((item) => {
            if ((item.children).length > 0) {
                this._traverse(item.children, result);
            } else if (item.loading === true && (item.children).length === 0) {
                item.children = result[item.id];
            }
            item.loading = false;
        });
    }

    _handleSearchLanguage(newState) {
        this.setState({openLevel: newState});
    }

    _handleAddLanguage(node) {
        var addList = this.state.addList;

        const newItem = {
            form: this.state.form,
            id_lang: node.id,
            language: node.title,
            type: (node.subtitle.length > 0 ? "alternative" : "main")
        };

        const listItems = addList.filter(function (itemList) {
            return itemList.language === newItem.language;
        });

        if (listItems.length > 0) {
            addList.splice(addList.indexOf(listItems[0]), 1);
        } else {
            addList.push(newItem);
        }


        this.setState({addList: addList, openLevel: -1});
    }

    _handleAdd() {
        if (this.state.addList.length > 0) {
            this.props.callbackParent(this.state.rootEntryId, this.state.addList);
            this.setState({btSaveState: "warning"});
        } else {
            LexemeUtilities.showModalMessage("warning", "WARNING_CHOOSE_LANGUAGE", 2000);
        }
    }

    render() {

        return <Grid style={{height: '100%'}} fluid>
            <Row id={"loc-var-add-typehead"}>
                <Col xs={7} md={7}>&nbsp;</Col>
                <Col xs={5} md={5} style={{paddingRight: 0}}>
                    <LanguageTypeahead ref="typehead"
                                       callbackParent={(newState) => this._handleSearchLanguage(newState)}/>
                </Col>
            </Row>
            <Row className={"row-add-plus-tree " + (this.state.treeData.length > 0 ? "add-plus-tree-loaded" : "")}>
                { this.state.treeData.length > 0 ?
                    <LanguageTreeView searchId={this.state.searchId} searchAncestors={this.state.searchAncestors}
                                      rootEntryId={this.state.rootEntryId} openLevel={this.state.openLevel}
                                      type={this.state.treeData[0].ltvType}
                                      treeData={this.state.treeData} form={this.state.form} height={"100%"}
                                      callbackParent={(newState) => this._handleAddLanguage(newState)}/> : "" }
            </Row>
            <Row key={"key-add-plus"}
                 className={'row-add-plus-list ' + (this.state.addList.length > 0 ? 'add-plus-list-selected' : '')}>
                {this.state.addList.length > 0 ? (this.state.addList.map(item => <span
                    key={"key-add-plus-" + item.language}
                    className={"add-plus-item add-plus-" + item.type}>{item.language}</span>)) : "" }
            </Row>
            <Row className="row-add-plus-button">
                <Button bsStyle={this.state.btSaveState} onClick={() => this._handleAdd()}>{this.state.btName}</Button>
            </Row>
        </Grid>
    }

}