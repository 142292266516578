/**
 * Created by irisi on 06/11/2018.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {ButtonGroup, Button, ButtonToolbar, Glyphicon} from 'react-bootstrap';

import stylesLexeme from '../_css/dictionary/searchPage.module.css';
import BUtils from './../utilities/BaseUtilities';


export class SearchResultButtonBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            buttonShowResultGlyph: 'triangle-left'
        };
    }

    _collapseSearchResult() {
        const buttonShowResultGlyph = this.state.buttonShowResultGlyph === 'triangle-left' ? 'triangle-right' : 'triangle-left';

        this.setState({buttonShowResultGlyph}, function () {
            if (this.state.buttonShowResultGlyph === 'triangle-right') {
                document.getElementById("dict-result-container").className = BUtils.joinClasses(stylesLexeme['dict-result-container--hide'], 'col-lg-1');
                //document.getElementById("lexeme-page-container").className = BUtils.joinClasses(stylesLexeme['lexeme-page-container'], stylesLexeme['lexeme-page-container-full']);
                if (document.getElementById("phrase-editor-right-col") != null)
                    document.getElementById("phrase-editor-right-col").className = "col col-md-6 col-xs-6";
            } else {
                document.getElementById("dict-result-container").className = BUtils.joinClasses(stylesLexeme['dict-result-container'], stylesLexeme['dict-result-container--show'], 'col-lg-3');
                //document.getElementById("lexeme-page-container").className = BUtils.joinClasses(stylesLexeme['lexeme-page-container'], stylesLexeme['lexeme-page-container-right'], 'col-lg-8');
                if (document.getElementById("phrase-editor-right-col") != null)
                    document.getElementById("phrase-editor-right-col").className = "col col-md-7 col-xs-7";
            }

            ReactDOM.findDOMNode(this.buttonResultCollapse).blur();
        }.bind(this));
    }

    render() {

        return <ButtonGroup vertical>
            <Button id="bt-resize-page"
                    ref={(button) => {
                        this.buttonResultCollapse = button;
                    }}
                    onClick={() => this._collapseSearchResult()}>
                <Glyphicon id="bt-resize-page__glyphicon" glyph={this.state.buttonShowResultGlyph}/>
            </Button>
        </ButtonGroup>;
    }
}
