import React from 'react';
import Dict from 'dict-lib';
import {Button, Glyphicon} from 'react-bootstrap';
import './SCSimpleSoundPlayer.css';
import {LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";

export class SCSimpleSoundPlayer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            glyph: 'volume-down',
            additionalClass: typeof this.props.additionalClass !== 'undefined' ? " " + this.props.additionalClass : "",
        };

        this._playSound = this._playSound.bind(this);
        this._resetAudio = this._resetAudio.bind(this);
    }

    componentDidMount() {
        this._resetAudio();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({src: nextProps.src}, function() {
            this._resetAudio();
        }.bind(this));
    }

    _resetAudio() {
        if (typeof this.audio !== 'undefined') {
            var aud = this.audio;
            aud.load();
            aud.onended = function () {
                this.setState({glyph: 'volume-down'});
            }.bind(this);
        }
    }

    _playSound() {
        this.setState({glyph: 'volume-up'});
        //this.audio.play();
        LexemeUtilities.playSound(this.audio);
    }

    render() {

        return <div className="media">
            <div key={'key-media-player'} /*id="simple-player-audio"*/ className="media-player">
                { Object.keys(this.props.src).length > 0 ? <audio className='simple-player-audio-tag' ref={(audio) => {
                    this.audio = audio
                }} src={this.props.src/* + '?' + new Date().getMilliseconds()*/}/> : "" }
            </div>
            <div className="media-controls">
                <div className={"media-controls-play" + this.state.additionalClass} onClick={() => this._playSound()}>
                    <Glyphicon glyph={this.state.glyph}/></div>
            </div>
        </div>
    }
}
