import React from 'react';
import ReactDom from 'react-dom';
import './I18nPage.css';
import {SCRadiobutton} from './../../dict-components/radiobutton/SCRadiobutton';
import {
    FormControl,
    FormGroup,
    InputGroup,
    Button,
    ButtonToolbar,
    Grid,
    Row,
    Col,
    Fade,
    Collapse,
    Well,
    Glyphicon
} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import Dict from 'dict-lib';


export class I18nPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            translation: {},
            validationCode: null,
            callDefaultI18n: "admin/i18n/getDefaultI18n/",
            newLangCode: "",
            searchTranslation: "",
            languageItems: {},
            languages: [],
            transComps: {
                newLang: false,
                newLabel: true
            },
            saveTranslationResult: 'primary',
            saveTranslationGlyph: '',
            removeTranslationResult: 'primary',
            removeTranslationGlyph: '',
            i18nItems: [],
            showRefreshButton: false
        });

        this.fillCode = '';
        this.fillLang = {};

        this._collapseOpen = this._collapseOpen.bind(this);
        this._handleNewLangFormChange = this._handleNewLangFormChange.bind(this);
        this._handleNewTranslation = this._handleNewTranslation.bind(this);
        this._addI18nLang = this._addI18nLang.bind(this);
        this._handleSearchTranslation = this._handleSearchTranslation.bind(this);
        this._saveTranslations = this._saveTranslations.bind(this);
        this._removeTranslations = this._removeTranslations.bind(this);
        this._fillCodeField = this._fillCodeField.bind(this);
        this._emptyTranslationFields = this._emptyTranslationFields.bind(this);
        this._handleSearchSelect = this._handleSearchSelect.bind(this);
        this._exportProperties = this._exportProperties.bind(this);
        this._resetCodeTypeahead = this._resetCodeTypeahead.bind(this);
        this._resetLanguages = this._resetLanguages.bind(this);
    }

    componentWillMount() {
        this._resetLanguages();
        this._exportProperties();
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.codeToTranslate).focus();
    }

    _resetLanguages() {
        var callSearch = 'admin/i18n/getCodedLanguages/';
        Dict.call(callSearch).then(result => {
            const trsList = {codeToTranslate: "", langsToTranslate: {}};
            for (var i = 0; i < result.length; i++) {
                trsList["langsToTranslate"][result[i].code] = "";
            }

            this.setState({
                languages: result,
                translation: trsList
            })
        });
    }

    _exportProperties(/*callback*/) {
        var callSearch = 'admin/i18n/exportProperties';
        Dict.call(callSearch).then(result => {
            this.setState({i18nItems: result});
        });
    }

    _handleNewLangFormChange(event) {
        this.setState(
            {newLangCode: event.target.value}
        );
    }

    _handleSearchTranslation(event) {
        this.setState(
            {searchTranslation: event.target.value}
        );
    }

    _handleNewTranslation(event, lang) {
        var newTrans = this.state.translation;
        if (lang == 'codeToTranslate') {
            newTrans['codeToTranslate'] = event.target.value;
        } else {
            newTrans['langsToTranslate'][lang] = event.target.value;
        }

        this.setState({
            translation: newTrans
        });
    }

    /*_handleNewCodeToTranslate(event) {
     this.setState(
     {translation: {codeToTranslate: event.target.value}}
     );
     }*/

    _collapseOpen(trComp, focusOn) {
        var newState = {};
        for (var key in this.state.transComps) {
            if (this.state.transComps.hasOwnProperty(key)) {
                newState[key] = key == trComp && !this.state.transComps[key];
            }
        }

        this.setState({
            transComps: newState
        }, function () {
            setTimeout(function () {
                if (focusOn) {
                    ReactDom.findDOMNode(focusOn).focus();
                }
            }, 500);
        });
    }

    _addI18nLang() {
        var callSearch = 'admin/i18n/addLanguage/' + this.state.newLangCode;
        Dict.call(callSearch).then(result => {
            if (result == 'OK') {
                const langs = this.state.languages;
                langs.push(this.state.newLangCode);
                this.setState(langs);
            }
        });
    }

    _handleSearchSelect(item) {
        if (item.length > 0)
            this.fillCode = item[0].code;
    }

    _emptyTranslationFields() {
        ReactDom.findDOMNode(this.codeToTranslate).value = '';
        for (var key in this.fillLang) {
            ReactDom.findDOMNode(this.fillLang[key]).value = '';
        }

        this.setState({
            translation: {}
        }, function () {
            this._resetLanguages();
        });
    }

    _fillCodeField() {
        var callSearch = 'admin/i18n/getAllTranslationsByCode/' + this.fillCode;
        Dict.call(callSearch).then(result => {

            ReactDom.findDOMNode(this.codeToTranslate).value = this.fillCode;

            for (var key in this.fillLang) {
                ReactDom.findDOMNode(this.fillLang[key]).value = result.langsToTranslate[key];
            }

            this.setState({
                translation: result
            }, function () {
                this.refs.codeTypeahead.getInstance().clear();
            }.bind(this));

        });
    }

    _resetCodeTypeahead() {
        this._exportProperties();
        this.refs.codeTypeahead.getInstance().clear();
        /*this._exportProperties(function () {
         //this.refs.codeTypeahead.getInstance()._handleTextChange(this.fillCode);
         });*/
    }

    _removeTranslations() {
        var tr = JSON.stringify(this.state.translation);
        tr = tr.replace(/\//g, '^slash^').replace(/\./g, '^dot^').replace(/\?/g, '^questionMark^');

        var callSearch = "admin/i18n/removeCode/" + tr;
        Dict.call(callSearch).then(result => {
            var bsStyle = result == 'OK' ? 'success' : 'danger';
            var glyph = result == 'OK' ? 'thumbs-up' : 'thumbs-down';

            this.setState({
                removeTranslationResult: bsStyle,
                removeTranslationGlyph: glyph
            }, this._resetCodeTypeahead());

            setTimeout(function () {
                this.setState({
                    removeTranslationResult: 'primary',
                    removeTranslationGlyph: ''
                });
            }.bind(this), 3000);

        });
    }


    _saveTranslations() {
        if (!this.state.translation.codeToTranslate) {
            this.setState({validationCode: 'error'}, function () {
                setTimeout(function () {
                    this.setState({validationCode: null});
                }.bind(this), 3000);
            });
        } else {
            var tr = JSON.stringify(this.state.translation);
            tr = tr.replace(/\//g, '^slash^').replace(/\./g, '^dot^').replace(/\?/g, '^questionMark^');

            var callSearch = "admin/i18n/translateCode/" + tr;
            Dict.call(callSearch).then(result => {
                var bsStyle = result == 'OK' ? 'success' : 'danger';
                var glyph = result == 'OK' ? 'thumbs-up' : 'thumbs-down';

                this.setState({
                    saveTranslationResult: bsStyle,
                    saveTranslationGlyph: glyph
                }, this._resetCodeTypeahead());

                setTimeout(function () {
                    this.setState({
                        saveTranslationResult: 'primary',
                        saveTranslationGlyph: ''
                    });
                }.bind(this), 3000);

            });
        }
    }

    render() {

        var listAddLabel = this.state.languages.map((resultItem, i) =>
            <FormGroup key={"key-label-" + resultItem.code}>
                <InputGroup>
                    <InputGroup.Addon>
                        {resultItem.code}
                    </InputGroup.Addon>
                    <FormControl key={'form-langs-' + i} type="text"
                                 ref={(input) => {
                                     this.fillLang[resultItem.code] = input;
                                 }}
                                 onChange={(evt) => this._handleNewTranslation(evt, resultItem.code)}
                                 value={this.translation}
                    />
                </InputGroup>
            </FormGroup>);

        listAddLabel.unshift(
            <FormGroup key={"key-label-code"} validationState={this.state.validationCode}>
                <InputGroup >
                    <InputGroup.Addon style={{fontWeight: 'normal'}}>
                        {Dict.t('CODE')}
                    </InputGroup.Addon>
                    <FormControl style={{width: '300px'}}
                                 ref={(input) => {
                                     this.codeToTranslate = input;
                                 }}
                                 type="text"
                                 onChange={(evt) => this._handleNewTranslation(evt, "codeToTranslate")}
                                 value={this.translation}
                    />
                </InputGroup>
            </FormGroup>);

        return <div id="dict-i18n" className="container-fluid">
            <Grid id="i18n-lang-list" fluid>
                <Row className="show-grid">
                    <Col lg={12} key={"key-lang-list-label"}><h2>{Dict.t("INTERFACE_LANGUAGE")}:
                        <strong>{Dict.getGlobalProperty("INTERFACE_LANGUAGE")}</strong></h2></Col>
                </Row>
            </Grid>
            <ButtonToolbar>
                <Button
                    onClick={() => this._collapseOpen('newLabel', this.codeToTranslate)}>{Dict.t('ADD_SEARCH_CODE_I18N')}</Button>
                <Button
                    onClick={() => this._collapseOpen('newLang', this.newLangCode)}>{Dict.t('ADD_NEW_I18N_LANGUAGE')}</Button>
            </ButtonToolbar>
            <Collapse in={this.state.transComps.newLabel}>
                <div id="i18n-add-label">
                    <Well>
                        <FormGroup style={{textAlign: 'right'}}>
                            <Button onClick={this._fillCodeField} style={{verticalAlign: 'top', marginRight: '5px'}}>
                                <Glyphicon style={{paddingRight: '4px'}} glyph="chevron-left"/>{Dict.t('FILL')}
                            </Button>
                            <Typeahead
                                ref="codeTypeahead"
                                clearButton={true}
                                options={this.state.i18nItems}
                                align="justify"
                                maxResults={5}
                                minLength={2}
                                onChange={item => this._handleSearchSelect(item)}
                                placeholder={Dict.t('SEARCH_I18N')}/>
                        </FormGroup>
                        {listAddLabel}
                        <FormGroup style={{textAlign: 'right', marginBottom: '0'}}>
                            <Button bsStyle="primary"
                                    onClick={this._emptyTranslationFields}>{Dict.t('EMPTY')}</Button>
                            <Button bsStyle={this.state.removeTranslationResult}
                                    onClick={this._removeTranslations}><Glyphicon style={{paddingRight: '4px'}}
                                                                                  glyph={this.state.removeTranslationGlyph}/>{Dict.t('REMOVE')}
                            </Button>
                            <Button bsStyle={this.state.saveTranslationResult}
                                    onClick={this._saveTranslations}><Glyphicon style={{paddingRight: '4px'}}
                                                                                glyph={this.state.saveTranslationGlyph}/>{Dict.t('SAVE')}
                            </Button>
                        </FormGroup>
                    </Well>
                </div>
            </Collapse>
            <Collapse in={this.state.transComps.newLang} style={{height: 0}}>
                <div style={{textAlign: 'center'}}>
                    <Well id="well-new-lang-code">
                        <FormControl id="input-new-lang-code" type="text" placeholder={Dict.t('LANG_CODE') + "..."}
                                     key={"18n-new-lang-code"}
                                     ref={(input) => {
                                         this.newLangCode = input;
                                     }}
                                     value={this.state.newLangCode}
                                     onChange={(evt) => this._handleNewLangFormChange(evt)}
                        />
                        <Button onClick={this._addI18nLang}>{Dict.t('ADD')}</Button>
                    </Well>
                </div>
            </Collapse>
        </div>

    }

}