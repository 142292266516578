import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {InputGroup, FormControl, Button, Grid, Row, Label} from 'react-bootstrap';
import './LocVars.css';
import {saveNewLocVarForm} from './LocVarActions';


export class LocVarEditForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ids: this.props.ids,
            form: this.props.item.form,
            newForm: this.props.item.form
        };

        this._handleFormChange = this._handleFormChange.bind(this);
        this._handleKeyUp = this._handleKeyUp.bind(this);
        this._handleKeyUp = this._handleKeyUp.bind(this);
        this._handleSaveForm = this._handleSaveForm.bind(this);
    }

    componentWillMount() {
        var ids = [];

        if(this.props.item.id) {
            ids.push(this.props.item.id);
        } else {
            this.props.item.variants.map((item) => ids.push(item.id));
        }

        var update = this.state;
        update.ids = ids;
        this.setState(update);
    }

    componentDidMount() {
        ReactDOM.findDOMNode(this.locVarForm).focus();
    }

    _handleFormChange(evt) {
        var update = this.state;
        update.newForm = evt.target.value;
        this.setState(update);
    }

    _handleKeyUp(evt) {
    }

    _handleSaveForm() {
        saveNewLocVarForm(this.state.newForm, this.state.ids);
    }

    render() {

        return <Grid fluid>
            <Row>
                <InputGroup id={"loc-var-edit-group"}>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.locVarForm = input;
                                 }}
                                 value={this.state.newForm}
                                 onChange={(evt) => this._handleFormChange(evt)}
                                 onKeyUp={this._handleKeyUp}/>
                    <Button onClick={this._handleSaveForm} >{Dict.t('SAVE')}</Button>
                </InputGroup>
            </Row>
        </Grid>;
    }
}