import React from 'react';
import {Grid} from 'react-bootstrap';
import {SCTree} from './../dict-components/components';
import './../_css/corpora.css';
import BUtils from "../utilities/BaseUtilities";
import stylesSCTree from "../dict-components/tree/SCTree.module.css";
import {TreeItemSearch} from "../dict-components/tree/components/TreeItemSearch";
import {LexemePageInfos} from "../lexeme/page/editor/LexemeHelper";
import {LexemePageContext} from "../lexeme/page/LexemePageContext";
import {TreeContext} from "./TreeContext";
import {TreeDOMHelper} from "../dict-components/tree/components/TreeHelper";


window.addEventListener('resize', function() {
    TreeDOMHelper.resizeTreeHeight();
});

export class CorporaTreeCatalog extends React.Component {

    getContext() {
        return {
            module: 'TREE'
        }
    }

    render() {

        return <TreeContext.Provider value={this.getContext()}>
            <Grid id="dict-corpora">
                <SCTree containerId={"dict-corpora"}/>
                {/*<div id="tree-item-search-page"
                 className={BUtils.joinClasses(stylesSCTree['selected-tree-filter-items-page'])}>
            </div>*/}
            </Grid>
        </TreeContext.Provider>
    }
}