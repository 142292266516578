import React from 'react';
import {deleteTranslations} from './TranslationAction';
import {getLexemeProperties} from '../../LexemePage';
import Dict from 'dict-lib';
import {Button, Grid, Row, Col} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class TranslationDelete extends React.Component {

    constructor(props) {
        super(props);

        this._handleRemove = this._handleRemove.bind(this);
    }

    _handleRemove() {
        deleteTranslations(JSON.stringify(this.props.item), getLexemeProperties('root_entry_id'));
    }

    render() {

        return <Grid fluid>
            <Row>
                <Col lg={7} md={7} className="popup-label">{Dict.t('ASK_DELETE_TRANSLATION')}</Col>
                <Col lg={5} md={5}>
                    <SCSubmitButton onClick={this._handleRemove} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </Grid>;
    }


}
