import React from 'react';
import Dict from 'dict-lib';
import {AdministrationUtilities} from './../../administration/AdministrationHelper';
import {Typeahead} from 'react-bootstrap-typeahead';
import {LexemeUtilities} from './../../lexeme/page/editor/LexemeHelper';
import './typehead.css';


export class LanguageTypeahead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            align: 'justify',
            type: this.props.type,
            items: [],
            value: '',
            callSearch: this.props.callSearch
        };

        this._handleSearch = this._handleSearch.bind(this);
        this._handleSelect = this._handleSelect.bind(this);
    }

    componentDidMount() {

        //AdministrationUtilities.getLangInItem();

        var service = 'getLanguages';
        if (this.props.type === 'TREE_LANGUAGE_HIERARCHY')
            service = 'getTreeLanguageHierarchy';

        var callSearch = typeof this.state.callSearch === 'undefined' ? ('service/' + service) : this.state.callSearch;
        Dict.call(callSearch).then((result) => {
            this.setState(result);
        });
    }

    _handleSearch(query) {
        if (!query) {
            return;
        }
    }

    _handleSelect(item) {
        if (item[0]) {
            this.setState({
                value: item[0].id
            }, function () {
                //console.log(this.state);
            });
            this.props.callbackParent(item[0].id);
        }
    }

    _getSelected() {
        return this.state.value > 0 ? LexemeUtilities.getTypeaheadItemById(this.state.items, this.state.value) : (typeof this.props.selected !== 'undefined' && (this.state.items).length > 0 ? LexemeUtilities.getTypeaheadItemById(this.state.items, this.props.selected.id) : []);
    }

    render() {
        const {align} = this.state;
        var chooseLanguage = "lingua...";

        //var selected = typeof this.props.selected !== 'undefined' && (this.state.items).length > 0 ? LexemeUtilities.getTypeaheadItemById(this.state.items, this.props.selected.id) : [];

        var selected = this._getSelected();

        return <Typeahead
            id={'language-th'}
            align={align}
            onChange={item => this._handleSelect(item)}
            onSearch={this._handleSearch}
            options={this.state.items}
            placeholder={chooseLanguage}
            selected={selected}
            clearButton={true}
        />

    }
}

export default LanguageTypeahead;