import React from 'react';
import Dict from 'dict-lib';
import {SearchPage} from "./../search/SearchPage";
import {CorporaTreeCatalog} from "./../corpora/CorporaTreeCatalog";
import {DictNavbarMenu, ModalEditLanguage} from "../search/DictNavbarMenu";
import {TreeNavbarMenu} from "../corpora/TreeNavbarMenu";
import {DictionarySettings} from "../utilities/DictUtilities";
import {Button} from "react-bootstrap";
import stylesScNavbar from "../dict-components/navbar/ScNavbar.module.css";
import ReactDOM from "react-dom";

export const PropertyTypeItems = {
    RootModules: [{
        code: 'DICTIONARY',
        path: '/dictionary',
        component: function () {
            return SearchPage;
        },
        navbarMenu: <DictNavbarMenu key={'dictionary-module-navbarmenu'} module={{ code : 'DICTIONARY'}}/>,
        requirements: ['LANG_IN', 'LANG_OUT', 'DEFAULT_SEARCH_LANG'],
        shortcutButtons: function () {
            const rootLanguages = DictionarySettings.getRootLanguages();
            return rootLanguages.map((language) => {
                return <Button bsClass={stylesScNavbar['navbar-shortcut-button']} onClick={() => {
                    ReactDOM.render(<ModalEditLanguage operation={'GENERAL_DATA'} editType={'language'}
                                                       language={language}/>, document.getElementById("dict-modal-container"));
                    document.getElementsByTagName("body")[0].click();
                }}>{language.code}</Button>;
            });
        }
    },
        {
            code: 'TOPONOMASTIC',
            path: '/toponyms',
            referenceModule: 'DICTIONARY',
            additionalParams: {}
        },
        {
            code: 'TREE', path: '/tree', component: function () {
                return CorporaTreeCatalog
            },
            requirements: [],
            navbarMenu: <TreeNavbarMenu key={'dictionary-module-navbarmenu'} module={{ code : 'TREE'}}/>,
            shortcutButtons: function () {
                return ''
            }
        }],

    RequirementsRoutes: [{
        code: 'MAIN_MODULE_REQUIRED',
        module: 'ALL',
        type: 'properties',
        tab: 'modules',
        check: function () {
            var mainModule = AdministrationUtilities.getMainModule();
            return typeof mainModule !== 'undefined' && mainModule !== null;
        }
    }],

    SubModules: [{
        code: 'TOPONOMASTIC',
        path: '/toponyms',
        referenceModule: 'DICTIONARY',
        additionalParams: {isToponym: true}
    }]
};


export const AdministrationUtilities = {

    objectRequiredRoutePath: '/objectsRequired',

    openWizard(moduleCode) {
        if (moduleCode === 'DICTIONARY') {
            DictionarySettings.openWizard();
        }
    },

    getSearchLangItem: function () {
        var rootLanguages = Dict.getVarious("root_languages");
        var defSearchLang = Dict.getGlobalProperty('DEFAULT_SEARCH_LANG');
        var searchId = (typeof defSearchLang !== 'undefined' && defSearchLang !== null && defSearchLang.length > 0) ? defSearchLang : this.getLangInId();

        var langItem = rootLanguages.filter(item => {
            return item.id + '' === searchId;
        })[0];

        return typeof langItem === 'undefined' ? rootLanguages[0] : langItem;
    },

    getLangItemById: function (id) {
        var rootLanguages = Dict.getVarious("root_languages");

        return this.getLangItemByIdAndCollection(id, rootLanguages);
    },

    getLangItemByIdAndCollection: function (id, collection) {
        const res =  collection.filter(item => {
            return item.id + "" === id + "";
        })[0];

        return res;
    },

    getLangInItem: function () {
        return this.getLangItemById(this.getLangInId());
    },

    getLangOutItem: function () {
        return this.getLangItemById(this.getLangOutId());
    },

    isTreeModelLanguage: function (id) {
        return this.getLangItemById(id).is_tree;
    },

    getLangInId: function () {
        return Dict.getGlobalProperty("LANG_IN");
    },

    getLangOutId: function () {
        return Dict.getGlobalProperty("LANG_OUT");
    },

    getSecondaryOutLanguages: function (langId) {
            var rootLanguages = Dict.getVarious("root_languages");

            var secondary = rootLanguages.filter(item => {
                return item.is_active && item !== this.getLangInItem() && item !== this.getLangOutItem();
            });

            return secondary;
    },

    getOppositeLanguages: function(langId) {
        if(langId === Dict.getGlobalProperty("LANG_IN") + "") {
            var secondary = this.getSecondaryOutLanguages(langId);
            var langOut = this.getLangOutItem();

            return secondary === null ? [langOut] : [langOut].concat(secondary);
        } else
            return [this.getLangInItem()];
    },

    getLangDescrById: function (id) {
        return Dict.call('/service/getLanguageDescription/' + id);
    },

    checkCoreOptions: function (module) {
        if (module.code === 'DICTIONARY')
            return this.checkDictionaryCoreLanguageOptions();
        else
            return [];
    },

    checkDictionaryCoreLanguageOptions: function () {
        var rootLanguages = Dict.getVarious("root_languages");
        const coreLanguageOptions = ['descriptionLanguages->DESCR_LANGUAGE', 'senses->PHRASE_ORIGINAL_SCRIPT', 'entries->POLY_DEC_GRAM_CAT'];
        var missingCoreOptions = {};
        const languageOptions = DictionarySettings.getActiveLanguageOptions();

        for (var languageOption in languageOptions) {
            const language = rootLanguages.find(item => {
                // noinspection EqualityComparisonWithCoercionJS
                return item.id == languageOption.replace("languageOptions_", "");
            }).label;
            var missingCoreOptionsByLang = [];
            for (const coreLanguageOption of coreLanguageOptions) {
                var value = languageOptions[languageOption][coreLanguageOption];
                if (typeof value === 'undefined' || value.length === 0)
                    missingCoreOptionsByLang.push(Dict.t(coreLanguageOption.split('->')[1]));
            }
            if (missingCoreOptionsByLang.length > 0)
                missingCoreOptions[language] = missingCoreOptionsByLang.join(', ');
        }

        return missingCoreOptions;
    },

    checkIndexingDict: function() {
        var indexingDictActive = Dict.getVarious('indexingDictActive');
        return indexingDictActive;
    },

    getActiveDescriptionLanguages: function(languageId) {
        var rootLanguages = Dict.getVarious("root_languages");
        var activeLangs = [];
        const opts = Dict.getLanguageOptionsById(languageId);

        for (const lang of rootLanguages) {
            if(opts['descriptionLanguages->DESCR_LANGUAGE_ACTIVE_' + lang.id] === 'true') {
                lang.choosen = this.getDescriptionLanguage(languageId).id === lang.id;
                activeLangs.push(lang);
            }
        }

        return activeLangs;
    },

    getDescriptionLanguageByContext(context) {
        return typeof context.descrLanguage === 'undefined' ? context.dictProperties.language_code : context.descrLanguage;
    },

    getDescriptionLanguage(languageId) {
        const opts = Dict.getLanguageOptionsById(languageId);
        return this.getLangItemById(opts['descriptionLanguages->DESCR_LANGUAGE']);
    },

    printMissingCoreOptions: function (missingCoreOptions) {
        var text = Dict.t('MISSING_CORE_OPTIONS') + ":<p>";
        for (var lang in missingCoreOptions) {
            text += lang + ": " + missingCoreOptions[lang] + "<br>";
        }

        return text;
    },

    printSchemaAnomalies: function (schemaAnomalies) {
        var text = Dict.t('SCHEMA_ANOMALIES') + ":<p>";
        schemaAnomalies.forEach((anomaly) => {
            text += anomaly.table + ": " + anomaly.anomaly + "<br>";
        });

        return text;
    },

    printOptimisticStatus: function () {
        var status = Dict.getSharedOptions().USE_OPTIMISTIC_STATUS;
        const mode = typeof status !== 'undefined' && JSON.parse(status) ? "OPTIMISTIC_MODE" : "PESSIMISTIC_MODE";
        return Dict.t(mode);
    },

    encodeOSPath: function (path) {
        return typeof path === 'undefined' ? 'undefined' : path.replace(/\//g, '^slash^').replace(/\./g, '^dot^').replace(/\?/g, '^questionMark^');
    },

    getStoredAreas: function() {
        return Dict.getVarious('stored_areas');
    },

    retrieveStoredArea: function(area) {
        const areas = Dict.getVarious('stored_areas');

        if(areas.length > 0) {
            var areaFound = areas.find((item) => {
                return item.area === area;
            });

            return areaFound ? areaFound.name : null;
        }
    },

    decodeURIComponentSafe(s) {
        if (!s) {
            return s;
        }
        return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
    },

    getMainModule() {
        var mainModuleCode = Dict.getGlobalProperty('MAIN_MODULE');
        return this.getModule(mainModuleCode);
    },

    getModule(moduleCode) {
        return typeof moduleCode !== 'undefined' && moduleCode !== null ? PropertyTypeItems.RootModules.find((module) => {
            return module.code === moduleCode;
        }) : null;
    },

    getModuleByPathName(modulePath) {
        return PropertyTypeItems.RootModules.find((module) => {
            return module.path === modulePath;
        });
    },

    getActiveModuleByPathName(modulePath) {
        return PropertyTypeItems.RootModules.find((module) => {
            return module.path === modulePath && this.isModuleActive(module.code);
        });
    },

    getMainModulePath() {
        var res = this.getMainModule();
        return typeof res === 'undefined' || res === null ? this.objectRequiredRoutePath : res.path;
    },

    /*getMainModuleComponent() {
        var res = this.getMainModule();
        var component = res === null ? null : res.component();
        return component;
    },*/

    getModulePathByCode(moduleCode) {
        return this.getModule(moduleCode).path;
    },

    getActiveModules: function () {
        return PropertyTypeItems.RootModules.filter((module) => {
            return this.isModuleActive(module.code);
        })
    },

    isModuleActive: function (moduleCode) {
        var isActive = Dict.getGlobalProperty(moduleCode);
        return isActive !== null && JSON.parse(isActive);
    },

    getReferenceModuleOrSelf(module) {
        if (typeof module.referenceModule === 'undefined')
            return module;
        else
            return this.getModule(module.referenceModule);
    },

    getModuleNavBarMenu(module) {
        return this.getReferenceModuleOrSelf(module).navbarMenu;
    },

    getModuleComponent(module) {
        return this.getReferenceModuleOrSelf(module).component();
    },

    getModuleRequirements(module) {
        return this.getReferenceModuleOrSelf(module).requirements;
    },

    getSkin(langId) {
        if (typeof langId === 'undefined') {
            langId = this.getSearchLangItem().id;
        }

        var langInId = Dict.getGlobalProperty('LANG_IN');
        var isLangIn = (langId + '') === langInId;

        var skin = Dict.getGlobalProperty('SKIN_' + (isLangIn ? 'IN' : 'OUT'));
        if (typeof skin === 'undefined' || skin == null) {
            skin = 'white';
        }

        return skin;
    },

    getOpaqueSkin(alpha, langId, skin) {
        var skin = typeof skin === 'undefined' ? this.getSkin(langId) : skin;
        var r = parseInt(skin.slice(1, 3), 16),
            g = parseInt(skin.slice(3, 5), 16),
            b = parseInt(skin.slice(5, 7), 16);

        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    },

    resetSkin(langId) {
        var module = AdministrationUtilities.getModuleByPathName(document.location.pathname);

        if ((module.code === 'DICTIONARY' || module.referenceModule === 'DICTIONARY') && document.getElementById('dict-search-button') !== null) {
            var skin = this.getSkin(langId);

            var navButtons = document.getElementById('nav-buttons');
            var navButtonsItems = document.getElementsByClassName('navbar-nav')[0].querySelectorAll('li');

            var opaqueSkin00 = this.getOpaqueSkin(0.0, langId, skin);
            var opaqueSkin02 = this.getOpaqueSkin(0.2, langId, skin);
            var opaqueSkin04 = this.getOpaqueSkin(0.45, langId, skin);
            //var opaqueSkin06 = this.getOpaqueSkin(0.6, langId, skin);
            var opaqueSkin08 = this.getOpaqueSkin(0.8, langId, skin);

            var border = '2px solid ';
            var containerColor = '';
            var navButtonsBg = '';
            var navText = '';

            if (skin === '#ffffff') {
                border += '#c7c7c7';
                navButtonsBg = skin;
                containerColor = skin;

                navButtonsItems.forEach((element) => {
                    element.style.backgroundColor = skin;

                    element.onmouseover = function () {
                        this.style.backgroundColor = '#dcdcdc';
                    }

                    element.onmouseout = function () {
                        this.style.backgroundColor = skin;
                    }
                });

                navText = skin;
            } else {
                border += opaqueSkin04;
                navButtonsBg = opaqueSkin02;
                containerColor = opaqueSkin02;

                navButtonsItems.forEach((element) => {
                    element.style.backgroundColor = opaqueSkin00;

                    element.onmouseover = function () {
                        this.style.backgroundColor = opaqueSkin02;
                    }

                    element.onmouseout = function () {
                        this.style.backgroundColor = opaqueSkin00;
                    }
                });

                navText = opaqueSkin08;
                if (document.getElementById('dict-search-button') !== null) {
                    document.getElementById('dict-search-button').style.borderColor = opaqueSkin04;
                }
            }

            navButtons.style.backgroundColor = navButtonsBg;

            document.getElementById('app-navbar').getElementsByClassName('container')[0].style.backgroundColor = containerColor;
            document.getElementById('app-navbar').getElementsByClassName('navbar-brand')[0].style.color = this.isLightOrDark(skin) === 'light' ? '#777777' : '#ffffff';
            document.getElementById('well-dict-menu').style.border = border;
            document.getElementById('dict-result-container').style.border = border;
            document.getElementById('lexeme-page-container').style.border = border;
            document.getElementById('special-search-menu-settings').style.border = '1px solid ' + opaqueSkin04;
            document.getElementById('nav-text').style.backgroundColor = navText;
            document.getElementById('sc-footer').getElementsByClassName('container')[0].style.backgroundColor = opaqueSkin04;

        }
    },

    isLightOrDark(color) {

        // Variables for red, green, blue values
        var r, g, b, hsp;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {

            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = color[1];
            g = color[2];
            b = color[3];
        } else {

            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'));

            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {

            return 'light';
        } else {

            return 'dark';
        }
    }

}