import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import {Grid, Row} from 'react-bootstrap';
import Dict from 'dict-lib';
import {getLexemeProperties, getRootEntryId, getPage} from '../../LexemePage';
import {LexemeSorter} from './../LexemeSorter';

export class SubSenseSort extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        const Sorter = <LexemeSorter type={"SENSES"} parentItem={this.props.item} context={this.props.context} isPoly={this.props.additionalData.isPolyEdit} polyItem={this.props.additionalData.polyItem} callbackParent={(newState) => {}}
                                     /*callbackParent={(newState) => this._handleSortEnd(newState)}*//>;
        ReactDOM.render(Sorter, document.getElementById('subentry-sense-sorter-container-id'));
    }

    render() {

        return <Grid className={"grid-entry-sort"} fluid>
            <Row id="subentry-sense-sorter-container-id" className={this.props.additionalData.isPolyEdit ? "poly-sorter-container" : ""}/>
        </Grid>;
    }
}
