import React from 'react';
import Dict from 'dict-lib';
import {Button, Grid, Row, Col} from 'react-bootstrap';
import {SCCheckbox} from "../../dict-components/checkbox/SCCheckbox";

import stylesCgrData from './../../_css/dictionary/CgrData.module.css';
import BUtils from './../../utilities/BaseUtilities';
import {EditLanguage} from "./EditLanguage";


export class EditCgrs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: '',
            id: '',
            create: false
        };

        this._newItem = {id: -1, label: ""};

        this._getCgrData = this._getCgrData.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.create === true && prevState.create === false) {
            this._getCgrData(this._newItem, -1);
        } else if (Object.keys(this.props.savedInfos).length > 0) {
            this._selectNewCgrItem(this.props.savedInfos);
        } else if (this.props.reset === true) {
            this.setState({data: '', id: '', create: false});
        }
    }

    _selectNewCgrItem(savedInfos) {
        var item = this.props.items.find((item) => {
            return item.id === savedInfos.id;
        })

        if (typeof item !== 'undefined') {
            var index = savedInfos.orderFactor;
            this._getCgrData(item, index);
        }
    }

    _getCgrData(item, index) {
        var data = {
            dataObject: {
                id: item.id,
                orderFactor: index,
                uniqueDescription: item.label
            },
            readOnlyDataObject: {forQuotation: this.props.forQuotation},
            className: this.props.className,
            specialFields: []
        }
        this.setState({data, id: item.id, create: item.id < 0});
        this.props.callBackChangeCgr(item.id);
    }

    _changeData(changedData) {
        changedData.id = this.state.id;
        changedData.className = this.state.data.className;
        Object.assign(changedData, this.state.data.readOnlyDataObject);

        this.props.callBackChangeData(changedData);
    }

    render() {

        const isCreate = this.state.create;

        return <Grid className={stylesCgrData['column-list-cgrs-grid']} fluid>
            <Col lg={3} md={3} className={stylesCgrData['column-list-cgrs']}>{
                this.props.items.map((item, index) => {
                    return <Row>
                        <Button key={'list-cgrs-' + index} onClick={() => this._getCgrData(item, index)}
                                bsClass={BUtils.joinClasses(stylesCgrData['column-list-cgr-button'], stylesCgrData[(item.id === this.state.id ? 'column-list-cgr-button--selected' : '')])}>{item.label}</Button>
                    </Row>
                })}
            </Col>
            <Col lg={9} md={9} bsClass={stylesCgrData['column-edit-cgr']}>{this.state.data === '' ? '' :
                <div className={stylesCgrData['column-list-cgr-edit-container']}>
                    <Row
                        className={stylesCgrData['column-list-cgr-edit-bar']}>{Dict.t(isCreate ? 'NEW_CGR' : 'EDIT')}</Row>
                    <Row className={stylesCgrData['column-list-cgr-edit-row']}>
                        <EditLanguage key={'key-edit-cgr-' + this.state.id} data={this.state.data}
                                      callBackChangeData={(changedData) => this._changeData(changedData)}/>
                    </Row>
                </div>}
            </Col>
        </Grid>;
    }

}
