/**
 * Created by irisi on 06/06/2017.
 */
import React from 'react';
import {Modal, Grid, Row} from 'react-bootstrap';
import './SCModalMessage.css';
import error from './../../../../public/img/error.png';
import warning from './../../../../public/img/warning.png';

export class SCModalMessage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return <Modal className={"modal-message modal-message-" + this.props.type} show={this.props.show} restoreFocus>
            <Modal.Body><img className="modal-img" src={this.props.type==='error' ? error : warning}/>
                <span className="modal-text">{this.props.msg}</span>
            </Modal.Body>
        </Modal>;
    }
}
