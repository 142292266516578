import React from "react";
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {
    Grid,
    Label,
    Modal,
    Col,
    FormControl,
    InputGroup,
    Button,
    Row,
    ButtonGroup,
    ButtonToolbar
} from "react-bootstrap";
import stylesScNavbar from "../../dict-components/navbar/ScNavbar.module.css";
import {DictionaryHelper, ModalOperations, Notifications} from "../../utilities/DictUtilities";
import SCRadiobutton from "../../dict-components/radiobutton/SCRadiobutton";
import BUtils from '../../utilities/BaseUtilities';
import FontAwesome from "react-fontawesome";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {SCAreaNotes} from "../../dict-components/areaNotes/SCAreaNotes";
import {BackOfficeTree} from "./BackOfficeTree";
import Dropzone from "react-dropzone";
import {LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";
import {SCFileViewer} from "../../dict-components/fileViewer/SCFileViewer";

const allMultimediaMime = "audio/*, audio/mpeg, image/jpeg,image/png,image/gif, video/mp4, video/mpeg, video/quicktime, video/mpg, video/*";

export class BackOfficeSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
            showFiles: false
        };

        this._handleRadioLanguageChange = this._handleRadioLanguageChange.bind(this);
        this._handleItemEditFormChange = this._handleItemEditFormChange.bind(this);
        this._reset = this._reset.bind(this);
        this._checkOperation = this._checkOperation.bind(this);
    }

    componentDidMount() {
        Dict.call('service/search/backOfficeEntitiesByType/' + this.props.type + '/').then((result) => {
            const searchItems = result.items;
            if (searchItems.length > 0) {
                var langId = this.props.languageRequired ? Dict.getVarious('root_languages')[0].id : undefined;
                var searchItemSelected = searchItems[0].code;
                this.setState({searchItems, searchItemSelected, langId}, () => {
                    this._loadProperties();
                    setTimeout(function () {
                        document.getElementById("backoffice-search-form-control").focus();
                    }, 500);
                });
            }

            this._listItems();

        });
    }

    _getBackOfficeEntityInfos() {
        Dict.call('service/search/getBackOfficeEntityInfos/' + this.state.searchItemSelected + '/' + this.state.langId + '/').then((result) => {
            const countItems = result.countItems;
            const isPerformantNode = result.isPerformantNode;
            const multimediaFieldName = result.multimediaFieldName;

            this.setState({countItems, isPerformantNode, multimediaFieldName});
        });
    }

    _listItems() {
        this._handleSearchFormChange('*', false, this.state.searchItemSelected);
    }

    _handleChangeItem(newState) {
        this.setState({countItems: undefined, searchItemSelected: newState, isPerformantNode: false}, () => {
            this._listItems();
            this._loadProperties();
        });
    }

    _handleRadioLanguageChange(newState) {
        this.setState({langId: newState}, () => {
            this._getBackOfficeEntityInfos();
        });
    }

    _loadProperties() {
        this._getBackOfficeEntityInfos();
        this._reset();
    }

    _reset() {
        this.setState({
            resultItems: undefined,
            edit: undefined,
            resultItemSelected: undefined,
            operationResult: {msg: '', style: ''},
            entityCollections: []
        });
    }

    _selectReset() {
        this.setState({
            componentPreview: null
        })
    }

    _resetSearchItems() {
        this._handleSearchFormChange(document.getElementById('backoffice-search-form-control').value, false);
    }

    _addChild(parent) {
        this._retrieveBackOfficeEntityFields(undefined, parent)
    }

    _retrieveSelectedBackOfficeEntityFields() {
        this._retrieveBackOfficeEntityFields(this.state.resultItemSelected);
    }

    _retrieveBackOfficeEntityFields(item, parentToAdd) {
        const isNew = typeof item === 'undefined';
        const hasParent = typeof parentToAdd !== 'undefined';
        const hasMultimedia = typeof this.state.multimediaFieldName !== 'undefined' && this.state.multimediaFieldName !== null;

        if (isNew)
            item = {id: 'new', label: Dict.t('NEW_ITEM')};

        Dict.call('service/search/retrieveBackOfficeEntityFields/' + this.state.searchItemSelected + '/' + item.id + '/').then((result) => {
            if (hasParent) {
                result.entityFields.parent.value = {id: parentToAdd.id, label: parentToAdd.originalLabel};
                item.originalLabel = parentToAdd.originalLabel + ' / ' + Dict.t('NEW_ITEM');
            } else if (typeof result.pathToObject !== 'undefined') {
                item.originalLabel = result.pathToObject;
                item.hasChildren = result.hasChildren;
            } else
                item.originalLabel = item.label;

            var multimediaFiles;
            if (hasMultimedia) {
                multimediaFiles = result.entityFields[this.state.multimediaFieldName].value;
                if (multimediaFiles.length > 0) {
                    multimediaFiles.sort(function (a, b) {
                        var item1 = a['label'].toLowerCase();
                        var item2 = b['label'].toLowerCase();
                        return item1 > item2 ? 1 : -1;
                    });

                    multimediaFiles = BUtils.sortMultimediaFiles(multimediaFiles);
                }
            }

            this.setState({
                resultItemSelected: {
                    id: item.id,
                    label: item.label,
                    originalLabel: item.originalLabel,
                    itemFields: result.entityFields,
                    hasChildren: item.hasChildren,
                    multimediaFiles
                }
            });
        });
    }

    _handleSearchFormChange(text, searchCollection, entity) {
        if (text.trim().length > 0) {
            Dict.call('service/search/searchBackOfficeEntityItems/' + entity + '/' + text + '/'/* + this.state.langId + '/'*/).then((resultItems) => {
                if (searchCollection) {
                    var entityCollections = this.state.entityCollections;
                    entityCollections[entity + 'Items'] = resultItems.items;

                    this.setState({entityCollections});
                } else {
                    this.setState({resultItems: resultItems.items});
                }
            });
        } else {
            setTimeout(() => {
                this._reset();
            }, 500);
        }
    }

    _handleItemEditFormChange(field, value) {
        var resultItemSelected = this.state.resultItemSelected;
        resultItemSelected.itemFields[field].value = value;
        this.setState({resultItemSelected});
    }

    _askRemoveItem() {
        var operationResult = {msg: Dict.t('ASK_REMOVE_ITEM'), style: 'warning'};
        this.setState({operationResult});
    }

    _resetOperation() {
        var operationResult = {msg: '', style: ''};
        this.setState({operationResult});
    }

    _removeItem(assignToParent) {
        Dict.call('service/search/removeBackOfficeEntity/' + this.state.searchItemSelected + '/' + this.state.resultItemSelected.id + '/' + assignToParent + '/').then((result) => {
            this._checkOperation(result, 'remove', () => {
                //this._reset();
                //this._resetSearchItems();
                Dict.call('service/search/countBackOfficeEntityItems/' + this.state.searchItemSelected + '/' + this.state.langId + '/').then((countItems) => {
                    this.setState({countItems});
                    this._listItems();
                })
            });
        }).catch(err => {
            var result = {result: 'error'};
            this._checkOperation(result, 'remove', () => {

            });
        });
    }


    /*_resetAdd(result) {
        this._retrieveBackOfficeEntityFields({id: result.id, label: result.uniqueDescr});
        this._listItems();
    }*/

    _resetStore(result) {
        this._retrieveBackOfficeEntityFields({id: result.id, label: result.uniqueDescr});
        this._listItems();
    }

    _hasMultimedia() {
        return typeof this.state.multimediaFieldName !== 'undefined' && typeof this.state.multimediaFilesToAdd !== 'undefined' && Object.keys(this.state.multimediaFilesToAdd).length > 0;
    }

    _checkSave() {
        if (typeof this.state.multimediaFieldName === 'undefined' || !this._hasMultimedia())
            this._saveItem();
        else {
            var files = this.state.multimediaFilesToAdd;
            var fileValues = [];
            var idx = 0;

            files.forEach((file) => {
                const isExternalLink = typeof externalLink !== 'undefined' && file.externalLink;
                Dict.call('service/search/retrieveBackOfficeEntityMultiMediaData/' + isExternalLink + '/', {
                    method: 'POST',
                    data: file,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }).then((result) => {
                    var filename = LexemeUtilities.encodeUrl(file.name).replace(/\./g, '^dot^');
                    var datFileName = isExternalLink ? '^externalLink^' : LexemeUtilities.encodeUrl(result.filename).replace(/\./g, '^dot^');
                    var entity = this.state.searchItemSelected;
                    var id = this.state.resultItemSelected.id;

                    Dict.call('service/search/storeBackofficeEntityMultimedia/' + entity + '/' + id + '/' + filename + '/' + datFileName + '/').then(result => {
                        fileValues.push({id: 'new', label: result.filename});
                        if (fileValues.length === files.length) {
                            var resultItemSelected = this.state.resultItemSelected;
                            var arrayValue = resultItemSelected.itemFields[this.state.multimediaFieldName].value;
                            var state = this.state;

                            resultItemSelected.itemFields[state.multimediaFieldName].value = arrayValue.concat(fileValues); //resultItemSelected.itemFields[this.state.multimediaFieldName].value.concat(fileValues);

                            var multimediaFilesToAdd = [];
                            this.setState({resultItemSelected, multimediaFilesToAdd}, () => this._saveItem());
                        }
                    });
                }).catch(function (e) {
                    console.log(e); // "oh, no!"
                });
            });
        }
    }

    _saveItem() {
        var storeItem = {};
        Object.keys(this.state.resultItemSelected.itemFields).map((itemField) => {
            storeItem[itemField] = this.state.resultItemSelected.itemFields[itemField].value;
        });

        const operation = this.state.resultItemSelected.id === 'new' ? 'add' : 'modify';

        Dict.call('service/search/storeBackOfficeEntity/' + this.state.searchItemSelected + '/' + this.state.resultItemSelected.id + '/' + LexemeUtilities.encodeUrl(JSON.stringify(storeItem)) + '/').then((result) => {
            this._checkOperation(result, operation, (result) => this._resetStore(result)); /*(operation === 'add' ? this._resetAdd(result) : this._resetModify(result)));*/
        }).catch((error) => {
            this._checkOperation({result: 'erro >>> errore di persistenza'}, operation);
        });
    }

    _checkOperation(result, operation, callback) {
        var duration = 1000;
        var operationResult = {msg: 'ok', style: 'success'};
        var countItems = this.state.countItems;
        if (result.result !== 'OK') {
            duration = 5000;
            operationResult = {msg: result.result, style: 'danger'};
        } else {
            if (operation === 'add')
                countItems = parseInt(countItems) + 1;
            else if (operation === 'remove')
                countItems = parseInt(countItems) - 1;
            if (typeof callback !== 'undefined')
                callback(result);
        }

        var resultItemSelected = this.state.resultItemSelected;
        if (typeof resultItemSelected !== 'undefined')
            resultItemSelected.originalLabel = result.title;

        this.setState({operationResult, countItems, resultItemSelected}, () => {
            setTimeout(() => {
                operationResult = {msg: '', style: ''};

                // ????????????????'
                /*if (typeof this.state.multimediaFieldName !== 'undefined') {
                    this._retrieveSelectedBackOfficeEntityFields();
                }*/
                this.setState({operationResult});
            }, duration);
        });
    }

    _onDrop(files) {
        var multimediaFilesToAdd = this.state.multimediaFilesToAdd;
        if (typeof multimediaFilesToAdd === 'undefined')
            multimediaFilesToAdd = [];

        multimediaFilesToAdd = multimediaFilesToAdd.concat(files);
        const showFiles = true;

        this.setState({multimediaFilesToAdd, showFiles}, function () {
                setTimeout(
                    function () {
                    }.bind(this), 3000)
            }
        )
    }

    render() {
        const resultItemSelected = this.state.resultItemSelected;
        const hasResultItemSelected = typeof resultItemSelected !== 'undefined';
        const hasCountItems = typeof this.state.countItems !== 'undefined';
        const hasOperation = typeof this.state.operationResult !== 'undefined';
        const hasOperationMsg = hasOperation && Object.keys(this.state.operationResult.msg).length > 0;
        const isNew = hasResultItemSelected && this.state.resultItemSelected.id === 'new';
        const isAskRemove = hasOperationMsg && this.state.operationResult.msg === Dict.t('ASK_REMOVE_ITEM');
        const isPerformantNode = typeof this.state.isPerformantNode !== 'undefined' && this.state.isPerformantNode;
        const hasChildren = hasResultItemSelected && resultItemSelected.hasChildren;
        const hasParent = hasResultItemSelected && typeof resultItemSelected.itemFields.parent !== 'undefined' && Object.keys(resultItemSelected.itemFields.parent.value).length > 0;

        const ScRadio = typeof this.state.searchItems !== 'undefined' ?
            <SCRadiobutton key={"key-radio-bksearch-sort-types"} bsStyle="default"
                           items={this.state.searchItems}
                           callbackParent={(newState) => this._handleChangeItem(newState)}/> : '';

        const SearchFormControl = typeof this.state.searchItems !== 'undefined' ?
            <FormControl className={stylesScNavbar['backoffice-search-modal__form-control']} type="text"
                         id={'backoffice-search-form-control'}
                         placeholder={Dict.t('FILTER')}
                         value={this.state.text}
                         onChange={(evt) => this._handleSearchFormChange(evt.target.value, false, this.state.searchItemSelected)}
            /> : '';

        const ScRadioLanguage = this.props.languageRequired && typeof this.state.searchItems !== 'undefined' ? DictionaryHelper.getRootLanguagesSCRadio('dict-lang-bo-search', this._handleRadioLanguageChange) : '';

        const count = hasCountItems ? this.state.countItems + ' items' : '...';
        const selectedId = typeof this.state.resultItemSelected !== 'undefined' ? this.state.resultItemSelected.id : '';

        const resultItems = typeof this.state.resultItems !== 'undefined' ? this.state.resultItems.map((item) => {
            return <div key={'key-bo-result-items-' + item.id}
                        className={BUtils.joinClasses(stylesScNavbar['backoffice-search-modal-col__result__items'], stylesScNavbar['backoffice-search-modal-col__result__items' + (item.id === selectedId ? '--selected' : '')])}
                        onClick={() => this._retrieveBackOfficeEntityFields(item)}>{item.label}</div>
        }) : '';

        const editorTitle = hasResultItemSelected ? <Row
            className={stylesScNavbar['backoffice-search-modal__edit-input-group-title']}>{this.state.resultItemSelected.originalLabel}</Row> : '';
        const editor = hasResultItemSelected ? Object.keys(this.state.resultItemSelected.itemFields).map((itemField) => {
            const field = this.state.resultItemSelected.itemFields[itemField];
            var element = '';
            if (field.type === 'input') {
                element = <InputGroup className={stylesScNavbar['backoffice-search-modal__edit-input-group']}>
                    <InputGroup.Addon className={stylesScNavbar['backoffice-search-modal__edit-input-group-addon']}>
                        {Dict.t(field.label)}
                    </InputGroup.Addon>
                    <FormControl className={stylesScNavbar['backoffice-search-modal__edit-form-control']}
                                 type="text"
                                 placeholder={Dict.t('EMPTY_FIELD')}
                                 value={field.value}
                                 onChange={(evt) => this._handleItemEditFormChange(itemField, evt.target.value)}
                    />
                </InputGroup>
            } else if (field.type === 'parentSelector' || field.type === 'entitySelector') {
                var defaultSelected = [];
                if (Object.keys(field.value).length > 0)
                    defaultSelected.push(field.value);

                var className = field.type === 'parentSelector' ? this.state.searchItemSelected : field.className;
                const isNewChild = field.type === 'parentSelector' && isNew && Object.keys(field.value).length > 0;

                element = <Row key={'key-backoffice-' + field.label + '-of-' + this.state.resultItemSelected.id} p
                               className={stylesScNavbar['backoffice-row-async-typeahead']}>
                    <Col lg={3} md={3}
                         className={stylesScNavbar['backoffice-async-typeahead__label']}>{Dict.t(field.label)}</Col>
                    <Col lg={9} md={9}>
                        {isNewChild ? <div
                                className={BUtils.joinClasses(stylesScNavbar['backoffice-async-typeahead__add-new-child'])}>{field.value.label}</div> :
                            <AsyncTypeahead
                                delay={50}
                                ref={"parentTypeahead"}
                                className={stylesScNavbar['backoffice-async-typeahead']}
                                minLength={0}
                                maxResult={10}
                                clearButton
                                autoFocus={false}
                                align={'right'}
                                onFocus={(evt) => {
                                    if (evt.target.value === '') {
                                        this._handleSearchFormChange("*", true, className);
                                    }
                                }}
                                onInputChange={(evt) => {
                                    if (evt === '') {
                                        this._handleSearchFormChange("*", true, className);
                                    }
                                }}
                                onChange={(items) => {
                                    this._handleItemEditFormChange(itemField, items.length > 0 ? items[0].id : {});
                                }}
                                onSearch={(evt) => {
                                    if (!evt) {
                                        return;
                                    } else {
                                        this._handleSearchFormChange(evt, true, className);
                                    }
                                }
                                }
                                defaultSelected={defaultSelected}
                                options={this.state.entityCollections[className + 'Items']}
                                placeholder={Dict.t('SEARCH') + ' ' + Dict.t(field.label)}
                                useCache={false}
                            />}
                    </Col>
                </Row>;
            } else if (field.type === 'languageMap') {
                element = <Row key={'key-backoffice-' + field.label + '-of-' + this.state.resultItemSelected.id}
                               className={stylesScNavbar['backoffice-row-async-typeahead']}>
                    <Col lg={3} md={3}
                         className={stylesScNavbar['backoffice-async-typeahead__label']}>{Dict.t(field.label)}</Col>
                    <Col lg={9} md={9}>
                        <SCAreaNotes languageAreaNote={field.value}
                                     key={'key-backoffice-' + field.label + '-of-' + this.state.resultItemSelected.id}
                                     name={field.label}
                                     callbackParent={(areNote) => this._handleItemEditFormChange(itemField, areNote)}/>
                    </Col>
                </Row>
            } else if (field.type === 'multimediaCollection') {
                element = <Row key={'key-backoffice-' + field.label + '-of-' + this.state.resultItemSelected.id}
                               className={stylesScNavbar['backoffice-row-dropzone']}>
                    <Col lg={3} md={3}
                         className={stylesScNavbar['backoffice-async-typeahead__label']}>{Dict.t(field.label)}</Col>
                    <Col lg={8} md={8}>
                        <Dropzone
                            accept={allMultimediaMime}
                            /*rejectClassName={"add-sound-reject-drop-zone"}*/
                            acceptClassName={"accept-drop-zone"}
                            rejectClassName={"add-sound-reject-drop-zone"}
                            className={BUtils.joinClasses(stylesScNavbar['backoffice-dropzone'], "area-dropzone")}
                            onDrop={acceptedFiles => this._onDrop(acceptedFiles)}
                            onDragOver={this._onDragOver}
                            onDragLeave={this._onDragLeave}
                            /*onDropRejected={this._onDropRejected}*/>
                            <p style={{padding: '3px 6px'}}>{Dict.t('ADD_MULTIMEDIA')}</p>
                        </Dropzone>
                    </Col>
                    <Col lg={1} md={1}>
                        <Button
                            onClick={() => this.setState({showFiles: !this.state.showFiles})}>{this.state.showFiles ? '>>' : '<<'}</Button>
                    </Col>
                </Row>
            } else if (field.type === 'involvedEntities') {
                console.log();
                element = <Row className={stylesScNavbar['backoffice-row-async-typeahead']}>
                    <Col lg={3} md={3} className={stylesScNavbar['backoffice-entity__label']}>{field.label}</Col>
                    <Col lg={9} md={9}
                         className={stylesScNavbar['backoffice-entity__list']}>{Object.keys(field.value).length > 0 ? field.value.map(item => item.label).join(', ') :
                        <i>{Dict.t('NONE')}</i>}</Col>
                </Row>;
            }

            return element;
        }) : '';

        const ButtonBar = <ButtonToolbar
            className={BUtils.joinClasses(stylesScNavbar['backoffice-search-modal-col__edit-button-bar'])}>
            <Label
                className={stylesScNavbar['backoffice-search-modal-col__edit-button-bar-label--' + hasOperationMsg]}
                bsStyle={hasOperation ? this.state.operationResult.style : 'primary'}>{hasOperationMsg ? this.state.operationResult.msg : ''}</Label>
            {!isNew && isPerformantNode && !isAskRemove ? <Button
                onClick={() => this._addChild(this.state.resultItemSelected)}>{Dict.t('ADD_CHILD')}</Button> : ''}
            {isNew || isAskRemove ? '' :
                <Button onClick={() => this._askRemoveItem()}>{Dict.t('REMOVE')}</Button>}
            {isAskRemove && isPerformantNode && hasChildren ? <>
                <Button
                    onClick={() => this._removeItem(true)}>{Dict.t(hasParent ? 'ASSIGN_CHILDREN_TO_PARENT' : 'CHILDREN_BECOME_ROOTS')}</Button>
                <Button onClick={() => this._removeItem(false)}>{Dict.t('THE_WHOLE_BRANCH')}</Button>
            </> : ''}
            {isAskRemove && (!isPerformantNode || !hasChildren) ?
                <Button onClick={() => this._removeItem(false)}>{Dict.t('YES')}</Button> : ''}
            {isAskRemove ? <Button onClick={() => this._resetOperation()}>{Dict.t('NO')}</Button> : ''}
            {!isAskRemove ? <Button onClick={() => this._checkSave()}>{Dict.t('SAVE')}</Button> : ''}
        </ButtonToolbar>;

        return <Modal show={this.state.show} dialogClassName={stylesScNavbar['backoffice-search-dialog-modal']}
                      enforceFocus={false}
                      restoreFocus onHide={() => this.setState({show: false}, () => {
            ModalOperations._emptyModalContainer()
        })}>
            <Modal.Header className={stylesScNavbar['backoffice-search-modal__header']} closeButton>
                <div className={stylesScNavbar['backoffice-search-modal__header__div']}>
                    <div>
                        <h4 className={'modal-title'}>{Dict.t('SEARCH_IN') + ': '}</h4>
                        <h3 className={'modal-title'}>{Dict.t(this.props.type.toUpperCase() + "_LABEL")}</h3>
                    </div>
                    <div className={stylesScNavbar['backoffice-search-modal__header__div__flex']}>
                        {ScRadioLanguage}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Grid className={stylesScNavbar['backoffice-search-modal-sc-radio-grid']} fluid>
                    <div className={stylesScNavbar['backoffice-search-modal-sc-radio-grid__entities']}>{ScRadio}</div>
                    <div
                        className={stylesScNavbar['backmultimediaFilesoffice-search-modal-sc-radio-grid_field-and-search']}>
                        <div>{SearchFormControl}</div>
                        <div>
                            <Button onClick={() => this._retrieveBackOfficeEntityFields()}>
                                {Dict.t('ADD')}
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid className={stylesScNavbar['backoffice-search-modal-list']} fluid>
                    <div
                        className={BUtils.joinClasses(stylesScNavbar['backoffice-search-modal-col__result'], stylesScNavbar['backoffice-search-modal-col__result--' + this.state.showFiles])}>
                        <div className={[stylesScNavbar['backoffice-search-modal-col__result__count']]}>
                            <span>{count}</span>
                            <Button onClick={() => this._retrieveBackOfficeEntityFields()}
                                    className={BUtils.joinClasses(stylesScNavbar['bt-backoffice-search-add'], stylesScNavbar['bt-backoffice-search-add--' + hasCountItems])}>
                                <FontAwesome name={'plus'}/>
                            </Button>
                        </div>
                        <div>{resultItems}</div>
                    </div>
                    {isPerformantNode ? <div
                        className={BUtils.joinClasses(stylesScNavbar['backoffice-search-modal-col__result'], stylesScNavbar['backoffice-search-modal-col__tree'], stylesScNavbar['backoffice-search-modal-col__tree--' + this.state.showFiles])}>
                        <BackOfficeTree entity={this.state.searchItemSelected}
                                        updateTree={hasOperation && this.state.operationResult.msg === 'ok'}
                                        expandedKey={typeof this.state.resultItemSelected !== 'undefined' ? (this.state.resultItemSelected.id + '') : undefined}
                                        selectable={true}
                                        callbackSelect={(item) => {
                                            this._selectReset();
                                            this._retrieveBackOfficeEntityFields(item);
                                        }}
                        />
                    </div> : ''}
                    <div
                        className={BUtils.joinClasses(stylesScNavbar['backoffice-search-modal-col__edit'], stylesScNavbar['backoffice-search-modal-col__edit--' + hasResultItemSelected])}>
                        <div>{editorTitle}{editor}</div>
                        {ButtonBar}
                    </div>
                    {hasResultItemSelected ? <div
                        className={BUtils.joinClasses(stylesScNavbar['backoffice-search-edit__list-files'], BUtils.joinClasses(stylesScNavbar['backoffice-search-edit__list-files--' + this.state.showFiles]))}>
                        <SCFileViewer
                            item={this.state.resultItemSelected}
                            itemType={this.state.searchItemSelected}
                            filesToAdd={this.state.multimediaFilesToAdd}
                            editable={true}

                            callBackResetFilesToAdd={(multimediaFilesToAdd) => this.setState({multimediaFilesToAdd})}
                            callBackRemoveFile={() => this._retrieveSelectedBackOfficeEntityFields()}
                            callBackSetTitle={() => this._retrieveSelectedBackOfficeEntityFields()}
                            callBackAddLink={(newLink) => this._onDrop({name: newLink, externalLink: true})}

                        /></div> : ''}
                </Grid>
            </Modal.Body>
        </Modal>
    }
}