import React from 'react';
import Dict from 'dict-lib';
import {promoteSenses} from './SenseAction';
import {LanguageTreeViewPlus} from './../../../../dict-components/components';
import './../../../../_css/lexeme.css';


export class SensePromote extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            call: 'service/getAncestorSelectorLanguageTreeViewbySenses/' + encodeURIComponent(JSON.stringify(this.props.item)) + '/'
        };

        this._handlePromote = this._handlePromote.bind(this);
    }

    _handlePromote(rootId, addList) {
        if (addList.length > 0) {
            return promoteSenses(this.props.item, addList);
        }
    }

    render() {

        return <LanguageTreeViewPlus forceCall={true} item={this.props.item} call={this.state.call} btName={Dict.t('PROMOTE')}
                                     callbackParent={(rootId, addList) => this._handlePromote(rootId, addList)}/>;
    }


}
