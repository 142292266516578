import React from 'react';
import {saveTopic} from './SenseAction';
import Dict from 'dict-lib';
import {Typeahead} from 'react-bootstrap-typeahead';
import {Button, Row, Grid} from 'react-bootstrap';
import {LexemeUtilities} from './../../../page/editor/LexemeHelper';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css';
import './../../../../_css/lexeme.css';
import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import ReactDom from "react-dom";
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class SenseTopics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            topics: [],
            topic: typeof this.props.item.topic !== 'undefined' && Object.keys(this.props.item.topic).length > 0 ? {id : this.props.item.topic.id} : {id : ''}
        };

        this._handleSetTopics = this._handleSetTopics.bind(this);
    }

    componentWillMount() {
        Dict.call('service/topics/getTopics/').then(result => {
            this.setState(result);
        });
    }

    componentDidMount() {
        this.refs.refTopics.getInstance().focus();
    }

    _saveTopic() {
        this.setState({btDisabled: true});
        saveTopic(this.state.item, this.state.topic.id).then(result => {
            this.setState({btSaveState: "success", btDisabled: false}, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });
        });
    }

    _handleSetTopics(item) {
        var topic = item.length > 0 ? {id: item[0].id , label : item.label} : { id : '' , label : ''};
        this.setState({topic});
    }


    render() {

        var selectTopic = typeof this.state.topic === 'undefined' || this.state.topic.length === 0 ? "" : LexemeUtilities.getTypeaheadItem(this.state.topics, this.state.topic);

        return <Grid fluid>
            <Row>
                <Typeahead
                    clearButton
                    ref={"refTopics"}
                    minLength={1}
                    align={"justify"}
                    onChange={item => this._handleSetTopics(item)}
                    options={this.state.topics}
                    placeholder={Dict.t('TOPICS')}
                    selected={selectTopic}
                />
            </Row>
            <Row className={stylesLexemeSenses['row-bt-sense-topics']}>
                <SCSubmitButton onClick={() => this._saveTopic()} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>;
    }


}
