import React from "react";
import Dict from 'dict-lib';
import {Grid, Row, Col, Label, Button, Glyphicon} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import {AdministrationPage} from "./AdministrationPage";

export class RequirementsPage extends React.Component {

    componentDidMount() {

    }


    _openAdministrationPage(req) {
        ReactDOM.render(<AdministrationPage show={true}
                                            defaultActiveKey={req.type}
                                            defaultActiveTab={req.tab}/>, document.getElementById("dict-modal-container"));
    }

    _retrieveRequirements(reqs) {

        return typeof reqs === 'undefined' ? [] : reqs.map((req) => {
            return <Button id="bt-unset-core-options" bsStyle="danger"
                           style={{fontWeight: 'bold', paddingRight: '10px', marginLeft: '10px', marginTop: '0'}}
                           onClick={() => this._openAdministrationPage(req)}>
                <Glyphicon id={"unset-core-options-alert-icon"}
                           glyph="alert" data-tip data-for={'alert-unset-core-options'}/>
                <span>{Dict.t(req.code)}</span>
            </Button>
        });
    }


    render() {

        const networkStatus = Dict.getNetworkStatus();
        const networkError = Dict.networkAnomaliesFound();
        const GlobalRequirements = !networkError && this._retrieveRequirements(this.props.globalRequirements);
        const ModuleRequirements = !networkError && this._retrieveRequirements(this.props.moduleRequirements);

        if(networkError)
            console.log(networkStatus.message);

        return <Grid fluid>
            {networkError ? <Row>
                <Col lg={2} md={2}>
                    <h4>
                        <Label>{networkStatus.abstract}</Label>
                    </h4>
                </Col>
            </Row> : ''}
            {GlobalRequirements.length > 0 ? <Row>
                <Col lg={2} md={2}>
                    <h4>
                        <Label>{Dict.t('Missing Global Requirements')}:</Label>
                    </h4>
                </Col>
                <Col>{GlobalRequirements}</Col>
            </Row> : ''}
            {ModuleRequirements.length > 0 ? <Row>
                <Col lg={2} md={2}>
                    <h4>
                        <Label>{'Missing Module Requirements'}:</Label>
                    </h4>
                </Col>
                <Col>{ModuleRequirements}</Col>
            </Row> : ''}
        </Grid>;
    }

}