import React from 'react';
import {getLexemeProperties} from './../../LexemePage';
import {saveDating} from './EntryActions';
import Dict from 'dict-lib';
import {Grid, Button, Row, Col, InputGroup, FormControl} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import './../../editor/root-entry/RootEntry.css';
import {LexemeUtilities} from "../LexemeHelper";
import ReactDOM from "react-dom";
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";

export class EntryDating extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item.lexeme_data.dating
        };

        this.keyOk = true;

        this._handleFirstDating = this._handleFirstDating.bind(this);
        this._handleFirstAttestation = this._handleFirstAttestation.bind(this);
        this._handleBiblio = this._handleBiblio.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this._saveDating = this._saveDating.bind(this);
    }

    componentWillMount() {
        if (this.state.item.dating == 0) {
            var item = JSON.parse(JSON.stringify(this.state.item));
            item.dating = "";
            this.setState({item});
        }
    }

    componentDidMount() {
        ReactDOM.findDOMNode(this.firstDating).focus();
    }

    _handleFirstDating(evt) {
        if (this.keyOk) {
            var item = JSON.parse(JSON.stringify(this.state.item));
            item.first_dating = evt.target.value;
            this.setState({item});
        }
    }

    _handleKeyDown(evt) {
        var charCode = evt.keyCode;
        this.keyOk = evt.keyCode == 8 || (!(charCode > 31 && (charCode < 48 || charCode > 57)) && (this.state.item.first_dating + "").length < 4);
    }

    _handleFirstAttestation(evt) {
        var item = JSON.parse(JSON.stringify(this.state.item));
        item.form_of_first_attestation = evt.target.value;
        this.setState({item});

    }

    _handleBiblio(evt) {
        var item = JSON.parse(JSON.stringify(this.state.item));
        item.bibliographic_reference_of_first_attestation = evt.target.value;
        this.setState({item});

    }

    _saveDating() {
        var item = JSON.parse(JSON.stringify(this.state.item));

        if (typeof item.first_dating == 'undefined' || (item.first_dating + "").length == 0)
            item.first_dating = 0;

        saveDating(JSON.stringify(item));
    }

    _handleKeyUpNext(evt, node) {
        LexemeUtilities.handleKeyUp(evt, () => {
            ReactDOM.findDOMNode(node).focus();
        });
    }

    _handleKeyUp(evt) {
        LexemeUtilities.handleKeyUp(evt, () => {
            this._saveDating();
        });
    }

    render() {

        return <Grid fluid>
            <Row className={'entry-dating'}>
                <Row>
                    <InputGroup className={"entry-first-dating-input"}>
                        <InputGroup.Addon>{Dict.t('FIRST_DATING')}</InputGroup.Addon>
                        <FormControl type="text"
                                     ref={(input) => {
                                         this.firstDating = input;
                                     }}
                                     value={this.state.item.first_dating}
                                     onChange={(evt) => this._handleFirstDating(evt)}
                                     onKeyDown={this._handleKeyDown}
                                     onKeyUp={(evt) => this._handleKeyUpNext(evt, this.entryFirstAttestation)}
                        />
                    </InputGroup>
                </Row>
                <Row>
                    <InputGroup className={"entry-dating-input"}>
                        <InputGroup.Addon>{Dict.t('FORM_OF_FIRST_ATTESTATION')}</InputGroup.Addon>
                        <FormControl type="text"
                                     ref={(input) => {
                                         this.entryFirstAttestation = input;
                                     }}
                                     value={this.state.item.form_of_first_attestation}
                                     onChange={(evt) => this._handleFirstAttestation(evt)}
                                     onKeyUp={(evt) => this._handleKeyUpNext(evt, this.entryBiblio)}
                        />
                    </InputGroup>
                </Row>
                <Row>
                    <InputGroup className={"entry-dating-input"}>
                        <InputGroup.Addon>{Dict.t('BIBLIO_REFERENCE_OF_FIRST_ATTESTATION')}</InputGroup.Addon>
                        <FormControl type="text"
                                     ref={(input) => {
                                         this.entryBiblio = input;
                                     }}
                                     value={this.state.item.bibliographic_reference_of_first_attestation}
                                     onChange={(evt) => this._handleBiblio(evt)}
                                     onKeyUp={(evt) => this._handleKeyUp(evt)}
                        />
                    </InputGroup>
                </Row>
            </Row>
            <Row className="popup-row-bt">
                <SCSubmitButton onClick={this._saveDating} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>;
    }

}
