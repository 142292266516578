import React from 'react';
import Dict from 'dict-lib';
import {saveLink} from './SenseAction';
import {Grid, Row, Button} from 'react-bootstrap';
import {getLexemeProperties} from './../../LexemePage';
import {SubEntryCombo, SCCombo, SCSubmitButton} from './../../../../dict-components/components';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css'
import './../../../../_css/lexeme.css';
import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import ReactDom from "react-dom";


export class SenseLinks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            senseToLinkId: "",
            type: "",
            optionsLinks: [],
            msg: ''
        };

        this._saveLink = this._saveLink.bind(this);
    }

    componentWillMount() {
        Dict.call('/service/options/getActiveLinkBetweenSemanticUnits/' + getLexemeProperties('language_id')).then(result => {
            if (result.length > 0)
                this.setState({optionsLinks: result, type: result[0].value});
            else
                this.setState({msg: Dict.t('NO_LINK_BETWEEN_SEM_UNITS_FOUND')});
        });
    }

    _saveLink() {
        if (this.state.senseToLinkId.length > 0) {
            this.setState({btDisabled: true});
            saveLink(this.state.item, this.state.senseToLinkId, this.state.type).then(result => {
                this.setState({btSaveState: "success", btDisabled: false}, function () {
                    setTimeout(function () {
                        this.setState({btSaveState: "primary"});
                    }.bind(this), 1000);
                });
            });
        }
    }

    render() {

        return <Grid fluid>
            <Row>
                {this.state.optionsLinks.length > 0 ? <SCCombo options={this.state.optionsLinks}
                                                               callbackParent={(newState) => {
                                                                   this.setState({type: newState, forceFocus : true});
                                                               }}/> : this.state.msg}
            </Row>
            <Row>
                <SubEntryCombo languageId={getLexemeProperties("language_id")} forceFocus={this.state.forceFocus}
                               callbackParent={(newState) => this.setState({senseToLinkId: newState})}/>
            </Row>
            <Row className={stylesLexemeSenses['row-bt-sense']}>
                {this.state.optionsLinks.length > 0 ? <SCSubmitButton onClick={() => this._saveLink()} text={Dict.t('SAVE')}/> : ''}
            </Row>
        </Grid>
    }
}