import React from "react";
import Dict from 'dict-lib';
import {Grid, Button, Row, Col, InputGroup, FormControl, Label} from "react-bootstrap";
import {PropertyTypeItems} from "../AdministrationHelper";
import {SCRadiobutton} from './../../dict-components/radiobutton/SCRadiobutton.js';
import stylesUsers from './Users.module.css';
import styleCreateLexeme from "../../_css/dictionary/createLexeme.module.css";
import BUtils from "../../utilities/BaseUtilities";


export class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: '',
            user: {id: '', username: '', password: '', roles: {}},
            listUsers: [],
            showError: false,
            bsStyle: 'default'
        }
    }

    componentDidMount() {
        const urls = [
            '/user/getRoles/',
            '/user/getUsers/'
        ];


        Promise.all(urls.map(url => Dict.call(url))).then(result => {
            var modules = result[0];
            var listUsers = result[1];
            this.setState({listUsers, modules});
        });
    }

    _reset() {
        const user = {id: '', username: '', password: '', roles: {}};
        const mode = '';
        const showError = false;
        const message = ''
        this.setState({user, mode, showError, message});
    }

    _updateCredentials(evt, field) {
        var user = this.state.user;
        user[field] = evt.target.value;
        this.setState({user});
    }

    _updateRoles(newState, module) {
        var user = this.state.user;

        var res = this.state.modules[module].find((moduleItem) => {
            return moduleItem.code === parseInt(newState);
        });

        user.roles[module] = typeof res === 'undefined' ? [] : [res];
        this.setState({user});
    }

    _reloadUserList() {
        return Dict.call('/user/getUsers/').then((listUsers) => {
            this.setState({listUsers});
        });
    }

    _checkSave() {
        var okSave = {result: 'OK', message: ''};
        const username = this.state.user.username;
        const password = this.state.user.password;

        if (typeof this.state.listUsers.find((user) => {
            const selectedUserId = this.state.user.id + '';
            const sameUser = (user.id + '') === selectedUserId;
            return !sameUser && user.label === username;
        }) !== 'undefined') {
            return {result: false, message: 'username presente'};
        } else if (username === '' || username.indexOf(' ') >= 0 || password === '' || password.indexOf(' ') >= 0) {
            return {result: false, message: 'username o password non inseriti o non corretti'};
        } else
            return okSave;
    }

    _save() {
        var checkSave = this._checkSave();

        if (checkSave.result === 'OK') {
            var credentials = JSON.stringify({
                id: this.state.user.id,
                username: this.state.user.username,
                password: this.state.user.password
            });

            var roles = [];

            Object.keys(this.state.user.roles).forEach(name => {
                var stateRoles = this.state.user.roles;
                var id = typeof (stateRoles[name])[0] !== 'undefined' ? (stateRoles[name])[0].code : '-1';
                if (typeof (stateRoles[name])[0] !== 'undefined')
                    roles.push({id: id, name});
            });

            Dict.call('/user/save/' + credentials + '/' + JSON.stringify(roles) + '/').then((result) => {
                if (result.result === 'OK') {
                    this._reloadUserList();
                    var user = this.state.user;
                    user.id = result.id;
                    this.setState({bsStyle: 'success', mode : 'EDIT', user}, () => {
                        setTimeout(function () {
                            this.setState({bsStyle: 'default'});
                        }.bind(this), 2000);
                    })
                }
            });
        } else {
            var showError = true;
            var message = checkSave.message;
            this.setState({showError, message}, () => {
                setTimeout(function () {
                    this.setState({showError: false, message: ''});
                }.bind(this), 2000);
            });
        }
    }

    _delete() {
        Dict.call('/user/delete/' + this.state.user.id + '/').then((result) => {
            if (result === 'OK') {
                this._reloadUserList().then((result) => {
                    this._reset();
                });
            }
        });
    }

    _loadUser(userId) {
        const user = {id: '', username: '', password: '', roles: {}};
        this.setState({user, mode: 'LOADING_USER'});
        Dict.call('/user/loadUser/' + userId + '/').then((user) => {
            const mode = "EDIT";
            this.setState({user, mode});
        });
    }

    _openUserForm(user) {
        var userCrendtial = <>
            <Row>
                <h4>
                    <Label>
                        {Dict.t('CREDENTIALS')}
                    </Label>
                </h4>
                <InputGroup className={stylesUsers["users__input"]}>
                    <InputGroup.Addon>{Dict.t('USER_NAME')}</InputGroup.Addon>
                    <FormControl type="text"
                                 placeholder={Dict.t('USER_NAME') + "..."}
                                 value={this.state.user.username}
                                 onChange={(evt) => {
                                     this._updateCredentials(evt, 'username');
                                 }}
                    />
                </InputGroup>
            </Row>
            <Row>
                <InputGroup className={stylesUsers["users__input"]}>
                    <InputGroup.Addon>{Dict.t('PASSWORD')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('USER_NAME') + "..."}
                                 value={this.state.user.password}
                                 onChange={(evt) => {
                                     this._updateCredentials(evt, 'password');
                                 }}
                    />
                </InputGroup>
            </Row>
        </>;

        var userEditLabel = [<h4>
            <Label>
                {Dict.t('ROLES')}
            </Label>
        </h4>];

        var userEdit = typeof this.state.modules !== 'undefined' ? userEditLabel.concat(Object.keys(this.state.modules).map((module) => {

            const user = this.state.user;
            var items = [{label: Dict.t('NONE'), code: -1}].concat(this.state.modules[module].map((result) => {
                return result;
            }));

            var userRole;
            var settedRole = (user.roles[module]);
            if (user.id === 'new' || typeof settedRole === 'undefined' || settedRole.length === 0) {
                userRole = '-1';
            } else {
                userRole = settedRole[0].code + '';
            }

            return <Row className={stylesUsers['users-module__row']}>
                <div className={stylesUsers['users-module__name']}>{Dict.t(module)}</div>
                <div className={stylesUsers['users-module__roles']}>
                    <SCRadiobutton
                        items={items}
                        label={''}
                        default={userRole}
                        additionalClass={stylesUsers['users-module__roles__radio']}
                        callbackParent={(newState) => {
                            this._updateRoles(newState, module);
                        }}
                    />
                </div>
            </Row>;
        })) : '';

        return {userCrendtial, userEdit};
    }

    render() {
        const mode = this.state.mode;
        const isAddEdit = mode === 'ADD' || mode === 'EDIT';
        const isEdit = mode === 'EDIT';
        const isLoadingUser = mode === 'LOADING_USER';
        const isConfirmDelete = mode === 'CONFIRM_DELETE';

        var userCredential = '';
        var userEdit = '';
        if (isAddEdit || isConfirmDelete) {
            var userData = this._openUserForm();
            userCredential = userData.userCrendtial;
            userEdit = userData.userEdit;
        }

        const listUsers = this.state.listUsers.length > 0 ? this.state.listUsers.map((user) => {
            return <div
                className={BUtils.joinClasses(stylesUsers['users-list__div'], stylesUsers['users-list__div--' + (user.id + '' === this.state.user.id + '')])}
                onClick={() => {
                    this._loadUser(user.id);
                }}>{user.label}</div>;
        }) : '';


        return <Grid className={stylesUsers['users__grid']} fluid>
            <Row className={stylesUsers['users-input__row']}>
                <Col className={BUtils.joinClasses(stylesUsers['users__col'], stylesUsers['users-list__col'])}
                     lg={2} md={2}>
                    <h4><Label>{Dict.t('USER_LIST')}</Label></h4>
                    {listUsers}
                </Col>
                <Col className={BUtils.joinClasses(stylesUsers['users__col'], stylesUsers['users-input__col'])}
                     lg={4} md={4}>{userCredential}</Col>
                <Col className={BUtils.joinClasses(stylesUsers['users__col'], stylesUsers['users-roles__col'])}
                     lg={6} md={6}>{userEdit}</Col>
            </Row>
            <Row className={stylesUsers['users-buttons__row']}>
                <div
                    className={stylesUsers['users-buttons__message--' + this.state.showError]}>{this.state.message}</div>
                {isAddEdit ? <Button onClick={() => {
                    this._reset();
                }}>{Dict.t('RESET')}</Button> : ''}
                {isEdit ? <Button onClick={() => {
                    this.setState({mode: 'CONFIRM_DELETE'});
                }}>{Dict.t('REMOVE')}</Button> : ''}
                {isConfirmDelete ? <>
                    <Button onClick={() => {
                        this._delete();
                    }}>{Dict.t('CONFIRM_DELETE')}</Button>
                    <Button onClick={() => {
                        this.setState({mode: 'EDIT'})
                    }}>{Dict.t('BACK')}</Button>
                </> : ''}
                {!isLoadingUser && !isConfirmDelete ? <Button
                    bsStyle={this.state.bsStyle}
                    onClick={() => {
                        if (isAddEdit) {
                            this._save();
                        } else {
                            var user = this.state.user;
                            user.id = 'new';
                            this.setState({user, mode: 'ADD'})
                        }
                    }}>{Dict.t(isAddEdit ? 'SAVE' : 'ADD')}</Button> : ''}
            </Row>
        </Grid>
    }

}

