import React from 'react';
import {deleteLink} from './../../../editor/senses/SenseAction';
import Dict from 'dict-lib';
import {Button, Row, Col} from 'react-bootstrap';
import './../../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../../dict-components/submitButton/SCSubmitButton";


export class SenseDeleteLink extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this._handleRemove = this._handleRemove.bind(this);
    }

    _handleRemove(ids) {
        this.setState({btDisabled: true});
        deleteLink(ids).then(result => {
            this.setState({btSaveState: "success", btDisabled: false}, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });
        });
    }

    render() {

        return <div className="locVar-delete">
            <Row>
                <Col lg={9} md={9} className="popup-label">{Dict.t('ASK_DELETE_LINK')}</Col>
                <Col lg={3} md={3}>
                    <SCSubmitButton onClick={() => this._handleRemove(this.props.additionalData.ids)} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </div>;
    }


}
