exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".lexemePlace-module__lexeme-place__div--ready___2kIlW {\r\n    opacity: 1;\r\n    padding: 5px;\r\n    margin: 6px 4px;\r\n    background-color: #dcdcdc;\r\n    border-radius: 3px;\r\n    border: 1px solid #909090;\r\n    transition: opacity .25s ease-in-out;\r\n}\r\n\r\n.lexemePlace-module__lexeme-place__div--not-ready___3x2yV {\r\n    opacity: 0;\r\n}\r\n\r\n.lexemePlace-module__pathToObject__row___3x_kL {\r\n    font-weight: bold;\r\n    font-size: 18px;\r\n    margin-top:5px;\r\n    margin-bottom: 3px;\r\n    text-align: center;\r\n}\r\n\r\n.lexemePlace-module__entityFields__row____k1sr {\r\n    margin-bottom: 5px;\r\n    display: table-row;\r\n}\r\n\r\n.lexemePlace-module__entityFields__row____k1sr > div {\r\n    display: table-cell;\r\n    font-size: 15px;\r\n    font-family: Roboto;\r\n}\r\n\r\n.lexemePlace-module__entityFields__col-label___3trFY {\r\n    font-weight: lighter;\r\n    text-align: right;\r\n    padding-right: 2px;\r\n}\r\n\r\n.lexemePlace-module__entityFields__col-descr___eJSeK {\r\n    font-weight: bold;\r\n}\r\n\r\n.lexemePlace-module__place-container___3sCRJ {\r\n    display: flex;\r\n    flex-direction: row;\r\n    height: 85%;\r\n}\r\n\r\n.lexemePlace-module__place-contents__div___12GoI {\r\n    padding: 0 10px;\r\n}\r\n\r\n.lexemePlace-module__place-contents__div-container___1SYZn {\r\n    display: table;\r\n}\r\n\r\n.lexemePlace-module__place-map__div___3D932 {\r\n    margin:3px 9px 3px 3px;\r\n    border: 1px solid #909090;\r\n    border-radius: 3px;\r\n    flex: 1;\r\n    box-shadow: 2px 2px 2px 2px #b7abab;\r\n}", ""]);

// Exports
exports.locals = {
	"lexeme-place__div--ready": "lexemePlace-module__lexeme-place__div--ready___2kIlW",
	"lexeme-place__div--not-ready": "lexemePlace-module__lexeme-place__div--not-ready___3x2yV",
	"pathToObject__row": "lexemePlace-module__pathToObject__row___3x_kL",
	"entityFields__row": "lexemePlace-module__entityFields__row____k1sr",
	"entityFields__col-label": "lexemePlace-module__entityFields__col-label___3trFY",
	"entityFields__col-descr": "lexemePlace-module__entityFields__col-descr___eJSeK",
	"place-container": "lexemePlace-module__place-container___3sCRJ",
	"place-contents__div": "lexemePlace-module__place-contents__div___12GoI",
	"place-contents__div-container": "lexemePlace-module__place-contents__div-container___1SYZn",
	"place-map__div": "lexemePlace-module__place-map__div___3D932"
};