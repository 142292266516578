import React from 'react';
import {deleteSubentry} from './SubentryActions';
import Dict from 'dict-lib';
import {Button, Row, Col} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class SubDelete extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ids: this.props.item.ids
        };

        this._handleRemove = this._handleRemove.bind(this);
    }

    _handleRemove() {
        deleteSubentry(this.state.ids, this.props.additionalData.isPolyEdit);
    }

    render() {

        return <div className="locVar-delete">
            <Row>
                <Col lg={7} md={7} className="popup-label">{Dict.t('ASK_DELETE_SUBENTRY')}</Col>
                <Col lg={5} md={5}>
                    <SCSubmitButton onClick={this._handleRemove} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </div>;
    }


}
