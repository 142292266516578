import React from 'react';
import './SCToastNotification.css';
import info from '../../../public/img/info.png';

export class SCToastNotification extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {

        return <div className="sc-toast-container">
            <div className="sc-toast-icon"><img className="sc-toast-pulse" src={info}/></div>
            <div className="sc-toast-text" dangerouslySetInnerHTML={{__html: this.props.text}}/>
        </div>;
    }
}
