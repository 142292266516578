import React from "react";
import Dict from 'dict-lib';
import {SCCheckbox, SCComponents} from '../../../dict-components/components';
import {Button, FormControl, FormGroup, Glyphicon, Grid, Image, InputGroup, Label} from 'react-bootstrap';
import {AdministrationUtilities, PropertyTypeItems} from "../../AdministrationHelper";
import {DictionarySettings, Notifications} from "../../../utilities/DictUtilities";
import Dropzone from "react-dropzone";
import BUtils from "../../../utilities/BaseUtilities";
import stylesLexemeSenses from "../../../_css/dictionary/lexemeParts/lexemeSenses.module.css";


export class PropertyType extends React.Component {
    constructor(props) {
        super(props);

        this.dropInfoMsg = Dict.t('MSG_DROP_IMG_FILE_HERE');
        //this.dropOverMsg = Dict.t('MSG_ACCEPTED_FILE');
        this.dropLoadedMsg = Dict.t('MSG_LOADED_FILE');
        //this.dropRejectMsg = Dict.t('MSG_REJECTED_FILE');
        //this.layouts = ['header', 'footer'];

        this.state = ({
            group: this.props.tab,
            properties: Object.assign({}, Dict.getGlobalProperties(), {}),
            realProperties: {},
            propertiesComponents: [],
            rootLanguages: [],
            saveProperties: 'primary',
            savePropertiesGlyph: '',
            wizardRequired: false,
            areaFiles: {},
            storedLayoudAreas: [],
            removeLayout: [],
            reloadAfterSave: false
        });

        this._initComponents = this._initComponents.bind(this);
        this._updateProperties = this._updateProperties.bind(this);
        this._handleSecondaryLanguages = this._handleSecondaryLanguages.bind(this);
        this._saveProperties = this._saveProperties.bind(this);
        this._handlePropertyTextField = this._handlePropertyTextField.bind(this);
    }

    componentWillMount() {
        var tab = this.props.tab;
        var func = this.props.isModule ? '_buildModulesProperties(\'' + tab + '\')' : '_build' + tab.charAt(0).toUpperCase() + tab.slice(1) + '()';
        eval('this.' + func);

        if (tab === 'base') {
            this._cleanTmpCustomHtml('all');
        }

        /*if (this.props.tab === 'base') {
            eval('this._buildBase()');
        } else if (this.props.tab === 'modules') {
            this._buildModules();
        } else if (this.props.tab === 'various') {
            this._buildVarious();
        }*/
    }

    _cleanTmpCustomHtml(type) {
        return Dict.call('admin/properties/cleanTmpCustomHtml/' + type + '/').then((storedLayoudAreas) => {
            if (typeof storedLayoudAreas !== 'undefined' && storedLayoudAreas.length > 0) {
                this.setState({storedLayoudAreas});
            }
        });
    }

    _handleSecondaryLanguages(id, value) {
        var upd = this.state.realProperties;
        var updGroup = upd[this.state.group + '->SECONDARY_LANGUAGES'];
        var split = typeof updGroup !== 'undefined' && Object.keys(updGroup).length > 0 ? (updGroup + "").split("_") : [];

        if (value) {
            if (split.indexOf(id) < 0)
                split.push(id + "");
        } else {
            var newSplit = [];
            split.map((listValue) => {
                if (listValue !== id)
                    newSplit.push(listValue);
            });

            split = newSplit.slice(0);
        }

        upd[this.state.group + '->SECONDARY_LANGUAGES'] = split.join("_");

        this.setState({
            realProperties: upd
        });
    }

    _initValue(item) {

        if (item.id === 'CUSTOM_FOOTER') {
            console.log();
        }

        var id = item.id;
        var retVal = this.state.properties[this.props.tab + "->" + id];

        if (retVal === "true")
            retVal = true;
        else if (retVal === "false")
            retVal = false;
        else if (typeof retVal === "undefined") {
            if (item.type === 'select') {
                retVal = item.options[0].value;
            } else if (item.type === 'checkbox') {
                retVal = false;
            } else
                retVal = item.value;
        }

        return retVal;
    }

    _handlePropertyTextField(item, evt) {
        const index = this.state.propertiesComponents.indexOf(item);
        var propertiesComponents = this.state.propertiesComponents;
        propertiesComponents[index].value = evt.target.value;

        this.setState({
            propertiesComponents
        });

        this._updateProperties(propertiesComponents[index].id, evt.target.value, false);
    }

    _handleComponentProperties(item, value) {
        const index = this.state.propertiesComponents.indexOf(item);
        var propertiesComponents = this.state.propertiesComponents;
        propertiesComponents[index].value = value;

        this.setState({
            propertiesComponents
        });

        this._updateProperties(propertiesComponents[index].id, value, false);
    }

    _updateProperties(property, value, isInit) {
        var upd = this.state.realProperties;
        upd[this.state.group + '->' + property] = value;
        const saveProperties = isInit ? "primary" : "warning";
        const savePropertiesGlyph = isInit ? "" : "hand-right";

        this.setState({
            realProperties: upd,
            saveProperties: saveProperties,
            savePropertiesGlyph: savePropertiesGlyph
        }, function () {
            if (false)
                console.log(this.state.realProperties);
        });
    }

    _initComponents(toLoad) {
        var realProperties = {};

        for (var lo in toLoad) {
            var component = toLoad[lo];

            if (component.type !== 'label')
                component.value = this._initValue(component);

            this._updateProperties(component.id, component.value, true);
            realProperties[this.state.group + "->" + component.id] = this._checkValue(component);
        }

        this.setState({
            propertiesComponents: toLoad,
            realProperties
        });
    }

    _initWizard() {
        this.setState({wizardRequired: true});
    }

    _checkValue(component) {
        const stateValue = this.state.properties[this.state.group + "->" + component.id];
        if(typeof stateValue === 'undefined' && component.type === 'checkbox')
            return false;
        else
            return stateValue;
    }

    _saveProperties() {
        var isChangeModule = this.state.group === 'modules';
        const removeLayout = this.state.removeLayout; /*.length > 0 ? this.state.removeLayout : '[]';*/
        Dict.call('admin/properties/updateProperties/' + Dict.encodeUrl(JSON.stringify(this.state.realProperties)) + '/' + this.props.tab + '/' + JSON.stringify(removeLayout) + '/').then(result => {
            var bsStyle = result === 'OK' ? 'success' : 'danger';
            var glyph = result === 'OK' ? 'thumbs-up' : 'thumbs-down';

            if (!isChangeModule) {
                this.setState({
                    saveProperties: bsStyle,
                    savePropertiesGlyph: glyph
                });
            }

            setTimeout(function () {
                if (isChangeModule) {
                    var module = this.state.propertiesComponents.find((prop) => {
                        return prop.id === 'MAIN_MODULE';
                    });
                    var isModuleActive = this.state.propertiesComponents.find((prop) => {
                        return prop.id === module.value;
                    }).value === true;

                    if (isModuleActive) {
                        var modulePath = AdministrationUtilities.getModulePathByCode(module.value);
                        window.location.href = modulePath;
                    } else {
                        bsStyle = 'danger';
                        glyph = 'thumbs-down';
                        this.setState({
                            saveProperties: bsStyle,
                            savePropertiesGlyph: glyph
                        });
                    }
                } else {
                    this.setState({
                        saveProperties: 'primary',
                        savePropertiesGlyph: ''
                    });
                }

                if (this.state.reloadAfterSave)
                    window.location.reload();

            }.bind(this), (isChangeModule ? 1000 : 3000));
        });
    }

    _buildBase() {
        var toLoad = [];

        Dict.call('/admin/i18n/getCodedLanguages/').then(resultIntLangs => {

            var headerTitle = {
                id: 'HEADER_TITLE', type: 'textfield', label: Dict.t('HEADER_TITLE'),
            };

            var title = {
                id: 'TITLE', type: 'textfield', label: Dict.t('TITLE'),
            };

            var footer = {
                id: 'FOOTER', type: 'textfield', label: Dict.t('FOOTER'),
            };

            var interfaceLang = {
                id: 'INTERFACE_LANGUAGE', type: 'radio', label: 'INTERFACE_LANGUAGE',
                items: resultIntLangs
            };

            var customLabel = {
                id: 'CUSTOM_LABEL',
                type: 'label',
                label: 'Trascina immagini, file html o una pagina web zip. Il file zip deve contenere in radice una cartella con il nome dell\'area (footer, header)'
            };

            var custom_header = {
                id: 'CUSTOM_HEADER',
                type: 'layout-drop-zone',
                label: 'CUSTOM_HEADER',
                msg: 'trascina qui il tuo header',
                area: 'header'
            };

            var custom_footer = {
                id: 'CUSTOM_FOOTER',
                type: 'layout-drop-zone',
                label: 'CUSTOM_FOOTER',
                msg: 'trascina qui il tuo footer',
                area: 'footer'
            };

            var serverUrl = {
                id: 'SERVER_URL',
                type: 'textfield',
                label: Dict.t('SERVER_URL')
            };

            toLoad.push(headerTitle);
            toLoad.push(title);
            toLoad.push(footer);
            toLoad.push(interfaceLang);
            toLoad.push(serverUrl);
            toLoad.push(customLabel);
            toLoad.push(custom_header);
            toLoad.push(custom_footer);

            this._initComponents(toLoad);
        });
    }

    _buildModules() {
        var toLoad = [];
        var moduleItems = [];

        PropertyTypeItems.RootModules.forEach((module) => {
            moduleItems.push({code: module.code, label: Dict.t(module.code)});
            toLoad.push({
                id: module.code,
                type: 'checkbox',
                label: Dict.t(module.code)
            })
        });

        var mainModule = {
            id: 'MAIN_MODULE', type: 'radio', value: false, label: 'MAIN_MODULE',
            items: moduleItems
        };

        toLoad.unshift(mainModule);

        this._initComponents(toLoad);
    }

    _buildModulesProperties(moduleCode) {
        var toLoad = [];

        if (moduleCode === 'DICTIONARY') {
            Dict.call('service/getRootLanguages/').then(result => {
                var optionsService = [];
                result.map(item => {
                    optionsService.push({value: item.id + '', text: item.label});
                });

                if (optionsService.length < 2) {
                    this._initWizard();
                } else {

                    var langIn = {
                        id: 'LANG_IN',
                        type: 'select',
                        label: Dict.t('LANG_IN'),
                        options: optionsService
                    };

                    var skinIn = {
                        id: 'SKIN_IN',
                        type: 'textfield-colorpicker',
                        label: Dict.t('DIRECT_SKIN')
                    };

                    var langOut = {
                        id: 'LANG_OUT',
                        type: 'select',
                        label: Dict.t('LANG_OUT'),
                        options: optionsService
                    };

                    var skinOut = {
                        id: 'SKIN_OUT',
                        type: 'textfield-colorpicker',
                        label: Dict.t('INVERSE_SKIN')
                    };

                    var defaultSearchLang = {
                        id: 'DEFAULT_SEARCH_LANG',
                        type: 'select',
                        label: Dict.t('DEFAULT_SEARCH_LANG'),
                        options: optionsService
                    };

                    var legacyPath = {
                        id: 'LEGACY_PATH',
                        type: 'textfield',
                        label: Dict.t('LEGACY_PATH')
                    };

                    var secondaryLabel = {
                        id: 'SELECT_SECONDARY_LANGUAGE',
                        type: 'label',
                        label: Dict.t('SELECT_SECONDARY_LANGUAGE')
                    };

                    var formSearchLabel = {
                        id: 'FORMS_SEARCH',
                        type: 'label',
                        label: Dict.t('FORMS_SEARCH')
                    };


                    toLoad.push(langIn);
                    toLoad.push(skinIn);
                    toLoad.push(langOut);
                    toLoad.push(skinOut);
                    toLoad.push(defaultSearchLang);
                    toLoad.push(legacyPath);
                    //toLoad.push(toponomastic);
                    toLoad.push(secondaryLabel);

                    result.map(language => {
                        if ((language.id + "") !== this.state.properties['DICTIONARY_MODULE->LANG_IN'] && (language.id + "") !== this.state.properties['DICTIONARY_MODULE->LANG_OUT']) {

                            var item = {
                                id: "SECONDARY_LANGUAGE_ACTIVE-" + language.id,
                                label: language.label,
                                type: 'checkbox'
                            };


                            toLoad.push(item);
                        }
                    });

                    toLoad.push(formSearchLabel);

                    result.map(language => {
                        if ((language.id + "") === this.state.properties['DICTIONARY_MODULE->LANG_IN'] || (language.id + "") === this.state.properties['DICTIONARY_MODULE->LANG_OUT']) {
                            var itemForms = {
                                id: "FORMS_SEARCH_ACTIVE-" + language.id,
                                label: language.label,
                                type: 'checkbox'
                            };

                            toLoad.push(itemForms);
                        }
                    });

                    this._initComponents(toLoad);
                    this.setState({rootLanguages: result});
                }
            });
        } else if (moduleCode === 'TREE') {
            var title = {
                id: 'TREE_TITLE',
                type: 'textfield',
                label: Dict.t('TITLE')
            };

            var AdvanceFieldPropLabel = {
                id: 'ADVANCED_FIELDS_LABEL',
                type: 'label',
                label: 'La collezione campi (separati da virgola) per la ricerca avanzata compariranno nella ricerca semplice'
            };

            var AdvancedSearchFields = {
                id: 'ADVANCED_SEARCH_FIELDS',
                type: 'textfield',
                label: Dict.t('ADVANCED_SEARCH_FIELDS') + ' [collezioneOggetto::collezioneCampi]'
            };

            var AdvanceFieldResultPropLabel = {
                id: 'ADVANCED_RESULT_LABEL',
                type: 'label',
                label: 'La collezione campi (separati da virgola) verrà stampata secondo l\'ordine inserito. Gli oggetti non inseriti verranno ignorati ed avranno layout normale (1)'
            };

            var AdvancedSearchResultFields = {
                id: 'ADVANCED_SEARCH_RESULT_FIELDS',
                type: 'textfield',
                label: Dict.t('ADVANCED_SEARCH_RESULT_FIELDS') + ' [collezioneOggetto::collezioneCampi]'
            };

            var prefilledFields = {
                id: 'PREFILLED_FIELDS',
                type: 'textfield',
                label: Dict.t('PREFILLED_FIELDS') + ' [collezioneOggetto::collezioneCampi]'
            };

            var documentPropLabel = {
                id: 'DOC_PROP_LABEL',
                type: 'label',
                label: 'Per convenzione ed un miglior recupero nella lista i18n è consigliabile utilizzare il prefisso TREE_PROP_LABEL_'
            };

            var documentContainer = {
                id: 'TREE_PROP_LABEL_DOCUMENT_CONTAINER_NAME',
                type: 'textfield',
                label: Dict.t('DOCUMENT_CONTAINER_NAME')
            };

            var subItemDocumentContainer = {
                id: 'TREE_PROP_LABEL_SUB_ITEM_DOCUMENT_CONTAINER_NAME',
                type: 'textfield',
                label: Dict.t('SUB_ITEM_DOCUMENT_CONTAINER_NAME')
            };

            var mainSkin = {
                id: 'TREE_MAIN_SKIN',
                type: 'textfield-colorpicker',
                label: Dict.t('TREE_MAIN_SKIN')
            };

            var minorSkin = {
                id: 'TREE_MINOR_SKIN',
                type: 'textfield-colorpicker',
                label: Dict.t('TREE_MINOR_SKIN')
            };

            var advanceSearchLabel = {
                id: 'TREE_ADVANCED_SEARCH_LABEL',
                type: 'label',
                label: 'Ricerca avanzata'
            };

            var advancedSearchFieldLayoutItems = [{value: "1", text: 'completo'}, {
                value: "2",
                text: 'solo campi specifici per la ricerca avanzata'
            }];

            var advancedSearchFieldLayout = {
                id: 'ADVANCED_SEARCH_FIELD_LAYOUT',
                type: 'select',
                label: Dict.t('ADVANCED_SEARCH_FIELD_LAYOUT'),
                options: advancedSearchFieldLayoutItems
            };

            var advancedSearchResultLayoutItems = [{value: "1", text: 'completo'}, {
                value: "2",
                text: 'usa il layout di risultati specifico'
            }];

            var advancedSearchResultLayout = {
                id: 'ADVANCED_SEARCH_RESULT_LAYOUT',
                type: 'select',
                label: Dict.t('ADVANCED_SEARCH_RESULT_LAYOUT'),
                options: advancedSearchResultLayoutItems
            };

            toLoad.push(title);
            toLoad.push(documentPropLabel);
            toLoad.push(documentContainer);
            toLoad.push(subItemDocumentContainer);
            toLoad.push(mainSkin);
            toLoad.push(minorSkin);
            toLoad.push(advanceSearchLabel);
            toLoad.push(advancedSearchFieldLayout);
            toLoad.push(AdvanceFieldPropLabel);
            toLoad.push(AdvancedSearchFields);
            toLoad.push(advancedSearchResultLayout);
            toLoad.push(AdvanceFieldResultPropLabel);
            toLoad.push(AdvancedSearchResultFields);
            toLoad.push(prefilledFields);

            this._initComponents(toLoad);
        }
    }

    _onDrop(files, area) {
        var areaFiles = this.state.areaFiles;
        delete (areaFiles[area + 'File']);
        areaFiles[area + 'File'] = files;

        this.setState({
            areaFiles, dropHeaderText: this.dropLoadedMsg, reloadAfterSave: true
        });
    }

    _setReload() {
        this.setState({reloadAfterSave: true});
    }

    _buildVarious() {
        var toLoad = [];

        var anomalies = {
            id: "DETECT_SCHEMA_ANOMALIES",
            label: "mostra anomalie di mapping db",
            type: 'checkbox'
        };

        var useDbSearch = {
            id: "USE_DB_SEARCH",
            label: "usa ricerca da db",
            type: 'checkbox'
        };

        toLoad.push(anomalies);
        toLoad.push(useDbSearch);
        this._initComponents(toLoad);
    }

    render() {

        var state = this.state;

        const components = this.state.propertiesComponents.map(item => {
                if (item.type === 'label') {
                    return <h4 key="key-secondary-lang-select"><Label
                        className={'prop-secondary-select'}>{item.label}</Label></h4>;
                } else if (item.type === 'textfield') {
                    return <InputGroup key={"key-property-" + item.id}>
                        <InputGroup.Addon>{item.label}</InputGroup.Addon>
                        <FormControl type="text"
                                     value={item.value}
                                     onChange={(evt) => this._handlePropertyTextField(item, evt)}
                        />
                    </InputGroup>
                } else if (item.type === "layout-drop-zone") {

                    var f = this.state.areaFiles[item.area + 'File'];
                    const hasFile = typeof f !== 'undefined';
                    var showActive = false;
                    var isRemove = this.state.removeLayout.includes(item.area);
                    var storedArea = this.state.storedLayoudAreas.find((area) => {
                        return area.area === item.area;
                    })

                    var idLayoutContainer = item.area + '-layout-container';
                    var idPreview = item.area + '-preview';
                    var idPreviewRestoreContainer = item.area + '-preview-restore-container';
                    var idPreviewButton = item.area + '-restore-button';
                    var idPreviewDropZone = item.area + '-layout-add-dropzone';
                    var idPreviewErrorMsgDropZone = item.area + '-error-msg-add-dropzone';
                    var previewLayoutErrorDrop = "layout-add-dropzone-error";

                    var isImg = false;
                    var isZip = false;
                    var isHtml = false;
                    var fileToProcess = null;

                    if (hasFile) {
                        showActive = false;
                        var fileToProcess = f[0];
                        const extension = BUtils.extractFileExtension(fileToProcess.name);

                        Dict.call('admin/properties/uploadFileCustomHtml/' + item.area + '/' + extension + '/', {
                            method: 'POST',
                            data: fileToProcess,
                            headers: {
                                'Accept': 'image/jpeg,image/png,image/gif, text/html, application/zip, multipart/x-zip, application/x-zip-compressed, application/x-compressed',
                                'Content-Type': fileToProcess.type,
                            }
                        }).then((result) => {
                            if (result.result === 'OK') {
                                document.getElementById(idPreview).src = "custom/tmp/" + item.area + "/" + result.filename;
                                document.getElementById(idPreview).style.opacity = '1';
                                showActive = true;
                            } else {
                                document.getElementById(idPreviewDropZone).classList.add(previewLayoutErrorDrop);
                                document.getElementById(idPreviewErrorMsgDropZone).innerHTML = result.result;
                                setTimeout(function () {
                                    document.getElementById(idPreviewDropZone).classList.remove(previewLayoutErrorDrop);
                                    document.getElementById(idPreviewErrorMsgDropZone).innerHTML = '';
                                }, 5000);

                                var areaFiles = this.state.areaFiles;
                                delete (areaFiles[item.area + 'File']);
                                this.setState({areaFiles});
                            }
                        });
                    } else if (storedArea) {
                        showActive = true;
                        fileToProcess = storedArea;

                        setTimeout(function () {
                            if (document.getElementById(idPreview).style.opacity === '0') {
                                document.getElementById(idPreview).src = "custom/" + item.area + "/" + storedArea.name;
                                document.getElementById(idPreview).style.opacity = '1';
                                document.getElementById(idPreviewRestoreContainer).style.display = 'inline-flex';
                            }
                        }, 200);
                    }

                    if (fileToProcess !== null) {
                        isImg = BUtils.isImage(fileToProcess.name);
                        isZip = BUtils.isZip(fileToProcess.name);
                        isHtml = BUtils.isHtml(fileToProcess.name);
                    }


                    const activateCallback = (id, newState) => {
                        this._setReload();
                        this._handleComponentProperties(item, newState);
                    };

                    return <div key={'key-layout-' + item.area} id={idLayoutContainer}
                                className={'preview-layout-container'}>
                        <Dropzone
                            id={idPreviewDropZone}
                            accept={"image/jpeg,image/png,image/gif, text/html, application/zip, multipart/x-zip, application/x-zip-compressed, application/x-compressed"}
                            rejectClassName={"add-img-reject-drop-zone"}
                            acceptClassName={"accept-drop-zone"}
                            style={{marginBottom: '10px'}}
                            ref={(node) => {

                            }} className="area-dropzone"
                            onDrop={acceptedFiles => this._onDrop(acceptedFiles, item.area)}
                        >
                            <p className="p-dropzone-layout"
                               style={{height: (fileToProcess === null ? '50px' : 'auto')}}>{item.msg}</p>
                            <p className="p-dropzone-layout p-error-dropzone" id={idPreviewErrorMsgDropZone}/>
                            <div>
                                {fileToProcess !== null && isImg ?
                                    <Image style={{height: '100px', width: '100%', opacity: 0}} id={idPreview} src={''}
                                           responsive/> : ""}
                                {(fileToProcess !== null && (isZip || isHtml)) ?
                                    <iframe id={idPreview} style={{
                                        width: '100%',
                                        height: '100px',
                                        border: '1px solid black',
                                        opacity: hasFile ? 1 : 0
                                    }} src={''} frameBorder="0"/> :
                                    ""}
                            </div>
                        </Dropzone>
                        <div id={idPreviewRestoreContainer} style={{display: 'inline-flex'}}>
                            {showActive ? <Button key={'key-dropzone-button-' + item.area}
                                                  style={{display: hasFile ? 'none' : 'block'}} onClick={() => {
                                var removeLayout = state.removeLayout;

                                if (removeLayout.includes(item.area))
                                    removeLayout.splice(removeLayout.indexOf(item.area), 1);
                                else
                                    removeLayout.push(item.area);

                                this.setState({removeLayout, reloadAfterSave: true});

                            }}>{isRemove ? 'Annulla rimozione' : 'Rimuovi'}</Button> : ""}
                            {hasFile ?
                                <Button id={idPreviewButton} key={'key-dropzone-button-' + item.area} onClick={() => {
                                    Dict.call('admin/properties/cleanTmpCustomHtml/' + item.area + '/').then((result) => {
                                        var areaFiles = this.state.areaFiles;
                                        delete (areaFiles[item.area + 'File']);
                                        this._cleanTmpCustomHtml(item.area).then((result) => {
                                            this.setState({areaFiles, reloadAfterSave: true});
                                        })
                                    });
                                }}>Ripristina</Button> : ""}
                            {showActive || hasFile ? <SCCheckbox key={"key-chk-" + item.id}
                                                                 checked={item.value}
                                                                 label={'attiva/disattiva'}
                                                                 tooltip={item.id}
                                                                 id={item.id}
                                                                 items={item.items}
                                                                 callbackParent={eval(activateCallback)}/> : ''}
                        </div>
                    </div>
                } else if (item.type === 'textfield-colorpicker') {
                    var colorValue = typeof item.value === 'undefined' ? '#ffffff' : item.value;
                    var background = 'white';
                    if (!colorValue.length === 7 || isNaN(Number('0x' + colorValue.substring(1, 7)))) {
                        colorValue = '#ffffff';
                        background = 'red';
                    }

                    return <div style={{width: '850px'}} className={'form-group'}>
                        <label className={'control-label'} style={{width: '200px'}}>{item.label}</label>
                        <input type='color' value={colorValue} style={{
                            borderRadius: '3px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginBottom: '15px',
                            cursor: 'pointer',
                            background: background
                        }}
                               onChange={(evt) => {
                                   this._handlePropertyTextField(item, evt);
                               }}/>
                        <input type='text' style={{borderRadius: '3px', border: '1px solid #cccccc', padding: '4px'}}
                               value={colorValue} onChange={(evt) => {
                            this._handlePropertyTextField(item, evt);
                        }}/>
                    </div>
                } else {
                    const callback = item.type === 'radio' || item.type === 'select' ? (newState) => this._handleComponentProperties(item, newState) : (id, newState) => this._handleComponentProperties(item, newState);
                    return SCComponents.getSCComponentItem(item, this.state.language, callback);
                }
            }
        );

        return <Grid id="properties-tab" fluid>
            {components}
            {this.state.wizardRequired ? <Button
                onClick={() => AdministrationUtilities.openWizard(this.props.tab)}>{Dict.t('OPEN_WIZARD')}</Button> : ''}
            <FormGroup id="option-tab-language-group-button">
                <Button bsStyle={this.state.saveProperties}
                        onClick={this._saveProperties}><Glyphicon style={{paddingRight: '4px'}}
                                                                  glyph={this.state.savePropertiesGlyph}/>{Dict.t('SAVE')}
                </Button>
            </FormGroup>
        </Grid>
    }
}
