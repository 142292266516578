import React from 'react';
import ReactDom from 'react-dom';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {buildEntrySearchByForm, searchByParams} from './../../search/lib/search';
import './typehead.css';
import stylesScNavbar from "../navbar/ScNavbar.module.css";


export class EntryTypeahead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            align: 'justify',
            items: [],
            languageId: this.props.languageId,
            selected: this.props.selected,
            options: []
        };

        this.hasCallbackParentSearchOn = typeof this.props.callbackParentSearchOn !== 'undefined';

        this._handleSearch = this._handleSearch.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleOnInputChange = this._handleOnInputChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const languageId = nextProps.languageId;
        if ((typeof languageId !== 'undefined' && languageId !== this.state.languageId) || this.props.searchParams.className !== nextProps.searchParams.className) {
            var options = [];
            var items = [];
            this.refs.entrytypeahead.getInstance().clear();
            this.setState({languageId, options, items}, function () {
                this.refs.entrytypeahead.getInstance().focus();
            });
        } else if (nextProps.forceFocus) {
            this.refs.entrytypeahead.getInstance().focus();
        }
    }


    _handleSearch(query) {
        if (!query) {
            return;
        } else {
            var promise = typeof this.props.searchParams !== 'undefined' ? searchByParams(this.props.searchParams) : buildEntrySearchByForm(query + "*", this.state.languageId);

            promise.then(result => {
                const items = result.items;
                var options = [];
                if (items.length > 0) {
                    items.map(item => {
                        options.push({id: item.fields.id, label: item.fields.entryForm, abstr: item.fields.abstr});
                    });
                }
                this.setState({options});
            });
        }
    }

    _handleOnInputChange(text) {
        var options = [];
        this.setState({options});

        if (this.hasCallbackParentSearchOn)
            this.props.callbackParentSearchOn(text.length > 0, false, text);
    }

    _handleChange(item) {
        if (Object.keys(item).length > 0) {
            this.setState({
                value: item[0].id
            });
            this.props.callbackParent(item[0]);
            if (this.hasCallbackParentSearchOn && typeof this.props.searchParams === 'undefined')
                this.props.callbackParentSearchOn(true, true, item[0].label);
        } else {
            this.props.callbackParent("");
            if (this.hasCallbackParentSearchOn)
                this.props.callbackParentSearchOn(false, false, '');
        }
    }

    _handleKeyDown(evt) {
        if (this.props.enableEnter && evt.keyCode === 13) {
            this.props.callbackParentPressEnter();
        }
    }

    render() {
        const {align} = this.state;
        var chooseLanguage = typeof this.props.placeholder !== 'undefined' ? this.props.placeholder : "entry...";

        return (
            <AsyncTypeahead
                ref={"entrytypeahead"}
                minLength={1}
                maxResult={10}
                className={typeof this.props.className === 'undefined' ? '' : this.props.className}
                clearButton
                autoFocus={true}
                align={align}
                ignoreDiacritics
                filterBy={(option, props) => {
                    return true;
                }}
                emptyLabel
                onKeyDown={evt => this._handleKeyDown(evt)}
                onChange={item => this._handleChange(item)}
                onSearch={this._handleSearch}
                onInputChange={this._handleOnInputChange}
                onFocus={this.props.onFocus}
                options={this.state.options}
                placeholder={chooseLanguage}
                useCache={typeof this.props.useCache !== 'undefined' ? this.props.useCache : true}
                renderMenuItemChildren={(option, props) => {
                    return <>
                        <strong style={{ marginRight : '6px'}} dangerouslySetInnerHTML={{__html: option.label}}/>
                        <small dangerouslySetInnerHTML={{__html: option.abstr}}/>
                    </>
                }
                }
            />
        );
    }
}



