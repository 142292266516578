import React from 'react';
import {Button, Glyphicon, OverlayTrigger, Tooltip} from 'react-bootstrap';
import SortableTree, {} from 'react-sortable-tree';
import './Treeview.css';
import styleTreeView from './LanguageTreeView.module.css';
import Dict from 'dict-lib';
import {getLexemeOptions} from './../../lexeme/page/LexemePage';

export class LanguageTreeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: this.props.form,
            treeData: this.props.treeData,
            searchId: "",
            searchAncestors: "",
            existingFormAncestors: "",
            existingFormDescendants: "",
            rootEntryId: this.props.rootEntryId,
            expandAncestors: "",
            expandDescription: "",
            type: this.props.type
        };


        this._handleClick = this._handleClick.bind(this);
        this._updateTreeData = this._updateTreeData.bind(this);
        this._eachRecursive = this._eachRecursive.bind(this);
        this._finishCallback = this._finishCallback.bind(this);
    }

    componentWillMount() {

        var searchId = "";
        var searchAncestors = "";

        if (typeof this.props.searchId !== 'undefined') {
            searchId = "^" + this.props.searchId + "^";
            searchAncestors = "^" + this.props.searchAncestors + "^";
        }

        this._resetSelection(this.state.treeData[0]);

        this.setState({
            searchId: searchId,
            searchAncestors: searchAncestors

        }, function () {
            if (searchId.length > 0 && document.getElementsByClassName("rst__expandButton").length > 0)
                document.getElementsByClassName("rst__expandButton")[0].click();
        });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.openLevel > 0) {
            Dict.call('service/getLanguageTreeViewNodeById/' + nextProps.openLevel).then(result => {
                    if (typeof result.expandAncestors !== 'undefined' && result.expandAncestors != null)
                        this.setState({
                            expandAncestors: result.expandAncestors,
                            expandDescription: result.expandDescription
                        }, this._updateTreeData(this.state.treeData));
                }
            );
        }
    }

    _updateTreeData(treeData) {
        this.setState({treeData});
    }

    _resetSelection(node) {
        node.children.map(childNode => {
            childNode.selected = false;
            this._resetSelection(childNode);
        });
    }

    _eachRecursive(obj) {
        if (typeof obj.children == 'undefined') {
            obj = obj[0];
            this._performRecursive(obj);
        }

        obj.children.map((item) => {
            this._performRecursive(item);
        });

        return obj;
    }

    _performRecursive(node) {
        if (node.id === node.chainFilterId) {
            node.expanded = true;
        }

        if (this._isNotAllowedAncestor(node)) {
            node.expanded = true;
        }

        if (typeof node.children !== 'undefined' && node.children.length > 0)
            return this._eachRecursive(node);
    }

    _handleClick(node) {
        var searchId = "";
        var searchAncestors = "";
        if (node.selected) {
            node.selected = false;
            searchId = this.state.searchId.replace(node.id + "^", "");
            searchAncestors = this.state.searchAncestors.replace(node.ancestors, "");
        } else {
            node.selected = true;
            searchId = this.state.searchId == '' || this.state.searchId == '^^' ? "^" + node.id + "^" : this.state.searchId + node.id + "^";
            searchAncestors = node.ancestors + this.state.searchAncestors;
        }

        this.setState({searchId: searchId, searchAncestors: searchAncestors}, function () {
            this.props.callbackParent(node);
        });
    }


    _setExistingFormDescendantRecursive(node) {
        node.children.map(childNode => {
            childNode.permission_state = "IS_EXISTING_FORM_DESCENDANT";
            this._setExistingFormDescendantRecursive(childNode);
        });
    }


    _mySearchMethod(item) {
        const node = item.item.node;

        if (this.state.form === '') {
            this._manageEmptyForm(node);
        } else {
            const selectedNodeFound = this.state.searchId.indexOf("^" + node.id + "^") > -1 || node.selected;
            const existingFormNodeFound = node.typology !== "NOT_ATTESTED" && typeof node.form_list !== 'undefined' && node.form_list.length > 0 && node.form_list.indexOf("^" + this.state.form + "^") > -1;
            const ancestoreNodeToExpandFound = this.state.expandAncestors.length > 0 && ("^" + this.state.expandAncestors).indexOf("^" + node.id + "^") > -1;

            if (existingFormNodeFound) {
                this._manageExistingFormNode(node);
            } else if (ancestoreNodeToExpandFound) {
                this._manageAncestorNodeToExpand(node);
            } else if (selectedNodeFound) {
                this._manageSelectedNode(node);
            } else {
                this._manageNotSelectedNode(node);
            }

            return selectedNodeFound;
        }
    }


    _manageEmptyForm(node) {
        node.permission_state = 'EMPTY_FORM';
        if (this.state.existingFormAncestors.indexOf(node.ancestors) === -1) {
            var existingFormAncestors = node.ancestors + this.state.existingFormAncestors;
            this._setExistingFormDescendantRecursive(node);
            this.setState({existingFormAncestors: existingFormAncestors});
        }
    }

    _manageExistingFormNode(node) {
        if (node.typology !== "LEXOTYPE") {
            node.permission_state = 'ENTRY_EXISTS';
            if (this.state.existingFormAncestors.indexOf(node.ancestors) === -1) {
                var existingFormAncestors = node.ancestors + this.state.existingFormAncestors;
                this._setExistingFormDescendantRecursive(node);
                this.setState({existingFormAncestors: existingFormAncestors});
            }
        }
    }

    _manageNotSelectedNode(node) {
        node.chain_state = this.state.searchAncestors.indexOf("^" + node.id + "^") > -1 ? "IS_SELECTED_ANCESTOR" : "OK";

        if (node.permission_state === 'EMPTY_FORM' && this.state.form !== '')
            node.permission_state = "OK";

        if (node.chain_state === "OK") {
            node.chain_state = this.state.existingFormAncestors.indexOf("^" + node.id + "^") > -1 ? "IS_EXISTING_FORM_ANCESTOR" : "OK";
        }
        if (node.chain_state === "OK") {
            node.chain_state = node.ancestors.indexOf("^" + this.state.searchId + "^") > -1 ? "IS_SELECTED_DESCENDANT" : "OK";
            var sep = this.state.searchId.split("^");
            sep.forEach(function (el) {
                if (node.ancestors.indexOf("^" + el + "^") > -1) {
                    return node.chain_state = "IS_SELECTED_DESCENDANT";
                }
            });
        }
    }

    _manageSelectedNode(node) {
        node.selected = true;
        if (this.state.searchAncestors === '^') {
            this.setState({searchAncestors: node.ancestors});
        }
    }

    _manageAncestorNodeToExpand(node) {
        node.expanded = true;
    }

    _resetNodes(rowInfo) {
        /*if (rowInfo.node.loading == true)
         console.log(rowInfo.node);*/
        const isAllowed = this._isAllowed(rowInfo);
        const msg = Dict.t(rowInfo.node.permission_state === 'OK' ? rowInfo.node.chain_state : rowInfo.node.permission_state);


        if (typeof this.state.type !== 'undefined' && this.state.type.startsWith('PURE_'))
            return "";
        else {
            return {
                buttons: [<Button id={"bt-info-" + rowInfo.node.id}
                                  className={"rst_button rst_button_allowed_" + isAllowed}
                                  onClick={() => this._handleClick(rowInfo.node)}><Glyphicon
                    glyph={"chevron-right"}/>{this.props.form}
                </Button>,
                    <OverlayTrigger placement="right"
                                    overlay={<Tooltip id="rst-tooltip" className="rst__tooltip">{msg}</Tooltip>}>
                        <Button id={"bt-info-" + rowInfo.node.id}
                                className={"rst_button rst_button_allowed_" + !this._isAllowed(rowInfo)}
                                onClick={() => {
                                    alert(msg)
                                }}>
                            <Glyphicon
                                glyph="ban-circle"/>
                        </Button>
                    </OverlayTrigger>]
            }
        }
    }

    _isAllowed(rowInfo) {
        return rowInfo.node.permission_state === 'OK' && rowInfo.node.chain_state === 'OK' /*&& !this._findSelectedAncestor(rowInfo)*/;
    }

    _isNotAllowedAncestor(node) {
        return node.chain_state === 'IS_SELECTED_ANCESTOR';
    }

    _isExistingFormAncestor(node) {
        return node.chain_state === 'IS_EXISTING_FORM_ANCESTOR';
    }

    _eachChildrenAllowRecursive(items) {
        items.map(item => {
            var node = typeof item.node !== 'undefined' ? item.node : item;
            node.chain_state = "IS_SELECTED_DESCENDANT";
            this._eachChildrenAllowRecursive(node.children);
        });
    }

    _finishCallback(items) {
        if (this.state.expandDescription.length > 0) {
            var scrollToLang = this.state.expandDescription;
            this.setState({expandAncestors: "", expandDescription: ""}, this._updateTreeData(this.state.treeData));

            setTimeout(function () {
                var tree = document.getElementsByClassName("rst__tree");

                if (tree.length >= 0) {
                    var titles = document.getElementsByClassName("rst__rowTitle");

                    Array.from(titles).forEach(function (el) {
                        if (el.innerText === scrollToLang) {
                            var parent = el.parentNode.parentNode;
                            parent.parentNode.parentNode.scrollIntoView({block: "start", behavior: "smooth"});
                            var classNameOld = parent.className;
                            parent.className = parent.className + " rst__rowTypeaheadSearch";
                            setTimeout(function () {
                                parent.className = classNameOld;
                            }, 3000);
                        }
                    });
                }
            }, 500);
        }
    }


    render() {

        var tree = [];
        if (this.state.treeData.length > 0) {
            var res = this._eachRecursive(this.state.treeData);
            tree.push(res);
        }

        return <div style={{height: this.props.height ? this.props.height : '100%'}}>
            <SortableTree
                treeData={tree}
                onChange={treeData => this.setState({treeData})}
                canDrag={false}
                /*onVisibilityToggle={(item) => this._onVisibilityToggle(item)}*/
                /*searchQuery={}*/
                searchMethod={(item) => this._mySearchMethod({item})}
                searchFinishCallback={(items) => this._finishCallback(items)}
                generateNodeProps={rowInfo => this._resetNodes(rowInfo)}
                isVirtualized={false}
            />
        </div>
    }

}