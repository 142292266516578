import React from 'react';
import Dict from 'dict-lib';
import {saveNotes} from './EntryActions';
import {Grid, Row, InputGroup, FormControl, Button} from 'react-bootstrap';
import './../../../../_css/lexeme.css';

export class EntryNotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: this.props.item.variants
        };

        this._handleEntryNote = this._handleEntryNote.bind(this);
        this._saveEntryNotes = this._saveEntryNotes.bind(this);
    }

    _handleEntryNote(item, evt) {
        var items = JSON.parse(JSON.stringify(this.state.items));
        var index = this.state.items.indexOf(item);
        items[index].notes = evt.target.value;
        this.setState({items});
    }

    _saveEntryNotes() {
        var items = JSON.stringify(this.state.items);

        this.setState({btDisabled: true});
        saveNotes(items).then(result => {
            /*this.setState({btSaveState: "success", btDisabled: false}, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });*/
        });
    }

    render() {
        return <Grid className="poly-notes-container" fluid>
            { this.state.items ? this.state.items.map(item =>
                <Row key={"key-poly-notes-" + item.id}>
                    <InputGroup className={"poly-notes"}>
                        <InputGroup.Addon>{item.language}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                     ref={(input) => {
                                         this.entryNote = input;
                                     }}
                                     value={item.notes}
                                     onChange={(evt) => this._handleEntryNote(item, evt)}
                        />
                    </InputGroup>
                </Row>) : ""}
            <Row className={"row-bt-poly-notes"}><Button bsStyle={this.state.btSaveState}
                /*disabled={this.state.btDisabled}*/
                                                          onClick={() => this._saveEntryNotes()}>{Dict.t('SAVE')}</Button></Row>
        </Grid>;
    }


}
