import React from 'react';
import {deleteSense, removeSenseVarLoc} from './SenseAction';
import Dict from 'dict-lib';
import {SCCheckbox} from '../../../../dict-components/components';
import {Button, Row, Col} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import './../../parts/03_Subentries/parts/LexemeSenses.css';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';

export class SenseRemove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            idsToRemove: []
        };

        this._handleRemove = this._handleRemove.bind(this);
        this._handleCheckboxRemove = this._handleCheckboxRemove.bind(this);
    }

    _handleRemove() {
        if ((this.state.idsToRemove).length > 0)
            removeSenseVarLoc(this.props.item, this.state.idsToRemove);
    }

    _handleCheckboxRemove(id, newState) {
        var idsToRemove = this.state.idsToRemove;
        if (newState) {
            idsToRemove.push(id.replace('local-id-', ''));
        } else {
            idsToRemove.splice(idsToRemove.indexOf(id.replace('local-id-'), 1));
        }

        this.setState({idsToRemove});
    }

    render() {

        var hasSelectItems = (this.state.idsToRemove).length > 0;

        var varLocs = this.props.item.local_senses.map((item) =>
            <Row>
                <Col className={stylesLexemeSenses['local-sense-remove__lang']} lg={6} md={6}>{item.language}</Col>
                <Col className={stylesLexemeSenses['local-sense-remove__lang-chk']} lg={6} md={6}><SCCheckbox id={"local-id-" + item.id}
                                                                                         callbackParent={(id, newState) => this._handleCheckboxRemove(id, newState)}/></Col>
            </Row>
        );

        return <div className={stylesLexemeSenses['loc-var-sense-remove']}>
            <Row className={stylesLexemeSenses['local-senses-to-remove-list']}>{varLocs}</Row>
            <Row className={stylesLexemeSenses['ask-local-sense']}>
                <Col className={stylesLexemeSenses['ask-local-sense-text']} lg={10} md={10}>
                    <Row>{Dict.t(hasSelectItems ? 'ASK_DELETE_SELECTED_LOCAL_SENSE' : 'SELECT_LOCAL_SENSE')}</Row>
                </Col>
                { hasSelectItems > 0 ?
                    <Col lg={2} md={2}>
                        <Button onClick={this._handleRemove}>{Dict.t('YES')}</Button>
                    </Col> : "" }
            </Row>
        </div>;
    }


}
