import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {movePoly} from './EntryActions';
import {Button, Row, Col} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {getLexemeProperties} from './../../LexemePage';
import {EntryTypeahead} from './../../../../dict-components/components';

export class EntryPolyMove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rootToAssign: {}
        };

        this._handleMovePoly = this._handleMovePoly.bind(this);
        this._handleAssignEntry = this._handleAssignEntry.bind(this);
    }

    _handleMovePoly() {
        this.setState({btDisabled: true});
        movePoly(this.props.item.ids, this.state.rootToAssign).then(result => {
            if (result.result == 'OK') {
                var backdrop = document.getElementsByClassName("modal-backdrop");
                var poly = document.getElementsByClassName("modal-poly");

                backdrop[0].className = "modal-backdrop fade out";
                poly[0].className = "modal-poly fade out";
            }
        });
    }

    _handleAssignEntry(newState) {
        this.setState({rootToAssign: newState.id});
    }

    render() {

        const foundRoot = this.state.rootToAssign > 0;

        return <div className="">
            <Row>
                <Col lg={5} md={5}>
                    <EntryTypeahead languageId={getLexemeProperties("language_id")}
                                    callbackParent={(newState) => this._handleAssignEntry(newState)}/></Col>
                { foundRoot ?
                    <Col lg={5} md={5} className="popup-label">{Dict.t('ASK_MOVE_POLY')}</Col>
                    : "" }
                { foundRoot ?
                    <Col lg={1} md={1}>
                        <Button onClick={this._handleMovePoly}>{Dict.t('YES')}</Button>
                    </Col>
                    : "" }
            </Row>
        </div>;
    }

}
