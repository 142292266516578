import Dict from "dict-lib";
import {DictionarySettings} from "./DictUtilities";

export const ImportStatus = {
    BEGIN: 'BEGIN',
    ANALYSIS_STARTED: 'ANALYSIS_STARTED',
    ANALYSIS_FINISHED: 'ANALYSIS_FINISHED',
    IMPORT_STARTED: 'IMPORT_STARTED',
    IMPORT_FINISHED: 'IMPORT_FINISHED',
    WAITING_DB_EMPTY: 'WAITING_DB_EMPTY'
};

export const ImportEntities = {
    TOPONOMASTIC: {1: 'PlaceTypology', 2: 'Place', 3: 'Toponym'},
    DICTIONARY: {1: 'Entry'}
};

export var ImportService = {

    prepareLaunchImport: function (params) {
        var type = params.type;
        return eval('this.prepareLaunchImport' + type + '(params)');
    },

    prepareLaunchImportTOPONOMASTIC: function (params) {
        if (params.step === 1) {
            params.isLast = (params.length - params.start <= params.objectToProcess);
            params.nextPass = false;
            params.percent = params.end / params.length * 100;
        } else if (params.step === 2) {
            params.additionalParams = '/' + params.pass;
            params.nextPass = (params.length - params.start <= params.objectToProcess) && params.pass === 1;
            params.isLast = (params.length - params.start <= params.objectToProcess) && params.pass === 2;
            if (params.pass === 1) {
                params.percent = params.end / params.length * 100 / 4;
            } else if (params.pass === 2) {
                params.percent += (params.end / params.length * 100 / 3);
                params.lines = params.lines.map((line) => {
                    return {descrizione: line.descrizione, padre: line.padre}
                });
            }
        } else if (params.step === 3) {
            params.percent = params.end / params.length * 100;
            params.nextPass = false;
            params.isLast = (params.length - params.start <= params.objectToProcess);
        }

        return params;
    },

    prepareLaunchImportDICTIONARY: function (params) {
        if (params.step === 1) {
            params.isLast = (params.length - params.start <= params.objectToProcess);
            params.nextPass = false;
            params.percent = params.end / params.length * 100;
        }

        return params;
    },

    checkCsvByType(type, step, langItemsLabelArray) {
        return eval('this.checkCsv' + type + '(step, langItemsLabelArray)');
    },

    checkCsvTOPONOMASTIC: function (step, langItemsLabelArray) {
        var checkFileArray = null;

        if (step === 1)
            checkFileArray = langItemsLabelArray.concat('descrizione');
        else if (step === 2)
            checkFileArray = langItemsLabelArray.concat(['descrizione', 'tipologia', 'latitudine', 'longitudine', 'padre'])
        else if (step === 3)
            checkFileArray = ['nome', 'lingua', 'luogo'];

        return checkFileArray;
    },

    checkCsvDICTIONARY: function (step, langItemsLabelArray) {
        var checkFileArray = null;

        if (step === 1)
            checkFileArray = ['forma', 'lingua', 'c.gr.', 'locuzioni'];
        return checkFileArray;
    },

    analizeByType(type, resolve, step, rowsToCheck, resultItems, langItems) {
        return eval('this.analyze' + type + '(resolve, step, rowsToCheck, resultItems, langItems)');
    },

    analyzeTOPONOMASTIC: function (resolve, step, rowsToCheck, resultItems, langItems) {
        var failedRows = [];
        if (step === 1) {
            ImportHelper.checkMissingOrExistingField(failedRows, 'descrizione', resultItems, rowsToCheck, false, true, true);
            return resolve(failedRows);
        } else if (step === 2) {
            resolve(Dict.call('service/search/searchBackOfficeEntityItems/PlaceTypology/*/undefined/').then((resultItems2) => {
                ImportHelper.checkMissingOrExistingField(failedRows, 'descrizione', resultItems, rowsToCheck, false, true, true);
                ImportHelper.checkMissingOrExistingField(failedRows, 'tipologia', resultItems2, rowsToCheck, true, false, true);

                ImportHelper.checkMissingAndByType(failedRows, 'latitudine', 'float', rowsToCheck);
                ImportHelper.checkMissingAndByType(failedRows, 'longitudine', 'float', rowsToCheck);
                ImportHelper.checkTreeValidation(failedRows, rowsToCheck, 'descrizione', 'padre');

                return failedRows;
            }));
        } else if (step === 3) {
            resolve(Dict.call('service/search/searchBackOfficeEntityItems/Place/*/undefined/').then((resultItems2) => {
                ImportHelper.checkMissingOrExistingField(failedRows, 'nome', resultItems, rowsToCheck, false, true, true);
                ImportHelper.checkMissingOrExistingField(failedRows, 'lingua', {items: langItems}, rowsToCheck, true, false, true);
                ImportHelper.checkMissingOrExistingField(failedRows, 'luogo', resultItems2, rowsToCheck, true, false, true);
                return failedRows;
            }));
        } else {
            return resolve([{line: '', error: 'step errati'}]);
        }
    },

    analyzeDICTIONARY: function (resolve, step, rowsToCheck, resultItems, langItems) {
        var failedRows = [];

        if (step === 1) {
            resolve(DictionarySettings.getAllCgrs().then((cgrs) => {
                ImportHelper.checkMissingOrExistingField(failedRows, 'forma', resultItems, rowsToCheck, false, false, true);
                ImportHelper.checkMissingOrExistingField(failedRows, 'lingua', {items: langItems}, rowsToCheck, true, false, true);

                ImportHelper.checkDictionaryCgrSenses(failedRows, 'c.gr.', 'lingua', cgrs, langItems, rowsToCheck);
                ImportHelper.checkDictionaryLocSenses(failedRows, 'locuzioni', cgrs, langItems, rowsToCheck);

                return failedRows;
            }));
        }
    },

    getDescriptionByType(type, step, descrLanguages) {
        return eval('this.getDescription' + type + '(step, descrLanguages)');
    },

    getDescriptionTOPONOMASTIC(step, descrLanguages) {
        var instructions = '';
        if (step === 1) {
            var descrStr = descrLanguages.map((descrLanguage) => {
                return '<strong>' + descrLanguage.code + '</strong>';
            });
            instructions = '<h4>STEP 1 - inserimento tipologie di luogo</h4><i>il file .csv deve avere le colonne <strong>descrizione</strong> per la descr.univoca e ' + descrStr + ' per eventuali descrizioni per lingua</i>';
        } else if (step === 2) {
            var descrStr = descrLanguages.map((descrLanguage) => {
                return '<strong>' + descrLanguage.code + '</strong>';
            });
            instructions = '<h4>STEP 2 - inserimento luoghi</h4><i>il file .csv deve avere le colonne <strong>descrizione</strong> per la descr.univoca e ' + descrStr + ' per eventuali descrizioni per lingua, <strong>tipologia</strong>, <strong>padre</strong>, <strong>latitudine</strong> e <strong>longitudine</strong></i>'
        } else if (step === 3) {
            instructions = '<h4>STEP 3 - inserimento luoghi</h4><i>il file .csv deve avere le colonne <strong>forma</strong> del lemma, il codice univoco della <strong>lingua</strong> e <strong>luogo</strong></i>';
        }

        return instructions;
    },

    getDescriptionDICTIONARY(step, descrLanguages) {
        var instructions = '';
        if (step === 1) {
            instructions = '<h4>STEP 1 - inserimento lemmi</h4><i>il file .csv deve avere le colonne <strong>nome</strong> del lemma, <strong>lingua</strong> e <strong>c.gr.</strong>. Per inserire i sensi per il sottolemma corrispondente, riempire la colonna <strong>c.gr.</strong> nel modo seguente: cgr;senso1;senso2;senso3;...';
        }

        return instructions;
    }


};

export var ImportHelper = {

    getErrorLineNumber: function (index) {
        return index + 2 + ") ";
    },

    checkMissingOrExistingField: function (failedRows, field, resultItems, rowsToCheck, checkExistingInDbType, checkListContains, checkMissing) {
        checkExistingInDbType = typeof checkExistingInDbType === 'undefined' || checkExistingInDbType;
        checkListContains = typeof checkListContains === 'undefined' || checkListContains;
        checkMissing = typeof checkMissing === 'undefined' || checkMissing;

        rowsToCheck.forEach((rowToCheck, index) => {

            //var fileError = typeof rowToCheck[field] === 'undefined';

            var resExisting = checkExistingInDbType ? resultItems.items.find((item) => {
                return rowToCheck[field].toLowerCase() === item.label.toLowerCase();
            }) : undefined;

            var resListContains = checkListContains ? rowsToCheck.filter((item) => {
                return rowToCheck[field].toLowerCase() === item[field].toLowerCase();
            }) : [];

            var notFound = checkMissing && rowToCheck[field].length === 0;

            var line = this.getErrorLineNumber(index) + rowToCheck[field];
            if (checkExistingInDbType && typeof resExisting === 'undefined' && !notFound) {
                failedRows.push({line, error: 'non esiste'});
            } else if (resListContains.length > 1) {
                failedRows.push({
                    line,
                    error: 'la lista contiene più di una ' + field + ':' + rowToCheck[field]
                });
            } else if (notFound) {
                failedRows.push({line, error: field + ' mancante'});
            }
        });
    },

    checkMissingOrExistingFieldByFilter(failedRows, field, filter, resultItems, langItems, rowsToCheck) {
        rowsToCheck.forEach((rowToCheck, index) => {
            var line = this.getErrorLineNumber(index) + field + ' ' + rowToCheck[field];
            if (rowToCheck[field] === '') {
                failedRows.push({
                    line,
                    error: field + ' mancante'
                });
            } else {
                langItems.forEach((lang) => {
                    var filterValue = lang.label;

                    if (rowToCheck[filter] === filterValue) {
                        var collection = resultItems.find((item) => {
                            return typeof item[filterValue] !== 'undefined';
                        })[filterValue];

                        var resExisting = collection.find((item) => {
                            return item.label === rowToCheck[field];
                        });

                        if (typeof resExisting === 'undefined')
                            failedRows.push({
                                line,
                                error: ' non esiste in ' + filterValue
                            });
                    }
                });
            }
        });
    },

    checkDictionaryCgrSenses(failedRows, field, filter, resultItems, langItems, rowsToCheck) {
        rowsToCheck.forEach((rowToCheck, index) => {
            var line = this.getErrorLineNumber(index) + rowToCheck[field];
            if (rowToCheck[field] === '') {
                failedRows.push({
                    line,
                    error: field + ' mancante'
                });
            } else {
                var cgrs = rowToCheck[field].split('|').map((item) => {
                    var split = item.split(';');
                    var cgr = split[0];
                    var senses = split.length > 0 ? split.slice(1, split.length) : [];
                    var findDuplicates = senses.filter((item, index) => senses.indexOf(item) !== index);
                    if (findDuplicates.length > 0) {
                        failedRows.push({
                            line,
                            error: "il senso '" + findDuplicates[0] + "' compare più volte sulla c.gr. " + cgr
                        });
                    }


                    return {[field]: cgr, [filter]: rowToCheck[filter]};
                });
                this.checkMissingOrExistingFieldByFilter(failedRows, field, filter, resultItems, langItems, cgrs);
                console.log();
            }
        });


    },

    checkDictionaryLocSenses(failedRows, field, resultItems, langItems, rowsToCheck) {
        rowsToCheck.forEach((rowToCheck, index) => {
            var line = this.getErrorLineNumber(index) + 'locuzioni';

            var id = langItems.find((item) => {
                return item.label === rowToCheck.lingua;
            }).id;
            var options = Dict.getLanguageOptionsById(id);
            var polyCgr = options['entries->POLY_DEC_GRAM_CAT'];
            if (typeof polyCgr === 'undefined') {
                this.addImportError(failedRows, line, 'manca la c.gr. di default delle locuzioni per ' + rowToCheck.lingua);
            }

            if (rowToCheck[field].trim().length > 0) {
                rowToCheck[field].split('|').forEach((locution, indexLoc) => {
                    var split = locution.split(';');
                    var locForm = split[0];
                    var indexBracketOpen = locForm.indexOf('[');
                    var indexBracketClose = locForm.indexOf(']');
                    var hasDash = locForm.indexOf(' ~ ') > 0;
                    var hasBrackets = (indexBracketOpen > 0 || indexBracketClose > 0) && indexBracketClose > indexBracketOpen;

                    if (!(hasDash || hasBrackets)) {
                        this.addImportError(failedRows, line, 'la locuzione ' + (indexLoc + 1) + ' non contiene né tilde né declinazione tra quadre');
                    }

                    var senses = split.slice(1, split.length);
                    var findDuplicates = senses.filter((item, index) => senses.indexOf(item) !== index);
                    if (findDuplicates.length > 0) {
                        this.addImportError(failedRows, line, "il senso '" + findDuplicates[0] + "' compare più volte ");
                    }
                })
            }

            /*if (rowToCheck[field] === '') {
                failedRows.push({
                    line,
                    error: field + ' mancante'
                });
            } else {
                var cgrs = rowToCheck[field].split('|').map((item) => {
                    var split = item.split(';');
                    var cgr = split[0];
                    var senses = split.length > 0 ? split.slice(1, split.length) : [];
                    var findDuplicates = senses.filter((item, index) => senses.indexOf(item) != index);
                    if(findDuplicates.length > 0) {
                        failedRows.push({line, error: "il senso '" + findDuplicates[0] + "' compare più volte sulla c.gr. " + cgr});
                    }


                    return { [field] : cgr, [filter] : rowToCheck[filter]};
                });
                this.checkMissingOrExistingFieldByFilter(failedRows, field, filter, resultItems, langItems, cgrs);
            }*/
        });
    },

    checkCsv: function (rowToCheck, realColumns) {
        var rowToCheckArrays = Object.keys(rowToCheck);
        var differences;
        var isRightCsv;
        if (rowToCheckArrays.length === realColumns.length) {
            differences = rowToCheckArrays.filter(value => !realColumns.includes(value));
        } else {
            var max = rowToCheckArrays.length > realColumns.length ? rowToCheckArrays : realColumns;
            var min = max === rowToCheckArrays ? realColumns : rowToCheckArrays;
            differences = max.filter(value => !min.includes(value));
        }

        isRightCsv = differences.length === 0;

        return {isRightCsv, differences};
    },

    checkMissingAndByType: function (failedRows, field, type, rowsToCheck) {
        rowsToCheck.forEach((rowToCheck, index) => {

            var notFound = rowToCheck[field].length === 0;
            var line = this.getErrorLineNumber(index) + rowToCheck[field];

            var isNan = false;
            if (type === 'float') {
                isNan = isNaN(parseFloat(rowToCheck[field]));
            }

            if (isNan) {
                failedRows.push({line, error: field + ' non è di tipo ' + type});
            } else if (notFound) {
                failedRows.push({line, error: field + ' mancante'});
            }
        });
    },

    checkTreeValidation: function (failedRows, rowsToCheck, uniqueField, parentField) {
        //TODO: ERRORE PLACE 1.1.1
        var roots = [];

        const idMapping = rowsToCheck.reduce((acc, item, i) => {
            acc[item[uniqueField]] = i;
            return acc;
        }, {});

        var foundAnomaly = false;
        var chainToReset = [];
        rowsToCheck.forEach((item, index) => {
            if (foundAnomaly)
                return;
            if (item[parentField] === null || item[parentField] === '') {
                roots.push(item);
                return;
            }

            var parentItem = rowsToCheck[idMapping[item[parentField]]];
            var line;
            if (typeof parentItem === 'undefined') {
                line = this.getErrorLineNumber(index) + " errore di gerarchia ";
                failedRows.push({line, error: 'questo padre [' + item[parentField] + '] non esiste'});
                return;
            } else {
                chainToReset.forEach((chainItem) => {
                    delete chainItem.visited;
                });
                var checkItem = item;
                checkItem.visited = true;
                chainToReset = [checkItem];
                while (!foundAnomaly && typeof checkItem !== 'undefined') {
                    checkItem = rowsToCheck[idMapping[checkItem[parentField]]];
                    if (typeof checkItem !== 'undefined') {
                        foundAnomaly = typeof checkItem.visited !== 'undefined';
                        checkItem.visited = !foundAnomaly;
                        chainToReset.push(checkItem);
                    }
                }

                if (foundAnomaly) {
                    line = this.getErrorLineNumber(index) + item[uniqueField];
                    failedRows.push({
                        line,
                        error: 'rilevata catena circolare'
                    });
                }
            }

            parentItem.children = [...(parentItem.children || []), item];
        });
    },

    addImportError: function (failedRows, line, errorMsg) {
        failedRows.push({line, error: errorMsg});
    },

    getEntity: function (type, step) {
        return (ImportEntities[type])[step];
    },

    getImportService: function (type, step) {
        return 'import' + this.getEntity(type, step);
    },

};