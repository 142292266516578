import React from 'react';
import ReactDom from 'react-dom';
import Dict from 'dict-lib';
import {OverlayTrigger, Popover, InputGroup, FormControl} from 'react-bootstrap';
import {getLexemeProperties, getLexemeOptions, getDictionaryProperties} from './../../../LexemePage';
import {LexemeUtilities, ToolbarTypes, getContextTypes} from './../../../editor/LexemeHelper';
import {LexemeEditor} from './../../../editor/LexemeEditor';
import './LexemeSenses.css';
import './LexemeSemanticReferences.css';
import './../../../../../_css/lexeme.css';
import {LexemePageContext} from "../../../LexemePageContext";

export class LexemeSemanticReferences extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name : 'SEMANTIC_REFERENCE',
            item: {}
        };

        this._isActive = this._isActive.bind(this);
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);

        /*const additionalData = { ids : ids };

        return <Popover id="lex-semantic-unit-references-button-bar" bsClass={"popover"}
                        positionLeft={400}><LexemeEditor
            title={item.description}
            type={ToolbarTypes.SEMANTIC_UNIT_REFERENCES}
            item={item}
            additionalData={additionalData}
            form={""}/></Popover>;*/
    }


    _isActive(item) {
        return LexemeUtilities.isActive(this, item);
        /*if (item.ids == this.state.item.ids) {
            return "lexEditableActive";
        } else
            return "";*/
    }

    _handleClick(item) {

        this.setState({
            item: LexemeUtilities.handleClick(this, item)
        })
    }

    render() {

        var i = 0;

        return <div className={'semanticunit-references semanticunit-references-' + this.props.way}>
            <div className={'semanticunit-reference-img img-' + this.props.itemRef.type.toLowerCase()}/>
            { this.props.itemRef.semantic_unit_reference_list.map((item) =>
                <OverlayTrigger
                    rootClose={true}
                    key={"key-overlay-sem-unit-ref-" + this.props.itemRef.type + i++}
                    trigger="click"
                    placement="bottom"
                    overlay={this._createButtonBarTooltip(this.props.itemSense, item.ids)}
                    onClick={() => {
                        this._handleClick(item)
                    }}
                >
                    <div className={"semanticunit-reference " + LexemeUtilities.getLexClass(this) + " " + this._isActive(item) }>
                        <div className={"semanticunit-reference-text "} >{item.text}</div>
                        { <div className={"semanticunit-reference-langs"}>{ item.local_sense.map((itemLocal) =>
                            <div key={"key-sem-refs-" + itemLocal.id}>{itemLocal.language}</div>
                        )}
                        </div>}
                    </div>
                </OverlayTrigger>)}
        </div>
    }
}

LexemeSemanticReferences.contextType = LexemePageContext