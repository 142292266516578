import React from "react";
import {changeTab, getPage, getTabIndex, getTabs} from "./LexemePage";
import {Button} from "react-bootstrap";
import {LexemeEditingLevel, LexemeUtilities} from "./editor/LexemeHelper";
import FontAwesome from "react-fontawesome";
import ReactTooltip from "react-tooltip";
import Dict from "dict-lib";
import stylesLexemePage from "../../_css/dictionary/lexemePage.module.css";
import ReactDOM from "react-dom";
import SCRadiobutton from "../../dict-components/radiobutton/SCRadiobutton";
import {AdministrationUtilities} from "../../administration/AdministrationHelper";
import {LexemePageContext} from "./LexemePageContext";
import {LexemeSenses} from "./parts/03_Subentries/parts/LexemeSenses";
import BUtils from './../../utilities/BaseUtilities';

export class LexemeDescrLanguage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    _changeDescr(value) {
        if (this.context.descrLanguage !== value)
            LexemeUtilities.changeLangDescr(value);
    }

    render() {
        const langs = AdministrationUtilities.getActiveDescriptionLanguages(this.context.languageId);
        const choosenLang = langs.find((lang) => {
            return lang.choosen === true;
        });

        const showLabel = typeof this.context === 'undefined' || this.context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR;

        const choosenCode = typeof choosenLang !== 'undefined' ? choosenLang.code : null;

        const additionalClassDiv = this.context.classType === 'PHRASES' ? 'lexeme-descr-language-buttons--tight' : 'lexeme-descr-language-buttons--normal';

        return <div className={BUtils.joinClasses(stylesLexemePage['lexeme-descr-language-buttons'], stylesLexemePage[additionalClassDiv])}>
            <SCRadiobutton items={langs}
                           label={showLabel ? Dict.t(langs.length > 1 ? 'DESCRIPTION_LANGUAGES' : 'DESCR_LANGUAGE') + ":" : ''}
                           useLabels={'code'}
                           default={choosenCode} callbackParent={(value) => this._changeDescr(value)}/>
        </div>;
    }

}


LexemeDescrLanguage.contextType = LexemePageContext;