import React from 'react';
import Dict from 'dict-lib';
import {EditingStatusTypes} from './../LexemeHelper';
import {setEditingStatus} from './EntryActions';
import {getRootEntryId} from './../../LexemePage';
import {Grid, Button, Row, Col, Glyphicon} from 'react-bootstrap';
import stylesLexemeTitle from '../../../../_css/dictionary/lexemeParts/lexemeTitle.module.css';
/*import './../../../../_css/lexeme.css';*/

export class EntryEditingStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: getRootEntryId(),
            editingStatusTypes: Object.values(EditingStatusTypes)
        };

        this._handleChange = this._handleChange.bind(this);
    }

    componentWillMount() {

    }

    _handleChange(text) {
        setEditingStatus(this.state.id, text);
    }

    render() {

        const editingButtons = this.state.editingStatusTypes.map(item => {
            return <Col key={'key-col-editing-status-' + item.text} lg={4} md={4}>
                <Button className={stylesLexemeTitle['bt-entry-editing-status']} bsStyle={item.bsStyle} onClick={() => this._handleChange(item.text)}><Glyphicon glyph={item.glyphicon} /><span>{Dict.t(item.text)}</span></Button>
            </Col>
        });


        return <Grid className={stylesLexemeTitle['entry-editing-status']} fluid>
            <Row>
                {editingButtons}
            </Row>
        </Grid>;
    }

}
