import React from 'react';
import ReactDom from 'react-dom';
import {setPhrase} from './../../../editor/phrases/PhraseAction';
import Dict from 'dict-lib';
import {SCSimpleSoundPlayer} from './../../../../../dict-components/components';
import {OverlayTrigger, Popover, InputGroup, FormControl, Glyphicon, Row} from 'react-bootstrap';
import {
    LexemeUtilities,
    ToolbarTypes,
    getContextTypes,
    LexemeDisplayContext,
    LexemeEditingLevel, LexemePageInfos
} from './../../../editor/LexemeHelper';
import {LexemeEditor} from './../../../editor/LexemeEditor';
import {LexemeTranslation} from './LexemeTranslation';
import './LexemePhrase.css';
import './../../../../../_css/lexeme.css';
import {LexemePageContext} from "../../../LexemePageContext";

import stylesLexemePhrases from './../../../../../_css/dictionary/lexemeParts/lexemePhrases.module.css';
import BUtils from './../../../../../utilities/BaseUtilities';
import stylesLexemeSenses from "../../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css";

export class LexemePhrase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "PHRASE",
            item: {},
            sense: this.props.suItem
        }
    }

    componentWillMount() {
        /*this.props.suItem.phraseologies.map((item) => {
            fetch(item.multimedia_file.replace('http://localhost:8080', ''));
        });*/
    }

    componentWillReceiveProps(nextProps) {
        //if (!this.props.isPolyFromLexeme)
        this.setState({sense: nextProps.suItem});
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _isActive(item) {
        return LexemeUtilities.isActive(this, item);
    }

    _isEmpty(description) {
        return description.replace(/-/g, '').replace('(', '').replace(')', '').trim().length == 0 ? 'emptyAbs' : "";
    }

    _onExit(item) {
        if (this.state.item === item) {
            this.setState({
                item: ''
            })
        }
    }

    _handleClick(item, event) {
        if (!event.target.classList.contains(stylesLexemePhrases['lexTextEditable'])) {
            this.setState({
                item: LexemeUtilities.handleClick(this, item)
            });
        }
    }

    _phraseDescrClick(item) {
        var id = "popover-edit-text";
        if (LexemeUtilities.isPageEditable(this)) {
            return <Popover id="lex-phrase-descr-form" /*{...this.props}*/ className={id}>
                <EditPhrase text={item.text} id={item.id} sense={this.state.sense}
                            callbackParent={(newState) => this.props.callbackParent()}/>
            </Popover>;
        } else
            return LexemeUtilities.getHiddenPopover(id);
    }

    render() {

        var additionalClass = '';
        const isPolyFromLexeme = this.props.isPolyFromLexeme;

        const displasyContext = this.context.displayContext;
        const lextTextClass = LexemeUtilities.getLexTextClass(this);
        const lexClass = LexemeUtilities.getLexClass(this);
        const isEditable = LexemeUtilities.isPageEditable(this);
        const isLexeme = LexemePageInfos.getLexemeProperties(this.context, 'lexeme');

        if (isPolyFromLexeme) {
            additionalClass += " polySense";
        }

        const phraseList = this.props.suItem.phraseologies.map((item) => <div key={"key-phrase-" + item.id}
                                                                              className={stylesLexemePhrases["phrase-item"]  /*lexemePhrase-" + this.context.pageStatus.toLowerCase()*/}>
            <div
                className={BUtils.joinClasses(stylesLexemePhrases['phrase-view'], stylesLexemePhrases['phrase-view--' + lexClass])}>
                {isPolyFromLexeme ? <div className={stylesLexemePhrases['phrase-view__no-edit']}>&nbsp;</div> :
                    <OverlayTrigger
                        key={"key-phrase-" + item.id}
                        rootClose={false}
                        trigger={"click"}
                        onExit={() => this._onExit(item)}
                        placement={(LexemeUtilities.isPageEditable(this) ? "bottom" : "right")}
                        container={document.getElementById("modal-poly-edit")}
                        overlay={this._createButtonBarTooltip()}>
                        <div
                            className={stylesLexemePhrases[lexClass] + ' ' + stylesLexemePhrases['lexEmptyEditable'] + ' ' + stylesLexemePhrases['lexeme-phrase'] + ' ' + this._isActive(item)}
                            onClick={(event) => {
                                this._handleClick(item, event)
                            }}>{isEditable ? <><Glyphicon glyph="comment"/><Glyphicon glyph="triangle-right"/><Glyphicon
                            glyph="triangle-right"/><Glyphicon glyph="triangle-right"/></> : ''}</div>
                    </OverlayTrigger>}
            </div>
            <div
                className={BUtils.joinClasses(stylesLexemePhrases['phrase-view'], stylesLexemePhrases['phrase-view-descr'], (item.is_hidden ? 'hidden-item' : ''))}>
                {isPolyFromLexeme ? <div>{item.text}</div> : <OverlayTrigger
                    rootClose={true}
                    key={"key-overlay-sense-" + item.ids}
                    trigger={"click"}
                    placement="right"
                    container={document.getElementById("modal-poly-edit")}
                    overlay={isPolyFromLexeme ? "" : this._phraseDescrClick(item)}
                >
                    <div ref="phraseDescrRef" onClick={(event) => {
                        this._handleClick(item, event);
                    }}
                         className={stylesLexemePhrases['phrase-descr'] + " " + (isPolyFromLexeme ? "" : " " + stylesLexemePhrases[lextTextClass] + " ") + this._isEmpty(item.text) + " " + additionalClass}>{item.text}</div>
                </OverlayTrigger>}
                {typeof item.original_script !== 'undefined' && Object.keys(item.original_script).length > 0 ?
                    <div className="phrase-originalScript">{item.original_script}</div> : ""}
                <div
                    className="phrase-long-bibiographical-reference">{item.long_bibiographical_reference}</div>
                {(typeof item.bibliographical_reference !== "undefined" && item.bibliographical_reference).length > 0 ?
                    <div
                        className="phrase-bibliographicalReference">{item.bibliographical_reference}</div> : ""}
                {((item.sense_descr).split(',')).length > 1 ?
                    <div className={stylesLexemePhrases['phrase-sense-descr']}><Glyphicon glyph="hand-left"/><span
                        className="ph-sense-descr">{item.sense_descr}</span></div> : ""
                }
                {isLexeme ? <div className={stylesLexemePhrases['phrase-language']}>{item.language}</div> : ''}
                {typeof item.multimedia_file !== 'undefined' && Object.keys(item.multimedia_file).length > 0 ?
                    <div
                        className={stylesLexemePhrases['phrase-sense-descr']}>{this.context === LexemeDisplayContext.DICTIONARY_SEARCH ?
                        <SCSimpleSoundPlayer
                            src={item.multimedia_file /*+ '?v=' + new Date().getMilliseconds()*/}/> : ""}</div> : ""}
                {(typeof item.notes !== "undefined" && item.notes).length > 0 ?
                    <div className={stylesLexemePhrases['phrase-notes']}>{'note:' + item.notes}</div> : ""
                }
                <div className={BUtils.joinClasses("senseItem", "senseTransls", stylesLexemePhrases['phraseTransls'])} key={"key-ph-translations-" + item.ids}>
                    <LexemeTranslation suItem={item} isSense={false}
                                       isPolyFromLexeme={isPolyFromLexeme}/></div>
            </div>
        </div>);


        return <div className={stylesLexemePhrases['lexeme-phrase-list']}>{phraseList}</div>;
    }
}

LexemePhrase.contextType = LexemePageContext;

class EditPhrase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text,
            showComponent: true,
            id: this.props.id,
            sense: this.props.sense
        };

        this.textExist = false;

        this._handlePhraseAbs = this._handlePhraseAbs.bind(this);
        this._handleKeyUp = this._handleKeyUp.bind(this);
    }

    componentWillMount() {
        if (document.getElementById("lex-phrase-button-bar")) {
            document.getElementById("lex-phrase-button-bar").classList.remove("in");
        }
    }

    componentDidMount() {
        var x = window.scrollX, y = window.scrollY;
        ReactDom.findDOMNode(this.phraseText).focus();
        ReactDom.findDOMNode(this.phraseText).value = "";
        ReactDom.findDOMNode(this.phraseText).value = this.state.text;
        window.scrollTo(x, y);
    }


    _handlePhraseAbs(evt) {
        this.textExist = false;//evt.target.value.trim().length > 0 && (evt.target.value.toLowerCase() != this.props.text.toLowerCase());
        this.setState({text: evt.target.value});
    }

    _handleKeyUp(evt) {
        if (evt.keyCode === 13) {
            if (this.textExist) {
                LexemeUtilities.showModalMessage("error", Dict.t('PHRASE_FOUND'), 2000);
            } else {
                setPhrase(this.state.sense.ids, this.state.text, this.state.id);
                this._handleClose();
            }
        } else if (evt.keyCode === 27) {
            //this.props.callbackParent();
            this._handleClose();
        }
    }

    _handleMouseOut(evt) {
        this._handleClose();
    }

    _handleClose() {
        var el = document.getElementsByClassName("popover-edit-text")[0];
        el.classList.add("popover-edit-text-fade-out");

        setTimeout(function () {
            document.getElementsByClassName(stylesLexemePhrases['lexeme-phrase-list'])[0].click();
        }.bind(this), 400);
    }

    _isEmpty(text) {
        return text.replace(/-/g, '').trim().length === 0 ? 'emptyAbs' : "";
    }

    render() {

        return <div ref={"senseEditContainer"} id="sense-edit-container-id"
                    className={this.state.showComponent ? "sense-edit-container " : "sense-edit-hide"}
        >
            <InputGroup className={"sense-edit " + ""}><FormControl type="text"
                                                                    placeholder={this.props.text}
                                                                    ref={(input) => {
                                                                        this.phraseText = input;
                                                                    }}
                                                                    value={this._isEmpty(this.state.text) ? "" : this.state.text}
                                                                    onChange={(evt) => this._handlePhraseAbs(evt)}
                                                                    onKeyUp={this._handleKeyUp}/>
            </InputGroup>
        </div>
    }
}