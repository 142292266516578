import React from 'react';
import {setSubentries, setPolies} from './../../LexemePage';
import {LexemeUtilities} from './../LexemeHelper';
import Dict from 'dict-lib';


const updateSubs = ['SET_SUBENTRIES'];
var updateClasses = ['subentries'];

export const deleteSubentry = (ids, isPoly) => {
    LexemeUtilities.callResetTabPages('/subentries/deleteSubentry/' + ids + '/' + updateSubs, [isPoly ? 'modal-body' : 'lexemeSubentries monoSubentries']);
};

export const saveSubentry = (item) => {
    item = escape(encodeURIComponent(item));
    return LexemeUtilities.call('/subentries/saveSubentry/' + item + '/' + updateSubs);
};

export const addNewSense = (item, langList, text, isTree, isTranslation) => {

    var ids = "";

    if (JSON.parse(isTree)) {
        langList.map((item) => {
            ids += item.id_lang + "_";
        });

        ids = ids.substring(0, ids.length - 1);
    } else
        ids = langList;

    const path = '/subentries/addNewSense/' + item.ids + '/' + ids + '/' + text + '/' + updateSubs;

    return typeof isTranslation!=='undefined' && isTranslation ? LexemeUtilities.callSimple(path, 'SPINNER_MSG_SENSE_CREATING') : LexemeUtilities.callHighlight(path, 'su-ids-');
};

export var setSubentriesOrder = (ids, isPoly, polies) => {
    return LexemeUtilities.callNoClose('/subentries/setSubentriesOrder/' + ids + '/' + ['SET_SUBENTRIES', 'SET_SENSES']);
};