/*import { authHeader } from '../_helpers';*/
import Dict from 'dict-lib';
import {Notifications} from "../../../utilities/DictUtilities";
import {history} from "../history";

export const userService = {
    login,
    logout,
    getAll,
    isLogged,
    unloggedReload,
    getRoleByModule,
    getRoleByContext,
    isAdministrator
};

function login(username, password) {
    var credentials = {user: username, password: password};
    return Dict.call('/user/login/' + JSON.stringify(credentials) + '/').then(result => {
        if (result.login === 'OK') {
            localStorage.setItem('user', result.user/*JSON.stringify(result.user)*/);
            localStorage.setItem('su', result.su);
            localStorage.setItem('encode', result.encode);
            localStorage.setItem('userRoles', JSON.stringify(result.roles));
            return result.user;
        } else {
            const error = 'error';
            history.push(/login/);
            return Promise.reject(error);
        }
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('su');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('encode');
    return Dict.call('/user/logout').then(result => {

    });

}

function getAll() {
    /* const requestOptions = {
         method: 'GET',
         headers: authHeader()
     };*/
    return "baddi"; // fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function isLogged() {
    return typeof localStorage.user !== 'undefined' && localStorage.userRoles !== 'undefined' && localStorage.length > 0;
}

function notLoggedHasLoggedIcon() {
    return !isLogged() && document.getElementById("sc-nav-dropdown") !== null;
}

function getRoles() {
    return JSON.parse(localStorage.userRoles);
}

function getRoleByModule(module) {
    var role = getRoles()[module];
    return (role.length > 0 ? role : [{label: Dict.t('NOT_SET')}]);
}

function getRoleByContext(context) {
    return getRoleByModule(context.module);
}

function isAdministrator() {
    return isLogged() && localStorage.su === 'true';
}

function unloggedReload() {
    if (notLoggedHasLoggedIcon()) {
        window.location.reload();
    }
}
