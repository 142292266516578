import React from 'react';
import ReactDOM from 'react-dom';
import {LexemePageContent, getLexemeProperties, getDictionaryProperties} from './../../lexeme/page/LexemePage';
import {LexemeEditingLevel, LexemePageInfos, LexemeUtilities} from './../../lexeme/page/editor/LexemeHelper';
import {Grid, Row, Col, Button} from 'react-bootstrap';
import Dict from 'dict-lib';
import {EntryTypeahead, SCRadiobutton, SCSubmitButton} from './../components';
import './LexemeNavigator.css';
import LexemePage from "./../../lexeme/page/LexemePage";

import stylesLexemeNavigator from './../../_css/dictionary/lexemeNavigator.module.css';
import BUtils from './../../utilities/BaseUtilities';
import {LexemePageContext} from "../../lexeme/page/LexemePageContext";
import {LexemeTitle} from "../../lexeme/page/parts/01_Title/LexemeTitle";

export class LexemeNavigator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rootEntryId: "",
            languageId: this.props.languageId,
            dictionaryType: "",
            transLangs: [],
            searchOn: {create: false, homographic: false, text: ''}
        };

        this.container = typeof this.props.container !== 'undefined' && false ? this.props.container : 'lexeme-navigator-page-container';

        this._setRootEntryId = this._setRootEntryId.bind(this);
        this._handleOnFocus = this._handleOnFocus.bind(this);
        this._handleRadioTradChange = this._handleRadioTradChange.bind(this);
        this._handleCreate = this._handleCreate.bind(this);
    }

    componentWillMount() {
        if (this.props.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR) {
            var oppositeLangsArray = this.props.context.oppositeLanguages;
            var transLangs = [];
            var languageId = oppositeLangsArray[0].id;

            for (var key in oppositeLangsArray) {
                transLangs.push({
                    code: oppositeLangsArray[key].id,
                    label: (Dict.t("TRANSLATIONS") + " (" + oppositeLangsArray[key].code + ")"),
                    variant: oppositeLangsArray[key].id,
                    permission: {
                        isAllowed: true,
                        msg: ''
                    }
                });
            }

            this.setState({transLangs, languageId});
        } else {
            var languageId = this.props.languageId;
            if (typeof languageId === 'undefined')
                languageId = getLexemeProperties('language_id');

            var dictionaryType = typeof this.props.useInGlobalDictionary !== 'undefined' && this.props.useInGlobalDictionary ? 'TYPE_1_IN' : getDictionaryProperties('type');

            this.setState({languageId, dictionaryType});
        }
    }

    _handleRadioTradChange(languageId) {
        this.setState({languageId});
        ReactDOM.render(<div>&nbsp;</div>, document.getElementById("lexeme-navigator-page-container"));
    }

    _setRootEntryId(newState) {
        if (newState.length !== 0) {
            if (typeof this.props.isStandAlone !== 'undefined' && this.props.isStandAlone) {
                Dict.call('/page/getPage/' + newState.id + '/' + this.state.languageId + '/' + this.props.dictType).then(result => {
                const StandAloneLexemePageConst = <LexemePageContent key={"key-lexeme-read-move-sense-" + newState.id}
                                                                     page={result.page} pageStatus={this.props.pageStatus}
                                                                     classType={this.props.classType}
                                                                     startItem={this.props.startItem}
                                                                     status={'SET_PAGE'}
                                                                     callBackSelectionHandler={(selectableItem) => this._callBackSelectionHandler(selectableItem)}/>;
                ReactDOM.render(StandAloneLexemePageConst, document.getElementById(this.container));
                });
            } else {
                const dictType = this.props.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR ? 'TYPE_1_OUT' : this.state.dictionaryType;
                Dict.call('/page/getPage/' + newState.id + '/' + this.state.languageId + '/' + dictType).then(result => {
                    const LexemePageConst = <LexemePageContent key={"key-lexeme-read-move-sense-" + newState.id}
                                                               page={result.page} pageStatus={this.props.pageStatus}
                                                               startItem={this.props.startItem}
                                                               status={'SET_PAGE'}
                                                               callBackSelectionHandler={(selectableItem) => this._callBackSelectionHandler(selectableItem)}/>;
                    ReactDOM.render(LexemePageConst, document.getElementById(this.container));
                    this.setState({rootEntryId: newState.id});
                });
            }
        } else {
            if (typeof this.props.callBackResetLexeme !== 'undefined')
                this.props.callBackResetLexeme();
            ReactDOM.render(<div/>, document.getElementById(this.container));
        }

        this._callBackSelectionHandler('');
    }

    _handleCreate() {
        Dict.call('/entry/createLexeme/' + this.state.searchOn.text + '/' + this.state.languageId + '/' + 'ENTRY' + '/').then((result) => {
            this._setRootEntryId({label: this.state.searchOn.text, id: result.id});
        });
    }

    _callBackSelectionHandler(selectableItem) {
        this.props.callbackParent(selectableItem);
    }

    _handleOnFocus() {
        if (this.state.rootEntryId.length > 0)
            this.setState({rootEntryId: "", subEntryId: ""});
    }

    render() {
        const legend = "seleziona lemma " + this.props.pageStatus;
        const isTranslator = this.props.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR;

        var colCreate = '';
        var colTypeAhead = '';
        var colTransLangs = '';

        if (!isTranslator) {
            colCreate = 0;
            colTypeAhead = 12;
            colTransLangs = 0;
        } else {
            if (this.state.searchOn.homographic === true) {
                colCreate = 2;
                colTypeAhead = 6;
                colTransLangs = 4;
            } else {
                colCreate = 1;
                colTypeAhead = 6;
                colTransLangs = 5;
            }
        }


        return (
            <Grid className={stylesLexemeNavigator['container-lexeme-navigator']} fluid>
                <fieldset>
                    <legend>{legend}</legend>
                    <Col lg={colCreate} md={colCreate} className={stylesLexemeNavigator['col-create-button']}>
                        {isTranslator && this.state.searchOn.create ?
                            <SCSubmitButton onClick={() => this._handleCreate()}
                                            text={Dict.t(this.state.searchOn.homographic ? 'CREATE_HOMOGRAPHIC' : 'CREATE')}/>
                            : ''}
                    </Col>
                    <Col lg={colTypeAhead} md={colTypeAhead}>
                        <Row id={"row-entry-typeahead"}>
                            <EntryTypeahead key={"key-eth-navigator"} languageId={this.state.languageId}
                                            onFocus={this._handleOnFocus}
                                            useCache={Object.keys(this.state.transLangs).length === 1}
                                            callbackParent={(newState) => this._setRootEntryId(newState)}
                                            callbackParentSearchOn={(create, homographic, text) => {
                                                var searchOn = {create, homographic, text};
                                                this.setState({searchOn})
                                            }}
                            />
                        </Row>
                    </Col>
                    <Col lg={colTransLangs} md={colTransLangs}>{isTranslator ? <SCRadiobutton
                        key={"key-radio-entry-sort-types-" + this.state.key}
                        bsStyle="default"
                        className="col-lg-4-35"
                        items={this.state.transLangs}
                        callbackParent={(newState) => this._handleRadioTradChange(newState)}/> : ""}
                    </Col>

                </fieldset>
                <Grid id="lexeme-navigator-page-container"
                      className={stylesLexemeNavigator['lexeme-navigator-page-container']} fluid/>
            </Grid>
        );
    }
}





