import React from 'react';
import Dict from 'dict-lib';
import {getLexemeProperties} from '../../LexemePage';
import {Grid, Col, Row} from 'react-bootstrap';
import './../../../../_css/lexeme.css';


export class TranslationExtendedView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            extendedView: {}
        }
    }

    componentWillMount() {
        Dict.call('/translations/getExtendedView/' + this.props.item.id + '/' + getLexemeProperties("language_id") + '/').then(result => {
            const extendedView = result.extendedView;
            /*var item
            var langs = "";*/

            for (var key in extendedView) {
                //console.log(key);
                extendedView[key].map((item) => {
                    //console.log(item);
                });
            }

            this.setState({extendedView});
        });
    }

    render() {

        var items = [];
        if (Object.keys(this.state.extendedView).length > 0) {
            for (var key in this.state.extendedView) {
                var descr = key + " <small>(";
                this.state.extendedView[key].map((item) => {
                    descr += item + ", ";
                });
                descr = descr.substring(0, descr.length - 2) + ")</small>";
                items.push(descr);
            }
        }

        const listDescr = items.length > 0 ? items.map(item => <Row key={"key-tr-extended-view-row"} className={"tr-extended-view-row"} dangerouslySetInnerHTML={{__html: item}} />) : "";

        return <Grid fluid className={'translation-extended-view'}>
            <Col>{listDescr}</Col>
        </Grid>;
    }


}
