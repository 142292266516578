import React, {PureComponent} from 'react';
import {Grid, Row, Button, ButtonToolbar, ButtonGroup, Glyphicon} from 'react-bootstrap';
import Dict from 'dict-lib';
import {getLexemeProperties, openPage} from '../LexemePage';
import '../parts/02_Data/LexemeData.css';
import './../../../_css/lexeme.css';
import {SCPopup} from './../../../dict-components/components';
import {
    LexemeUtilities,
    ToolbarTypes,
    getContextTypes,
    LexemeEditingLevel,
    TranslatableEntities,
    translate
} from './LexemeHelper';
import update from 'immutability-helper';
import {LexemePageContext} from "../LexemePageContext";

import stylesLexemeData from './../../../_css/dictionary/lexemeParts/lexemeData.module.css';
import LexemePage from "../LexemePage";
import ReactDOM from "react-dom";


export class LexemeEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            form: this.props.form,
            id: this.props.item.id,
            title: this.props.title,
            type: this.props.type,
            width: 0,
            height: 0,
            show: false,
            isOpen: false,
            additionalData: this.props.additionalData
        };

        this.x = 0;
        this.y = 0;

        this._setPopup = this._setPopup.bind(this);
        this._open = this._open.bind(this);
        this._handleClosePopup = this._handleClosePopup.bind(this);
    }

    // per i popup che non si devono chiudere né aggiornare (tipo ordina o aggiungi link)
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps === this.props;
    }

    _setPopup(item) {

        var target = document.getElementsByClassName("lexEditableActive")[0];

        var update = this.state;
        update.show = update.isOpen || !update.show;
        update.isOpen = update.show;
        update.width = item.width > 0 ? item.width : 400;
        update.height = item.height > 0 ? item.height : 400;
        update.minWidth = item.minWidth;
        update.minHeight = item.minHeight;
        update.additionalPopupClass = item.additionalPopupClass;

        update.operation = item;

        const lexemeData = document.getElementsByClassName(stylesLexemeData['lexemeData'])[0];
        /*const lexemeDataContainer = document.getElementsByClassName('lexemeDataContainer')[0];*/

        if (target.offsetLeft + (item.width / 2) + 90 > lexemeData.offsetWidth) {
            this.x = -(item.width + 10);
        } else
            this.x = -50;

        this.y = typeof item.y == 'undefined' ? "70" : item.y;
        this.x = typeof item.x == 'undefined' ? "70" : item.x;

        return update;
    }

    _handleClosePopup() {
        var update = this.state;
        update.show = false;
        update.isOpen = false;
        update.operation = '';
        this.setState(update);
    }

    _handleToolbarActions(action, operation) {
        if (action === 'open') {
            this._open(operation);
        } else {
            this._handleClosePopup();
        }
    }

    _open(item) {
        var update = this._setPopup(item);
        update.component = item.component;
        this.setState(update);
    }

    render() {

        var additionalData = this.props.additionalData;

        if (typeof additionalData === 'undefined')
            additionalData = {};
        additionalData.isPolyEdit = this.props.isPolyEdit;
        additionalData.polyItem = this.props.polyItem;

        const Popup = () => this.state.show ? <SCPopup {...this.state} {...this.props} x={this.x}
                                                       y={this.y}
                                                       callbackParent={() => this._handleClosePopup()} /*callbackParent={(coord) => this._handlePopupMove(coord)} */ /> :
            <div/>;

        return <div>
            <Popup/>
            <LexemeToolbar {...this.state} additionalData={additionalData} context={this.props.context}
                           name={this.props.name}
                           callbackParent={(action, operation) => this._handleToolbarActions(action, operation)}/>
        </div>
    }
}

class LexemeToolbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hoverOp: ''
        };

        this._open = this._open.bind(this);
        this._isActive = this._isActive.bind(this);
        this._textButton = this._textButton.bind(this);
    }

    componentWillMount() {

        var newState = this.state;
        const upd = update(newState, {$merge: this.props});
        this.setState(upd);
    }

    componentWillReceiveProps() {
        var update = this.state;
        update.isOpen = !this.props.isOpen;
        this.setState(update);
    }

    _open(item) {
        if (typeof item.command === 'undefined') {
            var isOpen = this.state.isOpen;
            if (!isOpen || item.operation !== this.state.operation) {
                this.props.callbackParent('open', item);
                //isOpen = true;
            } else {
                this.props.callbackParent('close', item.operation);
                //isOpen = false;
            }

            var update = this.state;
            update.operation = item.operation;
            update.isOpen = isOpen;
            this.setState(update);
        } else if (item.operation === 'OPEN_TAB') {
            const dictType = undefined;
            const pageStatus = this.props.context.pageStatus;
            const addData = this.props.additionalData;
            const entryId = item.command === 'OPEN_TAB' ? addData.entry_id : addData.id;

            LexemeUtilities.closeAfterSave();

            Dict.call('/page/getLexemePageData/' + entryId + '/' + dictType + '/').then(result => {
                LexemeUtilities.openLexemePage(result.id, result.rootEntryId, true, pageStatus, result.entryForm, dictType, result.entryAbstr, result.language, result.languageCode);
            });
        }
    }

    _textButton(text) {
        var update = this.state;
        update.hoverOp = text;
        this.setState(update);
    }

    _isActive(operation) {
        return operation === this.state.operation ? "active" : "";
    }

    _setStatus(status) {
        if (typeof status === 'undefined') {
            return "operationToDo";
        } else if (status === 'NOT_FINISHED')
            return "operationNotFinished";
        else
            return "";
    }


    render() {

        var additionalData = this.props.additionalData;
        additionalData.is_tree_model = getLexemeProperties('is_tree_model');

        var toolbar = LexemeUtilities.retrieveLexemeToolbar(this.props.type, this.props.item, this.props.context, additionalData);

        var descr = typeof this.state.hoverOp === 'undefined' || this.state.hoverOp.length === 0 ? '' : Dict.t(this.state.hoverOp);

        var Buttons = toolbar.filter(function (item) {
            return item.active;
        }).map((item) => <Button key={'lex-toolbar-' + item.operation}
                                 onClick={() => this._open(item)}
                                 onMouseOver={() => {
                                     this._textButton(item.operation)
                                 }}
                                 className={"tooltipButton " + this._isActive(item.operation) + " " + this._setStatus(item.status)}>
            <Glyphicon glyph={item.glyph}/></Button>);


        if (this.props.context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR && Object.values(TranslatableEntities).includes(this.props.name)) {
            const item = LexemeUtilities.retrieveTranslationButtons(this);
            const lexItem = this.props.item;
            var arriveIds;

            if (this.props.type === ToolbarTypes.ENTRY || this.props.type === ToolbarTypes.POLYRHEMATIC) {
                var subentries = lexItem.subentries;
                if (subentries.length > 0) {
                    var senses = subentries[0].senses;
                    if (senses.length > 0) {
                        arriveIds = senses[0].ids;
                    }
                }
            } else if (this.props.type === ToolbarTypes.SUBENTRIES) {
                var senses = lexItem.senses;
                if (senses.length > 0) {
                    arriveIds = senses[0].ids;
                }
            } else if (this.props.type === ToolbarTypes.PHRASES) {
                arriveIds = this.props.additionalData.phraseItem.id;
            } else
                arriveIds = item.arriveIds;

            Buttons.push(<Button key={'lex-toolbar-' + item.operation}
                                 onClick={() => translate(item.startItem, arriveIds)}
                                 onMouseOver={() => {
                                     this._textButton(item.operation)
                                 }}
                                 className={"tooltipButton " + this._isActive(item.operation) + " " + this._setStatus(item.status)}>
                <Glyphicon glyph={item.glyph}/></Button>);
        }

        return <Grid className={"tooltipGrid"}>
            <Row className={"tooltipRowDescr"}>{descr}</Row>
            <Row>
                <ButtonToolbar>
                    <ButtonGroup bsSize={"small"}>
                        {Buttons}
                    </ButtonGroup>
                </ButtonToolbar>
            </Row>
        </Grid>
    }
}

