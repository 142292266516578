exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\r\n.app-module__DictionaryApp-DICTIONARY___2r0bn {\r\n\r\n}\r\n\r\n.app-module__DictionaryApp-TREE___gE7Vs {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.app-module__App-body-DICTIONARY___ccQUT {\r\n    height: 79% !important;\r\n}\r\n\r\n.app-module__App-body-TREE___11rNb {\r\n    flex: 1;\r\n}", ""]);

// Exports
exports.locals = {
	"DictionaryApp-DICTIONARY": "app-module__DictionaryApp-DICTIONARY___2r0bn",
	"DictionaryApp-TREE": "app-module__DictionaryApp-TREE___gE7Vs",
	"App-body-DICTIONARY": "app-module__App-body-DICTIONARY___ccQUT",
	"App-body-TREE": "app-module__App-body-TREE___11rNb"
};