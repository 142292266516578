import React from 'react';
import ReactDOM from 'react-dom';
import './AdministrationPage.css';
import {Modal, Button, Tabs, Tab, Row, Col, Nav, NavItem, Glyphicon} from 'react-bootstrap';
import {I18nPage} from './i18n/I18nPage';
import {Options} from './options/Options';
import {Properties} from './properties/Properties';
import Dict from 'dict-lib';
import {Users} from "./users/Users";
//import Dict from './dict-lib/main';


export class AdministrationPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            showModal: true
        });

        this._close = this._close.bind(this);
    }

    _close() {
        this.setState({showModal: false}, function () {
            this.emptyModalContainer();
        });
    }

    emptyModalContainer() {
        setTimeout(function () {
            ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
        }, 500);
    }

    render() {

        const glyph = <Glyphicon glyph="chevron-right"/>;

        return <Modal id="dict-administration-page" className='baddone' show={this.state.showModal} onHide={this._close} restoreFocus>
            <Modal.Header closeButton>
                <Modal.Title>{Dict.t('ADMINISTRATION')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container id="dict-administration-left-tabs" onSelect={this._handlePagination}
                               defaultActiveKey={typeof this.props.defaultActiveKey === 'undefined' ? "properties" : this.props.defaultActiveKey}>
                    <Row className="clearfix">
                        <Col id="dict-administration-left-col" lg={2} sm={3}>
                            <Nav bsStyle="pills" stacked>
                                <NavItem eventKey="options">
                                    {Dict.t('OPTIONS')} {glyph}
                                </NavItem>
                                <NavItem eventKey="properties">
                                    {Dict.t('PROPERTIES')} {glyph}
                                </NavItem>
                                <NavItem eventKey="i18n">
                                    i18n {glyph}
                                </NavItem>
                                <NavItem eventKey="users">
                                    {Dict.t('USERS')} {glyph}
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col id="dict-administration-right-col" lg={10} sm={9}>
                            <Tab.Content animation>
                                <Tab.Pane eventKey="options">
                                    <Options/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="properties">
                                    <Properties defaultActiveTab={this.props.defaultActiveTab} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="i18n">
                                    <I18nPage />
                                </Tab.Pane>
                                <Tab.Pane eventKey="users">
                                    <Users />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this._close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}