import React from 'react';
import Dict from 'dict-lib';
import {assignMoveSense} from './PhraseAction';
import {Grid, Row, Button} from 'react-bootstrap';
import {getLexemeProperties} from './../../LexemePage';
import {LexemeEditingLevel} from './../../editor/LexemeHelper';
import {LexemeNavigator, SCSubmitButton} from './../../../../dict-components/components';
import {SubEntryCombo} from './../../../../dict-components/components';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css'
import './../../../../_css/lexeme.css';

import stylesLexemeSenses from "../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css";
import stylesLexemePhrases from "../../../../_css/dictionary/lexemeParts/lexemePhrases.module.css";


export class PhraseAssign extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            senseToAssignId: ""
        };

        this._assignSense = this._assignSense.bind(this);
        this._selectSense = this._selectSense.bind(this);
    }

    _assignSense() {
        if (this.state.senseToAssignId.length > 0) {
            this.setState({btDisabled: true});
            assignMoveSense(this.props.item.ids, this.state.senseToAssignId, this.props.additionalData.phraseItem.id, this.props.isMove).then(result => {

            });
        }
    }

    _selectSense(senseToAssignId) {
        this.setState({senseToAssignId});
    }

    render() {

        return <Grid className={stylesLexemePhrases['phrase-move-grid']} fluid>
            <LexemeNavigator pageStatus={LexemeEditingLevel.SENSE_SELECTOR} callbackParent={(selectableItem) => this._selectSense(selectableItem.ids)} />
            <Row className={stylesLexemeSenses['row-bt-sense-assign']}>
                <SCSubmitButton onClick={(event) => this._assignSense()} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>
    }
}