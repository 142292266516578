import React from 'react';
import ReactDom from 'react-dom';
import Dict from 'dict-lib';
import {savePhrase} from './SenseAction';
import {getLexemeProperties, getLexemeOptions} from './../../../page/LexemePage';
import {LexemeUtilities, LexemeEditingLevel, translate, getContextTypes} from './../../../page/editor/LexemeHelper';
import {Typeahead} from 'react-bootstrap-typeahead';
import {LanguageTypeahead, SCSubmitButton} from './../../../../dict-components/components';
import {Grid, Row, Col, InputGroup, FormControl, Button, Label} from 'react-bootstrap';
import './../../../../_css/lexeme.css';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';

export class SenseEditAddPhrase extends React.Component {

    constructor(props) {
        super(props);

        const isTranslatorSelector = typeof this.props.context !== 'undefined' && this.props.context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR;

        this.state = {
            item: this.props.item,
            isTranslationSelector: isTranslatorSelector,
            biblioReferences: [],
            langId: "",
            isAssign: false,
            assignInfos: "",
            phrase: {
                language: "",
                text: "",
                original_script: "",
                sense_descr: "",
                bibliographical_reference: "",
                long_bibiographical_reference: ""
            }
        };

        this.isTree = this.props.context.properties.tree_model;
    }

    componentWillMount() {
        // TODO: nel primo caso dovrebbe esserci un boolean isEditPhraseResult
        var langId = this.isTree ? '' : this.props.context.languageId;//lexeme ? "" : this.props.item.local_senses[0].language_id;
        if (typeof langId !== 'undefined' && langId.length > 0) {
            Dict.call("service/phraseology/getBibliographicalReferences/" + this.props.context.languageId + "/").then(result => {
                var phrase = {};

                if (typeof this.props.additionalData !== 'undefined' && typeof this.props.additionalData.phraseItem !== 'undefined') {
                    phrase = JSON.parse(JSON.stringify(this.props.additionalData.phraseItem));
                    langId = phrase.language_id;
                }

                this.setState({biblioReferences: result.items, langId, phrase});
            });
        }
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.phraseText).focus();
    }

    _handleModernPhrase(evt) {
        if (this.state.isAssign)
            this.setState({isAssign: false});
        this._updateAddPhraseByValue("text", evt.target.value);
    }

    _handleOriginalPhrase(evt) {
        this._updateAddPhraseByValue("original_script", evt.target.value);
    }

    _handleLongRefPhrase(evt) {
        this._updateAddPhraseByValue("long_bibiographical_reference", evt.target.value);
    }

    _updateAddPhraseByValue(property, value) {
        var phrase = this.state.phrase;
        phrase[property] = value;
        this.setState({phrase});
    }

    _handlePhraseLanguage(item) {
        this.setState({langId: item});
    }

    _handleBibliographicalReference(item) {
        var phrase = this.state.phrase;
        phrase.bibliographical_reference = typeof item[0] !== 'undefined' ? item[0].label : "";

        this.setState(phrase);
    }

    _savePhrase(isAssign) {
        //if (typeof this.state.langId !== 'undefined' && (this.state.langId + "").length > 0) {
        var phrase = JSON.stringify(this.state.phrase);

        this.setState({btDisabled: true});
        savePhrase(this.state.item, this.state.langId, phrase, isAssign, this.state.isTranslationSelector).then(result => {
            this.setState({
                btSaveState: result.result === 'WARNING' || result.result === 'WARNING_STEP_FURTHER' ? "warning" : "success",
                btDisabled: false,
                isAssign: result.result === 'WARNING_STEP_FURTHER',
                assignInfos: result.additionalInfos
            }, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });

            if (this.state.isTranslationSelector && result.result === 'OK') {
                var newLexSenseIds = JSON.parse(result.additionalInfos).newLexPhraseId;
                translate(this.props.context.startItem, newLexSenseIds);
            }
        });
        //}
    }

    _keyUpFocusOrSave(evt, node) {
        LexemeUtilities.handleKeyUp(evt, () => {
            if (typeof node !== 'undefined')
                ReactDom.findDOMNode(node).focus();
            else
                this._savePhrase(this.state.isAssign);
        })
    }

    render() {

        var biblRef = this.state.phrase.bibliographical_reference;
        var selectedBiblRef = typeof biblRef !== 'undefined' && (this.state.biblioReferences).length > 0 ? LexemeUtilities.getTypeaheadItemByLabel(this.state.biblioReferences, biblRef) : "";

        return <Grid fluid>
            {this.isTree ? <Row className={stylesLexemeSenses['sense-add-phrase__row']}>
                <Col xs={2} md={2}
                     className={stylesLexemeSenses['sense-add-phrase-typeahead__label']}>{Dict.t('PLACE')}</Col>
                <Col xs={10} md={10} style={{paddingRight: 0}}
                     className={stylesLexemeSenses['sense-add-phrase__col-typeahead']}>
                    {(typeof this.props.additionalData === 'undefined') ?
                        <LanguageTypeahead ref="addPhraseTypehead"
                            /*selected={this.state.phrase.language}*/
                                           callSearch={"service/getLanguageDescendants/" + this.state.item.ids + "/" + true + "/"}
                                           callbackParent={(newState) => this._handlePhraseLanguage(newState)}/> : this.state.phrase.language}
                </Col>
            </Row> : ""}
            <Row className={stylesLexemeSenses['sense-add-phrase__row']}>
                <InputGroup className={stylesLexemeSenses['sense-add-phrase__edit-group']}>
                    <InputGroup.Addon>{Dict.t(getLexemeOptions("phrase_original_script") ? 'MODERN_PHRASE' : 'PHRASE')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.phraseText = input;
                                 }}
                                 value={this.state.phrase.text}
                                 onChange={(evt) => this._handleModernPhrase(evt)}
                                 onKeyUp={(evt) => {
                                     this._keyUpFocusOrSave(evt, this.phraseOriginal);
                                 }}
                    />
                </InputGroup>
            </Row>
            {getLexemeOptions("phrase_original_script") ? <Row className={stylesLexemeSenses['sense-add-phrase__row']}>
                <InputGroup className={stylesLexemeSenses['sense-add-phrase__edit-group']}>
                    <InputGroup.Addon>{Dict.t('ORIGINAL_SCRIPT')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.phraseOriginal = input;
                                 }}
                                 value={this.state.phrase.original_script}
                                 onChange={(evt) => this._handleOriginalPhrase(evt)}
                                 onKeyUp={(evt) => {
                                     this._keyUpFocusOrSave(evt, this.phraseLong);
                                 }}
                    />
                </InputGroup>
            </Row> : ""}
            {getLexemeOptions("bibliographical_reference") ?
                <Row className={stylesLexemeSenses['sense-add-phrase__row']}>
                    <Col xs={4} md={4}
                         className={stylesLexemeSenses['sense-add-phrase-typeahead__label']}>{Dict.t('BIBLIOGRAPHICAL_REFERENCE')}</Col>
                    <Col xs={8} md={8} style={{paddingRight: 0}}
                         className={stylesLexemeSenses['sense-add-phrase__col-typeahead']}>
                        <Typeahead
                            clearButton
                            ref={"addBibReferenceTypehead"}
                            align={"justify"}
                            onChange={item => this._handleBibliographicalReference(item)}
                            options={this.state.biblioReferences}
                            placeholder={Dict.t('BIBLIOGRAPHICAL_REFERENCE')}
                            selected={selectedBiblRef}
                        />
                    </Col>
                </Row> : ""}
            {getLexemeOptions("bibliographical_reference") ?
                <Row className={stylesLexemeSenses['sense-add-phrase__row']}>
                    <InputGroup className={stylesLexemeSenses['sense-add-phrase__edit-group']}>
                        <InputGroup.Addon>{Dict.t('LONG_BIBLIOGRAPHICAL_REFERENCE')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                     ref={(input) => {
                                         this.phraseLong = input;
                                     }}
                                     value={this.state.phrase.long_bibiographical_reference}
                                     onChange={(evt) => this._handleLongRefPhrase(evt)}
                                     onKeyUp={(evt) => {
                                         this._keyUpFocusOrSave(evt);
                                     }}
                        />
                    </InputGroup>
                </Row> : ""}
            <Row className={"row-bt-sense-notes"}>
                {this.state.isAssign ?
                    <div><span
                        className={stylesLexemeSenses['add-phrase__assign-infos']}>{this.state.assignInfos}</span>
                        <SCSubmitButton onClick={() => this._savePhrase(this.state.isAssign)} text={Dict.t('ASSIGN')}/>
                    </div> : ""}
                {!this.state.isAssign ?
                    <SCSubmitButton onClick={() => this._savePhrase(this.state.isAssign)}
                                    text={Dict.t(this.state.isTranslationSelector ? 'ADD_AND_TRANSLATE' : 'SAVE')}/> : ""}
            </Row>
        </Grid>;
    }

}
