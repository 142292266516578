import React from "react";
import {changeTab, getPage, getTabIndex, getTabs} from "./LexemePage";
import {Button} from "react-bootstrap";
import {LexemeUtilities} from "./editor/LexemeHelper";
import FontAwesome from "react-fontawesome";
import ReactTooltip from "react-tooltip";
import Dict from "dict-lib";
import stylesLexemePage from "../../_css/dictionary/lexemePage.module.css";
import ReactDOM from "react-dom";

export class LexemeShiftButtons extends React.Component {
    constructor(props) {
        super(props);

        this.dictType = this.props.dictType;
        this.tabIndex = getTabIndex();
    }

    componentDidMount() {
        const idPostFix = this.props.idPostFix;

        this._resetShiftButtons(idPostFix);
    }

    _resetShiftButtons(idEntry) {
        const elContainer = document.getElementById('lexeme-shift-buttons-' + this.tabIndex);
        if(elContainer) {
            elContainer.style.display = 'none';
            Dict.call('/entry/getPreviousNextPage/' + idEntry + '/').then((result) => {
                if (Object.keys(result).length > 0) {
                    var previousTooltipId = 'previous-button-tooltip-id-' + this.tabIndex;
                    var nextTooltipId = 'next-button-tooltip-id-' + this.tabIndex;

                    const previous = this._createShiftButton(result.previous, true, previousTooltipId);
                    const next = this._createShiftButton(result.next, false, nextTooltipId);

                    const prevToolTip = this._createShiftButtonTooltip(result.previous, true, previousTooltipId);
                    const nextToolTip = this._createShiftButtonTooltip(result.next, false, nextTooltipId);

                    ReactDOM.render(<>{previous}{prevToolTip}{next}{nextToolTip}</>, document.getElementById('lexeme-shift-buttons-' + this.tabIndex), () => {
                        elContainer.style.display = 'block';
                    });
                }
            });
        }
    }

    _createShiftButton(resultObj, isPrevious, tooltipId) {
        if(typeof resultObj !== 'undefined' && Object.keys(resultObj).length > 0 && resultObj.result !== 'ERROR') {
            const id = resultObj.page.lexeme_properties.root_entry_id;
            const entryForm = resultObj.page.title.entry_form;

            return <Button bsStyle={'default'} onClick={() => {

                var foundTab = getTabs().findIndex((tab) => {
                    return parseInt(id) === tab.id && entryForm === tab.entryForm;
                });

                if (foundTab > -1) {
                    changeTab(getPage(), foundTab);
                } else {
                    LexemeUtilities.setPage(resultObj, true);
                    this._resetShiftButtons(id);
                }
            }}
                           ref={(input) => {
                               this.createButton = input
                           }}
                           data-tip
                           data-for={tooltipId}
            ><FontAwesome name={'arrow-' + (isPrevious ? 'left' : 'right')}/></Button>;
        } else
            return '';
    }

    _createShiftButtonTooltip(resultObj, isPrevious, id) {
        if(typeof resultObj !== 'undefined' && Object.keys(resultObj).length > 0) {
            return <ReactTooltip id={id} type={'info'} delayShow={500}>
            <span
                dangerouslySetInnerHTML={{__html: (Dict.t((isPrevious ? 'PREVIOUS' : 'NEXT') + '_LEXEME') + ': ' + resultObj.page.title.entry_form + '  -  ' + resultObj.page.entry_abstract)}}/>
            </ReactTooltip>;
        } else return '';
    }


    render() {
        return <div id={'lexeme-shift-buttons-' + this.tabIndex} className={stylesLexemePage['lexeme-shift-buttons']}/>;
    }

}