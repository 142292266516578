import React from 'react';
import {showHidePhrase} from './PhraseAction';
import Dict from 'dict-lib';
import {Button, Row, Col, Grid} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class PhraseShowHide extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id
        };

        this._showHidePhrase = this._showHidePhrase.bind(this);
    }

    _showHidePhrase() {
        showHidePhrase(this.props.item.ids, this.props.additionalData.phraseItem.id);
    }

    render() {
        return <Grid fluid>
            <Row>
                <Col lg={7} md={7} className="popup-label">{Dict.t(this.props.additionalData.phraseItem.is_hidden ? 'ASK_SHOW_PHRASE' : 'ASK_HIDE_PHRASE')}</Col>
                <Col lg={5} md={5}>
                    <SCSubmitButton onClick={this._showHidePhrase} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </Grid>;
    }

}
