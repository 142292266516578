import React from 'react';
import {moveSense} from './SenseAction';
import Dict from 'dict-lib';
import {Button, Row, Grid} from 'react-bootstrap';
import {LexemeEditingLevel} from './../../editor/LexemeHelper';
import {LexemeNavigator, SCSubmitButton} from './../../../../dict-components/components';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css';
import './../../../../_css/lexeme.css';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';

export class SenseMove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id,
            subentryToMoveId: "",
            rootEntryId: "",
            mantain: this.props.mantain
        };

        this._moveSense = this._moveSense.bind(this);
        this._selectCgr = this._selectCgr.bind(this);
    }

    _moveSense() {
        moveSense(this.props.item, this.state.subentryToMoveId, this.state.mantain);
    }

    _selectCgr(subentryToMoveId) {
        this.setState({subentryToMoveId});
    }

    render() {

        return <Grid className={stylesLexemeSenses['sense-move-grid']} fluid>
            <LexemeNavigator pageStatus={LexemeEditingLevel.SUBENTRY_SELECTOR} callbackParent={(selectableItem) => this._selectCgr(selectableItem.ids)} />
            <Row className={"row-bt-sense"}>
                <SCSubmitButton onClick={() => this._moveSense()} text={Dict.t(this.state.mantain ? 'COPY' : 'MOVE')}/>
            </Row>
        </Grid>;
    }


}
