import React from 'react';
import {showHideEntry} from './EntryActions';
import {getLexemeProperties} from './../../LexemePage';
import Dict from 'dict-lib';
import {Button, Row, Col, Grid} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";
import {LexemePageInfos} from "../LexemeHelper";


export class EntryShowHide extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id
        };

        this._showHideSense = this._showHideSense.bind(this);
    }

    _showHideSense() {
        showHideEntry(this.props.additionalData.isPoly ? this.props.item.ids : getLexemeProperties('root_entry_id'));
    }

    render() {

        const isPoly = this.props.additionalData.isPoly;
        const label = Dict.t(this.props.item.lexeme_properties.is_hidden ? 'ASK_SHOW_' + (isPoly ? 'POLY' : 'ENTRY')  : ('ASK_HIDE_' + (isPoly ? 'POLY' : 'ENTRY')));

        return <Grid fluid>
            <Row>
                <Col lg={9} md={9} className="popup-label">{label}</Col>
                <Col lg={3} md={3}>
                    <SCSubmitButton onClick={this._showHideSense} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </Grid>;
    }

}
