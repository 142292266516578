import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {toast} from 'react-toastify';
import {SCModalMessage, SCModalTopSpinner, SCRadiobutton, SCToastNotification} from './../dict-components/components';
import {DictionaryWizard} from "../search/DictionaryWizard";
import stylesSearchMenu from "../_css/dictionary/searchMenu.module.css";
import stylesTrash from "../dict-components/trash/SCTrash.module.css";

export var DictionarySettings = {
    openWizard: function () {
        ReactDOM.render(<DictionaryWizard show={true}/>, document.getElementById('dict-modal-container'));
    },

    getRootLanguages: function () {
        return Dict.getVarious('root_languages');
    },

    getActiveLanguages: function () {
        return this.getRootLanguages().filter((langItem) => {
            return langItem.is_active === true;
        })
    },

    getActiveLanguageOptions: function () {
        var languageOptions = Dict.getLanguageOptions();
        const activeLangs = this.getActiveLanguages();
        Object.keys(languageOptions).forEach(languageOption => {
            var active = activeLangs.find(activeLang => {
                return activeLang.id + '' === languageOption.replace("languageOptions_", "");
            });

            if (typeof active === 'undefined')
                delete languageOptions[languageOption];
        });

        return languageOptions;
    },

    getAllCgrs: function (callback) {
        const dictCall = (url) => Dict.call(url);

        var root_Languages = Dict.getVarious('root_languages');
        var services = root_Languages.map((language) => {
            return ('service/getLangDescrToCgrs/' + language.id);
        });

        const promiseArray = services.map(dictCall);

        return Promise.all(promiseArray);
    },

    useToponomastic: function () {
        return Dict.getGlobalProperty("TOPONOMASTIC") === "true";
    }
};

export var DictionaryHelper = {
    getRootLanguagesSCRadio: function (id, callBack) {
        return <SCRadiobutton key={"key-radio-languages"} bsStyle="info"
                              id={id}
                              items={Dict.getVarious('root_languages').filter((langItem) => {
                                  return langItem.is_active === true;
                              }).map((item) => {
                                  return {label: item.label, code: item.id + ''};
                              })}
                              additionalClass={stylesSearchMenu['dict-languages-radio']}
                              callbackParent={(newState) => {
                                  callBack(newState);
                              }}/>
    }
};

export var Notifications = {

    showToastNotification: function (message, duration, timeout) {
        setTimeout(function () {
            toast(<SCToastNotification text={message}/>, {
                autoClose: duration > 0 ? duration : false,
                hideProgressBar: true
            })
        }, timeout);
    },

    closeAllToastNotification: function () {
        toast.dismiss();
    },

    isToastActive: function () {
        return document.getElementsByClassName('sc-toast-container').length > 0;
    },

    isToastActiveByNum: function (n) {
        var length = document.getElementsByClassName('sc-toast-container').length;
        return length <= n;
    },

    showErrorModalMessage: function (message, timeout) {
        this.showModalMessage('error', message, timeout)
    },

    showWarningModalMessage: function (message, timeout) {
        this.showModalMessage('warning', message, timeout)
    },

    showModalMessage: function (type, message, timeout) {

        if ((typeof timeout === 'number' && timeout === 0) || typeof timeout === 'undefined') {
            timeout = 5000;
        }

        if (typeof message === 'string' && message.startsWith('ignore:'))
            message = message.replace('ignore:', '');
        else if ((typeof message === 'string') && type !== 'downloadable-html' && !message.startsWith("_") && !message.endsWith("_"))
            message = Dict.t(message);

        ReactDOM.render(<SCModalMessage show={true} type={type}
                                        msg={message}/>, document.getElementById('dict-modal-container'));

        if(timeout >= 0) {
            setTimeout(function () {
                ReactDOM.render(<SCModalMessage show={false} type={type}
                                                msg={message}/>, document.getElementById('dict-modal-container'));
            }, timeout);
        }

    },

    closeModalMessage: function (type, message) {
        ReactDOM.render(<SCModalMessage show={false} type={type}
                                        msg={message}/>, document.getElementById('dict-modal-container'));
    },

    showModalMultiMessage: function (type, message, timeout) {
        if (timeout <= 0 || typeof timeout === 'undefined') {
            timeout = 5000;
        }

        if (!message.startsWith("_") && !message.endsWith("_"))
            message = Dict.t(message);

        ReactDOM.render(<SCModalMessage show={true} type={type}
                                        msg={message}/>, document.getElementById('dict-modal-container'));

        setTimeout(function () {
            ReactDOM.render(<SCModalMessage show={false} type={type}
                                            msg={message}/>, document.getElementById('dict-modal-container'));
        }, timeout);
    },

    showModalSpinner: function (message, additionalClass) {
        ReactDOM.render(<SCModalTopSpinner show={true} msg={message}
                                           additionalClass={additionalClass}/>, document.getElementById('dict-modal-container'));
    },

    closeModalSpinner: function () {
        ReactDOM.render(<SCModalTopSpinner show={false} msg={''}/>, document.getElementById('dict-modal-container'));
    },

    closeModalSpinnerMsg: function (message, additionalClass) {
        ReactDOM.render(<SCModalTopSpinner show={false} msg={message}
                                           additionalClass={additionalClass}/>, document.getElementById('dict-modal-container'));
    },

    buildDropDeleteInfo(type, params, additional) {
        return JSON.stringify({
            type,
            params,
            additional
        });
    }
};

export var Activations = {
    showTrash: function () {
        document.getElementById('dictionary-trash-container').classList.add(stylesTrash['dictionary-trash-container__show--true']);
    },

    hideTrash: function () {
        document.getElementById('dictionary-trash-container').classList.remove(stylesTrash['dictionary-trash-container__show--true']);
    },

    activateTrash: function () {
        document.getElementById('dictionary-trash-container').classList.add(stylesTrash['dictionary-trash-container__active--true']);
    },

    deactivateTrash: function () {
        document.getElementById('dictionary-trash-container').classList.remove(stylesTrash['dictionary-trash-container__active--true']);
    },
}

export var ModalOperations = {
    _emptyModalContainer(timeout) {
        timeout = typeof timeout === 'undefined' ? 200 : timeout;
        setTimeout(function () {
            ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
        }, timeout);
    }
};