import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {Navbar, NavDropdown, Nav, NavItem, MenuItem, Glyphicon, Button, ButtonToolbar, Dropdown} from 'react-bootstrap';
import {AdministrationPage} from './../../administration/AdministrationPage';
import {AdministrationUtilities} from './../../administration/AdministrationHelper';
import {CreateLexeme} from './../../lexeme/CreateLexeme';
import ReactTooltip from "react-tooltip";
import {CreatePhrase} from "../../lexeme/page/editor/phrases/CreatePhrase";

import {userActions} from './../../_redux/authentication/actions/user.action';
import {userService} from './../../_redux/authentication/services/user.services';
import {history} from "../../_redux/authentication/history";

import './ScNavbar.css';
import stylesScNavbar from './ScNavbar.module.css';
import {ModalEditLanguage, DictNavbarMenu} from "../../search/DictNavbarMenu";
import {DictionarySettings, Notifications} from "../../utilities/DictUtilities";
import {LexemeGenerator} from "../../search/admin/LexemeGenerator";
import {LexemeErrorPages} from "../../search/admin/LexemeErrorPages";
import {BackOfficeSearch} from "../../search/backOfficeSearch/BackOfficeSearch";
import {SCImport} from "../../administration/tools/import/SCImport";
import {LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";

const isLogged = userService.isLogged();
const isAdministrator = userService.isAdministrator();

export class SCNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.unsetCoreOptions = AdministrationUtilities.checkDictionaryCoreLanguageOptions();
        this.indexingDictActive = AdministrationUtilities.checkIndexingDict();
        this.anomalies = Dict.getAnomalies();
        this.schemaAnomalies = {};
        this.activeModules = AdministrationUtilities.getActiveModules();
        if (typeof this.anomalies.schemaAnomalies !== 'undefined' && this.anomalies.schemaAnomalies !== null) {
            this.schemaAnomalies = this.anomalies.schemaAnomalies;
        }
    }

    componentDidMount() {
        this._resetCaretsIcon("glyphicon glyphicon-menu-hamburger");
        while (document.getElementsByClassName("caret").length > 1) {
            this._resetCaretsIcon("glyphicon glyphicon-triangle-right");
        }

        AdministrationUtilities.resetSkin();
    }

    _resetCaretsIcon(className) {
        const carets = document.getElementsByClassName("caret");
        if (isLogged && carets.length > 1)
            carets[0].className = className;
    }


    _openOptionPage() {
        ReactDOM.render(<AdministrationPage show={true}
                                            defaultActiveKey={'options'}/>, document.getElementById("dict-modal-container"));
    }

    _openAdministrationPage(defaultActiveKey) {
        defaultActiveKey = "properties";
        ReactDOM.render(<AdministrationPage show={true}
                                            defaultActiveKey={defaultActiveKey}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _buildButtonError(text, type) {
        var offset = type === "options" ? 0 : 200;
        var delayHide = "150";
        if(type === 'schema') {
            delayHide = "1500";
        }

        var reloadText = "clicca per aggiornare dopo le modifiche";
        if(type === 'indexing') {
            reloadText = "in caso di server inattivo, avviare e clicca per aggiornare. Altrimenti sarà necessario risolvere e riavviare l'applicazione"
        }

        text += '<br><i>(' + reloadText + ')</i>';
        var msg = type === 'schema' ? 'Ricontrollo schema db' : 'Ricontrollo opzioni';
        return <Button onClick={() => {
            Notifications.showModalSpinner(msg + ", l'app verrà ricaricata. Attendere...", stylesScNavbar['long-modal-spinner']);
            Dict.call('/admin/checkSchemaAnomalies').then((result => {
                window.location.reload();
            }))
        }} bsStyle="danger"
                       style={{fontWeight: 'bold', marginTop: '7px', marginBottom: '0px', padding: '0'}}>
            <Glyphicon id={"unset-core-options-alert-icon"}
                       glyph="alert" data-tip data-for={'alert-unset-core-options'}/>
            <ReactTooltip id={'alert-unset-core-options'} type='error' place={"bottom"} delayHide={delayHide}
                          offset={{right: offset}}>
                <span dangerouslySetInnerHTML={{__html: text}}   />
            </ReactTooltip>
        </Button>
    }

    _handleLogout() {
        userActions.logout();
    }

    render() {
        var isAdministrator = userService.isAdministrator();
        var module = this.props.module;
        /*if(typeof module.referenceModule !== 'undefined')
            module = AdministrationUtilities.getModule(module.referenceModule);*/
        const isActive = this.props.isActive;

        var unsetCoreOptions = this.unsetCoreOptions;//AdministrationUtilities.checkDictionaryCoreLanguageOptions();
        var btDangerCoreOptions = '';
        if (Object.keys(unsetCoreOptions).length > 0) {
            var printMissingOptions = AdministrationUtilities.printMissingCoreOptions(unsetCoreOptions);
            btDangerCoreOptions = this._buildButtonError(printMissingOptions, "options");
        }

        var btDangerSchemaAnomalies = '';
        if (Object.keys(this.schemaAnomalies).length > 0) {
            var printSchemaAnomalies = AdministrationUtilities.printSchemaAnomalies(this.schemaAnomalies);
            btDangerSchemaAnomalies = this._buildButtonError(printSchemaAnomalies, "schema");
        }

        //TODO: per riattivare indicizzazione
        var btIndexingAnomalies = '';
        if (this.indexingDictActive === false) {
            var printINdexingAnomalies = "Servizio d'indicizzazione inesistente o inattivo<p style='padding-left: 5px'>Possibili cause:<ul>\n" +
                "  <li>Server inattivo</li>\n" +
                "  <li>proprietà <i>solr.sever</i> (path del server es: <small><i>protocollo://host</i>/solr</small>) non presente tra le application.properties</li>\n" +
                "  <li><strong>Modulo Dictionary</strong></li>\n" +
                "  <li>core inesistente, anomalo o nome non corrispondente (nome del db)</li>\n" +
                "  <li>core di errori inesistente, anomalo o nome non corrispondente (nome del db + '_error_pages')</li>\n" +
                "  <li><strong>Modulo Tree</strong></li>\n" +
                "  <li>core inesistente, anomalo o nome non corrispondente (nome del db + '_tree')</li>\n" +
                "  <li>core di errori inesistente, anomalo o nome non corrispondente (nome del db + '_tree_documents')</li>\n" +
                "</ul></p>";
            btIndexingAnomalies = this._buildButtonError(printINdexingAnomalies, "indexing");
        }

        const ModuleNavbarMenu = () => {
            return AdministrationUtilities.getModuleNavBarMenu(module);
        };

        const SCNavDropDown = () => {
            return (isLogged && (isAdministrator || this.activeModules.length > 1) ?
                <NavDropdown id="basic-nav-dropdown" bsClass="hamburger-li dropdown"
                             title="">
                    {isActive ? <ModuleNavbarMenu/> : ''}
                    <MenuItem divider/>
                    <CommonNavToggleDropdown currentModule={module} activeModules={this.activeModules}
                                             callBackOpenAdministrationPage={(activeKey) => this._openAdministrationPage(activeKey)}/>

                </NavDropdown>
                : '');
        };

        const moduleButtons = null; //module.shortcutButtons();

        const SCNavs = () => {
            return (isLogged ? <>
                {isAdministrator ? <NavItem onClick={() => {
                    /*ReactDOM.render(<SCImport show={true} type={this.props.module.code}/>, document.getElementById("dict-modal-container"));*/
                    ReactDOM.render(<LexemeGenerator show={true}/>, document.getElementById("dict-modal-container"));
                    document.getElementsByTagName("body")[0].click()
                }}>
                    <Glyphicon glyph="glass"/>
                </NavItem> : ''}
                <NavItem href="/">
                    <Glyphicon glyph="home"/>
                </NavItem>
                <NavItem onClick={() => {
                    LexemeUtilities.showModalSpinner("Pulizia di sessione e reload", "");
                    Dict.call('/admin/clearSession').then((result) => {
                        setTimeout(function () {
                            window.location.reload();
                        }, 2000);
                    });
                }}>
                    <Glyphicon glyph="refresh"/>
                </NavItem>
                {isAdministrator ? <NavItem onClick={() => this._openAdministrationPage()}>
                    <Glyphicon glyph="wrench"/>
                </NavItem> : ''}
                {btDangerCoreOptions}
                {btDangerSchemaAnomalies}
                {btIndexingAnomalies}
            </> : '')
        };

        const title = Dict.getGlobalProperty('TITLE');
        const customHeader = Dict.getGlobalProperty('CUSTOM_HEADER');
        var embedObj = null;

        if (typeof customHeader !== 'undefined' && customHeader === 'true') {

            embedObj =
                <embed style={{width: '100%', height: '100%', padding: '0', margin: '0', backgroundColor: 'white'}}
                       src={'custom/header/' + AdministrationUtilities.retrieveStoredArea('header')}/>;
        } else {
            embedObj = <a href="/"
                          style={{marginLeft: '0'}}>{typeof title !== 'undefined' && title !== null && title.length > 0 ? title : 'Welcome to Smallcodes BackOffice'}</a>
        }

        var SCNavbarHeader = () => {
            return <Navbar.Header style={{width: '100%'}}>
                <Navbar.Brand>
                    {embedObj}
                </Navbar.Brand>
            </Navbar.Header>;
        };


        const role = isLogged ? userService.getRoleByModule(this.props.module.code) : {label: ''};

        const SCUserDropDown = () => {
            return isLogged ? <ButtonToolbar>
                <Dropdown id="sc-nav-dropdown">
                    <Dropdown.Toggle>
                        <Glyphicon glyph="user"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={stylesScNavbar['sc-user-dropdown-menu']}>
                        <MenuItem><strong>{localStorage.user.replace(/"/g, '')}</strong></MenuItem>
                        <MenuItem data-tip data-for={'info-role-description'}>
                            <small>[{role}]</small>
                        </MenuItem>
                        <ReactTooltip id={'info-role-description'} type='info' place={"left"}>
                            {Dict.t(role + "_DESCRIPTION")}
                        </ReactTooltip>
                        <MenuItem divider/>
                        <MenuItem onClick={() => this._handleLogout()}>{'Logout'}</MenuItem>
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonToolbar> : <Button onClick={() => {
                history.push('/login', {from: {pathname: window.location.pathname}});
            }}><Glyphicon glyph={"log-in"}/></Button>;
        };

        return <Navbar id={'app-navbar'}>
            <Nav id='nav-buttons' className={stylesScNavbar['nav-buttons']}>
                <SCNavDropDown key={Math.random()}/>
                {moduleButtons}
                <SCNavs/>
            </Nav>
            <Nav id='nav-text' className={stylesScNavbar['nav-navbar-header']}>
                <SCNavbarHeader/>
            </Nav>
            <Nav>
                <SCUserDropDown/>
            </Nav>
        </Navbar>
    }
}

export class CommonNavToggleDropdown extends React.Component {

    render() {

        const NavOtherModules = this.props.activeModules.filter((module) => {
            return module !== this.props.currentModule;
        }).map((module) => {
            return <MenuItem onClick={() => {
                window.location.href = module.path;
            }}>{Dict.t(module.code)}</MenuItem>;
        });

        return <>
            {isAdministrator ? <NavToggleDropdown id="admin-nav-dropdown" bsClass="nestedNavDropdown dropdown"
                                                  key={'scnavbar-navtoggledropdown-admin'}
                                                  title={<span className={stylesScNavbar['nav-span-title']}><Glyphicon
                                                      glyph={"wrench"}/>{Dict.t('ADMINISTRATION')}</span>}
                                                  pullRight={true}>
                <MenuItem key={'scnavbar-menuitem-options'} onClick={() => {
                    this.props.callBackOpenAdministrationPage("options")
                }}>{Dict.t('OPTIONS')}</MenuItem>
                <MenuItem key={'scnavbar-menuitem-properties'}
                          onClick={() => {
                              this.props.callBackOpenAdministrationPage("properties")
                          }}>{Dict.t('PROPERTIES')}</MenuItem>
                <MenuItem key={'scnavbar-menuitem-i18n'} onClick={() => {
                    this.props.callBackOpenAdministrationPage("i18n")
                }}>i18n</MenuItem>
                <MenuItem key={'scnavbar-menuitem-options'} onClick={() => {
                    this.props.callBackOpenAdministrationPage("users")
                }}>{Dict.t('USERS')}</MenuItem>
            </NavToggleDropdown> : ''}
            {this.props.activeModules.length > 1 ?
                <NavToggleDropdown id="other-mods-nav-dropdown" key={'scnavbar-navtoggledropdown-other-mods'}
                                   bsClass={(NavOtherModules.length > 0 ? '' : 'hiddenNestedNavDropdown') + ' nestedNavDropdown dropdown'}
                                   title={<span className={stylesScNavbar['nav-span-title']}><Glyphicon
                                       glyph={"right-arrow"}/>{Dict.t('GO_TO')}</span>}
                                   pullRight={true}>{NavOtherModules}
                </NavToggleDropdown> : ''}
        </>
    }

}

export class NavToggleDropdown extends React.Component {

    /*constructor(props) {
        super(props);
    }*/

    _onToggleNavDropDown(isOpen, event, source) {
        /*const target = event.target.parentElement.classList.contains('nestedNavDropdown') ? event.target : event.target.parentElement;
        var triangle;

        if (source.source === 'click') {
            var openTriangle = document.getElementsByClassName('navbar')[0].getElementsByClassName('glyphicon-triangle-bottom')[0];

            if (typeof openTriangle !== 'undefined') {
                openTriangle.className = 'glyphicon glyphicon-triangle-right';
            }

            triangle = target.parentElement.getElementsByClassName('glyphicon')[1];
            if(typeof triangle === 'undefined') {
                triangle = target.parentElement.getElementsByClassName('glyphicon')[0];
            }
            triangle.className = 'glyphicon glyphicon-triangle-' + (isOpen ? 'bottom' : 'right');
        } else if ((source.source === 'rootClose' || source.source === 'select') && !(target.className === stylesScNavbar['nav-span-title']) && !(target.className === 'nestedNavDropdown dropdown-toggle')) {
            triangle = document.getElementsByClassName('navbar')[0].getElementsByClassName('glyphicon-triangle-bottom')[0];
            if (typeof triangle !== 'undefined')
                triangle.className = 'glyphicon glyphicon-triangle-right';
        }*/
    }

    render() {

        return <NavDropdown id={this.props.id} bsClass={this.props.bsClass}
                            onToggle={(isOpen, event, source) => this._onToggleNavDropDown(isOpen, event, source)}
                            title={this.props.title}
                            pullRight={true}>{this.props.children}
        </NavDropdown>
    }
}





