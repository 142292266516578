import React from 'react';
import {LexemeUtilities} from './../LexemeHelper';
import * as Dict from "dict-lib";

const updateLocVar = ['SET_LOC_VARS', 'SET_POLYS'];
var updateClasses = ['lexDataLocVars', 'lexeme-polyrhematic-list'];


export const saveNewLocVarForm = (newForm, ids) => {
    LexemeUtilities.callByClassName('/locVars/editLocVars/' + newForm + '/' + ids + '/' + updateLocVar, updateClasses, true);
};

export const addNewLocVar = (rootId, langList) => {
    var myClasses = ['lexDataLocVars', 'lexeme-polyrhematic-list'];

    var ids = "";
    var form = "";

    langList.map((item) => {
        form = item.form;
        ids += item.id_lang + "_";
    });

    ids = ids.substring(0, ids.length - 1);

    //LexemeUtilities.callByClassName('/locVars/addNewLocVar/' + rootId + '/' + form + '/' + ids + '/' + updateLocVar, myClasses, true)
    LexemeUtilities.callHighlightWithModalSpinner('/locVars/addNewLocVar/' + rootId + '/' + form + '/' + ids + '/' + updateLocVar, Dict.t('ADDING_VAR_LOC'), 'lexDataVarLocForm_')
};

export const deleteLocVar = (id) => {
    var myClasses = ['lexDataLocVars', 'lexemeSubentry polySubFromLexeme'];

    LexemeUtilities.callByClassName('/locVars/deleteLocVar/' + id + '/' + updateLocVar, myClasses, true);
};

export const promoteLocVar = (id) => {
    var myClasses = updateClasses.concat(['lexemeTitle lexTitleDirect']);

    LexemeUtilities.callByClassName('/locVars/promoteLocVar/' + id + '/' + updateLocVar, myClasses, true);
};

export const removeForm = (id) => {
    var myClasses = ['lexDataLocVars'];
    var myUpdateocVar = ['SET_LOC_VARS'];


    LexemeUtilities.call('/locVars/removeForm/' + id + '/' + myUpdateocVar);
};



