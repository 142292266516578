import React from 'react';
import {removeForm} from './LocVarActions';
import Dict from 'dict-lib';
import {Button, Grid, Row, Col} from 'react-bootstrap';
import './LocVars.css';


export class LocVarRemoveForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ids: ""
        };

        this._handleRemove = this._handleRemove.bind(this);
    }

    componentWillMount() {
        var ids = "";

        if (typeof this.props.item.variants == 'undefined') {
            ids = this.props.item.id;
        } else {
            this.props.item.variants.map(variant => ids += variant.id + "_");
            ids = ids.substring(0, ids.length - 1);
        }

        this.setState({ids: ids});
    }

    _handleRemove() {
        removeForm(this.state.ids);
    }


    render() {

        return <Grid fluid>
            <Row>
                <Col lg={10} md={10} className="popup-label">{Dict.t('ASK_REMOVE_FORM')}</Col><Col lg={2} md={2}><Button
                onClick={this._handleRemove}>{Dict.t('YES')}</Button></Col>
            </Row>
        </Grid>;
    }


}