import React from 'react';
import Dict from 'dict-lib';
import ReactDOM from 'react-dom';
import {LexemePageContext, pageContext} from "./LexemePageContext";
import {Provider, connect} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducers/rdcEntry';
import {
    LexemeUtilities,
    LexemeEditingLevel,
    LexemeRenderStatus,
    LexemeReduxUtilities,
    getContextTypes, LexemeDisplayContext, LexemePageInfos
} from './editor/LexemeHelper';
import {LexemeDictionaryType} from './parts/00_DictionaryType/LexemeDictionaryType';
import {LexemeTitle} from './parts/01_Title/LexemeTitle';
import {LexemeData} from './parts/02_Data/LexemeData';
import {LexemeSubentries} from './parts/03_Subentries/LexemeSubentries';
import {LexemePolyrhematics} from './parts/04_Polyrhematics/LexemePolyrhematics';
import {LexemeReferences} from './parts/05_References/LexemeReferences';
import scLogo from '../../../public/img/scLogo.svg';
import {Notifications} from "../../utilities/DictUtilities";
import PropTypes from 'prop-types';
import {Tabs, Tab, TabContent, TabPane} from 'react-bootstrap';

import './../../_css/lexeme.css';
import stylesLexemePage from '../../_css/dictionary/lexemePage.module.css';
import stylesRootEntry from '../../_css/dictionary/lexemeParts/rootEntry.module.css';
import BUtils from './../../utilities/BaseUtilities';
import FontAwesome from "react-fontawesome";
import {LexemaPageContextProvider} from "./LexemePageContextProvider";
import error from "../../../public/img/error.png";
import warning from "../../../public/img/warning.png";
import {userService} from "../../_redux/authentication/services/user.services";

let store = createStore(reducer, applyMiddleware(thunk/*, logger*/));

let lexemeTreeData = [];

export var resetState = () => store.dispatch({
    type: 'RESET_STATE',
});

export var addTab = (state) => store.dispatch({
    type: 'ADD_TAB',
    id: state.id,
    pageStatus: state.pageStatus,
    language: state.language,
    dictionaryType: state.dictionaryType,
    tabs: state.tabs,
    tabIndex: state.tabIndex,
    classType: state.classType,
    isUpToDate: state.isUpToDate,
    forceSpinner: state.forceSpinner,
    loadCallBack: state.loadCallBack
});

export var setPage = (state, result) => store.dispatch({
    type: 'SET_PAGE',
    id: state.id,
    language: state.language,
    dictionaryType: state.dictionaryType,
    pageStatus: state.pageStatus,
    tabs: state.tabs,
    tabIndex: state.tabIndex,
    result: result,
    descrLanguage: result.descrLanguage
});

export var openPage = (state, result) => store.dispatch({
    type: 'OPEN_PAGE',
    id: state.id,
    language: state.language,
    dictionaryType: state.dictionaryType,
    classType: state.classType,
    entryForm: state.entryForm,
    pageStatus: state.pageStatus,
    tabIndex: state.tabIndex,
    result: result,
    descrLanguage: state.descrLanguage
});

export var changeTab = (state, tabIndex) => store.dispatch({
    type: 'CHANGE_TAB',
    tabIndex: tabIndex
});

export var removeTab = (result) => store.dispatch({
    type: 'REMOVE_TAB',
    tabs: result.tabs,
    tabIndex: result.tabIndex,
});

export var resetPage = (state) => store.dispatch({
    type: 'RESET_PAGE',
    id: state.id,
    language: state.language,
    dictionaryType: state.dictionaryType,
    pageStatus: state.pageStatus,
});

export var setSubentries = (subIds) => store.dispatch({
    type: 'SET_SUBENTRIES',
    result: subIds
});

export var setSenses = (senses, subentryIds) => store.dispatch({
    type: 'SET_SENSES',
    result: {senses: senses, subentryIds: subentryIds}
});

export var setPolies = (polies) => store.dispatch({
    type: 'SET_POLIES',
    result: polies
});

export const getPage = () => {
    return store.getState();
};

export const getTabs = () => {
    return store.getState().tabs;
};

export var getTabIndex = () => {
    return store.getState().tabIndex;
};

export const getLexemeOptions = (option) => {
    return store.getState().page.lexeme_options[option.toLowerCase()];
};

export const getLexemeProperties = (property) => {
    return store.getState().page.lexeme_properties[property];
};

export const getRootEntryId = () => {
    return getLexemeProperties("root_entry_id");
};

export const getDictionaryProperties = (property) => {
    return store.getState().page.dictionary_properties[property];
};

export const getLexemeTreeView = () => {
    return lexemeTreeData;
};

export const getLexemePolyrhematics = () => {
    return store.getState().page.polyrhematics;
};

/*export const createLexemeTreeViewById = () => {
    var state = store.getState();
    lexemeTreeData = [];
    Dict.call('service/getLanguageTreeViewbyLexeme/' + state.id).then(result => {
        lexemeTreeData.push(result);
    });
};*/

export const rebuildLexemeVarLocTreeViewById = () => {
    var state = store.getState();
    lexemeTreeData = [];
    Dict.call('page/rebuildLexemeVarLocTreeView/' + state.id).then(result => {
        lexemeTreeData.push(result);
    });
};

export const getDescriptionLanguage = () => {
    return getLexemeOptions('descr_language');
};

export const isToponym = () => {
    return store.getState().page.lexeme_properties.discriminator === 'TOPONYM';
};


var loadPage = () => dispatch => {
    var state = store.getState();
    var tab = state.tabs[state.tabs.length - 1];

    Dict.call('/page/getPage/' + tab.id + '/' + tab.language + '/' + tab.dictionaryType).then(result => {

        if (result.result !== 'OK') {
            if (result.abstract === 'conflict 409') {
                LexemeUtilities.callBuilderError(tab.id, result.message);
                LexemeUtilities.showErrorModalMessage(result.message, -1);
            }
        } else {
            tab.page = result.page;

            if (typeof state.descrLanguage !== 'undefined')
                state.descrLanguage = '';

            dispatch(openPage(state, result));

            Notifications.closeModalSpinner();
            if (typeof state.loadCallBack === 'function') {
                state.loadCallBack();
            }
        }

    });
};


export const resetLexemeStatus = () => {
    return LexemeUtilities.call('/page/resetLexemeStatus/' + getRootEntryId() + '/');
    /*.then(result => {
     setPage(getPage(), result);
     })*/
};

export var loadAdvancedFields = (lexeme_data) => {
    const lexData = typeof lexeme_data === 'undefined' ? store.getState().page.lexeme_data : (lexeme_data);

    const simplify_IPAExists = typeof lexData.simplify_IPA !== 'undefined' && (lexData.simplify_IPA + "").length > 0;
    var simplify_IPA = simplify_IPAExists ?
        <div className={stylesRootEntry['lexData-simplify-ipa']}>{lexData.simplify_IPA}</div> : "";

    const hyphenationExists = typeof lexData.hyphenation !== 'undefined' && lexData.hyphenation > 0;
    var hyphenation = hyphenationExists ?
        <div className={stylesRootEntry['lexData-hyphenation']}>{lexData.hyphenation}</div> : "";

    var etymologyFilter = lexData.etymology.length > 0 ? lexData.etymology.filter(function (item) {
        return item.lang_id === getLexemeOptions('descr_language');
    }) : [];

    if (etymologyFilter.length > 0)
        etymologyFilter = etymologyFilter[0];
    else
        etymologyFilter = null;

    const etymologyExists = etymologyFilter != null && etymologyFilter.text.length > 0;
    var etymology = etymologyExists ?
        <div className={stylesRootEntry['lexData-etymology']}
             dangerouslySetInnerHTML={{__html: etymologyFilter.text}}/> : "";

    const notesExists = typeof lexData.notes !== 'undefined' && lexData.notes.length > 0;
    var notes = notesExists && (userService.isAdministrator() || Dict.getLanguageOptionsById(this.props.lang.id)["editPageManager->SHOW_NOTES"]()) ?
        <div className={stylesRootEntry['lexData-notes']}
             dangerouslySetInnerHTML={{__html: Dict.t('NOTES') + ':' + lexData.notes}}/> : "";

    //if (etymologyExists || hyphenationExists || simplify_IPAExists) {
    ReactDOM.render(
        <div>{simplify_IPA}{etymology}{notes}{hyphenation}</div>, document.getElementById("lexData-advanced-fields-" + getRootEntryId()));
    //}
};

export default class LexemePage extends React.Component {

    componentWillMount() {
        this._addTab(this.props);
        setTimeout(function () {
            if (typeof this.props.callbackParent === 'function')
                this.props.callbackParent(true);
        }.bind(this), 1000);
    }

    componentWillReceiveProps(nextProps) {
        this._addTab(nextProps);
    }

    _addTab(props) {
        var newState = JSON.parse(JSON.stringify(props));
        newState.tabs = [];
        newState.tabs.push({
            id: newState.id,
            entryForm: newState.entryForm,
            languageCode: newState.languageCode,
            language: newState.language,
            dictionaryType: newState.dictionaryType,
            classType: newState.classType
        });
        newState.tabIndex = 0;

        addTab(newState);
    }

    callBackSelectionHandler(selectableItem) {
        this.props.callBackSelectionHandler(selectableItem);
    }

    render() {
        return <Provider store={store}>
            <ConnectedContainer
                callbackContainer={(selectableItem) => this.callBackSelectionHandler(selectableItem)}/>
        </Provider>;
    }
}

class LexemePageContainer extends React.Component {

    componentDidMount() {
        this.props.load();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === 'ADD_TAB' || nextProps.status === 'FOUND_TAB' /*|| (store.getState().tabs.length > 1 && nextProps.status === 'OPEN_PAGE')*/)
            this.props.load();
    }

    retrieveLexemeProperties() {
        return store.getState().page.lexeme_properties;
    }

    retrieveDictionaryProperties() {
        return store.getState().page.dictionary_properties;
    }

    retrieveTitle() {
        return store.getState().page.title;
    }

    callBackSelectionHandler(selectableItem) {
        this.props.callbackContainer(selectableItem);
    }

    render() {

        var lexemePageStatus = typeof store.getState().pageStatus === 'undefined' ? LexemeEditingLevel.EDITABLE : store.getState().pageStatus;

        var content = <LexemePageTabContent page={store.getState().page} id={this.props.id}
                                            pageStatus={lexemePageStatus}
                                            status={store.getState().status} isUpToDate={store.getState().isUpToDate}
                                            forceSpinner={store.getState().forceSpinner}
                                            classType={store.getState().classType}
                                            tabs={store.getState().tabs}
                                            tabIndex={store.getState().tabIndex}
                                            descrLanguage={store.getState().descrLanguage}
                                            callBackSelectionHandler={(selectableItem) => this.callBackSelectionHandler(selectableItem)}
        />;

        return <div>
            {content}
            <div id="dict-preload-image">&nbsp;</div>
        </div>;
    }
}

export class LexemePageTabContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showAdditionalMsg: '',
            entryForm: '',
            generatingMediumBig: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({showAdditionalMsg: ''});
        if (nextProps.status === 'WAIT_PAGE' && this.props.status !== 'SET_PAGE' && (!nextProps.isUpToDate)) {
            Dict.call('/page/getPageSize/' + this.props.id + '/null').then(result => {
                this.setState({
                    showAdditionalMsg: result.additionalMsg,
                    entryForm: result.entryForm,
                    generatingMediumBig: result.generatingStatus
                });

                if (result.generatingStatus > 0) {
                    Notifications.showModalSpinner(result.entryForm + " - " + result.additionalMsg, stylesLexemePage['creating-medium-big-lexeme']);
                }
            });
        }
    }

    handleTabSelect(tabIndex, evt) {
        var newState = JSON.parse(JSON.stringify(store.getState()));
        if (evt.target.id === 'tab-close-' + LexemeReduxUtilities.buildTabsUniqueLexemeId(store.getState(), tabIndex)) {
            var tabPosition = newState.tabIndex;
            newState.tabIndex = (tabIndex > tabPosition || tabPosition === 0)  /*&& tabPosition !== newState.tabs.length - 1*/ ? newState.tabIndex : newState.tabIndex - 1;
            newState.tabs.splice(tabIndex, 1);
            removeTab(newState);
        } else {
            LexemeUtilities.closeAfterSave();
            changeTab(newState, tabIndex);
        }
    }

    callBackSelectionHandler(selectableItem) {
        this.props.callBackSelectionHandler(selectableItem);
    }

    isBigLexeme(page) {
        return page.polyrhematics.length > 20 || page.subentries.length > 5;
    }

    render() {

        const page = this.props.page;
        const pageStatus = this.props.pageStatus;
        const descrLanguage = this.props.descrLanguage;
        const activeKey = parseInt(this.props.tabIndex);
        var tabs = store.getState().tabs;
        var TabItems = [];
        var viewIdClass = 'view-page';

        var Views = tabs.map((tab, index) => {
            const renderComponent = this.props.tabIndex !== index || Object.keys(page).length > 0;
            var idPostFix = LexemeReduxUtilities.buildTabsUniqueLexemeId(store.getState(), index);

            if (renderComponent) {
                return <LexemePageContent page={tab.page} index={index + "-" + tab.entryForm}
                                          key={'lexemeComponentone-' + idPostFix}
                                          idPostFix={idPostFix}
                                          classType={this.props.classType}
                                          pageStatus={pageStatus}
                                          descrLanguage={descrLanguage}
                                          callBackSelectionHandler={(selectableItem) => this.callBackSelectionHandler(selectableItem)}/>;
            } else if (!this.props.isUpToDate && tab.entryForm.length > 0 && !this.state.generatingMediumBig) {
                return <div id={viewIdClass} className={stylesLexemePage[viewIdClass]}>
                    <p className={'is-uptodate-paragraph'}>
                        <img className={stylesLexemePage['scrollable-dict-contents__sc-logo']} src={scLogo} alt={''}/>
                        <span id='is-uptodate-msg'
                              className={stylesLexemePage['view-page__is-uptodate-msg']}>{tab.entryForm + " - " + Dict.t('GENERATING_PAGE')}</span>
                    </p>
                </div>;
            } else if (this.props.forceSpinner || true) {
                return <div id={viewIdClass + 'pio'} className={BUtils.joinClasses(stylesLexemePage[viewIdClass])}>
                    <p className={stylesLexemePage['view-page__is-uptodate-paragraph']}>
                        <img className={stylesLexemePage['scrollable-dict-contents__sc-logo']} src={scLogo} alt={''}/>
                    </p>
                </div>;
            }
        });

        Views.forEach((view, index) => {
            var idPostFix = LexemeReduxUtilities.buildTabsUniqueLexemeId(store.getState(), index);
            var tabClassName = typeof tabs[index].page !== 'undefined' && tabs[index].page.lexeme_properties.is_hidden ? stylesLexemePage['hideview-lexeme'] : '';
            var homo = typeof tabs[index].page !== 'undefined' && tabs[index].page.title.homographic > 0 ? '-' + tabs[index].page.title.homographic : '';

            TabItems.push(<Tab id={'tab-' + idPostFix} eventKey={index} key={'key-tab-' + idPostFix}
                               bsClass={tabClassName}
                               title={<div
                                   className={BUtils.joinClasses(stylesLexemePage['tab-container'], tabClassName)}>
                                   <span id={"tab-entryForm-" + idPostFix}
                                         className={stylesLexemePage['tab-container__entryForm-label']}>
                                       {tabs[index].entryForm + homo}
                                   </span>
                                   <span id={"tab-languageCode-" + idPostFix}
                                         className={stylesLexemePage['tab-container__languageCode-label']}>
                                       <small>{tabs[index].languageCode}</small>
                                   </span>
                                   <FontAwesome name={'times'} id={"tab-close-" + idPostFix}
                                                className={stylesLexemePage['tab-container__close']}/>
                               </div>}>
                <div className={tabClassName}>{view}</div>
            </Tab>);
        });

        return <Tabs activeKey={activeKey} id="lexeme-tabs" animation={false}
                     onSelect={(key, evt) => this.handleTabSelect(key, evt)}>
            {TabItems}
        </Tabs>
    }
}

export class LexemePageContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "LEXEME_PAGE_CONTENT",
            isOK: true
        };
    }

    static getDerivedStateFromError(error) {
        return {isOK: false};
    }

    componentDidCatch(error, errorInfo) {
        Dict.call('/page/setRenderingError/' + this.props.page.lexeme_properties.root_entry_id + '/' + error.message + '/').then(result => {
            Notifications.showErrorModalMessage("RENDER_ERROR_MESSAGE", 2000);
        });
    }

    callBackSelectionHandler(selectable) {
        //if (typeof this.props.callBackSelectonHandler !== 'undefined')
        this.props.callBackSelectionHandler(selectable);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        //return false;
        return this.props.page !== nextProps.page || this.props.page.lexeme_properties.reset === "true" || this.props.descrLanguage !== nextProps.descrLanguage;
    }

    render() {
        const page = this.props.page;
        const pageStatus = this.props.pageStatus;
        const descrLanguage = this.props.descrLanguage;
        const displayContext = LexemeDisplayContext.DICTIONARY_SEARCH;
        const idPostFix = this.props.idPostFix;
        const startItem = this.props.startItem;
        const classType = this.props.classType;

        if (this.state.isOK) {
            return <LexemaPageContextProvider callBackSelectionHandler={(item) => {
                this.callBackSelectionHandler(item)
            }} idPostFix={idPostFix}
                                              classType={classType}
                                              pageStatus={pageStatus}
                                              page={page}
                                              displayContext={displayContext}
                                              descrLanguage={descrLanguage}
                                              startItem={startItem}/>
        } else {
            var message = Dict.t(userService.isLogged() ? 'RENDER_ERROR_MESSAGE' : 'SEARCH_RESULT_PAGE_IN_REVIEW');
            return <div className={stylesLexemePage['lexeme-page-error-component']}><img className="modal-img"
                                                                                         src={error}/><span>{message}</span>
            </div>
        }
    }
}

LexemePageContent.context = LexemePageContent;

const mapStateToProps = (state) => {
    return {
        id: state.id,
        language: state.language,
        dictionaryType: state.dictionaryType,
        page: state.page,
        status: state.status
    };
};

export const mapDispatchToProps = (dispatch) => {

    return {
        load: () => {
            dispatch(loadPage());
        }
    };
};

const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(LexemePageContainer);