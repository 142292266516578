import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {addReference} from './EntryActions';
import {Grid, Row, Button, Col} from 'react-bootstrap';
import {getLexemeProperties, getRootEntryId} from './../../LexemePage';
import {EntryTypeahead, PolyTypeahead, SCCombo, SCSubmitButton} from './../../../../dict-components/components';
import './../../../../_css/lexeme.css';
import './../root-entry/RootEntry.css';


export class EntryLinks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            entryToLinkId: "",
            polyToLinkId: "",
            type: "",
            optionsLinks: [],
            showPoly: false,
            isPoly: this.props.additionalData.isPoly
        };

        this._saveLink = this._saveLink.bind(this);
        this._showHidePoly = this._showHidePoly.bind(this);
        this._handleOnFocus = this._handleOnFocus.bind(this);
    }

    componentWillMount() {
        var service = '/service/options/' + (/*typeof this.props.item.html_form !== 'undefined'*/ this.state.isPoly ? 'getActiveLinkBetweenEntries/' : 'getActiveMonoLinkBetweenEntries/');
        Dict.call(service + getLexemeProperties('language_id')).then(result => {
            this.setState({optionsLinks: result.comboItems, type: result.comboItems[0].value});
        });
    }

    _showHidePoly() {
        if (!this.state.showPoly) {
            const polyTh = <PolyTypeahead languageId={getLexemeProperties("language_id")}
                                          monoId={this.state.entryToLinkId.id}
                                          callbackParent={(newState) => this.setState({polyToLinkId: newState})}
                                          autoFocus={true}/>;
            ReactDOM.render(polyTh, document.getElementById("entry-link-type-ahead-poly-id"));
        } else {
            this.setState({polyToLinkId: ""});
            ReactDOM.render(<div
                className="empty-polyth"></div>, document.getElementById("entry-link-type-ahead-poly-id"));
        }

        this.setState({showPoly: !this.state.showPoly});
    }

    _handleOnFocus() {
        ReactDOM.render(<div className="empty-polyth"></div>, document.getElementById("entry-link-type-ahead-poly-id"));
        this.setState({polyToLinkId: "", showPoly: false});
    }

    _saveLink() {
        if (Object.keys(this.state.entryToLinkId).length > 0 || Object.keys(this.state.polyToLinkId).length > 0) {
            var idToLink = Object.keys(this.state.polyToLinkId).length > 0 ? this.state.polyToLinkId.id : this.state.entryToLinkId.id;
            this.setState({btDisabled: true});
            addReference(this.state.isPoly ? this.props.item.ids : getRootEntryId(), idToLink, this.state.type, this.state.isPoly)
        }
    }

    render() {

        return <Grid fluid>
            <Row>
                <Col md={5} lg={5} className={"entry-link-type-ahead-combo"}>
                    {this.state.optionsLinks.length > 0 ? <SCCombo options={this.state.optionsLinks}
                                                                   callbackParent={(newState) => {
                                                                       this.setState({type: newState});
                                                                   }  }/> : "" }
                </Col>
                <Col md={7} lg={7} className={"entry-link-type-ahead-mono"}>
                    <EntryTypeahead languageId={getLexemeProperties("language_id")} onFocus={this._handleOnFocus}
                                    callbackParent={(newState) => this.setState({entryToLinkId: newState})}/>
                    {Object.keys(this.state.entryToLinkId).length > 0 ? <div>{ this.state.isPoly ? "" : <Button bsStyle="primary"
                                                                                     onClick={(event) => this._showHidePoly()}>{Dict.t('SHOW_POLIES')}</Button>}
                    </div> : ""}
                </Col>
            </Row>
            <Row /*className={"row-entry-link-" + this.state.showPoly}*/ >
                <Col md={5} lg={5} className={"entry-link-type-ahead-combo"}>&nbsp;</Col>
                <Col id={"entry-link-type-ahead-poly-id"} md={7} lg={7} className={"entry-link-type-ahead-poly"}/>
            </Row>
            <Row className={"row-bt-link"}>
                <SCSubmitButton onClick={this._saveLink} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>
    }
}