import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import FontAwesome from "react-fontawesome";
import {Modal, Button, Row, Col} from 'react-bootstrap';
import Tree, {TreeNode} from 'draggable-react-tree-component';
import cssAnimation from 'css-animation';
import {TreeDOMHelper, TreeUtilities} from "../../../dict-components/tree/components/TreeHelper";
import stylesMorphoRule from "../../../_css/dictionary/lexemeParts/lexemeMorphoRule.module.css";
import BUtils from './../../../utilities/BaseUtilities';
import stylesScNavbar from "../../../dict-components/navbar/ScNavbar.module.css";
import ReactTooltip from "react-tooltip";
import {MorphoRuleEdit} from './MorphoRuleEdit';
import {BackOfficeSearch} from "../../../search/backOfficeSearch/BackOfficeSearch";

function animate(node, show, done) {
    let height = node.offsetHeight;

    return cssAnimation(node, 'collapse', {
        start() {
            if (!show) {
                node.style.height = height + 'px';
            } else {
                height = node.offsetHeight;
                node.style.height = 0;
            }
        },
        active() {
            node.style.height = show ? height + 'px' : 0;
        },
        end() {
            node.style.height = '';
            done();
        },
    })
}

export class MorphoRuleSchema extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: true,
            expandedKeys: [],
            selectedKeys: [],
            autoExpandParent: true,
            treeStructure: [],
            openEditor: false
        };

        this._close = this._close.bind(this);

        [
            'onExpand',
            'onLoadData',
            '_close'
        ].forEach((name) => (this[name] = this[name].bind(this)));
    }

    componentWillMount() {
        Dict.call('/declinedForms/getMorphoRules/' + this.props.languageId + '/').then((treeStructure) => {
            this.setState({treeStructure});
        });
    }

    _close() {
        this.setState({showModal: false}, () => {
            this.emptyModalContainer();
        });
    }

    emptyModalContainer() {
        setTimeout(function () {
            ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
        }, 500);
    }

    onExpand(expandedKeys, treeNode) {
        //const items = treeNode.node.props.children.props.items;

        this.setState({
            expandedKeys,
            autoExpandParent: false,
        }, function () {
            TreeDOMHelper.resetItemSelection();
        })
    }


    onLoadData(treeNode) {
        const item = treeNode.props.children.props.item;
        this._performLoadData(item);
    }

    _performLoadData(item, callback) {
        if (!item.children) {
            Dict.call('/declinedForms/loadChildren/' + item.key + '/').then((newItem) => {
                var treeStructure = [...this.state.treeStructure];
                item.children = newItem.children;
                item.isLeaf = newItem.isLeaf;
                this.setState({treeStructure, lastRenderKey: item.children[item.children.length - 1].key}, () => {
                    if (typeof callback !== 'undefined')
                        callback();
                });
            });
        }
    }

    _openEdit(operation, item) {
        this.setState({openEditor: true, selectedKeys: [item.key]});
        var componentToOpen = '';
        if (operation === 'EDIT') {
            componentToOpen = <MorphoRuleEdit item={item} resetOpen={true} languageId={this.props.languageId}/>;
            //componentToOpen = <MorphoRuleEdit key={'key-morpho-rule-' + item.key} type={'MorphologicalRule'} item={item}/>
        }

        const container = document.getElementById('morpho-rule-schema-col-2');
        ReactDOM.unmountComponentAtNode(container);
        ReactDOM.render(componentToOpen, container);
    }


    render() {

        const animation = {
            enter(node, done) {
                return animate(node, true, done)
            },
            leave(node, done) {
                return animate(node, false, done)
            },
            appear(node, done) {
                return animate(node, true, done)
            },
        };

        const loop = data => (
            data.map((item) => {
                    return <TreeNode
                        className={"drag-not-over " + "tree-li"}
                        id={"id-" + item.title}
                        isLeaf={item.isLeaf}
                        key={item.key}
                        /*items={goLoop && (typeof visibleChildren !== 'undefined' && visibleChildren.length > 0) ? loop(visibleChildren) : null}*/
                        items={item.children && item.children.length > 0 ? loop(item.children) : null}
                        openAnimation={animation}
                    ><MorphoRuleTreeItem
                        item={item}
                        callbackCallOperation={(operation) => {
                            this._openEdit(operation, item)
                        }}
                    /></TreeNode>
                }
            )
        );


        return <Modal id="morpho-rule-schema" show={this.state.showModal} onHide={this._close} restoreFocus>
            <Modal.Header closeButton>
                <Modal.Title>{Dict.t('MORPHO_RULES')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Col lg={4} md={4} className={stylesMorphoRule['morpho-rule-schema-col-1']}>
                    <Row>
                        <Tree
                            expandedKeys={this.state.expandedKeys}
                            onExpand={this.onExpand}
                            loadData={this.onLoadData}
                            autoExpandParent={this.state.autoExpandParent}
                            onSelect={this.onSelect}
                            multiple={true}
                            selectedKeys={this.state.selectedKeys}
                            /*onRightClick={(evt) => evt.node.selectHandle.children[1].classList.add('node-right-selected')}*/
                            openAnimation={animation}
                        >
                            {loop(this.state.treeStructure)}
                        </Tree>
                    </Row>
                </Col>
                <Col id={'morpho-rule-schema-col-2'} lg={8} md={8}
                     className={BUtils.joinClasses(stylesMorphoRule['morpho-rule-schema-col-2'], stylesMorphoRule['morpho-rule-schema-col-2--' + this.state.openEditor])}>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this._close}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}

class MorphoRuleTreeItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this._openEdit = this._openEdit.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    _openEdit(operation) {
        this.props.callbackCallOperation(operation);
    }

    render() {

        const dataTip = 'edit-morpho-rule-' + this.props.item.key;

        return <>
            <div className={BUtils.joinClasses(stylesMorphoRule['morpho-rule-item'])}>
                <div onClick={() => {
                    this._openEdit('EDIT');}
                }>{this.props.item.title}</div>
                <div className={BUtils.joinClasses(stylesMorphoRule['morpho-rule-buttons'])}>
                    <Button data-tip data-for={dataTip + 1} onClick={() => {
                        this._openEdit('EDIT')
                    }}>
                        <FontAwesome name={'pencil'}/>
                        <ReactTooltip id={dataTip + 1} className={'morpho-rule-tooltip'} type='info' delayShow={500}>
                            <span>{Dict.t('EDIT')}</span>
                        </ReactTooltip>
                    </Button>
                    <Button data-tip data-for={dataTip + 2} onClick={() => {
                        this._openEdit('ADD')
                    }}>
                        <FontAwesome name={'plus'}/>
                        <ReactTooltip id={dataTip + 2} className={'morpho-rule-tooltip'}
                                      type='info' delayShow={500}>
                            <span>{Dict.t('ADD')}</span>
                        </ReactTooltip></Button>
                    <Button data-tip data-for={dataTip + 3} onClick={() => {
                        this._openEdit('SORT')
                    }}>
                        <FontAwesome name={'sort-alpha-asc'}/>
                        <ReactTooltip id={dataTip + 3}
                                      className={'morpho-rule-tooltip'}
                                      type='info' delayShow={1500}>
                            <span>{Dict.t('SORT')}</span>
                        </ReactTooltip>
                    </Button>
                    <Button data-tip data-for={dataTip + 4} onClick={() => {
                        this._openEdit('EDIT_SCHEMA')
                    }}>
                        <FontAwesome name={'table'}/>
                        <ReactTooltip id={dataTip + 4} className={'morpho-rule-tooltip'}
                                      type='info' delayShow={500}>
                            <span>{Dict.t('EDIT_SCHEMA')}</span>
                        </ReactTooltip>
                    </Button>
                </div>
            </div>
        </>
    }
}


