import React from 'react';
import './sccheckbox.css';

export class SCCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            [this.props.id]: '',
            checked: this.props.checked,
            additionalClass: typeof this.props.additionalClass === 'undefined' ? '' : ' ' + this.props.additionalClass
        };

        this._handleChange = this._handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(typeof this.props.checked !== 'undefined' && this.props.checked !== prevProps.checked) {
            this.setState({checked: this.props.checked})
        }
    }

    _handleChange(event) {
        const target = event.target;
        const newState = target.checked;
        /*const id = target.id;*/

        this.setState({
            [this.props.id]: newState,
            checked: newState
        });

        this.props.callbackParent(this.props.id, newState);
    }

    render() {
        return <div className={"sc-checkbox" + this.state.additionalClass}>
            <input id={this.props.id} type="checkbox" checked={this.state.checked} onChange={this._handleChange}
                   disabled={this.props.disabled}/>
            <label htmlFor={this.props.id} title={this.props.tooltip}>{this.props.label}</label>
        </div>;
    }
}

export default SCCheckbox;