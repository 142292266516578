import React from 'react';
import {SearchMenu} from './SearchMenu';
import {Grid} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import {AdministrationUtilities} from "../administration/AdministrationHelper";

import stylesSearchPage from '../_css/dictionary/searchPage.module.css';
import stylesLexemePage from '../_css/dictionary/lexemePage.module.css';
import BUtils from './../utilities/BaseUtilities';
import {Notifications} from "../utilities/DictUtilities";
import {LexemeUtilities} from "../lexeme/page/editor/LexemeHelper";
import {getRootEntryId} from "../lexeme/page/LexemePage";
import Dict from "dict-lib";
import {SCTrash} from "../dict-components/trash/SCTrash";

export class SearchPage extends React.Component {
    render() {

        var matchModule = AdministrationUtilities.getModuleByPathName(this.props.match.path);

        return <Grid id="dict-search" className={stylesSearchPage['dict-search']}>
            <SearchMenu ref="ref-search-menu" module={matchModule}/>
            <div id="dict-result-content" className={stylesSearchPage['dict-result-content']}>
                <div id="dict-result-container-buttonbar"
                     className={stylesSearchPage['dict-result-container-buttonbar']}/>
                <Col lg={3} md={3} id="dict-result-container" className={stylesSearchPage['dict-result-container']}
                     style={{backgroundColor: 'white', backgroundClip: 'contentBox'}}/>
                <Col lg={8} md={8} id="lexeme-page-container"
                     className={BUtils.joinClasses(stylesSearchPage['lexeme-page-container'], stylesSearchPage['lexeme-page-container-right'])}/>
                <div id={'export-container'} style={{display: 'none'}}/>
                <SCTrash/>
            </div>
        </Grid>
    }
}

