import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import FontAwesome from "react-fontawesome";
import {Modal, Button, Row, Col, Grid, FormControl, InputGroup, Label, ControlLabel, FormGroup} from 'react-bootstrap';
import Tree, {TreeNode} from 'draggable-react-tree-component';
import BUtils from './../../../utilities/BaseUtilities';
import stylesMorphoRule from "../../../_css/dictionary/lexemeParts/lexemeMorphoRule.module.css";


export class MorphoPlayground extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            split1: [],
            split2: []
        };
    }

    componentWillMount() {
        Dict.call('/declinedForms/getMorphoPlayGround/').then((result) => {
            const length = result.length;
            var split1 = result.slice(0, length / 2);
            var split2 = result.slice(length / 2, length);
            this.setState({split1, split2});
        });
    }

    _createSplitMap(split) {
        return split.map((item, index) => {
            return <Row bsClass={ BUtils.joinClasses(stylesMorphoRule['row-morpho-playground'])} onClick={() => this._handleChoose(item.method)}>
                <i style={{ marginRight: '4px', fontSize: '11px'}}>{item.returnType}</i>
                <span style={{ fontSize: '13px' }}>{item.method}</span>
            </Row>
        });
    }

    _handleChoose(method) {
        this.props.callbackParent(method);
    }


    render() {

        var split1Map1 = this._createSplitMap(this.state.split1);
        var split1Map2 = this._createSplitMap(this.state.split2);


        return <Grid key={'key-morpho-playground'} className={stylesMorphoRule['morpho-playground']} >
            <Col lg={5} md={5} className={stylesMorphoRule['col-morpho-playground']}>{split1Map1}</Col>
            <Col lg={7} md={7} className={stylesMorphoRule['col-morpho-playground']}>{split1Map2}</Col>
        </Grid>
    }

}