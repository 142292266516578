import React from "react";

import {Button, Glyphicon, Grid, Row, Col} from 'react-bootstrap';
import Dict from "dict-lib";

export class SCSubmitButton extends React.Component {

    render() {
        return <Button onClick={() => this.props.onClick()}
                       bsStyle={'primary'}
                       disabled={typeof this.props.disabled !== 'undefined' && this.props.disabled === true}
                       className={typeof this.props.className === 'undefined' ? 'lexemeButtonSave' : this.props.className}>{this.props.text}</Button>
    }

}