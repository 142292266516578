import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {
    Navbar,
    NavDropdown,
    Nav,
    Row,
    Col,
    NavItem,
    MenuItem,
    Glyphicon,
    Dropdown,
    Button,
    ButtonToolbar,
    Modal
} from 'react-bootstrap';
import {AsyncTypeahead, Typeahead} from "react-bootstrap-typeahead";
import axios from 'axios';
import {AdministrationUtilities} from './../administration/AdministrationHelper';
import {CommonNavToggleDropdown, NavToggleDropdown} from "../dict-components/navbar/SCNavbar";
import './../dict-components/navbar/ScNavbar.css';
import stylesScNavbar from './../dict-components/navbar/ScNavbar.module.css';
import FontAwesome from "react-fontawesome";
import ReactTooltip from "react-tooltip";
import BUtils from "../utilities/BaseUtilities";
import {CreateLexeme} from "../lexeme/CreateLexeme";
import {NodeOperations, TreeNodeHelper, TreeUtilities} from "../dict-components/tree/components/TreeHelper";
import stylesSCTree from "../dict-components/tree/SCTree.module.css";

export class TreeNavbarMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    _bulkUpdate() {
        ReactDOM.render(<BulkUpdate show={true}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    render() {

        return <>
            <NavToggleDropdown id="tools-dropdown" bsClass="nestedNavDropdown dropdown"
                               title={<span className={stylesScNavbar['nav-span-title']}><FontAwesome
                                   name={'briefcase'}/>{Dict.t('TOOLS')}</span>}
                               pullRight={true}>
                <MenuItem key={'key-tool-make-static-pages'}
                          onClick={() => this._bulkUpdate()}>{Dict.t('BULK_UPDATE')}</MenuItem>
            </NavToggleDropdown>
        </>;
    }
}

export class BulkUpdate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            objects: [],
            defaultObject: []
        };
    }

    /*componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.showModal !== this.state.showModal)
            this.setState({showModal: true});
    }*/

    componentDidMount() {
        var tbi = TreeNodeHelper.treeBasicItems;
        var cti = []; //this.state.composedTreeItems;
        var cni = TreeNodeHelper.treeContainersItems;

        var itemsToIcon = this.state.itemsToIcon;
        var tot;

        if (cti.length > 0)
            tot = tbi.concat(cti);
        else
            tot = tbi;

        tot = tot.concat(cni);

        cni.forEach((cniItem) => {
            cniItem.subItems.forEach((subItem) => {
                tot = tot.concat(subItem);
            });
        });

        var objects = tot.map((item) => {
            return {label: Dict.t(item.code), code: item.code};
        });

        this.setState({showModal: true, objects});
    }

    _close() {
        this.setState({showModal: false}, function () {
            this._emptyModalContainer();
        }.bind(this));
    }

    _emptyModalContainer(callback) {
        //if (typeof callback === 'function') {
        setTimeout(function () {
            ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
            callback();
        }, 1000);
        //}
    }

    _launchResetTitleForSorting() {
        var operation = {name: 'RESET_TITLE_FOR_SORTING', object: this.state.defaultObject[0].code};
        this._call(operation);
    }

    _launchResetCleanText() {
        var operation = {name: 'CLEAN_TEXT', object: this.state.defaultObject[0].code};
        this._call(operation);
    }

    _call(operation) {
        const projectName = TreeUtilities.getDefaultTreeProjectName()
        Dict.call('/tree/bulkUpdate/' + projectName + '/' + JSON.stringify(operation) + '/').then((result) => {
            console.log();
        });
    }


    render() {
        return <Modal id={"bulk_update"}
                      dialogClassName={stylesScNavbar['tree-navbar-tools-modal-dialog']}
                      show={this.state.showModal} onHide={() => this._close()}
                      restoreFocus>
            <Modal.Header className={stylesScNavbar['search-modal-navbar']}>
                <Modal.Title><span
                    style={{fontWeight: 'bold'}}>{Dict.t('BULK_UPDATE')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{paddingTop: '0px'}}>
                <Row className={stylesScNavbar['tree-navbar-tools__row']}>
                    <Col lg={4} md={4} className={stylesScNavbar['tree-navbar-tools__first-col']}>Oggetto</Col>
                    <Col lg={8} md={8}>
                        <Typeahead
                            id={'objects-typeahead'}
                            ref={'objects-typeahead'}
                            className={stylesSCTree['bulk-updateobjects-typeahead-typeahead']}
                            clearButton
                            onChange={(defaultObject) => {
                                this.setState({defaultObject});
                            }}
                            options={this.state.objects}
                            defaultSelected={this.state.defaultObject}
                            placeholder={Dict.t('SEARCH')}
                        />
                    </Col>
                </Row>
                <Row className={stylesScNavbar['tree-navbar-tools__row']}>
                    <Col lg={4} md={4} className={stylesScNavbar['tree-navbar-tools__first-col']}>Ricostruisci titolo
                        for sorting</Col>
                    <Col lg={8} md={8}><Button onClick={() => {
                        this._launchResetTitleForSorting()
                    }}>Vai</Button></Col>
                </Row>
                <Row className={stylesScNavbar['tree-navbar-tools__row']}>
                    <Col lg={4} md={4} className={stylesScNavbar['tree-navbar-tools__first-col']}>Pulisci testo</Col>
                    <Col lg={8} md={8}><Button onClick={() => {
                        this._launchResetCleanText()
                    }}>Vai</Button></Col>
                </Row>
                <Row className={stylesScNavbar['tree-navbar-tools__row']}>
                    <Col lg={4} md={4} className={stylesScNavbar['tree-navbar-tools__first-col']}>Sostituisci:</Col>
                    <Col lg={8} md={8}>--- con --- campo</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>;
    }


}