import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    MenuItem,
    Glyphicon,
    Button,
    ButtonToolbar,
    Dropdown,
    Grid,
    Col
} from 'react-bootstrap';
import {AdministrationUtilities} from "../../administration/AdministrationHelper";
import stylesScNavbar from "./ScNavbar.module.css";

export class SCFooter extends React.Component {
    render() {

        var text = Dict.getGlobalProperty('FOOTER');

        const customFooter = Dict.getGlobalProperty('CUSTOM_FOOTER');
        var embedObj = null;

        if (typeof customFooter !== 'undefined' && customFooter === 'true') {
            embedObj =
                <embed style={{
                    /*width: '100%',*/
                    /*height: '90px',*/
                    /*padding: '0',*/
                    /*margin: '0',*/
                    backgroundColor: 'white'
                    /*objectFit: 'fill'*/
                }}
                       src={'custom/footer/' + AdministrationUtilities.retrieveStoredArea('footer')}/>;

            return <div style={{
                position: 'fixed',
                bottom: '5px',
                /*display: 'flex',*/
                marginLeft: '0',
                paddingLeft: '0',
                height: '100px'
            }}>
                <div>{embedObj}</div>
            </div>
        } else {
            embedObj = text;
            return (
                <div id="sc-footer" className="">
                    <Navbar>
                        <div style={{width: '100%'}} fluid>
                            <NavbarBrand>{embedObj}</NavbarBrand>
                        </div>
                    </Navbar>
                </div>
            )
        }
    }
}