import React from 'react';
import Dict from 'dict-lib';
import {Grid, Row, Col, Button} from 'react-bootstrap';
import {LanguageTreeView, LanguageTypeahead} from './../../../../dict-components/components';
import {addNewLocVar} from './LocVarActions';
import {getLexemeProperties, getLexemeTreeView} from './../../LexemePage';
import './LocVars.css';


export class LocVarHierarchy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entryId: this.props.item.id,
            treeData: [],
            searchId: "", //"3", //"8",
            searchAncestors: "", //"2", //"2^3^4^5^6",
            openLevel: -1,
            type: "PURE_LEXEME_TREE"
        };
    }

    componentWillMount() {
        Dict.call('service/getPureLanguageTreeViewbyLocVar/' + this.state.entryId).then(result => {
            var treeData = [];
            treeData.push(result.tree);
            this.setState({treeData : treeData, searchId : result.filterLang, searchAncestors : result.filterLangAncestors});
        });
    }

    render() {


        return <Grid style={{height: '100%'}} fluid>
            { this.state.treeData.length > 0 ?
                <LanguageTreeView searchId={this.state.searchId} searchAncestors={this.state.searchAncestors}
                                  rootEntryId={this.state.rootEntryId} openLevel={this.state.openLevel}
                                  treeData={this.state.treeData} form={this.state.form} height={"120%"} type={this.state.type} /> : "" }
        </Grid>
    }

}