import React from 'react';
import Dict from 'dict-lib';
import ReactDOM from 'react-dom';
import {getContextTypes, LexemeEditingLevel, LexemeUtilities} from "../LexemeHelper";
import {Button, Col, FormControl, Grid, InputGroup, Row} from "react-bootstrap";
import {LanguageTypeahead} from "../../../../dict-components/typeahead/LanguageTypeahead";
import {Typeahead} from "react-bootstrap-typeahead";
import {savePhrase, addSense, removeSenseByLexeme, deletePhrase} from "./PhraseAction";
import {getLexemeProperties, LexemePageContent, removeTab, resetState} from "../../LexemePage";
import {SCPopup} from "../../../../dict-components/popup/SCPopup";
import {LexemeNavigator} from "../../../../dict-components/components";
import LexemePage from "../../LexemePage";
import {Notifications} from "../../../../utilities/DictUtilities";

import stylesPhraseEditor from './../../../../_css/dictionary/phraseEditor.module.css';
import stylesLexemePage from './../../../../_css/dictionary/lexemePage.module.css';
import BUtils from './../../../../utilities/BaseUtilities';


export class PhraseEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            phrase: '',
            senses: [],
            type: ''
        };

        var puripuri = Dict.getLanguageOptionsById(this.props.lang.langId);

        this.phraseOriginalScript = Dict.getLanguageOptionsById(this.props.lang.id)["senses->PHRASE_ORIGINAL_SCRIPT"] === 'true';
        this.bibliographicalReference = Dict.getLanguageOptionsById(this.props.lang.id)["senses->BIBLIOGRAPHICAL_REFERENCE"] === 'true';
    }


    componentWillMount() {
        Dict.call('/phrases/getPhraseEditorItem/' + this.props.phraseId).then(result => {
            this._resetEditor(result);
        });
    }

    _resetEditor(result) {
        this.setState({
            phrase: result.phraseItem,
            senses: result.senseItems,
            biblioReferences: result.biblioReferences,
            senseToAssignId: undefined,
            type: ''
        });

        this._emptyEditor();
    }

    _emptyEditor() {
        ReactDOM.render(<div/>, document.getElementById('phrase-editor-right-col'))
    }

    _savePhrase() {
        savePhrase(JSON.stringify(this.state.phrase)).then(result => {
            if (typeof result.result === 'undefined' || result.result !== 'ERROR') {
                this._resetEditor(result);
                this._resetPhraseResultItem(result);
            } else {
                this._resetEditor(JSON.parse(result.additionalInfos));
                this._resetPhraseResultItem(JSON.parse(result.additionalInfos));
            }
        });
    }

    _assignPhrase() {
        this._performSensePhraseOperation('assign', 'ASSIGNING_PHRASE');
    }

    _detachPhrase() {
        this._performSensePhraseOperation('detach', 'DETACHING_PHRASE');
    }

    _deletePhrase() {
        this._performSensePhraseOperation('delete', 'DELETING_PHRASE');
    }

    _performSensePhraseOperation(operation, msg) {

        Notifications.showModalSpinner(Dict.t(msg), '');

        setTimeout(function () {
            var promise;

            if (operation === 'assign') {
                promise = addSense(this.state.phrase.id, this.state.senseToAssignId);
            } else if (operation === 'detach') {
                promise = removeSenseByLexeme(this.state.phrase.id, this.state.openLexemeId);
            } else if (operation === 'delete') {
                promise = deletePhrase(null, this.state.phrase.id);
            }

            promise.then(result => {
                if (typeof result === 'undefined' || result.result !== 'ERROR') {
                    if (operation === 'delete') {
                        ReactDOM.render(<div/>, document.getElementById('lexeme-page-container'));
                        this.props.callbackParentRemoveItem();
                    } else {
                        this._resetEditor(result);
                        this._resetPhraseResultItem(result);
                    }
                    Notifications.closeModalSpinner();
                } else {
                    Notifications.showModalMessage(result.result.toLowerCase(), result.message, 4000);

                }
            });
        }.bind(this), 500);
    }

    _resetPhraseResultItem(result) {
        var resultItem = this.props.phraseResultItem;
        resultItem.text = result.phraseItem.text;
        resultItem.textForSearching = result.phraseForSearching;
        resultItem.lexemes = [];


        if (result.senseItems.length > 0) {
            result.senseItems.forEach((item) => {
                resultItem.lexemes.push({id: item.root_entry.id, label: item.root_entry.description});
            });
        }

        this.props.callbackParentResetResult(resultItem);
    }

    _handleModernPhrase(evt) {
        if (this.state.isAssign)
            this.setState({isAssign: false});
        this._updateAddPhraseByValue("text", evt.target.value);
    }

    _handleOriginalPhrase(evt) {
        this._updateAddPhraseByValue("original_script", evt.target.value);
    }

    _handleLongRefPhrase(evt) {
        this._updateAddPhraseByValue("long_bibiographical_reference", evt.target.value);
    }

    _handleBibliographicalReference(item) {
        var phrase = this.state.phrase;
        phrase.bibliographical_reference = typeof item[0] !== 'undefined' ? item[0].label : "";

        this.setState(phrase);
    }

    _updateAddPhraseByValue(property, value) {
        var phrase = this.state.phrase;
        phrase[property] = value;
        this.setState({phrase});
    }

    _selectSense(senseToAssignId) {
        if (senseToAssignId === this.state.senseToAssignId)
            senseToAssignId = '';
        this.setState({senseToAssignId});
    }

    _openSenseLexeme(rootId) {
        //resetState();
        ReactDOM.unmountComponentAtNode(document.getElementById('phrase-editor-right-col'));
        this.setState({type: 'openLexeme', openLexemeId: rootId}, function () {
            Dict.call('/page/getPage/' + rootId + '/' + this.state.languageId + '/' + null).then(result => {
                const LexemePageConst = LexemeUtilities.getLexemePage(rootId, 'READ_ONLY', result.page, 'PHRASE_SEARCH', this.props.searchType);

                const RowRemove = <Row key={'key-row-detach-phrase-' + rootId}>
                    <Button id={"button-detach-phrase"}
                            bsStyle={this.state.type === 'assign' ? 'info' : 'default'}
                            onClick={() => {
                                document.getElementById("confirm-button-detach-phrase").style.display = 'inline';
                            }
                            }>{Dict.t('DETACH_PHRASE_FROM_LEXEME')}
                    </Button>
                    <span id={"confirm-button-detach-phrase"}
                          className={stylesPhraseEditor['phrase-editor__confirm-button-detach']}
                          style={{display: 'none'}}>
                    <span>{Dict.t('CONFIRM_DETACH_PHRASE')}</span>
                    <Button onClick={() => this._detachPhrase()}>
                        {Dict.t('YES')}
                    </Button>
                    <span>/</span>
                    <Button onClick={() => {
                        document.getElementById("confirm-button-detach-phrase").style.display = 'none';
                    }
                    }>
                        {Dict.t('NO')}
                    </Button>
                </span>
                </Row>;

                ReactDOM.render(
                    <div
                        id={'phrase-lexeme-page-const'}>{LexemePageConst}{RowRemove}</div>, document.getElementById('phrase-editor-right-col'));
            });

            /*<LexemePage key={"key-openlexeme-page-" + rootId}
                                                id={rootId}
                                                isUpToDate={true}
                                                pageStatus={LexemeEditingLevel.READ_ONLY}
                                                classType={this.props.searchType}
                                                dictionaryType={this.props.dictionaryType}
                                                entryAbstr={''}
                                                language={this.state.phrase.language_id}
                                                rootEntryId={rootId}
                                                forceSpinner={true}
                                                loadCallBack={function () {
                                                    document.getElementById("button-detach-phrase").style.visibility = "visible";
                                                    var height = document.getElementById("lexeme-page-container").clientHeight - 170;
                                                    document.getElementsByClassName(stylesLexemePage['scrollable-dict-contents'])[0].style.height = height + "px";
                                                }}
            />;*/


        }.bind(this));
    }

    render() {

        var biblRef = this.state.bibliographical_reference;
        var selectedBiblRef = typeof biblRef !== 'undefined' && (biblRef).length > 0 ? LexemeUtilities.getTypeaheadItemByLabel(biblRef, biblRef) : "";
        var openLexemeId = this.state.openLexemeId;
        const phraseSet = typeof this.state.phrase !== 'undefined' && Object.keys(this.state.phrase).length > 0;

        const LexemesLine = this.state.senses.map((item, index) => {
            const openLexeme = item.root_entry.id === openLexemeId;

            return <div key={'key-phraseEditor-senseView-' + index}
                        className={BUtils.joinClasses(stylesPhraseEditor['phraseEditor-senseView'], (openLexeme ? stylesPhraseEditor['phraseEditor-open-lexeme'] : ''))}
                        onClick={() => this._openSenseLexeme(item.root_entry.id)}>
                <span
                    className={BUtils.joinClasses(stylesPhraseEditor['phraseEditor-senseView__entry'], (openLexeme ? stylesPhraseEditor['phraseEditor-senseView__entry--open'] : ''))}>{item.root_entry.description}</span>
                <span
                    className={stylesPhraseEditor['phraseEditor-senseView__subentry']}>{item.subentry.description}</span>
                <span className={stylesPhraseEditor['phraseEditor-senseView__sense']}
                      dangerouslySetInnerHTML={{__html: item.sense.description}}/>
            </div>
        });


        return <Grid className={stylesPhraseEditor['phrase-editor']} fluid>
            {this.state.lexeme ? <Row className={stylesPhraseEditor['phrase-editor__row']}>
                <Col xs={2} md={2}
                     className={stylesPhraseEditor['phrase-editor__typeahead-label']}>{Dict.t('PLACE')}</Col>
                <Col xs={10} md={10} style={{paddingRight: 0}}
                     className={stylesPhraseEditor['phrase-editor__typeahead']}>
                    {(typeof this.props.additionalData === 'undefined') ?
                        <LanguageTypeahead ref="addPhraseTypehead"
                                           callSearch={"service/getLanguageDescendants/" + this.state.item.ids + "/" + true + "/"}
                                           callbackParent={(newState) => this._handlePhraseLanguage(newState)}/> : this.state.phrase.language}
                </Col>
            </Row> : ""}
            <Col id="phrase-editor-left-col" xs={5} md={5} className={"col"}>
                {phraseSet ? <Row className={stylesPhraseEditor['phrase-editor__lexemesline']}>
                    {LexemesLine}
                    <Button
                        bsStyle={this.state.type === 'assign' ? 'info' : 'default'}
                        onClick={() => this.setState({type: 'assign', openLexemeId: ''})}>{Dict.t('ASSIGN_NEW_SENSE')}
                    </Button>
                </Row> : ''}
                {phraseSet ? <Row className="edit-phrase-row">
                    <InputGroup className={stylesPhraseEditor["edit-phrase-edit-group"]}>
                        <InputGroup.Addon>{Dict.t(this.phraseOriginalScript ? 'MODERN_PHRASE' : 'PHRASE')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                     ref={(input) => {
                                         this.phraseText = input;
                                     }}
                                     value={this.state.phrase.text}
                                     onChange={(evt) => this._handleModernPhrase(evt)}
                        />
                    </InputGroup>
                </Row> : ''}
                {this.phraseOriginalScript && phraseSet ? <Row className="edit-phrase-row">
                    <InputGroup className={stylesPhraseEditor["edit-phrase-edit-group"]}>
                        <InputGroup.Addon>{Dict.t('ORIGINAL_SCRIPT')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                     ref={(input) => {
                                         this.phraseOriginal = input;
                                     }}
                                     value={this.state.phrase.original_script}
                                     onChange={(evt) => this._handleOriginalPhrase(evt)}
                        />
                    </InputGroup>
                </Row> : ""}
                {this.bibliographicalReference ? <Row className="edit-phrase-row">
                    <Col xs={4} md={4}
                         className="edit-phrase-typeahead-label">{Dict.t('BIBLIOGRAPHICAL_REFERENCE')}</Col>
                    <Col xs={8} md={8} style={{paddingRight: 0}} className="sense-add-phrase-typeahead">
                        <Typeahead
                            clearButton
                            ref={"addBibReferenceTypehead"}
                            align={"justify"}
                            onChange={item => this._handleBibliographicalReference(item)}
                            options={this.state.biblioReferences}
                            placeholder={Dict.t('BIBLIOGRAPHICAL_REFERENCE')}
                            selected={selectedBiblRef}
                        />
                    </Col>
                </Row> : ""}
                {this.phraseOriginalScript && phraseSet ? <Row className="edit-phrase-row">
                    <InputGroup className={stylesPhraseEditor["edit-phrase-edit-group"]}>
                        <InputGroup.Addon>{Dict.t('LONG_BIBLIOGRAPHICAL_REFERENCE')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                     ref={(input) => {
                                         this.phraseLong = input;
                                     }}
                                     value={this.state.phrase.long_bibiographical_reference}
                                     onChange={(evt) => this._handleLongRefPhrase(evt)}
                        />
                    </InputGroup>
                </Row> : ""}
                {phraseSet ? <Row className={stylesPhraseEditor['phrase-editor__row-bt-save']}>
                    <Button bsStyle={this.state.btSaveState} onClick={() => {
                        document.getElementById("confirm-button-delete-phrase").style.display = 'inline'
                    }}>
                        {Dict.t('DELETE')}
                    </Button>
                    <Button bsStyle={this.state.btSaveState} onClick={() => this._savePhrase(this.state.isAssign)}>
                        {Dict.t('SAVE')}
                    </Button>
                </Row> : ''}
                <Row id={"row-confirm-button-delete-phrase"}
                     className={stylesPhraseEditor['phrase-editor__row-confirm-button-delete-phrase']}>
                    <span id={"confirm-button-delete-phrase"}
                          className={stylesPhraseEditor['phrase-editor__confirm-button-delete-phrase']}
                          style={{display: 'none'}}>
                        <span>{Dict.t('CONFIRM_DELETE_PHRASE')}</span>
                        <Button onClick={() => this._deletePhrase()}>
                            {Dict.t('YES')}
                        </Button>
                        <span>/</span>
                        <Button onClick={() => {
                            document.getElementById("confirm-button-delete-phrase").style.display = 'none';
                        }
                        }>
                        {Dict.t('NO')}
                        </Button>
                    </span>
                </Row>
            </Col>
            <Col id="phrase-editor-right-col" lg={7} md={7} className={BUtils.joinClasses("col", stylesPhraseEditor['phrase-editor-right-col'])}>
                {this.state.type === 'assign' ? <div>
                    <Row>
                        <LexemeNavigator pageStatus={LexemeEditingLevel.SENSE_SELECTOR} useInGlobalDictionary={true}
                                         container={this.props.container} isStandAlone={true}
                                         classType={this.props.searchType}
                                         callbackParent={(selectableItem) => this._selectSense(selectableItem.ids)}
                                         languageId={this.state.phrase.language_id}/>
                    </Row>
                    <Row className={stylesPhraseEditor['phrase-editor__assign-button-row']}>
                        <Button
                            bsStyle={this.state.btSaveState}
                            className={typeof this.state.senseToAssignId === 'undefined' || Object.keys(this.state.senseToAssignId).length === 0 ? stylesPhraseEditor['phrase-editor__assign-hide'] : ''}
                            onClick={() => this._assignPhrase()}>{Dict.t('ASSIGN')}
                        </Button>
                    </Row>
                    <Row>
                        <div id={"phrase-editor-lexeme-container"}/>
                    </Row>
                    <Row>
                        <div id={"init-lexeme-container"} style={{display: 'none'}}/>
                    </Row>
                </div> : ''}
            </Col>
        </Grid>;
    }
}