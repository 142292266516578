import React from 'react';
import {AdvancedFields} from './../root-entry/AdvancedFields';
import './LocVars.css';


export class LocVarAdvancedFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }


    render() {


        return <div className="loc-var-advanced-fields-container"><AdvancedFields id={this.props.item.id}/></div>
    }
}