exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\r\n.createLexeme-module__dict-create-lexeme___3iXhm {\r\n\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm .modal-body {\r\n    height: 75px;\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm .modal-body > div {\r\n    vertical-align: bottom;\r\n    height: 40px;\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm .modal-body > div:first-child {\r\n    padding-top: 2px;\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm .modal-body > div:nth-child(2) {\r\n    padding-top: 2px;\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm .modal-body > div:nth-child(3) {\r\n    padding-top: 3px;\r\n    text-align: right;\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm .modal-body > div:nth-child(3) button {\r\n    min-width: 90px;\r\n    border: 1px solid;\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm input.form-control {\r\n}\r\n\r\n.createLexeme-module__dict-create-lexeme___3iXhm span.input-group-addon {\r\n    width: 110px !important;\r\n    font-size: 12px;\r\n    padding-left: 5px !important;\r\n    padding-right: 5px !important;\r\n}\r\n\r\n.createLexeme-module__create-lexeme__div-sup___1IQuY {\r\n    display: table-cell;\r\n    vertical-align: top;\r\n}\r\n\r\n.createLexeme-module__create-lexeme__sup___1lkJ- {\r\n    padding-left: 3px;\r\n}\r\n\r\n.createLexeme-module__create-lexeme__sup-first___2cw6V {\r\n    font-size: 12px;\r\n    font-weight: bold;\r\n}\r\n\r\n.createLexeme-module__create-lexeme__sup-second___Ew1BP {\r\n    font-size: 10px;\r\n    vertical-align: 2px;\r\n}\r\n\r\n.createLexeme-module__create-lexeme-lexhomo--0___1B0RJ {\r\n    visibility: hidden;\r\n}\r\n\r\n.createLexeme-module__create-lexeme__var-is-primary___1VG9i {\r\n    visibility: hidden !important;\r\n}\r\n\r\n.createLexeme-module__create-lexeme-varhomo--0___3xCnd {\r\n    visibility: hidden;\r\n}", ""]);

// Exports
exports.locals = {
	"dict-create-lexeme": "createLexeme-module__dict-create-lexeme___3iXhm",
	"create-lexeme__div-sup": "createLexeme-module__create-lexeme__div-sup___1IQuY",
	"create-lexeme__sup": "createLexeme-module__create-lexeme__sup___1lkJ-",
	"create-lexeme__sup-first": "createLexeme-module__create-lexeme__sup-first___2cw6V",
	"create-lexeme__sup-second": "createLexeme-module__create-lexeme__sup-second___Ew1BP",
	"create-lexeme-lexhomo--0": "createLexeme-module__create-lexeme-lexhomo--0___1B0RJ",
	"create-lexeme__var-is-primary": "createLexeme-module__create-lexeme__var-is-primary___1VG9i",
	"create-lexeme-varhomo--0": "createLexeme-module__create-lexeme-varhomo--0___3xCnd"
};