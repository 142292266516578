import React from 'react';
import Dict from 'dict-lib';
import ReactDom from 'react-dom';
import {getLexemeProperties, getLexemeOptions} from './../../LexemePage';
import {Grid, Row, InputGroup, FormControl, Button} from 'react-bootstrap';
import {LexemeUtilities, getContextTypes, LexemeEditingLevel, translate} from './../../editor/LexemeHelper';
import {addNewLocSense} from './SenseAction';
import {addNewSense} from './../subentries/SubentryActions';
import {LanguageTreeViewPlus, SCSubmitButton} from './../../../../dict-components/components';
import './../../../../_css/lexeme.css';
import './../../parts/03_Subentries/parts/LexemeSenses.css';
import BUtils from './../../../../utilities/BaseUtilities';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';


export class SenseAdd extends React.Component {

    constructor(props) {
        super(props);

        var isNew = typeof this.props.additionalData !== 'undefined' && this.props.additionalData.isNew;
        var call = isNew ? 'service/getLanguageTreeView/' : 'service/getLanguageTreeViewbySenses/' + this.props.item.ids + '/';

        this.state = {
            item: this.props.item,
            call: call,
            isNew: isNew,
            text: "",
            saving: false,
            asComponent: typeof this.props.asComponent === 'undefined' ? false : this.props.asComponent,
            isTranslationSelector: typeof this.props.context !== 'undefined' && this.props.context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR
        };

        this.defabs = [];
        this.textExist = false;

        this._handleAdd = this._handleAdd.bind(this);
        this._handleSenseAbs = this._handleSenseAbs.bind(this);
    }

    componentWillMount() {
        if (this.state.isNew) {
            const descrLang = typeof this.props.context.descrLanguage === 'undefined' ? this.props.context.dictProperties.language_code : this.props.context.descrLanguage;
            this.props.item.senses.map((item) => {
                var descr = item.description[descrLang];
                if (typeof descr === 'undefined')
                    descr = '';
                this.defabs.push(descr.toLowerCase())
            });
        }
    }

    componentDidMount() {
        if (this.state.isNew)
            ReactDom.findDOMNode(this.senseAdd).focus();
    }

    _handleSenseAbs(evt) {
        var text = evt.target.value;
        this.textExist = evt.target.value.trim().length > 0 && (evt.target.value.toLowerCase() !== this.state.text.toLowerCase() && this.defabs.indexOf(evt.target.value.toLowerCase()) > -1);
        this.setState({text});
    }

    _handleAdd(rootId, addList) {
        if (this.state.asComponent) {
            this.props.callbackParent(this.state.text, addList);
        } else {
            this.setState({saving: true}, () => {
                const isTree = getLexemeProperties("is_tree_model");
                if (addList.length > 0 || isTree) {
                    if (this.state.isNew) {
                        if (this.textExist) {
                            LexemeUtilities.showModalMessage("error", 'ignore:' + Dict.t('SENSE_ABS_FOUND'), 2000);
                        } else {
                            const addSensePromise = addNewSense(this.props.item, addList, this.state.text, this.props.context.isTree, this.state.isTranslationSelector);
                            addSensePromise.then(result => {
                                if (this.state.isTranslationSelector) {
                                    var newLexSenseIds = JSON.parse(JSON.parse(result.additionalInfos).newLexSense).ids;
                                    translate(this.props.context.startItem, newLexSenseIds);
                                }
                                this.setState({saving: false});
                            });
                        }
                    } else {
                        addNewLocSense(this.props.item, rootId, addList);
                    }
                }
            });
        }
    }

    render() {

        return <Grid className={stylesLexemeSenses['grid-sense-add' + (this.state.isNew ? '--new' : '')]} fluid>
            {this.state.isNew ? <Row className={stylesLexemeSenses['row-sense-add']}><InputGroup
                className={stylesLexemeSenses['sense-add']}>
                <InputGroup.Addon>{Dict.t('SENSE')}</InputGroup.Addon>
                <FormControl type="text" placeholder={Dict.t('TEXT') + "..."}
                             ref={(input) => {
                                 this.senseAdd = input;
                             }}
                             value={this.state.text}
                             onChange={(evt) => this._handleSenseAbs(evt)}
                             onKeyUp={(evt) => {
                                 LexemeUtilities.handleKeyUp(evt, () => {
                                     this._handleAdd(this.props.context.rootEntryId, this.props.context.languageId);
                                 })
                             }}
                />
            </InputGroup></Row> : ""}
            {getLexemeProperties("is_tree_model") && !this.state.isTranslationSelector ?
                <LanguageTreeViewPlus forceCall={true} item={this.props.item} call={this.state.call}
                                      btName={Dict.t('ADD')}
                                      callbackParent={(rootId, addList) => this._handleAdd(rootId, addList)}
                />
                : <Row
                    className={BUtils.joinClasses(stylesLexemeSenses['row-sense-add-button'], stylesLexemeSenses['row-sense-add-button-saving--' + this.state.saving])}>
                    <SCSubmitButton
                        onClick={() => this._handleAdd(this.props.context.rootEntryId, this.props.context.languageId)}
                        text={Dict.t(this.state.isTranslationSelector ? 'ADD_AND_TRANSLATE' : 'SAVE')}/>
                </Row>}
        </Grid>;
    }
}