/*import {initialState} from './index';*/
import Dict from 'dict-lib';


export const initialState = {
    id: {},
    language: {},
    dictionaryType: {},
    tabs: [],
    page: '',
    status: 'NEW'
};

function addTab(action) {
    return {
        id: action.id,
        language: action.language,
        languageCode: action.languageCode,
        dictionaryType: action.dictionaryType,
        classType: action.classType,
        entryForm: action.entryForm,
        page: '',
        tabs: action.tabs,
        tabIndex: action.tabIndex,
        status: action.type,
        pageStatus: action.pageStatus,
        isUpToDate: action.isUpToDate,
        forceSpinner: action.forceSpinner,
        loadCallBack: action.loadCallBack
    }
}

function getPage() {
    return initialState;
}

function openPage(state, action) {
    return {
        id: action.id,
        language: action.language,
        dictionaryType: action.dictionaryType,
        classType: action.classType,
        entryForm: action.entryForm,
        page: action.result.page,
        tabs: state.tabs,
        status: action.type,
        pageStatus: action.pageStatus,
        tabIndex: action.tabIndex,
        descrLanguage: state.descrLanguage
    }
}

function setPage(state, action) {
    return {
        id: action.id,
        language: action.language,
        dictionaryType: action.dictionaryType,
        entryForm: action.entryForm,
        page: action.result.page,
        tabs: state.tabs,
        status: action.type,
        pageStatus: action.pageStatus,
        tabIndex: state.tabIndex,
        descrLanguage: action.descrLanguage
    }
}

function changeTab(state, action) {

    return {
        id: state.id,
        language: state.language,
        dictionaryType: state.dictionaryType,
        entryForm: state.entryForm,
        page: state.tabs[action.tabIndex].page,
        tabs: state.tabs,
        pageStatus: state.pageStatus,
        status: action.type,
        tabIndex: action.tabIndex
    }
}

function removeTab(state, action) {

    return {
        id: state.id,
        language: state.language,
        dictionaryType: state.dictionaryType,
        entryForm: state.entryForm,
        pageStatus: state.pageStatus,
        tabIndex: action.tabIndex,
        page: action.tabs.length === 0 ? '' : action.tabs[action.tabIndex].page,
        tabs: action.tabs,//action.tabs.length === 0 ? [] : action.tabs,
        status: action.type,
    }
}

function resetPage(state) {
    return {
        id: state.id,
        language: state.language,
        dictionaryType: state.dictionaryType,
        page: state.page,
        status: state.type
    }
}

function setSubentries(state, action) {
    var page = JSON.parse(JSON.stringify(state.page));
    page.subentries = action.result;

    return {
        id: state.id,
        language: state.language,
        dictionaryType: state.dictionaryType,
        page: page,
        status: state.type,
        pageStatus: state.pageStatus
    }
}

function setSenses(state, action) {
    var page = JSON.parse(JSON.stringify(state.page));
    var subentry = page.subentries.filter(function (item) {
        return item.ids === action.result.subentryIds;
    })[0];
    subentry.senses = action.result.senses;

    return {
        id: state.id,
        language: state.language,
        dictionaryType: state.dictionaryType,
        page: page,
        status: state.type
    }
}

function resetState() {
    return initialState;
}

function setPolies(state, action) {
    var page = JSON.parse(JSON.stringify(state.page));
    page.polyrhematics = action.result;

    return {
        id: state.id,
        language: state.language,
        dictionaryType: state.dictionaryType,
        page: page,
        status: state.type
    }
}

function testPage(action) {
    return {
        page: action.result,
        status: action.type
    }
}

export default function rdcEntry(state = initialState, action) {

    var newState;

    switch (action.type) {
        case 'GET_PAGE':
            return getPage();
        case 'ADD_TAB':
            newState = addTab(action);
            return newState;
        case 'OPEN_PAGE':
            newState = openPage(state, action);
            return newState;
        case 'SET_PAGE':
            newState = setPage(state, action);
            return newState;
        case 'CHANGE_TAB':
            newState = changeTab(state, action);
            return newState;
        case 'REMOVE_TAB':
            newState = removeTab(state, action);
            return newState;
        case 'RESET_PAGE':
            return resetPage(state);
        case 'TEST_PAGE':
            return testPage(action);
        case 'SET_SUBENTRIES':
            return setSubentries(state, action);
        case 'SET_SENSES':
            return setSenses(state, action);
        case 'SET_POLIES':
            return setPolies(state, action);
        case 'RESET_STATE':
            return resetState();
        default:
            return state;
    }
}