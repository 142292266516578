import React from 'react';
import ReactDom from 'react-dom';
import {LexemePageContext} from "../../../LexemePageContext";
import Dict from 'dict-lib';
import {
    OverlayTrigger,
    Popover,
    Row,
    Col,
    InputGroup,
    FormControl,
    Button,
    Glyphicon,
    Modal,
    Image,
    Grid
} from 'react-bootstrap';
import {getLexemeProperties, getDictionaryProperties} from './../../../LexemePage';
import {
    LexemeUtilities,
    ToolbarTypes,
    getContextTypes,
    LexemePageInfos,
    LexemeEditingLevel, LexemeDisplayContext
} from './../../../editor/LexemeHelper';
import {LexemePhrase} from './LexemePhrase';
import {LexemeTranslation} from './LexemeTranslation';
import {LexemeSemanticReferences} from './LexemeSemanticReferences';
import {setDefabs, changeStatus} from './../../../editor/senses/SenseAction';
import './LexemeSenses.css';
import './../../../editor/LexemeEditor.css';
import './../../../../../_css/lexeme.css';

import stylesLexemeSenses from './../../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import stylesLexemePhrases from './../../../../../_css/dictionary/lexemeParts/lexemePhrases.module.css';
import stylesLexemeEditor from './../../../../../_css/dictionary/lexemeEditor.module.css';
import BUtils from './../../../../../utilities/BaseUtilities';
import {userService} from "../../../../../_redux/authentication/services/user.services";
import {Notifications} from "../../../../../utilities/DictUtilities";
import {AdministrationUtilities} from "../../../../../administration/AdministrationHelper";

export class LexemeSenses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "SENSE",
            item: {},
            modalPlayShow: false,
        };

        this.defabs = [];
        this.img = "";

        this._isActive = this._isActive.bind(this);
        this._senseDescrClick = this._senseDescrClick.bind(this);
        this._openPlayPhrases = this._openPlayPhrases.bind(this);
    }

    componentWillMount() {
        this._preload();
    }

    componentWillReceiveProps() {
        this._preload();
    }

    _senseDescrClick(item) {
        var id = "id-popover-edit-senses";
        if (LexemeUtilities.isPageEditable(this)) {
            const lexSenses = this.props.isInherited ? this.props.inherited_senses : this.props.senses;
            this._setDefabs(lexSenses);
            var myDefabs = this.defabs.slice(0);
            return <Popover /*{...this.props}*/ className={"popover-edit-text"} id={id}>
                <EditSense defabs={myDefabs} item={item} context={this.context}
                           description={this._getDescriptionByDescrLanguage(item)}
                           callbackParent={(newState) => this.props.callbackParent()}/>
            </Popover>;
        } else
            return LexemeUtilities.getHiddenPopover(id);
    }

    _preload() {
        if (this.context.displayContext === LexemeDisplayContext.DICTIONARY_SEARCH) {
            if (Object.keys(this.props.senses).length > 0) {
                this.props.senses.filter(item => {
                    return Object.keys(item.multimedia_file).length > 0;
                }).map(item => {
                    var src = item.multimedia_file;
                    this.img = <Image src={src} responsive/>;

                    ReactDom.render(this.img, document.getElementById("dict-preload-image"), function () {
                        //ReactDom.unmountComponentAtNode(document.getElementById("dict-preload-image"));
                    });
                });
            }
        }
    }

    _openStatusPopover(item) {
        return <Popover positionLeft={200} positionTop={100} className={stylesLexemeSenses['popover-sense-status']}
                        id={"status-sense-id"}>
            <StatusSense item={item}/>
        </Popover>;
    }

    _openSenseImage(item) {
        if (this.context.displayContext === LexemeDisplayContext.DICTIONARY_SEARCH) {
            var descriptionLanguage = getDictionaryProperties('language_code');
            var id = "id-popover-image-sense";
            var title = item.imgTitle.filter(itm => {
                return itm.language === descriptionLanguage;
            })
            var caption = item.imgCaption.filter(itm => {
                return itm.language === descriptionLanguage;
            })

            if (title.length === 0) {
                title = this._getDescriptionByDescrLanguage(item);
            } else {
                title = title[0].description;
            }       
        }

        return <Popover positionLeft={200} positionTop={100} className={stylesLexemeSenses['popover-sense-image']}
                        id={id}>
            <Image src={item.multimedia_file} responsive/><Grid
            className={stylesLexemeSenses['popover-sense-image-info-container']}
            fluid><Row
            className={"popopver-image-title"}>{title}</Row><Row
            className={"popopver-image-caption"}>{caption}</Row></Grid>
        </Popover>;
    }

    _setDefabs(lexSenses) {
        this.defabs = [];
        lexSenses.map((item) => {
            var descr = this._getDescriptionByDescrLanguage(item);
            if (typeof descr !== 'undefined')
                this.defabs.push(descr.toLowerCase())
        });
    }

    _handleStatusClick(item, event) {
        const active = document.getElementsByClassName(stylesLexemeSenses['choose-sense-status-grid']);
        if (active.length > 0) {
            var id = (active[0].id + "").replace("choose-sense-status-", "");
            if (id !== item.ids)
                document.getElementById("sense-status-ids-" + id).click();
        }

        this.setState({
            stautsItem: item
        });
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _isActive(item) {
        return LexemeUtilities.isActive(this, item);
    }

    _handleClick(item, isTranslationSelector, event) {
        if (/*!isTranslationSelector && */!event.target.classList.contains(stylesLexemeSenses['lexTextEditable'])) {
            this.setState({
                item: LexemeUtilities.handleClick(this, item)
            });
        }
    }

    _onExit(item) {
        if (this.state.item === item) {
            this.setState({
                item: ''
            })
        }
    }

    _phrasesHasSound(phrases) {
        return phrases.filter(function (item) {
            return Object.keys(item.multimedia_file).length > 0
        }).length > 0;
    }

    _openPlayPhrases() {
        ReactDom.findDOMNode(this.senseOpenPhraseSound).blur();
        this.setState({modalPlayShow: true});
    }

    _isEmpty(description) {
        return typeof description === "undefined" || description.replace(/-/g, '').replace('(', '').replace(')', '').trim().length === 0;
    }

    _getDescriptionByDescrLanguage(itemSense) {
        var contextDescrLanguage = this.context.descrLanguage;

        var code = typeof contextDescrLanguage !== 'undefined' ? contextDescrLanguage : AdministrationUtilities.getDescriptionLanguage(this.context.languageId).code;
        var itemDescr = itemSense.description.find(
            (item) => {
                return item.language === code
            });

        return typeof itemDescr !== 'undefined' && itemDescr !== null ? itemDescr.description : '';
    }


    render() {
        const isDisplayContextDictionarySearch = LexemeUtilities.isDisplayContextDictionarySearch(this);
        const isLexeme = LexemePageInfos.getLexemeProperties(this.context, 'lexeme');
        var keyCounter = 1;
        var additionalClass = '';
        const editable = LexemeUtilities.isPageEditable(this);
        const translatorSelector = LexemeUtilities.isTranslationSelector(this);
        const senseViewClass = editable ? 'sense-edit-view' : 'sense-ro-view';
        const senseDivViewClass = editable ? 'sense-edit-view__div' : 'sense-ro-view__div';
        const senseTrViewClass = editable ? 'senseTransls--editable' : 'senseTransls--ro';
        const senseTrExportViewClass = isDisplayContextDictionarySearch ? 'senseTransls--dict-search' : 'senseTransls--export';
        const isLogged = userService.isLogged();
        const hideToHidden = Dict.getSharedOptions().HIDE_HIDDEN_ITEMS_TO_USERS === 'true';

        const isPolyFromLexeme = typeof this.props.isPolyFromLexeme === 'undefined' ? false : this.props.isPolyFromLexeme;

        if (isPolyFromLexeme) {
            additionalClass += ' ' + stylesLexemeSenses['polySense'] + ' ';
        }

        if (this.props.isInherited) {
            additionalClass += ' ' + stylesLexemeSenses['lexemeInheritedSense'] + ' ';
        }

        const lexSenses = this.props.isInherited ? this.props.inherited_senses : this.props.senses;
        var i = 1;
        var i2 = 1;
        var i3 = 1;
        var i4 = 1;

        var editablePostFix = '';
        if (editable) {
            editablePostFix = 'editable';
        } else if (translatorSelector) {
            editablePostFix = 'translator_selector';
        } else

            var ButtonPhrases = "";
        if (this.context.displayContext === LexemeDisplayContext.DICTIONARY_SEARCH) {
            ButtonPhrases = <Button className={stylesLexemeSenses['sense-play-phrases__button']}
                                    ref={(button) => {
                                        this.senseOpenPhraseSound = button;
                                    }}
                                    onClick={() => {
                                        this._openPlayPhrases()
                                    }}><Glyphicon
                glyph={"triangle-right"}/></Button>
        }


        var senseList = lexSenses.map((item) => {

            var isActiveClass = this._isActive(item);
            var activeEditableClass = BUtils.joinClasses(stylesLexemeSenses['lexemeSense--' + editablePostFix + '-' + (isActiveClass.length > 0 ? 'active' : 'not-active')], isActiveClass);

            var itemDescription = this._getDescriptionByDescrLanguage(item);
            var isEmpty = this._isEmpty(itemDescription);
            var isEmptyValue = isEmpty ? 'emptyAbs' : 'notEmptyAbs';
            var lexClassClean = LexemeUtilities.getLexClass(this);
            var lexClass = stylesLexemeSenses[lexClassClean];
            const phraseologies = isLogged && !hideToHidden ? item.phraseologies : item.phraseologies.filter((phrase) => {
                return !phrase.is_hidden;
            });

            var hiddenClass = '';
            if (item.is_hidden)
                hiddenClass = isLogged && hideToHidden ? stylesLexemeSenses['lexemeHiddenSense'] : 'hidden-item';

            return <div
                className={BUtils.joinClasses(stylesLexemeSenses['lexemeSense'], hiddenClass/*, stylesLexemeSenses['lexemeSense--' + this.context.pageStatus.toLowerCase()]*/)}
                key={"key-lexSenseOverlay-" + item.ids}>
                <div
                    className={BUtils.joinClasses(stylesLexemeSenses[senseViewClass], stylesLexemeSenses['sense-edit-view__overlay'])}>
                    {isPolyFromLexeme ? "" :
                        <OverlayTrigger key={"key-lexSenseOverlay" + keyCounter++}
                                        rootClose={false}
                                        trigger={isPolyFromLexeme ? "" : "click"}
                                        onExit={() => isPolyFromLexeme ? "" : this._onExit(item)}
                                        container={document.getElementById("modal-poly-edit")}
                                        placement={(editable ? "bottom" : "right")}
                                        overlay={this._createButtonBarTooltip()}>
                            <div
                                className={BUtils.joinClasses(stylesLexemeSenses['lexemeSense--' + editablePostFix], (isPolyFromLexeme ? '' : ' ' + lexClass + ' '), activeEditableClass, additionalClass)}
                                key={"sense-" + this.props.ids + "-" + i}
                                onClick={(event) => {
                                    this._handleClick(item, translatorSelector, event);
                                }}
                            >
                                <Glyphicon glyph="comment"/><Glyphicon glyph="triangle-right"/><Glyphicon
                                glyph="triangle-right"/>
                                <div
                                    className={BUtils.joinClasses(stylesLexemeSenses['senseOrder'], additionalClass)}>{item.is_hidden ? "" : i++}</div>
                            </div>
                        </OverlayTrigger>}
                </div>
                <div
                    className={BUtils.joinClasses(stylesLexemeSenses[senseViewClass], stylesLexemeSenses['sense-edit-view__descr'])}>
                    {this.props.isPolyEdit || !editable ?
                        <div id={"sense-status-ids-" + item.ids}
                             key={"key-div-edit-status-" + item.ids}
                             className={stylesLexemeSenses[senseDivViewClass]}
                             onClick={(event) => {
                                 LexemeUtilities.showModalMessage('warning', 'WARNING_CHANGE_STATUS_POLY', 2000);
                             }}>&nbsp;</div> :
                        <OverlayTrigger key={"key-lexSenseStatusOverlay" + keyCounter++}
                                        rootClose={!isPolyFromLexeme}
                                        trigger={"click"}
                                        container={document.getElementById("modal-poly-edit")}
                                        placement={"right"}
                                        overlay={this._openStatusPopover(item)}>
                            <div id={"sense-status-ids-" + item.ids}
                                 key={"key-div-status-" + item.ids}
                                 className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseStatus'], stylesLexemeSenses['senseStatus--status' + item.status])}
                                 onClick={(event) => {
                                     this._handleStatusClick(item, event)
                                 }}>&nbsp;</div>
                        </OverlayTrigger>}
                    {isPolyFromLexeme ?
                        <div className={stylesLexemeSenses['sense-edit-view__poly-view']}>{isEmpty ? '' : itemDescription}</div> :
                        <OverlayTrigger
                            rootClose={!isPolyFromLexeme}
                            key={"key-overlay-sense-" + item.ids}
                            trigger={"click"}
                            placement={editable ? "bottom" : "right"}
                            container={document.getElementById("modal-poly-edit")}
                            overlay={isPolyFromLexeme ? this._createButtonBarTooltip() : this._senseDescrClick(item)}
                        >
                            <div id={"su-ids-" + item.ids} ref="senseDescrRef" onClick={(event) => {
                                this._handleClick(item, translatorSelector, event)
                            }}
                                 className={BUtils.joinClasses('lexHighlightable', stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseDescr--' + isEmptyValue], stylesLexemeSenses['senseDescr'], (isPolyFromLexeme ? (translatorSelector ? stylesLexemeSenses[LexemeUtilities.getLexClass(this)] + " " + activeEditableClass : "") : " " + stylesLexemeSenses[LexemeUtilities.getLexTextClass(this)] + " "), additionalClass)}>{isEmpty ? '' : itemDescription}</div>
                        </OverlayTrigger>}
                    {typeof item.topic === 'undefined' ? "" :
                        <div
                            className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseItemTopic'])}>{item.topic.description}</div>}
                    {typeof item.register_usage !== 'undefined' && typeof item.register_usage.description !== 'undefined' ?
                        <div
                            className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseItemUsage'])}>{item.register_usage.description}</div> : ""}
                    {typeof item.domain_usage !== 'undefined' && typeof item.domain_usage.description !== 'undefined' ?
                        <div
                            className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseItemUsage'])}>{item.domain_usage.description}</div> : ""}
                    {typeof item.frequency_usage !== 'undefined' && typeof item.frequency_usage.description !== 'undefined' ?
                        <div
                            className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseItemUsage'])}>{item.frequency_usage.description}</div> : ""}
                    {typeof item.chronological_usage !== 'undefined' && typeof item.chronological_usage.description !== 'undefined' ?
                        <div
                            className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseItemUsage'])}>{item.chronological_usage.description}</div> : ""}
                    {item.dating > 0 ? <div className={stylesLexemeSenses['sense-dating']}>{item.dating}</div> : ""}
                    {Object.keys(item.multimedia_file).length > 0 ?
                        <div
                            className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['senseImage'])}>
                            <OverlayTrigger
                                rootClose={!isPolyFromLexeme}
                                key={"key-overlay-sense-image-" + item.ids}
                                trigger={"click"/*["hover", "focus"]*/}
                                placement={"right"}
                                container={document.getElementById("modal-poly-edit")}
                                overlay={this._openSenseImage(item)}
                            ><Glyphicon glyph="picture"/></OverlayTrigger>
                        </div> : ""}
                    {isLexeme ?
                        <div
                            className={BUtils.joinClasses(stylesLexemeSenses[senseDivViewClass], stylesLexemeSenses['sense-langs-group'])}>{item.local_senses.map((itemLang) =>
                            <div
                                className={BUtils.joinClasses(stylesLexemeSenses['senseLangs'], 'senseCommaLang')}
                                key={"sense-" + this.props.ids + "-" + i + "-" + i2++}>{isLexeme ?
                                <span id={'senseItemLang_' + itemLang.id}
                                      className={BUtils.joinClasses(stylesLexemeSenses['senseItemLang'], 'lexHighlightable')}>{itemLang.language}</span> : ""}<span
                                className={BUtils.joinClasses(stylesLexemeSenses['senseItemNotes'], (typeof itemLang.notes !== 'undefined' && itemLang.notes.length > 0 ? stylesLexemeSenses['senseItemNotes--hasContent'] : ''))}>{itemLang.notes}</span>
                            </div>)}</div> : <div
                            className={stylesLexemeSenses['senseItemNotes']}>{typeof item.local_senses[0].notes !== 'undefined' && item.local_senses[0].notes.length > 0 ? '(note:' + item.local_senses[0].notes + ')' : ''}</div>}
                    <div
                        key={"key-div-sense-translations-" + item.ids}
                        className={BUtils.joinClasses(stylesLexemeSenses['senseTransls'], stylesLexemeSenses[senseTrViewClass], stylesLexemeSenses[senseTrExportViewClass])}
                    >
                        <LexemeTranslation key={"key-sense-translations-" + item.ids} suItem={item} isSense={true}
                                           isPolyFromLexeme={isPolyFromLexeme}/>
                    </div>
                    {phraseologies.length > 0 ?
                        <>
                            <div
                                className={stylesLexemePhrases['sense-phrase-label'] + ' ' + stylesLexemePhrases['sense-phrase-label--' + (isPolyFromLexeme ? 'poly' : 'mono')]}>{Dict.t('PHRASEOLOGY') + ":"}{this._phrasesHasSound(phraseologies) ?
                                ButtonPhrases : ""}
                            </div>
                            <ModalPlayPhrases items={phraseologies}
                                              show={this.state.modalPlayShow}
                                              callbackParent={(newState) => {
                                                  this.setState({modalPlayShow: newState})
                                              }}
                                              senseDescr={itemDescription}/>
                            <div className={stylesLexemeSenses['sense-phrases']}>
                                <LexemePhrase key={"key-sense-phrases-" + item.ids} suItem={item} isSense={false}
                                              isPolyFromLexeme={isPolyFromLexeme}/>
                            </div>
                        </> : ""}
                    {item.semantic_unit_references.map((itemRef, index) => <LexemeSemanticReferences
                        key={"link-between-senses-" + itemRef.id + "-" + i3++} way={'direct'} itemRef={itemRef}
                        itemSense={item}/>)}
                    {item.inverse_semantic_unit_references.map((itemRef, index) => <LexemeSemanticReferences
                        key={"link-between-senses-inv-" + itemRef.id + "-" + i4++} way={'inverse'} itemRef={itemRef}
                        itemSense={item}/>)}
                </div>
            </div>
        });


        return <div className={stylesLexemeSenses['lexemeSenses'] + ' ' + additionalClass}>{senseList}
        </div>;
    }
}

class EditSense extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            description: this.props.description,
            showComponent: true,
            defabs: this.props.defabs
        };

        this.textExist = false;
        this.placeHolder = typeof this.props.context.descrLanguage === 'undefined' ? this.props.context.dictProperties.language_code : this.props.context.descrLanguage;

        this._handleSenseAbs = this._handleSenseAbs.bind(this);
        this._handleKeyUp = this._handleKeyUp.bind(this);
    }

    componentWillMount() {
        if (document.getElementById("lex-sense-button-bar")) {
            document.getElementById("lex-sense-button-bar").classList.remove("in");
        }
    }

    componentDidMount() {
        var x = window.scrollX, y = window.scrollY;
        ReactDom.findDOMNode(this.senseAbs).focus();
        ReactDom.findDOMNode(this.senseAbs).value = "";
        ReactDom.findDOMNode(this.senseAbs).value = this.state.description;
        window.scrollTo(x, y);
        /*setTimeout(function () {
         document.getElementById("lex-sense-button-bar").classList.remove("in");
         }, 0);*/
    }


    _handleSenseAbs(evt) {
        var propsDescr = typeof this.props.description !== 'undefined' ? this.props.description[this.descrLanguage] : '';
        this.textExist = evt.target.value.trim().length > 0 && (evt.target.value.toLowerCase() !== propsDescr && this.state.defabs.indexOf(evt.target.value.toLowerCase()) > -1);

        var description = evt.target.value;

        this.setState({description});
    }

    _handleKeyUp(evt) {
        if (evt.keyCode === 13) {
            if (this.textExist) {
                LexemeUtilities.showModalMessage("error", Dict.t('SENSE_ABS_FOUND'), 2000);
            } else {
                var descrLang = this.props.context.descrLanguage;
                if (typeof descrLang === 'undefined')
                    descrLang = AdministrationUtilities.getDescriptionLanguage(this.props.context.languageId).code;

                setDefabs(this.props.item.ids, this.state.description, descrLang);
                this._handleClose();
            }
        } else if (evt.keyCode === 27) {
            //this.props.callbackParent();
            this._handleClose();
        }
    }

    _handleClose() {
        var el = document.getElementsByClassName("popover-edit-text")[0];
        el.classList.add("popover-edit-text-fade-out");

        setTimeout(function () {
            document.getElementsByClassName(stylesLexemeSenses['lexemeSenses'])[0].click();
        }.bind(this), 400);
    }

    _isEmpty(description) {
        return typeof description === "undefined" || description.replace(/-/g, '').replace('(', '').replace(')', '').trim().length === 0;
    }

    render() {

        var length = (this.state.description + "").length;
        var width = 200 * (parseInt(length / 26) + 1) + 20;
        const isEmpty = this._isEmpty(this.state.description);
        var value = isEmpty ? '' : this.state.description;

        return <div ref={"senseEditContainer"} id="sense-edit-container-id"
                    className={stylesLexemeSenses[this.state.showComponent ? 'sense-edit-container' : 'sense-edit-hide']}>
            <InputGroup className={stylesLexemeSenses['sense-edit__input-group']}>
                <FormControl style={{width: width}}
                             type="text"
                             placeholder={this.placeHolder}
                             ref={(input) => {
                                 this.senseAbs = input;
                             }}
                             value={value}
                             onChange={(evt) => this._handleSenseAbs(evt)}
                             onKeyUp={this._handleKeyUp}/>
            </InputGroup>
        </div>
    }
}

class StatusSense extends React.Component {

    constructor(props) {
        super(props);

        this.state = {item: this.props.item};

        this.statusTypes = ['APPROVED', 'NOT_APPROVED', 'TO_REVISE'];
    }

    _hoverSenseStatus(status) {
        document.getElementById('choose-sense-status-description').textContent = Dict.t(status);
    }

    _changeStatus(status) {
        changeStatus(this.props.item, status).then(result => {
            document.getElementById("sense-status-ids-" + this.props.item.ids).click();
        });
    }

    render() {

        const itemStatus = this.props.item.status;

        const validStatuts = this.statusTypes.filter(status => {
            return status !== itemStatus;
        }).map(status => {
            return <div key={'key-choose-sense-' + status} onClick={() => this._changeStatus(status)}
                        onMouseOver={() => this._hoverSenseStatus(status)}
                        onMouseOut={() => document.getElementById('choose-sense-status-description').innerHTML = '&nbsp;'}
                        className={BUtils.joinClasses(stylesLexemeSenses['chooseSenseStatus'], stylesLexemeSenses['senseStatus'], stylesLexemeSenses['senseStatus--status' + status])}>&nbsp;</div>;
        });

        return <Grid id={"choose-sense-status-" + this.props.item.ids}
                     className={stylesLexemeSenses['choose-sense-status-grid']} fluid>
            <Row id="choose-sense-status-description"
                 className={stylesLexemeSenses['choose-sense-status-description']}>&nbsp;</Row>
            <Row>{validStatuts}</Row>
        </Grid>
    };
}

class ModalPlayPhrases extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            modalPlayShow: this.props.modalPlayShow,
            itemPlayingId: "",
            isPlaying: false,
            isPause: false
        };

        this._openPlayPhrases = this._openPlayPhrases.bind(this);
        this._playList = this._playList.bind(this);
    }

    componentWillMount() {
        this.setState({
            itemPlayingId: "",
        });
    }

    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }*/

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.show && this.props.show) {
            const items = this.props.items.filter(function (item) {
                return Object.keys(item.multimedia_file).length > 0
            });

            this.setState({items});

            setTimeout(() => {
                this._playList(0);
            }, 400);
        }
    }

    componentDidMount() {
        /*this._playList(0);*/
    }

    _playList(index) {
        var item = this.props.items[index];
        if (item.multimedia_file.length > 0) {
            var audio = ReactDom.findDOMNode(this['playPhrasesAudio' + item.id]);
            this.setState({itemPlayingId: item.id, isPlaying: true}, function () {
                audio.play();
            });
            audio.onended = () => {
                if (Object.keys(this.props.items).length > (index + 1)) {
                    this._playList(++index)
                } else {
                    this._stop();
                }
            }
        } else
            this._playList(++index);
    }

    _getAdditionaClasses(item) {
        return (item.id === this.state.itemPlayingId ? stylesLexemeSenses['modal-phrase--is-playing'] : "") + " " + stylesLexemeSenses['modal-phrase--' + (item.multimedia_file.length > 0 ? 'has-multimedia' : 'no-multimedia')];
    }

    _openPlayPhrases() {
        /*this.setState({modalPlayShow: true});*/
    }

    _closePlayPhrases() {
        this.setState({modalPlayShow: false, itemPlayingId: ""});
        this.props.callbackParent(false);
    }

    _getAudioElement(item) {
        return this._getAudioElementById(item.id);
    }

    _getAudioElementById(id) {
        return ReactDom.findDOMNode(this['playPhrasesAudio' + id]);
    }

    _pause() {
        document.getElementById('id-modal-play-phrases').focus();
        if (Object.keys(this.state.itemPlayingId).length !== 0) {
            if (this.state.isPlaying) {
                this.setState({isPlaying: false, isPause: true});
                this.props.items.map((item) => {
                    var audio = this._getAudioElement(item);
                    audio.pause();
                });
            } else {
                this._play();
            }
        }
    }

    _stop() {
        document.getElementById('id-modal-play-phrases').focus();
        this.props.items.map((item) => {
            var audio = this._getAudioElement(item);
            audio.pause();
            audio.currentTime = 0;
            this.setState({itemPlayingId: ""});
        });
    }

    _play() {
        document.getElementById('id-modal-play-phrases').focus();
        if (Object.keys(this.state.itemPlayingId).length === 0) {
            this._playList(0);
        } else {
            this._getAudioElementById(this.state.itemPlayingId).play();
        }
        this.setState({isPlaying: true, isPause: false});
    }

    render() {

        return <Modal id={"id-modal-play-phrases"} show={this.props.show}
                      onHide={() => this._closePlayPhrases()}>
            <Modal.Header closeButton>
                <Modal.Title>{Dict.t('AUDIO_PHRASES_OF')}:&nbsp;<i>
                    <small>{this.props.senseDescr}</small>
                </i>
                    <div className={stylesLexemeSenses['modal-play-phrases__button-container']}>
                        <Button onClick={() => this._stop()}><Glyphicon glyph="stop"/></Button>
                        <Button onClick={() => this._pause()}><Glyphicon glyph="pause"/></Button>
                        <Button onClick={() => this._play()}><Glyphicon glyph="play"/></Button>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{
                this.props.items.map(item =>
                    <Row
                        className={BUtils.joinClasses(stylesLexemeSenses['row__play-phrases'], this._getAdditionaClasses(item))}
                        key={'key-phrase-play-item-' + item.id}>
                        <Col id={"col-play-phrases-text-" + item.id}
                             className={stylesLexemeSenses['col__play-phrases-text']} lg={6} md={6}>{item.text}
                        </Col>
                        <Col lg={6} md={6}>
                            <audio id={"audio-play-phrases-" + item.id} className='simple-player-audio-tag'
                                   ref={(audio) => {
                                       this['playPhrasesAudio' + item.id] = audio
                                   }} src={item.multimedia_file}/>
                        </Col>
                    </Row>
                )}
            </Modal.Body></Modal>
    }
}

LexemeSenses.contextType = LexemePageContext;