import React from "react";
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap';
import './SCCombo.css';

export class SCCombo extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            value : this.props.value,
            id : this.props.id,
            options : this.props.options,
            label: this.props.label
        });

        this._handleChange = this._handleChange.bind(this);
    }

    componentWillMount() {
        const value = this.props.value;
        const defaultValue = this.props.options.filter(function (item) {
            return item.value === value;
        })[0];

        if(typeof defaultValue=="undefined") {
            this.setState({value : this.props.options[0].value});
        }
    }


    _handleChange(event) {
        this.setState({
            value : event.target.value
        });


        this.props.callbackParent(event.target.value);
    }

    render() {

        const options = this.state.options.map((item) =>
            <option key={"key-sccombo-" + item.value} value={item.value} >{item.text}</option>
        );

        return <FormGroup className="sc-combo" key={"key-sccombo-group" + this.props.value + "-" + this.props.id}>
            <ControlLabel title={this.props.tooltip}>{this.props.label}</ControlLabel>
            <FormControl id={this.state.id} componentClass="select" onChange={this._handleChange} value={this.state.value}>
                {options}
            </FormControl>
        </FormGroup>
    }
}