import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './_css/index.css';
import Dict from 'dict-lib';

import {Provider} from 'react-redux';
import {store} from './_redux/authentication/store';
import {userActions} from "./_redux/authentication/actions/user.action";

//import Dict from './_dict-modules/dict-lib/main';

function init() {
    /*console.log(process.env.NODE_ENV);*/

    var properties = Dict.loadProperties();

    return Promise.all([properties]).catch((error) => {
    });
}

init().then((ret) => {
    document.getElementById('appTitle').innerText = Dict.getGlobalProperty('HEADER_TITLE');

    ReactDOM.render(
        <Provider store={store}><App/></Provider>,
        document.getElementById('root')
    );
});





