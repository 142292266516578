import React from 'react';
import ReactDOM from 'react-dom';
import {rebuildEntry} from './EntryActions';
import {getLexemeProperties} from './../../LexemePage';
import {LexemeUtilities} from './../LexemeHelper';
import {SCModalTopSpinner, SCSubmitButton} from './../../../../dict-components/components';
import Dict from 'dict-lib';
import {Button, Row, Col} from 'react-bootstrap';
import '../../../../_css/dictionary/lexemeParts/lexemeTitle.module.css';
import './../../../../_css/lexeme.css';

export class EntryRebuild extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this._handleRebuild = this._handleRebuild.bind(this);
    }


    _handleRebuild() {
        rebuildEntry(getLexemeProperties('root_entry_id'));
    }


    render() {

        return <div className="entry-delete">
            <Row>
                <Col lg={9} md={9} className="popup-label">{Dict.t('ASK_REBUILD_LEXEME')}</Col>
                <Col lg={3} md={3}>
                    <SCSubmitButton onClick={this._handleRebuild} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </div>;
    }


}
