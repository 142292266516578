import {SCRadiobutton} from './radiobutton/SCRadiobutton';
import {SCCheckbox} from './checkbox/SCCheckbox';
import {SCCombo} from './combo/SCCombo';
import React from "react";
import Dict from 'dict-lib';
import {
    Collapse,
    Well
} from 'react-bootstrap';

export {SCCheckbox} from './checkbox/SCCheckbox';
export {SCCombo} from './combo/SCCombo';
export {SubEntryCombo} from './combo/SubEntryCombo';
export {SCRadiobutton} from './radiobutton/SCRadiobutton';
export {SCModalTopSpinner} from './spinner/SCModalTopSpinner/SCModalTopSpinner';
export {SCModalMessage} from './modal/SCModalMessage/SCModalMessage';
export {SCPopup} from './popup/SCPopup';
export {SCSubmitButton} from './submitButton/SCSubmitButton';
export {SCSimpleSoundPlayer} from './simpleSoundPlayer/SCSimpleSoundPlayer';
export {SCToastNotification} from './toastNotification/SCToastNotification';
export {SCTree} from './tree/SCTree';
export {LexemeNavigator} from './lexemeNavigator/LexemeNavigator';
/*import {SCPopup} from './popup/SCPopup';*/

export {EntryTypeahead} from './typeahead/EntryTypeahead';
export {PolyTypeahead} from './typeahead/PolyTypeahead';
export {CgrTypeahead} from './typeahead/CgrTypeahead';
export {LanguageTypeahead} from './typeahead/LanguageTypeahead';
export {LanguageTreeView} from './treeview/LanguageTreeView';
export {LanguageTreeViewPlus} from './treeview/LanguageTreeViewPlus';
export {Ipa} from './ipa/Ipa';

export const SCComponents = {
    getSCComponentItem: function (item, keypost, callback) {
        if (item.type === 'checkbox')
            return SCComponents.getSCCheckboxByItem(item, keypost, callback);
        if (item.type === 'radio')
            return SCComponents.getSCRadiobuttonByItem(item, keypost, callback);
        if (item.type === 'select')
            return SCComponents.getSCSelectByItem(item, keypost, callback);
    },
    getSCCheckboxByItem: function (item, keypost, callback) {
        return <SCCheckbox key={"key-chk-" + item.id + "-" + keypost}
                           checked={item.value}
                           label={item.label ? item.label : Dict.t(item.id)}
                           tooltip={item.id}
                           id={item.id}
                           items={item.items}
                           callbackParent={eval(callback)}/>
    },
    getSCRadiobuttonByItem: function (item, keypost, callback) {
        return <SCRadiobutton key={"key-radio-" + item.id + "-" + keypost}
                              default={item.value}
                              label={item.label ? Dict.t(item.label) : ''}
                              tooltip={item.id}
                              id={item.id}
                              items={item.items}
                              bsStyle="default"
                              callbackParent={eval(callback)}/>
    },
    getSCSelectByItem: function (item, keypost, callback) {
        return <SCCombo key={"key-select-" + item.id + "-" + keypost}
                        id={item.id}
                        label={item.label}
                        tooltip={item.id}
                        value={typeof item.value == 'undefined' ? item.options[0].value : item.value}
                        options={item.options}
                        callbackParent={eval(callback)}/>

    },
    getSCCheckboxWithCheckboxByItem: function (item, nestedItems, keypost, callbackCheck, callbackNestedCheck, collapse) {
        return <div key={"key-chkchk-" + item.id + "-" + keypost}><SCCheckbox
            key={"key-chk1-" + item.id + "-" + keypost}
            checked={item.value}
            label={Dict.t(item.id)}
            tooltip={item.id}
            id={item.id}
            callbackParent={eval(callbackCheck)}/>
            <Collapse key={'key-collapse-' + item.id + '-' + keypost} in={eval(collapse)}>
                <div>
                    <Well>
                        {nestedItems.map((nestedItem, index) =>

                            <SCCheckbox
                                key={"key-chk2-" + nestedItem.id + "-" + keypost}
                                checked={nestedItem.value}
                                label={Dict.t(nestedItem.id)}
                                tooltip={nestedItem.id}
                                id={nestedItem.id}
                                callbackParent={eval(callbackNestedCheck[index])}/>
                        )
                        }
                    </Well></div>
            </Collapse>
        </div>
    },
    getSCCheckboxWithRadioByItem: function (item, keypost, callbackCheck, callbackRadio, collapse) {
        return <div key={"key-chkradio-" + item.id + "-" + keypost}><SCCheckbox
            key={"key-chk-" + item.id + "-" + keypost}
            checked={item.value}
            label={Dict.t(item.id)}
            tooltip={item.id}
            id={item.id}
            callbackParent={eval(callbackCheck)}/>
            <Collapse key={'key-collapse-' + item.id + '-' + keypost}
                      in={eval(collapse)}>
                <div>
                    <Well>
                        <SCRadiobutton key={'key-radio-' + item.id + '-' + keypost}
                                       default={item.value}
                                       id={item.radio.id}
                                       bsStyle="default"
                                       items={item.radio.items}
                                       callbackParent={eval(callbackRadio)}/>
                    </Well>
                </div>
            </Collapse>
        </div>

    },
    getSCCheckboxItemsWithRadio: function (item, keypost, callbackCheck, callbackRadio) {
        var isLast = item.radio.radioItems.filter((item) => {
            return !item.disabled
        }).length === 1;

        var CheckBoxList = item.activeItems.map((activeItem) => {
            var cleanActiveId = activeItem.id.replace((item.id + '_ACTIVE_'), '');
            return <SCCheckbox
                key={"key-chk-" + activeItem.id + "-" + keypost}
                checked={activeItem.value}
                label={activeItem.label}
                tooltip={activeItem.label}
                id={activeItem.id}
                disabled={(item.value === cleanActiveId) || (isLast && (keypost + ''  === cleanActiveId))}
                callbackParent={eval(callbackCheck)}/>
        });

        return <div key={"key-chksradio-" + item.id + "-" + keypost}>{CheckBoxList}
            <div style={{ 'borderTop' : '2px solid grey', 'display' : 'inline-block', 'paddingTop' : '7px'}}>
                <SCRadiobutton key={'key-radio-' + item.id + '-' + keypost}
                               default={item.value}
                               id={'chks-radio-language-descr-' + keypost}
                               bsStyle={"default"}
                               label={"default:"}
                               items={item.radio.radioItems}
                               callbackParent={eval(callbackRadio)}/>
            </div>
        </div>

    }

};


