import React from 'react';
import Dict from 'dict-lib';
import {deleteEntry} from './EntryActions';
import {getRootEntryId, removeTab} from './../../LexemePage';
import {Button, Row, Col} from 'react-bootstrap';
import '../../../../_css/dictionary/lexemeParts/lexemeTitle.module.css';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";

export class EntryDelete extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.additionalData.isPoly ? this.props.item.ids : getRootEntryId()
        };

        this._handleRemove = this._handleRemove.bind(this);
    }

    _handleRemove() {
        deleteEntry(this.state.id, this.props.additionalData.isPoly);
    }

    render() {

        var form = this.props.additionalData.isPoly ? this.props.item.form : (this.props.item.lexeme_data.root_entry_form).toUpperCase();

        return <div className="entry-delete">
            <Row>
                <Col lg={9} md={9} className="popup-label" ><strong>{form + ": "}</strong>{Dict.t(this.props.additionalData.isPoly ? 'ASK_DELETE_POLY' : 'ASK_DELETE_LEXEME')}</Col>
                <Col lg={3} md={3}>
                    <SCSubmitButton onClick={this._handleRemove} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </div>;
    }

}
