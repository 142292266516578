exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\r\n#dictionary-trash-container {\r\n    display: none;\r\n}\r\n\r\n.SCTrash-module__dictionary-trash-container__show--true___lincW {\r\n    display: block !important;\r\n    width: 54px;\r\n    height: 44px;\r\n    font-size: 34px;\r\n    position: absolute;\r\n    left: 94%;\r\n    top: 84%;\r\n    border: 2px solid #adadad;\r\n    /*padding: 0px 10px;*/\r\n    border-radius: 6px;\r\n    text-align: center;\r\n    padding-top: 3px;\r\n    animation: SCTrash-module__pulse___uMDit 0.3s ease 3;\r\n}\r\n\r\n@keyframes SCTrash-module__pulse___uMDit {\r\n    /*0% { transform: scale(1); }\r\n    50% { transform: scale(1.2); }\r\n    100% { transform: scale(1); }*/\r\n    0% {\r\n        transform: scale(1);\r\n    }\r\n    25% {\r\n        transform: scale(1.01)\r\n    }\r\n    50% {\r\n        transform: scale(1.03);\r\n    }\r\n    60% {\r\n        transform: scale(1.05);\r\n    }\r\n    70% {\r\n        transform: scale(1);\r\n    }\r\n    80% {\r\n        transform: scale(0.97);\r\n    }\r\n    90% {\r\n        transform: scale(0.94);\r\n    }\r\n    100% {\r\n        transform: scale(1);\r\n    }\r\n}\r\n\r\n.SCTrash-module__dictionary-trash-container__show--false___3q385 {\r\n    display: none;\r\n}\r\n\r\n.SCTrash-module__dictionary-trash-container__active--true___1OIqc {\r\n    background-color: #dcdcdc;\r\n}\r\n", ""]);

// Exports
exports.locals = {
	"dictionary-trash-container__show--true": "SCTrash-module__dictionary-trash-container__show--true___lincW",
	"pulse": "SCTrash-module__pulse___uMDit",
	"dictionary-trash-container__show--false": "SCTrash-module__dictionary-trash-container__show--false___3q385",
	"dictionary-trash-container__active--true": "SCTrash-module__dictionary-trash-container__active--true___1OIqc"
};