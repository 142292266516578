import React from 'react';
import {ButtonToolbar, Button, ControlLabel, Col} from 'react-bootstrap';
import './SCRadiobutton.css';
import Dict from 'dict-lib';

export class SCRadiobutton extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            value: this.props.default,
            additionalClass: typeof this.props.additionalClass === 'undefined' ? '' : this.props.additionalClass
        };

        this.listItems = [];
        this.label = '';
        this.i18nLabel = typeof this.props.i18nLabel !== 'undefined' && this.props.i18nLabel;
    }

    componentWillMount() {
        if (this.props.callDefault) {
            var callSearch = this.props.callDefault;
            Dict.call(callSearch).then(result => {
                this.setState({
                    value: result.code
                })
            });
        } else if (typeof this.props.default === 'undefined') {
            this.setState({value: (this.props.items[0].code + '')});
        }
    }

    _isClassActive(value) {
        return 'btn ' + (((value + '').trim() === this.state.value) ? 'active' : 'default');
    }

    _handleRadioChange(event) {
        this.setState({
            value: event.target.value
        });

        this.props.callbackParent(event.target.value);
    }

    render() {

        if (this.props.items) {
            this.listItems = [];
            var labelsName = typeof this.props.useLabels !== 'undefined' ? this.props.useLabels : 'label';
            for (var i = 0; i < this.props.items.length; i++) {
                var item = this.props.items[i];
                var disabled = typeof item.disabled !== 'undefined' && item.disabled;
                const classItem = <Button className={this._isClassActive(item.code) + " scradio-btn scradio-btn-enabled--" + !disabled}
                                          bsSize={this.props.bsSize}
                                          onClick={(evt) => this._handleRadioChange(evt)} value={item.code}
                                          bsStyle={this.props.bsStyle}
                                          style={this.props.styleButtons}
                                          key={item.code}>{this.i18nLabel ? Dict.t(item[labelsName]) : item[labelsName]}</Button>;
                this.listItems.push(classItem);
            }

            if (this.props.label) {
                this.label =
                    <Col><ControlLabel htmlFor={this.props.id} className='scradio-label' title={this.props.tooltip}
                                       key={'key-label-' + item.code}>{this.props.label}</ControlLabel></Col>;
                this.listItems = <Col>{this.listItems}</Col>;
            }
        }

        return <ButtonToolbar className={'scradiobutton ' + this.state.additionalClass} style={this.props.style}
                              id={this.props.id}>{this.label}{this.listItems}</ButtonToolbar>;
    }
}

export default SCRadiobutton;
