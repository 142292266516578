import React from 'react';
import Dict from 'dict-lib';
import {setSenses} from './../../LexemePage';
import {LexemeUtilities} from './../LexemeHelper';

const updatePhrases = ['SET_PHRASES'];
var updateClasses = ['subentries'];

export const deletePhrase = (senseIds, id) => {
    return LexemeUtilities.callResetTabPages('/phrases/deletePhrase/' + senseIds + '/' + id + '/' + updatePhrases);
};

export const detachPhrase = (senseIds, id) => {
    return LexemeUtilities.callResetTabPages('/phrases/detachPhrase/' + senseIds + '/' + id + '/' + updatePhrases);
};

export const setPhrase = (senseIds, text, id) => {
    text = LexemeUtilities.encodeUrl(text);
    return LexemeUtilities.callResetTabPages('/phrases/setPhrase/' + senseIds + '/' + text + '/' + id + '/' + updatePhrases);
};

export const savePhraseNotes = (senseIds, text, id) => {
    text = LexemeUtilities.encodeUrl(text);
    return LexemeUtilities.call('/phrases/saveNotes/' + senseIds + '/' + text + '/' + id + '/' + updatePhrases);
};

export const assignMoveSense = (senseIds, idSenseToAssign, id, isMove) => {
    var op = isMove ? 'moveSense' : 'assignSense';

    return LexemeUtilities.callResetTabPages('/phrases/' + op + '/' + senseIds + '/' + idSenseToAssign + '/' + id + '/' + updatePhrases);
};

export const addSense = (idPhrase, idSense) => {
    return Dict.call('/phrases/addSense/' + idPhrase + '/' + idSense);
};

export const removeSenseByLexeme = (idPhrase, openLexemeId) => {
    return Dict.call('/phrases/removeSenseByLexeme/' + idPhrase + '/' + openLexemeId);
};

export const setPhrasesOrder = (items, senseItems, senseItemIds, subentryIds) => {
    //setSenses(senseItems, subentryIds);
    items = LexemeUtilities.encodeUrl(JSON.stringify(items));

    return LexemeUtilities.callNoClose('/phrases/setPhrasesOrder/' + items + '/' + senseItemIds);
};

export const savePhrase = (lexPhrase) => {
    lexPhrase = LexemeUtilities.encodeUrl(lexPhrase);

    return LexemeUtilities.call('/phrases/savePhrase/' + lexPhrase);
};

export const showHidePhrase = (senseIds, id) => {
    return LexemeUtilities.call('/phrases/showHide/' + senseIds + '/' + id + '/' + updatePhrases);
};

export const transformPhraseInPoly = (senseIds, phraseId, entryId) => {

    return LexemeUtilities.callResetTabPages('/phrases/transformPhraseInPoly/' + senseIds + '/' + phraseId + '/' + entryId + '/' + updatePhrases);
};