import React from "react";
import {SharedOptions} from './tabs/SharedOptions'
import {LanguageOptions} from './tabs/LanguageOptions'
import {Tabs, Tab} from 'react-bootstrap';
import './Options.css';
import Dict from 'dict-lib';

export class Options extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            key: 2,
            rootLanguages: {},
            sharedOptions: [],
            langOptions: []
        });

        this._handleSelect = this._handleSelect.bind(this);
    }

    componentWillMount() {
        var call = Dict.call('/admin/options/getRootLanguages');
        call.then((result) => {
            this.setState({
                rootLanguages: result
            });
        });
    }

    _handleSelect(key) {
        this.setState({key});
    }

    _handleSharedOptions(sharedOptions) {
        this.setState({
            sharedOptions: {
                checkbox: sharedOptions
            }
        });
    }


    render() {
        const tabs = this.state.rootLanguages.length ? this.state.rootLanguages.map((result, index) => <Tab
            eventKey={index} key={"key-" + index + "-options-tab"} title={result.label}>
            <LanguageOptions key={"key-" + index + "-tab-lang-options-" + result.id}
                             descriptionLanguages={this.state.rootLanguages} language={result.id}/>
        </Tab>) : '';
        if (tabs) {
            tabs.unshift(<Tab eventKey={(tabs.length)} key={"key-" + (tabs.length) + "-options-tab"}
                              title={Dict.t("SHARED_OPTIONS")}><SharedOptions
                callbackParent={(sharedOptions) => this._handleSharedOptions(sharedOptions)}/></Tab>)
        }

        return (
            <Tabs activeKey={this.state.key} onSelect={this._handleSelect} id="options-tab">
                {tabs}
            </Tabs>
        );
    }
}