import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import Dict from 'dict-lib';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import LexemePage, {getLexemeProperties} from '../lexeme/page/LexemePage';
import {Button, ListGroup, Modal, Row} from 'react-bootstrap';
import {ListGroupItem} from 'react-bootstrap';
import {Pagination} from 'react-bootstrap';
import {performSearch} from "./lib/search";
import FontAwesome from "react-fontawesome";
import ReactTooltip from "react-tooltip";
import {PhraseEditor} from "../lexeme/page/editor/phrases/PhraseEditor";
import {LexemeUtilities, LexemeEditingLevel,} from "../lexeme/page/editor/LexemeHelper";
import {userService} from "../_redux/authentication/services/user.services";

import stylesSearchPage from '../_css/dictionary/searchPage.module.css';
import BUtils from './../utilities/BaseUtilities';
import {Notifications} from "../utilities/DictUtilities";
import error from "../../public/img/error.png";
import warning from "../../public/img/warning.png";
import {SCModalMessage} from "../dict-components/modal/SCModalMessage/SCModalMessage";

export class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listItems: '',
            activePage: this.props.nPage,
            selectedId: '',
            selectedSemanticUnitId: '',
            newUpdateIds: [],
            export: {status: 'stop'},
            searchParams: this.props.searchParams
        };

        this._handleSelect = this._handleSelect.bind(this);
        this._launchExport = this._launchExport.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            selectedId: '',
            export: {status: 'stop'}
        });
    }

    _scrollTo(pos) {
        this.scroll.scrollToY(pos);
    }

    _handleSelect(eventKey) {
        if (eventKey > 0) {
            this.setState({
                activePage: eventKey,
                selectedId: ''
            }, function () {
                performSearch({nPage: eventKey, langId: this.props.language.id});
            });
        }
    }

    _launchExport(isOnlyStructure) {
        var exp = {status: 'start'};
        this.setState({export: exp});
        const totalPages = this.props.result.totalPages > 0 ? this.props.result.totalPages : (this.props.result.items.length > 0 ? 1 : 0);
        LexemeUtilities.exportLexemePages(this.props.searchParams, totalPages, this.props.language.id, this.props.dictionaryType, () => {
            this.setState({export: {status: 'finished'}});
        });
    }

    render() {

        var items = this.props.result.items;
        var totalPages = this.props.result.totalPages;

        var listItems = [];
        var ulKey = '';
        for (var i = 0; i < items.length; i++) {
            var resultItem = items[i];
            var resultItemId = resultItem.fields.id;
            var resultItemSemanticUnitId = typeof resultItem.fields.semanticUnitId !== 'undefined' && resultItem.fields.semanticUnitId !== 'null' ? resultItem.fields.semanticUnitId : '';
            ulKey += resultItemId + "_";
            listItems.push(<SearchResultItem key={"search-result-key-" + resultItemId + i} resultItem={resultItem}
                                             showLanguage={this.props.language.is_tree}
                                             isSelected={resultItemId === this.state.selectedId}
                                             itemsLenght={items.length}
                                             isNewUpdateId={this.state.newUpdateIds.indexOf(resultItemId) > 0}
                                             isBySemanticUnitSelected={resultItemSemanticUnitId === this.state.selectedSemanticUnitId}
                                             searchType={this.props.result.searchType}
                                             viewPoint={this.props.language}
                                             callbackParentSelect={(selectedId, selectedSemanticUnitId) => this.setState({
                                                 selectedId,
                                                 selectedSemanticUnitId
                                             })}
                                             callbackParentUpdate={(newUpdateId) => {
                                                 var newUpdateIds = BUtils.cloneJson(this.state.newUpdateIds)

                                                 if (newUpdateIds.indexOf(newUpdateId) === -1) {
                                                     newUpdateIds.push(newUpdateId)
                                                 }

                                                 this.setState({
                                                     newUpdateIds
                                                 })
                                             }}
            />);
        }

        const foundItems = listItems.length > 0;
        if (!foundItems) {
            ulKey = "noResultFound";
            listItems.push(<div key={"key-search-no-object-found"}
                                style={{fontWeight: 'bold'}}>{Dict.t('NO_OBJECT_FOUND')}</div>);
            setTimeout(function () {
                listItems = [];
                document.getElementById("dict-result-container").innerHTML = "";
            }.bind(this), 5000);
        }


        let pagItems = [];
        var drawButton = true;
        const nPage = this.props.nPage;

        for (let number = 1; number <= totalPages; number++) {
            if (nPage <= 2 && number <= 3) {
                drawButton = true;
            } else {
                var abs = Math.abs(number - nPage);

                if (nPage >= 3 && abs < 2) {
                    drawButton = true;
                } else {
                    drawButton = false;
                    if (abs < 3 || (nPage === 1 && number === 4))
                        pagItems.push(<Pagination.Ellipsis key={'key-page-item-' + number}/>);
                }
            }

            if (drawButton) {
                pagItems.push(
                    <Pagination.Item active={number === nPage} key={'key-page-item-' + number}
                                     onClick={() => this._handleSelect(number)}>{number}</Pagination.Item>)
            }

            drawButton = true;
        }

        return <div className={stylesSearchPage['pagination-container']} key={'pag-con-' + Math.random()}>
            <Pagination bsSize="medium" onSelect={this._handleSelect} key={'key-pagination'}>
                <Pagination.First onClick={() => this._handleSelect(1)} key={'key-pagination-first'}/>
                <Pagination.Prev onClick={() => this._handleSelect(nPage > 1 ? this.props.nPage - 1 : -1)}
                                 key={'key-pagination-prev'}/>
                {pagItems}
                <Pagination.Next onClick={() => this._handleSelect(nPage < totalPages ? this.props.nPage + 1 : -1)}
                                 key={'key-pagination-next'}/>
                <Pagination.Last onClick={() => this._handleSelect(totalPages)} key={'key-pagination-last'}/>
            </Pagination>
            <ListGroup id="dict-result-list" className={stylesSearchPage['dict-result-list']} key={'key-listgroup'}>
                {listItems}
            </ListGroup>
            <Row
                className={BUtils.joinClasses(stylesSearchPage['dict-result-list-buttons'], stylesSearchPage['dict-result-list-buttons--' + foundItems])}>
                <FontAwesome name={"spinner"}
                             className={BUtils.joinClasses(stylesSearchPage['dict-result-list-buttons__spinner'], stylesSearchPage['dict-result-list-buttons__spinner--' + (this.state.export.status === 'start')])}
                             spin/>
                <span id={'bt-search-export-counter'}
                      className={BUtils.joinClasses(stylesSearchPage['dict-search-result-export-counter'], stylesSearchPage['dict-search-result-export-counter--' + (this.state.export.status === 'start')])}>0%</span>
                <Button id={'bt-search-result-open-export'}
                        className={BUtils.joinClasses(stylesSearchPage['dict-result-list-buttons__button'], stylesSearchPage['dict-result-list-buttons__button--' + (this.state.export.status === 'finished')])}
                        onClick={() => {
                            ReactDOM.render('', document.getElementById('dict-modal-container'),
                                () => {
                                    ReactDOM.render(<SearchExportModal
                                        show={true}/>, document.getElementById('dict-modal-container'))
                                });

                            //LexemeUtilities.showDownloadableHtmlModalMessage(document.getElementById("export-split-part1").innerHTML, {additionalProps: {divide: 10}});
                        }}>{Dict.t('OPEN')}</Button>
                <Button id={'bt-search-result-export'} className={stylesSearchPage['dict-result-list-buttons__button']}
                        onClick={() => {
                            this._launchExport()
                        }}>{Dict.t('EXPORT')}</Button>
            </Row>
        </div>
    }
}

class SearchResultItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isUpToDate: true, /*this.props.resultItem.fields.jsonPageUpToDate || this.props.isNewUpdateId,*/
            entryFormView: this.props.resultItem.fields.entryForm,
            resultItem: this.props.resultItem.fields,
            isDeleted: false
        };

        this._handleClick = this._handleClick.bind(this);
        this._handlePhraseClick = this._handlePhraseClick.bind(this);

        this.hasErrors = this.props.resultItem.fields.hasErrors;
        this.pageNotComplete = this.hasErrors || !this.state.isUpToDate;
        this.pageInReview = !userService.isLogged() && this.pageNotComplete;

    }

    _handleClick() {

        this.props.callbackParentSelect(this.props.resultItem.fields.id, this.props.resultItem.fields.semanticUnitId);
        const rifs = this.props.resultItem.fields;
        const pageStatus = userService.isLogged() ? LexemeEditingLevel.EDITABLE : LexemeEditingLevel.READ_ONLY;
        const lang = this.props.viewPoint;
        const callback = () => this.props.callbackParentUpdate(this.props.resultItem.fields.id);

        if (this.hasErrors && !userService.isAdministrator())
            Notifications.showErrorModalMessage((this.pageInReview? 'SEARCH_RESULT_PAGE_IN_REVIEW' : 'SEARCH_ERROR_RESULT_TOOLTIP_ABBR'), 3000);
        else
            LexemeUtilities.openLexemePage(rifs.id, rifs.rootEntryId, this.state.isUpToDate, pageStatus, rifs.entryForm, this.props.dictionaryType, rifs.entryAbstr, lang.id, lang.code, callback);
        /*LexemeUtilities.exportLexemePage(rifs.id, rifs.rootEntryId, this.state.isUpToDate, rifs.entryForm, this.props.dictionaryType, rifs.entryAbstr, lang.id, lang.code, () => {
        var res = '';
        var css = document.getElementsByTagName('style');
        for (let styleItem of css) {
        res += styleItem.outerHTML;
        }
        res += document.getElementById("lexeme-tabs-pane-0").innerHTML;
        console.log(res);
        });*/
    }

    _handleClickPhraseLexeme(id) {
        const LexemePageConst = <LexemePage key={"key-lexeme-page-" + id}
                                            id={id} isUpToDate={this.state.isUpToDate}
                                            pageStatus={'EDITABLE'} dictionaryType={this.props.dictionaryType}
                                            entryAbstr={''}
                                            language={this.props.viewPoint}
                                            rootEntryId={id}
        />;
        ReactDOM.render(LexemePageConst, document.getElementById("lexeme-page-container"));
    }

    _handlePhraseClick(id) {
        if (!this.state.isDeleted) {
            const PhraseEditorConst = <PhraseEditor key={"key-phrase-editor-" + id}
                                                    phraseId={id}
                                                    searchType={this.props.searchType}
                                                    phraseResultItem={this.state.resultItem}
                                                    lang={this.props.viewPoint}
                                                    container={"phrase-editor-lexeme-container"}
                                                    callbackParentResetResult={(resultItem) => this.setState({resultItem})}
                                                    callbackParentRemoveItem={() => {
                                                        this.setState({isDeleted: true})
                                                    }}
            />;

            ReactDOM.render(PhraseEditorConst, document.getElementById("lexeme-page-container"));
        } else {
            Notifications.showErrorModalMessage("OBJECT_IS_DELETED", 2000);
        }
    }

    render() {
        var resultItem = this.state.resultItem;
        const isPhrase = this.props.searchType === 'PHRASES';
        const isSense = this.props.searchType === 'SENSES';
        const isSemanticSearch = this.props.searchType === 'SENSES' || isPhrase;
        const id = this.props.resultItem.fields.id + (isSense ? "-" + resultItem.semanticUnitId : '');
        const isHidden = resultItem.hideView;

        const isSelected = this.props.isSelected && (!isSemanticSearch || this.props.isBySemanticUnitSelected);

        const Language = <div
            className={BUtils.joinClasses(stylesSearchPage['dict-result-entryDescr__language'], stylesSearchPage['dict-result-entryDescr__language__language--'] + this.props.showLanguage ? 'show' : 'hide')}>({resultItem.language})</div>;

        const groupId = "dict-result-item-" + id;
        var groupClassName = BUtils.joinClasses(stylesSearchPage['dict-result-item'], stylesSearchPage['dict-result-item--' + (isSelected ? 'selected' : 'not-selected')], (isHidden ? stylesSearchPage['dict-result-item--hidden'] : ''));

        if (this.props.searchType === 'PHRASES') {
            const LexemesLine = resultItem.lexemes.map(item => {
                return <div onClick={() => this._handleClickPhraseLexeme(item.id)}>{item.label}</div>
            });

            return <ListGroupItem id={groupId}
                                  className={groupClassName}
            >
                <div
                    className={BUtils.joinClasses(stylesSearchPage['dict-result-field'], stylesSearchPage['dict-result-phrase'], this.state.isDeleted ? stylesSearchPage['dict-result-phrase--deleted'] : '')}
                    onClick={() => this._handlePhraseClick(id)}>{resultItem.text}
                </div>
                <div
                    className={BUtils.joinClasses(stylesSearchPage['dict-result-field'], stylesSearchPage['dict-result-phrase-lexeme'])}>{LexemesLine}</div>
            </ListGroupItem>;
        } else {

            const lexemeClassNames = " dict-rootentry-" + (resultItem.rootEntryId) + " search-type-" + this.props.searchType;
            groupClassName += " " + lexemeClassNames;
            const homoSplit = resultItem.rootEntryFormForSorting.split('^');
            const homo =  parseInt(homoSplit[homoSplit.length - 1]);

            const idTooltip = 'search-result-tooltip-' + id;
            const Tooltip = !this.pageNotComplete ? '' :
                <span id={'dict-result-item-tooltip-' + id}><FontAwesome name={"exclamation-triangle"}
                                                                         className={BUtils.joinClasses(stylesSearchPage["search-result-tooltip-img"], stylesSearchPage["search-result-tooltip-img--" + (this.hasErrors || this.pageInReview ? 'error' : 'warning')])}
                                                                         data-tip
                                                                         data-for={idTooltip}/>
                            <ReactTooltip id={idTooltip} type={this.hasErrors ? 'error' : 'info'}>
                            <span>{Dict.t(this.pageInReview ? 'SEARCH_RESULT_PAGE_IN_REVIEW' : this.hasErrors ? 'SEARCH_ERROR_RESULT_TOOLTIP' : 'SEARCH_RESULT_TOOLTIP')}</span>
                            </ReactTooltip></span>;

            const idEntryForm = 'result-entryForm-' + id;
            const EntryForm = resultItem.htmlEntryForm != null && Object.keys(resultItem.htmlEntryForm).length > 0 ?
                <span id={idEntryForm} dangerouslySetInnerHTML={{__html: resultItem.htmlEntryForm}}/> :
                <span id={idEntryForm}>{resultItem.entryForm}</span>;

            const isToponym = typeof resultItem.discriminator !== 'undefined' && resultItem.discriminator === 'TOPONYM';
            const abstr = isToponym ?  <FontAwesome name={'map-signs'}/>: (resultItem.abstr == null ? "&nbsp;" : <span dangerouslySetInnerHTML={{__html: resultItem.abstr}} />);

            return <ListGroupItem id={groupId}
                                  className={groupClassName}
                                  onClick={this._handleClick}>
                <div
                    className={BUtils.joinClasses(stylesSearchPage['dict-result-field'], stylesSearchPage['dict-result-status'])}
                    data-status={resultItem.lexemeStatus}>
                    <div className={stylesSearchPage['dict-result-status-color']}/>
                </div>
                <div
                    className={BUtils.joinClasses(stylesSearchPage['dict-result-field'], stylesSearchPage['dict-result-entry-descr'])}>{Tooltip}{EntryForm}
                    <sup className={stylesSearchPage['dict-result-entry-descr-homo--' + (homo > 0)]}>{homo}</sup>&nbsp;
                    <br/>
                    {Language}
                </div>
                <div id={"dict-result-item-entryAbstr-" + id}
                     className={BUtils.joinClasses(stylesSearchPage['dict-result-field'], stylesSearchPage['dict-result-entryAbstr'])}>{abstr}</div>
            </ListGroupItem>
        }
    }
}

class SearchExportModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nPage: 1,
            pageText: document.getElementById("export-split-part1").innerHTML,
            show: true
        };

        this.groups = document.getElementsByClassName('export-split-part');
        this.rawGroups = document.getElementsByClassName('export-struct-split-part');
    }

    _movePages(direction) {

        var nPage = this.state.nPage;
        if (direction === 'next')
            nPage++;
        else
            nPage--;

        if (nPage > 0 && nPage <= this.groups.length) {
            var pageText = document.getElementById('export-split-part' + nPage).innerHTML;
            this.setState({
                nPage,
                pageText
            })
        }
    };


    render() {

        var pagesButton = '';
        if (this.groups.length > 1) {
            pagesButton = <>
                <Button bsSize="large"
                        onClick={() => this._movePages('prev')}><FontAwesome name={"arrow-left"}/></Button>
                <span
                    className={stylesSearchPage['span-export-page-counter']}>{this.state.nPage + '/' + this.groups.length}</span>
                <Button bsSize="large"
                        onClick={() => this._movePages('next')}><FontAwesome name={"arrow-right"}/></Button>
            </>;
        }

        const content = <div id="modal-div-html-container">
            <Row id="modal-row-html-container" dangerouslySetInnerHTML={{__html: this.state.pageText}}>
            </Row>
            <Row id="modal-row-html-buttons">
                {pagesButton}
                <Button bsSize="large"
                        onClick={() => {
                            var json = '';
                            for (let group of this.rawGroups) {
                                json += group.innerHTML;
                            }
                            LexemeUtilities.downloadJson(json, 'strutture');
                        }
                        }>{Dict.t('DOWNLOAD_STRUCTURE')}</Button>
                <Button bsSize="large"
                        onClick={() => {
                            var html = '';
                            for (let group of this.groups) {
                                html += group.innerHTML;
                            }
                            LexemeUtilities.downloadHtml(html, 'schede');
                        }
                        }>{Dict.t('DOWNLOAD')}</Button>
                <Button bsSize="large"
                        onClick={() => {
                            this.setState({show: false});
                        }}>{Dict.t('CLOSE')}</Button>
            </Row>
        </div>;

        return <Modal
            className={BUtils.joinClasses("modal-message", stylesSearchPage["modal-message-downloadable-html"])}
            dialogClassName={stylesSearchPage["modal-dialog-message-downloadable-html"]} show={this.state.show}
            restoreFocus>

            <Modal.Body className={stylesSearchPage["modal-body-message-downloadable-html"]}>
                {content}
            </Modal.Body>
        </Modal>;
    }
}