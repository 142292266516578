import React from 'react';
import PropTypes from 'prop-types';
import {deleteLocVar} from './LocVarActions';
import Dict from 'dict-lib';
import {LexemeData} from '../../parts/02_Data/LexemeData';
import {LexemeSubentries} from '../../parts/03_Subentries/LexemeSubentries';
import {LexemePolyrhematics} from '../../parts/04_Polyrhematics/LexemePolyrhematics';
import {LexemeReferences} from '../../parts/05_References/LexemeReferences';
import {Button, Grid, Row, Col} from 'react-bootstrap';
import './LocVars.css';
import './../../../../_css/lexeme.css';
import {LexemePageContext} from "../../LexemePageContext";
import {LexemeEditingLevel, LexemePageInfos} from "../LexemeHelper";


export class LocVarPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id,
            waitmsg: this.props.waitmsg,
            full: this.props.full
        };
    }

    componentDidMount() {
        Dict.call('locVars/getLocVarPage/' + this.state.id + '/' + this.state.full).then(result => {
            this.setState(result);
        });
    }

    getContext() {

        const page = this.state.page

        return typeof page !== 'undefined' ? {
            properties: page.lexeme_properties,
            options: page.lexeme_options,
            dictProperties: page.dictionary_properties
        } : {};
    }

    render() {

        /*const title = typeof this.state.page!=='undefined' ? <LexemeTitle key='del-loc-title' page={this.state.page}/> : <div>WAITING</div>;*/
        const data = typeof this.state.page !== 'undefined' ? <LexemeData key='del-loc-data' page={this.state.page}/> :
            <div>{this.state.waitmsg ? "WAIT..." : ""}</div>;
        const subs = typeof this.state.page !== 'undefined' ?
            <LexemeSubentries key='del-loc-subs' page={this.state.page} pageStatus={LexemeEditingLevel.READ_ONLY}/> :
            <div></div>;
        const poly = typeof this.state.page !== 'undefined' ?
            <LexemePolyrhematics key='del-loc-poly' page={this.state.page}/> : <div></div>;
        const refer = typeof this.state.page !== 'undefined' ?
            <LexemeReferences key='del-loc-refer' page={this.state.page}/> : <div></div>;


        return <LexemePageContext.Provider key={'lexeme-provi-loc-var' + this.props.item.id}
                                           value={this.getContext()}><Row className="locVar-lexeme">
            <div className="locVar-lexeme-ins">{/*title*/}{data}{subs}{poly}{refer}</div>
        </Row></LexemePageContext.Provider>
    }
}

