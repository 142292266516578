import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import axios from 'axios';
import {AdministrationUtilities} from './../../../administration/AdministrationHelper';
import tinymce from 'tinymce';
import 'tinymce/themes/modern';
import {Editor} from '@tinymce/tinymce-react';
import '../../tinymce/plugin.min';
import {Glyphicon, InputGroup, FormControl, FormGroup, Row, Col, Button} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {EntryTypeahead, CgrTypeahead, LanguageTypeahead} from './../../components';
import {LexemeUtilities} from "../../../lexeme/page/editor/LexemeHelper";
import FontAwesome from "react-fontawesome";
import {Notifications} from "../../../utilities/DictUtilities";
import BUtils from './../../../utilities/BaseUtilities';
import {Document, Page} from 'react-pdf';

import stylesSCTree from './../SCTree.module.css';
import GoogleDocsViewer from "react-google-docs-viewer";
import {userService} from "../../../_redux/authentication/services/user.services";
import {setEditingStatus} from "../../../lexeme/page/editor/entry/EntryActions";

export var TreeUtilities = {

    getDefaultTreeProjectName: function () {
        return Dict.getGlobalProperty('TREE_TITLE');
    },

    createFileData: function (data, fileType) {
        var contentType = typeof fileType === 'undefined' ? 'application/json' : fileType;

        return Dict.call('/tree/retrieveMediaData', {
            method: 'POST',
            data: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': contentType,
            }
        });
    },

    createTmpFileByDatFile: function (file) {
        return TreeUtilities.createFileData(file, file.type).then(result => {
            var filename = BUtils.extractFilenameNoExtension(file.name);
            var extension = BUtils.extractFileExtension(file.name);
            var encodedFilename = AdministrationUtilities.encodeOSPath(filename);
            return Dict.call('/tree/createTmpFileByDatFile/' + result.filename + '/' + encodedFilename + '/' + extension);
        })
    },

    buildProjectToExport: function (state, tableOfData) {
        var exportProject = {};
        exportProject.projectName = state.projectName;
        exportProject.treeStructure = undefined; //state.treeStructure;
        exportProject.autoincrement = state.autoincrement;
        exportProject.composedTreeItems = state.composedTreeItems;
        exportProject.composedCollections = state.composedCollections;
        if (typeof tableOfData !== 'undefined')
            exportProject.tableOfData = tableOfData;

        /*NodeOperations.traverseToData(state.treeStructure, (item) => {
            item.data = '';
        });*/

        var debug = true;

        if (debug)
            return JSON.stringify(exportProject);
        else
            return escape(encodeURIComponent(JSON.stringify(exportProject)));
    },

    writeNowDateString: function () {
        var dateJson = this.getDateJSon(new Date());
        return dateJson.dd + "-" + dateJson.mm + "-" + dateJson.yyyy + '_' + dateJson.hhhh + dateJson.mmmm + dateJson.ssss;
    },

    writeDateString: function (date, choosenMode) {
        var dateJson = this.getDateJSon(date);

        for (var prop in JSON.parse(JSON.stringify(dateJson))) {
            if (isNaN(dateJson[prop])) {
                return "";
            }
        }

        var retVal = '';
        if (choosenMode === 'yyyy')
            retVal = dateJson.yyyy;
        else if (choosenMode === 'mm-yyyy') {
            retVal = dateJson.mm + "-" + dateJson.yyyy;
        } else
            retVal = dateJson.dd + "-" + dateJson.mm + "-" + dateJson.yyyy;

        return retVal;
    },

    writeDateStringForInput(value, choosenMode) {
        if (typeof value === 'undefined' || value === '')
            return null;
        else {
            var retVal = '';

            if (choosenMode === 'yyyy') {
                //value = new Date(value).getFullYear();
                /*while (value.length < 4)
                    value = "0".concat(value);*/
                retVal = value;
            } else {
                while (value.length < 4)
                    value = "0".concat(value);

                var dateJson = this.getDateJSon(new Date(value));

                if (choosenMode === 'mm-yyyy') {
                    retVal = dateJson.yyyy + "-" + dateJson.mm;
                } else
                    retVal = dateJson.yyyy + "-" + dateJson.mm + "-" + dateJson.dd;
            }

            return retVal;
        }
    },

    getDateJSon(date) {
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
        var yyyy = date.getFullYear() + "";

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        while (yyyy.length < 4)
            yyyy = "0".concat(yyyy);

        var hhhh = date.getUTCHours();
        var mmmm = date.getUTCMinutes();
        var ssss = date.getUTCSeconds();

        var json = {dd: dd, mm: mm, yyyy: yyyy, hhhh: hhhh, mmmm: mmmm, ssss: ssss};
        return json;
    },


    getDateByValue: function (value) {
        var cleanValue = value.replace(/\//g, ' ').replace(/-/g, ' ');
        var splitCleanValue = cleanValue.split(' ');
        var choosenMode = 'dd-mm-yyyy';


        var date = new Date(splitCleanValue[2] + ' ' + splitCleanValue[1] + ' ' + splitCleanValue[0]);
        if (date.toString() === 'Invalid Date' || isNaN(date))
            return '';

        if (splitCleanValue.length === 1) {
            choosenMode = 'yyyy';
        } else if (splitCleanValue.length === 2) {
            choosenMode = 'mm-yyyy';
        }

        return {date: date, choosenMode: choosenMode};
    },

    buildSaveFilename(name) {
        return name + "_" + this.writeNowDateString() + ".json";
    },

    /*import: function (file, callback) {
        var r = new FileReader();
        r.onload = function (e) {
            var path = '';

            try {
                path = AdministrationUtilities.encodeOSPath(JSON.parse(e.target.result).projectName);
                Dict.call('/tree/createProject/' + path).then(result => {
                    TreeDOMHelper.callNotification(result);
                });

                var contents = e.target.result;
                callback(contents);
            } catch (e) {
                Notifications.showErrorModalMessage('TREE_PARSING_IMPORT_ERROR', 1500);
            }
        };

        r.readAsText(file);
    },*/

    loadDefaultQuickSave: function () {
        return Dict.call('/tree/loadDefaultProject/' + userService.isLogged() + '/');
    },

    quickSave: function (state, callback) {
        return this.save(state, 'quickSave', callback);
    },

    save: function (state, filename, callback) {
        var projectSave = TreeUtilities.buildProjectToExport(state);

        return this.createFileData(projectSave).then(function (result) {
            Dict.call('/tree/saveProject/' + state.projectName + '/' + result.filename + '/').then(result => {
                TreeDOMHelper.callNotification(result);
                if (typeof callback !== 'undefined')
                    callback(result);
            });
        });
    },

    saveItemData: function (state, item, parent) {
        this.checkSubContainerAndAddContainerData(item, parent);
        return this.performSave(state, item);
    },

    performSave(state, item) {
        return this.createFileData(item.data).then((resultData) => {
            var itemParam = Object.assign({}, item, {key: item.key, ancestors: item.ancestors});
            delete itemParam.data;
            delete itemParam.children;
            return Dict.call('/tree/saveItemData/' + state.projectName + '/' + LexemeUtilities.encodeUrl(JSON.stringify(itemParam)) + '/' + resultData.filename + '/').then((result) => {
                TreeUtilities.quickSave(state);
                return result;
            })
        });
    },

    checkSubContainerAndAddContainerData(item, parent) {
        const isSub = TreeNodeHelper.isSubContainerItem(item.code);
        const isLeafWithParent = item.isLeaf && typeof parent !== 'undefined';

        if (isLeafWithParent && isSub && !item.data.find((datum) => {
            return datum.label.startsWith("CONTAINER_");
        })) {
            item.data.push({label: 'CONTAINER_TITLE', value: parent.title, type: 'textfield'});
            parent.data.forEach((datum) => {
                if (datum.type !== 'dropzone') {
                    var newDatum = JSON.parse(JSON.stringify(datum));
                    newDatum.label = 'CONTAINER_' + newDatum.label;
                    item.data.push(newDatum);
                }
            });
        }
    },

    checkContainerAndResetChildrenData(parent, state, callback) {
        if (TreeNodeHelper.isContainerItem(parent.code)) {
            delete parent.children;

            TreeUtilities.loadNode(state, parent.key).then((items) => {
                var arrayItems = [];
                var arrayItemsData = [];
                items.forEach((item, index) => {
                    if (TreeNodeHelper.isSubContainerItem(item.code)) {

                        item.data.filter((datum) => {
                            return datum.label.startsWith('CONTAINER_')
                        }).forEach((filterItem) => {
                            item.data.splice(item.data.indexOf(filterItem), 1);
                        });

                        var dataRes = item.data.find((datum) => {
                            return datum.label === 'PAGE_NUMBER';
                        });

                        var parentDropzone = parent.data.find((datum) => {
                            return datum.type === 'dropzone';
                        });

                        if(parentDropzone.value.length > 0) {
                            var parentFilename = parentDropzone.value[0].filename;
                            dataRes.resource.filename = parentFilename;
                        }

                        item.data.push({label: 'CONTAINER_TITLE', value: parent.title, type: 'textfield'});
                        parent.data.forEach((datum) => {
                            if (datum.type !== 'dropzone') {
                                var newDatum = JSON.parse(JSON.stringify(datum));
                                newDatum.label = 'CONTAINER_' + newDatum.label;
                                item.data.push(newDatum);
                            }
                        });

                        arrayItemsData.push(item.data);
                        var itemParam = Object.assign({}, item, {key: item.key, ancestors: item.ancestors});
                        delete itemParam.data;
                        arrayItems.push(itemParam);
                    }
                });

                return this.createFileData(arrayItemsData).then((resultData) => {
                    return Dict.call('/tree/saveItemListData/' + state.projectName + '/' + LexemeUtilities.encodeUrl(JSON.stringify(arrayItems)) + '/' + resultData.filename + '/').then((result) => {
                        TreeUtilities.quickSave(state);
                        callback();
                    })
                });
            });
        }
    },

    addImportMultimediaResources(projectName, files, callback) {
        var length = files.length;

        if (length > 20) {
            Notifications.showModalSpinner(Dict.t('SPINNER_INSERT_MULTIMEDIA_RESOURCE') + ' 0%', '');
        }

        return files.forEach(function (file, indexFile) {
            Dict.call('/tree/retrieveMediaData', {
                method: 'POST',
                data: file,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then(function (result) {
                var filename = LexemeUtilities.encodeUrl(file.name);
                filename = filename.replace(/\./g, '^dot^');

                var dateFileName = LexemeUtilities.encodeUrl(result.filename);
                dateFileName = dateFileName.replace(/\./g, '^dot^');

                Dict.call('/tree/addImportMultimediaResources/' + projectName + '/' + filename + '/' + dateFileName + '/').then(result => {

                    if (result.listFiles.length === length || result.listFiles.length % 50 === 0) {
                        var fileList = result.listFiles.map(file => {
                            return file.filename;
                        });
                        callback(fileList);
                        if (result.listFiles.length === length)
                            Notifications.closeModalSpinner();
                    } else if (result.listFiles.length % 10 === 0) {
                        Notifications.showModalSpinner(Dict.t('SPINNER_INSERT_MULTIMEDIA_RESOURCE') + ' ' + parseInt(result.listFiles.length * 100 / length) + '%', '');
                    }

                    /*if(result.listFiles.length === length) {
                        var fileList = result.listFiles.map(file => {
                            return file.filename;
                        })
                        callback(fileList);
                        incr += 10;
                    }*/
                });
            }).catch(function (e) {
                console.log(e); // "oh, no!"
            });
        });
    },

    emptyImportMultimediaResources: function () {
        Dict.call('/tree/emptyImportMultimediaResources/');
    },

    convertJsonArrayToCSV: function (objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += '\t'

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    },

    exportCSV: function (filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename + '.csv');

        element.style.display = 'none';
        document.body.appendChild(element);


        element.click();
    },

    insertTableOfData: function (state, tableOfData, clear, callback) {

        const chunkLength = 250;
        var chunksNumber = parseInt(tableOfData.length / chunkLength) + (tableOfData.length % chunkLength === 0 ? 0 : 1);
        var chunks = [];
        for (var i = 0; i < chunksNumber; i++) {
            chunks.push(tableOfData.slice(i * chunkLength, (i * chunkLength) + chunkLength));
        }

        this.performInsertTableData(state, chunks, 0, clear, callback)
    },

    performInsertTableData: function (state, chunks, index, clear, callBackSave) {
        var chunk = chunks[index];
        this.createFileData(chunk).then((result) => {
            Dict.call('/tree/insertTreeTableOfData/' + state.projectName + '/' + result.filename + '/' + clear + '/').then((result) => {
                if (result.result === 'OK') {
                    if (index === chunks.length - 1)
                        this.quickSave(state, callBackSave);
                    else
                        this.performInsertTableData(state, chunks, index + 1, clear, callBackSave);
                } else {
                    TreeDOMHelper.callNotification(result);
                }
            }).catch((e) => {
                TreeDOMHelper.callNotification({result: 'error', msg: 'OPERATION_FAILED'});
            });
        });
    },

    loadNode: function (state, key) {
        const isLogged = userService.isLogged();
        return Dict.call('/tree/loadNodeChildren/' + state.projectName + '/' + key + '/' + isLogged + '/');
    },

    buildMultimediaSrc: function (projectName, item, data) {
        const filename = Dict.encodeUrl(item.filename);
        const extension = Dict.encodeUrl(item.extension);
        const folder = Dict.encodeUrl(data.folder);

        /*var service = 'getMultimedia';
        var endPoint = '/';
        if(data.code === 'DOCUMENTS') {
            service = 'getMultimediaPreview';
            endPoint = '';
        }*/

        return BUtils.getServerUrl() + '/tree/getMultimedia/' + projectName + '/' + folder + '/' + extension + '/' + filename + '/';
    }
};

export var TreeDOMHelper = {

    hideNodePageContainer() {
//        if(document.getElementById('tree-modal-doc') !== null && !document.getElementById('tree-modal-doc').style.display === 'block') {
        if (document.getElementById('tree-modal-doc') === null) {
            this.hideNodeOperationContainer();
            this.hideNodePage();
            this.hideNodeImportContainer();
            this.hideNodetreeLinkContainer();
        }
        //}
    },

    hideNodeOperationContainer() {
        //setTimeout(() => {
        //tinymce.remove();
        //}, 1000);
        //tinymce.remove();
        var container = document.getElementById("node-operations-container");
        this.setElementToInActive(container);
    },

    hideNodePage() {
        var container = document.getElementById("node-page");
        this.setElementToInActive(container);
        /*container.classList.remove('active');
        ReactDOM.unmountComponentAtNode(container);*/
    },

    hideNodeImportContainer() {
        var container = document.getElementById("node-import-container");
        this.setElementToInActive(container);
    },

    hideNodetreeLinkContainer() {
        var container = document.getElementById("node-treelink-page");
        this.setElementToInActive(container);
        ReactDOM.unmountComponentAtNode(container);
    },

    hideAndClickExpander(expander) {
        TreeDOMHelper.hideNodePageContainer();
        if (typeof expander !== 'undefined' && expander.classList.contains('react-tree-expander_close'))
            expander.click();
    },

    resetNodePageContainer(event, nodeElementId) {
        var ele = document.getElementById(nodeElementId);
        this.setElementToActive(ele);

        const isShift = event !== null && typeof event.nativeEvent !== 'undefined' && typeof event.nativeEvent.dataTransfer !== 'undefined' && event.nativeEvent.dataTransfer.shift;

        if (!isShift) {
            ele.style.top = event.clientY - parseFloat(5) + "px";
            ele.style.left = event.clientX + parseFloat(100) + "px";
        }

        return ele;
    },

    resetNodeTreeLinkContainer(event) {
        var ele = document.getElementById('node-treelink-page');
        var offsetHeight = ele.offsetHeight;
        var top = event.clientY - (typeof offsetHeight === 'undefined' ? 0 : offsetHeight) - 100;
        if (top < 0)
            ele.style.top = 100;
        ele.style.top = (event.clientY - (typeof offsetHeight === 'undefined' ? 0 : offsetHeight)) + "px";
        ele.style.left = event.clientX + parseFloat(5) + "px";
        return ele;
    },

    setElementToActive(ele) {
        ele.classList.add(stylesSCTree[ele.id + "--active"]);
        ele.classList.remove(stylesSCTree[ele.id + "--inactive"]);
    },

    setElementToInActive(ele) {
        ele.classList.add(stylesSCTree[ele.id + "--inactive"]);
        ele.classList.remove(stylesSCTree[ele.id + "--active"]);
    },

    resetItemSelection(parentElement) {
        var parenElementFound = false;
        const hasParentElement = typeof parentElement !== 'undefined';
        if (document.getElementById("import-tree-object-log") != null)
            document.getElementById("import-tree-object-log").style.display = 'none';

        var activeElements = document.getElementsByClassName('react-tree-item-label-active');
        if (activeElements.length > 0) {
            Array.from(activeElements).forEach((item, index) => {
                if (hasParentElement && item.isEqualNode(parentElement)) {
                    parenElementFound = true;
                } else {
                    item.classList.remove('react-tree-item-label-active');
                }
            });
        }

        if (hasParentElement && !parenElementFound) {
            setTimeout(function () {
                if (!parentElement.classList.contains('react-tree-item-label-active')) {
                    parentElement.classList.add('react-tree-item-label-active');
                }
            }, 10);
        }
    },

    resetNodeEditorPosition(ele) {
        var reactTree = document.getElementsByClassName('react-tree')[0];
        var nodeOperationContainer = document.getElementById('node-operations-container');

        if (ele.style.left !== '' && parseFloat(ele.style.left.replace('px', '')) > 800) {
            nodeOperationContainer.style.left = 300 + "px";
        }

        var posHeight = (parseFloat(ele.offsetHeight) + parseFloat(ele.style.top.replace('px', '')));
        if (posHeight > reactTree.offsetHeight) {
            nodeOperationContainer.style.top = 150 + "px";
            document.getElementById('tree-object-element-container').style.maxHeight = (document.getElementsByClassName('react-tree')[0].offsetHeight - 200) + "px";
        }

    },

    callNotification(result, callback) {
        if (result.result === 'error') {
            Notifications.showErrorModalMessage('ignore:' + result.message, 3000);
        } else if (typeof callback === 'function') {
            callback();
        }
    },

    openDocumentPreview(src, buttonId, fileLength, parentItem, filename, extension, page) {
        this.performOpenDocumentPreview(src, buttonId, fileLength, parentItem.projectName, parentItem.key, 'tree-modal-doc-body', filename, extension, page)
    },

    performOpenDocumentPreview(src, buttonId, fileLength, projectName, parentKey, renderElement, filename, extension, page) {
        const viewerHeight = window.innerHeight - 170;
        const iframeId = 'iframe-viewer-id';
        const testUrl = 'http://infolab.stanford.edu/pub/papers/google.pdf';
        Dict.call('/tree/createTmpFileByPdfFile/' + projectName + '/' + parentKey + '/' + filename + '/' + extension).then((result) => {
            var iframeSrc = result.path + "/" + result.filename + '#page=' + page.replace('[', '').replace(']', '');
            var style = {width: '667px', height: viewerHeight, border: 'none'};
            var iframe = React.createElement(
                "div",
                null,
                React.createElement("iframe", {
                    id: iframeId, src: iframeSrc,
                    style: style, onLoad: () => {
                    }
                })
            );

            ReactDOM.render(iframe, document.getElementById(renderElement));
        })
    },

    /***
     OLD DOC

     if (counter > 8) {
                    var extension = this.extractExtensionByGetMultimedia(src);
                    iframe = <div style={style}>
                    <span
                        className={stylesSCTree['tree-modal-doc__iframe-span']}>{Dict.t('WARNING_TREE_DOCUMENT_PREVIEW_NOT_WORKING') + ':'}</span>
                        <a className={stylesSCTree['tree-modal-open-external']} target='_blank'
                           href={"https://docs.google.com/viewer?url=" + src}><FontAwesome
                            name={'external-link-square'}/></a>
                        {extension === 'pdf' ? <div style={style}>
                    <span
                        className={stylesSCTree['tree-modal-doc__iframe-span']}>{Dict.t('WARNING_TREE_DOCUMENT_PREVIEW_OPEN_INTERNAL_PDF') + ':'}</span>
                            <a className={stylesSCTree['tree-modal-open-external']} target='_blank' onClick={() => {
                                document.getElementById('tree-modal-doc-body').style.height = (viewerHeight + parseInt('30')) + 'px';
                                this.openInternalPdfDocumentPreview(1, null, viewerHeight, src);
                            }}><FontAwesome name={'file-pdf-o'}/></a></div> : ''}
                    </div>;
                }
     ****/

    openInternalPdfDocumentPreview(numPage, totalPages, height, src) {
        document.getElementById('tree-modal-doc-body').style.visibility = totalPages === null ? 'hidden' : 'visible';

        ReactDOM.render(<>
            <Document file={src} onLoadSuccess={(pdfProxy) => {
                document.getElementsByClassName('react-pdf__Page')[0].style.left = '122px';
                if (totalPages === null) {
                    this.openInternalPdfDocumentPreview(numPage, pdfProxy._pdfInfo.numPages, height, src);
                }
            }}><Page pageNumber={numPage} loading={''} width={600} scale={height / 850}/>
            </Document>
        </>, document.getElementById('tree-modal-doc-body'));

        if (totalPages !== null) {
            ReactDOM.render(<><Button className={stylesSCTree['tree-modal-doc__button']} onClick={() => {
                if (numPage > 1)
                    this.openInternalPdfDocumentPreview(numPage - 1, totalPages, height, src);
            }}>{'<<'}</Button>
                <span className={stylesSCTree['tree-modal-doc__button-span']}>{numPage + '/' + totalPages}</span>
                <Button className={stylesSCTree['tree-modal-doc__button']} onClick={() => {
                    if (numPage < totalPages)
                        this.openInternalPdfDocumentPreview(numPage + 1, totalPages, height, src);
                }}>{'>>'}</Button></>, document.getElementById('additional-tree-modal-doc-footer-items'));
        }
    },

    extractExtensionByGetMultimedia(src) {
        var srcParts = src.split('/');
        return srcParts[srcParts.length - 3];
    },

    setSkin() {
        var elMain = document.getElementsByClassName(stylesSCTree['tree-main-skin']);
        for (var el of elMain) {
            el.style.backgroundColor = Dict.getGlobalProperty('TREE_MAIN_SKIN');
        }

        var elMinor = document.querySelectorAll('.' + stylesSCTree['tree-minor-skin'], '.' + stylesSCTree['react-tree-item-label']); //document.getElementsByClassName(stylesSCTree['tree_minor_skin'] + ' ' + stylesSCTree['react-tree-item-label']);
        for (var el of elMinor) {
            el.style.backgroundColor = Dict.getGlobalProperty('TREE_MINOR_SKIN');
        }

        /*var elMinorButtonContainer = document.getElementById('node-page');//document.querySelectorAll('.' + stylesSCTree['tree-docs-viewer-item__buttons']);
        var elButtons = elMinorButtonContainer.getElementsByTagName('button');
        for (var el of elButtons) {
            el.style.backgroundColor = Dict.getGlobalProperty('TREE_MINOR_SKIN');
        }*/
    },

    setOpenItemSkin() {
        var elMinorButtonContainer = document.getElementById('node-page');//document.querySelectorAll('.' + stylesSCTree['tree-docs-viewer-item__buttons']);
        var elButtons = elMinorButtonContainer.getElementsByTagName('button');
        for (var el of elButtons) {
            el.style.backgroundColor = Dict.getGlobalProperty('TREE_MINOR_SKIN');
        }

        elMinorButtonContainer.style.backgroundColor = 'rgba(255, 255, 255, 0.75)';
    },

    trySetEditItemSkin(nretry) {
        const maxRetries = 4;

        if (!this.setEditItemSkin() && nretry <= maxRetries) {
            setTimeout(() => {
                this.trySetEditItemSkin(++nretry);
            }, 50);
        }
    },

    setEditItemSkin() {
        var elMinorButtonContainer = document.getElementById('node-operations-container__header');
        if (elMinorButtonContainer !== null) {
            elMinorButtonContainer.style.backgroundColor = Dict.getGlobalProperty('TREE_MINOR_SKIN');
            return true;
        } else
            return false;
    },

    resizeTreeHeight() {
        var tree = document.getElementById("sc-tree");
        if (typeof tree !== 'undefined' && tree !== null) {
            const innerHeight = window.innerHeight;
            const offset = innerHeight > 750 ? 230 : 250;
            tree.style.height = (innerHeight - offset) + 'px';
        }
    },

    _dragElement(elementId, headerId) {
        const elmnt = document.getElementById(elementId);

        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(headerId)) {
            // if present, the header is where you move the DIV from:
            document.getElementById(headerId).onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

};

export var NodeOperations = {

    addNewOrSetResourceElement: function (projectName, treedata, item, data, id, isAdd, position, title, callback) {
        var notEncodedFolder = id;
        const folder = escape(encodeURIComponent(notEncodedFolder));
        var hasPreview = typeof data[position].preview !== 'undefined';

        if (typeof data[position].value !== 'undefined' && data[position].value.length > 0) {
            //var cloneDataValue = JSON.parse(JSON.stringify(data[position].value));
            Dict.call('/tree/deleteMultimedia/' + projectName + '/' + folder + '/' + LexemeUtilities.encodeUrl(JSON.stringify(data[position].value)) + '/').then(result => {
                data[position].value = result;
                if (!hasPreview) {
                    callback(data[position], false);
                }
            });
        }

        if (hasPreview) {
            var fileNames = [];
            var files = [];

            data[position].preview.forEach(function (fileArray) {
                fileArray.forEach(function (file) {
                    files.push(file);
                });
            });

            files.forEach(function (file, indexFile) {
                TreeUtilities.createFileData(file, file.type).then(function (result) {
                    var extension = BUtils.extractFileExtension(file.name);
                    var fileNameNotEncoded;
                    const isDocument = data[position].label === 'DOCUMENT';
                    var service = isDocument ? 'saveDocument' : 'saveMultimedia';
                    const filetype = isDocument ? extension : escape(encodeURIComponent(file.type));


                    if (isDocument) {
                        fileNameNotEncoded = BUtils.extractFilenameNoExtension(file.name);
                        if (typeof data[position].value !== 'undefined') {
                            data[position].value.forEach((dataPosItem) => {
                                if ((dataPosItem.filename + '.' + dataPosItem.extension) === file.name)
                                    fileNameNotEncoded += "_copy(" + new Date().getTime() + ")";
                            });
                        }
                    } else {
                        fileNameNotEncoded = id + "_" + new Date().getTime();
                    }

                    const fileName = escape(encodeURIComponent(fileNameNotEncoded));
                    title = escape(encodeURIComponent(title));
                    window.URL.revokeObjectURL(file.preview);

                    var serviceResource = '/tree/' + service + '/' + projectName + '/' + fileName + '/' + result.filename + '/' + filetype + '/' + folder + '/' + title + '/';

                    Dict.call(serviceResource).then(result => {
                        fileNames.push({filename: result.filename, extension: result.extension});

                        if (fileNames.length === files.length) {

                            var dataWithValue = JSON.parse(JSON.stringify(data[position]));
                            delete dataWithValue.preview;
                            delete dataWithValue.accept;

                            if(isAdd && dataWithValue.value === "")
                                dataWithValue.value = [];

                            dataWithValue.value = isAdd || true ? fileNames : dataWithValue.value.concat(fileNames);
                            dataWithValue.folder = notEncodedFolder;
                            callback(dataWithValue, true);
                        }
                    });
                });
            });
        } else {
            callback(data[position], false);
        }
    },

    createNewItem: function (parent, title, code, data) {
        var ancestors = (parent.ancestors + "").length === 0 ? parent.key : parent.ancestors + "_" + parent.key;

        var newItem = {
            title,
            key: "",
            parentKey: parent.key + "",
            ancestors: ancestors,
            code: code,
            isLeaf: true,
            hidden: false,
            hiddenByBranch: false
        };

        if (code === 'FOLDER') {
            newItem.children = [];
        } else if (TreeNodeHelper.isContainerItem(parent.code)) {
            var dataResourceName = TreeNodeHelper.getSubItemResourceNameByCodes(parent.code, code);
            var parentSharedResourceName = TreeNodeHelper.getContainerSharedResourceName(parent.code);

            var resourceValue = parent.data.find((datum) => {
                return datum.code === parentSharedResourceName.code;
            }).value[0];

            data.find((datum) => {
                return datum.label === dataResourceName.label
            }).resource = resourceValue;

            newItem.data = data;
        } else {
            newItem.data = data;
        }

        //parent.children.push(newItem);
        return newItem;
    },

    setItem: function (item, title, data) {
        item.title = title;
        if (item.type !== 'FOLDER')
            item.data = data;

        return item;
    },

    removeNode: function (treedata, item) {
        this.traverseToKey(treedata, item.key, (found, index, arr) => {
            arr.splice(index, 1);
        });

        return treedata;
    },

    removeChildren: function (treedata, item) {
        item.children.forEach((child) => {
            treedata = this.removeNode(treedata, child);
        })

        return treedata;
    },

    getItemById: function (projectName, key) {
        return Dict.call('/tree/getItemByKey/' + projectName + '/' + key + '/');
    },

    retrieveItemData: function (projectName, item) {
        return new Promise((resolve) => {
            if (item.code !== 'FOLDER') {
                if (item.data === '')
                    return resolve(Dict.call('/tree/getItemDataByKey/' + projectName + '/' + item.key + '/'));
                else
                    return resolve(item.data);
            } else
                return resolve();
        });
    },

    retrieveItemDataByKey: function (projectName, key) {
        return new Promise((resolve) => {
            if (item.code !== 'FOLDER') {
                if (item.data === '')
                    return resolve(Dict.call('/tree/getItemDataByKey/' + projectName + '/' + key + '/'));
                else
                    return resolve(item.data);
            } else
                return resolve();
        });
    },

    recursiveShowHideBranch(parentItem, hide, branchUpdate, callback) {
        parentItem.children.map((child) => {
            branchUpdate.push(child);
        });
        parentItem.children.forEach((item, index, arr) => {
            item.hiddenBranch = hide;
            if (item.children) {
                return this.recursiveShowHideBranch(item, hide, branchUpdate, callback);
            } else {
                return callback(branchUpdate);
            }
        });

        console.log('ho finito');
    },

    traverseToNode(parentItem, callback) {
        parentItem.children.forEach((item, index, arr) => {

            if (item.children) {
                return this.traverseToNode(item, callback)
            } else {
                return callback(item);
            }
        });
    },

    traverseToType(data, type, projectName, callback) {
        data.forEach((item, index, arr) => {

            var dataByTypes = [];

            var retrieveItem = this.retrieveItemData(projectName, item);

            retrieveItem.then((data) => {
                if (typeof data !== 'undefined') {
                    //item.data = data;

                    dataByTypes = data.filter((data) => {
                        return data.type === type;
                    });

                    if (dataByTypes.length > 0) {
                        return callback(item, dataByTypes, arr)
                    }
                }

                if (item.children) {
                    return this.traverseToType(item.children, type, projectName, callback)
                }
                return null;
            }).catch(function (e) {
                console.log(e); // "oh, no!"
            });
        })
    },

    traverseToKey(data, key, callback) {
        data.forEach((item, index, arr) => {

            if (item.key === key) {
                return callback(item, index, arr)
            }
            if (item.children) {
                return this.traverseToKey(item.children, key, callback)
            }
            return null;
        })
    },

    traverseToKeyLoadData(projectName, data, key, callback) {
        data.forEach((item, index, arr) => {

            if (item.key === key) {
                NodeOperations.retrieveItemData(projectName, item).then((data) => {
                    item.data = data;
                    return callback(item, index, arr);
                });
            }
            if (item.children) {
                return this.traverseToKeyLoadData(projectName, item.children, key, callback)
            }
            return null;
        })
    },

    traverseToData(data, callback) {
        data.forEach((item, index, arr) => {

            if (item.code !== 'FOLDER' && typeof item.data !== 'undefined' && item.data !== '') {
                return callback(item, index, arr)
            }
            if (item.children) {
                return this.traverseToData(item.children, callback)
            }
            return null;
        })
    },

    traverseToTitle(data, title, callback) {
        var titleLowerCase = title.toLowerCase();

        data.forEach((item, index, arr) => {
            /*if (item.type === 'folder')
                return nullo;*/
            var itemTitleLowerCase = item.title.toLowerCase();

            if (item.type !== 'folder' && (itemTitleLowerCase.startsWith(titleLowerCase) || (itemTitleLowerCase.match(' ' + titleLowerCase)))) {
                callback(item, index, arr);
            }

            if (item.children) {
                return this.traverseToTitle(item.children, title, callback)
            }
            return null;
        })
    },

    retrieveParentKey(item) {
        var split = item.ancestors.split('_');
        var parentKey = split[split.length - 1];
        return parentKey;
    },

    retrieveParentItem(tree, item, callback) {
        var parentKey = this.retrieveParentKey(item);

        this.traverseToKey(tree, parentKey, callback);
    },

    retrieveParentContainer(tree, item, callback) {
        var parentKey = this.retrieveParentKey(item);
        this.traverseToKey(tree, parentKey, callback);
    },

    retrieveChildItemAndOrderFactor: function (parent, key) {
        var parentAndOrder = {};
        var item = parent.children.filter(function (item) {
            return key === item.key;
        })[0];

        var orderFactor = parseFloat(parent.children.indexOf(item)) + parseFloat(1);

        parentAndOrder.parent = item;
        parentAndOrder.order = orderFactor;

        return parentAndOrder;
    },

    retrieveRoot: function (tree, item) {
        var key = item.ancestors.replace('_ROOT_', '').split('_')[1];

        var root = tree.filter(function (item) {
            return key === item.key;
        })[0];

        return root;
    },

    buildNumbering: function (tree, item) {
        var numbering = "";
        if (this.retrieveParentKey(item).length > 0) {
            var parent = this.retrieveRoot(tree, item);
            var ancestors = item.ancestors.endsWith('_') ? [item.ancestors.split('_')[0]] : item.ancestors.split('_');
            if (parent != null) {
                numbering = parent.title + ".";

                for (var i = 1; i < ancestors.length; i++) {
                    //var parentAndOrder = this.retrieveChildItemAndOrderFactor(parent, ancestors[i]);
                    //parent = parentAndOrder.parent;
                    //numbering += parentAndOrder.order + ".";
                    numbering += ancestors[i] + ".";
                }

                //numbering += parseFloat(parent.children.indexOf(item)) + 1;
            } else {
                numbering = Dict.t('ERROR_BUILD_NUMBERING')
            }
        }

        return numbering;
    }
};

export var TreeNodeHelper = {

    treeNodeOperations: {
        ADD: 'add',
        EDIT: 'edit',
        REMOVE: 'remove',
        DRAGDROP: 'dragdrop',
        SORT: 'sort',
        IMPORT: 'import'
    },

    treeBuilderOperations: {
        DESIGN: {code: 'DESIGN', label: 'NEW_COMPONENT'},
        EDIT: {code: 'EDIT', label: 'EDIT_COMPONENT'},
        CREATE_COLLECTIONS: {code: 'CREATE_COLLECTIONS', label: 'CREATE_COLLECTIONS'}
    },

    treeContainersItems: [
        {
            code: 'DOCUMENT_CONTAINER', icon: 'book',
            propLabel: 'TREE_PROP_LABEL_DOCUMENT_CONTAINER_NAME',
            isContainerItem: true,
            basicItems: [{label: 'DATE', code: 'DATE_PICKER', type: 'datepicker'},
                {label: 'NOTES', code: 'TEXT', type: 'textfield', hidden: true},
                {
                    label: '___',
                    code: 'DOCUMENTS',
                    type: 'dropzone',
                    isSharedResource: true
                }],
            subItems: [{
                code: 'BOOKMARK', icon: 'bookmark',
                propLabel: 'TREE_PROP_LABEL_SUB_ITEM_DOCUMENT_CONTAINER_NAME',
                isBasic: false,
                isSubItem: true,
                basicItems: [{
                    label: 'AUTHOR',
                    type: 'textfield',
                    code: 'AUTHOR'
                }, /*{label: 'REGISTER_NAME', type: 'textfield'},*/ {
                    label: 'PAGE_NUMBER',
                    isPartOfResource: true,
                    isImportSortBy: true,
                    type: 'pdf-page-number'
                }, {label: 'NOTES', type: 'tinymce', hidden: true}]
            }]
        }
    ],

    treeBasicItems: [
        {
            code: 'TEXT_FIELD', icon: 'file-text-o',
            isBasic: true,
            element: {label: 'TEXT', type: 'textfield'}
        },
        {
            code: 'RICH_TEXT', icon: 'newspaper-o',
            isBasic: true,
            element: {label: 'RICH_TEXT', type: 'tinymce'}
        },
        {
            code: 'DATE_PICKER', icon: 'calendar',
            isBasic: true,
            element: {
                label: 'DATE',
                type: 'datepicker',
                mode: [{code: 'dd-mm-yyyy', label: 'DD-MM-YYYY'}, {code: 'mm-yyyy', label: 'MM-YYYY'}, {
                    code: 'yyyy',
                    label: 'YYYY'
                }]
            }
        },
        {
            code: 'CHECK_BOX', icon: 'check-square-o',
            isBasic: true,
            element: {label: 'CHECK_BOX', type: 'checkbox'}
        },
        {
            code: 'CUSTOM_DATA_COLLECTION', icon: 'caret-square-o-down',
            isBasic: true,
            element: {label: 'DATA_COLLECTION', type: 'autocomplete', items: [], customLabel: ''}
        },
        {
            code: 'PREFILLED_DATA_COLLECTION', icon: 'list',
            isBasic: true,
            element: {
                label: 'PREFILLED_DATA_COLLECTION',
                type: 'autocomplete',
                items: [],
                customLabel: '',
                serviceItems: '',
                collectionId: ''
            }
        },
        {
            code: 'IMAGE_GALLERY', icon: 'picture-o',
            isBasic: true,
            element: {label: 'IMAGE_GALLERY', type: 'dropzone', accept: "image/jpeg, image/png, image/gif"}
        },
        {
            code: 'AUDIO_GALLERY', icon: 'music',
            isBasic: true,
            element: {label: 'AUDIO_GALLERY', type: 'dropzone', accept: "audio/*, audio/mpeg"}
        },
        {
            code: 'VIDEO_GALLERY', icon: 'video-camera',
            isBasic: true,
            element: {
                label: 'VIDEO_GALLERY',
                type: 'dropzone',
                accept: "video/mp4, video/mpeg, video/quicktime, video/mpg"
            }
        },
        {
            code: 'DOCUMENTS', icon: 'file-text',
            isBasic: true,
            element: {
                label: 'DOCUMENT',
                type: 'dropzone',
                accept: "application/pdf, .doc"
            }
        },
        {
            code: 'PATH_BROWSER', icon: 'archive',
            isBasic: true,
            element: {
                label: 'PATH_BROWSER',
                type: 'pathbrowser'
            }
        },
        {
            code: 'WEB_LINK', icon: 'link',
            isBasic: true,
            element: {
                label: 'WEB_LINK',
                type: 'weblink'
            }
        },
        {
            code: 'TREE_LINK', icon: 'external-link-square',
            isBasic: true,
            element: {
                label: 'TREE_LINK',
                type: 'treelink'
            }
        }
    ],

    resetListFiles: function (path, operation, browsable, isDirectory, callback) {
        var encodedFolderPath = LexemeUtilities.encodeUrl(AdministrationUtilities.encodeOSPath(path));

        if (isDirectory) {
            Dict.call('/tree/browseFolder/' + (encodedFolderPath.length > 0 ? encodedFolderPath : 'not_found') + '/' + operation).then(result => {
                /*if (browsable)
                      document.getElementById("pathbrowser-error").style.display = 'none';
                }*/

                if (browsable && result.result === 'error') {
                    if (result.cause === 'isFile') {
                        this.resetListFiles(path, "path", true, false, callback);
                    } else {
                        document.getElementById("pathbrowser-error").style.display = 'inline';
                    }
                } else {
                    const serverUrl = BUtils.getServerUrl();
                    var ListFiles = result.listFiles.map((file, index) => {
                        var fileTypeClass = file.isDirectory ? 'directory' : 'file';
                        var browsableClass = browsable ? 'browsable' : 'not-browsable';
                        const isImage = BUtils.isImage(file.path);

                        return <li data-tip data-for={'pathbrowser-tooltip-' + index}
                                   className={BUtils.joinClasses(stylesSCTree['ul-pathbrowser-list-files__li'], stylesSCTree['ul-pathbrowser-list-files__li-' + fileTypeClass + '--' + browsableClass])}
                                   onClick={() => {
                                       if (browsable)
                                           this.resetListFiles(file.path, "path", (file.isDirectory || isImage), file.isDirectory, callback);
                                       else {
                                           this.downloadFile(file.path, file.filename);
                                       }
                                   }}>
                            <div
                                className={BUtils.joinClasses(stylesSCTree['ul-pathbrowser-list-files__div'], stylesSCTree['ul-pathbrowser-list-files__icon'])}>
                                <FontAwesome name={file.isDirectory ? "folder" : 'file-o'}/></div>
                            <div
                                className={BUtils.joinClasses(stylesSCTree['ul-pathbrowser-list-files__div'], stylesSCTree['ul-pathbrowser-list-files__filename'])}>{file.filename}</div>
                            <div
                                className={BUtils.joinClasses(stylesSCTree['ul-pathbrowser-list-files__div'], stylesSCTree['ul-pathbrowser-list-files__extension'])}>{file.extension}</div>
                            <div
                                className={BUtils.joinClasses(stylesSCTree['ul-pathbrowser-list-files__div'], stylesSCTree['ul-pathbrowser-list-files__size'], stylesSCTree['ul-pathbrowser-list-files__size--' + fileTypeClass])}>{file.size} bytes
                            </div>
                            {isImage ? <ReactTooltip id={'pathbrowser-tooltip-' + index}
                                                     className={stylesSCTree['tooltip-pathbrowser']} delayShow={250}>
                                <img className={stylesSCTree['tooltip-pathbrowser__img']}
                                     src={serverUrl + '/admin/getLocalImage/' + Dict.encodeUrl(JSON.stringify(file.path)) + '/'}/></ReactTooltip> : ''}
                        </li>;
                    });
                }

                if (typeof callback !== 'undefined') {
                    result.listFiles = ListFiles;
                    result.forceHideContents = false;
                    callback(result, true);
                }
            })
        } else {
            var filename = BUtils.extractFilename(path);
            if (BUtils.isImage(path)) {
                Dict.call('/admin/getLocalImage/' + Dict.encodeUrl(JSON.stringify(path)) + '/').then(() => {
                    const result = {
                        resource: 'http://' + Dict.getGlobalProperty('SERVER_URL') + '/admin/getLocalImage/' + Dict.encodeUrl(JSON.stringify(path)) + '/',
                        folderPath: path,
                        filename: filename,
                        forceHideContents: false
                    };
                    callback(result, false);
                })
            } else {
                const result = {
                    filename: filename,
                    folderPath: path,
                    forceHideContents: true
                }

                setTimeout(function () {
                    callback(result, false);
                }, 250);
            }
        }
    },

    downloadFile: function (filepath, filename) {
        var encodedFolderPath = LexemeUtilities.encodeUrl(AdministrationUtilities.encodeOSPath(filepath));
        var encodedFilename = AdministrationUtilities.encodeOSPath(filename);
        Dict.call('/tree/createTmpFile/' + encodedFolderPath + '/' + encodedFilename).then(result => {
            if (result.result === 'OK') {
                this.performDownloadFile(filename);
            } else {

            }
        });
    },

    downloadMediaFile: function (projectName, filename, folder, mimeType) {
        var encodedFilename = AdministrationUtilities.encodeOSPath(filename);
        Dict.call('/tree/createTmpMultimediaFile/' + projectName + '/' + encodedFilename + '/' + folder + '/' + mimeType + '/').then(result => {
            if (result.result === 'OK') {
                this.performDownloadFile(filename)
            } else {

            }
        });
    },

    performDownloadFile: function (filename) {
        var url = BUtils.getServerUrl() + '/tree/downloadFile/' + filename + '/';
        var a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        /*document.body.removeChild(a);*/
    },

    treeDbCollections: function () {

        var typeAheadCollection = [];
        var rootLanguages = Dict.getVarious("root_languages");

        rootLanguages.map((rootLanguage) => {
            typeAheadCollection.push({
                code: "ENTRIES_" + rootLanguage.id,
                label: Dict.t('ENTRIES') + " (" + rootLanguage.label + ")",
                component: <EntryTypeahead languageId={rootLanguage.id} callbackParent={(newState) => {
                }}/>
            });

            typeAheadCollection.push({
                code: "CGRS_" + rootLanguage.id,
                label: Dict.t('GRAMMATICAL_CATEGORIES') + " (" + rootLanguage.label + ")",
                component: <CgrTypeahead languageId={rootLanguage.id} callbackParent={(newState) => {
                }}/>
            });
        });

        typeAheadCollection.push({
            code: "LANGUAGES",
            label: Dict.t('LANGUAGES'),
            component: <LanguageTypeahead callbackParent={(newState) => {
            }}/>
        });

        /*Collections.sort(typeAheadCollection, */


        typeAheadCollection.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });

        return typeAheadCollection;
    },


    isBasicItem: function (code) {
        return typeof this.treeBasicItems.filter((item) => {
            return item.code === code;
        })[0] !== 'undefined';
    },

    isComposedTreeItems: function (code) {
        return typeof this.composedTreeItems !== 'undefined' && typeof this.composedTreeItems.filter((item) => {
            return item.code === code;
        })[0] !== 'undefined';
    },

    isContainerItem: function (code) {
        return typeof this.treeContainersItems.filter((item) => {
            return item.code === code;
        })[0] !== 'undefined';
    },

    isSubContainerItem: function (code) {
        const container = this.getContainerBySubitem(code);
        return typeof container !== 'undefined' && container !== null;
    },

    isRootItem: function (item) {
        return item.ancestors === '_ROOT_';//(item.ancestors + '').length === 0;
    },

    getItemByCode: function (code, composedTreeItems) {
        var ret = this.getBasicItemByCode(code);
        if (typeof ret === 'undefined' || ret === null)
            ret = this.getContainerItemByCode(code);
        if (typeof ret === 'undefined' || ret === null)
            ret = this.getSubItemByCode(code);
        if (typeof composedTreeItems !== 'undefined' && typeof ret === 'undefined' || ret === null) {
            ret = composedTreeItems.find((item) => {
                return item.code === code;
            });
        }


        return ret;
    },

    getBasicItemByCode: function (code) {
        return this.treeBasicItems.find((item) => {
            return item.code === code;
        });
    },

    getContainerItemByCode: function (code) {
        return this.treeContainersItems.find((item) => {
            return item.code === code;
        });
    },

    getSubItemByCode: function (code) {
        var subItem = '';
        this.treeContainersItems.forEach((item) => {
            if (subItem === '')
                subItem = item.subItems.find((subItem) => {
                    return code === subItem.code;
                });
        })

        return subItem;
    },

    getSubItemByCodes: function (containerCode, subItemcode) {
        return this.treeContainersItems.filter((item) => {
            return item.code === containerCode;
        })[0].subItems.filter((item) => {
            return item.code === subItemcode;
        })[0];
    },

    getSubItemPropLabelByCodes: function (containerCode, subItemcode) {
        return this.getSubItemByCodes(containerCode, subItemcode);
    },

    getSubItems: function () {
        var subItems = [];

        this.treeContainersItems.forEach((item) => {
            subItems = subItems.concat(item.subItems);
        });

        return subItems;
    },

    getSubItemResourceNameByCodes: function (containerCode, subItemcode) {
        const subItem = this.treeContainersItems.find((item) => {
            return item.code === containerCode;
        }).subItems.find((subItem) => {
            return subItem.code === subItemcode;
        });

        return subItem.basicItems.find((basicItem) => {
            return basicItem.isPartOfResource;
        });
    },

    getNextChild(parent, item) {
        var isNext = false;
        var nextChild = null;

        parent.children.forEach((child) => {
            if (isNext)
                nextChild = child;
            isNext = child.key === item.key;
        });

        return nextChild;
    },

    getPreviousChild(parent, item) {
        var stopFind = false;
        var previousChild = null;

        parent.children.forEach((child, index) => {
            stopFind = stopFind || child.key === item.key;
            if (!stopFind)
                previousChild = child;
        });

        return previousChild;
    },

    getContainerBySubitem: function (code) {
        const retValue = this.treeContainersItems.find((item) => {
            return item.subItems.find((subItem) => {
                if (subItem.code === code)
                    return item;
            })
        })

        return retValue;
    },

    getContainerSharedResourceName: function (code) {
        var container = this.treeContainersItems.find((item) => {
            return item.code === code;
        });

        return container.basicItems.find((basicItem) => {
            return basicItem.isSharedResource;
        });
    },

    getContainerSharedResource: function (containerItem) {
        var reosurce = this.getContainerSharedResourceName(containerItem.code);
        return containerItem.data.find((datum) => {
            return datum.code === reosurce.code;
        });
    },

    getContainerSubItemByCode: function (code) {
        this.treeContainersItems.forEach((item) => {
            item.subItems.filter((subItem) => {
                if (subItem.code === code)
                    return subItem;
            })
        })
    },

    getPropLabel(item) {
        var propLabel = Dict.getGlobalProperty(item.propLabel);
        return Dict.t(typeof propLabel === 'undefined' && propLabel === null ? item.code : propLabel);
    },

    getAutoCompleteItemById: function (items, id) {
        return items.filter(item => item.id === id)[0];
    },

    getAutoCompleteItemByLabel: function (items, label, caseSensitive) {
        return items.filter(item => caseSensitive ? (item.label === label) : item.label.toLowerCase() === (label + "").toLowerCase())[0];
    }
};