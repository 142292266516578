import React from "react";
import Dict from 'dict-lib';
import stylesSCTree from "../SCTree.module.css";
import BUtils from "../../../utilities/BaseUtilities";
import {
    Button,
    Col,
    FormControl,
    Grid,
    Row,
    Label,
    Modal,
    Pagination,
    DropdownButton,
    MenuItem,
    ButtonToolbar
} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import ReactTooltip from "react-tooltip";
import {Typeahead} from "react-bootstrap-typeahead";
import {TreeDOMHelper, TreeNodeHelper, TreeUtilities} from "./TreeHelper";
import InputGroup from "react-bootstrap/es/InputGroup";
import SCCheckbox from "../../checkbox/SCCheckbox";
import scLogo from "../../../../public/img/scLogo.svg";

export class TreeIndexedSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            listItems: [],
            page: 0,
            indexedValueSearch: '',
            openSpecificObject: false,
            openSpecificDate: false,
            openSearchText: false,
            showDocument: false,
            specificObjects: [],
            selectedObject: [],
            specificFields: [],
            prefilledObjects: [],
            selectedField: [],
            selectedSortField: [],
            selectedDate: {from: '', to: ''},
            listDateObjects: [],
            specificFieldsValues: {},
            nPage: 1,
            totalPages: 0,
            pagItems: [],
            isSpecificSearch: false,
            loading: false,
            additionalTitle: '',
            showSortedBy: true
        };

        this.isAdvancedSearch = this.props.isAdvancedSearch;
        this.layout = parseInt(Dict.getGlobalProperty('ADVANCED_SEARCH_FIELD_LAYOUT'));
        this.resultLayout = this.isAdvancedSearch ? parseInt(Dict.getGlobalProperty('ADVANCED_SEARCH_RESULT_LAYOUT')) : 1;
    }

    componentDidMount() {
        TreeDOMHelper.setSkin();

        if (this.props.simpleSearch !== null && (typeof this.props.indexedSearchQuery !== 'undefined')) {
            this._performSearchByValue(function () {
            });
            this._populateSpecificObjects(function () {
                this._changeSpecificObject(this._getSpecificObject());
            }.bind(this));
        } else {
            this._performOpenSpecificObject();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const simpleSearchUpdate = nextProps.simpleSearch != null && nextProps.simpleSearch.filter((item) => {
            return item.checked;
        }).length > 0;

        const val = simpleSearchUpdate && (nextProps.indexedSearchOpen || this.props.indexedSearchOpen);
        return typeof val !== 'undefined' ? val : false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.indexedSearchOpen || (this.props.indexedSearchQuery !== prevProps.indexedSearchQuery)) {
            if (typeof this.props.indexedSearchQuery === 'undefined') {
                this.setState({listItems: []}, function () {
                    this._performOpenSpecificObject();
                })
            } else {
                this._performSearchByValue();
            }
        }
    }

    _getSpecificObject() {
        const fieldsAdv = Dict.getGlobalProperty('ADVANCED_SEARCH_FIELDS');
        if (typeof fieldsAdv !== 'undefined' && fieldsAdv !== null && fieldsAdv.length > 0) {
            var split = fieldsAdv.split('::');
            const objCode = split[0];
            var selectedObject = [this.state.specificObjects.find((obj) => {
                return obj.code === objCode.toUpperCase();
            })];
        }

        if (typeof selectedObject === "undefined")
            selectedObject = [];

        //this.setState({selectedObject});
        return selectedObject;
    }

    _performSearchByValue() {
        var additionalTitle = this.props.docsSearch ? Dict.t('DOCUMENTS') : (this.props.simpleSearch !== null && this.props.simpleSearch.length > 0 ? this.props.simpleSearch.filter((item) => {
            return item.item !== 'DOCS_SEARCH' && item.checked;
        }).map((filtered) => {
            return filtered.label;
        }).join(', ') : '');

        this.setState({listItems: [], loading: true, additionalTitle}, () => {
            this._searchByValue(1, this.props.indexedSearchQuery);
        });
    }

    _performOpenSpecificObject() {
        setTimeout(function () {
            //document.getElementById('indexed-value-search').focus();
            this._populateDateObjects();
            this._populateSpecificObjects();

            this._populatePrefilledFields();

            this.setState({openSpecificObject: true});

            const fieldsAdv = Dict.getGlobalProperty('ADVANCED_SEARCH_FIELDS');
            if (typeof fieldsAdv !== 'undefined' && fieldsAdv.length > 0) {

                var split = fieldsAdv.split('::');
                const objCode = split[0];
                var selectedObject = [this.state.specificObjects.find((obj) => {
                    return obj.code === objCode.toUpperCase();
                })];

                if (typeof selectedObject === "undefined")
                    selectedObject = [];

                setTimeout(function () {
                    this._changeSpecificObject(selectedObject);
                    this._showHideSearchFields(1);

                    var focusEl = document.getElementById('indexed-value-search');
                    if (split[1]) {
                        var el = document.getElementById('indexed-value-search-' + split[1]);
                        if (el)
                            focusEl = el;
                    }

                    /*focusEl.focus();*/

                }.bind(this), 300);
            } else {
                setTimeout(() => {
                    this._showHideSearchFields(1);
                }, 300);
            }

        }.bind(this), 300);
    }

    _showHideSearchFields(showVal) {
        var divs = document.getElementsByClassName(stylesSCTree['tree-indexed-search-item__body-col-fields__first__div']);
        for (var i = 0; i < divs.length; i++) {
            divs[i].style.opacity = showVal;
        }
    }

    _emptySearch() {
        var indexedValue = '';
        this.setState({indexedValue});
    }

    _exportResult() {
        this._searchByValue(1, this.props.indexedSearchQuery, true)
    }

    _filterSearchResultItem(item, fieldResultView) {
        if (this.resultLayout === 2) {
            var newItem = '';
            var matchValues = '';
            if (fieldResultView !== '') {
                matchValues = item.listItem.matchValues.filter((item) => {
                    return fieldResultView.includes(item.label);
                });

                matchValues.sort((a, b) => {
                    return fieldResultView.indexOf(a.label) - fieldResultView.indexOf(b.label);
                });
            }

            return newItem;
        } else {
            return item;
        }
    }

    _buildSortedBy(firstField) {
        var sorteBy = this.state.specificFields.filter((field) => {
            return !field.hidden && field.code.toLowerCase() !== firstField.toLowerCase();
        }).map((field) => {
            return field.code;
        });

        sorteBy.unshift(firstField);

        return sorteBy;
    }

    _searchByKeyDown() {
        if (this.state.specificFieldsValues !== null)
            this._searchByValue(1);
        else {
            setTimeout(() => {
                this._searchByKeyDown()
            }, 50);
        }
    }

    _resetSortBy(allSortedBy, changeSortField) {
        var specificFieldsValuesAsArray = Object.keys(this.state.specificFieldsValues);
        var specificFieldsValues = {};
        specificFieldsValuesAsArray.forEach((item) => {
            var value = this.state.specificFieldsValues[item];
            if (typeof value !== 'undefined' && value !== null && value !== '')
                specificFieldsValues[item] = value;
        });
        specificFieldsValuesAsArray = Object.keys(specificFieldsValues);

        var firstFieldSort = null;
        if (specificFieldsValuesAsArray.length === 1 && typeof changeSortField === 'undefined') {
            firstFieldSort = specificFieldsValuesAsArray[0];
        } else
            firstFieldSort = typeof this.state.selectedSortField === 'undefined' || this.state.selectedSortField.length === 0 ? 'title' : this.state.selectedSortField[0].code;

        var sortedBy = this._buildSortedBy(firstFieldSort);

        var selectedSortField = [];
        if (typeof allSortedBy !== 'undefined') {
            selectedSortField = [this.state.specificFields.find((item) => {
                return item.code === allSortedBy;
            })];
        } else if (typeof changeSortField === 'undefined' && firstFieldSort !== null) {
            selectedSortField = this.state.selectedSortField.length > 0 && this.state.specificFields.length > 0 ? [this.state.specificFields.find((item) => {
                return item.code === firstFieldSort;
            })] : [];
        } else
            selectedSortField = this.state.selectedSortField;

        const showSortedBy = typeof allSortedBy === 'undefined';

        return {sortedBy, specificFieldsValues, selectedSortField, showSortedBy};
    }

    _searchByValue(nPage, indexedValueSearch, isExport, allSortedBy, changeSortField, callback) {
        var call = '';

        const sortJson = this._resetSortBy(allSortedBy, changeSortField);
        var specificFieldsValues = sortJson.specificFieldsValues;
        var sortedBy = sortJson.sortedBy;

        const validSpecificValues = Object.keys(this.state.specificFieldsValues).filter((field) => {
            return this.state.specificFieldsValues[field].trim() !== '';
        });

        const onlyTitle = validSpecificValues.length === 1 && validSpecificValues[0] === 'TITLE';

        if (typeof nPage === 'undefined')
            nPage = this.state.nPage;
        if (typeof isExport === 'undefined')
            isExport = false;

        var searchText = (typeof this.props.docsSearch === 'undefined' && this.state.openSearchText) || this.props.docsSearch;

        if (typeof allSortedBy !== "undefined") {
            call = '/tree/searchIndexed/' + this.props.projectName + '/___/' + nPage + '/' + this.state.selectedObject[0].code + '/{}/all/' + this._buildSortedBy(allSortedBy) + '/' + 'AND' + '/' + searchText + '/' + isExport + '/' + true + '/'
        } else if (typeof indexedValueSearch === 'undefined') {
            indexedValueSearch = typeof this.state.indexedValue === 'undefined' || this.state.indexedValue === '' ? '___' : this.state.indexedValue;
            const selectedObject = this.state.selectedObject.length > 0 ? this.state.selectedObject[0].code : "all";
            //const selectedField = this.state.selectedField.length > 0 ? (typeof this.state.selectedField[0].code !== 'undefined' ? this.state.selectedField[0].code : this.state.selectedField[0].label) : "all";
            specificFieldsValues = indexedValueSearch.trim() === '___' ? JSON.stringify(specificFieldsValues) : '{}';
            searchText = this.state.openSearchText;
            const selectedDate = this.state.selectedDate === {
                from: '',
                to: ''
            } ? "all" : JSON.stringify(this.state.selectedDate);

            call = '/tree/searchIndexed/' + this.props.projectName + '/' + indexedValueSearch + '/' + nPage + '/' + selectedObject + '/' + specificFieldsValues + '/' + selectedDate + '/' + sortedBy + '/' + 'AND' + '/' + searchText + '/' + isExport + '/' + true + '/'
        } else {
            call = '/tree/simpleSearchIndexed/' + this.props.projectName + '/' + nPage + '/' + indexedValueSearch + '/' + Dict.encodeUrl(JSON.stringify(this.props.simpleSearch)) + '/' + sortedBy + '/' + this.props.docsSearch + '/' + isExport + '/' + true + '/';
        }

        Dict.call(call).then((result) => {
            var fieldResultView = null;
            const selectedObject = this.state.selectedObject;
            if (result.listItems.length > 0 && typeof selectedObject !== 'undefined' && selectedObject.length > 0 && TreeNodeHelper.isSubContainerItem(selectedObject[0].code)) {
                if (this.resultLayout === 2) {
                    try {
                        fieldResultView = Dict.getGlobalProperty('ADVANCED_SEARCH_RESULT_FIELDS').split('::')[1].split(',');
                    } catch (error) {
                        console.error(error);
                    }
                }
            }

            if (isExport) {
                var header = {};
                var headerDataToAdd = null;
                //header['type'] = Dict.t('TYPE').toUpperCase();
                if (fieldResultView === null) {
                    header['title'] = Dict.t('TITLE').toUpperCase();
                    result.listItems[0].data.forEach((dataItem) => {
                        header[dataItem.label] = Dict.t(dataItem.label).toUpperCase();
                    });
                } else {
                    headerDataToAdd = result.listItems[0].data.map(item => {
                        return item.label
                    }).concat('TITLE').filter(item => {
                        return fieldResultView.includes(item);
                    });
                    headerDataToAdd.sort((a, b) => {
                        return fieldResultView.indexOf(a) - fieldResultView.indexOf(b);
                    });
                    headerDataToAdd.forEach((dataItem) => {
                        if (dataItem === 'CONTAINER_TITLE') {
                            var container = TreeNodeHelper.getContainerBySubitem(this.state.selectedObject[0].code);
                            header[dataItem] = TreeNodeHelper.getPropLabel(container).toUpperCase();
                        } else
                            header[dataItem] = Dict.t(dataItem).toUpperCase();
                    })
                }

                var ret = result.listItems.map((item) => {
                    var retItem = {};
                    //retItem[item.code] = Dict.t(item.code);
                    var data = item.data;
                    if (fieldResultView !== null) {
                        data = data.filter((itemData) => {
                            return fieldResultView.includes(itemData.label);
                        });

                        data.push({label: 'TITLE', value: item.title});
                        data.sort((a, b) => {
                            return fieldResultView.indexOf(a.label) - fieldResultView.indexOf(b.label);
                        });
                        data.forEach((itemData) => {
                            retItem[itemData.label] = itemData.value;
                        });

                    } else {
                        retItem['TITLE'] = item.title;
                        data.forEach((itemData) => {
                            retItem[itemData.label] = itemData.value;
                        });
                    }


                    return retItem;
                });

                ret = [header].concat(ret);
                TreeUtilities.exportCSV('result', TreeUtilities.convertJsonArrayToCSV(ret));
            } else {
                var listItems = [];
                if (!searchText) {
                    listItems = result.listItems.map((item) => {
                        var rawMatchValues = [{label: 'TITLE', value: item.title}].concat(item.data.filter((datum) => {
                            if (fieldResultView !== null && !fieldResultView.includes(datum.label)) {
                                return false;
                            } else if (datum.label === 'CONTAINER_TITLE' || datum.label === 'CONTAINER_DATE') {
                                return true;
                            } else {
                                var obj = TreeNodeHelper.getItemByCode(item.code, this.props.composedTreeItems);

                                var retBasicItem = obj.basicItems.find((objItem) => {
                                    return objItem.label === datum.label;
                                });

                                return typeof retBasicItem !== 'undefined' && (typeof retBasicItem.hidden === 'undefined' || !retBasicItem.hidden);
                            }
                        }));

                        var matchValues = rawMatchValues.map((matchValue) => {
                            if (matchValue.code === "DATE_PICKER")
                                matchValue.value = TreeUtilities.writeDateString(new Date(matchValue.value), 'dd-mm-yyyy');
                            return matchValue;
                        });

                        if (!onlyTitle && fieldResultView !== null) {
                            matchValues.sort((a, b) => {
                                return fieldResultView.indexOf(a.label) - fieldResultView.indexOf(b.label);
                            });
                        }

                        return {item, matchValues};
                    });
                } else
                    listItems = result.listItems;

                const totalPages = result.totalPages;
                var pagItems = [];
                if (totalPages > 0) {
                    pagItems = this._handleShiftPagination(nPage, totalPages);
                }

                var isSpecificSearch = this.state.selectedObject.length > 0;
                const loading = false;

                var selectedSortField = sortJson.selectedSortField;
                const showSortedBy = sortJson.showSortedBy;
                this.setState({
                    listItems,
                    indexedValueSearch,
                    totalPages,
                    pagItems,
                    nPage,
                    isSpecificSearch,
                    loading,
                    selectedSortField,
                    showSortedBy
                }, () => {
                    var objMsg = document.getElementById(this.props.id + '-tree-indexed-search-item__body-no-items-found');
                    if (listItems.length === 0) {
                        objMsg.style.display = 'block';
                        if (typeof this.props.indexedSearchQuery === 'undefined') {
                            setTimeout(function () {
                                objMsg.style.display = 'none';
                            }, 2000)
                        }
                    } else {
                        objMsg.style.display = 'none';
                    }
                });

                if (typeof callback === 'function')
                    callback();
            }
        })
    }

    _matchValue(searchText, value) {
        var cleanText = this._buildCleanText(searchText);
        var retValue = "";

        if (searchText.endsWith("*")) {
            retValue = value.startsWith(cleanText) || value.indexOf(" " + cleanText) >= 0;
        } else if (searchText.startsWith("*")) {
            retValue = value.endsWith(cleanText) || value.indexOf(cleanText + " ") >= 0;
        } else {
            retValue = value.indexOf(cleanText) >= 0;
        }

        if (!retValue) {
            const isUpperFirst = searchText[0] === searchText[0].toLowerCase();
            searchText = isUpperFirst ? (value.charAt(0).toUpperCase() + value.slice(1)) : value.toLowerCase();

            return this._matchValue(searchText, value);
        }


        return retValue;
    }

    _buildCleanText(text) {
        return text.replace(/\*/g, '');
    }

    _highlight(text, label) {
        //this.state.indexedValueSearch
        //SON65
        if (typeof label !== 'undefined' && typeof this.state.specificFieldsValues[label] !== 'undefined') {
            var textToClean = this._buildCleanText(this.state.specificFieldsValues[label]);
            var highlightRet = BUtils.highlight(textToClean, text);
            if (highlightRet === text) {
                const isUpperFirst = textToClean[0] === textToClean[0].toLowerCase();
                var newText = isUpperFirst ? (textToClean.charAt(0).toUpperCase() + textToClean.slice(1)) : textToClean.toLowerCase();
                return BUtils.highlight(newText, text);
            } else
                return BUtils.highlight(textToClean, text);
        } else return text;
    }

    _reset() {
        this.setState({
            listItems: [],
            page: 0,
            specificFields: [],
            specificFieldsValues: {}
        })
    }

    _populateSpecificObjects(callback) {
        var subitems = [];
        var openSpecificDate = false;
        var selectedObject = this.state.selectedObject;

        var selectedDate = {from: '', to: ''};
        TreeNodeHelper.treeContainersItems.forEach((item) => {
            return subitems = subitems.concat(item.subItems);
        })

        const containerObjects = TreeNodeHelper.treeContainersItems.concat(subitems).map(item => {
            var newItem = JSON.parse(JSON.stringify(item));
            newItem.label = TreeNodeHelper.getPropLabel(item);
            return newItem;
        });

        var specificObjects = TreeNodeHelper.treeBasicItems.map(item => {
            var newItem = JSON.parse(JSON.stringify(item));
            newItem.label = Dict.t(item.code);
            return newItem;
        });

        specificObjects = containerObjects.concat(specificObjects);

        var openSpecificObject = !this.state.openSpecificObject;
        if (!openSpecificObject) {
            selectedObject = [];
            this._resetFields();
        }

        this.setState({
            openSpecificObject,
            specificObjects,
            openSpecificDate,
            selectedObject,
            selectedDate
        }, function () {
            if (typeof callback !== 'undefined')
                callback();
        });
    }

    _changeSpecificObject(selectedObject) {
        var openSpecificDate = false;
        var openSearchText = false;
        var oldSelectedObject = this.state.selectedObject;
        var container = null;

        if (selectedObject.length > 0) {
            var selectedItem = TreeNodeHelper.getItemByCode(selectedObject[0].code);
            var specificFields = [];
            var selectedSortField = [];
            if (selectedItem.isBasic) {
                if ((selectedItem.element.type === 'textfield' || selectedItem.element.type === 'tinymce'))
                    specificFields = [selectedItem];
            } else {
                specificFields = selectedItem.basicItems.filter((item) => {
                    return item.type === 'textfield' || item.type === 'tinymce';
                }).map(item => {
                    return {label: Dict.t(item.label), type: item.type, code: item.label, hidden: item.hidden};
                });

                container = TreeNodeHelper.getContainerBySubitem(selectedItem.code);
            }

            selectedSortField = [{label: Dict.t('TITLE'), code: 'TITLE'}];
            specificFields = selectedSortField.concat(specificFields);

            if (this.layout === 1 && typeof container !== 'undefined' && container != null) {
                var datePickerContainer = container.basicItems.find((item) => {
                    return item.type === 'datepicker';
                });

                var containerFields = [{
                    label: (/*Dict.t('TITLE') + ' ' + */TreeNodeHelper.getPropLabel(container)),
                    code: 'CONTAINER_TITLE'
                }];

                if (datePickerContainer) {
                    containerFields.push({label: 'DATE', code: 'DATE'});
                }

                containerFields = containerFields.map((containerItem, index) => {
                    var label = index > 0 ? Dict.t(containerItem.label) : containerItem.label;
                    var code = containerItem.code;
                    var containerCode = container.code;
                    var containerFieldsSize = containerFields.length;
                    return {label, code, containerCode, containerFieldsSize}
                });

                specificFields = containerFields.concat(specificFields);
            }


        } else {
            specificFields = [];
        }

        var listDateObjects = this.state.listDateObjects;
        var foundDateItems = selectedObject.filter((obj) => {
            return listDateObjects.indexOf(obj.label) >= 0;
        });

        if (foundDateItems.length === 0 && container !== null) {
            if (listDateObjects.indexOf(container.code) >= 0)
                foundDateItems.push(container);
        }

        const hasDateFieldsOrNotSelected = foundDateItems.length > 0;

        this.setState({
            openSpecificDate,
            openSearchText,
            specificFields,
            selectedSortField,
            selectedObject,
            hasDateFieldsOrNotSelected
        }, () => {
            if (oldSelectedObject.length > 0 && oldSelectedObject !== selectedObject)
                this._resetFields();
        });
    }

    _changeSortedField(item) {

        var selectedSortField = [];
        if (item.length > 0) {
            selectedSortField = item;
            selectedSortField.label = [typeof item[0].code === 'undefined' ? item[0].label : Dict.t(item[0].code)]
        }

        const searchResultCol = document.getElementsByClassName(stylesSCTree["tree-indexed-search-item__body-col-results"])[0];
        searchResultCol.style.visibility = "hidden";
        this.setState({
            selectedSortField,
        }, () => {
            const listItems = this.state.listItems;
            if (typeof listItems !== 'undefined' && listItems.length > 0) {
                this._searchByValue(this.state.nPage, this.props.indexedSearchQuery, undefined, undefined, selectedSortField[0].code, () => {
                    searchResultCol.style.visibility = "visible";
                });
            }
        });
    }

    _populatePrefilledFields() {
        const prefilledFields = Dict.getGlobalProperty('PREFILLED_FIELDS');
        var prefilledObjects = [];
        if (typeof prefilledFields !== 'undefined' && prefilledFields !== null && prefilledFields !== '') {
            var prefilledFieldsSplit = prefilledFields.split(',');
            prefilledFieldsSplit.forEach((prefilledItem) => {
                var prefilledObject = {
                    prefilledItem: prefilledItem.split('::')[0].toUpperCase(),
                    prefilledField: prefilledItem.split('::')[1].toUpperCase(),
                    selected: []
                };
                prefilledObjects.push(prefilledObject);
            });
        }

        var prefilledJson = Dict.encodeUrl(JSON.stringify(prefilledObjects));
        Dict.call('/tree/prefillObjects/' + this.props.projectName + '/' + prefilledJson + '/').then((result) => {
            //per ora uno solo poi si farà un filter
            prefilledObjects[0].collection = result.comboItems;

            this.setState({prefilledObjects}, () => {
            });
        })
    }

    _populateDateObjects() {
        var listDateObjects = typeof this.props.composedTreeItems === 'undefined' ? [] : BUtils.cloneJson(this.props.composedTreeItems);
        var subItems = TreeNodeHelper.getSubItems();

        TreeNodeHelper.treeContainersItems.concat(listDateObjects).concat(subItems).filter((item) => {
            var objCode = item.basicItems.filter((basicItem) => {
                return basicItem.type === 'datepicker';
            });

            if (objCode.length > 0)
                listDateObjects.push(item.code);
        });

        this.setState({listDateObjects});
    }

    _openSpecificDate() {
        var openSpecificDate = !this.state.openSpecificDate;
        this.setState({
            openSpecificDate
        })
    }

    _openSearchText(newState) {
        this._resetOpen();

        var openSearchText = newState;
        var openSpecificDate = false;
        var openSpecificObject = false;

        this.setState({openSearchText, openSpecificDate, openSpecificObject});
    }

    _resetOpen() {
        var specificFields = [];
        var specificFieldsValue = {};
        var selectedSortField = [];
        var specificField = [];
        var selectedObject = [];
        var hasDateFieldsOrNotSelected = false;
        var selectedDate = {from: '', to: ''};
        var listItems = [];

        this.setState({
            specificFields,
            specificField,
            selectedSortField,
            selectedObject,
            selectedDate,
            specificFieldsValue,
            listItems,
            hasDateFieldsOrNotSelected
        });
    }

    _resetFields() {
        var specificFields = [];
        var specificFieldsValues = {};
        this.setState({specificFields, specificFieldsValues});
    }

    _fillYearTo(evt, isFrom) {
        var selectedDate = this.state.selectedDate;

        if (isFrom) {
            selectedDate.from = evt.target.value;
        } else
            selectedDate.to = evt.target.value;
    }

    _handlePagination(eventKey) {
        if (eventKey > 0) {
            this.setState({
                nPage: eventKey
            }, () => {
                if (this.props.docsSearch) {
                    this.setState({listItems: [], loading: true})
                }
                this._searchByValue(this.state.nPage, this.props.indexedSearchQuery);
            });
        }
    }

    _handleShiftPagination(nPage, totalPages) {
        var pagItems = [];
        var drawButton = true;

        for (let number = 1; number <= totalPages; number++) {
            if (nPage <= 2 && number <= 3) {
                drawButton = true;
            } else {
                var abs = Math.abs(number - nPage);

                if (nPage >= 3 && abs < 2) {
                    drawButton = true;
                } else {
                    drawButton = false;
                }
            }

            if (drawButton) {
                pagItems.push(
                    <Pagination.Item active={number === nPage} key={'key-page-item-' + number}
                                     onClick={() => this._handlePagination(number)}>{number}</Pagination.Item>)
            }

            drawButton = true;
        }

        return pagItems;
    }


    render() {

        var container = null;
        const selectedObject = this.state.selectedObject;
        const listItems = this.state.listItems;
        const hasListItems = typeof listItems !== 'undefined' && listItems.length > 0;

        if (!this.props.docsSearch && hasListItems && typeof selectedObject !== 'undefined' && selectedObject.length > 0 && TreeNodeHelper.isSubContainerItem(selectedObject[0].code)) {
            container = TreeNodeHelper.getContainerBySubitem(selectedObject[0].code);
        }

        const result = hasListItems ? listItems.map((listItem, index) => {
            const item = listItem.item;
            const openSearchText = this.state.openSearchText;
            var matchValues = listItem.matchValues;

            if (container !== null) {
                const toIndex = 2;
                const fromIndex = matchValues.findIndex((item) => {
                    return item.label === 'CONTAINER_TITLE';
                });
                const element = matchValues.splice(fromIndex, 1)[0];
                matchValues.splice(toIndex, 0, element);
            }

            const matchValuesDiv = openSearchText || typeof listItem.pages !== 'undefined' || matchValues.length === 1 ? '' : matchValues.map((matchValue, index) => {
                if (this.resultLayout === null || this.resultLayout === 1) {
                    if (matchValue.label !== 'TITLE') {
                        var id = item.key + "-" + matchValue.label + "-" + index;
                        var printLabel = Dict.t(matchValue.label);

                        if (matchValue.label === 'CONTAINER_TITLE') {
                            printLabel = /*Dict.t('TITLE') + ' ' +*/TreeNodeHelper.getPropLabel(container);
                        }

                        return <span className={stylesSCTree['result-tree-index-item__match__container']}>
                    <span
                        className={stylesSCTree['result-tree-index-item__match__label']}>{printLabel + ":"}</span>
                    <span className={stylesSCTree['result-tree-index-item__match__value']} id={id}
                          dangerouslySetInnerHTML={{__html: this._highlight(matchValue.value, matchValue.label)}}/>
                </span>;
                    }
                } else {
                    const isSortField = this.state.selectedSortField.length > 0 && matchValue.label === this.state.selectedSortField[0].code
                    var openTag = isSortField ? '<strong>' : '';
                    var closeTag = isSortField ? '</strong>' : '';

                    return <span
                        className={stylesSCTree['result-tree-index-item__match-value-item-layout-' + this.resultLayout]}
                        dangerouslySetInnerHTML={{__html: openTag + this._highlight(matchValue.value, matchValue.label) + closeTag}}/>
                }
            });

            var openButtons = "";
            if (this.state.openSearchText || typeof listItem.pages !== 'undefined') {
                var listToMap = listItem.pages.length > 5 ? listItem.pages.slice(0, 5) : listItem.pages;
                listToMap.sort();

                openButtons = listToMap.map((page) => {
                    return <Button style={{marginRight: '4px'}} onClick={() => {
                        this.props.callbackOpenTextIndexedSearch(item, page);
                        this.setState({showDocument: true});
                    }}>{'pag.' + page}</Button>
                })
            } else {
                openButtons = [];
            }

            return <div
                className={BUtils.joinClasses(stylesSCTree['result-tree-index-item'], stylesSCTree['result-tree-index-item--' + ((index + 1) % 2 === 0 ? 'even' : 'odd')])}>
                    <span className={stylesSCTree['result-tree-index-item__data']} onClick={() => {
                        this.props.callbackOpenItem(item);
                    }
                    }>
                        {this.resultLayout === null || this.resultLayout === 1 ? <><span
                            className={stylesSCTree['result-tree-index-item__tooltip']}>
                        <FontAwesome
                            className={'ooo'}
                            data-tip data-for={'tooltip-advanced-item-code-' + index}
                            name={this.props.itemsToIcon[item.code]}/>
                        <ReactTooltip id={'tooltip-advanced-item-code-' + index} type='info' delayShow={20}
                                      className={"tree-tool-tip"}>{Dict.t(item.code)}</ReactTooltip>
                        </span>
                            <span
                                className={BUtils.joinClasses(stylesSCTree['result-tree-index-item__descr'], stylesSCTree['result-tree-index-item__descr__no-match--' + (matchValuesDiv === '')])}
                                dangerouslySetInnerHTML={{__html: this._highlight(item.title)}}/>
                        </> : ''}
                        <span
                            className={BUtils.joinClasses(stylesSCTree['result-tree-index-item__match-value'], stylesSCTree['result-tree-index-item__match-value--' + (matchValuesDiv !== '')])}>{matchValuesDiv}</span>
                    </span>
                <span className={stylesSCTree['result-tree-index-item__button']}>
                    {openButtons}
                    </span>
            </div>;
        }) : '';

        var filter = result === '' || this.state.openSearchText || this.props.docsSearch ? '' :
            <div className={stylesSCTree['result-tree-index-item__filter']}>
                {this.state.isSpecificSearch ? <Button style={{marginLeft: '5px'}} onClick={() => {
                    this._exportResult();
                }}>{Dict.t('EXPORT')}
                </Button> : ''}
                {/*<Button style={{marginLeft: '5px'}} onClick={() => {
                    document.getElementById('tree-indexed-search-filter-th-container').style.visibility = 'visible';
                }
                }>
                    <FontAwesome
                        name={'filter'}/>
                </Button> */}
                {this.state.showSortedBy || true ?
                    <div id={'tree-indexed-search-filter-th-container'} style={{visibility: 'visible'}}>
                        <h4 id='tree-indexed-search-filter-label' style={{display: 'inline', marginLeft: '15px'}}>
                            <Label bsStyle={'primary'}
                                   className={stylesSCTree['tree-indexed-search-filter-label']}>{Dict.t('ORDER_BY')}:</Label>
                        </h4>
                        <DropdownButton
                            bsSize="medium"
                            title={this.state.selectedSortField.length > 0 && typeof this.state.selectedSortField[0] !== 'undefined' ? this.state.selectedSortField[0].label : ''}
                            id="dropdown-size-medium-field-filter"
                        >
                            {this.state.specificFields.filter((field) => {
                                return typeof field.hidden === 'undefined' || !field.hidden;
                            }).map((selectedSortField, index) => {
                                return <MenuItem eventKey={index} onSelect={() => {
                                    this._changeSortedField([selectedSortField]);
                                }}>{selectedSortField.label}</MenuItem>
                            })}
                        </DropdownButton>
                    </div> : ''}
            </div>;


        const hasIndexedSearchQuery = typeof this.props.indexedSearchQuery !== 'undefined';

        var selectedObjectFields = '';
        var showIndexButton = [];

        if (typeof this.state.specificFields !== 'undefined' && this.state.specificFields.length > 0) {
            var containerSize = this.state.specificFields[0].containerFieldsSize;
            const hasContainer = typeof containerSize !== 'undefined';

            selectedObjectFields = this.state.specificFields.filter((field) => {
                return !field.hidden;
            }).map((field, index) => {
                var fieldToId = field.code;
                var isContainer = hasContainer && index < containerSize;
                var label = '';
                var additionalInputGroup = '';
                var fieldLabel = (isContainer ? 'CONTAINER_' : '') + field.label;
                var isDatePicker = field.code === 'DATE_PICKER' || field.code === 'DATE';
                var addonLabel = '';

                if (hasContainer && this.layout === 1) {
                    var labelAdditionalText = index === 0 ? TreeNodeHelper.getPropLabel(TreeNodeHelper.getContainerItemByCode(field.containerCode)) : (index === containerSize ? TreeNodeHelper.getPropLabel(this.state.selectedObject[0]) : '');
                    label = labelAdditionalText !== '' ?
                        <h4 style={{marginTop: '7px', marginBottom: 0}}><Label
                            bsStyle="primary">{Dict.t('FIELDS') + ' ' + labelAdditionalText}</Label></h4> : ''
                }

                if (isDatePicker) {
                    //var to_fieldLabel = (isContainer ? 'CONTAINER_' : '') + 'TO';
                    additionalInputGroup = <InputGroup
                        className={BUtils.joinClasses(stylesSCTree["input-group-indexed-search-date"], stylesSCTree['input-group-indexed-search-date--' + this.state.openSpecificDate])}>
                        <InputGroup.Addon key={"key-ref-create-entry"}
                                          style={{
                                              fontWeight: 'bold',
                                              minWidth: '150px',
                                              fontSize: '12px',
                                              height: '10px',
                                          }}>{Dict.t('TO')}</InputGroup.Addon><FormControl
                        id={'indexed-value-search-' + fieldToId}
                        type="number"
                        style={{width: '100%'}}
                        placeholder={Dict.t('SEARCH')}
                        value={isDatePicker ? this.state.selectedDate.to : (typeof this.state.specificFieldsValues[fieldLabel] !== 'undefined' ? this.state.specificFieldsValues[fieldLabel] : '')}
                        ref={(input) => {
                            this['indexSearch' + field.code] = input;
                        }}
                        disabled={typeof this.state.indexedValue !== 'undefined' && this.state.indexedValue !== ''}
                        onChange={(evt) => {
                            var selectedDate = this.state.selectedDate;
                            selectedDate.to = evt.target.value;
                            this.setState({selectedDate})
                        }}
                        onKeyDown={(evt) => {
                            if (evt.keyCode === 13) {
                                this._searchByValue(1);
                            }
                        }}
                    /></InputGroup>;

                    addonLabel = Dict.t('FROM');
                } else {
                    addonLabel = field.label;
                }

                var elementSearch = '';
                const prefilledObjects = this.state.prefilledObjects;
                const selectedObject = this.state.selectedObject;
                const hasSelAndPred = prefilledObjects.length > 0 && selectedObject.length > 0;
                if (hasSelAndPred && prefilledObjects[0].prefilledItem === selectedObject[0].code && fieldToId === prefilledObjects[0].prefilledField) {
                    elementSearch = <><Typeahead
                        id={'tree-indexed-search-specific-' + fieldToId}
                        key={'key-search-element-' + fieldToId}
                        ref={(ref) => this.specObject = ref}
                        maxResults={5}
                        minLength={1}
                        paginationText={Dict.t('DISPLAY_ADDITIONAL_RESULTS')}
                        className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-specific-element'])}
                        clearButton={false}
                        onChange={(selectedObject, evt) => {
                            var prefilledObjects = this.state.prefilledObjects;
                            var specificFieldsValues = this.state.specificFieldsValues;
                            if (selectedObject.length > 0) {
                                specificFieldsValues[field.code] = selectedObject[0].label;
                                prefilledObjects[0].selected = selectedObject;
                            } else {
                                //delete specificFieldsValues[field.code];
                                specificFieldsValues = this.state.specificFieldsValues;
                                prefilledObjects[0].selected = [];
                                specificFieldsValues[field.code] = "";
                            }

                            this.setState({specificFieldsValues, prefilledObjects})

                        }}

                        onFocus={(rr) => {
                            console.log();
                        }}

                        onInputChange={(text, evt) => {
                            var listItems = this.state.listItems;
                            var specificFieldsValues = this.state.specificFieldsValues;
                            specificFieldsValues[field.code] = text;
                            this.setState({specificFieldsValues, listItems})
                        }}

                        onKeyDown={(evt) => {
                            if (evt.keyCode === 13) {
                                setTimeout(() => {
                                    this._searchByKeyDown();
                                }, 1000);
                            }
                        }
                        }
                        onSearch={() => {
                            return;
                        }}
                        options={this.state.prefilledObjects[0].collection}
                        selected={this.state.prefilledObjects[0].selected}
                        placeholder={Dict.t('SEARCH')}
                    />
                        <div style={{textAlign: 'right', marginBottom: '35px'}}
                             className={stylesSCTree['tree-indexed-search-item_button']}>
                            <Button onClick={() => {
                                this._searchByValue(1);
                            }}>
                                <FontAwesome
                                    name={'search'}/>
                            </Button>
                            <Button style={{marginLeft: '2px'}} onClick={() => {
                                var specificFieldsValues = this.state.specificFieldsValues;
                                prefilledObjects[0].selected = [];
                                specificFieldsValues[field.code] = "";
                                this.specObject.getInstance().clear();
                                this.setState({specificFieldsValues, prefilledObjects});
                            }}>
                                <FontAwesome
                                    name={'close'}/>
                            </Button>
                        </div>
                    </>
                } else {
                    elementSearch = <>
                        <FormControl
                            id={'indexed-value-search-' + fieldToId}
                            type={isDatePicker ? "number" : "text"}
                            style={{width: '100%', marginRight: '3px'}}
                            placeholder={Dict.t('SEARCH')}
                            value={isDatePicker ? this.state.selectedDate.from : (typeof this.state.specificFieldsValues[field.code] !== 'undefined' ? this.state.specificFieldsValues[field.code] : '')}
                            ref={(input) => {
                                this['indexSearch' + field.code] = input;
                            }}
                            disabled={typeof this.state.indexedValue !== 'undefined' && this.state.indexedValue !== ''}
                            onChange={(evt) => {
                                if (isDatePicker) {
                                    var selectedDate = this.state.selectedDate;
                                    selectedDate.from = evt.target.value;
                                    this.setState({selectedDate})
                                } else {
                                    var specificFieldsValues = this.state.specificFieldsValues;
                                    var listItems = this.state.listItems;
                                    if (evt.target.value.length > 0)
                                        specificFieldsValues[field.code] = evt.target.value;
                                    else {
                                        delete specificFieldsValues[field.code];
                                    }

                                    this.setState({specificFieldsValues, listItems});
                                }
                            }
                            }
                            onKeyDown={(evt) => {
                                if (evt.keyCode === 13) {
                                    this._searchByValue(1);
                                }
                            }}
                        /><Button onClick={() => {
                        this._searchByValue(1);
                    }}>
                        <FontAwesome
                            name={'search'}/>
                    </Button>
                        <Button style={{marginLeft: '2px'}} onClick={() => {
                            var specificFieldsValues = this.state.specificFieldsValues;
                            var listItems = this.state.listItems;
                            specificFieldsValues[field.code] = '';
                            this.setState({specificFieldsValues, listItems});
                        }}>
                            <FontAwesome
                                name={'close'}/>
                        </Button>
                    </>
                }

                if (this.layout === 2) {
                    showIndexButton.push(<Button style={{marginRight: '5px'}} onClick={() => {
                        this._searchByValue(this.state.nPage, '___', false, fieldToId);
                    }}>
                        {Dict.t('SHOW_INDEX_BY') + " " + Dict.t(fieldToId)}
                    </Button>);
                }

                return <>{label}
                    <div className={stylesSCTree['input-group-indexed-search-complete']}>
                        <InputGroup
                            className={BUtils.joinClasses(stylesSCTree['input-group-indexed-search' + (isDatePicker ? '-date' : '')], (isDatePicker ? stylesSCTree['input-group-indexed-search-date--' + this.state.openSpecificDate] : ''))}>
                            <InputGroup.Addon key={"key-ref-create-entry"}
                                              style={{
                                                  fontWeight: 'bold',
                                                  minWidth: '80px',
                                                  fontSize: '12px',
                                                  height: '34px',
                                                  paddingTop: '9px'
                                              }}>{addonLabel}</InputGroup.Addon>
                            {elementSearch}
                        </InputGroup>{additionalInputGroup}</div>
                </>
            });
        }

        var pagination = result !== '' && this.state.pagItems.length > 1 ?
            <Pagination bsSize="small" style={{margin: '5px 10px 5px 0', verticalAlign: 'middle', display: 'block'}}
                        onSelect={this._handlePagination} key={'key-pagination'}>
                <Pagination.First onClick={() => this._handlePagination(1)} key={'key-pagination-first'}/>
                <Pagination.Prev
                    onClick={() => this._handlePagination(this.state.nPage > 1 ? this.state.nPage - 1 : -1)}
                    key={'key-pagination-prev'}/>
                {this.state.pagItems}
                <Pagination.Next
                    onClick={() => this._handlePagination(this.state.nPage < this.state.totalPages ? this.state.nPage + 1 : -1)}
                    key={'key-pagination-next'}/>
                <Pagination.Last onClick={() => this._handlePagination(this.state.totalPages)}
                                 key={'key-pagination-last'}/>
            </Pagination> : '';


        return <div id={this.props.id} key={'key-' + this.props.id} style={{left: this.props.left}}
                    className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-full--' + (this.props.indexedSearchOpen ? 'active' : 'inactive')], stylesSCTree['tree-indexed-search-all--' + hasIndexedSearchQuery])}>
            <Grid className={stylesSCTree['tree-indexed-search-item']} fluid>
                <Row id={this.props.id + '__header'}
                     className={BUtils.joinClasses(stylesSCTree['tree-item-search__title'], stylesSCTree['tree-minor-skin'])}>{hasIndexedSearchQuery ? (Dict.t('SEARCH') + ": '" + this.props.indexedSearchQuery + "' in " + this.state.additionalTitle) : Dict.t('INDEX_ADVANCED_SEARCH')}</Row>
                <Row className={BUtils.joinClasses(stylesSCTree['tree-indexed-item-search__body'])}>
                    <Col id={'tree-indexed-search-item__body-col-fields'} lg={5} md={5}
                         className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-item__body-col-fields'], stylesSCTree['tree-main-skin'], stylesSCTree['tree-indexed-search-item__body-col-fields--' + !hasIndexedSearchQuery])}>
                        {this.layout === 1 ? <>
                            <div
                                className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-item__body-col-fields__div'], stylesSCTree['tree-indexed-search-item__body-col-fields__first__div'])}>
                                <InputGroup style={{marginRight: '4px', width: '80%'}}>
                                    <FormControl
                                        id={'indexed-value-search'}
                                        type="text"
                                        placeholder={Dict.t(this.state.openSearchText ? 'DOCS_SEARCH' : 'ALL_FIELDS_SEARCH')}
                                        value={this.state.indexedValue}
                                        ref={(input) => {
                                            this.indexedSearch = input;
                                        }}
                                        onChange={(evt) => {
                                            var indexedValue = evt.target.value;
                                            this.setState({indexedValue});
                                        }}
                                        onKeyDown={(evt) => {
                                            if (evt.keyCode === 13) {
                                                this._searchByValue(1);
                                            }
                                        }}
                                    />
                                    <InputGroup.Addon style={{
                                        padding: '0 6px',
                                        height: '10px !important',
                                        position: 'absolute',
                                        right: '12px',
                                        zIndex: '1000',
                                        marginTop: '10px',
                                        backgroundColor: 'transparent',
                                        border: 0,
                                        cursor: 'pointer',
                                        opacity: '0.4'
                                    }}
                                                      onClick={() => {
                                                          this._emptySearch();
                                                          document.getElementById('indexed-value-search').focus();
                                                      }}><FontAwesome name={"times"}/></InputGroup.Addon>
                                </InputGroup>
                                <SCCheckbox
                                    checked={this.state.openSearchText}
                                    key={"key-chk-search-text-indexed"}
                                    additionalClass={stylesSCTree['chk-search-text-indexed']}
                                    label={Dict.t('DOCS_SEARCH')}
                                    tooltip={'cerca all\' interno di un documento'}
                                    id={"chk-search-text-indexed"}
                                    callbackParent={(id, newState) => {
                                        this._openSearchText(newState);
                                    }}/>

                            </div>
                            <div style={{textAlign: 'right', marginBottom: '35px'}}
                                 className={stylesSCTree['tree-indexed-search-item__body-col-fields__first__div']}>
                                <Button onClick={() => {
                                    this._searchByValue(1);
                                }}>
                                    {Dict.t('SEARCH')}
                                </Button>
                            </div>
                        </> : ''}
                        <div
                            className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-item__body-col-fields__first__div'])}>
                            {!this.state.openSearchText && this.layout === 1 ? <><Button
                                className={stylesSCTree['button-indexed-search']}
                                onClick={() => {
                                    if (this.state.openSpecificObject)
                                        this._populateSpecificObjects();
                                    else
                                        this._performOpenSpecificObject();
                                }}>{Dict.t(this.state.openSpecificObject ? 'CLOSE_SPECIFIC_FIELDS' : 'SEARCH_SPECIFIC_FIELDS')}</Button>
                                {<Button
                                    className={BUtils.joinClasses(stylesSCTree['button-indexed-search'], stylesSCTree['button-indexed-search--' + this.state.hasDateFieldsOrNotSelected])}
                                    onClick={() => {
                                        this._openSpecificDate();
                                    }}>{Dict.t(this.state.openSpecificDate ? 'CLOSE_SEARCH_BY_DATE' : 'SEARCH_BY_DATE')}</Button>}</> : ''}
                            {this.layout === 1 ? <Typeahead
                                id={'tree-indexed-search-specific-obj'}
                                ref={(typeahead) => this.specObject}
                                paginationText={Dict.t('DISPLAY_ADDITIONAL_RESULTS')}
                                className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-specific-obj'], stylesSCTree['tree-indexed-search-specific-obj--' + this.state.openSpecificObject])}
                                onChange={selectedObject => {
                                    this._changeSpecificObject(selectedObject);
                                }}
                                onSearch={() => {
                                    return;
                                }}
                                options={this.state.specificObjects}
                                selected={this.state.selectedObject}
                                placeholder={Dict.t('COLLECTION')}
                            /> : ''}
                            {this.layout === 2 ? <>{showIndexButton}<h3
                                style={{marginTop: '25px', marginBottom: '15px'}}><Label
                                bsStyle="primary">{Dict.t('SEARCH_FIELDS')}</Label></h3></> : ''}
                            {selectedObjectFields}
                            {this.layout === 2 || true ? <div style={{textAlign: 'right', marginTop: '10px'}}
                                                              className={stylesSCTree['tree-indexed-search-item__body-col-fields__first__div']}>
                                {/*<Button onClick={() => {
                                    this._searchByValue(1);
                                }}>
                                    {Dict.t('SEARCH')}
                                </Button>*/}
                            </div> : ''}
                        </div>
                    </Col>
                    <Col lg={hasIndexedSearchQuery ? 12 : 7} md={hasIndexedSearchQuery ? 12 : 7}
                         className={stylesSCTree['tree-indexed-search-item__body-col-results']}>
                        <div
                            className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-item__body-col-results__overview'], stylesSCTree['tree-indexed-search-item__body-col-results__overview--' + (result !== '')])}>{result}</div>
                        <div id={this.props.id + '-tree-indexed-search-item__body-no-items-found'}
                             className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-item__body-no-items-found'])}>
                            {Dict.t('NO_ITEMS_FOUND')}
                        </div>
                        <div className={stylesSCTree['sc-loading-info--' + this.state.loading]}>
                            <img className={stylesSCTree['sc-logo']}
                                 src={scLogo}/><span>{Dict.t('SEARCH_IN_PROGRESS_WAIT') + "..."}</span>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={BUtils.joinClasses(stylesSCTree['tree-item-search__footer'], stylesSCTree['tree-item-search__footer__doc--' + this.props.docsSearch])}>
                    {pagination}{filter}
                    <Button className={stylesSCTree['tree-item-advanced-search__bottom-buttons']} onClick={() => {
                        this._reset();
                        this.props.callbackCloseIndexedSearch();
                        this._showHideSearchFields(0);
                    }}><FontAwesome name={'times'}/></Button>
                </Row>
                <Modal id='tree-modal-doc-indexed-search' show={this.state.showDocument}
                       className={stylesSCTree["tree-modal-doc"]}
                       backdrop={'static'}
                       dialogClassName={stylesSCTree['tree-modal-doc__dialog']}
                       onHide={() => this.setState({showDocument: false})} restoreFocus>
                    <Modal.Header id={'tree-modal-doc-header'} className={stylesSCTree['tree-modal-doc__header']}/>
                    <Modal.Body id={'tree-modal-doc-body-indexed-search'}
                                className={stylesSCTree["tree-modal-doc__body"]}>
                    </Modal.Body>
                    <Modal.Footer><span id={'additional-tree-modal-doc-footer-items'}
                                        className={stylesSCTree['additional-tree-modal-doc-footer-items']}/><Button
                        onClick={() => {
                            //var doc = {filename: '', src: '', pageNumber: 1, numbPages: -1};
                            this.setState({showDocument: false});
                        }}>{Dict.t('CLOSE')}</Button></Modal.Footer>
                </Modal>
            </Grid>
        </div>

    }

}