import React from 'react';
import PropTypes from 'prop-types';
import {Popover, Glyphicon} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import {Notifications} from './../../../utilities/DictUtilities';
import * as EN from './entry/EntryComponents';
import * as LV from './loc-vars/LocVarComponents';
import * as SU from './subentries/SubentryComponents';
import * as SE from './senses/SenseComponents';
import * as TR from './translations/TranslationComponents';
import * as PH from './phrases/PhrasesComponents';
import * as RE from './references/ReferencesComponents';
import {
    addTab,
    getPage,
    setPage,
    resetPage,
    getLexemeOptions,
    createLexemeTreeViewById,
    rebuildLexemeVarLocTreeViewById,
    getLexemeProperties,
    getDictionaryProperties,
    resetLexemeStatus,
    getTabs,
    changeTab,
    isToponym,
    openPage, LexemePageContent, changeLangDescr
} from './../LexemePage';
import {performSearchByInitialState} from './../../../search/lib/search';
import {SCModalMessage, SCModalTopSpinner, SCToastNotification} from './../../../dict-components/components';
import {LexemeEditor} from './LexemeEditor';
import {addTranslation} from './senses/SenseAction';
import './LexemeEditor.css';
import {toast} from 'react-toastify';
import Dict from 'dict-lib';
import {LexemePageContext} from "../LexemePageContext";

import stylesSearchPage from './../../../_css/dictionary/searchPage.module.css';
import stylesLexemePage from './../../../_css/dictionary/lexemePage.module.css';
import stylesSCPopup from './../../../dict-components/popup/SCPopup.module.css';

import BUtils from './../../../utilities/BaseUtilities';
import LexemePage from "./../LexemePage";
import {LexemeTitle} from "../parts/01_Title/LexemeTitle";
import {LexemeData} from "../parts/02_Data/LexemeData";
import {LexemeSubentries} from "../parts/03_Subentries/LexemeSubentries";
import {LexemePolyrhematics} from "../parts/04_Polyrhematics/LexemePolyrhematics";
import {LexemeReferences} from "../parts/05_References/LexemeReferences";
import {AdministrationUtilities} from "../../../administration/AdministrationHelper";
import {LexemaPageContextProvider} from "../LexemePageContextProvider";
import {saveEntryForm} from "./entry/EntryActions";

export var LexemeUtilities = {
    callByClassName: function (path, classNames, rebuildTree) {
        //this.closeAfterSave(); da aggiungere solo dove sicuramente non si blocca. Era stato messo nelle
        // operazioni dove tooltip e popup si chiudevano in sequenza (ed era brutto a vedersi), ma non ricordo quali
        classNames.map(className => {
            var elements = [].slice.call(document.getElementsByClassName(className));
            elements.map(classElement => {
                classElement.className += " lexemePartSaving";
            });
        });

        const promise = Dict.call(path);

        promise.then(result => {
            this.manageResult(result, classNames);
            if (rebuildTree) {
                rebuildLexemeVarLocTreeViewById();
            }
        });

        return promise;
    },

    callSimple: function (path, msg) {
        var msgAsClass = msg.replace(/_/g, '-').toLowerCase();
        this.showModalSpinner(Dict.t(typeof msg === 'undefined' ? 'loading' : msg), msgAsClass);

        const promise = Dict.call(path);
        promise.then(result => {
            if (result.result === 'OK') {
                this.closeAfterSave();
                if (Object.keys(result.message).length > 0)
                    LexemeUtilities.showToastNotification(result.message, 5000, 2000);
            } else {
                this.showModalMessage(result.result.toLowerCase(), result.message, 5000);
            }
        });

        return promise;
    },

    callResetLexemeStatus: function (path, msg) {
        msg = typeof msg === 'undefined' ? 'RECALCULATE_LEXEME_STATUS' : msg;

        return LexemeUtilities.callSimple(path, msg).then(result => {
            setTimeout(function () {
                resetLexemeStatus().then(result => {
                    setTimeout(function () {
                            LexemeUtilities.closeModalSpinner();
                            performSearchByInitialState();
                        },
                        500);
                });
            }, 0);
        });
    },

    callSpinner: function (path, msg) {
        this.showModalSpinner(Dict.t(typeof msg === 'undefined' ? 'loading' : msg), '');
        this.handleSaveWaiting();

        const promise = Dict.call(path);
        promise.then(result => {
            if (result.result !== 'OK') {
                if (result.abstract === 'conflict 409') {
                    this.callSystemError(path, result.message);
                } else
                    this.showModalMessage(result.result.toLowerCase(), result.message, 5000);
            } else {
                this.closeModalSpinner();
                this.closeAfterSave();
                this.setPage(result);
            }
            this.handleSaveFinish();
        });

        return promise;
    },

    call: function (path) {
        this.handleSaveWaiting();

        const promise = Dict.call(path);
        promise.then(result => {
            if (result.result === 'OK_NO_SET_PAGE') {
            } else if (result.result !== 'OK') {
                if (result.abstract === 'conflict 409') {
                    this.callSystemError(path, result.message);
                } else
                    this.showModalMessage(result.result.toLowerCase(), result.message, 5000);
            } else {
                this.closeAfterSave();
                this.setPage(result);
            }
        });

        return promise;
    },

    //TODO: accorpare
    callSystemError: function (path, message) {
        var page = getPage().page;
        this.call('/page/setSystemError/' + page.lexeme_properties.root_entry_id + '/' + path.split('/').slice(1, 3).join('^') + '/' + message + '/');
    },

    callBuilderError: function (id, message) {
        this.call('/page/setBuilderError/' + id + '/' + message.replace(/(\r\n|\n|\r)/gm, "") + '/');
    },

    callResetTabPages: function (path) {
        LexemeUtilities.handleSaveWaiting();
        return this.call(path).then(() => {
            LexemeUtilities.handleSaveFinish();
            this.resetTabPages();
        })
    },

    callByClassNameResetTabPages: function (path, classNames, rebuildTree) {
        LexemeUtilities.handleSaveWaiting();
        return this.callByClassName(path, classNames, rebuildTree).then(() => {
            LexemeUtilities.handleSaveFinish();
            this.resetTabPages();
        })

    },

    callHighlight: function (path, prefix) {
        this.call(path).then(result => {
            Notifications.closeModalSpinner();
            if(result.result === 'OK') {
                this.performHighlight(prefix, JSON.parse(result.additionalInfos).newId);
            } else
                this.showModalMessage(result.result.toLowerCase(), result.message, 4000);
        })
    },

    callHighlightWithModalSpinner: function (path, msg, prefix) {
        Notifications.showModalSpinner(msg);
        this.call(path).then(result => {
            Notifications.closeModalSpinner();
            if(result.result === 'OK') {
                this.performHighlight(prefix, JSON.parse(result.additionalInfos).newId);
            } else
                this.showModalMessage(result.result.toLowerCase(), result.message, 4000);
        })
    },

    callMultipleHighlight: function (path, prefix) {
        this.call(path).then(result => {
            JSON.parse(result.additionalInfos).newIds.split('_').forEach((id) => {
                this.performHighlight(prefix, id);
            })
        })
    },

    performHighlight: function (prefix, id) {
        var element = document.getElementById(prefix + id);
        var elementToAdd;
        if (element.classList.contains('lexHighlightable')) {
            elementToAdd = element;
        } else {
            elementToAdd = element.getElementsByClassName('lexHighlightable')[0];
        }

        elementToAdd.classList.add('lexHighlightable--active');

        setTimeout(() => {
            elementToAdd.classList.remove('lexHighlightable--active');
        }, 5000);
    },

    callNoClose: function (path) {
        const promise = Dict.call(path);
        promise.then(result => {
            this.handleSaveFinish();
            if (result.result === 'OK') {
                this.setPage(result);
            } else {
                if (result.abstract === 'conflict 409') {
                    this.callSystemError(path, result.message);
                } else
                    this.showModalMessage(result.result.toLowerCase(), result.message, 5000);
            }
        });

        return promise;
    },

    closeAfterSave: function () {
        const popovers = document.getElementsByClassName('popover');
        if (popovers.length > 0) {
            const popover = document.getElementsByClassName('popover')[0];
            popover.style = "visibility:hidden";
        }

        const draggables = document.getElementsByClassName('react-draggable');
        if (draggables.length > 0) {
            const draggable = document.getElementsByClassName('react-draggable')[0];
            draggable.style = "visibility:hidden";
        }

        setTimeout(function () {
            const btPopups = document.getElementsByClassName(stylesSCPopup['bt-popup-close']);
            var timeOut = 0;
            if (btPopups.length > 0) {
                btPopups[0].click();
                timeOut = 50;
            }

            setTimeout(function () {
                const elems = document.getElementsByClassName('lexEditableActive');
                if (elems.length > 0)
                    elems[0].click();
            }, timeOut);
        }, 50);
    },

    resetPage(result) {
        //resetPage(getPage());
        setPage(state, result);
    },

    changeLangDescr(descrLanguage) {
        //changeLangDescr(descrLanguage);

        var state = getPage();
        var result = JSON.parse(JSON.stringify(getPage()));
        result.descrLanguage = descrLanguage;
        setPage(state, result);

    },

    setPage(result, resetTab) {
        var state = getPage();

        var involvedIds = JSON.parse(result.additionalInfos) === null ? undefined : JSON.parse(result.additionalInfos).involvedLexemeIds;
        if (typeof involvedIds !== 'undefined') {
            this.resetTabInvolvedItems(state, involvedIds);
        }

        state.tabs[state.tabIndex].page = result.page;

        //state.descrLanguage = 'baddone';

        if (typeof resetTab !== 'undefined' && resetTab) {
            const id = parseInt(result.lexemePageAnalysis.id);
            state.id = id;
            state.tabs[state.tabIndex].id = id;
            state.tabs[state.tabIndex].entryForm = result.page.title.entry_form;
        }

        result.descrLanguage = state.descrLanguage;

        setPage(state, result);
        this.resetResultItem(result);
    },

    resetTabInvolvedItems(state, involvedIds) {
        var split = involvedIds.split(',');
        state.tabs.forEach((item, index) => {
            if (split.includes(item.id + "")) {
                Dict.call('/page/getPage/' + item.id + '/' + item.language + '/' + item.dictionatyType).then((itemResult) => {
                    state.tabs[index].page = itemResult.page;
                });
            }
        });
    },

    resetTabPages() {
        var state = getPage();
        state.tabs.forEach((item, index) => {
            Dict.call('/page/getPage/' + item.id + '/' + item.language + '/' + item.dictionatyType).then((itemResult) => {
                state.tabs[index].page = itemResult.page;
                //if (index === (parseInt(state.tabs.length) - 1)  /*state.id + '' === itemResult.page.lexeme_properties.root_entry_id*/)
                if (state.tabIndex === index)
                    setPage(state, itemResult);
            });
        });
    },

    resetResultItem(result) {
        var state = getPage();
        const rootEntryId = state.page.lexeme_properties.root_entry_id;
        var lexemeResult = document.getElementsByClassName("dict-rootentry-" + rootEntryId);
        var entryIdsToUpdate = '';
        var searchType = '';
        for (const lexemeResultElement of lexemeResult) {
            for (const classListElement of lexemeResultElement.classList) {
                if (classListElement.startsWith('search-type-')) {
                    searchType = classListElement.replace('search-type-', '');
                }
            }

            const entryId = lexemeResultElement.id.replace('dict-result-item-', '');
            if (entryId === rootEntryId) {
                var eleForm = document.getElementById('result-entryForm-' + rootEntryId);
                var eleAbstr = document.getElementById('dict-result-item-entryAbstr-' + rootEntryId);
                if ((eleForm.innerHTML !== result.page.lexeme_data.root_entry_form || eleAbstr.innerHTML !== result.page.entry_abstract)) {
                    eleForm.innerHTML = result.page.lexeme_data.root_entry_form;
                    if (!isToponym())
                        eleAbstr.innerHTML = result.page.entry_abstract;

                    var parent = eleAbstr.parentElement;
                    parent.classList.add(stylesSearchPage['dict-result-item--modified']);
                    setTimeout(function () {
                        parent.classList.remove(stylesSearchPage['dict-result-item--modified']);
                    }, 3000);
                }
            } else {
                entryIdsToUpdate += entryId + "_";
            }
        }

        if (entryIdsToUpdate.length > 0) {
            entryIdsToUpdate = entryIdsToUpdate.substring(0, entryIdsToUpdate.length - 1);
            Dict.call('/service/search/updateResultEntryItems/' + entryIdsToUpdate + '/' + searchType).then(result => {
                for (const resultItem of result) {
                    var eleForm = document.getElementById('result-entryForm-' + resultItem.id);
                    var eleAbstr = document.getElementById('dict-result-item-entryAbstr-' + resultItem.id)
                    if (eleForm.innerHTML != resultItem.entryForm || eleAbstr.innerHTML != resultItem.entryAbstract) {
                        eleForm.innerHTML = resultItem.entryForm;
                        eleAbstr.innerHTML = resultItem.entryAbstract;

                        var parent = eleAbstr.parentElement;
                        parent.classList.add('modified');
                    }
                }

                setTimeout(function () {
                    for (const lexemeResultElement of lexemeResult) {
                        lexemeResultElement.classList.remove('modified');
                    }
                }, 3000);
            });
        }
    },

    manageResult: function (result, classNames) {
        var state = getPage();
        const isError = result.result === 'ERROR';

        if (!isError) {
            this.closeAfterSave();
        }
        setTimeout(() => {
            if (!isError)
                this.setPage(result);
            classNames.map(className => {
                var elements = [].slice.call(document.getElementsByClassName(className)); // [].slice.call crea un array di oggetti. Mentre getElements una htmlcollection, che però non consente l'iterazione .map
                elements.map(classElement => {
                    if (!isError)
                        classElement.className = className + " lexemePartReload";
                    setTimeout(() => {
                        classElement.className = className;
                    }, isError ? 0 : 2000);
                });
            });
        }, isError ? 0 : 200);


        if (result.result === 'OK') {
            this.closeAfterSave();
        } else {
            if (result.abstract === 'conflict 409') {
                this.callSystemError(path, result.message);
            } else
                this.showModalMessage(result.result.toLowerCase(), result.message, 5000);
        }
    },

    playSound: function (media) {
        const playPromise = media.play();
        playPromise.catch(() => {
            this.showErrorModalMessage('MEDIA_RESOURCE_NOT_FOUND', 1500);
        })
    },

    showHighlight: function (elemId, duration, timeout) {
        const elem = document.getElementById(elemId);
        var className = elem.className;

        setTimeout(function () {
            elem.className += " lexHighlightable--active";
            setTimeout(function () {
                elem.className = className
            }, duration);
        }, timeout);
    },

    // TODO: Spostare tutti gli show messaging su DictUtilities->Notifications

    showToastNotification: function (message, duration, timeout) {
        Notifications.showToastNotification(message, duration, timeout);
        /*setTimeout(function () {
            toast(<SCToastNotification text={message}/>, {
                autoClose: duration > 0 ? 50000 : false,
                hideProgressBar: true
            })
        }, timeout);*/
    },

    showErrorModalMessage: function (message, timeout) {
        if (message.length > 130) {
            message = message.substring(0, 129) + '...';
        }

        Notifications.showErrorModalMessage(message, timeout);
    },

    showWarningModalMessage: function (message, timeout) {
        Notifications.showWarningModalMessage(message, timeout);
    },

    showModalMessage: function (type, message, timeout) {
        Notifications.showModalMessage(type, message, timeout);
    },

    showModalSpinner: function (message, additionalClass) {
        Notifications.showModalSpinner(message, additionalClass);
        /*ReactDOM.render(<SCModalTopSpinner show={true} msg={message + '...'}
                                           additionalClass={additionalClass}/>, document.getElementById("dict-modal-container"));*/
    },

    closeModalSpinner: function () {
        Notifications.closeModalSpinner();
        /*ReactDOM.render(<SCModalTopSpinner show={false} msg={''}/>, document.getElementById("dict-modal-container"));*/
    },

    closeModalPoly: function () {
        setTimeout(function () {
            document.getElementsByClassName("modal-poly")[0].click();
        }, 100);
    },

    emptyLexemePage: function () {
        ReactDOM.unmountComponentAtNode(document.getElementById("lexeme-page-container"));
    },

    encodeUrl: function (str) {
        return escape(encodeURIComponent(str));
    },

    retrieveLexemeToolbar: function (type, item, context, additionalData) {
        if (type === ToolbarTypes.ENTRY) {
            additionalData.isPoly = false;
            return LexemeToolbars.entry(item, context, additionalData);
        } else if (type === ToolbarTypes.LOC_VARS)
            return LexemeToolbars.locVars(item, context, additionalData);
        else if (type === ToolbarTypes.SUBENTRIES)
            return LexemeToolbars.subentries(item, context, additionalData);
        else if (type === ToolbarTypes.SENSES)
            return LexemeToolbars.senses(item, context, additionalData);
        else if (type === ToolbarTypes.SEMANTIC_UNIT_REFERENCES)
            return LexemeToolbars.semantic_unit_references(item, additionalData);
        else if (type === ToolbarTypes.TRANSLATIONS)
            return LexemeToolbars.translations(item, additionalData);
        else if (type === ToolbarTypes.PHRASES)
            return LexemeToolbars.phrases(item, context, additionalData);
        else if (type === ToolbarTypes.REFERENCES)
            return LexemeToolbars.references(item, context, additionalData);
        else if (type === ToolbarTypes.POLYRHEMATIC) {
            additionalData.isPoly = true;
            return LexemeToolbars.polyrhematic(item, context, additionalData);
        }
    },

    retrieveTranslationButtons: function (item) {
        return LexemeToolbars.translatable(item);
    },

    resetTranslationSelectorPopovers: function () {
        var trSelectors = document.getElementsByClassName("translation_selector");
        var len = trSelectors.length;
        for (var i = 0; i < len; i++) {
            if (typeof trSelectors[i] !== 'undefined')
                trSelectors[i].parentNode.removeChild(trSelectors[i]);
        }
    },

    getTypeaheadItem: function (collection, selected) {
        if (typeof selected !== 'undefined') {
            var index = collection.map((item) => {
                return item.id
            }).indexOf(Number(selected.id));

            return collection.slice(index, index + 1);
        } else return "";
    },

    getTypeaheadItemById: function (collection, id) {
        if (typeof id !== 'undefined') {
            var index = collection.map((item) => {
                return item.id
            }).indexOf(id);

            return collection.slice(index, index + 1);
        } else return "";
    },

    getTypeaheadItemByLabel: function (collection, label) {
        if (typeof label !== 'undefined') {
            var index = collection.map((item) => {
                return item.label
            }).indexOf(label);

            return collection.slice(index, index + 1);
        } else return "";
    },

    getContext: function (component) {
        return component.context;
    },

    getPageStatus: function (component) {
        const pageStatus = this.getContext(component).pageStatus;
        return typeof pageStatus === 'undefined' ? 'ERROR' : pageStatus;
    },

    getDisplayContext: function (component) {
        const displayContext = this.getContext(component).displayContext;
        return typeof displayContext === 'undefined' ? 'ERROR' : displayContext;
    },

    isDisplayContextDictionarySearch: function (component) {
        return this.getDisplayContext(component) === 'DICTIONARY_SEARCH';
    },

    isPageEditable: function (component) {
        return this.getPageStatus(component) === 'EDITABLE';
    },

    handleKeyUp(evt, enterCallback) {
        if (evt.keyCode === 13) {
            enterCallback();
        } else if (evt.keyCode === 27) {
            this.closeAfterSave();
        }
    },

    handleSaveWaiting() {
        const button = document.getElementsByClassName('lexemeButtonSave')[0];
        if (typeof button !== 'undefined') {
            button.classList.add('lexemeButtonSaveWait');
            button.classList.remove('lexemeButtonSave');
            //button.classList.remove('btn-default');
        }
    },

    handleSaveFinish() {
        const button = document.getElementsByClassName('lexemeButtonSaveWait')[0];
        if (typeof button !== 'undefined') {
            button.classList.add('lexemeButtonSave');
            button.classList.remove('lexemeButtonSaveWait');
        }
    },

    /*handleClickBCustomClass: function (component, item, className) {
        return this.handleClickByClassName(component, item, className);
    },*/

    handleClick: function (component, item, className) {

        const isReadOnly = this.isReadOnly(component);
        const isEditable = this.isPageEditable(component);
        const isSelectable = this.isSelectable(component);
        const isTranslatable = this.isTranslatable(component);
        const isOpenable = this.isOpenable(component);

        if (isReadOnly && isOpenable) {
            Dict.call('/page/getLexemePageData/' + item.entry_id + '/' + null + '/').then(result => {
                LexemeUtilities.openLexemePage(result.id, result.rootEntryId, true, LexemeEditingLevel.READ_ONLY, result.entryForm, undefined, result.entryAbstr, result.language, result.languageCode);
            });
        } else if (isEditable || isSelectable || isTranslatable) {
            const active = document.getElementsByClassName(typeof className === 'undefined' ? this.getLexClass(component) + 'Active' : className);
            if (active.length > 0)
                active[0].click();


            if (isSelectable)
                component.context.selectionHandler({name: component.state.name, ids: item.ids});

            return item === component.state.item ? '' : item;
        } else return '';
        //return isEditable || isSelectable || isTranslatable ? (item == component.state.item ? '' : item) : "";
    },

    createButtonBarTooltip: function (component) {
        const name = component.state.name;
        const id = "lex-" + name.toLowerCase() + "-button-bar";
        const props = component.props;
        const item = component.state.item;
        const context = component.context;

        if (context.pageStatus !== LexemeEditingLevel.EDITABLE && context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR || (typeof component.state.item === 'undefined' ? false : Object.keys(component.state.item).length === 0)) {
            return this.getHiddenPopover(id);
        } else {
            const isPolyEdit = typeof props.isPolyEdit !== 'undefined' && props.isPolyEdit;
            const page = props.page;
            const polyEditClass = isPolyEdit ? " poly-edit " : "";

            if (name === "TITLE") {
                return <Popover id={id} className={context.pageStatus.toLowerCase()}><LexemeEditor
                    name={name}
                    title={"Titiolo"}
                    type={ToolbarTypes.ENTRY} item={component.props.page} context={context}/></Popover>;
            } else if (name === "POLYRHEMATIC_TITLE") {
                return <Popover id="lex-poly-button-bar" className={context.pageStatus.toLowerCase()}><LexemeEditor
                    name={name}
                    title={"titolo"}
                    type={ToolbarTypes.POLYRHEMATIC}
                    item={this.isTranslationSelector(component) ? component.props : component.props.page}
                    context={context}
                    polyItem={item}/></Popover>
            } else if (name === "LOC_VARS") {
                var title = "";

                if (typeof item.id === 'undefined') {
                    var isFormBased = typeof item.form !== 'undefined';
                    title = isFormBased ? item.form : item.language;
                    var langs = "";
                    var sep = ",&nbsp;";
                    item.variants.map((itemLang) => langs += (isFormBased ? itemLang.language : itemLang.form) + sep);
                    title += "&nbsp;(" + langs.substring(0, langs.length - sep.length) + ")";
                } else {
                    title = item.form + "&nbsp;(" + item.language + ")";
                }

                title = (item.length > 0 && typeof item !== 'undefined' ? Dict.t(item) + ":&nbsp;" : "") + title;

                return <Popover id="lex-loc-var-button-bar">
                    <LexemeEditor
                        name={name}
                        title={title} type={ToolbarTypes.LOC_VARS}
                        item={item}
                        form={item.form}
                        context={context}/></Popover>;
            } else if (name === "SUBENTRY") {
                return <Popover id={id}
                                className={id + " " + polyEditClass + " " + context.pageStatus.toLowerCase()} /*positionLeft={400}*/>
                    <LexemeEditor
                        name={name}
                        title={item.cgr.description + " " + (typeof item.subentry_form === 'undefined' ? "" : item.subentry_form)}
                        type={ToolbarTypes.SUBENTRIES} item={item} isPolyEdit={isPolyEdit}
                        polyItem={isPolyEdit ? page : undefined}
                        form={""}
                        context={context}
                    />
                </Popover>
            } else if (name === "SENSE") {
                return <Popover id={id}
                                bsClass={polyEditClass + " underSuItem " + context.pageStatus.toLowerCase() + " popover"}>
                    <LexemeEditor
                        name={name}
                        title={item.description}
                        type={ToolbarTypes.SENSES} item={item} isPolyEdit={isPolyEdit}
                        polyItem={isPolyEdit ? props.polyItem : undefined}
                        form={""}
                        context={context}
                    />
                </Popover>;
            } else if (name === "PHRASE") {
                const phraseItem = item;
                const additionalData = {phraseItem, isSense: false};
                return <Popover id="lex-phrase-button-bar"
                                className={"popover" + " " + context.pageStatus.toLowerCase() + " "} positionLeft={400}><LexemeEditor
                    name={name}
                    title={item.description}
                    type={ToolbarTypes.PHRASES}
                    item={component.state.sense}
                    additionalData={additionalData}
                    form={""}
                    context={context}
                /></Popover>;
            } else if (name === "TRANSLATION") {
                const suItem = component.props.suItem;
                const isSense = component.props.isSense;
                const id = item.id;
                const entry_id = item.entry_id;
                const additionalData = {suItem, isSense, id, entry_id};

                return <Popover id="lex-tr-button-bar" bsClass={"popover"}
                                positionLeft={400}><LexemeEditor
                    name={name}
                    title={item.description}
                    type={ToolbarTypes.TRANSLATIONS}
                    item={item}
                    additionalData={additionalData}
                    form={""}
                    context={context}
                /></Popover>;
            } else if (name === "REFERENCE") {
                var direct = component.props.direct;
                const additionalData = {ids: item.ids, way: direct ? 'direct' : 'inverse', entry_id: item.entry_id};

                return <Popover id="lex-reference-button-bar" positionLeft={400}>
                    <LexemeEditor
                        name={name}
                        title={"sono baddi, proprio lui"}
                        type={ToolbarTypes.REFERENCES} item={item}
                        additionalData={additionalData}
                        isPolyEdit={component.props.isPolyEdit}
                        form={""}
                        context={context}
                    />
                </Popover>;
            } else if (name === "SEMANTIC_REFERENCE") {
                const additionalData = {ids: item.ids, way: props.way, entry_id: item.entry_id};

                return <Popover id="lex-semantic-unit-references-button-bar" bsClass={"popover"}
                                positionLeft={400}>
                    <LexemeEditor
                        name={name}
                        title={item.description}
                        type={ToolbarTypes.SEMANTIC_UNIT_REFERENCES}
                        item={item}
                        additionalData={additionalData}
                        form={""}
                        context={context}
                    /></Popover>;
            }
        }
    },

    getHiddenPopover: function (id) {
        return <Popover id={id} bsClass={'popover hidden-popover'}>&nbsp;</Popover>;
    },

    isSelectable: function (component) {
        const name = component.state.name;
        const status = component.context.pageStatus;

        if (name === "SUBENTRY") {
            return status === LexemeEditingLevel.SUBENTRY_SELECTOR;
        } else if (name === "SENSE") {
            return status === LexemeEditingLevel.SENSE_SELECTOR;
        } else if (name === "PHRASE") {
            return status === LexemeEditingLevel.PHRASE_SELECTOR
        } else
            return false;
    },

    isReadOnly: function (component) {
        const status = component.context.pageStatus;
        return status === LexemeEditingLevel.READ_ONLY;
    },

    isTranslationSelector: function (component) {
        return component.context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR;
    },

    isTranslatable: function (component) {
        return this.isTranslationSelector(component) && Object.values(TranslatableEntities).includes(component.state.name);
    },

    isOpenable: function (component) {
        return this.isReadOnly(component) && Object.values(OpenableEntities).includes(component.state.name);
    },

    getLexTextClass: function (component) {
        if (this.isPageEditable(component)) {
            return "lexTextEditable";
        } else
            return "lexTextOperationsDisabled";
    },

    getLexPolyOpenableClass: function (component) {
        if (!this.isReadOnly(component) && !this.isTranslationSelector(component)) {
            return "lexPolyOpenable";
        } else
            return "lexPolyOpenableDisabled";
    },

    getLexClass: function (component) {
        if (this.getDisplayContext(component) !== 'DICTIONARY_SEARCH') {
            return "lexPlainText";
        } else if (this.isPageEditable(component)) {
            return "lexEditable";
        } else if (this.isSelectable(component)) {
            return "lexSelectable";
        } else if (this.isTranslatable(component)) {
            return "lexTranslatable";
        } else if (this.isOpenable(component)) {
            return "lexOpenable";
        } else
            return "lexOperationsDisabled";
    },

    isActive: function (component, item) {
        const state = component.state;

        if ((typeof state.item === 'undefined') && state.id === item)
            return LexemeUtilities.getLexClass(component) + "Active";
        else if ((typeof state.item !== 'undefined') && ((typeof state.item.ids !== 'undefined' && item.ids === state.item.ids) || (typeof state.item.id !== 'undefined' && item.id === state.item.id)))
            return LexemeUtilities.getLexClass(component) + "Active";
        else
            return "";
    },

    openLexemePageByLexemeData(result, pageStatus, dictionaryType, callback) {
        this.openLexemePage(result.id, result.rootEntryId, true, pageStatus, result.entryForm, dictionaryType, result.entryAbstr, result.language, result.languageCode, callback);
    },

    openLexemePage(id, rootEntryId, isUpToDate, pageStatus, entryForm, dictionaryType, entryAbstr, langId, langCode, callback) {

        if (typeof callback === 'undefined')
            callback = () => {
            };

        var tabs = getTabs();

        if (tabs.length === 0) {
            const LexemePageConst = <LexemePage key={"key-lexeme-page-" + id}
                                                id={id} isUpToDate={isUpToDate}
                                                pageStatus={pageStatus}
                                                entryForm={entryForm}
                                                dictionaryType={dictionaryType}
                                                entryAbstr={entryAbstr}
                                                language={langId}
                                                languageCode={langCode}
                                                rootEntryId={rootEntryId}
                                                callbackParent={callback}
            />;
            ReactDOM.render(LexemePageConst, document.getElementById("lexeme-page-container"));
        } else {
            var state = getPage();
            var newTab = {
                id: id,
                entryForm: entryForm,
                languageCode: langCode,
                language: langId,
                dictionaryType: dictionaryType
            };

            var foundTab = state.tabs.findIndex((tab) => {
                return (newTab.id + '') === (tab.id + '') && newTab.entryForm === tab.entryForm && newTab.languageCode === tab.languageCode && newTab.dictionaryType === tab.dictionaryType;
            });

            if (foundTab > -1) {
                changeTab(state, foundTab);
            } else {
                state.tabs.push(newTab);

                state.isUpToDate = isUpToDate;
                state.tabIndex = state.tabs.length - 1;
                addTab(state);
            }
        }
    },

    exportLexemePages(searchParams, totalPages, langId, dictionaryType, callback) {
        const groupLenght = 20;
        searchParams = JSON.parse(JSON.stringify(searchParams));
        searchParams.nPage = 1;
        this.performExportLexemePages(searchParams, langId, dictionaryType, totalPages, [], [], (components, rawComponents) => {
            var groups = parseInt(components.length / groupLenght);
            if (groups === 0) {
                groups = 1;
            }
            var exportSplitContainer = document.createElement('span');
            exportSplitContainer.id = 'export-split-container';
            var exportSplitContainerStructure = document.createElement('span');
            exportSplitContainerStructure.id = 'export-split-container-structure';
            new Array(groups).fill().forEach((item, index) => {
                var part = components.slice(index * groupLenght, groupLenght * (index + 1));
                var div = document.createElement('div');
                div.id = 'export-split-part' + (index + 1);
                div.className = stylesSearchPage['export-split-part'] + ' export-split-part';
                ReactDOM.render(part, div);
                exportSplitContainer.appendChild(div);
            });

            var divStruct = document.createElement('div');
            divStruct.id = 'export-struct-split';
            divStruct.className = stylesSearchPage['export-struct-split-part'] + ' export-struct-split-part';
            ReactDOM.render(JSON.stringify(rawComponents), divStruct);
            exportSplitContainerStructure.appendChild(divStruct);

            document.getElementById("export-container").innerHTML = '';
            document.getElementById("export-container").appendChild(exportSplitContainer);
            document.getElementById("export-container").appendChild(exportSplitContainerStructure);

            //ReactDOM.render(components, document.getElementById("export-container"));

            callback();
        });
    },

    performExportLexemePages(searchParams, langId, dictionaryType, totalePages, components, rawComponents, callback) {
        var callSearch = 'service/search/' + JSON.stringify(searchParams);
        Dict.call(callSearch).then((result) => {
            var ids = [];

            for (const item of result.items) {
                ids.push(item.fields.id);
            }

            Dict.call('/page/getPages/' + JSON.stringify(ids) + '/' + langId + '/' + dictionaryType).then((pages) => {
                document.getElementById('bt-search-export-counter').innerText = parseInt(searchParams.nPage * 100 / totalePages) + "%"; //searchParams.nPage + '/' + totalePages;
                var counter = ((searchParams.nPage - 1) * 8) + 1;

                for (const page of pages) {
                    components.push(this.exportPage(page, counter++));
                }

                rawComponents = rawComponents.concat(pages);

                if (searchParams.nPage === totalePages) {
                    callback(components, rawComponents);
                } else {
                    searchParams.nPage = searchParams.nPage + 1;
                    this.performExportLexemePages(searchParams, langId, dictionaryType, totalePages, components, rawComponents, callback);
                }
                //return {components, nPage: searchParams.nPage};
                //callback(componentoni, searchParams.nPage);
            });
        });
    },

    exportPage(page, idPostFix) {
        idPostFix = typeof idPostFix === 'undefined' ? 1 : idPostFix;
        var pageStatus = LexemeEditingLevel.READ_ONLY;
        var displayContext = LexemeDisplayContext.EXPORT;

        return this.getLexemePage(idPostFix, pageStatus, page, displayContext);
    },

    getLexemePage(idPostFix, pageStatus, page, displayContext, classType) {
        return <LexemaPageContextProvider idPostFix={idPostFix} pageStatus={pageStatus} page={page} classType={classType}
                                          displayContext={displayContext}/>
    },

    downloadTmpFile: function (filename) {
        var url = BUtils.getServerUrl() + '/service/io/downloadFile/' + filename;
        var a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    },

    downloadHtml: function (html, filename) {
        //var html = document.getElementById(containerId).innerHTML;
        var res = '';
        var css = document.getElementsByTagName('style');
        for (let styleItem of css) {
            res += styleItem.outerHTML;
        }

        html = '<html><head><meta charset="UTF-8">' + res + '</head>' + html + '</html>';

        Dict.call('/entry/retrieveMediaData', {
            method: 'POST',
            data: html,
            headers: []
        }).then(function (result) {
            //var filename = result.filename;
            Dict.call('/service/io/createDownloadFile/' + result.filename + '/' + filename + '/html').then(result => {
                var url = BUtils.getServerUrl() + '/service/io/downloadFile/' + filename + '^dot^html';
                var a = document.createElement('a');
                a.href = url;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();

            });
        });
    },

    downloadJson: function (json, filename) {
        Dict.call('/entry/retrieveMediaData', {
            method: 'POST',
            data: json,
            headers: []
        }).then(function (result) {
            Dict.call('/service/io/createDownloadFile/' + result.filename + '/' + filename + '/json').then(result => {
                var url = BUtils.getServerUrl() + '/service/io/downloadFile/' + filename + '^dot^json';
                var a = document.createElement('a');
                a.href = url;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();

            });
        });
    },

    getContextTypes: function () {
        return {
            pageStatus: React.PropTypes.string,
            selectSenseHandler: React.PropTypes.func
        };
    }
};

export var LexemePageInfos = {

    createContext: function (pageStatus, displayContext, page, callBackSelectionHandler, startItem, descrLanguage, classType) {
        const languageId = Object.keys(page).length > 0 ? page.lexeme_properties.language_id : "";

        return {
            pageStatus: pageStatus,
            displayContext: displayContext,
            properties: page.lexeme_properties,
            options: page.lexeme_options,
            dictProperties: page.dictionary_properties,
            data: page.lexeme_data,
            rootEntryId: Object.keys(page).length > 0 ? page.lexeme_properties.root_entry_id : "",
            languageId,
            oppositeLanguages: AdministrationUtilities.getOppositeLanguages(languageId),
            secondaryOut: Object.keys(page).length > 0 ? page.lexeme_properties.language_id : "",
            isTree: Object.keys(page).length > 0 ? page.lexeme_properties.is_tree_model + "" : "",
            selectionHandler: callBackSelectionHandler,
            startItem: typeof startItem !== 'undefined' && startItem != null ? JSON.stringify(startItem) : '',
            descrLanguage: descrLanguage,
            classType,
        }
    },

    getLexemeProperties: function (context, property) {
        return context.properties[property];
    },

    getLexemeOptions: function (context, option) {
        return context.options[option.toLowerCase()];
    },

    getDictionaryProperties: function (context, property) {
        return context.dictProperties[property];
    },

    getRootEntryId: function (context) {
        return this.getLexemeProperties(context, "root_entry_id");
    },

    getLanguageId: function (context) {
        return this.getLexemeProperties(context, "language_id");
    },

    getDescriptionLanguage: function (context) {
        return this.getLexemeOptions(context, 'descr_language');
    },

    isLexeme: function (context) {
        return this.getLexemeProperties(context, 'lexeme');
    },

    isInverse: function (context) {
        return this.getDictionaryProperties(context, 'is_inverse');
    },

    isToponym: function (context) {
        return this.getLexemeProperties(context, 'discriminator') === 'TOPONYM';
    }

};

export var LexemeReduxUtilities = {

    buildTabsUniqueLexemeId: function (state, index) {
        return state.tabs[index].id
    },


}

export const ToolbarTypes = {
    ENTRY: "ENTRY",
    LOC_VARS: "LOC_VARS",
    SUBENTRIES: "SUBENTRIES",
    SENSES: "SENSES",
    SEMANTIC_UNIT_REFERENCES: "SEMANTIC_UNIT_REFERENCES",
    TRANSLATIONS: "TRANSLATIONS",
    PHRASES: "PHRASES",
    REFERENCES: "REFERENCES",
    POLYRHEMATIC: "POLYRHEMATIC"
};

export const LexemeEditingLevel = {
    EDITABLE: "EDITABLE",
    READ_ONLY: "READ_ONLY",
    TRANSLATING: "TRANSLATING",
    SUBENTRY_SELECTOR: "SUBENTRY_SELECTOR",
    SENSE_SELECTOR: "SENSE_SELECTOR",
    PHRASE_SELECTOR: "PHRASE_SELECTOR",
    TRANSLATION_SELECTOR: "TRANSLATION_SELECTOR",
    POLYRHEMATIC_SELECTOR: "POLYRHEMATIC_SELECTOR"
};

export const LexemeDisplayContext = {
    DICTIONARY_SEARCH: "DICTIONARY_SEARCH",
    EXPORT: "EXPORT",
    PHRASE_SEARCH: "PHRASE_SEARCH",
}

export const LexemeRenderStatus = {
    OPEN_PAGE: 'OPEN_PAGE',
    SET_PAGE: 'SET_PAGE',
    CHANGE_TAB: 'CHANGE_TAB',
    REMOVE_TAB: 'REMOVE_TAB'
}

export const LexemeStatusTypeSearchItemsArray = [
    {code: "ROOT_ENTRY", label: "ROOT_ENTRY"},
    {code: "ALL_ELEMENTS", label: "ALL_ELEMENTS"},
    {code: "AT_LEAST_ONE_ELEMENT", label: "AT_LEAST_ONE_ELEMENT"}
];


export const StatusType = {
    APPROVED: "APPROVED",
    TO_APPROVED_TO_REVISE: "TO_APPROVED_TO_REVISE",
    TO_REVISE: "TO_REVISE",
    TO_REVISE_NOT_APPROVED: "TO_REVISE_NOT_APPROVED",
    NOT_APPROVED: "NOT_APPROVED"
};

export const EditingStatusTypes = {
    ANY: {text: "ANY", glyphicon: "edit", bsStyle: "default"},
    EDITING: {text: "EDITING", glyphicon: "edit", bsStyle: "warning"},
    FIRST_APPROVAL: {text: "FIRST_APPROVAL", glyphicon: "unchecked", bsStyle: "info"},
    FINAL_APPROVAL: {text: "FINAL_APPROVAL", glyphicon: "check", bsStyle: "success"}
};

export const LanguageTypeAheadTypes = {
    ALL_LANGUAGES: "ALL_LANGUAGES",
    ROOT_LANGUAGES: "ROOT_LANGUAGES",
    TREE_LANGUAGE_HIERARCHY: "TREE_LANGUAGE_HIERARCHY"
};

export const TranslatableEntities = {
    TITLE: "TITLE",
    SUBENTRY: "SUBENTRY",
    SENSE: "SENSE",
    PHRASE: "PHRASE",
    POLYRHEMATICS: "POLYRHEMATICS",
    POLYRHEMATIC_TITLE: "POLYRHEMATIC_TITLE"
};

export const OpenableEntities = {
    REFERENCE: "REFERENCE",
    TRANSLATION: "TRANSLATION",
    SEMANTIC_REFERENCE: "SEMANTIC_REFERENCE"
}

export const SoundableEntities = {
    ROOT_ENTRY: "ROOT_ENTRY",
    LOC_VAR: "LOC_VAR",
    PHRASEOLOGY: "PHRASEOLOGY"
};

var LexemeToolbars = {

    isSingleLang: function (item) {
        return typeof item.id !== 'undefined' && typeof item.language !== 'undefined';
    },

    isGroupLang: function (item) {
        return typeof item.language !== 'undefined' && typeof item.id == 'undefined';
    },

    isSingleForm: function (item) {
        return typeof item.id !== 'undefined' && typeof item.form !== 'undefined';
    },

    isGroupForm: function (item) {
        return typeof item.form !== 'undefined' && !this.isSingleForm(item);
    },

    isGroupItem: function (item) {
        return this.isGroupLang(item) || this.isGroupForm(item);
    },

    isSingleFormMain: function (item) {
        return this.isSingleForm(item) && item.type === 'MAIN';
    },

    isSingleLangMain: function (item) {
        return this.isSingleLang(item) && item.type === 'MAIN';
    },

    isSingleFormAlternative: function (item) {
        return this.isSingleForm(item) && item.type !== 'MAIN';
    },

    isSingleLocVarItem: function (item) {
        return this.isSingleLang(item) || this.isSingleForm(item);
    },

    isSingleMainLocVarItem: function (item) {
        return (this.isSingleLang(item) || this.isSingleForm(item)) && item.type === 'MAIN';
    },

    getLocVarDeleteItem: function (item) {
        if (this.isGroupLang(item)) {
            return item.variants[0];
        } else if (this.isSingleLangMain) {
            return item;
        } else
            return null;
    },

    /*isLanguageGroupLocVarItem: function (item) {
     return this.isGroupLang(item) && typeof item.language !== 'undefined';
     },*/

    isTreeModel: function (additionalData) {
        return additionalData.is_tree_model;
    },

    checkChangeTypology: function (item) {
        return this.isSingleMainLocVarItem(item) && ((item.typology != "LEXOTYPE" || getLexemeOptions("allow_lexo_root")) && (item.type == 'MAIN'));
    },

    retrieveAddPhraseHeight() {
        var height = 160;
        if (getLexemeProperties("lexeme")) {
            height = parseFloat(height) + 50;
        }
        if (getLexemeOptions("phrase_original_script")) {
            height = parseFloat(height) + 50;
        }
        if (getLexemeOptions("bibliographical_reference")) {
            height = parseFloat(height) + 90;
        }

        return height;
    },

    isPoly(additionalData) {
        return additionalData.isPolyEdit;
    },

    isDirect() {
        return !getDictionaryProperties("is_inverse");
    },

    isActiveByContext(context) {
        return context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR;
    },

    entry: function (item, context, additionalData) {
        const isPoly = this.isPoly(additionalData);
        const isTransSelector = context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR;
        additionalData.soundType = SoundableEntities.ROOT_ENTRY;
        const isActiveByContext = this.isActiveByContext(context);
        const allFormSize = item.lexeme_data.all_forms.length;
        const isTree = this.isTreeModel(additionalData);
        const isToponym = item.lexeme_properties.discriminator === 'TOPONYM';

        return [
            {
                operation: 'DELETE_ENTRY',
                glyph: 'trash',
                component: <EN.EntryDelete item={item} additionalData={additionalData}/>,
                width: 600,
                height: 120,
                minHeight: 120,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'REBUILD',
                glyph: 'refresh',
                component: <EN.EntryRebuild item={item} /*form={form}*//>,
                width: 500,
                height: 130,
                minWidth: 450,
                minHeight: 130,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'EDITING_STATUS',
                glyph: 'ok',
                component: <EN.EntryEditingStatus item={item} additionalData={additionalData}/>,
                width: 800,
                height: 130,
                minWidth: 800,
                minHeight: 130,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'SHOW_HIDE',
                glyph: 'eye-open',
                component: <EN.EntryShowHide item={item} additionalData={additionalData}/>,
                width: 500,
                height: 150,
                minWidth: 500,
                minHeight: 100,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_SOUND',
                glyph: 'volume-up',
                component: <EN.EntryAddSound item={item} additionalData={additionalData}/>,
                width: 740,
                height: 360,
                minWidth: 740,
                minHeight: 360,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'EDIT_FORM',
                glyph: 'edit',
                component: <EN.EntryEditForm item={item} /*form={form}*//>,
                width: 500,
                height: 150,
                minWidth: 450,
                minHeight: 100,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'SORT',
                glyph: 'sort',
                component: <EN.EntrySort item={item} additionalData={additionalData}/>,
                width: isPoly ? 800 : 850,
                height: 570,
                minWidth: isPoly ? 800 : 850,
                minHeight: 570,
                x: 300,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'ADD_CGR',
                glyph: 'th-list',
                component: <EN.EntryAddSubentry item={item} additionalData={additionalData} context={context}/>,
                width: this.isTreeModel(additionalData) ? 800 : 500,
                minWidth: this.isTreeModel(additionalData) ? 800 : 500,
                minHeight: this.isTreeModel(additionalData) ? 770 : 300,
                height: this.isTreeModel(additionalData) ? 770 : 300,
                y: this.isTreeModel(additionalData) ? -100 : 0,
                active: !isToponym,
                status: 'OK'
            },
            {
                operation: 'ADD_ALTERNATIVE_FORM',
                glyph: 'plus-sign',
                component: <EN.EntryAddAltForm item={item}/>,
                width: 600,
                minWidth: 350,
                height: 225,
                minHeight: 225,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'MANAGE_ALTERNATIVE_FORMS',
                glyph: 'equalizer',
                component: <EN.EntryAltFormRemove item={item}/>,
                width: 400,
                minWidth: 400,
                height: 275,
                minHeight: 275,
                active: isActiveByContext && allFormSize > 1 && !isToponym,
                status: 'OK'
            },
            {
                operation: 'ADD_LOC_VAR',
                glyph: 'leaf',
                component: <EN.EntryAddVarLoc item={item}/>,
                width: 900,
                minWidth: 900,
                height: 550,
                minHeight: 350,
                active: isActiveByContext && isTree && !isToponym,
                status: 'OK'

            },
            {
                operation: 'ADD_POLY',
                glyph: 'list',
                component: <EN.EntryAddPoly item={item} context={context}/>,
                width: isTree ? 800 : 500,
                minWidth: isTree ? 800 : 500,
                minHeight: isTree && !isTransSelector ? 400 : 300,
                height: isTree && !isTransSelector ? 750 : 300,
                y: isTree ? -100 : 0,
                active: !isToponym,
                status: 'OK'

            },
            {
                operation: 'SET_PLACE',
                glyph: 'globe',
                component: <EN.EntryAddPlace item={item}/>,
                width: 400,
                minWidth: 400,
                height: 350,
                minHeight: 350,
                y: -100,
                active: isToponym && this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADVANCED_FIELDS',
                glyph: 'list-alt',
                component: <EN.EntryAdvancedFields item={item}/>,
                width: 800,
                height: 750,
                minHeight: 750,
                y: -100,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'CHANGE_TYPOLOGY',
                glyph: 'certificate',
                component: <EN.EntryChangeTypology item={item}/>,
                width: 500,
                minWidth: 500,
                height: 160,
                minHeight: 160,
                x: 520,
                y: 0,
                active: isActiveByContext && isTree && !isToponym,
                status: 'OK'
            },
            {
                operation: 'DATING',
                glyph: 'calendar',
                component: <EN.EntryDating item={item}/>,
                width: 860,
                minWidth: 860,
                height: 275,
                minHeight: 275,
                active: isActiveByContext,
                status: 'OK'
            },
            {
                operation: 'LINKS',
                glyph: 'link',
                component: <EN.EntryLinks item={item} additionalData={additionalData}/>,
                width: 900,
                minWidth: 900,
                height: 225,
                minHeight: 225,
                active: isActiveByContext,
                status: 'OK'
            }
        ]
    },

    polyrhematic: function (item, context, additionalData) {
        additionalData.polyItem = item;

        return [
            {
                operation: 'DELETE_ENTRY',
                glyph: 'trash',
                component: <EN.EntryDelete item={item} additionalData={additionalData}/>,
                width: 800,
                height: 120,
                minHeight: 120,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'NOTES',
                glyph: 'file',
                component: <EN.EntryNotes item={item}/>,
                width: 500,
                minWidth: 500,
                height: 500,
                minHeight: 100,
                x: -620,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'SORT_CGRS',
                glyph: 'sort',
                component: <EN.EntrySort item={item} additionalData={additionalData}/>,
                width: 535,
                height: 370,
                minWidth: 535,
                minHeight: 370,
                x: -620,
                y: 65,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'SHOW_HIDE',
                glyph: 'eye-open',
                component: <EN.EntryShowHide item={item} context={context} additionalData={additionalData}/>,
                width: 500,
                height: 150,
                minWidth: 450,
                minHeight: 100,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_CGR',
                glyph: 'th-list',
                component: <EN.EntryAddSubentry item={item} additionalData={additionalData} context={context}/>,
                width: this.isTreeModel(additionalData) ? 800 : 500,
                minWidth: this.isTreeModel(additionalData) ? 800 : 500,
                minHeight: this.isTreeModel(additionalData) ? 770 : 300,
                height: this.isTreeModel(additionalData) ? 770 : 300,
                y: this.isTreeModel(additionalData) ? -100 : 0,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_SENSE',
                glyph: 'plus-sign',
                component: <SE.SenseAdd item={item} additionalData={{isNew: true}} context={context}/>,
                minHeight: this.isTreeModel(additionalData) && context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR ? 750 : 200,
                height: this.isTreeModel(additionalData) && context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR ? 750 : 200,
                minWidth: 800,
                width: 800,
                y: this.isTreeModel(additionalData) ? -320 : 0,
                active: !this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'TRANSFORM_IN_PHRASE',
                glyph: 'retweet',
                component: <EN.EntryPolyTransformInPhrase item={item} additionalData={additionalData}/>,
                width: 900,
                minWidth: 900,
                height: 250,
                minHeight: 250,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'MOVE_POLY',
                glyph: 'export',
                component: <EN.EntryPolyMove item={item} additionalData={additionalData} isMove={true}/>,
                width: 900,
                minWidth: 900,
                height: 160,
                minHeight: 160,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'LINKS',
                glyph: 'link',
                component: <EN.EntryLinks item={item} additionalData={additionalData}/>,
                width: 900,
                minWidth: 900,
                height: 225,
                minHeight: 225,
                active: this.isActiveByContext(context),
                status: 'OK'
            }
        ]
    },
    locVars: function (item, context, additionalData) {
        additionalData.soundType = SoundableEntities.LOC_VAR;

        const isGroupItem = this.isGroupItem(item);
        const isGroupForm = this.isGroupForm(item);
        const isGroupLang = this.isGroupLang(item);
        const isSingleLocVarItem = this.isSingleLocVarItem(item);

        const hasAlternativeForms = isSingleLocVarItem && context.data.lexeme_locVars.find(locItem => {
            return locItem.language === item.language && locItem.variants.length > 1;
        });

        const openManageAltForms = isGroupLang && item.variants.length > 1;
        const isAlternativeForm = item.type === 'ALTERNATIVE';

        additionalData.isLocVar = true;

        return [
            {
                operation: this.isGroupLang(item) ? 'DELETE_LANGUAGE_VARS' : 'DELETE_LOC_VAR',
                glyph: 'trash',
                component: <LV.LocVarDelete item={this.getLocVarDeleteItem(item)} context={context}/>, //form={form}/>,
                width: 600,
                height: 400, //(item.type == "MAIN" ? -1 : 120),
                minHeight: 120,
                additionalPopupClass: 'additional-deleteLocVar',
                active: this.isGroupLang(item) || (this.isSingleLangMain(item) && !hasAlternativeForms),
                status: 'OK'
            },
            {
                operation: 'REMOVE_FORM',
                glyph: 'remove',
                component: <LV.LocVarRemoveForm item={item} /*form={form}*//>,
                width: 600,
                height: 130,
                minHeight: 130,
                active: (isSingleLocVarItem || this.isGroupForm(item)) && isAlternativeForm,
                status: 'OK'
            },
            {
                operation: 'MANAGE_ALTERNATIVE_FORMS',
                glyph: 'equalizer',
                component: <EN.EntryAltFormRemove item={item} additionalData={additionalData}/>,
                width: 400,
                minWidth: 400,
                height: 275,
                minHeight: 275,
                active: openManageAltForms,
                status: 'OK'
            },
            {
                operation: 'ADVANCED_FIELDS',
                glyph: 'list-alt',
                component: <LV.LocVarAdvancedFields item={item} /*form={form}*//>,
                width: 800,
                height: 750,
                minHeight: 750,
                y: -200,
                active: this.isSingleLocVarItem(item),
                status: 'OK'
            },
            {
                operation: 'ADD_SOUND',
                glyph: 'volume-up',
                component: <EN.EntryAddSound item={item} additionalData={additionalData}/>,
                width: 740,
                height: 360,
                minWidth: 740,
                minHeight: 360,
                active: this.isSingleLocVarItem(item) && this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'PROMOTE_LOC_VAR',
                component: <LV.LocVarPromoteToRootEntry item={item} /*form={form}*//>,
                glyph: 'circle-arrow-up',
                width: -1,
                height: 120,
                active: !isAlternativeForm && this.isSingleLang(item),
                status: 'OK'
            },
            {
                operation: 'CHANGE_TYPOLOGY',
                component: <EN.EntryChangeTypology item={item}/>,
                glyph: 'certificate',
                width: 500,
                minWidth: 500,
                height: 160,
                minHeight: 160,
                active: this.checkChangeTypology(item),
                status: 'OK'
            },
            {
                operation: 'SHOW_HIERARCHY',
                glyph: 'resize-full',
                component: <LV.LocVarHierarchy item={item} /*form={form}*//>,
                //width: -1,
                minHeight: 400,
                minWidth: 200,
                height: 600,
                width: 800,
                y: -200,
                active: this.isSingleLang(item),
                status: 'OK'
            },
            {
                operation: 'EDIT_FORM',
                glyph: 'edit',
                component: <LV.LocVarEditForm item={item} /*form={form}*//>,
                width: 500,
                height: 150,
                minWidth: 450,
                minHeight: 100,
                active: isSingleLocVarItem, //this.isTreeModel(additionalData) && !this.isSingleLang(item),
                status: 'OK'
            },
            {
                operation: 'ADD_LOC_VAR_FOR_THIS_FORM',
                glyph: 'plus-sign',
                component: <LV.LocVarAdd item={item} /*form={form}*//>,
                minHeight: 400,
                minWidth: 200,
                height: 600,
                width: 800,
                y: -200,
                active: isSingleLocVarItem,
                status: 'OK'
            },
            {
                operation: 'ADD_ALTERNATIVE_FORM',
                glyph: 'plus',
                component: <EN.EntryAddAltForm item={item}/>,
                width: 600,
                minWidth: 350,
                height: 175,
                minHeight: 175,
                active: this.isGroupLang(item),
                status: 'OK'
            },
            {
                operation: 'INFO',
                glyph: 'info-sign',
                component: <LV.LocVarPage item={item} full={true}/>,
                width: 600,
                height: -1,
                minHeight: 90,
                active: this.isSingleLang(item),
                status: 'OK'
            }
        ]
    },

    subentries: function (item, context, additionalData) {
        const isPoly = this.isPoly(additionalData);

        return [
            {
                operation: 'DELETE',
                glyph: 'trash',
                component: <SU.SubDelete item={item} additionalData={additionalData}/>,
                width: 500,
                minWidth: 500,
                height: 125,
                minHeight: 110,
                x: isPoly ? -680 : 215,
                y: isPoly ? 15 : -17,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'EDIT',
                glyph: 'edit',
                component: <SU.SubEdit item={item} additionalData={additionalData}/>,
                width: 500,
                minWidth: 500,
                height: 450,
                minHeight: 450,
                x: isPoly ? -680 : 215,
                y: isPoly ? 15 : -17,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'FORMS',
                glyph: 'th',
                component: <SU.SubForms item={item} additionalData={additionalData}/>,
                width: 1300,
                minWidth: 500,
                height: 650,
                minHeight: 650,
                x: isPoly ? -680 : -19,
                y: isPoly ? 15 : -114,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'SORT_SENSES',
                glyph: 'sort',
                component: <SU.SubSenseSort item={item} context={context} additionalData={additionalData}/>,
                width: isPoly ? 550 : 650,
                height: 500,
                minWidth: isPoly ? 550 : 650,
                minHeight: 500,
                x: isPoly ? -680 : 215,
                y: isPoly ? 15 : -17,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_SENSE',
                glyph: 'plus-sign',
                component: <SE.SenseAdd item={item} additionalData={{isNew: true}} context={context}/>,
                minHeight: this.isTreeModel(additionalData) && context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR ? 750 : 200,
                height: this.isTreeModel(additionalData) && context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR ? 750 : 200,
                minWidth: 800,
                width: 800,
                /*y: this.isTreeModel(additionalData) ? -320 : 0,*/
                x: isPoly ? -680 : 215,
                y: isPoly ? 15 : -17,
                active: true,
                status: 'OK'
            }
        ]
    },

    senses: function (item, context, additionalData) {
        const isPoly = this.isPoly(additionalData);
        additionalData.languageId = context.languageId;
        var foundSortableTr = false;
        for (var key in item.translations) {
            if (Object.keys(item.translations[key]).length > 1) {
                foundSortableTr = true;
                break;
            }
        }

        var topics = Dict.getVarious('topics');
        const hideSort = Object.keys(item.phraseologies).length === 1 && !foundSortableTr;

        return [
            {
                operation: 'DELETE',
                glyph: 'trash',
                component: <SE.SenseDelete item={item}/>,
                width: 650,
                minWidth: 650,
                height: 125,
                minHeight: 110,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'SENSE_LOC_VAR_ADD',
                glyph: 'plus-sign',
                component: <SE.SenseAdd item={item}/>,
                minHeight: 400,
                minWidth: 200,
                height: 750/*450*/,
                width: 800,
                y: -320/*-200*/,
                active: this.isActiveByContext(context) && this.isTreeModel(additionalData),
                status: 'OK'
            },
            {
                operation: 'SENSE_LOC_VAR_DELETE',
                glyph: 'minus-sign',
                component: <SE.SenseRemove item={item}/>,
                width: 450,
                minWidth: 450,
                height: 325,
                minHeight: 325,
                active: this.isActiveByContext(context) && this.isTreeModel(additionalData),
                status: 'OK'
            },
            {
                operation: 'SPREAD_TO_CHILDREN',
                glyph: 'circle-arrow-down',
                component: <SE.SenseSpread item={item}/>,
                minHeight: 300,
                minWidth: 850,
                height: 300,
                width: 850,
                y: -200,
                active: this.isActiveByContext(context) && this.isTreeModel(additionalData) && !this.isSingleLang(item),
                status: 'OK'
            },
            {
                operation: 'PROMOTE',
                glyph: 'circle-arrow-up',
                component: <SE.SensePromote item={item}/>,
                minHeight: 400,
                minWidth: 200,
                height: 600,
                width: 800,
                y: -200,
                active: this.isActiveByContext(context) && this.isTreeModel(additionalData) && !this.isSingleLang(item),
                status: 'OK'
            },
            {
                operation: 'SORT',
                glyph: 'sort',
                component: <SE.SenseSort item={item} additionalData={additionalData}/>,
                width: isPoly ? 535 : 835,
                height: isPoly ? 430 : 570,
                minWidth: isPoly ? 535 : 835,
                minHeight: isPoly ? 440 : 570,
                x: isPoly ? -600 : 660,
                y: isPoly ? -15 : -100,
                active: this.isActiveByContext(context) && !hideSort,
                status: 'OK'
            },
            {
                operation: 'NOTES',
                glyph: 'file',
                component: <SE.SenseNotes item={item}/>,
                width: 500,
                minWidth: 500,
                height: this.isTreeModel(item) ? 500 : 200,
                minHeight: 200,
                x: 660,
                y: -200,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'USAGES',
                glyph: 'wrench',
                component: <SE.SenseUsages item={item}/>,
                width: 800,
                minWidth: 800,
                height: 200,
                minHeight: 200,
                x: 660,
                y: -200,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'LINKS',
                glyph: 'resize-horizontal',
                component: <SE.SenseLinks item={item}/>,
                width: 900,
                minWidth: 900,
                height: 275,
                minHeight: 275,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'DATING',
                glyph: 'calendar',
                component: <SE.SenseDating item={item}/>,
                width: 500,
                minWidth: 500,
                height: 175,
                minHeight: 175,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_PHRASE',
                glyph: 'th-list',
                component: <SE.SenseEditAddPhrase item={item} context={context}/>,
                width: 680,
                minWidth: 680,
                height: this.retrieveAddPhraseHeight(),
                minHeight: this.retrieveAddPhraseHeight(),
                x: 450,
                y: -200,
                active: true,
                status: 'OK'
            },
            {
                operation: 'ADD_IMAGE',
                glyph: 'picture',
                component: <SE.SenseAddImage item={item}/>,
                width: 1024,
                minWidth: 1024,
                height: 660,
                minHeight: 660,
                y: -300,
                x: 250,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'TOPICS',
                glyph: 'comment',
                component: <SE.SenseTopics item={item}/>,
                width: 500,
                minWidth: 500,
                height: 180,
                minHeight: 180,
                y: -100,
                x: 550,
                active: topics.topics.length > 0 && this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'MOVE',
                glyph: 'copy',
                component: <SE.SenseMove item={item} mantain={false}/>,
                width: 850,
                minWidth: 650,
                height: 650,
                minHeight: 300,
                y: -250,
                x: 550,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'COPY',
                glyph: 'duplicate',
                component: <SE.SenseMove item={item} mantain={true}/>,
                width: 850,
                minWidth: 650,
                height: 650,
                minHeight: 300,
                y: -250,
                x: 550,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_TRANSLATION',
                glyph: 'globe',
                component: <SE.SemanticUnitAddTranslation item={item} context={context}/>,
                width: 1080,
                minWidth: 900,
                height: 650,
                minHeight: 300,
                y: -450,
                x: 450,
                active: /*this.isDirect() &&*/ this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'SHOW_HIDE',
                glyph: 'eye-open',
                component: <SE.SenseShowHide item={item}/>,
                width: 570,
                minWidth: 570,
                height: 140,
                minHeight: 140,
                active: this.isActiveByContext(context),
                status: 'OK',
            }
        ]
    },

    semantic_unit_references: function (item, additionalData) {
        return [
            {
                operation: 'DELETE',
                glyph: 'trash',
                component: <SE.SenseDeleteLink item={item} additionalData={additionalData}/>,
                width: 550,
                minWidth: 550,
                height: 125,
                minHeight: 110,
                active: additionalData.way === 'direct',
                status: 'OK'
            },
            /*{
             operation: 'SHOW_HIDE',
             glyph: 'eye-open',
             component: <SE.SenseHideLink item={item}/>,
             width: 500,
             minWidth: 500,
             height: 250,
             minHeight: 110,
             active: true
             },*/
            {
                operation: 'OPEN_TAB',
                glyph: 'open',
                command: 'OPEN_TAB',
                width: 500,
                minWidth: 500,
                height: 250,
                minHeight: 110,
                active: true
            }
        ]
    },

    translations: function (item, additionalData) {
        return [
            {
                operation: 'DELETE',
                glyph: 'trash',
                component: <TR.TranslationDelete item={item} additionalData={additionalData}/>,
                width: 600,
                minWidth: 600,
                height: 130,
                minHeight: 130,
                active: true,
                status: 'OK'
            },
            {
                operation: 'EXTENDED_VIEW',
                glyph: 'zoom-in',
                component: <TR.TranslationExtendedView item={item}/>,
                width: 800,
                minWidth: 800,
                height: 200,
                minHeight: 200,
                active: true,
                status: 'OK'
            },
            {
                operation: 'NOTES',
                glyph: 'file',
                component: <TR.TranslationNotes item={item} additionalData={additionalData}/>,
                width: 500,
                minWidth: 500,
                height: 180,
                minHeight: 180,
                active: true,
                status: 'OK'
            },
            {
                operation: 'FORCE_DEF_ABS',
                glyph: 'text-width',
                component: <TR.TranslationForceDefAbs item={item} additionalData={additionalData}/>,
                width: 630,
                minWidth: 630,
                height: 130,
                minHeight: 130,
                active: true,
                status: 'OK'
            },
            {
                operation: 'SHOW_HIDE',
                glyph: 'eye-open',
                component: <TR.TranslationShowHide item={item} additionalData={additionalData}/>,
                width: 630,
                minWidth: 630,
                height: 130,
                minHeight: 130,
                active: true,
                status: 'OK'
            },
            {
                operation: 'OPEN_TAB',
                glyph: 'open',
                /*component: <TR.TranslationShowHide item={item} additionalData={additionalData}/>,*/
                command: 'OPEN_TAB',
                width: 630,
                minWidth: 630,
                height: 130,
                minHeight: 130,
                active: true, /*additionalData.isSense,*/
                status: 'OK'
            }
        ]
    },

    phrases: function (item, context, additionalData) {
        additionalData.soundType = SoundableEntities.PHRASEOLOGY;

        return [
            {
                operation: 'DELETE',
                glyph: 'trash',
                component: <PH.PhraseDelete item={item} additionalData={additionalData} isDetach={false}/>,
                width: 500,
                minWidth: 500,
                height: 130,
                minHeight: 130,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'EDIT',
                glyph: 'edit',
                component: <SE.SenseEditAddPhrase item={item} context={context} additionalData={additionalData}/>,
                width: 680,
                minWidth: 680,
                height: this.retrieveAddPhraseHeight(),
                minHeight: this.retrieveAddPhraseHeight(),
                y: -250,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'NOTES',
                glyph: 'file',
                component: <PH.PhraseNotes item={item} additionalData={additionalData}/>,
                width: 500,
                minWidth: 500,
                height: 190,
                minHeight: 190,
                x: 600,
                y: -250,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_SOUND',
                glyph: 'volume-up',
                component: <EN.EntryAddSound item={item} additionalData={additionalData}/>,
                width: 740,
                height: 360,
                minWidth: 740,
                minHeight: 360,
                y: -180,
                x: 380,
                active: this.isActiveByContext(context),
                status: 'NOT_FINISHED'
            },
            {
                operation: 'MOVE_PHRASE',
                glyph: 'export',
                component: <PH.PhraseAssign item={item} additionalData={additionalData} isMove={true}/>,
                width: 900,
                minWidth: 900,
                height: 600,
                minHeight: 600,
                x: 400,
                y: -280,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ASSIGN_PHRASE',
                glyph: 'resize-small',
                component: <PH.PhraseAssign item={item} additionalData={additionalData} isMove={false}/>,
                width: 900,
                minWidth: 900,
                height: 600,
                minHeight: 600,
                x: 400,
                y: -280,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'DETACH_PHRASE',
                glyph: 'resize-full',
                component: <PH.PhraseDelete item={item} additionalData={additionalData} isDetach={true}/>,
                width: 900,
                minWidth: 900,
                height: 130,
                minHeight: 130,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'TRANSFORM_IN_POLY',
                glyph: 'retweet',
                component: <PH.PhraseTransformInPoly item={item} additionalData={additionalData}/>,
                width: 900,
                minWidth: 900,
                height: 130,
                minHeight: 130,
                active: this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'ADD_TRANSLATION',
                glyph: 'globe',
                component: <SE.SemanticUnitAddTranslation item={item} additionalData={additionalData}
                                                          context={context}/>,
                width: 900,
                minWidth: 900,
                height: 700,
                minHeight: 700,
                y: -250,
                active: /*this.isDirect() && */ this.isActiveByContext(context),
                status: 'OK'
            },
            {
                operation: 'SHOW_HIDE',
                glyph: 'eye-open',
                component: <PH.PhraseShowHide item={item} additionalData={additionalData}/>,
                width: 570,
                minWidth: 570,
                height: 140,
                minHeight: 140,
                active: this.isActiveByContext(context),
                status: 'OK',
            }
        ]
    },

    references: function (item, context, additionalData) {
        return [
            {
                operation: 'DELETE',
                glyph: 'trash',
                component: <RE.ReferenceDelete item={item} additionalData={additionalData}/>,
                width: 600,
                minWidth: 600,
                height: 130,
                minHeight: 130,
                active: true,
                status: 'OK'
            },
            {
                operation: 'OPEN_TAB',
                glyph: 'open',
                command: 'OPEN_TAB',
                width: 500,
                minWidth: 500,
                height: 250,
                minHeight: 110,
                active: true
            }
        ]
    },

    translatable: function (component) {

        return {
            operation: 'TRANSLATE',
            glyph: 'resize-small',
            startItem: component.props.context.startItem,
            arriveIds: component.props.item.ids,
            additionalOnClickScript: "",
            width: 600,
            minWidth: 600,
            height: 130,
            minHeight: 130,
            active: true,
            status: 'OK'
        }
    }
};

export function translate(start, arriveIds) {
    if (typeof arriveIds !== 'undefined') {
        LexemeUtilities.resetTranslationSelectorPopovers();
        LexemeUtilities.showModalSpinner(Dict.t('SPINNER_MSG_TRANSLATING'), "modal-spinner-rebuild");

        var startId = typeof JSON.parse(start).discriminator === "undefined" || JSON.parse(start).discriminator === 'SENSE' ? 'ids' : 'id';

        addTranslation(JSON.parse(start)[startId], arriveIds, getLexemeProperties("root_entry_id")).then(result => {
            LexemeUtilities.closeModalSpinner();
            LexemeUtilities.resetTranslationSelectorPopovers();
            if (result.result === 'OK') {
                const trIdComponent = "translation-id-" + JSON.parse(result.additionalInfos).trId;
                LexemeUtilities.showHighlight(trIdComponent, 6000, 500);
                LexemeUtilities.showToastNotification(result.message, 10000, 1500);
            } else
                LexemeUtilities.showErrorModalMessage(result.message, 5000);
        });
    } else {
        LexemeUtilities.showErrorModalMessage("ignore:" + Dict.t('SEMANTIC_UNIT_TO_TRANSLATE_NOT_FOUND'), 5000);
    }
}