var Dict = require('dict-lib');

module.exports = {

    joinClasses: function (...classes) {
        return classes.join(' ');
    },

    trimmedSplit: function (string, separator) {
        return string.split(separator).map(item => {
            return item.trim()
        });
    },


    // DA CENTRALIZZARE

    isImage: function (filepath) {
        const extensions = ['jpg', 'png', 'jpeg', 'gif', 'bmp'];
        return extensions.some((extension) => {
            return typeof filepath !== 'undefined' && filepath.toLowerCase().endsWith('.' + extension.toLowerCase());
        });
    },

    isSound: function (filepath) {
        const extensions = ['mp3', 'wav', 'ogg'];
        return extensions.some((extension) => {
            return typeof filepath !== 'undefined' && filepath.endsWith('.' + extension.toLowerCase());
        });
    },

    isVideo: function (filepath) {
        const extensions = ['avi', 'mpeg', 'mkv', 'mpg', 'mp4'];
        return extensions.some((extension) => {
            return typeof filepath !== 'undefined' && filepath.endsWith('.' + extension.toLowerCase());
        });
    },

    isHtml: function (filepath) {
        const extensions = ['html', 'htm'];
        return extensions.some((extension) => {
            return typeof filepath !== 'undefined' && filepath.endsWith('.' + extension.toLowerCase());
        });
    },

    isZip: function (filepath) {
        const extensions = ['zip'];
        return extensions.some((extension) => {
            return typeof filepath !== 'undefined' && filepath.toLowerCase().endsWith('.' + extension.toLowerCase());
        });
    },

    isDocument: function (filepath) {
        const extensions = ['pdf', 'doc', 'txt', 'rtf', 'odt'];
        return extensions.some((extension) => {
            return typeof filepath !== 'undefined' && filepath.endsWith('.' + extension.toLowerCase());
        });
    },

    isValidURL: function (str) {
        let url;

        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    },

    getFileType: function (filename) {
        if (this.isValidURL(filename))
            return 'url';
        else if (this.isImage(filename))
            return 'image';
        else if (this.isSound(filename))
            return 'audio';
        else if (this.isVideo(filename))
            return 'video';
        else if (this.isDocument(filename))
            return 'doc';
        else
            throw filename + ": " + Dict.t('DROPPED_FILE_TYPE_NOT_FOUND');
    },

    isFileByStringPath: function (filepath, isUrl) {
        var separator = isUrl ? '/' : '\\';
        var lastIndex = filepath.substring(filepath.lastIndexOf(separator) + 1);
        return lastIndex.split('.').length > 1;
    },

    isUrl: function (str) {
        return typeof str !== 'undefined' && str.indexOf('/') > 0;
    },

    extractFilename: function (filepath) {
        var myGlobals = {isWin: false, isOsX: false, isNix: false};
        var appVer = navigator.appVersion;
        var fileSep = '/';

        if (appVer.indexOf("Win") !== -1) myGlobals.isWin = true;
        else if (appVer.indexOf("Mac") !== -1) myGlobals.isOsX = true;
        else if (appVer.indexOf("X11") !== -1) myGlobals.isNix = true;
        else if (appVer.indexOf("Linux") !== -1) myGlobals.isNix = true;


        if (myGlobals.isWin) {
            fileSep = '\\';
        }

        return typeof filepath === 'undefined' ? '' : filepath.split(fileSep)[filepath.split(fileSep).length - 1];
    },

    extractFileExtension: function (filename) {
        return filename.substring(filename.lastIndexOf('.') + 1, filename.length); // || file.filename
    },

    extractFilenameNoExtension: function (filename) {
        var extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length); // || file.filename
        return filename.substring(0, filename.length - extension.length - 1);
    },

    isLogged: function () {

    },

    deCamel: function (text, separator) {
        return text.replace(/([A-Z])/g, separator + '$1');
    },

    cloneJson: function (jsonObject) {
        return JSON.parse(JSON.stringify(jsonObject));
    },

    getServerUrl() {
        return Dict.getApiHost();
    },

    removeFromArray(item, collection) {
        return collection.splice(collection.indexOf(item), 1);
    },

    sortMultimediaFiles(multimediaFiles) {
        var img = [];
        var audio = [];
        var video = [];
        var url = [];

        multimediaFiles.forEach((itemMultimedia) => {
            if (this.isValidURL(itemMultimedia.fileUrl)) {
                itemMultimedia.type = 'url';
                url.push(itemMultimedia);
            } else if (this.isImage(itemMultimedia.fileUrl)) {
                itemMultimedia.type = 'img';
                img.push(itemMultimedia);
            } else if (this.isSound(itemMultimedia.fileUrl)) {
                itemMultimedia.type = 'audio';
                audio.push(itemMultimedia);
            } else if (this.isVideo(itemMultimedia.fileUrl)) {
                itemMultimedia.type = 'video';
                video.push(itemMultimedia);
            }
        });

        return img.concat(audio).concat(video).concat(url);
    },

    convertHeadedCsvToJson(file, callback) {
        this.convertCsvToJson(file, '', callback)
    },

    // se l'heading è incluso dentro il csv, altriment devono essere specificati i campi
    convertCsvToJson(file, heading, callback) {
        var hasHeading = heading.length === 0;
        var csvSeparator = '\t';
        var reader = new FileReader();
        reader.onload = function (event) {
            var lines = event.target.result.split("\n");

            var result = [];

            var headers = hasHeading ? lines[0].split(csvSeparator) : heading;
            var cleanHeaders = [];

            for (var k = headers.length - 1; k >= 0; k--) {
                if (headers[k].length > 0 && headers[k] !== '\r') {
                    var cleanHeadersTmp = headers.slice(0, k + 1);
                    cleanHeadersTmp.forEach(header => {
                        if (header.trim().length > 0) {
                            cleanHeaders.push(header.replace('\r', ''));
                        } else {
                            cleanHeaders.push('[' + Dict.t('EMPTY_FIELD').toUpperCase() + ']');
                        }
                    });
                    break;
                }
            }

            const start = hasHeading ? 1 : 0;
            for (var i = start; i < lines.length; i++) {
                if (lines[i].trim().length > 0) {
                    var obj = {};
                    var currentline = lines[i].split(csvSeparator);

                    for (var j = 0; j < cleanHeaders.length; j++) {
                        obj[cleanHeaders[j]] = currentline[j].replace('\r', '');
                    }

                    result.push(obj);
                }
            }

            callback(result);
        };
        reader.readAsText(file, 'utf-8');
    },

    compareJSon(json1, json2) {
        return JSON.stringify(json1) === JSON.stringify(json2);
    },

    highlight(value, text) {
            var index = text.indexOf(value);
            if (index >= 0) {
                return text.substring(0, index) + "<span class='highlight' style='background-color: yellow'>" + text.substring(index, index + value.length) + "</span>" + text.substring(index + value.length);
            } else
                return text;

    }

};