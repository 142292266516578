import React from "react";
import Rnd from 'react-rnd';
import Dict from 'dict-lib';
import {Button, Glyphicon, Grid, Row, Col} from 'react-bootstrap';
import {LexemeUtilities, LexemeEditingLevel, getContextTypes} from './../../lexeme/page/editor/LexemeHelper';

import stylesSCPopup from './SCPopup.module.css';

export class SCPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        /*this._onDragStop = this._onDragStop.bind(this);*/
        this._handleClose = this._handleClose.bind(this);
        this._handleCloseEditorPopup = this._handleCloseEditorPopup.bind(this);
    }

    /*_onDragStop(e, data) {
     this.props.callbackParent({ x : data.x, y : data.y});
     }*/

    _handleClose() {
        this.props.callbackParent();
    }

    // questo dovrebbe essere portato al parent. SCPopup dovrebbe essere disponibile anche per altre app
    _handleCloseEditorPopup() {
        if (this.props.context.pageStatus !== LexemeEditingLevel.TRANSLATION_SELECTOR) {
            const trActive = document.getElementsByClassName("lexTranslatableActive");
            if (trActive.length > 0) {
                trActive[0].click();
            }
        }
    }

    render() {

        var additionalPopupClass = typeof this.props.additionalPopupClass === 'undefined' ? "" : this.props.additionalPopupClass;

        return <Rnd
            ref={c => {
                this.rnd = c;
            }}
            default={{
                x: Number(this.props.x),
                y: Number(this.props.y),
                width: Number(this.props.width),
                height: Number(this.props.height)
            }}
            dragHandlerClassName={'.' + stylesSCPopup['popupBar'] }
            minWidth={Number.isInteger(this.props.minWidth) ? Number(this.props.minWidth) : 600}
            minHeight={Number.isInteger(this.props.minHeight) ? Number(this.props.minHeight) : 300}
            onDragStart={this._handleCloseEditorPopup}
            /*bounds="#lexeme-page-container"*/
            /*onDragStop={this._onDragStop}*/ >
            <div className={stylesSCPopup['box']} >
                <div className={stylesSCPopup['popupBar']}>
                    <div dangerouslySetInnerHTML={{__html: Dict.t(this.props.operation.operation)}} />
                    <div><Button className={stylesSCPopup['bt-popup-close']} onClick={this._handleClose}><Glyphicon glyph="remove" /></Button></div>
                </div>
                <div className={stylesSCPopup['popupContents'] + " " + additionalPopupClass}>{this.props.component}</div>
            </div>
        </Rnd>
    }
}