import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {Button, ControlLabel, FormControl, FormGroup, Grid, InputGroup, Label} from 'react-bootstrap';
import {TreeNode} from 'draggable-react-tree-component';
import {Typeahead} from "react-bootstrap-typeahead";
import BUtils from './../../../utilities/BaseUtilities';
import {MorphoPlayground} from "./MorphoPlayground";
import stylesMorphoRule from "../../../_css/dictionary/lexemeParts/lexemeMorphoRule.module.css";


export class MorphoRuleEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemData: '',
            originalItemData: '',
            openPlayground: false,
            openTest: true,
            testRule: '',
            testText: '',
            testResult: {}
        };
    }

    componentWillMount() {
        this._retrieveRule(this.props.item.key);
    }

    _retrieveRule(key) {
        Dict.call('/declinedForms/getMorphoRule/' + key + '/').then((itemData) => {
            this.setState({itemData, originalItemData: BUtils.cloneJson(itemData)});
        });
    }

    _openPlayground() {
        const container = document.getElementById('morpho-playground-container');
        const openPlayground = !this.state.openPlayground;
        if (!openPlayground) {
            ReactDOM.unmountComponentAtNode(container);
            this.setState({openPlayground});
        } else {
            ReactDOM.render(<MorphoPlayground
                callbackParent={(method) => this._handleChooseMethod(method)}/>, container, () => {
                this.setState({openPlayground});
            });
        }
    }

    _handleChooseMethod(method) {
        var value = this.state.openTest ? this.state.testRule : this.state.itemData.phoneticContext;
        if (value.trim() === '' || value.trim() === 'BSH:true' || method === 'BSH:true') {
            value = method;
        } else {
            value += " || " + method;
        }

        if (this.state.openTest) {
            this.setState({testRule: value});
        } else {
            var itemData = this.state.itemData;
            item.phoneticContext = value;
            this.setState({itemData})
        }
    }


    render() {

        const itemData = this.state.itemData;

        var selected = [];

        if (typeof itemData !== 'undefined' && itemData !== '') {
            selected = itemData.morphoClass === null || itemData.morphoClass.length === 0 ? [] : itemData.morphoClass;
        }

        return typeof itemData !== 'undefined' && itemData !== '' ?
            <Grid key={'key-morphorule-edit-' + this.props.item.key}>
                <h3><Label>{this.state.originalItemData.uniqueDescription}</Label></h3>
                <InputGroup>
                    <InputGroup.Addon>{Dict.t('UNIQUE_DESCRIPTION')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('ENTRY') + "..."}
                                 value={itemData.uniqueDescription}
                                 onChange={(evt) => {
                                     var itemData = this.state.itemData;
                                     itemData.uniqueDescription = evt.target.value;
                                     this.setState({itemData});
                                 }}
                                 onKeyUp={(evt) => {
                                 }}/>
                </InputGroup>
                <FormGroup>
                    <ControlLabel>{Dict.t('PHONETIC_CONTEXT')}</ControlLabel>
                    <FormControl componentClass="textarea" placeholder="" value={itemData.phoneticContext}
                                 onChange={(evt) => {
                                     var itemData = this.state.itemData;
                                     itemData.phoneticContext = evt.target.value;
                                     this.setState({itemData});
                                 }}
                                 onKeyUp={(evt) => {
                                 }}/>
                </FormGroup>
                <Button onClick={() => this._openPlayground()}
                >{Dict.t('OPEN_PLAYGROUND')}</Button>
                <Button onClick={() => this._handleChooseMethod('BSH:true')}>{Dict.t('SET_BSH_TRUE')}</Button>
                <Button onClick={() => this._handleChooseMethod('BSH:true')}>Open Test</Button>
                <div style={{border: '2px solid #dcdcdc', padding: '4px', margin: '4px', borderRadius: '3px'}}>
                    <InputGroup>
                        <InputGroup.Addon>{'Test text'}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('ENTRY') + "..."}
                                     value={this.state.testText}
                                     onChange={(evt) => {
                                         const testText = evt.target.value;
                                         this.setState({testText});
                                     }}
                                     onKeyUp={(evt) => {
                                     }}/>
                    </InputGroup>
                    <FormGroup>
                        <ControlLabel>{'Rule'}</ControlLabel>
                        <FormControl componentClass="textarea" placeholder="" value={this.state.testRule}
                                     onChange={(evt) => {
                                         const testRule = evt.target.value;
                                         this.setState({testRule});
                                     }}
                                     onKeyUp={(evt) => {
                                     }}/>
                    </FormGroup>
                    <Button>update and close</Button>
                    <Button onClick={() => {
                        Dict.call('/declinedForms/testMorphoRule/' + this.props.languageId + '/' + this.state.testText + '/' + this.state.testRule + '/null/').then((result) => {
                            var testResult = {};
                            if (result.error !== '') {
                                testResult.error = true;
                            } else {
                                testResult.error = false;
                                testResult.value = result.value;
                            }

                            this.setState({testResult});
                        })
                    }
                    }>test</Button>
                    <div>{ Object.keys(this.state.testResult).length === 0 ? '' : 'result: ' + this.state.testText + ' >>>>> ' + this.state.testResult.value } </div>
                </div>
                <Typeahead
                    id={'morpho-class-combo'}
                    key={'key-morpho-class-combo'}
                    clearButton
                    autoFocus={false /*true*/}
                    ref={"revisionStatusTypeahead"}
                    align={"justify"}
                    onChange={item => {
                        var itemData = this.state.itemData;
                        itemData.morphoClass = item;

                        this.setState({itemData});
                    }}
                    options={itemData.morphoComboClasses}
                    selected={selected}
                    placeholder={Dict.t('NO_CHOICE')}
                />
                <div id={'morpho-playground-container'}
                     className={BUtils.joinClasses(stylesMorphoRule['morpho-playground-container'], stylesMorphoRule['morpho-playground-container--' + this.state.openPlayground])}/>
            </Grid> : '';
    }
}