import React from 'react';
import Dict from 'dict-lib';
import {setSenses, setPolies} from './../../LexemePage';
import {LexemeUtilities} from './../LexemeHelper';

const updateSenses = ['SET_TRANSLATION'];
var updateClasses = ['subentries'];

export const deleteTranslations = (item, rootEntryId) => {
    item = LexemeUtilities.encodeUrl(item);
    return LexemeUtilities.callResetTabPages('/translations/deleteTranslations/' + item + '/' + rootEntryId + '/' + updateSenses);
};

export const saveTranslationNotes = (item, rootEntryId) => {
    item = LexemeUtilities.encodeUrl(item);
    return LexemeUtilities.call('/translations/saveNotes/' + item + '/' + rootEntryId + '/' + updateSenses);
};

export const showHideTranslation = (item, rootEntryId) => {
    item = LexemeUtilities.encodeUrl(item);
    return LexemeUtilities.call('/translations/showHide/' + item + '/' + rootEntryId + '/' + updateSenses);
};

export const forceDefAbs = (item, rootEntryId) => {
    item = LexemeUtilities.encodeUrl(item);
    return LexemeUtilities.call('/translations/forceDefAbs/' + item + '/' + rootEntryId + '/' + updateSenses);
};

export const setTranslationsOrder = (items, senseItems, senseItemIds, subentryIds, polies, isPoly) => {
    /*if (isPoly)
        setPolies(polies);
    else
        setSenses(senseItems, subentryIds);*/

    items = LexemeUtilities.encodeUrl(JSON.stringify(items));
    return LexemeUtilities.callNoClose('/translations/setTranslationsOrder/' + items + '/' + senseItemIds);
};

