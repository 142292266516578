import React from "react";
import ReactDOM from "react-dom";
import Dict from 'dict-lib';
import {PropertyType} from './tabs/PropertyType';
import {
    Tabs,
    Tab,
    Button,
    Collapse,
    Well,
    ButtonToolbar,
    ButtonGroup,
    Glyphicon,
    FormGroup
} from 'react-bootstrap';
import './Properties.css';
import {PropertyTypeItems} from "../AdministrationHelper";

export class Properties extends React.Component {
    constructor(props) {
        super(props);

        this.optionGroups = [
            {flag: 'base', name: 'BASE', isModule: false},
            {flag: 'various', name: 'VARIOUS', isModule: false},
            {flag: 'modules', name: 'MODULES', isModule: false},
        ];

        this.optionGroups = this.optionGroups.concat(PropertyTypeItems.RootModules.map((module) => {
            /*var code = Dict.t(module.code);
            var flag = code.charAt(0).toUpperCase() + code.slice(1);*/
            var flag = module.code;
            var name = module.code;
            var isModule = true;
            return {flag, name, isModule};
        }));

        this.state = ({
            key: typeof this.props.defaultActiveTab === 'undefined' ? 0 : this.optionGroups.findIndex((opt) => {
                return opt.flag === this.props.defaultActiveTab
            }),
        });

        this._handleSelect = this._handleSelect.bind(this);
    }

    _handleSelect(key) {
        this.setState({key});
    }


    render() {
        const tabs = this.optionGroups.map((item, index) =>
            <Tab key={'key-tab-properties-' + index} eventKey={index} title={Dict.t(item.name)}>
                <PropertyType key={'key-props-' + item.flag} tab={item.flag} isModule={item.isModule} />
            </Tab>);

        return (
            <Tabs key={'key-tab-properties'} activeKey={this.state.key} onSelect={this._handleSelect}
                  id="properties-tab">
                {tabs}
            </Tabs>
        );

    }
}

