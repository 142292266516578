import React from 'react';
import Dict from 'dict-lib';
import {LexemeUtilities} from './../LexemeHelper';
import {Grid, Row, Col, Button} from 'react-bootstrap';
import {LanguageTreeView, LanguageTypeahead} from './../../../../dict-components/components';
import {addNewLocVar} from './LocVarActions';
import {getLexemeProperties, getLexemeTreeView} from './../../LexemePage';
import './LocVars.css';


export class LocVarAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: "",
            treeData: [],
            searchId: "", //"3", //"8",
            searchAncestors: "", //"2", //"2^3^4^5^6",
            rootEntryId: getLexemeProperties("root_entry_id"),
            openLevel: -1,
            addList: [],
            asComponent: typeof this.props.asComponent === 'undefined' ? false : this.props.asComponent
        };


        this._handleAddLocVar = this._handleAddLocVar.bind(this);
    }

    componentWillMount() {
        var treeData = this.props.treeData;
        var form = this.state.asComponent ? this.props.form : this.props.item.form;
        var service = "";

        if (treeData.length === 0) {
            service = 'page/getLexemeVarLocTreeView/';
        } else if (treeData[0] === "") {
            service = 'page/rebuildLexemeVarLocTreeView/';
        }

        if (service.length > 0) {
            Dict.call(service + this.state.rootEntryId).then(result => {
                var treeData = [];
                treeData.push(result);
                this.setState({treeData});
            });
        } else {
            this.setState({treeData});
        }

        this.setState({treeData, form});

        /*if (treeData.length == 0) {
            Dict.call('service/getLanguageTreeViewbyLexeme/' + this.state.rootEntryId).then(result => {
                var treeData = [];
                treeData.push(result);
                //this.setState({treeData});
            });
        } else {
            //this.setState({treeData});
        }*/

        //this.setState({treeData, form});
    }

    componentWillReceiveProps(nextProps) {
        this.setState({form: nextProps.form})
    }

    _handleSearchLanguage(newState) {
        this.setState({openLevel: newState});
    }

    _handleAddLanguage(node) {
        var addList = this.state.addList;

        const newItem = {
            form: this.state.form,
            id_lang: node.id,
            language: node.title,
            type: (node.subtitle.length > 0 ? "alternative" : "main")
        };

        const listItems = addList.filter(function (itemList) {
            return itemList.language === newItem.language;
        });

        if (listItems.length > 0) {
            addList.splice(addList.indexOf(listItems[0]), 1);
        } else {
            addList.push(newItem);
        }


        this.setState({addList: addList});
    }


    _handleAddLocVar() {
        if (this.state.addList.length > 0) {
            addNewLocVar(this.state.rootEntryId, this.state.addList);
        } else {
            LexemeUtilities.showModalMessage("warning", "WARNING_CHOOSE_LANGUAGE", 2000);
        }
    }


    render() {

        /*console.log(this.state);
         console.log(this.props);*/


        return <Grid id={"loc-var-add-container-flex"} style={{height: '100%'}} fluid>
            <Row id={"loc-var-add-typehead"}>
                <Col xs={7} md={7}>&nbsp;</Col>
                <Col xs={5} md={5} style={{paddingRight: 0}}>
                    <LanguageTypeahead ref="typehead"
                                       callbackParent={(newState) => this._handleSearchLanguage(newState)}/>
                </Col>
            </Row>
            <Row
                className={"row-add-loc-var-tree " + (this.state.treeData.length > 0 ? "add-loc-var-tree-loaded" : "")}>
                {this.state.treeData.length > 0 ?
                    <LanguageTreeView searchId={this.state.searchId} searchAncestors={this.state.searchAncestors}
                                      rootEntryId={this.state.rootEntryId} openLevel={this.state.openLevel}
                                      treeData={this.state.treeData} form={this.state.form} height={"100%"}
                                      callbackParent={(newState) => this._handleAddLanguage(newState)}/> : ""}
            </Row>
            <Row key={"key-add-loc-var"}
                 className={"row-add-loc-var-list " + (this.state.addList.length > 0 ? "add-loc-var-list-selected" : "")}>
                {this.state.addList.length > 0 ? (this.state.addList.map(item => <span
                    key={"key-add-loc-var-" + item.language}
                    className={"add-loc-var-item add-loc-var-" + item.type}>{item.language}</span>)) : ""}
            </Row>
            <Row className="row-add-loc-var-button">
                <Button bsStyle="primary" onClick={() => this._handleAddLocVar()}>{Dict.t('ADD')}</Button>
            </Row>
        </Grid>
    }

}