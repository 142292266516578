import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Dict from 'dict-lib';
import {LexemeLocVars} from './parts/locVars/LexemeLocVars';
import {SCSimpleSoundPlayer} from './../../../../dict-components/components';
//import {getLexemeOptions, getRootEntryId, loadAdvancedFields} from './../../LexemePage';
import {LexemeDisplayContext, LexemeEditingLevel, LexemePageInfos} from "../../editor/LexemeHelper";
import {Col, Row} from 'react-bootstrap';
import './LexemeData.css';
import './../../../../_css/lexeme.css';

import stylesLexemeData from '../../../../_css/dictionary/lexemeParts/lexemeData.module.css';
import BUtils from './../../../../utilities/BaseUtilities';
import {loadAdvancedFields} from "../../LexemePage";
import {LexemePageContext} from "../../LexemePageContext";
import {LexemeDictionaryType} from "../00_DictionaryType/LexemeDictionaryType";

export class LexemeData extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Lo monto via render perchè può essere smontato e aggiornato senza ricaricare la pagina (che chiuderebbe il popup)
        if (this.context.displayContext === LexemeDisplayContext.DICTIONARY_SEARCH && this.context.pageStatus === LexemeEditingLevel.EDITABLE)
            loadAdvancedFields(this.props.page.lexeme_data);
    }

    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
        //console.log('');
    }*/

    render() {
        var keyCounter = 1;

        const lexProps = this.props.page.lexeme_properties;
        const dictProps = this.props.page.dictionary_properties;
        const lexData = this.props.page.lexeme_data;

        var rootForm = "";
        if (typeof lexData.root_entry_form !== 'undefined' && lexData.root_entry_form.length > 0 && !lexProps.show_main_form_in_all_forms_of) {
            rootForm = <div className={stylesLexemeData['lexDataRootEntry']}>
                <div className={stylesLexemeData['lexDataLabel']}>{Dict.t('ROOT_ENTRY') + ":"}</div>
                <div
                    className={BUtils.joinClasses(stylesLexemeData['lexDataRootEntryForm'], stylesLexemeData[lexData.root_typology])}>{lexData.root_entry_form}</div>
            </div>;
        }

        var dating = "";

        if (typeof lexData.dating !== 'undefined' && lexData.dating.first_dating > 0) {
            dating = <div className={stylesLexemeData['lexDataDating']}>
                <div className={stylesLexemeData['lexDataDating__row']}>
                    <div className={stylesLexemeData['lexDataDating__label']}>{Dict.t('FIRST_DATING')}</div>
                    <div className={stylesLexemeData['lexDataDating__value']}>{lexData.dating.first_dating}</div>
                </div>
                <div className={stylesLexemeData['lexDataDating__row']}>
                    <div
                        className={stylesLexemeData['lexDataDating__label']}>{Dict.t('FORM_OF_FIRST_ATTESTATION_ABBR')}</div>
                    <div
                        className={stylesLexemeData['lexDataDating__value']}>{lexData.dating.form_of_first_attestation}</div>
                </div>
                <div className={stylesLexemeData['lexDataDating__row']}>
                    <div
                        className={stylesLexemeData['lexDataDating__label']}>{Dict.t('BIBLIO_REFERENCE_OF_FIRST_ATTESTATION_ABBR')}</div>
                    <div
                        className={stylesLexemeData['lexDataDating__value']}>{lexData.dating.bibliographic_reference_of_first_attestation}</div>
                </div>
            </div>;
        }

        var allFormsOf = "";
        if (typeof lexData.all_forms !== 'undefined' && lexData.all_forms.length > 0) {
            var allFormsOfList = lexData.all_forms.filter(function (item) {
                return !item.hide;
            }).map((item) =>
                <div key={"key-lexDataAllForm-" + keyCounter++}
                     className={stylesLexemeData['lexDataAllForm']}>{item.form}</div>);

            allFormsOf = <div className={stylesLexemeData['lexDataAllFormsOf']}>
                <div
                    className={stylesLexemeData['lexDataLabel']}>{Dict.t(dictProps.is_root ? 'GENERAL_VARIANTS' : 'VARIANTS') + ":"}</div>
                {allFormsOfList}
            </div>
        }

        var locVars = typeof lexData.lexeme_locVars !== 'undefined' ?
            <LexemeLocVars locVars={lexData.lexeme_locVars} context={this.context}/> : "";

        var lexDataMultimedia = [];
        var lexDataMultimediaContainer = "";
        if (typeof lexData.multimedia_locVars !== 'undefined' && Object.keys(lexData.multimedia_locVars).length > 0) {
            lexDataMultimedia = lexData.multimedia_locVars.map(item =>
                <div className={stylesLexemeData['lexDataMultimedia']} key={"key-multimedia-sound-" + item.id}>[
                    <div>{item.form}</div>
                    <div className={stylesLexemeData['lexDataMultimediaLang']}>({item.language})</div>
                    <SCSimpleSoundPlayer src={item.multimedia_file}/>]</div>
            );
            lexDataMultimediaContainer =
                <div className={stylesLexemeData['lexDataMultimediaContainer']}>{lexDataMultimedia}</div>;
        }

        const isDataEmpty = allFormsOf.length === 0 && locVars.length === 0;

        const additionalLexemeDataContainerClass = isDataEmpty ? "lexemeDataContainerEmpty" : "";

        return <div className={stylesLexemeData['lexemeData']}>
            {rootForm}
            <div id={"lexData-advanced-fields-" + lexProps.root_entry_id}
                 className={stylesLexemeData['lexData-advanced-fields']}/>
            {dating}
            <div
                className={BUtils.joinClasses(stylesLexemeData['lexemeDataContainer'], stylesLexemeData[additionalLexemeDataContainerClass])}>
                {allFormsOf}
                {locVars}
                {lexDataMultimediaContainer}
            </div>
        </div>;
    }
}

LexemeData.contextType = LexemePageContext;