import React from 'react';
import ReactDOM from 'react-dom';
import update from 'immutability-helper';
import {Grid, Row, FormGroup, InputGroup, FormControl, Glyphicon, Button} from 'react-bootstrap';
import Dict from 'dict-lib';
import {Ipa, SCSubmitButton} from '../../../../dict-components/components';
import tinymce from 'tinymce';
import 'tinymce/themes/modern';
import '../../../../dict-components/tinymce/plugin.min';
import {saveAdvancedField} from './../entry/EntryActions';
import {getRootEntryId, loadAdvancedFields} from './../../LexemePage';
import {LexemeUtilities} from './../LexemeHelper';
import './RootEntry.css';

export class AdvancedFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            item: {},
            editorEtim: null,
            editorNote: null,
            tinyRowClass: 'aftiny-init'
        };

        this.enableDebugMode = false;
        this.firstOpen = true;
        this.notes = "";

        this._handleIPA = this._handleIPA.bind(this);
        this._handleIPAByValue = this._handleIPAByValue.bind(this);
        this._handleSIPA = this._handleSIPA.bind(this);
        this._handleHyph = this._handleHyph.bind(this);
        this._handleTrans = this._handleTrans.bind(this);
        this._updateAdvancedFieldsState = this._updateAdvancedFieldsState.bind(this);
        this._updateAdvancedFieldsStateByValue = this._updateAdvancedFieldsStateByValue.bind(this);
        this._updatePhoneticFromChart = this._updatePhoneticFromChart.bind(this);
        this._saveAdvancedFields = this._saveAdvancedFields.bind(this);
    }

    componentWillMount() {
        Dict.call('/entry/getAdvancedFieldsData/' + this.state.id).then(result => {
            this.setState({item: result}, function () {
                tinymce.init({
                    selector: `#${'etimology'}`,
                    height: 130,
                    min_height: 130,
                    menubar: '',
                    plugins: 'link image code sccharmap',
                    toolbar: 'etymology | undo redo | bold italic | alignleft aligncenter alignright | code | sccharmap',
                    setup: editor => {
                        /*editor.addButton('etymology', {
                            text: Dict.t('ETYMOLOGY')
                        });*/
                        editor.addButton('etymology', {
                            text: Dict.t('ETYMOLOGY'),
                            type: 'label'
                        });
                        this.setState({editorEtim: editor});
                        editor.on('keyup change', () => {
                            var item = this.state.item;
                            item.etimology = editor.getContent();
                            this.setState({item});
                        });
                    }
                });

                tinymce.init({
                    selector: `#${'notes'}`,
                    height: 130,
                    min_height: 130,
                    menubar: '',
                    plugins: 'link image code sccharmap',
                    toolbar: 'notes | undo redo | bold italic | alignleft aligncenter alignright | code | sccharmap',
                    setup: editor => {
                        editor.addButton('notes', {
                            text: Dict.t('NOTES'),
                            type: 'label'
                        });
                        /*editor.addButton('notes', {
                            text: Dict.t('NOTES'),
                            type: 'label'
                        });*/
                        this.setState({editorNote: editor});
                        editor.on('keyup change', () => {
                            var item = this.state.item;
                            item.notes = editor.getContent();
                            this.setState({item});
                        });
                    }
                });

                setTimeout(function () {
                    this.setState({
                        tinyRowClass: 'aftiny-mount'
                    })
                }.bind(this), 1000);

            });
        });
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        tinymce.remove(this.state.editor);
    }

    componentDidUpdate() {
        if (this.firstOpen) {
            ReactDOM.findDOMNode(this['mainForm' + 0]).focus();
            this.firstOpen = false;
        }
    }

    _handleIPAByValue(n, value) {
        var item = this.state.item;
        item.IPAs[n].IPA = value;
        /*var newArray = this.state.item.IPAs;
         newArray[n].IPA = e.target.value;*/

        this.setState({item});
    }

    _handleIPA(n, e) {
        this._handleIPAByValue(n, e.target.value);
    }

    _handleSIPA(e) {
        this._updateAdvancedFieldsState('simplify_IPA', e);
        //this.setState({item: {simplified_IPA: e.target.value}});
    }

    _handleHyph(e) {
        this._updateAdvancedFieldsState('hyphenation', e);
        //this.setState({item: {hyphenation: e.target.value}});
    }

    _handleTrans(e) {
        this._updateAdvancedFieldsState('translitteration', e);
        //this.setState({item: {translitteration: e.target.value}});
    }

    _updateAdvancedFieldsState(property, e) {
        this._updateAdvancedFieldsStateByValue(property, e.target.value);
    }

    _updateAdvancedFieldsStateByValue(property, value) {
        var item = this.state.item;
        item[property] = value;
        this.setState({item});
    }

    _updatePhoneticFromChart(text, count, type) {
        if (type == "IPA")
            this._handleIPAByValue(count, text);
        else
            this._updateAdvancedFieldsStateByValue("simplify_IPA", text);
    }

    _showModalIPA(count, type) {
        const text = type == "IPA" ? this.state.item.IPAs[count].IPA : this.state.item[type];
        setTimeout(function () {
            document.getElementsByClassName("react-draggable")[0].classList.add("react-draggable-translate");
            document.getElementsByClassName("modal-content-ipa")[0].getElementsByClassName("modal-content")[0].classList.add("modal-content-ipa-translate");
        }, 1500);

        ReactDOM.render(<Ipa show={true} count={count} type={type} text={text}
                             callbackParent={(text, count, type) => this._updatePhoneticFromChart(text, count, type)}/>, document.getElementById("modal-ipa-container"));
        /*ReactDOM.render(<SCModalMessage show={true} type={type}
         msg={message}/>, document.getElementById("dict-modal-container"));*/
    }


    _saveAdvancedFields() {
        var item = JSON.parse(JSON.stringify(this.state.item));
        item.etimology = item.etimology === null ? '' : escape(encodeURIComponent(item.etimology));
        item.notes = escape(encodeURIComponent(item.notes));

        var update = ['SET_HYPHENATION', 'SET_SIMPLIFY_IPA', 'SET_ETYMOLOGY', 'SET_NOTES', 'SET_IPA', 'SET_HYPOTIZED_IPA'];
        LexemeUtilities.handleSaveWaiting();
        Dict.call('/entry/saveAdvancedField/' + JSON.stringify(item) + '/' + update).then(result => {
            if (result.result === 'OK') {
                loadAdvancedFields(result.page.lexeme_data);
                LexemeUtilities.handleSaveFinish();
            }
        });
    }

    _handleKeyUp(evt, node) {
        LexemeUtilities.handleKeyUp(evt, () => {
            ReactDOM.findDOMNode(node).focus();
        });
    }

    render() {

        var count = 0;

        var simplifiedIpa = typeof this.state.item.simplify_IPA != 'undefined' ? this.state.item.simplify_IPA : "";
        var hyphenation = typeof this.state.item.hyphenation != 'undefined' ? this.state.item.hyphenation : "";
        var translitteration = typeof this.state.item.translitteration != 'undefined' ? this.state.item.translitteration : "";
        const ipas = this.state.item.IPAs;

        const IPAs = typeof ipas !== 'undefined' ? ipas.map((item, index) =>
            <Row key={"advance-ipa-" + item.id}>
                <InputGroup className={"advanced-fields-edit-group"}>
                    <InputGroup.Addon>{Dict.t('IPA') + " " + item.entry_form}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this['mainForm' + index] = input;
                                 }}
                                 value={item.IPA}
                                 onChange={(evt) => this._handleIPA(index, evt)}
                                 onKeyUp={(evt) => this._handleKeyUp(evt, (ipas.length > 1 && index !== (ipas.length - 1)) ? this['mainForm' + (index + 1)] : this.simplifyIpa)}
                    />
                    <InputGroup.Button>
                        <Button className={"bt-advanced-fields"} onClick={() => this._showModalIPA(count, "IPA")}>
                            <Glyphicon glyph={"search"}/>
                        </Button>
                    </InputGroup.Button>
                </InputGroup>
            </Row>
        ) : "";


        return <Grid className="tiny-af-container" fluid>
            <Row id={"modal-ipa-container"}></Row>
            {IPAs}
            <Row>
                <InputGroup className={"advanced-fields-edit-group"}>
                    <InputGroup.Addon>{Dict.t('SIMPLIFY_IPA')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.simplifyIpa = input;
                                 }}
                                 value={simplifiedIpa}
                                 onChange={(evt) => this._handleSIPA(evt)}
                                 onKeyUp={(evt) => this._handleKeyUp(evt, this.hyphenation)}
                    />
                    <InputGroup.Button>
                        <Button className={"bt-advanced-fields"}
                                onClick={() => this._showModalIPA(count, "simplify_IPA")}>
                            <Glyphicon glyph={"search"}/>
                        </Button>
                    </InputGroup.Button>
                </InputGroup>
            </Row>
            <Row>
                <InputGroup className={"advanced-fields-edit-group"}>
                    <InputGroup.Addon>{Dict.t('HYPHENATION')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.hyphenation = input;
                                 }}
                                 value={hyphenation}
                                 onChange={(evt) => this._handleHyph(evt)}
                                 onKeyUp={(evt) => this._handleKeyUp(evt, this.translitteration)}
                    />
                    <InputGroup.Button>
                        <Button className={"bt-advanced-fields"} onClick={this._handleLook3}>
                            <Glyphicon glyph={"copy"}/>
                        </Button>
                    </InputGroup.Button>
                </InputGroup>
            </Row>
            <Row>
                <InputGroup className={"advanced-fields-edit-group"}>
                    <InputGroup.Addon>{Dict.t('TRANSLITTERATION')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.translitteration = input;
                                 }}
                                 value={translitteration}
                                 onChange={(evt) => this._handleTrans(evt)}
                                 onKeyUp={(evt) => this._handleKeyUp(evt, this.etimology)}/>
                </InputGroup>
            </Row>
            <Row className={this.state.tinyRowClass}>
                <FormGroup>
                    <FormControl id={"etimology"} componentClass="textarea" placeholder="textarea"
                                 ref={(input) => {
                                     this.etimology = input;
                                 }}
                                 value={this.state.item.etimology}/>
                </FormGroup>
            </Row>
            <Row className={this.state.tinyRowClass}>
                <textarea
                    id={"notes"}
                    value={this.state.item.notes}
                />
            </Row>
            <Row className={"row-bt-advancedFields " + this.state.tinyRowClass }>
                <SCSubmitButton onClick={this._saveAdvancedFields} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>
    }
}
