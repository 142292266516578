import React from 'react';
import Dict from 'dict-lib';
import {saveNotes} from './SenseAction';
import {Grid, Row, InputGroup, FormControl, Button} from 'react-bootstrap';
import './../../../../_css/lexeme.css';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import {LexemeUtilities} from "../LexemeHelper";
import ReactDom from "react-dom";
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";

export class SenseNotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: this.props.item.local_senses
        };

        this._handleSenseNote = this._handleSenseNote.bind(this);
        this._saveSenseNotes = this._saveSenseNotes.bind(this);
    }

    _handleSenseNote(item, evt) {
        var items = JSON.parse(JSON.stringify(this.state.items));
        var index = this.state.items.indexOf(item);
        items[index].notes = evt.target.value;
        this.setState({items});
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.entryNote).focus();
    }

    _saveSenseNotes() {
        var items = JSON.stringify(this.state.items);

        this.setState({btDisabled: true});
        saveNotes(items).then(result => {
            this.setState({btSaveState: "success", btDisabled: false}, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });
        });
    }

    render() {

        return <Grid className={stylesLexemeSenses['sense-notes-container']} fluid>
            {this.state.items ? this.state.items.map(item =>
                <Row>
                    <InputGroup className={stylesLexemeSenses['sense-notes__input-group']}>
                        <InputGroup.Addon>{item.language}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                     ref={(input) => {
                                         this.entryNote = input;
                                     }}
                                     value={item.notes}soundbar
                                     onChange={(evt) => this._handleSenseNote(item, evt)}
                                     onKeyUp={(evt) => {
                                         LexemeUtilities.handleKeyUp(evt, () => {
                                             this._saveSenseNotes();
                                         })
                                     }}
                        />
                    </InputGroup>
                </Row>) : ""}
            <Row className={stylesLexemeSenses['sense-notes__row-bt']}>
                <SCSubmitButton onClick={this._saveSenseNotes} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>;
    }


}
