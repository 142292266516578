import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import ReactTooltip from 'react-tooltip';
import tinymce from 'tinymce';
import 'tinymce/themes/modern';
import {Editor} from '@tinymce/tinymce-react';
import '../../tinymce/plugin.min';
import {Typeahead} from 'react-bootstrap-typeahead';
import {TreeElementCollectionBuilder} from './TreeElementCollectionBuilder';
import {TreeNodeHelper} from './TreeHelper';
import {Notifications} from './../../../utilities/DictUtilities';
import FontAwesome from 'react-fontawesome';
import {
    Grid,
    Row,
    Col,
    Button,
    FormControl,
    InputGroup,
    Modal,
    Label
} from 'react-bootstrap';
import {SCRadiobutton} from './../../components';
import Dropzone from 'react-dropzone';
import 'react-contexify/dist/ReactContexify.min.css';
import 'draggable-react-tree-component/lib/styles.css';

import './../SCTree.css';
import BUtils from './../../../utilities/BaseUtilities';
import stylesSCTree from './../SCTree.module.css';

export class TreeElementBuilder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            exitingByButton: false,
            modality: TreeNodeHelper.treeBuilderOperations.EDIT.code,
            selectedItem: {item: '', element: {}},
            composed: {code: "", icon: "", isBasic: false, basicItems: []},
            editComposed: {code: "", icon: "", isBasic: false, basicItems: []},
            composedCollections: typeof this.props.composedCollections === 'undefined' ? [] : this.props.composedCollections,
            anomalies: {
                icon: {found: false, item: {}},
                code: {found: false, item: {}}
            },
            i18nlangs: [],
            interfaceLang: this.props.interfaceLang,
            bsStyle: "default"
        };

        this._close = this._close.bind(this);
        this._onExiting = this._onExiting.bind(this);
        this._handleSetElement = this._handleSetElement.bind(this);
        this._changeModality = this._changeModality.bind(this);
        this._addToComposed = this._addToComposed.bind(this);
        this._handleIcon = this._handleIcon.bind(this);
        this._handleCode = this._handleCode.bind(this);
        this._handleSave = this._handleSave.bind(this);
        this._resetBuilder = this._resetBuilder.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        var isEdit = this.state.modality == TreeNodeHelper.treeBuilderOperations.EDIT.code;
        var composed = isEdit ? {...this.state.composed} : {code: "", icon: "", isBasic: false, basicItems: []};

        if (nextProps.show) {
            this.setState({
                show: nextProps.show,
                interfaceLang: nextProps.interfaceLang,
                i18nlangs: nextProps.i18nlangs,
                composedCollections: nextProps.composedCollections,
                exitingByButton: false,
                selectedItem: {item: '', element: {}},
                composed: composed,
                editComposed: JSON.parse(JSON.stringify(composed)),
                anomalies: {
                    icon: {found: false, itemCode: ''},
                    code: {found: false, itemCode: ''}
                }
            });
        }
    }

    _resetBuilder() {
        if (this.composedTypeahead != null)
            this.composedTypeahead.getInstance().clear();
        if (this.state.modality === TreeNodeHelper.treeBuilderOperations.EDIT.code)
            this.editComposedTypeahead.getInstance().clear();

        this.setState({
            exitingByButton: false,
            selectedItem: {item: '', element: {}},
            composed: {code: "", icon: "", isBasic: false, basicItems: []},
            editComposed: {code: "", icon: "", isBasic: false, basicItems: []},
            anomalies: {
                icon: {found: false, itemCode: ''},
                code: {found: false, itemCode: ''}
            }
        });
    }

    _onExiting() {
        this.setState({show: !this.state.exitingByButton});
    }

    _close() {
        this.setState({show: false, composed: {code: "", basicItems: []}},
            function () {
                this.props.callbackParent(this.state.composed, this.state.composedCollections, 'closing');
            });
    }

    _changeModality(modality) {
        this.setState({modality});
        this._resetBuilder();
    }

    _checkToSave() {
        if (typeof this.state.editComposed !== 'undefined' && this.state.editComposed.code.length > 0 && this.state.composed.code.length > 0) {
            var editComposed = {...this.state.editComposed};
            delete editComposed.label;
            var composed = {...this.state.composed};

            return JSON.stringify(editComposed) !== JSON.stringify(composed);
        } else
            return false;
    }

    _handleSetElement(item) {
        this.setState({selectedItem: {item: item[0], element: [this._retrieveElementBySelection(item[0])]}});
    }

    _retrieveElementBySelection(item) {
        if (typeof item !== 'undefined') {
            return this._retrieveElement(item.element);
        } else
            return '';
    }

    _retrieveElement(element) {
        var retElement = '';
        /*var colLeft = 3;
         var colRight = 9;*/

        var type = element.type;

        if (type === "textfield") {
            retElement = <InputGroup className={stylesSCTree['teb-textfield']}>
                <FormControl
                    type="text"
                    placeholder={''}
                />
            </InputGroup>;
        } else if (type === "tinymce") {
            retElement = <Editor
                initialValue={''}
                init={{
                    plugins: 'link image code sccharmap',
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | sccharmap'
                }}
                onChange={(evt) => {
                }}
            />
        } else if (type === "datepicker") {
            retElement = <input /*id={id}*/ type="date" className={stylesSCTree['teb-datepicker__input']} value={''}
                                            onChange={(evt) => {
                                            }}/>;
        } else if (type === "dropzone") {
            retElement = <Dropzone accept={'application/json'}
                                   acceptClassName="accept-drop-zone"
                                   ref={(node) => {
                                       this.dropzoneElement = node;
                                   }}
                                   className={BUtils.joinClasses('area-dropzone', stylesSCTree['tree-element-builder__dropzone-element'])}
                                   onDrop={acceptedFiles => {
                                   }}
                                   onDropRejected={function () {
                                   }}>
                <p>{Dict.t('SC_TREE_MSG_DROP_FILE_HERE')}</p>
            </Dropzone>
        } else if (type === "autocomplete") {
            if (element.label === "PREFILLED_DATA_COLLECTION") {
                retElement = <Typeahead
                    ref={(typeahead) => this['typahead-autocompletes'] = typeahead}
                    clearButton
                    className={"teb-collection teb-autocomplete-collection-builder"}
                    onChange={item => {
                        var val = typeof item[0] === 'undefined' ? '' : item[0].id;
                        if ((val + "").length > 0) {
                            element.collectionId = val;
                            this.setState({composed: this.state.composed});
                        }
                    }}
                    onSearch={() => {
                        return;
                    }}
                    options={this.props.composedCollections}
                    selected={this.props.composedCollections.filter((item) => {
                        return item.id == element.collectionId
                    })}
                    placeholder={'collezione'}
                />
            }
        } else if (type == "pathbrowser") {
            retElement = <Dropzone accept={'application/json'}
                                   acceptClassName="accept-drop-zone"
                                   ref={(node) => {
                                       this.dropzoneElement = node;
                                   }}
                                   className={BUtils.joinClasses('area-dropzone', stylesSCTree['tree-element-builder__dropzone-element'])}
                                   onDrop={acceptedFiles => {
                                   }}
                                   onDropRejected={function () {
                                   }}>
                <p>{Dict.t('SC_TREE_MSG_DROP_FILE_HERE_p')}</p>
            </Dropzone>
        }

        return retElement;
    }

    _editComposed() {
        var composed = JSON.parse(JSON.stringify(this.state.editComposed));
        delete composed.label;
        this.setState({composed});
    }

    _addToComposed() {
        if (this.state.selectedItem.item) {
            var composed = this.state.composed;
            var selectedItem = JSON.parse(JSON.stringify(this.state.selectedItem));
            selectedItem.i18nLabels = {};

            this.state.i18nlangs.map((lang) => {
                selectedItem.i18nLabels[lang.code] = lang.code === this.state.interfaceLang ? '' : "_";
            });

            composed.basicItems.push(selectedItem);

            this.setState({composed}, function () {
                setTimeout(function () {
                    ReactDOM.findDOMNode(this['form-' + (composed.basicItems.length - 1) + "-" + this.state.interfaceLang]).focus();
                }.bind(this), 500);
            });
        }
    }

    _handleCode(evt) {
        var code = evt.target.value;
        var anomalies = this.state.anomalies;
        anomalies.code = {found: false, itemCode: ''};

        TreeNodeHelper.treeBasicItems.concat(this.props.composedTreeItems).map((item) => {
            if (item.code == code && (!item.code == this.state.editComposed.code)) {
                anomalies.code.found = true;
                anomalies.code.itemCode = item.isBasic ? Dict.t(item.code) : item.code;
            }
        });

        var composed = this.state.composed;
        composed.code = code;

        this.setState({composed, anomalies});
    }

    _handleIcon(evt) {
        var icon = evt.target.value;
        var anomalies = this.state.anomalies;
        anomalies.icon = {found: false, itemCode: ''};

        TreeNodeHelper.treeBasicItems.concat(this.props.composedTreeItems).map((item) => {
            if (item.icon === icon && (!item.code === this.state.editComposed.code)) {
                anomalies.icon.found = true;
                anomalies.icon.itemCode = item.isBasic ? Dict.t(item.code) : item.code;
            }
        });

        var composed = this.state.composed;
        composed.icon = icon;

        this.setState({composed, anomalies});
    }

    _handleSave() {

        var msg = [];

        if ((this.state.composed.code + '').length === 0) {
            msg.push(<Row key={'ke-save-composed-anomaly-1'}>{'- ' + Dict.t('COMPOSED_CODE_IS_EMPTY')}</Row>);
        }

        if ((this.state.composed.icon + '').length === 0) {
            msg.push(<Row key={'ke-save-composed-anomaly-2'}>{'- ' + Dict.t('COMPOSED_ICON_IS_EMPTY')}</Row>);
        }

        if (Object.keys(this.state.composed.basicItems).length === 0) {
            msg.push(<Row key={'ke-save-composed-anomaly-3'}>{'- ' + Dict.t('COMPOSED_HAS_NO_ITEMS')}</Row>);
        } else {
            var foundEmptyLabel = false;
            var foundEmptyPreCollection = false;
            this.state.composed.basicItems.map((item) => {
                if (item.item.code === 'PREFILLED_DATA_COLLECTION') {
                    if (item.item.element.collectionId === '')
                        foundEmptyPreCollection = true;
                }

                for (var labelItem in item.i18nLabels) {
                    if (item.i18nLabels[labelItem].length === 0) {
                        foundEmptyLabel = true;
                    }
                }
            });

            if (foundEmptyLabel)
                msg.push(<Row
                    key={'ke-save-composed-anomaly-3-1'}>{'- ' + Dict.t('COMPOSED_ITEMS_REQUIRE_LABEL')}</Row>);
            if (foundEmptyPreCollection)
                msg.push(<Row
                    key={'ke-save-composed-anomaly-3-1'}>{'- ' + Dict.t('PREFILLED_DATA_COLLECTION_REQUIRE_ITEM')}</Row>);
        }

        if (this.state.anomalies.icon.found) {
            msg.push(<Row key={'ke-save-composed-anomaly-4'}>{'- ' + Dict.t('ICON_FOUND')}</Row>);
        }

        if (this.state.anomalies.code.found) {
            msg.push(<Row key={'ke-save-composed-anomaly-5'}>{'- ' + Dict.t('CODE_FOUND')}</Row>);
        }

        if (Object.keys(msg).length > 0) {
            Notifications.showErrorModalMessage(<Grid
                className={stylesSCTree['tree-element-builder__grid-save-composed-anomaly']}
                fluid>{msg}</Grid>, 5000);
        } else {

            var isDesign = this.state.modality == TreeNodeHelper.treeBuilderOperations.DESIGN.code;
            var composed = JSON.parse(JSON.stringify(this.state.composed));

            this.props.callbackParent(this.state.composed, this.state.modality.toLowerCase() + '-composed-item');

            if (isDesign) {
                /*this._resetBuilder();*/
                Notifications.showToastNotification(Dict.t('COMPOSED_TREE_ITEM_CREATED') + ':&nbsp;<strong>' + this.state.composed.code + '</strong>', 3000, 1000);
            } else {
                this.setState({bsStyle: "success"}, function () {
                        setTimeout(function () {
                            this.setState({bsStyle: "default"});
                        }.bind(this), 500);
                    }
                );
            }
        }
    }

    render() {

        const modalityItem = TreeNodeHelper.treeBuilderOperations[this.state.modality];
        const isDesign = modalityItem === TreeNodeHelper.treeBuilderOperations.DESIGN;
        const isEdit = modalityItem === TreeNodeHelper.treeBuilderOperations.EDIT;

        const items = TreeNodeHelper.treeBasicItems.map((item) => {
            return {code: item.code, element: item.element, label: Dict.t(item.code)};
        });

        const operations = Object.keys(TreeNodeHelper.treeBuilderOperations).map((item) => {
            var itemOperation = TreeNodeHelper.treeBuilderOperations[item];
            return {code: itemOperation.code, label: Dict.t(itemOperation.label)};
        });

        const composedDescr = this.state.composed.basicItems.map((item, index) => {
            return <Row key={"key-builder-add-composed-descr-" + index}
                        className={stylesSCTree['tree-element-builder__row-composed-descr-element']}>
                <Col lg={3} md={3}>
                    <small style={{paddingRight: '3px'}}>{parseFloat(index) + parseFloat(1)})</small>
                    {Dict.t(item.item.code)}
                </Col>
                <Col lg={6} md={6}>
                    {this.state.i18nlangs.map((lang) => {
                        return <InputGroup key={"teb-inputgroup-" + index + "-" + lang.code}
                                           className={stylesSCTree['tree-element-builder__input-group']}>
                            <InputGroup.Addon style={{minWidth: '50px'}}>{lang.code}</InputGroup.Addon>
                            <FormControl
                                id={"id-form-" + index + "-" + lang.code}
                                type="text"
                                ref={(input) => {
                                    this['form-' + index + "-" + lang.code] = input;
                                }}
                                placeholder={Dict.t('LABEL')}
                                value={item.i18nLabels[lang.code]}
                                onChange={(evt) => {

                                    item.i18nLabels[lang.code] = evt.target.value;
                                    const composed = this.state.composed;

                                    this.setState({composed});
                                }}
                                onKeyDown={() => {
                                }}
                            />
                        </InputGroup>
                    })}
                </Col>
                <Col lg={3} md={3}>
                    <Button className={stylesSCTree['tree-object-wrapper__inSearchResult--' + (this.state.composed.basicItems[index].showInSearchResult ? 'show' : 'hide') ]} onClick={(evt) => {
                        var composed = this.state.composed;
                        composed.basicItems[index].showInSearchResult = !composed.basicItems[index].showInSearchResult;

                        this.setState({composed});
                    }}><FontAwesome name={'th-list'}
                                    className={stylesSCTree['tree-object-wrapper__show-hide-button-fa']}/>
                    </Button>
                    <Button style={{marginLeft: '5px'}} onClick={(evt) => {
                        var composed = this.state.composed;
                        composed.basicItems[index].hidden = !composed.basicItems[index].hidden;

                        this.setState({composed});
                    }}><FontAwesome name={this.state.composed.basicItems[index].hidden ? 'eye-slash' : 'eye'}
                                    className={stylesSCTree['tree-object-wrapper__show-hide-button-fa']}/>
                    </Button>
                    <Button style={{marginLeft: '5px'}} onClick={(evt) => {

                    var composed = this.state.composed;
                    composed.basicItems.splice(index, 1);

                    this.setState({composed});
                }}>{Dict.t('REMOVE')}</Button>
                </Col>
            </Row>;
        });

        const composedBasicItems = this.state.composed.basicItems.map((item, index) => {

            var label = item.i18nLabels[this.state.interfaceLang];
            var opacityLabel = '1';
            var fontSize = '14px';

            if (label.length == 0) {
                label = Dict.t('MISSING_LABEL');
                opacityLabel = '0.3';
                fontSize = '12px';
            }

            return <Row key={"key-builder-add-composed-" + index}
                        className={stylesSCTree['tree-element-builder__row-basic-items-element']}>
                <Col lg={3} md={3} style={{paddingTop: '5px'}}>
                    <small style={{paddingRight: '5px', opacity: '0.3'}}>{parseFloat(index) + parseFloat(1)})</small>
                    <span style={{opacity: opacityLabel, fontSize: fontSize}}>{label}</span></Col>
                <Col lg={9} md={9}>{this._retrieveElement(item.item.element)}</Col>
            </Row>;
        });

        const composedTreeItems = this.props.composedTreeItems.map(item => {
            var newItem = JSON.parse(JSON.stringify(item));
            newItem.label = item.code;
            return newItem;
        });

        const isEditToChoose = !isEdit || typeof this.state.editComposed === 'undefined' || (this.state.composed.id === this.state.editComposed.id && (this.state.composed.code + '').length > 0);
        const selectedEditItem = typeof this.state.editComposed === 'undefined' || this.state.editComposed.code === '' ? [] : [{
            id: this.state.editComposed.id,
            label: this.state.editComposed.code
        }];

        const ButtonSave = <Button className={"bt-treebuilder "}
                                   bsStyle={isEdit && this._checkToSave() ? "danger" : this.state.bsStyle}
                                   onClick={(evt) => this._handleSave(evt)}>{Dict.t('SAVE')}</Button>;

        return <Modal id="tree-element-builder" className={stylesSCTree['tree-element-builder']}
                      key={"key-tree-builder"} show={this.state.show} onHide={this._close}
                      restoreFocus
                      onExiting={() => {
                          this._onExiting()
                      }}>
            <Modal.Header>{Dict.t('MODALITY')}:<strong style={{
                paddingLeft: '5px',
                fontSize: '18px'
            }}>{Dict.t(modalityItem.code)}</strong></Modal.Header>
            <Modal.Body className={stylesSCTree['tree-element-builder__modal-body']}>
                {modalityItem === TreeNodeHelper.treeBuilderOperations.CREATE_COLLECTIONS ?
                    <TreeElementCollectionBuilder composedCollections={this.state.composedCollections}
                        /*callbackParent={(composedCollections) => {
                         this._saveComposedCollections(composedCollections);
                         }}*//> :
                    <Grid id={"teb-grid-element"} className={stylesSCTree['tree-element-builder__grid-element']}>
                        <Row>
                            <Col id="teb-column-selection"
                                 className={stylesSCTree['tree-element-builder__column-selection']} lg={6} md={6}>
                                <Row id="row-composed-element-label"
                                     className={BUtils.joinClasses(stylesSCTree['tree-element-builder__row'], stylesSCTree['tree-element-builder__composed-element-row-label'])}>
                                    <Label
                                        className={stylesSCTree['tree-element-builder__composed-element-label']}>{Dict.t(modalityItem.label)}</Label>
                                </Row>
                                {modalityItem === TreeNodeHelper.treeBuilderOperations.EDIT ?
                                    <Row className={stylesSCTree['tree-element-builder__row']}>
                                        <Col lg={7} md={7}
                                             className={stylesSCTree['tree-element-builder__col-component-code']}>
                                            <Typeahead
                                                id={'tree-element-builder-typeahead'}
                                                ref={(typeahead) => this.editComposedTypeahead = typeahead}
                                                className={"teb-collection teb-autocomplete-collection-builder"}
                                                onChange={(item) => {
                                                    var editComposed = item[0];
                                                    this.setState({editComposed});
                                                }}
                                                onSearch={() => {
                                                    return;
                                                }}
                                                options={composedTreeItems}
                                                selected={selectedEditItem/*[selectedCollection.items[selectedCollection.itemIdSelected]]*/}
                                                placeholder={'collezione'}
                                            />
                                        </Col>
                                        <Col lg={5} md={5}>
                                            <Button className={stylesSCTree['tree-element-builder__bt-treebuilder']}
                                                    onClick={() => {
                                                        this._editComposed();
                                                    }}>{Dict.t('EDIT')}</Button>
                                            <Button className={stylesSCTree['tree-element-builder__bt-treebuilder']}
                                                    onClick={() => {
                                                        this._resetBuilder();
                                                    }}>{Dict.t('RESET')}</Button>
                                        </Col>
                                    </Row> : ''}
                                <Row id="composed-code-icon"
                                     className={BUtils.joinClasses(stylesSCTree['tree-element-builder__row'], stylesSCTree['tree-element-builder__composed-code-icon'], stylesSCTree['tree-element-builder__composed--' + (isEditToChoose ? 'show' : 'hide')])}>
                                    <Col lg={7} md={7} className={'col-code-component-input-group'}>
                                        <InputGroup key={"key-component-name"}
                                                    className={stylesSCTree["tree-element-builder__component-name-inputgroup-first"]}>
                                            <InputGroup.Addon
                                                style={{minWidth: '50px'}}>{Dict.t('COMPONENT_CODE')}
                                            </InputGroup.Addon>
                                            <FormControl
                                                type="text"
                                                ref={(input) => {
                                                    this['form-omponent-name'] = input;
                                                }}
                                                placeholder={Dict.t('COMPONENT_CODE')}
                                                value={this.state.composed.code}
                                                onChange={(evt) => this._handleCode(evt)}
                                                onKeyDown={() => {
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                    {/*<Col lg={2} md={2}>
                                        {isDesign ? ButtonSave : ''}
                                    </Col>*/}
                                    <Col lg={5} md={5}
                                         className={BUtils.joinClasses(stylesSCTree['tree-element-builder__component-anomalies'], stylesSCTree['tree-element-builder__component-anomalies--' + (this.state.anomalies.code.found ? 'found' : 'not-found')])}
                                    >
                                        <FontAwesome name={"exclamation-triangle"} data-tip
                                                     data-for='tooltip-anomalies-code-found'>baddi</FontAwesome>
                                        <ReactTooltip id='tooltip-anomalies-code-found' type='error'
                                                      className={"treebuilder-tool-tip"}
                                                      place="top">{this.state.anomalies.code.found ? (Dict.t('CODE_FOUND') + ": " + this.state.anomalies.code.itemCode) : ''}</ReactTooltip>
                                    </Col>
                                    <Col lg={7} md={7} className={'col-icon-input-group'}>
                                        <InputGroup key={"key-component-icon"}
                                                    className={stylesSCTree["tree-element-builder__component-name-inputgroup-first"]}>
                                            <InputGroup.Addon style={{minWidth: '50px'}}>{Dict.t('ICON')}
                                                <small style={{paddingLeft: '4px', paddingRight: '4px'}}>(<a
                                                    href="https://fontawesome.com/v4.7.0/icons/"
                                                    target="_blank">{Dict.t('LIST')}</a>)
                                                </small>
                                                <FontAwesome name={'question-circle'} data-tip
                                                             data-for='tooltip-icon-list'/>
                                                <ReactTooltip id='tooltip-icon-list' type='info' place="top"
                                                              className={"treebuilder-tool-tip"}>
                                                    <span>{Dict.t('LIST-ICON-INFO')}</span>
                                                </ReactTooltip>
                                            </InputGroup.Addon>
                                            <FormControl
                                                type="text"
                                                ref={(input) => {
                                                    this['form-omponent-name'] = input;
                                                }}
                                                placeholder={Dict.t('ICON')}
                                                value={this.state.composed.icon}
                                                onChange={(evt) => this._handleIcon(evt)}
                                                onKeyDown={() => {
                                                }}
                                            />
                                            <InputGroup.Addon
                                                style={{minWidth: '80px'}}>{(typeof this.state.composed.icon !== 'undefined' && (this.state.composed.icon + '').length > 0 ?
                                                <FontAwesome className={stylesSCTree['tree-element-builder__fa']}
                                                             name={this.state.composed.icon}/> : <i>
                                                    <small>no icon</small>
                                                </i>)}
                                            </InputGroup.Addon>
                                        </InputGroup>
                                    </Col>
                                    <Col lg={5} md={5}
                                         className={BUtils.joinClasses(stylesSCTree['tree-element-builder__component-anomalies'], stylesSCTree['tree-element-builder__component-anomalies--' + (this.state.anomalies.icon.found ? 'found' : 'not-found')])}>
                                        <FontAwesome name={"exclamation-triangle"} data-tip
                                                     data-for='tooltip-anomalies-icon-found'>baddi</FontAwesome>
                                        <ReactTooltip id='tooltip-anomalies-icon-found' type='error'
                                                      className={"treebuilder-tool-tip"}
                                                      place="top">{this.state.anomalies.icon.found ? (Dict.t('ICON_FOUND') + ": " + this.state.anomalies.icon.itemCode) : ''}</ReactTooltip>
                                    </Col>
                                </Row>
                                <Row id="composed-elements-collection"
                                     className={BUtils.joinClasses(stylesSCTree['tree-element-builder__row'], stylesSCTree['tree-element-builder__composed--' + (isEditToChoose ? 'show' : 'hide')])}>
                                    <Col lg={7} md={7}><Typeahead
                                        id={"th-composed-elements-collection"}
                                        clearButton
                                        ref={(item) => {
                                            this.composedTypeahead = item;
                                        }}
                                        align={"justify"}
                                        onChange={item => this._handleSetElement(item)}
                                        options={items}
                                        placeholder={Dict.t('SELECT_ELEMENT_TO_ADD')}
                                        /*selected={''}*/
                                    />
                                    </Col>
                                    <Col lg={5} md={5}>
                                        <Button className={"bt-treebuilder"} onClick={() => {
                                            this._addToComposed()
                                        }}>{Dict.t('ADD')}</Button>
                                    </Col>
                                </Row>
                                {this.state.composed.basicItems.length > 0 ?
                                    <Row
                                        id="row-composed-element-label"
                                        className={BUtils.joinClasses(stylesSCTree['tree-element-builder__row'], stylesSCTree['tree-element-builder__composed-element-row-label'])}>
                                        <Label
                                            className={stylesSCTree['tree-element-builder__composed-element-label']}>{Dict.t('ELEMENTS') + ":"}</Label>
                                    </Row> : ''}
                                <Row id={"row-composed-descr"}
                                     className={stylesSCTree['tree-element-builder__row-composed-descr']}>{composedDescr}</Row>
                            </Col>
                            <Col id="teb-column-element"
                                 className={stylesSCTree['tree-element-builder__column-element']} lg={6}
                                 md={6}>{composedBasicItems}</Col>
                        </Row>
                        <Row id={"teb-edit-element-toolbar"}
                             className={stylesSCTree['tree-element-builder__edit-element-toolbar']}>{isEditToChoose ? ButtonSave : ''}</Row>
                    </Grid>}
            </Modal.Body>
            <Modal.Footer>
                <SCRadiobutton default={modalityItem.code}
                               label={''}
                               id={'sc-radio-treebuilder-operations'}
                               additionalClass={stylesSCTree['tree-element-builder__radio-operations']}
                               items={operations}
                               bsStyle={"primary"}
                               callbackParent={(value) => this._changeModality(value)}/>
                <Button
                    bsStyle={"primary"} onClick={() => this.setState({
                    exitingByButton: true,
                }, function () {
                    this._close();
                })}>
                    {Dict.t('CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    }
}
