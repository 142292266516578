import React from 'react';
import {deletePhrase, detachPhrase} from './PhraseAction';
import Dict from 'dict-lib';
import {Button, Row, Col} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class PhraseDelete extends React.Component {

    constructor(props) {
        super(props);

        this._handleRemove = this._handleRemove.bind(this);
    }

    _handleRemove() {
        var item = this.props.item;

        if (this.props.isDetach)
            detachPhrase(item.ids, this.props.additionalData.phraseItem.id);
        else
            deletePhrase(item.ids, this.props.additionalData.phraseItem.id);
    }

    render() {

        var msg = '';
        if (this.props.isDetach) {
            msg = this.props.additionalData.phraseItem.sense_descr.split(",").length > 1 ? "ASK_DETACH_SENSE" : "ASK_DETACH_ORPHANE_SENSE";
        } else {
            msg = "ASK_DELETE_PHRASE";
        }

        return <div className="locVar-delete">
            <Row>
                <Col lg={7} md={7} className="popup-label">{Dict.t(msg)}</Col>
                <Col lg={5} md={5}>
                    <SCSubmitButton onClick={this._handleRemove} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </div>;
    }


}
