import React from "react";
import {LexemePageContext} from "./LexemePageContext";
import {Button} from 'react-bootstrap';
import {LexemeDictionaryType} from "./parts/00_DictionaryType/LexemeDictionaryType";
import {LexemeTitle} from "./parts/01_Title/LexemeTitle";
import {LexemeData} from "./parts/02_Data/LexemeData";
import {LexemeSubentries} from "./parts/03_Subentries/LexemeSubentries";
import {LexemePolyrhematics} from "./parts/04_Polyrhematics/LexemePolyrhematics";
import {LexemeReferences} from "./parts/05_References/LexemeReferences";
import BUtils from "../../utilities/BaseUtilities";
import stylesLexemePage from '../../_css/dictionary/lexemePage.module.css';
import {LexemeDisplayContext, LexemePageInfos, LexemeUtilities} from "./editor/LexemeHelper";
import {LexemePlace} from "./parts/06_Place/LexemePlace";
import Dict from "dict-lib";
import ReactDOM from "react-dom";
import App from "../../App";
import ReactTooltip from "react-tooltip";
import FontAwesome from "react-fontawesome";
import {changeTab, getPage, getTabIndex, getTabs, setPage} from "./LexemePage";
import {LexemeShiftButtons} from "./LexemeShiftButtons";
import {LexemeDescrLanguage} from "./LexemeDescrLanguage";

export class LexemaPageContextProvider extends React.Component {
    constructor(props) {
        super(props);

        this.dictType = this.props.page.dictionary_properties.type;
        this.tabIndex = getTabIndex();
    }

    static getDerivedStateFromError(error) {
        return {isOK: false};
    }

    componentDidMount() {
        const idPostFix = this.props.idPostFix;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var contextonte = LexemePageInfos.createContext(this.props.pageStatus, this.props.displayContext, this.props.page, this.props.callBackSelectionHandler, this.props.startItem, this.props.descrLanguage, this.props.classType);
        return contextonte;
    }

    getContext() {
        return LexemePageInfos.createContext(this.props.pageStatus, this.props.displayContext, this.props.page, this.props.callBackSelectionHandler, this.props.startItem, this.props.descrLanguage, this.props.classType);
    }

    render() {
        const idPostFix = this.props.idPostFix;
        const pageStatus = this.props.pageStatus;
        const page = this.props.page;
        const displayContext = this.props.displayContext;

        const isSelector = pageStatus.endsWith('_SELECTOR');
        const isTreeModel = this.props.page.lexeme_properties.is_tree_model;
        const showDictionaryType = (!isTreeModel || isSelector) && displayContext === LexemeDisplayContext.DICTIONARY_SEARCH;
        const isToponym = this.props.page.lexeme_properties.discriminator === 'TOPONYM';

        return <LexemePageContext.Provider key={'lexeme-provi-' + idPostFix} value={this.getContext()}>
            {showDictionaryType ?
                <LexemeDictionaryType key='lexeme-dictionary-type' page={page} pageStatus={pageStatus}/> : ''}
            {!isSelector  ?
                <LexemeShiftButtons dictType={this.props.page.dictionary_properties.type}
                                    idPostFix={this.props.idPostFix}/> : ''}
            <LexemeDescrLanguage />
            <div className={'lexeme-page'}>
                <LexemeTitle key={'lexeme-title-' + idPostFix} page={page} pageStatus={pageStatus}/>
                <div id={'dictionary-body-' + page.lexeme_properties.root_entry_id}
                     className={BUtils.joinClasses(stylesLexemePage['dictionary-body'], stylesLexemePage['scrollable-dict-contents'])}
                     style={{
                         borderBottom: '1px solid #dddddd',
                         height: (isSelector ? 'auto' : (document.getElementById("lexeme-page-container").offsetHeight - 140) + "px")
                     }}>
                    <LexemeData key={'lexeme-data-' + idPostFix} page={page} pageStatus={pageStatus}/>
                    {isToponym ? <LexemePlace key={'lexeme-place-' + idPostFix} page={page}/> : <>
                        <LexemeSubentries key={'subentries-' + idPostFix} page={page} pageStatus={pageStatus}/>
                        <LexemePolyrhematics key={'polyrhematics-' + idPostFix} page={page} pageStatus={pageStatus}
                                             displayContext={displayContext}/></>}
                    <LexemeReferences key={'references-' + idPostFix} direct={true} page={page}
                                      pageStatus={pageStatus}/>
                    <LexemeReferences key={'inverse-references-' + idPostFix} direct={false} page={page}
                                      pageStatus={pageStatus}/>
                </div>
            </div>
        </LexemePageContext.Provider>
    }
}