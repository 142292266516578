import React from 'react';
import Dict from 'dict-lib';
import {Button, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import {SCCheckbox} from "../../dict-components/checkbox/SCCheckbox";

import stylesLanguageData from './../../_css/dictionary/languageData.module.css';
import BUtils from './../../utilities/BaseUtilities';

export class EditLanguage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataObject: JSON.parse(JSON.stringify(this.props.data.dataObject))
        }

        this._handleEditLangChange = this._handleEditLangChange.bind(this);
    }

    _handleEditLangChange(field, value) {
        var dataObject = this.state.dataObject;

        dataObject[field] = value;
        this.setState({dataObject});
        this.props.callBackChangeData(dataObject);
    }

    _retrieveComponentClass(field) {
        var sField = this.props.data.specialFields.find((specialField) => {
            return specialField.name === field;
        });

        return typeof sField !== 'undefined' && sField !== null ? sField.type : 'input';
    }


    render() {

        var components = this.state.dataObject === null ? '' : Object.keys(this.props.data.dataObject).map((field, index) => {
            var postFix = field + '-' + index;
            var dataField = this.state.dataObject[field];
            var label = Dict.t(BUtils.deCamel(field, "_").toUpperCase());
            var id = 'edit-lang-' + postFix;

            if (typeof dataField === 'string') {
                var componentClass = this._retrieveComponentClass(field);
                return <FormGroup key={'key-edit-lang-' + postFix} controlId={id}
                                  bsClass={BUtils.joinClasses(stylesLanguageData['edit-language-element'], stylesLanguageData[componentClass + '-group'])}>
                    <ControlLabel>{label}</ControlLabel>
                    <FormControl value={dataField} componentClass={componentClass}
                                 onChange={(event) => this._handleEditLangChange(field, event.target.value)}/>
                </FormGroup>
            } else if (typeof dataField === 'boolean') {
                return <SCCheckbox label={label} checked={dataField} id={id}
                                   additionalClass={BUtils.joinClasses(stylesLanguageData['edit-language-element'], stylesLanguageData['checkbox'])}
                                   callbackParent={(id, newState) => this._handleEditLangChange(field, newState)}/>
            }
        })

        return <>
            {components}
        </>
    }
}