import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import MicrolinkCard from '@microlink/react';
import {arrayMove, SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import ReactTooltip from 'react-tooltip';
import {SCCheckbox, SCRadiobutton, SCTree} from '../../components';
import {AsyncTypeahead, Typeahead} from 'react-bootstrap-typeahead';
import tinymce from 'tinymce';
import 'tinymce/themes/modern';
import {Editor} from '@tinymce/tinymce-react';
import '../../tinymce/plugin.min';
import {NodeOperations, TreeDOMHelper, TreeNodeHelper, TreeUtilities} from './TreeHelper';
import {
    Button,
    Carousel,
    CarouselItem,
    Col,
    FormControl,
    Glyphicon,
    Image,
    InputGroup,
    Modal,
    Row
} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import {Notifications} from './../../../utilities/DictUtilities';
import {ContextMenu, ContextMenuProvider, Item, Separator, Submenu} from 'react-contexify';
import {Page} from 'react-pdf';
import {Document} from 'react-pdf/dist/entry.webpack';
import GoogleDocsViewer from 'react-google-docs-viewer';

import 'react-contexify/dist/ReactContexify.min.css';
import 'draggable-react-tree-component/lib/styles.css';

import FontAwesome from 'react-fontawesome';
import {AdministrationUtilities} from "../../../administration/AdministrationHelper";
import {LexemeUtilities} from "../../../lexeme/page/editor/LexemeHelper";
import stylesSCTree from './../SCTree.module.css';
import stylesSorter from './../../../_css/sorter.module.css';
import './../SCTree.css';
import {userService} from "../../../_redux/authentication/services/user.services";
import BUtils from "../../../utilities/BaseUtilities";
import {TreeContext} from "../../../corpora/TreeContext";


export class TreeItemMenu extends React.Component {

    constructor(props) {
        super(props);

        this.isLogged = userService.isLogged();
        this.isAdministrator = userService.isAdministrator();
        this.role = props.roleProps.role;
        this.isInserter = props.roleProps.isInserter;
        this.isModifier = props.roleProps.isModifier;
        this.isModifierOrAdministrator = this.isModifier || this.isAdministrator;
        this.isComponentContainer = TreeNodeHelper.isContainerItem(props.item.code);

        this.state = {
            composedCollections: this.props.composedCollections,
            interfaceLang: '',
            subMenuWillOverflow: false,
            importStructure: {},
            showDocModal: false,
            showContextMenu: false
        };

        this._handleRemoveItem = this._handleRemoveItem.bind(this);
        this._importData = this._importData.bind(this);
        this._acceptImportData = this._acceptImportData.bind(this);

        this.firstClick = 0;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        //var isClick = !nextState.nextPrevFound && typeof nextState.data !== 'undefined' && nextState.data.length > 0;
        return this.props.item.key === this.props.lastUpdate || nextState.showContextMenu /*|| (this.props.item.code === 'FOLDER' && this.props.item.isHiddenBranch)*/;
    }

    /*componentDidUpdate(prevProps, prevState, snapshot) {

    }*/

    componentWillMount() {
        this._resetImportStructure();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({interfaceLang: nextProps.interfaceLang})
    }

    componentDidMount() {
        if (this.props.item.key === this.props.lastRenderKey /*parentKey === this.props.expandedKeys[this.props.expandedKeys.length - 1]*/) {
            var spinnerElement = document.getElementById('loading-key-spin-' + NodeOperations.retrieveParentKey(this.props.item));
            if (spinnerElement !== null)
                spinnerElement.style.display = 'none';
        }

        /*var elMinorButtonContainer = document.getElementById('node-page');//document.querySelectorAll('.' + stylesSCTree['tree-docs-viewer-item__buttons']);
        var elButtons = elMinorButtonContainer.getElementsByTagName('button');
        for (var el of elButtons) {
            el.style.backgroundColor = Dict.getGlobalProperty('TREE_MINOR_SKIN');
        }*/
    }


    _handleOpenEditItem(event, item) {
        this._handleOpenContextMenuItem(event, TreeNodeHelper.treeNodeOperations.EDIT, item, undefined, TreeNodeHelper.isBasicItem(item.code));
        TreeDOMHelper.trySetEditItemSkin(1);
        //const inputId = 'input-add-node';
        //document.getElementById(inputId).value = item.title;
    }

    _handleOpenSortItems(event, children) {
        var ele = TreeDOMHelper.resetNodePageContainer(event, "node-operations-container");
        var div = '';

        if (children.length > 1) {
            var itemList = children.map((child, index) =>
                <div key={"key-tree-item-sortable-" + index}>
                    {child.title}
                </div>
            );

            const DragHandle = SortableHandle(() => <div className="items-sortable-handle sortable-handle">
                <Glyphicon glyph="sort"/>
            </div>);

            const SortableItem = SortableElement(({value}) => {
                return (
                    <div
                        className={BUtils.joinClasses(stylesSorter['items-sortable-item'], stylesSorter['sortable-item'])}>
                        <DragHandle/>
                        {value}
                    </div>
                );
            });

            const SortableList = SortableContainer(({items}) => {
                return (
                    <div className={"div-sortable-container sortable-type-" + this.props.type}>
                        {items.map((value, index) => (
                            <SortableItem key={'sortable-item-' + index} index={index} value={value}/>
                        ))}
                    </div>
                );
            });

            var sortableItemList = <SortableList key={"key-lex-items-sortable"}
                                                 helperClass={"sortableHelper"} pressDelay={0} lockAxis={"y"}
                                                 useDragHandle={false}
                                                 items={itemList}
                                                 onSortStart={(obj) => {
                                                 }}
                                                 onSortEnd={(obj) => {
                                                     var sortedChildren = arrayMove(children, obj.oldIndex, obj.newIndex);
                                                     var child = sortedChildren[0];
                                                     this.props.callbackSort(sortedChildren, TreeNodeHelper.isRootItem(child));
                                                     this._handleOpenSortItems(event, sortedChildren);
                                                 }}/>;

            div = <div id="div-sort-node-container">{sortableItemList}</div>;
        } else {
            div = <div id="div-sort-node-container">{Dict.t('NO_SORT_COLLECTION_FOUND')}</div>;
        }

        ReactDOM.render(div, ele);
    }

    _handleShowHideItem(event, item) {
        var hiddenField = item.code === 'FOLDER' ? 'hiddenBranch' : 'hidden';
        this.props.callbackShowHideItem(typeof item[hiddenField] === 'undefined' || !item[hiddenField]);
    }

    _handleCutItems(item, cutChildren) {
        this.props.callbackCut(item, cutChildren);
    }

    _handleCopyItems(item, copyChildren) {
        this.props.callbackCopy(item, copyChildren);
    }

    _handlePasteItem(item) {
        this.props.callbackPaste(item);
    }

    _resetCut() {
        this.props.callbackResetCut();
    }

    _handleOpenContextMenuItem(event, operation, item, treeObject, isBasicItem) {
        TreeDOMHelper.resetItemSelection();

        const expander = event.target.parentElement.parentElement.previousSibling;

        TreeDOMHelper.hideNodePageContainer();
        var ele = TreeDOMHelper.resetNodePageContainer(event, "node-operations-container");

        ReactDOM.unmountComponentAtNode(ele);

        var retrieveItem = new Promise((resolve) => {
            if (operation === TreeNodeHelper.treeNodeOperations.ADD) {
                return resolve('');
            } else if (item.data === '' || typeof item.data === 'undefined' || item.data === []) {
                return resolve(Dict.call('/tree/getItemDataByKey/' + this.props.projectName + '/' + item.key + '/'));
            } else {
                return resolve(item.data);
            }
        });


        retrieveItem.then((data) => {
            item.data = data;
            this.setState({data}, () => {
                ReactDOM.render(<TreeNodeEditor item={item} operation={operation} treeObject={treeObject}
                                                isBasicItem={isBasicItem}
                                                {...this.props}
                                                isViewer={false}
                                                callbackParent={(returnItem, operation) => {
                                                    this.props.callbackSave(returnItem, operation, expander, operation === 'add' ? event.target.parentElement : null);
                                                }}/>, ele, function () {

                    if (operation === TreeNodeHelper.treeNodeOperations.ADD) {
                        setTimeout(function () {
                            TreeDOMHelper.resetNodeEditorPosition(ele);
                        }, 0);
                    } else
                        TreeDOMHelper.resetNodeEditorPosition(ele);
                });
            })
        })
    }

    _handleAddBookmark(event, item) {
        this._handleOpenContextMenuItem(event, TreeNodeHelper.treeNodeOperations.ADD, item, TreeNodeHelper.getSubItemByCodes(item.code, 'BOOKMARK'), true);
        //const inputId = 'input-add-node';
        //document.getElementById(inputId).value = item.title;
    }

    _handleOpenImportItem(event, operation, item, treeObject, isBasicItem, isSubitem) {
        TreeDOMHelper.resetItemSelection();

        isSubitem = typeof isSubitem !== 'undefined' && isSubitem;

        const expander = event.target.parentElement.parentElement.previousSibling;
        if (isSubitem) {
            expander.click();
        }

        TreeDOMHelper.hideNodePageContainer();
        var ele = TreeDOMHelper.resetNodePageContainer(event, "node-import-container");

        this._setImportStructure(item, treeObject);

        var allMultimediaMime = "audio/*, audio/mpeg, image/jpeg,image/png,image/gif, video/mp4, video/mpeg, video/quicktime, video/mpg, application/pdf";

        ReactDOM.render(<div>
            <div id={"import-tree-object-log"} className={stylesSCTree['node-import-log']}>
                <Row id={"import-tree-object-log-title"}
                     className={stylesSCTree['node-import-log__title']}>{Dict.t('IMPORT_LOG')}</Row>
                <Row id={"import-tree-object-log-contents"} className={stylesSCTree['node-import-log__contents']}/>
                <Row id={"import-tree-object-log-footer"} className={stylesSCTree['node-import-log__footer']}><span
                    id={'info-node-import-log-footer'}
                    className={stylesSCTree['info-node-import-log-footer']}>{Dict.t('IMPORT_DATA_HAS_RESOURCES')}</span>
                    <Button id={"bt-import-tree-object-log-footer"} bsStyle={'primary'}
                            onClick={() => this._acceptImportData(expander)}>{Dict.t('ACCEPT_IMPORT_DATA')}</Button>
                </Row>
            </div>
            <div id={"import-tree-multimedia-dropzone"}
                 className={BUtils.joinClasses(stylesSCTree['node-import-log'], stylesSCTree['node-import-resources'])}>
                <Row id={"import-tree-object-import-resource-title"}
                     className={stylesSCTree['node-import__tree-object-header-info']}>{Dict.t('INSERT_MULTIMEDIA_RESOURCE')}</Row>
                <Row className={stylesSCTree['node-import-log__dropzone']}>
                    <Dropzone
                        accept={allMultimediaMime}
                        acceptClassName="accept-drop-zone"
                        ref={(node) => {
                            this.dropzoneImportResourceElement = node;
                        }}
                        inputProps={{required: treeObject}}
                        className={BUtils.joinClasses(stylesSCTree['node-import-container__dropzone-multimedia-resource'], 'area-dropzone')}
                        onDropAccepted={(acceptedFiles) => {
                            TreeUtilities.addImportMultimediaResources(this.props.projectName, acceptedFiles, function (fileList) {

                                var contents = fileList.map(file => {
                                    return '<div>' + file + '</div>';
                                })

                                document.getElementById('import-tree-object-import-resource-contents').innerHTML = contents.join('');
                                this._prepareImport();
                            }.bind(this));

                        }}
                        onDropRejected={(acceptedFiles) => {
                            Notifications.showErrorModalMessage('DROPPED_FILE_NOT_ACCEPTED', 2000);
                        }}
                    >
                        <p>{Dict.t('INSERT_MULTIMEDIA_RESOURCE_HERE')}</p>
                        <p>{allMultimediaMime}</p>
                    </Dropzone>
                </Row>
                <Row id={"import-tree-object-import-resource-contents"}
                     className={BUtils.joinClasses(stylesSCTree['node-import-log__contents'], stylesSCTree['node-import-resource__contents'])}/>
                <Row id={"import-tree-object-import-resource-footer"}
                     className={stylesSCTree['node-import-log__footer']}>
                    <Button id={"bt-import-tree-object-import-resource-footer"} bsStyle={'primary'}
                            onClick={() => {
                                this._emptyResourceDropZone();
                                this._prepareImport();
                            }}>{Dict.t('EMPTY')}</Button>
                    <Button id={"bt-import-tree-object-close-import-resource-footer"} bsStyle={'primary'}
                            onClick={() => {
                                this._closeResourceDropZone();
                                this._prepareImport();
                            }}>{Dict.t('BT_OK')}</Button>
                </Row>
            </div>
            <Row id={"import-tree-object-header-info"}
                 className={BUtils.joinClasses(stylesSCTree['node-import__tree-object-header-info'], stylesSCTree['tree-import-info'], 'tree-import-info-headerfooter')}>{treeObject.isContainerItem ? Dict.t(treeObject.code) : treeObject.code} - {Dict.t('NODE_IMPORT_ON')}: {item.title}</Row>
            <Dropzone accept={'.csv'}
                      acceptClassName="accept-drop-zone"
                      ref={(node) => {
                          this.dropzoneImportElement = node;
                      }}
                      inputProps={{required: treeObject}}
                      className={BUtils.joinClasses(stylesSCTree['node-import-container__dropzone-element'], 'area-dropzone')}
                      onDropAccepted={(acceptedFiles) => {
                          document.getElementById('import-title-missing-info').innerHTML = '';
                          document.getElementById('bt-import-footer').style.visibility = 'hidden';
                          this._resetMultimediaResources();

                          BUtils.convertHeadedCsvToJson(acceptedFiles[0], function (result) {
                              var importStructure = this.state.importStructure;
                              importStructure.items = result;
                              this.setState({importStructure}, function () {
                                  this._prepareImport();
                              }.bind(this));
                          }.bind(this));
                      }}
                      onDropRejected={function () {
                          Notifications.showErrorModalMessage('DROPPED_FILE_NOT_ACCEPTED', 3000);
                      }}>
                <p>{'.csv'}</p>
            </Dropzone>
            <Row id={"import-tree-object-fields-info"} className={stylesSCTree['node-import__tree-import-info']}/>
            <Row id={"import-csv-item-fields-info"} className={stylesSCTree['node-import__tree-import-info']}/>
            <Row id={"import-matching-info"} className={stylesSCTree['node-import__tree-import-info']}/>
            <Row id={"import-title-missing-info"} className={stylesSCTree['node-import__tree-import-info']}/>
            <Row id={"import-footer"}
                 className={BUtils.joinClasses(stylesSCTree['node-import__tree-import-info'], 'tree-import-info-headerfooter')}>
                <Button id={"bt-import-footer"} bsStyle={'primary'}
                        onClick={() => this._importData()}>{Dict.t('IMPORT_LOG')}</Button>
                <Button id={"bt-import-multimedia-footer"} bsStyle={'primary'}
                        onClick={() => this._openImportMultimediaResourceDropzone()}>{Dict.t('IMPORT_MULTIMEDIA_RESOURCE')}</Button>
            </Row>
        </div>, ele, function () {
            //var reactTree = document.getElementsByClassName('react-tree')[0];
            /*if ((parseFloat(ele.offsetHeight) + parseFloat(ele.style.top)) > reactTree.offsetHeight) {
                document.getElementById(ele.id).style.top = 150 + "px";
                document.getElementById('tree-object-element-container').style.maxHeight = (document.getElementsByClassName('react-tree')[0].offsetHeight - 200) + "px";
            }*/
        });
    }

    _convertCsvToJson(file, callback) {
        var csvSeparator = '\t';
        var reader = new FileReader();
        reader.onload = function (event) {
            var lines = event.target.result.split("\n");

            var result = [];

            var headers = lines[0].split(csvSeparator);
            var cleanHeaders = [];

            for (var k = headers.length - 1; k >= 0; k--) {
                if (headers[k].length > 0 && headers[k] !== '\r') {
                    var cleanHeadersTmp = headers.slice(0, k);
                    cleanHeadersTmp.forEach(header => {
                        if (header.trim().length > 0) {
                            cleanHeaders.push(header);
                        } else {
                            cleanHeaders.push('[' + Dict.t('EMPTY_FIELD').toUpperCase() + ']');
                        }
                    });
                    break;
                }
            }

            for (var i = 1; i < lines.length; i++) {
                if (lines[i].trim().length > 0) {
                    var obj = {};
                    var currentline = lines[i].split(csvSeparator);

                    for (var j = 0; j < cleanHeaders.length; j++) {
                        obj[cleanHeaders[j]] = currentline[j];
                    }

                    result.push(obj);
                }
            }

            callback(result);
        };
        reader.readAsText(file, 'utf-8');
    }

    _resetImportStructure() {
        this._setImportStructure();
    }

    _setImportStructure(node, treeObject, items, info, prefix, listFiles) {
        node = typeof node === 'undefined' ? {} : node;
        treeObject = typeof treeObject === 'undefined' ? {} : treeObject;
        items = typeof items === 'undefined' ? [] : items;
        info = typeof info === 'undefined' ? this._getResetImportStructureInfos() : info;
        prefix = typeof prefix === 'undefined' ? '' : prefix;
        listFiles = typeof listFiles === 'undefined' ? [] : listFiles;

        var importStructure = {node, treeObject, items, info, prefix, listFiles};
        this.setState({importStructure});
    }

    _getResetImportStructureInfos() {
        document.querySelectorAll("." + stylesSCTree['node-import__tree-import-info']).forEach(function (element) {
            element.classList.remove(stylesSCTree['node-import__tree-import-info--active']);
        });

        return {
            treeObjectFields: '',
            csvItemFields: '',
            matchingFields: '',
            log: {dataNotAccepted: []},
            listFiles: [],
            importItems: []
        };
    }

    _prepareImport() {

        var importStructure = this.state.importStructure;

        const title = Dict.t('TITLE');
        importStructure.info = this._getResetImportStructureInfos();
        importStructure.info.csvItemFields = Object.keys(importStructure.items[0]);
        var hasTitle = Object.keys(importStructure.items[0])[0].toLowerCase() === title.toLowerCase();
        var treeObjectFieldsInfo;

        var label = '';
        if (importStructure.treeObject.isBasic) {
            label = Dict.t(importStructure.treeObject.element.label);
            importStructure.info.treeObjectFields = [label];
            treeObjectFieldsInfo = "<u>" + label + "</u>";
            importStructure.info.matchingFields = Object.keys(importStructure.items[0]).indexOf(label) > -1 ? label : [];
        } else if (importStructure.treeObject.isContainerItem || TreeNodeHelper.isSubContainerItem(importStructure.treeObject.code)) {
            label = Dict.t(importStructure.treeObject.code);
            importStructure.info.treeObjectFields = [label];
            treeObjectFieldsInfo = "<u>" + label + "</u>";

            importStructure.info.matchingFields = importStructure.treeObject.basicItems.map(item => Dict.t(item.label)).filter(function (n) {
                return Object.keys(importStructure.items[0]).indexOf(n) > -1;
            });

        } else {
            label = importStructure.treeObject.code;
            importStructure.info.treeObjectFields = importStructure.treeObject.basicItems.map(item => item.i18nLabels.it);
            treeObjectFieldsInfo = importStructure.info.treeObjectFields.map(item => "<u>" + item + "</u>").join(", ");

            importStructure.info.matchingFields = importStructure.treeObject.basicItems.map(item => item.i18nLabels.it).filter(function (n) {
                return Object.keys(importStructure.items[0]).indexOf(n) > -1;
            });
        }

        if (hasTitle) {
            importStructure.info.csvItemFields = Object.keys(importStructure.items[0]).slice(1, Object.keys(importStructure.items[0]).length);
            document.getElementById('bt-import-footer').style.visibility = 'visible';
            importStructure.info.hasTitle = true;
        } else {
            document.getElementById('import-title-missing-info').innerHTML = '<div><span>' + Dict.t('WARNING_IMPORT_COLUMN_TITLE_MISSING') + '</span><input id="import-title-missing-info-input-prefix" onkeyup="document.getElementById(\'bt-import-footer\').style.visibility = (this.value.length > 0 ? \'visible\' : \'hidden\');" type="text" value="" /></div>';
            importStructure.info.hasTitle = false;
        }

        var csvItemFieldsInfo = importStructure.info.csvItemFields.map(item => "<u>" + item + "</u>").join(", ");
        var matchingInfo = importStructure.info.matchingFields.length > 0 ? importStructure.info.matchingFields.map(item => "<u>" + item + "</u>").join(", ") : Dict.t('MATCHING_NOT_FOUND');

        document.getElementById('import-tree-object-fields-info').innerHTML = '<strong>' + Dict.t('ELEMENT_FIELDS') + ' ' + label + '</strong>: ' + treeObjectFieldsInfo;
        document.getElementById('import-csv-item-fields-info').innerHTML = '<strong>' + Dict.t('CSV_FIELDS') + '</strong>: ' + csvItemFieldsInfo;
        document.getElementById('import-matching-info').innerHTML = '<strong>' + Dict.t('MATCHING_FIELDS') + '</strong>: ' + matchingInfo;

        document.querySelectorAll("." + stylesSCTree['node-import__tree-import-info']).forEach(function (element) {
            element.classList.add(stylesSCTree['node-import__tree-import-info--active']);
        });

        Dict.call('/tree/listImportResourceFiles/').then(result => {
            if (result.listFiles.length > 0) {
                importStructure.info.listFiles = result.listFiles.map(function (file, index) {
                    var extension = BUtils.extractFileExtension(file.filename);

                    var type;
                    try {
                        type = BUtils.getFileType(file.filename);
                    } catch (e) {
                        type = 'NOT_FOUND';
                        Notifications.showWarningModalMessage('ignore:' + e, 2000);
                    }

                    return {
                        filename: BUtils.extractFilename(file.filename.substring(0, file.filename.length - extension.length - 1)),
                        extension,
                        type,
                        valid: false
                    };
                });

                this.setState({importStructure});

                document.getElementById('info-node-import-log-footer').style.display = 'inline';
            } else
                document.getElementById('info-node-import-log-footer').style.display = 'none';
        });
    }

    _importData() {
        var importStructure = this.state.importStructure;
        var prefix = importStructure.info.hasTitle ? '' : document.getElementById('import-title-missing-info-input-prefix').value;
        importStructure.info.log.dataNotAccepted = [];
        importStructure.importItems = [];
        const isSubItem = TreeNodeHelper.isSubContainerItem(importStructure.treeObject.code);
        const isContainer = importStructure.treeObject.isContainerItem;

        if (isSubItem) {
            importStructure.items = importStructure.items.sort((itemA, itemB) => {
                var fieldToSort = importStructure.treeObject.basicItems.find((basicItem) => {
                    return basicItem.isImportSortBy;
                })

                fieldToSort = Dict.t(fieldToSort.label);

                return itemA[fieldToSort] - itemB[fieldToSort];
            })
        }

        var indexItem = 0;
        importStructure.items.map((item) => {
            var propAsArrays = Object.keys(item);
            var title = importStructure.info.hasTitle && item[propAsArrays[0]].trim().length > 0 ? item[propAsArrays[0]] : prefix + "_" + new Date().getTime() + "_" + indexItem;
            title = title.trim();
            var data = [];

            var asArray = Object.keys(item);
            var undFieldsLength = asArray.filter(field => {
                return typeof item[field] === 'undefined' || item[field] === '' || item[field] === '\r';
            }).length;

            if (parseInt(100 * undFieldsLength / asArray.length) > 85) {
                this._addImportdataNotAcceptedLog(indexItem, item, Dict.t('TREE_IMPORT_SUSPECT_ITEM'), title);
            } else {

                importStructure.treeObject.basicItems.map((basicItem) => {
                    var element = {
                        label: isContainer || isSubItem ? basicItem.label : basicItem.i18nLabels[this.props.interfaceLang],
                        type: isContainer || isSubItem ? basicItem.type : basicItem.item.element.type,
                        code: isContainer || isSubItem ? basicItem.code : basicItem.item.code,
                        value: ''
                    };

                    if (element.type === 'autocomplete') {
                        if (element.code === 'CUSTOM_DATA_COLLECTION') {
                            element.items = basicItem.item.element.items;
                        } else if (element.code === 'PREFILLED_DATA_COLLECTION') {
                            element.collectionId = basicItem.item.element.collectionId;
                            element.items = [];
                            var composedCollections = this.state.composedCollections;
                            var collection = composedCollections.find((compItem) => {
                                return compItem.id === element.collectionId;
                            });
                            if (typeof collection !== 'undefined') {
                                element.items = JSON.parse(JSON.stringify(collection.items));
                            }
                        } else {
                            element.items = [];
                        }
                    }

                    var titolo = Dict.t('TITLE');
                    titolo = titolo.toLowerCase().trim(); // (titolo.charAt(0).toUpperCase() + titolo.substr(1)).trim();

                    importStructure.info.matchingFields.forEach(function (matchingField) {
                        var itemMatching = item[matchingField];
                        const isItemDefined = typeof itemMatching !== 'undefined';
                        const labelToCompare = isContainer || isSubItem ? Dict.t(element.label) : element.label;

                        if (labelToCompare === matchingField) {
                            if (element.type === "datepicker") {
                                var date = isItemDefined ? TreeUtilities.getDateByValue(itemMatching.trim()) : '';
                                if (date.length === 0) {
                                    element.value = "";
                                    this._addImportdataNotAcceptedLog(indexItem, item, Dict.t('TREE_IMPORT_NOT_VALID_DATE'), element.label + ' ' + '[gg-mm-aaaa]');
                                } else {
                                    element.value = date.date;
                                    element.choosenMode = date.choosenMode;
                                }
                            } else if (element.type === 'autocomplete') {
                                var label = item[matchingField];
                                var autoCompleteItem = TreeNodeHelper.getAutoCompleteItemByLabel(element.items, label, false);
                                element.itemIdSelected = typeof autoCompleteItem === 'undefined' ? undefined : autoCompleteItem.id;
                                if (typeof element.itemIdSelected === 'undefined') {
                                    this._addImportdataNotAcceptedLog(indexItem, item, Dict.t('TREE_IMPORT_AUTOCOMPLETE_LOG_ERROR'), element.label);
                                }
                            } else if (element.type === 'dropzone') {
                                var value = [];

                                var itemMatchingFiles = itemMatching.split(';');

                                itemMatchingFiles.forEach((itemMatchingFile, indexItemMatchingFile) => {
                                    var resourceFound = importStructure.info.listFiles.length > 0 && importStructure.info.listFiles.some((file => {
                                        if ((file.filename + "." + file.extension === itemMatchingFile.trim()) && (typeof item[titolo] !== 'undefined')) {
                                            file.valid = true;
                                            file.title = item[titolo];
                                            value.push({filename: file.filename, extension: file.extension});
                                            return true;
                                        } else
                                            return false;
                                    }));

                                    if (resourceFound) {
                                        element.value = value;
                                    } else {
                                        if (indexItemMatchingFile > 0)
                                            indexItem++;
                                        var msg = typeof item[titolo] == 'undefined' ? Dict.t('TREE_IMPORT_RESOURCE_TITLE_NOT_MACHING') : Dict.t('TREE_IMPORT_RESOURCE_NOT_FOUND');

                                        this._addImportdataNotAcceptedLog(indexItem, item, msg, element.label + ':' + itemMatchingFile.trim());
                                    }
                                });
                            } else if (element.type === 'pathbrowser') {
                                var path = isItemDefined ? itemMatching.trim() : '';
                                if (path.length > 0) {
                                    var encodedFolderPath = LexemeUtilities.encodeUrl(AdministrationUtilities.encodeOSPath(path));
                                    element.value = {path, showContents: false, isDirectory: false};
                                    Dict.call('/tree/checkIsDirectory/' + encodedFolderPath + '/').then(result => {
                                        element.value = {path, showContents: false, isDirectory: result.isDirectory};
                                    }).catch(function (e) {
                                        console.log(e); // "oh, no!"
                                    });
                                } else {
                                    this._addImportdataNotAcceptedLog(indexItem, item, Dict.t('TREE_IMPORT_CHECK_PATH_LOG_ERROR'), element.label);
                                }
                            } else {
                                element.value = isItemDefined ? itemMatching.trim() : '';
                            }
                        }
                    }.bind(this));

                    data.push(element);
                });

                var parent = '';
                if (isSubItem) {
                    if (item['titolo container'].startsWith('2010/3 (Setember)'))
                        console.log();

                    parent = importStructure.node.children.find((child) => {
                        return item['titolo container'] === child.title;
                    })
                } else {
                    parent = importStructure.node;
                }

                if (isSubItem && typeof parent === 'undefined') {
                    this._addImportdataNotAcceptedLog(indexItem, item, Dict.t('PARENT_TITLE_MATCHING_NOT_FOUND'), "");
                } else {
                    var newItem = NodeOperations.createNewItem(parent, title, importStructure.treeObject.code, data);
                    importStructure.importItems.push(newItem);

                    TreeUtilities.checkSubContainerAndAddContainerData(newItem, parent);
                    console.log()
                }

                this.setState({importStructure});
                indexItem++;
            }
        });

        ReactDOM.render(
            <div className={stylesSCTree['node-import-log-container']}
                 dangerouslySetInnerHTML={{__html: importStructure.info.log.dataNotAccepted.join('')}}/>, document.getElementById('import-tree-object-log-contents'), function () {
                document.getElementById('import-tree-multimedia-dropzone').style.display = 'none';
                document.getElementById("import-tree-object-log").style.display = 'block';
            });
    }

    _openImportMultimediaResourceDropzone() {
        var display = document.getElementById('import-tree-multimedia-dropzone').style.display;
        if (display === 'none' || display === '') {
            //TreeUtilities.emptyImportMultimediaResources();
            document.getElementById("import-tree-object-log").style.display = 'none';
            document.getElementById('import-tree-multimedia-dropzone').style.display = 'block';
            /*document.getElementById('import-tree-object-import-resource-contents').innerHTML = '';*/
            document.getElementById('bt-import-footer').disabled = true;
            document.getElementById('bt-import-multimedia-footer').disabled = true;
        }
    }

    _resetWrongColumns() {

    }

    _resetMultimediaResources() {
        /*TreeUtilities.emptyImportMultimediaResources();
        document.getElementById('import-tree-object-import-resource-contents').innerHTML = '';
        this._closeResourceDropZone();*/
        this._closeResourceDropZone();
        this._emptyResourceDropZone();
        document.getElementById("import-tree-object-log").style.display = 'none';
    }

    _closeResourceDropZone() {
        document.getElementById('import-tree-multimedia-dropzone').style.display = 'none';
        document.getElementById('bt-import-footer').disabled = false;
        document.getElementById('bt-import-multimedia-footer').disabled = false;
    }

    _emptyResourceDropZone() {
        TreeUtilities.emptyImportMultimediaResources();
        document.getElementById('import-tree-object-import-resource-contents').innerHTML = '';
    }

    _addImportdataNotAcceptedLog(indexItem, item, msg, contents) {
        var importStructure = this.state.importStructure;
        var titolo = Dict.t('TITLE');
        titolo = titolo.charAt(0).toUpperCase() + titolo.substr(1);
        var itemTitle = (importStructure.info.hasTitle ? item[titolo] : '');
        var rightArrow = '&xrArr;';
        var open = msg === Dict.t('TREE_IMPORT_SUSPECT_ITEM') ? '<div style="color:red" >' : '<div>';

        var element = open + (indexItem + 1) + '- ' + '<strong> ' + itemTitle + ' </strong> - <strong> ' + contents + ' </strong>' + rightArrow + ' ' + msg + ' </div>';

        importStructure.info.log.dataNotAccepted.push(element);
    }

    _acceptImportData(expander) {
        var validFiles = this.state.importStructure.info.listFiles.filter(file => {
            return file.valid;
        })

        this.props.callbackImport(this.state.importStructure.importItems, validFiles, this.state.importStructure.info.listFilesexpander);
    }

    _handleOpenViewer(event, item) {
        var element = TreeDOMHelper.resetNodePageContainer(event, "node-page");
        TreeDOMHelper.hideNodePageContainer();

        ReactDOM.unmountComponentAtNode(element);

        setTimeout(function () {

            var retrieveItem = new Promise((resolve) => {
                if (item.data === '')
                    return resolve(Dict.call('/tree/getItemDataByKey/' + this.props.projectName + '/' + item.key + '/'));
                else
                    return resolve(item.data);
            });

            retrieveItem.then((data) => {
                this.setState({data}, () => {
                    item.data = data;
                    ReactDOM.render(<TreeNodeEditor item={item}
                                                    numbering={NodeOperations.buildNumbering(this.props.treeStructure, item)}
                                                    isBasicItem={TreeNodeHelper.isBasicItem(item.code)}
                                                    projectName={this.props.projectName}
                                                    interfaceLang={this.props.interfaceLang}
                                                    treeStructure={this.props.treeStructure}
                                                    composedTreeItems={this.props.composedTreeItems}
                                                    isViewer={true}
                                                    itemsToIcon={this.props.itemsToIcon}
                                                    autoincrement={this.props.autoincrement}
                                                    callbackOpenEditItem={(event, item) => {
                                                        this._handleOpenEditItem(event, item);
                                                    }}
                                                    callbackOpenTreeLinks={(event, ids) => {
                                                        event.persist();
                                                        var treelinkPage = document.getElementById('node-treelink-page');//TreeDOMHelper.resetNodeTreeLinkContainer(event);

                                                        if (treelinkPage.classList.contains(stylesSCTree[treelinkPage.id + "--active"])) {
                                                            TreeDOMHelper.setElementToInActive(treelinkPage);
                                                            ReactDOM.unmountComponentAtNode(treelinkPage);
                                                        } else {
                                                            NodeOperations.getItemById(this.props.projectName, ids).then((item) => {
                                                                const ele = <TreeNodeEditor item={item}
                                                                                            numbering={NodeOperations.buildNumbering(this.props.treeStructure, item)}
                                                                                            isBasicItem={TreeNodeHelper.isBasicItem(item.code)}
                                                                                            projectName={this.props.projectName}
                                                                                            treeStructure={this.props.treeStructure}
                                                                                            interfaceLang={this.props.interfaceLang}
                                                                                            composedTreeItems={this.props.composedTreeItems}
                                                                                            isViewer={true}
                                                                                            isTreeLink={true}
                                                                                            autoincrement={this.props.autoincrement}
                                                                />;

                                                                TreeDOMHelper.setElementToActive(treelinkPage);
                                                                ReactDOM.render(ele, treelinkPage);
                                                                TreeDOMHelper.resetNodeTreeLinkContainer(event);
                                                            });
                                                        }
                                                    }
                                                    }/>, element, function () {
                        TreeDOMHelper.setElementToActive(element);
                        var reactTree = document.getElementsByClassName('react-tree')[0];
                        if ((parseFloat(element.offsetHeight) + parseFloat(element.style.top.replace('px', ''))) > reactTree.offsetHeight) {
                            document.getElementById('node-page').style.top = 150 + "px";
                            var setHeight = (document.getElementsByClassName('react-tree')[0].offsetHeight - 200) + "px";
                            document.querySelectorAll('.' + stylesSCTree['tree-object-element-container--overflow']).forEach(function (elem) {
                                elem.style.maxHeight = setHeight;
                            });
                        }
                        setTimeout(function () {
                            TreeDOMHelper.setOpenItemSkin();
                        }, 0);
                    });
                })
            })
        }.bind(this), 60);
    }

    _handleDoubleClick(evt, parentElement, item) {
        this._handleOpenViewer(evt, item);
    }

    _getIconByCode(code) {
        return this.props.itemsToIcon[code];
    }

    _handleRemoveItem(item, hasResourceFolders) {
        var removePromise = new Promise((resolve) => {
            if (hasResourceFolders) {
                return resolve(Dict.call('/tree/deleteMultimediaItem/' + this.props.projectName + '/' + item.key + '/'))
            } else {
                resolve();
            }
        });

        removePromise.then(() => {
            this.props.callbackQuickSave();
            this.props.callbackRemove();
        });

        TreeDOMHelper.hideNodePageContainer();
    }

    _toggleSelectItem(evt, item) {
        var parentElement = evt.target;
        while (!parentElement.classList.contains('react-tree-item-label')) {
            parentElement = parentElement.parentElement;
        }
        TreeDOMHelper.resetItemSelection(parentElement);
        const clientY = evt.clientY;

        // unico modo per far evidenziare la riga di selezione con tasto dx, sembra che il contextmenuprovider intercetti l'evento oncontextmenu
        if (evt.button === 2 && (this.isModifierOrAdministrator || this.isInserter)) {
            this.setState({showContextMenu: true}, () => {
                setTimeout(() => {
                    TreeDOMHelper.hideNodePageContainer();
                    parentElement.classList.add('react-tree-item-label-active');

                    //var containerItems = TreeNodeHelper.treeContainersItems.length;
                    var basicItems = TreeNodeHelper.treeBasicItems.length;
                    var composedItems = this.props.composedTreeItems.length;
                    var totLength = (parseFloat(basicItems) + parseFloat(composedItems)) * 31;

                    if (item.code === 'FOLDER' && (window.innerHeight - clientY < totLength)) {
                        setTimeout(function () {
                            document.getElementsByClassName('react-contexify__submenu')[0].style.top = '-' + (totLength - 300) + 'px';
                            document.getElementsByClassName('react-contexify__submenu')[1].style.top = '-' + (totLength - 300) + 'px';
                        }.bind(this), 100);
                    }
                })
            }, 1000);
        } else {
            parentElement.classList.add('react-tree-item-label-active');

            if (item.code === 'FOLDER') {
                const threshold = 350;
                if (this.firstClick > 0) {
                    if ((new Date().getTime() - this.firstClick) < threshold)
                        this._handleDoubleClick(evt, parentElement, item);
                    this.firstClick = 0;
                } else {
                    this.firstClick = new Date().getTime();
                    setTimeout(function () {
                        this.firstClick = 0;
                    }.bind(this), threshold);

                }
            } else {
                this._handleDoubleClick(evt, parentElement, item);
            }

            /*location.hash = 'item-click-' + item.key;*/
            const el = document.getElementById('item-click-' + item.key);
            var parent = el.parentNode.parentNode;
            parent.parentNode.parentNode.scrollIntoView({block: "start", behavior: "smooth"});
        }
    }


    render() {

        var item = this.props.item;
        const isItemToPaste = item === this.props.itemsToPaste;

        const projectName = this.props.projectName;
        const showMenu = this.isLogged && this.state.showContextMenu;

        const isHidden = typeof item.hidden !== 'undefined' && item.hidden;
        const isHiddenBranch = typeof item.hiddenBranch !== 'undefined' && item.hiddenBranch;
        const isHiddenValue = isHidden ? 'hide' : 'show';
        const isFolder = item.code === 'FOLDER';

        const showHidePostdix = isFolder ? '_BRANCH' : '';
        const hideShowCheck = isFolder ? isHiddenBranch : isHidden;
        const i18nHideShow = (hideShowCheck ? 'SHOW' : 'HIDE') + showHidePostdix;

        var hideShowValid = true;
        var hasChildren = false;
        var hasItemToPaste = false;
        var hasLoadedChildren = false;

        if (showMenu && isFolder) {
            if (isHiddenBranch) {
                var parentKey = NodeOperations.retrieveParentKey(item);
                var parent = document.getElementById('item-click-' + parentKey);
                if (parent !== null && parent.classList.contains(stylesSCTree['react-tree-branch-item--true'])) {
                    hideShowValid = false;
                }
            }

            hasChildren = isFolder && !item.isLeaf;
            hasLoadedChildren = hasChildren && typeof item.children !== 'undefined';
            hasItemToPaste = typeof this.props.itemsToPaste !== 'undefined' && Object.keys(this.props.itemsToPaste).length > 0;
        }


        return <div id={'item-click-' + item.key} ancestors={item.ancestors}
                    className={BUtils.joinClasses(stylesSCTree['react-tree-item--' + isHiddenValue], stylesSCTree['react-tree-branch-item--' + isHiddenBranch])}
                    onMouseUp={(evt) => this._toggleSelectItem(evt, item)}>
            <FontAwesome className={stylesSCTree['tree-node-fa']} name={this._getIconByCode(item.code)}/>
            {showMenu ? <>
                <ContextMenuProvider id={"sc-tree-node-context-menu-" + item.key} component="span"
                                     className={BUtils.joinClasses(stylesSCTree['tree_main_skin'], stylesSCTree['tree-node-context-menu'], stylesSCTree['tree-node-context-menu--' + (isFolder ? 'pure-node' : 'not-node')])}>
                    {item.title}
                </ContextMenuProvider>
            </> : <div
                className={BUtils.joinClasses(stylesSCTree['tree-node-context-menu'], stylesSCTree['tree-node-context-menu--' + (isFolder ? 'pure-node' : 'not-node')])}>{item.title}</div>}
            {showMenu && (this.isInserter || this.isModifierOrAdministrator) ?
                <ContextMenu id={"sc-tree-node-context-menu-" + item.key} animation={"pop"}
                >
                    {typeof item.code !== 'undefined' && item.code === 'FOLDER' ?
                        <Submenu label={Dict.t('ADD')}
                                 className={"submenu-will-overflow-" + this.state.subMenuWillOverflow}>
                            <Item
                                onClick={({event, ref, data, dataFromProvider}) => this._handleOpenContextMenuItem(event, TreeNodeHelper.treeNodeOperations.ADD, item)}><FontAwesome
                                className={"context-menu-fa"} name={"folder-open"}/>{Dict.t('FOLDER')}
                            </Item>
                            {TreeNodeHelper.treeContainersItems.map((tebItem) => {
                                var propLabel = TreeNodeHelper.getPropLabel(tebItem)
                                return <Item className="context-add-item" key={"key-contextmenu-" + tebItem.code}
                                             onClick={({event, ref, data, dataFromProvider}) => this._handleOpenContextMenuItem(event, TreeNodeHelper.treeNodeOperations.ADD, item, tebItem, true)}>
                                    <FontAwesome className={"context-menu-fa"}
                                                 name={tebItem.icon}/>{propLabel}
                                </Item>
                            })}
                            {<Separator/>}
                            {TreeNodeHelper.treeBasicItems.map((tebItem) => {
                                return <Item className="context-add-item" key={"key-contextmenu-" + tebItem.code}
                                             onClick={({event, ref, data, dataFromProvider}) => this._handleOpenContextMenuItem(event, TreeNodeHelper.treeNodeOperations.ADD, item, tebItem, true)}>
                                    <FontAwesome className={"context-menu-fa"}
                                                 name={tebItem.icon}/>{Dict.t(tebItem.code)}
                                </Item>
                            })}
                            {<Separator/>}
                            {this.props.composedTreeItems.map((ctiItem) => {
                                return <Item className="context-add-item" key={"key-contextmenu-" + ctiItem.code}
                                             onClick={({event, ref, data, dataFromProvider}) => this._handleOpenContextMenuItem(event, TreeNodeHelper.treeNodeOperations.ADD, item, ctiItem, false)}>
                                    <FontAwesome className={"context-menu-fa"}
                                                 name={ctiItem.icon}/>{ctiItem.code}
                                </Item>
                            })}
                        </Submenu> : ""}
                    {typeof item.code !== 'undefined' && item.code === 'FOLDER' ?
                        <Submenu label={Dict.t('IMPORT')}
                                 className={"submenu-will-overflow-" + this.state.subMenuWillOverflow}>
                            {TreeNodeHelper.treeContainersItems.map((tebContainerItem) => {
                                var containersAndSubItems = [tebContainerItem].concat(tebContainerItem.subItems);
                                return containersAndSubItems.map((tebItem, index) => {
                                    return <Item className="context-add-item" key={"key-contextmenu-" + tebItem.code}
                                                 onClick={({event, ref, data, dataFromProvider}) => this._handleOpenImportItem(event, TreeNodeHelper.treeNodeOperations.IMPORT, item, tebItem, true, index > 0)}>
                                        <FontAwesome className={"context-menu-fa"}
                                                     name={tebItem.icon}/>{TreeNodeHelper.getPropLabel(tebItem) + (index > 0 ? ' ➠ ' + TreeNodeHelper.getPropLabel(containersAndSubItems[0]) : '')}
                                    </Item>
                                })
                            })}
                            {<Separator/>}
                            {TreeNodeHelper.treeBasicItems.filter((tebItem) => {
                                return tebItem.element.type !== 'dropzone';
                            }).map((tebItem) => {
                                return <Item className="context-add-item" key={"key-contextmenu-" + tebItem.code}
                                             onClick={({event, ref, data, dataFromProvider}) => this._handleOpenImportItem(event, TreeNodeHelper.treeNodeOperations.IMPORT, item, tebItem, true)}>
                                    <FontAwesome className={"context-menu-fa"}
                                                 name={tebItem.icon}/>{Dict.t(tebItem.code)}
                                </Item>
                            })}
                            {<Separator/>}
                            {this.props.composedTreeItems.map((ctiItem) => {
                                return <Item className="context-add-item" key={"key-contextmenu-" + ctiItem.code}
                                             onClick={({event, ref, data, dataFromProvider}) => this._handleOpenImportItem(event, TreeNodeHelper.treeNodeOperations.IMPORT, item, ctiItem, false)}>
                                    <FontAwesome className={"context-menu-fa"}
                                                 name={ctiItem.icon}/>{ctiItem.code}
                                </Item>
                            })}
                        </Submenu> : ""}
                    {this.isModifierOrAdministrator ? <Item // EDITA
                        onClick={({event, ref, data, dataFromProvider}) => this._handleOpenEditItem(event, item)}>{Dict.t('EDIT')}
                    </Item> : ''}
                    {this.isModifierOrAdministrator && this.isComponentContainer ? <Item // EDITA
                        onClick={({event, ref, data, dataFromProvider}) => this._handleAddBookmark(event, item)}>{Dict.t('ADD') + ' ' + TreeNodeHelper.getPropLabel(TreeNodeHelper.getSubItemByCode('BOOKMARK'))}
                    </Item> : ''}
                    {this.isModifierOrAdministrator ? <Separator/> : ''}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator && !isFolder ? <Item // TAGLIA
                        onClick={({event, ref, data, dataFromProvider}) => this._handleCopyItems(item, false)}>{Dict.t('COPY')}
                    </Item> : ''}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator && hasChildren ? <Item // TAGLIA
                        onClick={({event, ref, data, dataFromProvider}) => this._handleCopyItems(item, true)}>{Dict.t('COPY_CONTENTS')}
                    </Item> : ''}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator ? <Item // TAGLIA
                        onClick={({event, ref, data, dataFromProvider}) => this._handleCutItems(item, false)}>{Dict.t('CUT')}
                    </Item> : ''}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator && hasChildren ? <Item // TAGLIA
                        onClick={({event, ref, data, dataFromProvider}) => this._handleCutItems(item, true)}>{Dict.t('CUT_CONTENTS')}
                    </Item> : ''}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator && item.code === 'FOLDER' && hasItemToPaste ?
                        <Item // TAGLIA
                            onClick={({event, ref, data, dataFromProvider}) => this._handlePasteItem(item)}>
                            <strong>{Dict.t(this.props.cutPasteType === 'CUT_CHILDREN' || this.props.cutPasteType === 'COPY_CHILDREN' ? 'PASTE_CONTENTS' : 'PASTE')}
                            </strong>
                        </Item> : ''}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator && (typeof this.props.itemsToPaste !== 'undefined' && this.props.itemsToPaste !== '') ?
                        <Item // TAGLIA
                            onClick={({event, ref, data, dataFromProvider}) => this._resetCut()}>{Dict.t('RESET_CUT')}
                        </Item> : ''}
                    {<Separator/>}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator && hideShowValid ?
                        <Item // ORDINA MOSTRA/NASCONDI
                            onClick={({event, ref, data, dataFromProvider}) => this._handleShowHideItem(event, item)}>{Dict.t(i18nHideShow)}
                        </Item> : ''}
                    {typeof item.code !== 'undefined' && hasLoadedChildren && item.code === 'FOLDER' && this.isModifierOrAdministrator ?
                        <Item // ORDINA SORT
                            onClick={({event, ref, data, dataFromProvider}) => this._handleOpenSortItems(event, item.children)}>{Dict.t('SORT')}
                        </Item> : ''}
                    {typeof item.code !== 'undefined' && this.isModifierOrAdministrator && TreeNodeHelper.isRootItem(item) ?
                        <Item // ORDINA SORT RADICI
                            onClick={({event, ref, data, dataFromProvider}) => this._handleOpenSortItems(event, this.props.treeStructure)}>{Dict.t('SORT_ROOTS')}
                        </Item> : ''}
                    {this.isModifierOrAdministrator ? <Item onClick={({event, ref, data, dataFromProvider}) => {   //CANCELLA
                        var retrieveMultimediaDir = new Promise((resolve) => {
                            if (item.code === 'FOLDER') {
                                return resolve(Dict.call('/tree/nodeHasMultimediaDirectories/' + projectName + '/' + item.key + '/'));
                            } else if (typeof item.data !== 'undefined' && item.data !== '') {
                                var count = item.data.filter(dataItem => {
                                    return dataItem.type === 'dropzone';
                                });
                                return resolve({hasMultimediaDirectories: count.length > 0});
                            }
                        });

                        retrieveMultimediaDir.then((res) => {
                            const AlertResources = res.hasMultimediaDirectories ?
                                <span id="span-remove-node-alert"
                                      className={stylesSCTree['div-remove-node__alert']}>[{Dict.t('ALERT_NODE_HAS_RESOURCES')}]</span> : '';

                            TreeDOMHelper.hideNodePageContainer();
                            var ele = TreeDOMHelper.resetNodePageContainer(event, "node-operations-container");
                            const div = <div id="div-remove-node-container"
                                             className={stylesSCTree['div-remove-node']}>
                                <span
                                    className={stylesSCTree['div-remove-node__ask']}>{Dict.t('ASK_DELETE_NODE')}</span>
                                {AlertResources}
                                <button onClick={() => {
                                    this._handleRemoveItem(item, res.hasMultimediaDirectories);
                                }}>{Dict.t('YES')}
                                </button>
                                <span className={stylesSCTree['div-remove-node__separator']}>/</span>
                                <button onClick={() => {
                                    TreeDOMHelper.hideNodePageContainer()
                                }}>{Dict.t('NO')}</button>
                            </div>;

                            ReactDOM.render(div, ele);
                        });
                    }}>{Dict.t('REMOVE')}
                    </Item> : ''}
                    { /*<Separator />*/}
                </ContextMenu> : ''}
            {isItemToPaste ? <FontAwesome name={this.props.cutPasteType === 'CUT_CHILDREN' ? "scissors" : "clone"}
                                          style={{marginLeft: '5px', marginRight: '3px'}}/> : ''}
            {isItemToPaste && this.props.cutPasteType.endsWith('_CHILDREN') ?
                <FontAwesome name={"list"} style={{marginLeft: '1px', marginRight: '3px'}}/> : ''}
            <FontAwesome name={"eye-slash"} className={stylesSCTree['react-tree-item-icon--' + isHiddenValue]}/>
            <FontAwesome id={'loading-key-spin-' + item.key} className={'loading-key-spin'}
                         style={{display: 'none'}} name={'spinner'} spin/>
        </div>;
    }
}


class TreeNodeEditor extends React.Component {

    constructor(props) {
        super(props);

        this.isAdd = this.props.operation === TreeNodeHelper.treeNodeOperations.ADD;
        //this.maxHeight = (document.getElementsByClassName('react-tree')[0].offsetHeight - 200) + "px";
        this.isLogged = userService.isLogged();
        //TODO: per il momento difficilmente manutenibile
        this.isContainerItem = true;//TreeNodeHelper.isContainerItem(this.isAdd && typeof this.props.treeObject !== 'undefined' ? this.props.treeObject.code : this.props.item.code);
        this.isSubContainerItem = false;//TreeNodeHelper.isSubContainerItem(this.isAdd && typeof this.props.treeObject !== 'undefined' ? this.props.treeObject.code : this.props.item.code);
        /*this.isContainer = null;
        this.isSubContainerItem = null;
        if(this.isAdd) {
            this.isContainerItem =
        } else {

        }*/

        //this.isContainerItem = this.isAdd && this.props.treeObject.code === 'BOOKMARK'; // TreeNodeHelper.isContainerItem(this.props.item.code);
        //this.isSubContainerItem = TreeNodeHelper.isSubContainerItem(this.props.item.code);

        this.state = {
            treeStructure: this.props.treeStructure,
            composedCollections: this.props.composedCollections,
            title: this.isAdd ? '' : this.props.item.title,
            code: typeof this.props.treeObject === 'undefined' ? 'FOLDER' : this.props.treeObject.code,
            data: typeof this.props.item.data === 'undefined' ? '' : JSON.parse(JSON.stringify(this.props.item.data)),
            item: JSON.parse(JSON.stringify(this.props.item)),
            anomaly: {hasAnomaly: false, type: ''},
            isRootNode: NodeOperations.retrieveParentKey(this.props.item) == null,
            isBasicItem: this.props.isBasicItem,
            interfaceLang: '',
            isViewer: this.props.isViewer,
            previewCarouselItems: [],
            storedCarouselItems: [],
            toDeleteCarouselItems: [],
            carouselCode: '',
            carouselPosition: 0,
            carouselActiveIndex: 0,
            showTreeCarousel: 'none',
            /*serverUrl: BUtils.getServerUrl(), //Dict.getGlobalProperty('SERVER_URL'),*/
            resourceElementsPosition: [],
            treeLinkOptions: [],
            opening: true,
            previousSibling: '',
            nextSibling: ''
            /*doc: {filename: '', src: '', pageNumber: 1, numbPages: -1}*/
        };

        this.parent = {};

        this._save = this._save.bind(this);
        this._drawTreeElementObject = this._drawTreeElementObject.bind(this);
        this._openEditCarousel = this._openEditCarousel.bind(this);
        this._openCarouselByIndex = this._openCarouselByIndex.bind(this);
        this._retrieveElementEdit = this._retrieveElementEdit.bind(this);
        this._openDocumentPreview = this._openDocumentPreview.bind(this);
    }

    componentWillMount() {

        NodeOperations.retrieveItemData(this.props.projectName, this.props.item).then((data) => {
            var item = this.props.item;
            item.data = data;
            var data = item.data;

            this.setState({item, data}, () => {
                var resourceElementsPosition = [];
                var data = [];

                if (this.isAdd && typeof this.props.treeObject !== 'undefined') {
                    if (this.isContainerItem) {
                        this.props.treeObject.basicItems.map((item, index) => {
                            this._pushBasicDataItemToContainer(item, resourceElementsPosition, data, index);
                        });
                    } else if (this.isSubContainerItem) {
                        this.props.treeObject.basicItems.map((item, index) => {
                            this._pushBasicDataItemToContainer(item, resourceElementsPosition, data, index);
                        });
                    } else if (this.props.isBasicItem) {
                        var element = JSON.parse(JSON.stringify(this.props.treeObject));
                        element.code = this.props.treeObject.code;
                        resourceElementsPosition = this.props.treeObject.element.type === 'dropzone' ? [0] : [];
                        data.push(element);
                    } else {
                        this.props.treeObject.basicItems.map((item, index) => {
                            this._pushBasicDataItem(item, resourceElementsPosition, data, index);
                        });
                    }

                    this.setState({data, resourceElementsPosition});
                } else if (typeof this.props.item.data !== 'undefined' && this.props.item.data.length > 0) {

                    const isComposedTreeItems = TreeNodeHelper.isComposedTreeItems(item.code);

                    const basicItems = isComposedTreeItems ? this.props.composedTreeItems.filter((myItem) => {
                        return myItem.code === item.code;
                    })[0].basicItems : this.props.item.data;

                    var pushIndex = 0;

                    basicItems.forEach((basicItem, index) => {
                        var resourcePositionFound;
                        if (this.props.isBasicItem) {
                            data = this.props.item.data;
                            resourcePositionFound = basicItem.type === 'dropzone';
                        } else if (false && TreeNodeHelper.isContainerItem(item.code)) {
                            item.data.map((item, index) => {
                                this._pushBasicDataItemToContainer(item, resourceElementsPosition, data, index);
                            });
                            resourcePositionFound = resourceElementsPosition.length > 0;
                        } else {
                            data = this.state.data;
                            if (isComposedTreeItems)
                                resourcePositionFound = typeof basicItem.item !== 'undefined' && basicItem.item.element.type === 'dropzone';
                            else
                                resourcePositionFound = typeof basicItem.value !== 'undefined' && basicItem.type === 'dropzone';

                            pushIndex = item.label === 'CONTAINER_TITLE' ? 1 : index;

                            if (this.state.data.filter((item) => {
                                return item.label === (isComposedTreeItems ? basicItem.i18nLabels['it'] : basicItem.label);
                            }).length === 0) {
                                this._pushBasicDataItem(basicItem, resourceElementsPosition, data, pushIndex);
                            }

                            data[index].basicItemHidden = basicItem.hidden === true;
                        }

                        if (resourcePositionFound) {
                            resourceElementsPosition.push(pushIndex);
                        }
                    });
                }

                if(this.isSubContainerItem) {
                    var contTitleIndex = data.findIndex((item) => {
                        return item.label === 'CONTAINER_TITLE';
                    });

                    const pushElement = data[contTitleIndex];
                    data.splice(contTitleIndex, 1)[0];
                    data.splice(1, 0, pushElement);
                }

                this.setState({data, resourceElementsPosition});
            });
        });
    }

    componentDidMount() {

        if (this.props.isViewer) {
            this._dragElement('node-page', 'title-viewer');
        } else {
            const node = ReactDOM.findDOMNode(this.formControl);
            node.value = '';
            node.focus();
            node.value = this.isAdd ? '' : this.state.item.title;

            if (!this.state.isRootNode && !this.props.isViewer) {
                NodeOperations.retrieveParentItem(this.state.treeStructure, this.props.item, (parent) => {
                    this.parent = parent;
                });
            }

            this._dragElement('node-operations-container', 'node-operations-container__header');
        }

        setTimeout(function () {
            TreeDOMHelper.setSkin();
        }, 0);

        NodeOperations.retrieveParentItem(this.props.treeStructure, this.props.item, (parent) => {
            var previousSibling = TreeNodeHelper.getPreviousChild(parent, this.props.item);
            var nextSibling = TreeNodeHelper.getNextChild(parent, this.props.item);

            this.setState({previousSibling, nextSibling});
        });
    }

    _dragElement(elementId, headerId) {
        const elmnt = document.getElementById(elementId);

        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(headerId)) {
            // if present, the header is where you move the DIV from:
            document.getElementById(headerId).onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    _pushBasicDataItemToContainer(item, resourceElementsPosition, data, index) {
        var element = {
            label: item.label,
            type: item.type,
            code: item.code,
            value: ''
        };

        if (item.type === 'dropzone') {
            resourceElementsPosition.push(index);
            element.accept = item.accept;
        }

        data.push(element);
    }

    _pushBasicDataItem(item, resourceElementsPosition, data, index) {
        var element = {
            label: item.i18nLabels[this.props.interfaceLang],
            type: item.item.element.type,
            code: item.item.code,
            value: '',
            basicItemHidden: item.hidden
        };

        if (item.item.element.type === 'dropzone') {
            resourceElementsPosition.push(index);
            element.accept = item.item.element.accept;
        }

        if (item.item.element.type === 'autocomplete' && typeof item.item.element.collectionId !== 'undefined') {
            element.collectionId = item.item.element.collectionId;
        }

        data.push(element);
    }

    _drawTreeElementObject() {
        const isViewer = this.props.isViewer;
        const isEdit = !isViewer;
        const isLogged = this.isLogged;
        return this.state.data.map((dataItem) => {
            var counter = this.state.data.indexOf(dataItem);
            var isHidden = typeof this.state.data[counter] !== 'undefined' && (this.state.data[counter].basicItemHidden || this.state.data[counter].hidden);
            var hasValue = typeof dataItem !== 'undefined' && this._elementHasValue(dataItem);
            var isContainerDataItem = typeof dataItem !== 'undefined' && dataItem.label.startsWith('CONTAINER_');

            var show;
            if (isEdit)
                show = !isContainerDataItem;
            else
                show = hasValue && (isLogged || !isHidden);

            if (show) {
                //if (/*this.isLogged ||*/ !this.props.isViewer || (!isHidden && hasValue)) {
                var id = this.state.item.key + "-" + counter;

                var element = this.props.isViewer ? this._retrieveElementViewer(id, counter) : this._retrieveElementEdit(id, counter);

                var longWidthItems = ['autocomplete', 'pathbrowser', 'dropzone'];
                var longWidthClass = longWidthItems.includes(dataItem.type) ? 'longWidth-item' : '';

                var tovRowClassName = this.props.isViewer ? stylesSCTree['tree-object-viewer'] : '';

                return <Row key={"key-tree-object-row-" + counter}
                            className={BUtils.joinClasses(stylesSCTree['tree-object-row'], stylesSCTree['tree-object-row--' + (this.props.isViewer ? 'viewer' : 'edit')], (this.props.isBasicItem ? (stylesSCTree['tree-object-row--basic-' + dataItem.type]) : ''), tovRowClassName, longWidthClass)}>
                    {element}
                </Row>
            }
        });
    }

    _elementHasValue(dataItem) {
        if (dataItem.type === 'checkbox') {
            return true;
        } else if (dataItem.type === 'autocomplete') {
            return dataItem.itemIdSelected > 0;
        } else if (dataItem.type === 'pathbrowser') {
            return (typeof dataItem.value !== 'undefined' && dataItem.value.path !== '' && typeof dataItem.value.path !== 'undefined');
        } else {
            return typeof dataItem.value !== 'undefined' && Object.keys(dataItem.value).length > 0
        }
    }

    _drawEditGallery() {
        var carouselItemsArray = [];
        var title = "";

        if (this.state.showTreeCarousel === 'stored') {
            carouselItemsArray = this.state.storedCarouselItems;
            title = "STORED_FILE_GALLERY";
        } else if (this.state.showTreeCarousel === 'preview') {
            carouselItemsArray = this.state.previewCarouselItems;
            title = "FILE_PREVIEW_GALLERY";
        } else if (this.state.showTreeCarousel === 'toDelete') {
            carouselItemsArray = this.state.toDeleteCarouselItems;
            title = "FILE_TO_DELETE_GALLERY";
        }

        var data = this.state.data;

        const carouselItems = carouselItemsArray.map((item, index) => {
            var dataCounter = data[this.state.carouselPosition];

            var status = this.state.showTreeCarousel !== 'preview' && typeof dataCounter.value !== 'undefined' ? dataCounter.value[index].status : undefined;

            var Component;
            if (this.state.carouselCode === 'AUDIO_GALLERY') {
                Component = <audio className={"tree-carousel-audio"} src={item} controls="controls"/>;
            } else if (this.state.carouselCode === 'VIDEO_GALLERY') {
                Component = <video className={stylesSCTree['tree-carousel-video']} src={item} controls="controls"/>;
            } else {
                Component = <Image className={"tree-carousel-img"} src={item} responsive/>;
            }

            return <CarouselItem key={"key-carousel-item-" + index}>
                <div
                    className={stylesSCTree['tree-object-viewer__carousel-container'] + ' ' + this.state.showTreeCarousel}>
                    {this.state.showTreeCarousel === 'stored' ?
                        <Row className={stylesSCTree['tree-carousel-row-chk']}>
                            <SCCheckbox label={Dict.t('INTO_DELETE_LIST')}
                                        id={"chk-to-delete-" + index}
                                        checked={typeof status !== 'undefined' && status === 'to_remove'}
                                        callbackParent={(id, newState) => {
                                            var toDeleteCarouselItems = this.state.toDeleteCarouselItems;

                                            if (newState) {
                                                toDeleteCarouselItems.push(item);
                                                dataCounter.value[index].status = "to_remove";
                                            } else {
                                                toDeleteCarouselItems.splice(toDeleteCarouselItems.indexOf(item), 1);
                                                delete dataCounter.value[index].status;
                                            }

                                            this.setState({
                                                toDeleteCarouselItems, data
                                            });
                                        }
                                        }>
                            </SCCheckbox>
                        </Row> : ""}
                    {Component}
                </div>
            </CarouselItem>;
        });

        return <div id={"tree-carousel-drop-container"}
                    className={BUtils.joinClasses(stylesSCTree['tree-carousel-drop-container'], stylesSCTree['tree-carousel-drop-' + this.state.showTreeCarousel])}>
            <Row id={"tree-carousel-bar"} className={stylesSCTree['tree-carousel-drop-container__row']}>
                <span id="tree-carousel-bar-title"
                      className={stylesSCTree['tree-carousel-bar__title']}>{Dict.t(title)}</span>
                <Button className={stylesSCTree['tree-carousel-bar__button']}
                        onClick={(evt) => {
                            this.setState({showTreeCarousel: 'none', activeIndex: 0})
                        }}><FontAwesome name="close"/></Button></Row>
            <Row className={stylesSCTree['tree-carousel-drop-container__row']}>
                <Carousel id={"tree-carousel-drop"} className={stylesSCTree['tree-carousel-drop']}
                          defaultActiveIndex={this.state.activeIndex}
                          slide={true} interval={null} controls={carouselItemsArray.length > 1}
                          indicators={carouselItemsArray.length > 1}>
                    {carouselItems}
                </Carousel>
            </Row>
        </div>
    }

    _buildShiftButton(item, symbol) {
        var shiftButton = '';
        if (typeof item !== 'undefined' && item !== null && item !== '') {
            shiftButton = <Button
                onClick={(evt) => {

                    var evtMouse = document.createEvent("MouseEvents");
                    evtMouse.initEvent("mouseup", true, true);
                    evtMouse.dataTransfer = {shift: true};
                    document.getElementById('item-click-' + item.key).dispatchEvent(evtMouse);

                }}
                bsStyle={"primary"}>{symbol}</Button>
        }

        return shiftButton;
    }

    _drawButtonBar() {

        const nextSibling = this.state.nextSibling;
        var nextButton = this._buildShiftButton(nextSibling, '>>>>');
        const previousSibling = this.state.previousSibling;
        var prevButton = this._buildShiftButton(previousSibling, '<<<<');

        var item = this.state.item;

        return <div id="add-element-buttonbar" className={stylesSCTree['add-element-buttonbar']}>
            {this.props.isViewer ? '' :
                <Button
                    onClick={(evt) => (this.state.resourceElementsPosition).length > 0 ? this._saveResource(item) : this._save(item)}
                    bsStyle={"primary"}>{Dict.t('SAVE')}</Button>}
            {userService.isLogged() && this.props.isViewer ? <Button onClick={(event) => {
                this.props.callbackOpenEditItem(event, item)
            }}>{Dict.t('EDIT')}</Button> : ''}
            {this.props.isViewer ? <>{prevButton}
                {nextButton}</> : ''}
            {this.props.isTreeLink ? '' : <Button onClick={(evt) => TreeDOMHelper.hideNodePageContainer()}
                                                  bsStyle={"primary"}>{Dict.t('CLOSE')}</Button>}
        </div>
    }

    _addAutocompleteCustomItem(data, counter) {
        var dataCounter = data[counter];
        var items = dataCounter.items;

        if (dataCounter.tmpData.addAutocompleteText.trim().length > 0) {
            if (items.filter((item) => {
                return item.label === dataCounter.tmpData.addAutocompleteText;
            }).length > 0) {
                Notifications.showErrorModalMessage("ITEM_ALREADY_EXISTS_IN_COLLECTION", 3000);
            } else {
                dataCounter.tmpData.startInsert = true;
                items.push({
                    id: parseInt(items.length) + 1,
                    label: dataCounter.tmpData.addAutocompleteText
                });

                this.setState({data});
                setTimeout(function () {
                    dataCounter.tmpData.startInsert = 'hidden';
                    dataCounter.tmpData.addAutocompleteText = '';
                    setTimeout(function () {
                        dataCounter.tmpData.startInsert = false;
                        dataCounter.tmpData.addAutocompleteText = '';
                        this.setState({data});
                    }.bind(this), 300);
                    this.setState({data});
                }.bind(this), 300);
            }
        }
    }


    // EDITA ELEMENTO
    _retrieveElementEdit(id, counter) {
        var data = BUtils.cloneJson(this.state.data);
        const type = data[counter].type;
        var value = data[counter].value;
        const isBasicItem = this.state.isBasicItem;
        const isHidden = data[counter].hidden === true;
        const isBasicItemHidden = data[counter].basicItemHidden;

        var retElement = '';
        var colLeft = 2;
        var colRight = 10;
        var additionalColLeft = '';
        var additionalColRight = '';
        var label = '';

        if (type === 'textfield' || type === 'weblink') {
            label = data[counter].label;
            retElement = <InputGroup className={stylesSCTree['teb-' + type]}>
                <FormControl
                    id={id}
                    componentClass="textarea"
                    className={stylesSCTree['teb-textfield__textarea']}
                    aria-label="With textarea"
                    placeholder={''}
                    ref={(input) => {
                        this['formControl-' + id] = input;
                    }}
                    value={value}
                    onChange={(evt) => {
                        data[counter].value = evt.target.value;

                        this.setState({data});
                    }}
                    onKeyDown={() => {
                    }}
                />
            </InputGroup>;
        } else if (type === 'tinymce') {
            label = data[counter].label;
            retElement = <Editor
                id={id}
                initialValue={value}
                init={{
                    plugins: 'link image code sccharmap',
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | sccharmap'
                }}
                onChange={(evt) => {
                    data[counter].value = evt.target.getContent();
                    this.setState({data});
                }}
            />
        } else if (type === 'datepicker') {
            colLeft = 2;
            colRight = 10;
            label = data[counter].label;
            var choosenMode = data[counter].choosenMode;
            if (typeof choosenMode === 'undefined')
                choosenMode = TreeNodeHelper.getBasicItemByCode(data[counter].code).element.mode[0].code;

            var inputType = 'date';
            if (choosenMode === 'mm-yyyy')
                inputType = 'month';
            else if (choosenMode === 'yyyy')
                inputType = 'number';

            var dateValue = TreeUtilities.writeDateStringForInput(value, choosenMode);
            retElement = <div className={stylesSCTree['teb-datepicker']}>
                <Col lg={4} md={4} className={stylesSCTree['teb-datepicker__col-input']}>
                    <input id={id} type={inputType} className={stylesSCTree['teb-datepicker__input']} value={dateValue}
                           onChange={(evt) => {
                               data[counter].value = inputType === 'number' ? evt.target.value : new Date(evt.target.value).toISOString();
                               this.setState({data});
                           }}/>
                </Col>
                <Col lg={8} md={8} className={stylesSCTree['teb-datepicker__col-radio']}>
                    <SCRadiobutton default={choosenMode}
                                   label={Dict.t('FORMAT')}
                                   i18nLabel={true}
                                   id={'teb-datepicker-mode'}
                                   additionalClass={stylesSCTree['teb-datepicker-mode']}
                                   items={TreeNodeHelper.getBasicItemByCode(data[counter].code).element.mode}
                                   bsStyle="default"
                                   callbackParent={(newState) => {
                                       data[counter].choosenMode = newState;
                                       this.setState({data});
                                   }}/>
                </Col>
            </div>;
        } else if (type === 'pdf-page-number') {
            colLeft = 2;
            colRight = 10;
            label = data[counter].label;

            const openViewerDocButtonId = 'open-viewer-doc-' + id;

            retElement = <div key={'open-viewer-doc-key'} className={stylesSCTree['tree-docs-viewer-item']}>
                <input id={id} type={'number'} className={stylesSCTree['teb-datepicker__input']} value={value}
                       onChange={(evt) => {
                           data[counter].value = evt.target.value;
                           this.setState({data});
                       }}/>
                <div className={stylesSCTree['tree-docs-viewer-item__buttons']}
                     style={{verticalAlign: 'top', marginLeft: '3px'}}>
                    <Button id={openViewerDocButtonId}
                            onClick={() => {

                                const isAdd = this.props.operation === 'add';
                                //NodeOperations.retrieveParentItem(this.props.treeStructure, this.props.item, (parent) => {
                                var val = this.props.item.data.find((item) => {
                                    return item.type === (isAdd ? 'dropzone' : type);
                                });

                                var src;
                                if (!isAdd) {
                                    const ancestors = this.props.item.ancestors;
                                    var folder = (ancestors).split("_")[(ancestors).split("_").length - 1];
                                    var item = {};
                                    item.value = [val.resource];
                                    data.folder = folder;
                                    src = TreeUtilities.buildMultimediaSrc(this.props.projectName, val.resource, data);
                                    this._createEditDocumentOnClick(src, openViewerDocButtonId, item.value[0], val.value[0], folder);

                                } else {
                                    src = this._buildSrc(val.value[0], val);
                                    this._createEditDocumentOnClick(src, openViewerDocButtonId, val.value[0], data[counter].value[0], this.props.item.key);
                                }

                                //})
                            }}>{Dict.t('SHOW')}</Button>
                </div>
            </div>;

        } else if (type === 'checkbox') {
            colLeft = 4;
            colRight = 8;
            label = data[counter].label;
            retElement =
                <SCCheckbox id={id} checked={typeof value === 'undefined' ? false : value}
                            additionalClass={stylesSCTree['teb-checkbox']}
                            callbackParent={(id, newState) => {
                                data[counter].value = newState;
                                this.setState({data});
                            }}/>
        } else if (type === 'autocomplete') {
            colLeft = 4;
            colRight = 8;

            if (typeof data[counter].tmpData === 'undefined') {
                data[counter].tmpData = {};
            }

            label = data[counter].label;
            var itemIdSelected = data[counter].itemIdSelected;
            var ref = "single-autocomplete-" + counter;
            var itemSelected;
            var selected = [];
            var startInsert = typeof data[counter].tmpData.startInsert === 'undefined' ? 'not-set' : data[counter].tmpData.startInsert;

            const isCustom = data[counter].code === 'CUSTOM_DATA_COLLECTION';

            if (isCustom) {
                additionalColLeft = stylesSCTree["tree-object-wrapper__col-left-autocomplete"];
                additionalColRight = stylesSCTree["tree-object-wrapper__col-right-autocomplete"];

                if (typeof data[counter].customLabel === 'undefined')
                    data[counter].customLabel = '';
                if (typeof data[counter].items === 'undefined')
                    data[counter].items = [];

                itemSelected = typeof data[counter].itemIdSelected === 'undefined' ? [] : [TreeNodeHelper.getAutoCompleteItemById(data[counter].items, data[counter].itemIdSelected)];

                retElement = <Row>
                    <Col lg={8} md={8} className={stylesSCTree['teb-autocomplete-add']}>
                        <FormControl
                            id={id}
                            type="text"
                            className={stylesSCTree['teb-autocomplete-add__input-custom-label']}
                            placeholder={'etichetta viewer'}
                            ref={(input) => {
                                this['formControl-autocomplete-' + counter] = input;
                            }}
                            value={data[counter].customLabel}
                            onChange={(evt) => {
                                data[counter].customLabel = evt.target.value;
                                this.setState({data});
                            }}
                            /*onKeyDown={() => {
                            }}*/
                        />
                        <input id={id + "-input-data"} type="text"
                               className={BUtils.joinClasses(stylesSCTree['teb-autocomplete-add__input-add'], stylesSCTree['teb-autocomplete-add__input-add--start-insert-' + startInsert])}
                               value={data[counter].tmpData.addAutocompleteText}
                               placeholder="aggiungi alla collezione"
                               onKeyUp={(evt) => {
                                   if (evt.keyCode === 13)
                                       this._addAutocompleteCustomItem(data, counter);
                               }}
                               onChange={(evt) => {
                                   data[counter].tmpData.addAutocompleteText = evt.target.value;
                                   this.setState({data});
                               }}/>
                        <Button className={stylesSCTree['teb-add-autocomplete__button-add']}
                                onClick={(evt) => this._addAutocompleteCustomItem(data, counter)}>>></Button>
                    </Col>
                    <Col lg={4} md={4}>
                        <Typeahead
                            id={'teb-autocomplete-' + data[counter]}
                            ref={(typeahead) => this[ref] = typeahead}
                            clearButton
                            className={BUtils.joinClasses(stylesSCTree['teb-autocomplete'], stylesSCTree['teb-autocomplete--start-insert-' + startInsert])}
                            onChange={item => {
                                if (typeof item[0] === 'undefined')
                                    delete data[counter].itemIdSelected;
                                else
                                    data[counter].itemIdSelected = item[0].id;

                                this.setState({data});
                            }}
                            onSearch={() => {
                                return;
                            }}
                            options={data[counter].items}
                            selected={itemSelected}
                            placeholder={!isCustom && !isBasicItem ? preCollectionLabelType : Dict.t('COLLECTION')}
                        />
                        <Button className={stylesSCTree['teb-add-autocomplete__button-remove']} onClick={() => {
                            data[counter].items.splice(data[counter].items.findIndex(function (item) {
                                return item.id === itemIdSelected;
                            }, 1));
                            delete data[counter].itemIdSelected;
                            this.setState({data}, function () {
                                this[ref].getInstance().clear();
                            }.bind(this));
                        }}>
                            <FontAwesome name={"trash"}/>
                        </Button></Col>
                </Row>;
            } else {
                var preCollectionLabelType = '';
                var postFix = (isBasicItem ? "" : "not") + "basic";

                this.props.composedCollections.some((item) => {
                    if (item.id === data[counter].collectionId) {
                        data[counter].items = item.items;
                        preCollectionLabelType = item.label;
                        selected = [item];
                        return true;
                    }
                });

                itemSelected = typeof data[counter].itemIdSelected === 'undefined' ? [] : [TreeNodeHelper.getAutoCompleteItemById(data[counter].items, data[counter].itemIdSelected)];
                if (typeof itemSelected[0] === 'undefined')
                    itemSelected = [];

                retElement = <div
                    className={BUtils.joinClasses(stylesSCTree['teb-autocomplete-pre'], stylesSCTree['teb-autocomplete-pre-' + postFix])}>
                    {isBasicItem ? <FormControl
                        id={id}
                        type="text"
                        className={stylesSCTree['teb-autocomplete-add__input-custom-label']}
                        placeholder={'etichetta viewer'}
                        ref={(input) => {
                            this['formControl-autocomplete-' + counter] = input;
                        }}
                        value={data[counter].customLabel}
                        onChange={(evt) => {
                            data[counter].customLabel = evt.target.value;
                            this.setState({data});
                        }}
                        onKeyDown={() => {
                        }}
                    /> : ''}
                    {isBasicItem ? <Typeahead
                        clearButton
                        className={stylesSCTree['teb-pre-autocomplete-collections']}
                        onChange={item => {
                            if (item.length > 0) {
                                data[counter].items = item[0].items;
                                data[counter].collectionId = item[0].id;
                            } else {
                                data[counter].items = [];
                                data[counter].collectionId = '';
                                delete data[counter].itemIdSelected;
                            }
                            this.setState({data});
                        }}
                        onSearch={() => {
                            return;
                        }}
                        options={JSON.parse(JSON.stringify(this.state.composedCollections))}
                        placeholder={Dict.t('AVAILABLE_COLLECTIONS')}
                        selected={selected}
                    /> : <label htmlFor={"th-autocomplete-pre-" + postFix}>({preCollectionLabelType})</label>}
                    <Typeahead
                        id={"th-autocomplete-pre-" + postFix}
                        ref={(typeahead) => this[ref] = typeahead}
                        clearButton
                        className={stylesSCTree['teb-pre-autocomplete-selected']}
                        onChange={item => {
                            if (typeof item[0] === 'undefined')
                                delete data[counter].itemIdSelected;
                            else
                                data[counter].itemIdSelected = item[0].id;

                            this.setState({data});
                        }}
                        onSearch={() => {
                            return;
                        }}
                        options={data[counter].items}
                        selected={itemSelected}
                        placeholder={isBasicItem ? Dict.t('COLLECTION') : preCollectionLabelType}
                    />
                </div>;
            }
        } else if (type === 'dropzone') {

            colLeft = 2;
            colRight = 10;

            label = data[counter].label;
            var code = data[counter].code;

            var dropPreviewId = "drop-preview-" + id;
            var resourcePreviewId = "resource-preview-" + id;

            var StoredImages = "";
            var storedCarouselItems = [];
            var toDeleteImages = "";
            var toDeleteCarouselItems = this.state.toDeleteCarouselItems;
            const carouselCode = data[counter].code;

            if (typeof value !== 'undefined' && value.length > 0) {
                if (!Array.isArray(value)) {
                    value = [value];
                }
                StoredImages = value.map((item, index) => {

                    var src = this._buildSrc(item, data[counter]);
                    const id = "stored-" + index;

                    if (code === 'DOCUMENTS') {
                        src = {
                            src: src,
                            filename: item.filename,
                            extension: item.extension,
                            folder: data[counter].folder
                        };
                    }

                    storedCarouselItems.push(src);
                    //if (typeof item.status !== 'undefined' && item.status === 'to_remove')
                    //    toDeleteCarouselItems.push(src);

                    return this._buildComponentSrc(data[counter], src, id, "stored", index);
                });

                toDeleteImages = toDeleteCarouselItems.map((src, index) => {
                    const id = "toDelete-" + index;
                    return this._buildComponentSrc(data[counter], src, id, "toDelete", index);
                });
            }

            retElement = <div id={id}>
                <Col lg={5} md={5}>
                    <Dropzone accept={this.state.data[counter].accept}
                              acceptClassName="accept-drop-zone"
                              ref={(node) => {
                                  this.dropzoneElement = node;
                              }}
                              className={'area-dropzone' + ' ' + stylesSCTree['dropzone-element']}
                              onDrop={acceptedFiles => {

                                  if (typeof data[counter].preview === 'undefined')
                                      data[counter].preview = [];

                                  data[counter].preview.push(acceptedFiles);
                                  var previewCarouselItems = this.state.previewCarouselItems[0];

                                  acceptedFiles.forEach((file, index) => {
                                      if (code === 'DOCUMENTS') {
                                          const dropPreview = document.getElementById(dropPreviewId);
                                          var openViewerDocButtonId = 'doc-accepted-preview-' + (index + dropPreview.children.length);
                                          var a = document.createElement("a");
                                          a.id = openViewerDocButtonId;
                                          a.innerHTML = file.name;
                                          a.className = stylesSCTree['dropzone-document-preview-element'];
                                          a.target = '_blank';
                                          a.onclick = () => {
                                              TreeUtilities.createTmpFileByDatFile(file, file.type).then((result) => {
                                                  var filename = BUtils.extractFilenameNoExtension(file.name);
                                                  var extension = BUtils.extractFileExtension(file.name);
                                                  var encodedFilename = AdministrationUtilities.encodeOSPath(filename);
                                                  var src = BUtils.getServerUrl() + '/tree/getTmpFile/' + encodedFilename + '/' + extension;
                                                  var fileLength = parseInt(result.message);
                                                  this._openDocumentPreview(src, openViewerDocButtonId, fileLength, filename, extension, true, 0, this.props.item.key);
                                              });
                                          };

                                          dropPreview.appendChild(a);
                                      } else {
                                          var tag = "";

                                          if (file.type.startsWith("audio")) {
                                              tag = "audio";
                                              var elbt = document.createElement("button");
                                              elbt.innerHTML = "&nbsp;&#9654";
                                              elbt.onclick = function () {
                                                  document.getElementById(file.lastModified).play();
                                              };
                                              document.getElementById(dropPreviewId).appendChild(elbt);
                                          } else if (file.type.startsWith("video")) {
                                              tag = "video";
                                          } else {
                                              tag = "img";
                                          }

                                          var element = document.createElement(tag);
                                          element.src = file.preview;
                                          element.className = stylesSCTree['gallery-element-preview'];
                                          element.id = file.lastModified;
                                          previewCarouselItems.push(file.preview);

                                          document.getElementById(dropPreviewId).appendChild(element);
                                          element.onclick = function () {
                                              var index = Array.from(element.parentNode.children).indexOf(element);
                                              this._openCarouselByIndex('preview', index);
                                          }.bind(this);
                                      }
                                  });

                                  this.setState({
                                      previewCarouselItems, data
                                  });
                              }}
                              onDropRejected={function () {
                              }}>
                        <p>{this.state.data[counter].accept}</p>
                    </Dropzone>
                </Col>
                <Col lg={7} md={7}
                     className={BUtils.joinClasses(stylesSCTree['dropzone-elements-preview'], stylesSCTree['dropzone-elements-preview__' + code])}>
                    <Row
                        className={BUtils.joinClasses(stylesSCTree['row-gallery-buttons'], stylesSCTree['row-gallery-buttons__' + code])}>
                        <Button id="bt-stored-open-gallery" onClick={(evt) => {
                            if (code !== 'DOCUMENTS')
                                this._openEditCarousel('stored', carouselCode, storedCarouselItems, toDeleteCarouselItems, counter);
                        }}>{Dict.t('STORED_FILE_GALLERY')}</Button>
                        <div id={resourcePreviewId}
                             className={stylesSCTree['dropzone-img-container']}>{StoredImages}</div>
                    </Row>
                    <Row
                        className={BUtils.joinClasses(stylesSCTree['row-gallery-buttons'], stylesSCTree['row-gallery-buttons__' + code])}>
                        <Button id="bt-preview-open-gallery" onClick={(evt) => {
                            if (code !== 'DOCUMENTS')
                                this._openEditCarousel('preview', carouselCode, storedCarouselItems, toDeleteCarouselItems, counter);
                        }}>{Dict.t('FILE_PREVIEW_GALLERY')}
                        </Button>
                        <Button onClick={(evt) => {
                            data[counter].preview = [];
                            var dropPreview = document.getElementById(dropPreviewId);
                            while (dropPreview.firstChild) {
                                dropPreview.removeChild(dropPreview.firstChild);
                            }
                            this.setState({
                                previewCarouselItems: []
                            });
                        }}>reset</Button>
                        <div id={dropPreviewId}
                             className={BUtils.joinClasses(stylesSCTree['dropzone-img-container'], stylesSCTree['dropzone-img-container__preview'])}/>
                    </Row>
                    <Row
                        className={BUtils.joinClasses(stylesSCTree['row-gallery-buttons'], stylesSCTree['row-gallery-buttons__' + code])}>
                        <Button id="bt-toDelete-open-gallery" onClick={(evt) => {
                            if (code !== 'DOCUMENTS')
                                this._openEditCarousel('toDelete', carouselCode, storedCarouselItems, toDeleteCarouselItems, counter);
                        }}>{Dict.t('FILE_TO_DELETE_GALLERY')}
                        </Button>
                        <Button onClick={(evt) => {
                            data[counter].value.forEach((dataCounterValueItem) => {
                                delete dataCounterValueItem.status;
                            });
                            this.setState({
                                toDeleteCarouselItems: []
                            });
                        }}>reset</Button>
                        <div id={resourcePreviewId}
                             className={stylesSCTree['dropzone-img-container']}>{toDeleteImages}</div>
                    </Row>
                </Col>
            </div>;
        } else if (type === 'pathbrowser') {
            label = data[counter].label;
            /*var formElementRefId = this['formControl-pathbrowser-' + id];*/
            value = data[counter].value;

            var resetFilesCallback = function (result, isDirectory) {
                data[counter].value = {
                    path: result.folderPath,
                    showContents: !result.forceHideContents && !this.isAdd && this.state.data[counter].value.showContents,
                    isDirectory: isDirectory,
                    filename: result.filename
                };
                this.setState({data, opening: false});

                this._buildPathBrowserView(result, isDirectory, 'pathbrowser-list-files-' + counter);

            }.bind(this);

            if (this.state.opening) {
                if (this.isAdd) {
                    TreeNodeHelper.resetListFiles("", "", true, true, resetFilesCallback);
                } else {
                    TreeNodeHelper.resetListFiles(value.path, "path", true, value.isDirectory, resetFilesCallback);
                }
            }

            retElement = <div>
                <Col lg={9} md={9} className={stylesSCTree['path-browser-rows']}>
                    <Row>
                        <FormControl
                            id={id}
                            type="text"
                            placeholder={''}
                            ref={(input) => {
                                this.formElementRefId = input;
                            }}
                            value={typeof value === 'undefined' ? '' : value.path}
                            onPaste={(evt) => {
                                setTimeout(function () {
                                    this._checkPath(data, counter, resetFilesCallback);
                                }.bind(this), 200);
                            }}
                            onChange={(evt) => {
                                document.getElementById("pathbrowser-error").style.display = 'none';
                                data[counter].value.path = evt.target.value;
                                data[counter].value.found = false;
                                this.setState({data});
                            }}
                            onKeyDown={(evt) => {
                                if (evt.keyCode === 13) {
                                    data[counter].value.path = evt.target.value;
                                    this._checkPath(data, counter, resetFilesCallback);
                                } else {
                                    data[counter].value.found = false;
                                }

                                this.setState({data});
                            }}
                        />
                    </Row>
                    {typeof value === 'undefined' ? '' : <Row
                        className={stylesSCTree['row-chk-show-contents--' + (BUtils.isImage(value.path) || value.isDirectory ? 'show' : 'hide')]}>
                        <SCCheckbox label={Dict.t('SHOW_CONTENTS_IN_FRONTEND')}
                                    id={"chk-show-contents-" + counter}
                                    additionalClass={stylesSCTree['path-browser-rows__sc-checkbox']}
                                    checked={typeof value !== 'undefined' && value.showContents}
                                    callbackParent={(id, newState) => {
                                        data[counter].value.showContents = newState;
                                        this.setState({data});
                                    }}
                        />
                    </Row>}
                    <Row>
                        <div id={'pathbrowser-list-files-' + counter}/>
                    </Row>
                </Col>
                <Col lg={3} md={3} className={stylesSCTree['pathbrowser-col-buttons'] + ' teb-col-pathbrowser-' + type}>
                    <Button className={"teb-button-remove-" + type} onClick={() => {
                        data[counter].value.path = value.path;
                        this._checkPath(data, counter, resetFilesCallback);
                    }}>
                        <FontAwesome name={"arrow-right"}/>
                    </Button>
                    <Button className={"teb-button-remove-" + type} onClick={() => {
                        TreeNodeHelper.resetListFiles(value.path, "previous", true, true, resetFilesCallback);
                    }}>
                        <FontAwesome name={"arrow-up"}/>
                    </Button>
                    <Button className={"teb-button-remove-" + type} onClick={() => {
                        document.getElementById("pathbrowser-error").style.display = 'none';
                        TreeNodeHelper.resetListFiles(value.path, "home", true, true, resetFilesCallback);
                    }}>
                        <FontAwesome name={"home"}/>
                    </Button>
                    <FontAwesome id={"pathbrowser-error"}
                                 className={BUtils.joinClasses(stylesSCTree['pathbrowser-col-buttons__info'], stylesSCTree['pathbrowser-col-buttons__info--' + (!this.isAdd && value.isUrl ? 'warning' : 'error')])}
                                 data-tip data-for={'tooltip-path-not-found'}
                                 name={!this.isAdd && value.isUrl ? "warning" : "exclamation"}/>
                    <ReactTooltip id={'tooltip-path-not-found'} type='info' delayShow={20}
                                  className={"tree-tool-tip"}>{Dict.t(!this.isAdd && value.isUrl ? 'FOUND_URL' : 'PATH_NOT_FOUND')}</ReactTooltip>
                </Col>
            </div>;
        } else if (type === 'treelink') {
            label = data[counter].label;
            //label = this.props.isBasicItem ?  TreeNodeHelper.getBasicItemByCode(data[counter].code).element.label : data[counter].label;
            var selectedValue = typeof data[counter].value === 'undefined' || data[counter].value === '' ? [] : [data[counter].value];

            /*if (selectedValue.length > 0) {
                console.log();
            }*/

            retElement = <div className={stylesSCTree['only-treelink']}>
                <Col lg={7} md={7}>
                    <AsyncTypeahead
                        id={'treelink-typeahead'}
                        isLoading={selectedValue.length === 0}
                        delay={800}
                        ref={"treeitemlinktypeaheadt"}
                        className={stylesSCTree["treelink-typeahead"]}
                        maxResults={5}
                        clearButton
                        onChange={item => {
                            data[counter].value = item.length > 0 ? item[0] : '';
                            this.setState({data});
                        }}
                        onKeyDown={() => {
                            /*var treeLinkOptions = [];
                            this.setState({treeLinkOptions});*/
                        }
                        }
                        onSearch={(textSearch) => {
                            var treeLinkOptions = [];
                            Dict.call('/tree/searchItems/' + this.props.projectName + '/' + textSearch + '/5/' + this.isLogged + '/').then((result) => {
                                result.listItems.forEach((item) => {
                                    var notLoggedHidden = item.hidden;
                                    if (item.code !== 'FOLDER' && !notLoggedHidden) {
                                        var value = item.title;
                                        treeLinkOptions.push({
                                            id: item.key,
                                            label: value,
                                            code: item.code,
                                            isBasic: item.isBasic,
                                            value,
                                        });

                                        this.setState({treeLinkOptions});
                                    }
                                });
                            });
                        }}
                        options={this.state.treeLinkOptions}
                        defaultSelected={selectedValue}
                        placeholder={Dict.t('SEARCH')}
                        useCache={false}
                    /></Col>{selectedValue.length > 0 ?
                <Col lg={5} md={5} style={{paddingTop: '7px', fontWeight: 'bold'}}>
                    <span>{selectedValue[0].isBasic ? Dict.t(selectedValue[0].code) : selectedValue[0].code}</span>
                    <FontAwesome className={stylesSCTree['tree-node-fa']}
                                 name={this.props.itemsToIcon[selectedValue[0].code]}/>
                </Col> : ''}
            </div>;
        }

        if (this.props.isBasicItem || this.isContainerItem || this.isSubContainerItem) {
            label = Dict.t(label);
        }

        return <div
            className={BUtils.joinClasses(stylesSCTree['tree-object-wrapper']/*, stylesSCTree['tree-object-wrapper--' + (isHidden ? 'hide' : 'show')]*/)}>
            <Col lg={colLeft} md={colLeft} className={additionalColLeft}>
                {isBasicItemHidden ?
                    <FontAwesome name={'eye-slash'} data-tip data-for={'tooltip-basicItem-hidden-' + counter}
                                 className={stylesSCTree['tree-object-wrapper__show-hidden-basicItem-fa']}/> :
                    <Button onClick={() => {
                        data[counter].hidden = !isHidden;
                        this.setState({data});
                    }} className={stylesSCTree['tree-object-wrapper__show-hide-button']}>
                        <FontAwesome name={isHidden ? 'eye-slash' : 'eye'} data-tip
                                     data-for={'tooltip-bt-hide-show-' + counter}
                                     className={stylesSCTree['tree-object-wrapper__show-hide-button-fa']}/>
                    </Button>}
                <label
                    className={stylesSCTree['tree-object-wrapper__label']}>
                    {label}</label>
                <ReactTooltip id={'tooltip-bt-hide-show-' + counter} type='info' delayShow={250}
                              className={"tree-tool-tip"}>{Dict.t('BT_HIDE_SHOW_TREE_OBJECT_TOOLTIP')}</ReactTooltip>
                <ReactTooltip id={'tooltip-basicItem-hidden-' + counter} type='info' delayShow={250}
                              className={"tree-tool-tip"}>{Dict.t('BASIC_ITEM_TREE_OBJECT_HIDDEN_TOOLTIP')}</ReactTooltip>
            </Col>
            <Col
                lg={colRight} md={colRight}
                className={BUtils.joinClasses(additionalColRight, stylesSCTree['tree-object-wrapper__col-right--' + (isHidden || isBasicItemHidden ? 'hide' : 'show')])}>{retElement}</Col>
        </div>;
    }

    _checkPath(data, counter, callback) {
        var path = data[counter].value.path;
        this.setState({data}, function () {
            var encodedFolderPath = LexemeUtilities.encodeUrl(AdministrationUtilities.encodeOSPath(path));
            var isUrl = BUtils.isUrl(path);
            if (isUrl) {
                data[counter].value.found = true;
                data[counter].value.isDirectory = false;
                data[counter].value.isUrl = true;
                data[counter].value.path = path;
                document.getElementById("pathbrowser-error").style.display = 'inline';

                this.setState({data});
            } else {
                data[counter].value.isUrl = false;
                Dict.call('/tree/checkIsDirectory/' + encodedFolderPath + '/').then(result => {
                    if (result.exists) {
                        TreeNodeHelper.resetListFiles(path, "path", true, result.isDirectory, callback);
                        data[counter].value.found = true;
                        this.setState({data});
                    } else {
                        document.getElementById("pathbrowser-error").style.display = 'inline';
                        data[counter].value.found = false;
                    }
                });
            }
        });
    }

    // VIEWER ELEMENTO
    _retrieveElementViewer(id, counter) {
        var data = this.state.data;
        const type = data[counter].type;
        var value = data[counter].value;

        var retElement = '';
        var colLeft = 2;
        var colRight = 10;
        var label = data[counter].label;
        const isHidden = data[counter].hidden === true;
        const isBasicItemHidden = data[counter].basicItemHidden;

        if (type === "textfield") {
            retElement = <Row className={stylesSCTree['tree-object-viewer__tinymce']}>{value}</Row>;
        } else if (type === "weblink") {
            if (!value.startsWith('http') && value !== "")
                value = 'http://' + value;
            retElement = <MicrolinkCard url={value}/>;
        } else if (type === "tinymce") {
            retElement = <Row className={stylesSCTree['tree-object-viewer__tinymce']}
                              dangerouslySetInnerHTML={{__html: value}}/>;
        } else if (type === "datepicker") {
            colLeft = 2;
            colRight = 10;
            while (value.length < 4)
                value = "0".concat(value);

            retElement = <Row
                className={stylesSCTree['tree-object-viewer__datepicker']}>{TreeUtilities.writeDateString(new Date(value), data[counter].choosenMode)}</Row>;
        } else if (type === "checkbox") {
            retElement = <Row>
                <span
                    className={BUtils.joinClasses(stylesSCTree['tree-object-viewer__checkmark'], stylesSCTree['tree-object-viewer__checkmark--' + (value ? 'checked' : 'unchecked')])}/>
            </Row>;
        } else if (type === "dropzone" || type === "pdf-page-number") {
            const isPdfPage = type === "pdf-page-number";
            const isCarousel = label !== 'AUDIO_GALLERY' && label !== 'VIDEO_GALLERY' && label !== 'DOCUMENT';
            const isDocument = label === 'DOCUMENT' || data[counter].code === 'DOCUMENTS' || isPdfPage;

            /*if (isPdfPage)
                label = Dict.t(label) + " pag." + value;*/


            if (!Array.isArray(value)) {
                value = [value];
            }

            if (isDocument) {
                const folder = isPdfPage ? NodeOperations.retrieveParentKey(this.state.item) : data[counter].folder;
                const StoredDocs = value.map((item, index) => {
                    var dataToSend = {folder};
                    var itemToSend = isPdfPage ? data[counter].resource : item;

                    const src = this._buildSrc(itemToSend, dataToSend);
                    return this._buildComponentViewerSrc(data[counter], {
                        src: src,
                        filename: itemToSend.filename + '.' + itemToSend.extension,
                        folder,
                        projectName: this.props.projectName
                    }, "key-viewer-list-" + index);
                });

                retElement =
                    <div id={"tree-docs-viewer"} className={stylesSCTree['tree-docs-viewer']}>{StoredDocs}</div>
            } else {

                const StoredImages = value.map((item, index) => {
                    const src = this._buildSrc(item, data[counter]);
                    return this._buildComponentViewerSrc(data[counter], src, "key-viewer-list-" + index);
                });

                if (isCarousel) {
                    var imgGalleryLength = typeof value === 'undefined' ? 0 : value.length;

                    var zoom = typeof data[counter].zoom === 'undefined' ? 'zoom-out' : data[counter].zoom;

                    retElement = <div id={"tree-carousel-drop-container"}
                                      onClick={(evt) => {
                                          var tagName = evt.target.tagName.toLowerCase();
                                          if (tagName === 'div' || tagName === 'img') {
                                              data[counter].zoom = typeof data[counter].zoom === 'undefined' || data[counter].zoom === 'zoom-out' ? 'zoom-in' : 'zoom-out';
                                              this.setState(this.state);
                                          }
                                      }}
                                      className={BUtils.joinClasses(stylesSCTree["tree-carousel-drop-container-viewer"], stylesSCTree["tree-carousel-drop-container-viewer--" + zoom])}>
                        <Carousel
                            id={"tree-carousel-drop"} className={BUtils.joinClasses(stylesSCTree['tree-carousel-drop'])}
                            slide={true}
                            interval={null} indicators={imgGalleryLength > 1} controls={imgGalleryLength > 1}>
                            {StoredImages}
                        </Carousel></div>
                } else {
                    retElement =
                        <div id={"tree-playlist-drop"}
                             className={stylesSCTree['tree-playlist-drop']}>{StoredImages}</div>;
                }
            }
        } else if (type === "autocomplete") {
            const isCustom = data[counter].code === 'CUSTOM_DATA_COLLECTION';
            /*colLeft = this.state.isBasicItem || isCustom ? 4 : 3;
            colRight = this.state.isBasicItem || isCustom ? 8 : 9;*/
            label = this.state.isBasicItem || isCustom ? data[counter].customLabel : data[counter].label;

            var additionalClass = '';
            var item = TreeNodeHelper.getAutoCompleteItemById(data[counter].items, data[counter].itemIdSelected)
            if (typeof item === 'undefined') {
                value = Dict.t('NO_ELEMENT_IS_SELECTED') + ".";
                additionalClass = stylesSCTree['tree-object-viewer__autocomplete-viewer-no-element'];
            } else {
                value = item.label;
            }

            retElement = <Row
                className={BUtils.joinClasses(stylesSCTree['tree-object-viewer__autocomplete-viewer'], additionalClass)}>{value}</Row>;
        } else if (type === "pathbrowser") {
            var isUrl = BUtils.isUrl(value.path);
            var isLocalDirectory = !isUrl && (typeof value.found === 'undefined' || value.found ? value.isDirectory : !BUtils.isFileByStringPath(value.path, isUrl));
            var isUrlFile = isUrl && !isLocalDirectory;

            if (typeof value.showContents !== 'undefined' && value.showContents) {
                TreeNodeHelper.resetListFiles(value.path, "path", false, isLocalDirectory, function (result) {
                    this._buildPathBrowserView(result, isLocalDirectory, 'viewer-pathbrowser-list-files-' + counter);
                }.bind(this));
            }

            const hideContentsNoDir = !value.showContents && !isLocalDirectory;
            const hideContentsDir = !value.showContents && isLocalDirectory;


            retElement = <div>
                <Row /*onClick={() => {
                    if (hideContentsNoDir)
                        TreeNodeHelper.downloadFile(value.path, value.filename);
                }}*/
                    className={BUtils.joinClasses(stylesSCTree['tree-object-viewer__pathbrowser-textfield'], stylesSCTree['tree-object-viewer__pathbrowser-textfield--show']) /*{+ '--' + (hideContentsDir ? 'show' : 'download')])}*/}>{value.path}</Row>
                <Row className={BUtils.joinClasses(stylesSCTree['tree-object-viewer__pathbrowser-buttons'])}>
                    {hideContentsDir || !isLocalDirectory ? <Button onClick={() => {
                        const el = document.createElement('textarea');
                        el.value = value.path;
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand('copy');
                        document.body.removeChild(el);
                        Notifications.showToastNotification(Dict.t('TOAST_MSG_COPY_PATH_DONE'), 2000, 0);
                    }
                    }>{Dict.t('COPY_PATH')}</Button> : <div id={"viewer-pathbrowser-list-files-" + counter}/>}
                    {isUrlFile ?
                        <Button onClick={() => {
                            var win = window.open(value.path, '_blank');
                            win.focus();
                        }}>{Dict.t('OPEN_NEW_TAB')}</Button>
                        : ''}
                    {/*hideContentsNoDir && !isUrl ?
                        <Button onClick={() => {
                            TreeNodeHelper.downloadFile(value.path, value.filename);
                        }}>{Dict.t('DOWNLOAD_FILE')}</Button>
                        : ''*/}
                </Row>
            </div>;
        } else if (type === 'treelink') {
            if (this.state.isBasicItem)
                label = TreeNodeHelper.getBasicItemByCode(data[counter].code).element.label;
            const hasValue = typeof value !== 'undefined' && Object.keys(value).length > 0;
            var valueLabel = hasValue ? value.value : Dict.t('TREELINK_UNSET');

            var span = <span style={{fontWeight: 'bold', fontSize: '14px'}}>{valueLabel}</span>;
            var fontAwesome = hasValue ?
                <><FontAwesome name={this.props.itemsToIcon[value.code]} data-tip
                               data-for={'tooltip-treelink-' + counter}
                               style={{verticalAlign: 'top', paddingLeft: '3px', paddingRight: '3px'}}/>
                </> : '';
            var reactTooltip = hasValue ? <ReactTooltip id={'tooltip-treelink-' + counter} type='info'
                                                        className={"tree-tool-tip"}>
                {valueLabel}
                <div>{'[' + value.code + ']'}</div>
            </ReactTooltip> : '';

            //NodeOperations.traverseToKeyLoadData(this.props.projectName, this.props.treeStructure, value.id);


            retElement = <div className={stylesSCTree["treelink-viewer"]}>
                <Col lg={8} md={8}
                     className={stylesSCTree["treelink-viewer__label"]}>{span}</Col>
                <Col lg={1} md={1} style={{
                    marginRight: '0px',
                    marginLeft: '0px',
                    padding: '3px',
                    fontSize: '20px'
                }}>{fontAwesome}{reactTooltip}</Col>
                <Col lg={3} md={3} className={BUtils.joinClasses(stylesSCTree['treelink-viewer__button'])}>
                    {hasValue ? <Button bsStyle={'primary'} onClick={(event) => {
                        //NodeOperations.traverseToKeyLoadData(this.props.projectName, this.props.treeStructure, value.id, () => {
                        this.props.callbackOpenTreeLinks(event, value.id)
                        //});
                    }
                    }>{Dict.t('SHOW') + " >>>"}</Button> : ''}
                </Col>
            </div>;

        }

        if ((this.props.isBasicItem || this.isContainerItem || this.isSubContainerItem) && typeof data[counter].customLabel === 'undefined') {
            if (this.isSubContainerItem && label === 'CONTAINER_TITLE') {
                const container = TreeNodeHelper.getContainerBySubitem(this.props.item.code);
                label = /*Dict.t('TITLE') + ' ' + */TreeNodeHelper.getPropLabel(container);
            } else {
                var itemStructure = TreeNodeHelper.getItemByCode(this.props.item.code);
                // pezza clamorosa
                if(this.props.item.code !== 'BOOKMARK') {
                    //if (this.isContainerItem) {
                    label = Dict.t(itemStructure.basicItems.find((itm) => {
                        return itm.code === data[counter].code;
                    }).label);
                    if (label === '_____')
                        label = '';
                } else {
                    label = Dict.t(label);
                }
            }
        }


        return <div className={stylesSCTree['tree-object-wrapper-viewer']}>
            <Col lg={colLeft} md={colLeft}>
                {isBasicItemHidden ?
                    <FontAwesome name={'eye-slash'} data-tip data-for={'tooltip-basicItem-hidden-' + counter}
                                 className={stylesSCTree['tree-object-wrapper__show-hidden-basicItem-fa']}/> : ''}
                <label>{label}</label>
            </Col>
            <Col
                lg={colRight} md={colRight}
                className={stylesSCTree['tree-object-wrapper__col-right--' + (isHidden || isBasicItemHidden ? 'hide' : 'show')]}>{retElement}</Col>
        </div>;
    }

    _buildSrc(item, data) {
        var url2 = TreeUtilities.buildMultimediaSrc(this.props.projectName, item, data);
        return url2;
    }

    _buildComponentSrc(dataCounter, src, id, galleryType, activeIndex) {
        var component;
        const key = 'key-stored-component-' + id;

        var openCarousel = function (_this) {
            _this._openCarouselByIndex(galleryType, activeIndex)
        };

        if (dataCounter.code === "AUDIO_GALLERY") {
            component = <span key={key}>
        <button onClick={(evt) => {
            document.getElementById(id).play();
        }}> ▶</button>
        <audio id={id} src={src}/>
        </span>;
        } else if (dataCounter.code === "VIDEO_GALLERY") {
            component = <video key={key} className={stylesSCTree['gallery-element-preview']} src={src}/>;
        } else if (dataCounter.code === "IMAGE_GALLERY") {
            component = <img key={key} className={stylesSCTree['gallery-element-preview']}
                             onClick={() => openCarousel(this)} src={src}/>;
        } else if (dataCounter.code === 'DOCUMENTS') {
            const containerViewerDocButtonId = 'div-container-open-viewer-doc-' + galleryType + '-' + id;
            const openViewerDocButtonId = 'open-viewer-doc-' + id;

            component = <div id={containerViewerDocButtonId} className={stylesSCTree['dropzone-document-elements']}>
                <div><a id={openViewerDocButtonId} onClick={() => {
                    this._createEditDocumentOnClick(src.src, openViewerDocButtonId, dataCounter.value[0], 0, src.folder);
                }}>{dataCounter.value[0].filename + '.' + dataCounter.value[0].extension}</a></div>
                {galleryType === 'toDelete' ? '' : <div onClick={() => {
                    var toDeleteCarouselItems = this.state.toDeleteCarouselItems;
                    toDeleteCarouselItems.push(src.src);
                    this.state.data[this.state.data.findIndex((item) => {
                        return item.label === dataCounter.label;
                    })].value[activeIndex].status = "to_remove";

                    var data = this.state.data;

                    this.setState({
                        toDeleteCarouselItems, data
                    })
                }
                }>x</div>}
            </div>;
        }

        return component;
    }

    _buildComponentViewerSrc(dataCounter, src, key) {
        var component;
        if (dataCounter.label === "AUDIO_GALLERY") {
            component = <audio key={key} src={src} className="tree-playlist-audio-item" controls="controls"/>;
        } else if (dataCounter.label === "VIDEO_GALLERY") {
            component = <video key={key} src={src} className="tree-playlist-audio-item" controls="controls"/>;
        } else if (dataCounter.label === "DOCUMENT" || dataCounter.code === 'DOCUMENTS' || dataCounter.type === "pdf-page-number") {
            /*<Document file={src} ><Page pageNumber={1} /></Document><p>Pbaddi</p>*/
            const openViewerDocButtonId = 'open-viewer-doc-' + key;
            const isPdfPage = dataCounter.type === "pdf-page-number";

            component = <div key={key} className={stylesSCTree['tree-docs-viewer-item']}>
                {dataCounter.type !== 'dropzone' ? <div
                    className={stylesSCTree['tree-docs-viewer-item__name']}>{dataCounter.value}</div> : ''}
                <div className={stylesSCTree['tree-docs-viewer-item__buttons']}>
                    <Button id={openViewerDocButtonId}
                            onClick={() => {
                                this._createEditDocumentOnClick(src.src, openViewerDocButtonId, isPdfPage ? dataCounter.resource : dataCounter.value[0], isPdfPage ? dataCounter.value : "0", src.folder);
                            }}>{Dict.t('SHOW')}</Button>
                    {isPdfPage ? '' : <Button
                        onClick={() => {
                            TreeNodeHelper.downloadMediaFile(src.projectName, src.filename, src.folder, dataCounter.value[0].extension);
                        }
                        }>{Dict.t('DOWNLOAD')}
                    </Button>}
                </div>
            </div>;
        } else {
            component = <CarouselItem key={key}>
                <div className={stylesSCTree['tree-object-viewer__carousel-container']}>
                    <img className={stylesSCTree['tree-object-viewer__carousel-container-img']} src={src}
                         alt={'no img'}/>
                </div>
            </CarouselItem>;
        }

        return component;
    }

    _buildPathBrowserView(result, isDirectory, containerId) {
        const folderPath = result.folderPath + '';
        const isImage = BUtils.isImage(folderPath);
        var has;
        if (isDirectory) {
            has = 'files';
        } else if (isImage) {
            has = 'resource';
        } else {
            has = 'no-resource';
        }

        const classeName = BUtils.joinClasses(stylesSCTree['ul-pathbrowser-list-files'], stylesSCTree['ul-pathbrowser-list-files--has-' + has])

        const renderPathBrowserElement = isDirectory ? <ul
                className={classeName}>{result.listFiles}</ul> :
            <div className={classeName}>
                {isImage ? <img className={stylesSCTree['ul-pathbrowser-img']}
                                src={result.resource}/> : ''}
            </div>;

        ReactDOM.render(renderPathBrowserElement, document.getElementById(containerId));
    }

    _createEditDocumentOnClick(src, openViewerDocButtonId, item, page, folder) {
        var sizeCall = '/tree/getMultimediaSize/' + src.split('/getMultimedia/')[1];
        Dict.call(sizeCall).then((fileSize) => {
            const filename = item.filename;
            const extension = item.extension;
            this._openDocumentPreview(src, openViewerDocButtonId, fileSize, filename, extension, false, page, folder);
        });
    }


    _openDocumentPreview(src, buttonId, fileLength, filename, extension, isTmp, page, folder) {
        this.setState({
            showDocModal: true
        }, () => {
            const parentItem = {projectName: this.props.projectName, key: folder/*isTmp ? null : this.state.item.key*/};
            TreeDOMHelper.openDocumentPreview(src, buttonId, fileLength, parentItem, filename, extension, page, folder);
        });
    }

    _openEditCarousel(showTreeCarousel, carouselCode, storedCarouselItems, toDeleteCarouselItems, carouselPosition) {
        this.setState({
            showTreeCarousel,
            carouselCode,
            storedCarouselItems,
            toDeleteCarouselItems,
            carouselPosition
        });
    }

    _openCarouselByIndex(galleryType, activeIndex) {
        this.setState({activeIndex}, function () {
            document.getElementById("bt-" + galleryType + "-open-gallery").click();
        })
    };

    _handleTitle(evt) {

        var children = '';

        if (this.isAdd) {
            children = typeof this.props.item.children === 'undefined' ? [] : this.props.item.children;
        } else {
            if (TreeNodeHelper.isRootItem(this.props.item)) {
                children = this.props.treeStructure;
            } else {
                children = this.parent.children;
            }
        }

        const hasNodeExistsAnomaly = typeof children !== 'undefined' && children.some((item) => {
            return item !== this.props.item && item.title === evt.target.value;
        });


        var anomaly = {hasAnomaly: false, type: ''};
        if (hasNodeExistsAnomaly) {
            anomaly = {hasAnomaly: true, type: 'NODE_EXISTS_BY_TITLE'}
        } else if (evt.target.value === '') {
            anomaly = {hasAnomaly: true, type: 'TITLE_CANNOT_BE_EMPTY'}
        }

        this.setState({title: evt.target.value, anomaly})
    }

    _saveResource(item) {
        const oldTreeStructure = [...this.state.treeStructure];
        var data = this.state.data;
        var title = this.state.title;
        var id = this.isAdd ? parseFloat(this.props.autoincrement) + parseFloat(1) : item.key;

        if (title.trim().length > 0) {
            this.state.resourceElementsPosition.forEach((position, index) => {
                NodeOperations.addNewOrSetResourceElement(this.props.projectName, oldTreeStructure, item, data, id, this.isAdd, position, title, function (dataResource, addFile) {
                    if (index === (this.state.resourceElementsPosition.length - 1)) {
                        data[position] = dataResource;

                        this.setState({
                            data
                        }, function () {
                            this._save(item);
                            if (TreeNodeHelper.isContainerItem(item.code)) {
                                this.props.callbackUpdateContainer(addFile);
                            }

                            //this.props.callbackQuickSave();
                        }.bind(this));
                    }
                }.bind(this));
            });
        } else {
            Notifications.showErrorModalMessage('TITLE_CANNOT_BE_EMPTY', 2000);
        }
    }

    _save(item) {
        var data = this.state.data;
        var title = this.state.title;

        if (title.trim().length > 0 && !this.state.anomaly.hasAnomaly) {
            var returnItem = {};
            if (this.isAdd) {
                returnItem = NodeOperations.createNewItem(item, title, this.state.code, data);
            } else {
                returnItem = NodeOperations.setItem(BUtils.cloneJson(item), title, data);
            }

            this.props.callbackParent(returnItem, this.isAdd ? TreeNodeHelper.treeNodeOperations.ADD : TreeNodeHelper.treeNodeOperations.EDIT);

        } else if (title.trim().length === 0) {
            Notifications.showErrorModalMessage('TITLE_CANNOT_BE_EMPTY', 4000);
        }
    }


    render() {

        /*console.log('**init**');
        console.log(this.state.doc.src);
        console.log('**fin**');*/

        const inputId = 'input-add-node';
        const isAdd = this.props.operation === 'add' && typeof this.props.treeObject !== 'undefined';

        var item = this.state.item;
        var headerType = '';


        if (isAdd) {
            headerType = Dict.t('ADD') + ' ' + (typeof this.props.treeObject.propLabel === 'undefined' ? Dict.t(this.props.treeObject.code) : TreeNodeHelper.getPropLabel(this.props.treeObject))
        } else {
            headerType = TreeNodeHelper.isContainerItem(item.code) ? TreeNodeHelper.getPropLabel(TreeNodeHelper.getItemByCode(item.code)) : Dict.t(item.code);
        }

        const operation = this.props.operation;

        var anomalySpan = <span id="input-node-anomaly"
                                className={BUtils.joinClasses(stylesSCTree['input-add-node-container__anomaly'], stylesSCTree['input-add-node-container__anomaly--' + this.state.anomaly.hasAnomaly])}>
        <Glyphicon glyph={"alert"} data-tip data-for='tooltip-input-add-node-anomaly'/>
        <ReactTooltip id='tooltip-input-add-node-anomaly' type='error' className={"tree-tool-tip"}>
        <span>{Dict.t(this.state.anomaly.type)}</span>
        </ReactTooltip>
        </span>;

        var treeObjectElement = typeof this.state.data !== 'undefined' && this.state.data.length > 0 ? this._drawTreeElementObject() : '';
        const editGallery = this.state.showTreeCarousel === 'none' ? "" : this._drawEditGallery();
        const buttonBar = this._drawButtonBar();

        return <div>
            {this.props.isViewer ? <div id="title-viewer"
                                        className={BUtils.joinClasses(stylesSCTree['title-viewer'], stylesSCTree['tree-minor-skin'])}>
                    <div id="title-viewer-title" className={stylesSCTree['title-viewer__title']}>{this.state.title}</div>
                    <div
                        id={TreeNodeHelper.isRootItem(item) ? "root-title-viewer-numbering" : "title-viewer-numbering"}
                        className={stylesSCTree['title-viewer__numbering']}>{this.props.numbering}</div>
                </div> :
                <>
                    <div id={'node-operations-container__header'}
                         className={stylesSCTree['node-operations-container__header']}>{'[id:' + +item.key + '] '}{headerType}</div>
                    <div id="input-add-node-container" className={stylesSCTree['input-add-node-container']}>
                        <label id="label-node" className={stylesSCTree['input-add-node-container__label']}
                               htmlFor={inputId}>
                            {Dict.t('TITLE')}
                        </label>
                        <FormControl
                            id={inputId}
                            className={stylesSCTree['input-add-node-container__input']}
                            type="text"
                            placeholder={Dict.t('TITLE')}
                            ref={(input) => {
                                this.formControl = input;
                            }}
                            value={this.state.title}
                            onChange={(evt) => this._handleTitle(evt)}
                            onKeyDown={(evt) => {
                                if (evt.keyCode === 27) {
                                    TreeDOMHelper.hideNodePageContainer();
                                } else if (evt.keyCode === 13) {
                                    this._save(item, operation);
                                }
                            }}
                        />
                        {anomalySpan}
                    </div>
                </>}
            <div id="tree-object-element-container"
                 className={stylesSCTree['tree-object-element-container--' + (this.state.isBasicItem ? 'no-overflow' : 'overflow')] + " " + (this.props.isTreeLink ? stylesSCTree['tree-object-element__treelink-container'] : '')}>{treeObjectElement}</div>
            {editGallery}
            {buttonBar}
            <Modal id='tree-modal-doc' show={this.state.showDocModal} className={stylesSCTree["tree-modal-doc"]}
                   backdrop={'static'}
                   dialogClassName={stylesSCTree['tree-modal-doc__dialog']}
                   onHide={() => this.setState({showDocModal: false})} restoreFocus>
                <Modal.Header id={'tree-modal-doc-header'} className={stylesSCTree['tree-modal-doc__header']}/>
                {/*<Modal.Header>{this.state.doc.numPages > 0 ? this.state.doc.filename + ' - ' + this.state.doc.pageNumber + '/' + this.state.doc.numPages : ''}<Button
                    className={stylesSCTree['tree-modal-doc__button']} onClick={() => {
                    var doc = this.state.doc;
                    if (doc.pageNumber > 1) {
                        doc.pageNumber--;
                        this.setState({doc});
                    }
                }
                }>{'<<'}</Button>
                    <Button className={stylesSCTree['tree-modal-doc__button']} onClick={() => {
                        var doc = this.state.doc;
                        if (doc.pageNumber < doc.numPages) {
                            doc.pageNumber++;
                            this.setState({doc});
                        }
                    }}>{'>>'}</Button>
                </Modal.Header>*/}
                <Modal.Body id={'tree-modal-doc-body'} className={stylesSCTree["tree-modal-doc__body"]}>
                    {/*<Document file={this.state.doc.src} onLoadSuccess={(pdfProxy) => {
                        var doc = this.state.doc;
                        doc.numPages = pdfProxy._pdfInfo.numPages;
                        this.setState({doc});
                    }}><Page pageNumber={this.state.doc.pageNumber} scale={0.9}
                    /></Document>*/}
                </Modal.Body>
                <Modal.Footer><span id={'additional-tree-modal-doc-footer-items'}
                                    className={stylesSCTree['additional-tree-modal-doc-footer-items']}/><Button
                    onClick={() => {
                        document.getElementById('node-page').style.opacity = 1;
                        var doc = {filename: '', src: '', pageNumber: 1, numbPages: -1};
                        this.setState({showDocModal: false, doc})
                    }}>{Dict.t('CLOSE')}</Button></Modal.Footer>
            </Modal>
        </div>
    }
}

TreeItemMenu.contextType = TreeContext;