import React from 'react';
import {showHideSense} from './SenseAction';
import Dict from 'dict-lib';
import {Button, Row, Col, Grid} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class SenseShowHide extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id
        };

        this._showHideSense = this._showHideSense.bind(this);
    }

    _showHideSense() {
        showHideSense(this.props.item);
    }

    render() {
        return <Grid fluid>
            <Row>
                <Col lg={7} md={7} className="popup-label">{Dict.t(this.props.item.is_hidden ? 'ASK_SHOW_SENSE' : 'ASK_HIDE_SENSE')}</Col>
                <Col lg={5} md={5}>
                    <SCSubmitButton onClick={this._showHideSense} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </Grid>;
    }

}
