import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {LexemeUtilities} from './../../lexeme/page/editor/LexemeHelper';
import {SearchResult} from '../SearchResult';
import {SearchResultButtonBar} from './../SearchResultButtonBar';
import {AdministrationUtilities} from './../../administration/AdministrationHelper';
import {SCModalTopSpinner} from '../../dict-components/spinner/SCModalTopSpinner/SCModalTopSpinner';

import stylesSearchPage from '../../_css/dictionary/searchPage.module.css';

const initialState = {
    searchParams: {},
    isLoading: false
};

export function getInitialState() {
    return initialState;
}

export function buildEntrySearchByForm(form, langId) {
    var forms = [];
    forms.push(form);
    var className = "Entry";
    var operator = "OR";
    var specialChars = false;
    var nPage = 1;
    return search(forms, operator, className, specialChars, nPage, langId);
}

function search(forms, operator, className, specialChars, nPage, langId) {
    var searchParams = {
        forms, operator, className, specialChars, nPage, langId
    };

    return Dict.call('service/search/' + JSON.stringify(searchParams));
}

export function searchByParams(searchParams) {
    return Dict.call('service/search/' + JSON.stringify(searchParams));
}

export function performSearchByInitialState() {
    var searchParams = getInitialState().searchParams;
    if (Object.keys(searchParams).length > 0) {
        Dict.call('service/search/' + JSON.stringify(searchParams)).then(result => {
            const SearcResultConst = <SearchResult nPage={searchParams.nPage} result={result}
                                                   language={AdministrationUtilities.getLangItemById(searchParams.langId)}/>;
            ReactDOM.render(SearcResultConst, document.getElementById("dict-result-container"));
        });
    }
}

export function performSearch(searchParams, isById, callback) {
    if (typeof searchParams.forms === "undefined") {
        initialState.searchParams.nPage = searchParams.nPage;
    } else {
        initialState.searchParams = searchParams;
    }

    initialState.isLoading = true;
    var callSearch = typeof isById !== 'undefined' && isById ? 'service/searchById/' + initialState.searchParams.forms[0].id : 'service/search/' + JSON.stringify(initialState.searchParams);
    return Dict.call(callSearch).then(result => {
        if(isById) {
            result.items[0].fields.entryForm = searchParams.forms[0].label;
            result.items[0].fields.abstr = searchParams.forms[0].abstr;
        }
        const SearcResultConst = <SearchResult searchParams={searchParams} nPage={searchParams.nPage} result={result}
                                               language={AdministrationUtilities.getLangItemById(searchParams.langId)}/>;

        const SearchResultButtonBarConst = <SearchResultButtonBar/>;

        setTimeout(function () {
            ReactDOM.render(SearcResultConst, document.getElementById("dict-result-container"));
            ReactDOM.render(SearchResultButtonBarConst, document.getElementById("dict-result-container-buttonbar"));
            /*ReactDOM.render(<SCModalTopSpinner show={false}
                                               msg={''}/>, document.getElementById("dict-modal-container"));*/

            var dicResultContent = document.getElementById("dict-result-content");
            if (result.items.length > 0 && !dicResultContent.classList.contains(stylesSearchPage['dict-result-content--active']))
                dicResultContent.classList.add(stylesSearchPage['dict-result-content--active']);

            setTimeout(function () {
                if (!isById)
                    document.getElementById("dict-result-list").click();
                initialState.isLoading = false;
                if(typeof callback === 'function')
                    callback();
            }, 100); // per far comparire/scomparire la scrollbar
        }, 10);
    });
}
