import React from "react";
import Dict from 'dict-lib';
import FontAwesome from "react-fontawesome";
import BUtils from './../../utilities/BaseUtilities';
import {Activations} from './../../utilities/DictUtilities';
import stylesLexemeTrash from './SCTrash.module.css';
import {deleteTranslations} from './../../lexeme/page/editor/translations/TranslationAction';

export class SCTrash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        return <FontAwesome id={'dictionary-trash-container'} name={'trash'}
            /*className={BUtils.joinClasses(stylesLexemeTrash['dictionary-trash-container__active--' + this.state.active])}*/
                            onDragOver={(ev) => {
                                ev.preventDefault();
                                Activations.activateTrash();
                            }
                            }
                            onDragEnter={(ev) => {
                                ev.preventDefault();
                            }
                            }
                            onDragLeave={(ev) => {
                                //if (event.originalEvent.pageX !== 0 || event.originalEvent.pageY !== 0) return false; // the fix
                                //if (event.currentTarget !== this.element[0]) alert('liiiv');
                                ev.preventDefault();
                                Activations.deactivateTrash();

                            }
                            }
                            onDrop={(ev) => {
                                ev.preventDefault();
                                var data = JSON.parse(ev.dataTransfer.getData('deleteInfo'));
                                var promise;

                                if (data.type === 'translation') {
                                    promise = deleteTranslations(data.params[0], data.params[1]);
                                }

                                promise.then(() => {
                                    Activations.deactivateTrash();
                                })
                            }
                            }>
        </FontAwesome>
    }


}