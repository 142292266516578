import React from 'react';
import Dict from 'dict-lib';
import ReactDom from 'react-dom';
import {Grid, Row, Button, InputGroup, FormControl} from 'react-bootstrap';
import {getLexemeProperties} from './../../LexemePage';
/*import {} from './EntryActions'*/
import {LocVarAdd} from './../loc-vars/LocVarAdd';
import './../../../../_css/lexeme.css';
import './../../editor/root-entry/RootEntry.css';
import FontAwesome from "react-fontawesome";


export class EntryAddVarLoc extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            locVarForm: "",
            addList: [],
            treeData: []
        };

        this.timeout = null;

        this._handleLocVarForm = this._handleLocVarForm.bind(this);
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.locVarForm).focus();
        Dict.call('page/retrieveLexemeVarLocTree/' + getLexemeProperties("root_entry_id")).then(result => {
            var treeData = [];
            treeData.push(result);
            this.setState({treeData}, () => {
               this._updateTree();
            });
        });
    }

    _handleLocVarForm(evt) {
        var locVarForm = evt.target.value;
        this.setState({locVarForm}, () => {
            this._updateTree();
        });

        //this._updateTree();
    }

    _updateTree() {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(function () {
            const loc = <LocVarAdd key={"key-lva-" + this.state.locVarForm} asComponent={true}
                                   form={this.state.locVarForm}
                                   treeData={this.state.treeData}
                                   changeForm={true}/>;
            ReactDom.render(loc, document.getElementById('row-entry-add-locvar-id'));
        }.bind(this), 500);
    }


    render() {

        return <Grid className={'grid-loc-add'} fluid>
            <Row className={"row-loc-add-form"}>
                <InputGroup className={"subentry-edit-group"}>
                    <InputGroup.Addon>{Dict.t('FORM')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.locVarForm = input;
                                 }}
                                 value={this.state.locVarForm}
                                 onChange={(evt) => this._handleLocVarForm(evt)}
                    />
                </InputGroup>
            </Row>
            <Row id={"row-entry-add-locvar-id"} className={"row-entry-add-locvar"}>
                <span className="spinner-text" style={{
                    fontFamily: 'Open Sans, sans-serif',
                    fontStyle : 'italic'
                }}><FontAwesome id={'loading-key-spin-'} style={{ fontSize : '18px', marginRight: '7px'}}
                                                            name={'spinner'} spin/>{Dict.t('TREE_LOADING_WAITING')}</span>
            </Row>
        </Grid>;
    }
}
