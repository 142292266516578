import React from 'react';
import {saveDating} from './SenseAction';
import Dict from 'dict-lib';
import {Grid, Button, Row, Col, InputGroup, FormControl} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css'

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import {LexemeUtilities} from "../LexemeHelper";
import ReactDom from "react-dom";
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class SenseDating extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item
        };

        this.keyOk = true;

        this._handleDating = this._handleDating.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    componentWillMount() {
        if (this.state.item.dating === 0) {
            var item = JSON.parse(JSON.stringify(this.state.item));
            item.dating = "";
            this.setState({item});
        }
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.senseDating).focus();
    }

    _handleDating(evt) {
        if (this.keyOk) {
            var item = JSON.parse(JSON.stringify(this.state.item));
            item.dating = evt.target.value;
            this.setState({item});
        }
    }

    _handleKeyDown(evt) {
        var charCode = evt.keyCode;
        this.keyOk = evt.keyCode == 8 || (!(charCode > 31 && (charCode < 48 || charCode > 57)) && (this.state.item.dating + "").length < 4);
    }

    _saveDating() {
        var item = JSON.parse(JSON.stringify(this.state.item));

        if (typeof item.dating === 'undefined' || (item.dating + "").length === 0)
            item.dating = 0;

        this.setState({btDisabled: true});
        saveDating(item);
    }

    render() {

        return <Grid fluid>
            <Row>
                <InputGroup className={stylesLexemeSenses['sense-dating__input']}>
                    <InputGroup.Addon>{ Dict.t('DATING') }</InputGroup.Addon>
                    <FormControl type="text"
                                 ref={(input) => {
                                     this.senseDating = input;
                                 }}
                                 value={this.state.item.dating}
                                 onChange={(evt) => this._handleDating(evt)}
                                 onKeyDown={this._handleKeyDown}
                                 onKeyUp={(evt) => {
                                     LexemeUtilities.handleKeyUp(evt, () => {
                                         this._saveDating();
                                     })
                                 }}
                    />
                </InputGroup>
            </Row>
            <Row className="popup-row-bt">
                <SCSubmitButton onClick={() => this._saveDating()} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>;
    }

}
