import React, { Component, PureComponent } from 'react';
import Dict from 'dict-lib';
import {LexemePolyrhematic} from './parts/LexemePolyrhematic';
import {LexemeReferences} from './../../../page/parts/05_References/LexemeReferences';
import {Button, Modal, Col, Row, Glyphicon, Collapse, Popover} from 'react-bootstrap';
import {LexemeSubentries} from './../../../page/parts/03_Subentries/LexemeSubentries'
import {LexemePolyrhematicTitle} from './LexemePolyrhematicTitle';
import {LexemeUtilities, getContextTypes, LexemeEditingLevel, LexemeDisplayContext} from './../../editor/LexemeHelper';
import './LexemePolyrhematics.css';
import './../../../../_css/lexeme.css';
import {LexemePageContext} from "../../LexemePageContext";

import stylesPolyrhematic from './../../../../_css/dictionary/lexemeParts/lexemePolyrhematic.module.css';
import BUtils from './../../../../utilities/BaseUtilities';
import {userService} from "../../../../_redux/authentication/services/user.services";

export class LexemePolyrhematics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: 'POLYRHEMATICS',
            showModal: false,
            polyProps: {form: ""},
            order: -1
        };

        this._openModalPoly = this._openModalPoly.bind(this);
        this._closeModalPoly = this._closeModalPoly.bind(this);
    }

    _openModalPoly(order) {
        this.setState({showModal: true, order: order});
    }

    _closeModalPoly() {
        this.setState({showModal: false});
    }

    render() {

        const isLogged = userService.isLogged();
        const hideToHidden = Dict.getSharedOptions().HIDE_HIDDEN_ITEMS_TO_USERS === 'true';
        var lexPolies = isLogged && !hideToHidden ? this.props.page.polyrhematics : this.props.page.polyrhematics.filter((polyrhematic) => {
            return !polyrhematic.is_hidden;
        });
        var poly = this.state.order >= 0 ? lexPolies[this.state.order] : null;
        var counter = 0;

        if(this.props.displayContext !== LexemeDisplayContext.DICTIONARY_SEARCH)
            lexPolies = lexPolies.filter((item) => {
               return !item.is_hidden;
            });

        const polyLength = Object.keys(lexPolies).length;

        var polyList = lexPolies.map((item, index) => {
            return <Row id={"div-poly-" + item.ids} className={BUtils.joinClasses(stylesPolyrhematic['lexeme-polyrhematic-item'], stylesPolyrhematic['lexeme-polyrhematic-item--' + (item.is_hidden ? 'hidden' : 'show')], stylesPolyrhematic['lexeme-polyrhematic-item--' + 'read_only'], LexemeUtilities.getLexPolyOpenableClass(this))}
                                                           key={"poly-" + item.ids + "-" + index}>
            {/*<div className={"poly-order"}>{count++}</div>*/}
            <LexemePolyrhematic {...item} order={counter++} key={"lex-poly-" + item.ids + "-" + index} callbackParent={(props) => this._openModalPoly(props)}/>
        </Row>});

        var countNotes = 0;
        if (poly != null) {
            var notes = [];
            for (var i = 0; i < (poly.variants).length; i++) {
                if (Object.keys(poly.variants[i].notes).length > 0) {
                    notes.push(<div className="lexeme-edit-poly-note"
                                    key={"poly-notes-" + countNotes++}>{poly.variants[i].language + ":" + poly.variants[i].notes}</div>);
                }
            }

            notes = Object.keys(notes).length > 0 ? <div className="lexeme-edit-poly-notes">{notes}</div> : "";
        }

        const previous = parseFloat(this.state.order) - parseFloat(1);
        const next = parseFloat(this.state.order) + parseFloat(1);

        return <div className={stylesPolyrhematic['lexeme-polyrhematics']}>
            {polyLength > 0 ?
                <div className={stylesPolyrhematic['lexeme-polyrhematics__label']}>{Dict.t('POLYRHEMATICS') + ":"}</div> : ""}
            {polyLength > 0 ? <div className={"lexeme-polyrhematics__list"}>
                {polyList}
            </div> : ""}
            <Modal className={'modal-poly'} show={this.state.showModal} onHide={this._closeModalPoly} keyboard={true}>
                <Modal.Header>
                    <Modal.Title>
                        <Col lg={8} md={8}>{poly == null ? '' : <LexemePolyrhematicTitle
                            key='lexeme-poly-title' page={poly}/>}
                        </Col>
                        <Col lg={4} md={4} className={"modal-poly-next-prev"}>
                            <Button className={previous >= 0 ? "" : "button-modal-poly-hidden"}
                                    onClick={() => this._openModalPoly(previous)}>
                                <Glyphicon glyph={"chevron-left"}/>
                            </Button>
                            <Col className={"modal-poly-next-prev-count " + (polyLength > 1 ? "" : "button-modal-poly-hidden")} lg={7} md={7}>{next + "/" + polyLength}</Col>
                            <Button
                                className={next <= polyLength - 1 ? "" : "button-modal-poly-hidden"}
                                onClick={() => this._openModalPoly(next)}>
                                <Glyphicon glyph={"chevron-right"}/>
                            </Button>
                        </Col>
                    </Modal.Title>
                </Modal.Header>
                { poly == null ? <Modal.Body id="modal-poly-edit"/> :
                    <Modal.Body id="modal-poly-edit">{notes}<LexemeSubentries key='subentries' isPolyFromLexeme={false}
                                                                              isPolyEdit={true}
                                                                              page={poly}/>
                        <div className={"lexeme-polyrhematic-references"}>
                            {Object.keys(poly.references).length > 0 ?
                                <LexemeReferences isPolyFromLexeme={false} isPolyEdit={true} direct={true}
                                                  page={poly}/> : ""}
                            {Object.keys(poly.inverse_references).length > 0 ?
                                <LexemeReferences isPolyFromLexeme={false} isPolyEdit={true} direct={false}
                                                  page={poly}/> : ""}
                        </div>
                    </Modal.Body> }
                <Modal.Footer>
                    <Button onClick={this._closeModalPoly}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>;
    }
}

