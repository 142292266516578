import React from 'react';
import Dict from 'dict-lib';
import {OverlayTrigger, Popover, InputGroup, FormControl} from 'react-bootstrap';
import {getRootEntryId} from './../../../LexemePage';
import {deleteTranslations} from './../../../editor/translations/TranslationAction';
import {
    LexemeUtilities,
    ToolbarTypes,
    getContextTypes,
    LexemePageInfos,
    translate
} from './../../../editor/LexemeHelper';
import {LexemeEditor} from './../../../editor/LexemeEditor';
import './LexemeSenses.css';
import './LexemeTranslation.css';
import './../../../../../_css/lexeme.css';
import {LexemePageContext} from "../../../LexemePageContext";

import BUtils from './../../../../../utilities/BaseUtilities';
import stylesLexemeSenses from './../../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import stylesLexemeTranslations from './../../../../../_css/dictionary/lexemeParts/lexemeTranslations.module.css';
import {Notifications, Activations, DictionarySettings} from "../../../../../utilities/DictUtilities";
import {userService} from "../../../../../_redux/authentication/services/user.services";

export class LexemeTranslation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "TRANSLATION",
            item: {},
            dropId: ''
        };

        this.activeLangsLabel = DictionarySettings.getActiveLanguages().map((item) => {
            return item.code;
        })


        this._isActive = this._isActive.bind(this);
    }

    _createButtonBarTooltip() {
        /*var id = "lex-" + this.state.name.toLowerCase() + "-button-bar";
        return LexemeUtilities.getHiddenPopover(id);*/
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _isActive(item) {
        return LexemeUtilities.isActive(this, item);
    }

    _onExit(item) {
        if (this.state.item === item) {
            this.setState({
                item: ''
            })
        }
    }

    _handleClick(item) {
        if (!this.props.isPolyFromLexeme || LexemeUtilities.isOpenable(this)) {
            this.setState({
                item: LexemeUtilities.handleClick(this, item)
            });
        }
    }

    render() {

        var isTreeOut = this.context.properties.lexeme && this.context.dictProperties.is_inverse;

        const classPrefix = this.props.isSense ? "sense" : "phrase";
        const dragDropId = this.props.isSense ? this.props.suItem.ids : this.props.suItem.id;
        /*const classItem = classPrefix + "Item";*/
        const classTransLangs = classPrefix + "TranslLangs";
        const classTransLang = classPrefix + "TranslLang";
        const isLogged = userService.isLogged();

        const isLexeme = LexemePageInfos.getLexemeProperties(this.context);
        const isInverse = LexemePageInfos.isInverse(this.context);
        /*const secondaryLanguages = getLexemeProperties('secondary_languages');*/
        var trSeparator = LexemePageInfos.getLexemeOptions(this.context, 'translation_separator');
        const trClassPostFix = typeof trSeparator !== 'undefined' && (trSeparator.endsWith(' ') || trSeparator.match(',')) ? 'no-space' : 'space';
        if (typeof trSeparator === 'undefined')
            trSeparator = '';
        else {
            trSeparator = trSeparator.replace(' ', '&nbsp;');

        }
        const classSenseTr = stylesLexemeSenses[classPrefix + '--' + (isInverse ? 'TranslInverse' : 'TranslDirect')];

        //const additionalTrSeparator = trSeparator.match(',') ? (this.props.isPolyFromLexeme ? "polyNoSpaceTrSeparator" : "") : (classPrefix + "TrSeparator");
        //const additionalTrSeparator = this.props.isPolyFromLexeme ? "polyNoSpaceTrSeparator" : "";
        const isLast = this.props.isLast;

        var translations = [];
        var langCounter = 1;
        const lexClass = LexemeUtilities.getLexClass(this);
        const customLexClass = LexemeUtilities.isPageEditable(this) ? (this.props.isPolyFromLexeme ? '' : stylesLexemeTranslations[lexClass]) : lexClass;

        const activeTranslationsByLang = this.props.suItem.translation_group.filter((item) => {
            return this.context.isTree === 'false' || this.activeLangsLabel.indexOf(item.language) >= 0;
        });

        if (activeTranslationsByLang.length > 0) {
            activeTranslationsByLang.forEach((key) => {
                var translationItem = this.props.suItem.translation_group.find((itemGroup) => {
                    return itemGroup.language === key.language;
                });


                var translationList = translationItem.translations.map((item) => {
                        const isActiveClass = this._isActive(item);
                        const isActiveCustomClass = stylesLexemeTranslations[isActiveClass];
                        var langDescr = '';

                        if(isTreeOut)
                            langDescr = <span className={stylesLexemeTranslations['translation-tree-out-languages']}>{"(" + item.languages.join(', ') + ")"}</span>;

                        console.log();

                        return <div id={"translation-id-" + item.id}
                                    className={stylesLexemeTranslations['translation'] + ' ' + classSenseTr + " " + ((isInverse && item.is_hidden_out || item.is_hidden_in) ? "hidden-item" : "")}
                        >
                            <OverlayTrigger id={"id-tr-overlay-" + item.id} key={"key-TrOverlay-" + item.id}
                                            rootClose={false}
                                            trigger={"click"}
                                            onExit={() => this._onExit(item)}
                                            placement="bottom"
                                            container={document.getElementById("modal-poly-edit")}
                                            overlay={this._createButtonBarTooltip()}>
                                <div
                                    className={stylesLexemeTranslations['translation-description'] + ' ' + stylesLexemeTranslations['translation-description-logged--' + isLogged] + ' ' + isActiveCustomClass + ' ' + isActiveClass + ' ' + customLexClass}
                                    dangerouslySetInnerHTML={{__html: item.description}}
                                    draggable={isLogged ? "true" : "false"}
                                    onDragStart={(ev) => {
                                        Activations.showTrash();
                                        ev.dataTransfer.setData("suIds", dragDropId);
                                        ev.dataTransfer.setData("trId", item.id);
                                        ev.dataTransfer.setData("deleteInfo", Notifications.buildDropDeleteInfo('translation', [JSON.stringify(item), getRootEntryId()], getRootEntryId()))
                                        ev.dataTransfer.setData("text", ev.target.innerText);
                                        Notifications.showToastNotification('<strong>' + ev.target.innerText + '</strong>: ' + Dict.t('MOVE_TR_MESSAGE'), 5000, 500);

                                        var elements = document.getElementsByClassName(stylesLexemeTranslations['tr-lang-group']);
                                        for (var i = 0; i < elements.length; i++) {
                                            elements[i].style.outline = '1.5pt dashed rgba(0, 0, 0, 0.4)';
                                            elements[i].style.outlineOffset = '-1.0pt';
                                        }
                                    }
                                    }
                                    onDragEnd={(ev) => {
                                        Activations.hideTrash();
                                        /*Notifications.closeAllToastNotification();
                                        Notifications.showToastNotification("droppone!", 2000, 500);*/
                                        var elements = document.getElementsByClassName(stylesLexemeTranslations['tr-lang-group']);
                                        for (var i = 0; i < elements.length; i++) {
                                            elements[i].style.outline = '0';
                                            elements[i].style.outlineOffset = '0';
                                        }
                                    }
                                    }

                                    onClick={(event) => {
                                        this._handleClick(item, event)
                                    }}
                                />
                            </OverlayTrigger>
                            {langDescr}
                            {typeof item.notes !== 'undefined' && (item.notes).length > 0 ?
                                <div
                                    className={BUtils.joinClasses('classItem', stylesLexemeTranslations['tr-notes'])}>{item.notes}</div> : ""}
                            {isLexeme && isInverse ?
                                <div key={'key-tr-lang-' + item.id}
                                     className={classTransLangs}>{item.languages.map((itemTrLang) => <div
                                    key={'key-tr-' + item.id + '-lang-' + langCounter++}
                                    className={classTransLang}>{itemTrLang}</div>)}</div> : ""}
                            {(!isLast || this.props.isPhrase) ?
                                <div
                                    className={BUtils.joinClasses(stylesLexemeTranslations['translation-separator'], stylesLexemeTranslations['translation-separator--' + trClassPostFix])}
                                    dangerouslySetInnerHTML={{__html: trSeparator}}/> : ""}
                        </div>
                    }
                );

                translations.push({
                    empty: false,
                    langCode: key,
                    key: "key-tr-" + key.language + "-" + dragDropId,
                    id: dragDropId,
                    translationList
                });

            });
        } else {
            translations.push({
                empty: true,
                langCode: 1,
                key: "key-tr-1-" + dragDropId,
                id: dragDropId,
                translationList: [Dict.t('EMPTY_FIELD')]
            });
        }

        var counter = 0;


        return translations.map((item) =>
            <div
                onDragOver={(ev) => {
                    ev.preventDefault();
                    this.setState({dropId: item.id});
                }
                }
                onDragEnter={(ev) => {
                    ev.preventDefault();
                }
                }
                onDragLeave={(ev) => {
                    ev.preventDefault();
                    this.setState({dropId: ''});
                }
                }
                onDrop={(ev) => {
                    Activations.hideTrash();
                    ev.preventDefault();
                    this.setState({dropId: ''});
                    Notifications.closeAllToastNotification();
                    if (ev.dataTransfer.getData("suIds") === item.id) {
                        //Notifications.showErrorModalMessage("ignore:Questo elemento contiene già questa traduzione");
                    } else {
                        LexemeUtilities.call('/senses/moveTranslation/' + ev.dataTransfer.getData('trId') + '/' + classPrefix + '/' + '/' + ev.dataTransfer.getData('suIds') + '/' + item.id + '/' + getRootEntryId() + '/').then((result) => {
                            if (result.result !== 'ERROR')
                                Notifications.showToastNotification(Dict.t('OK_MOVE_TR')/*"Droppato la traduzione <strong>" + ev.dataTransfer.getData('text') + "</strong>"*/, 5000, 500);
                        });
                    }
                }
                }
                className={BUtils.joinClasses(stylesLexemeTranslations['tr-lang-group'], stylesLexemeTranslations['tr-lang-group-visible--' + item.empty], stylesLexemeTranslations['tr-lang-group--' + (item.id === this.state.dropId)])}
                key={item.key + "-" + counter++}>{this.context.isTree === 'true' && this.activeLangsLabel.length > 1 ?
                <div
                    className="tr-langcode-span">{item.langCode.language + ":"}</div> : ""}{item.translationList}</div>
        );
    }
}

LexemeTranslation.contextType = LexemePageContext;