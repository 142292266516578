import React from "react";
import BUtils from "../../../utilities/BaseUtilities";
import stylesScNavbar from "../../../dict-components/navbar/ScNavbar.module.css";
import {Button, Col, Grid, Modal} from "react-bootstrap";
import Dropzone from "react-dropzone";
import {Notifications} from "../../../utilities/DictUtilities";
import Dict from "dict-lib";
import {getDescriptionLanguage} from "../../../lexeme/page/LexemePage";
import {LexemeUtilities} from "../../../lexeme/page/editor/LexemeHelper";
import {ImportHelper, ImportService, ImportStatus, ImportEntities} from "../../../utilities/ImportUtilities";

export class ImportManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            percent: 0,
            importIndex: 0,
            pass: 1,
            open: true
        };

        this.descrLanguages = Dict.getVarious('root_languages');
        this.steps = Object.keys(ImportEntities[this.props.type]).length;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        var ret = nextState.open || nextProps.step !== this.state.step || this.state.percent !== nextState.percent;
        return ret || nextProps.status !== this.props.status || nextProps.result !== this.props.result;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setState({step: this.props.step, open: false}, () => {
            if (false && (this.props.status === ImportStatus.BEGIN || this.props.status === ImportStatus.WAITING_DB_EMPTY))
                this._checkDbEmpty();
            if (this.props.status === ImportStatus.IMPORT_STARTED && prevProps.status !== ImportStatus.IMPORT_STARTED) {
                this.setState({importIndex: 0, pass: 1, isLast: false, percent: 0}, () => {
                    this._launchImport();
                });
            }
        });
    }

    componentDidMount() {
        this.props.callbackSteps(this.steps);
    }

    _checkDbEmpty() {
        var entity = ImportHelper.getEntity(this.props.type, this.props.step);
        Dict.call('/import/checkTableEmtpty/' + entity + '/').then((result) => {
            if (result === 'NOT_EMPTY')
                this._callDbNotEmpty(entity);
            else
                this.setState({dbChecked: true});
        });
    }

    _callDbNotEmpty(entity) {
        this.props.callbackDbNotEmpty([{line: '', error: 'La tabella di ' + entity + ' non è vuola'}]);
    }


    _launchImport() {
        const objectToProcess = 2;

        var step = this.props.step;
        var type = this.props.type;

        const rowsToCheck = this.state.rowsToCheck;
        var length = rowsToCheck.length;
        var start = this.state.importIndex;
        var isLast = rowsToCheck.length - start <= objectToProcess;
        var end = isLast ? length : start + objectToProcess;
        var pass = this.state.pass;
        var nextPass = -1;
        var percent = this.state.percent;

        var lines = rowsToCheck.slice(start, end);
        var additionalParams = '';
        var service = '_';

        var params = ImportService.prepareLaunchImport({
            type,
            start,
            end,
            step,
            objectToProcess,
            lines,
            length,
            percent,
            isLast,
            pass,
            nextPass,
            additionalParams
        });

        Dict.call('/import/' + ImportHelper.getImportService(this.props.type, step) + '/' + escape(encodeURIComponent(JSON.stringify(params.lines))) + params.additionalParams + '/').then((result) => {
            this.setState({importIndex: params.end}, () => {
                var percent = parseInt(params.percent);

                if (params.isLast) {
                    this.setState({percent: 100});
                    this.props.callbackImportFinished();
                } else if (params.nextPass) {
                    var pass = 2;
                    var importIndex = 0;
                    this.setState({percent, pass: pass, importIndex}, () => this._launchImport());
                } else {
                    this.setState({percent}, () => {
                        this._launchImport();
                    });
                }
            })
        });
    }

    _setFailedRows(failedRows) {
        failedRows.sort(function (a, b) {
            return a.line.localeCompare(b.line);
        });

        this.props.callbackAnalysisResult(failedRows);
    }

    _onDrop(acceptedFiles) {
        const step = this.state.step;
        var failedRows = [];
        var checkCsv = null;
        this.props.callbackStartChecking(true);
        var resultItems = [];
        var langItemsLabelArray = [];

        var langItems = this.descrLanguages.map((lang) => {
            langItemsLabelArray.push(lang.code);
            return {label: lang.code, id: lang.id};
        });

        BUtils.convertHeadedCsvToJson(acceptedFiles[0], (rowsToCheck) => {
            var checkFileArray = ImportService.checkCsvByType(this.props.type, step, langItemsLabelArray);

            if (rowsToCheck.length === 0) {
                failedRows.push({line: '', error: 'file vuoto'});
                this._setFailedRows(failedRows);
            } else if (!(checkCsv = ImportHelper.checkCsv(rowsToCheck[0], checkFileArray)).isRightCsv) {
                failedRows.push({
                    line: '',
                    error: 'file errato o campi non corretti: ' + checkCsv.differences.join(', ')
                });
                this._setFailedRows(failedRows);
            } else {
                this.setState({rowsToCheck}, () => {
                    var result = new Promise((resolve) => {
                        ImportService.analizeByType(this.props.type, resolve, step, rowsToCheck, resultItems, langItems);
                    });

                    result.then((failedRows) => {
                        this._setFailedRows(failedRows);
                    });
                });
            }
        });
    }

    render() {

        const instructions = ImportService.getDescriptionByType(this.props.type, this.state.step, this.descrLanguages);
        const isBegin = this.props.status === ImportStatus.BEGIN;
        const colDropZone = isBegin ? 4 : 2;

        return (
            <div>
                <div
                    className={BUtils.joinClasses(stylesScNavbar['dict-import-instr'], stylesScNavbar['dict-import-instr-show--' + isBegin])}
                    dangerouslySetInnerHTML={{__html: instructions}}/>
                <Grid fluid>
                    <Col lg={colDropZone} md={colDropZone}
                         className={stylesScNavbar['dict-import-drop-zone-show--' + isBegin]}>
                        <Dropzone
                            accept={'.csv'}
                            onDropAccepted={acceptedFiles => this._onDrop(acceptedFiles)}
                            acceptClassName="accept-drop-zone"
                            rejectClassName={stylesScNavbar['dict-import-modal-reject-drop-zone']}
                            onDropRejected={function () {
                                Notifications.showErrorModalMessage('DROPPED_FILE_NOT_ACCEPTED', 3000);
                            }}
                        >
                            <p style={{textAlign: 'center', paddingTop: '3px'}}>{'droppa qui .csv'}</p>
                        </Dropzone>
                    </Col>
                    <div
                        className={stylesScNavbar['dict-import-show-percent--' + (this.props.status === ImportStatus.IMPORT_STARTED)]}>
                        <h2>{this.state.percent}%</h2>
                    </div>
                    <div
                        className={stylesScNavbar['dict-import-finished--' + (this.props.status === ImportStatus.IMPORT_FINISHED)]}>
                        <h4>{this.state.step === this.steps ? 'Importazione totale conclusa' : 'Importazione step ' + this.state.step + ' conclusa. Vai al passo successivo'}</h4>
                    </div>
                </Grid>
            </div>
        );
    }

}