import React from 'react';
import ReactDom from 'react-dom';
import Dict from 'dict-lib';
import {Grid, Row, Button} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import {setPlace} from './EntryActions';
import {getLexemeProperties, getRootEntryId} from './../../LexemePage';


export class EntryAddPlace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            load: false,
            items: [],
            selected: null,
            involvedList: ''
        }
    }

    componentDidMount() {
        if (!this.state.load) {
            Dict.call('/service/getPlaces').then((result) => {
                const place = this.props.item.place;
                const load = true;
                const items = result.items;
                const selected = typeof place !== 'undefined' && place !== null ? [{
                    id: place.id,
                    label: place.description
                }] : null;

                this.setState({selected, items, load}, () => {
                        this._getInvolved();
                });
            });
        }
    }

    _getInvolved() {
        if(this.state.selected !== null && Object.keys(this.state.selected).length > 0) {
            Dict.call('/service/search/getInvolvedBackOfficeEntityItemsCollection/Place/' + this.state.selected[0].id + '/null/').then((result) => {
                if (Object.keys(result.collection).length > 0) {
                    var involvedList = [];
                    result.collection.forEach((item) => {
                        var selectedItemId = this.props.item.lexeme_properties.root_entry_id;
                        if ((item.id + '') !== selectedItemId)
                            involvedList.push(item.label);
                    });
                    if (involvedList.length > 0) {
                        involvedList = involvedList.join(', ');
                        this.setState({involvedList: involvedList});
                    }
                }
            });
        } else
            this.setState({involvedList: ''});
    }

    _handleChange(selected) {
        this.setState({selected}, () => {
                this._getInvolved();
        });
    }

    render() {

        const selected = this.state.selected;
        const hasInvolved = this.state.involvedList.length > 0;

        const involvedDescr = hasInvolved ? <>
            <span style={{marginRight: '3px', marginLeft: '2px'}}>{Dict.t('OTHER_INVOLVED_PLACE_TOPONYM')}:</span>
            <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>{this.state.involvedList}</span>
        </> : '';


        return this.state.load ? <Grid fluid>
            <Row>
                <Typeahead
                    id={'entry-set-place'}
                    /*className={''}*/
                    clearButton={true}
                    multiple={false}
                    onChange={item => this._handleChange(item)}
                    options={this.state.items}
                    placeholder={Dict.t('SET_PLACE')}
                    selected={selected}
                />
            </Row>
            <Row style={{
                marginTop: '5px',
                textAlign: 'left',
                height: '25px'
            }}>{involvedDescr}</Row>
            <Row style={{marginTop: '5px', textAlign: 'right'}}>
                <Button
                    onClick={() => setPlace(getRootEntryId(), selected !== null && Object.keys(selected).length > 0 ? selected[0].id : null)}>{Dict.t('SAVE')}</Button>
            </Row>
        </Grid> : '';


    }
}