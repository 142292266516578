import React from 'react';
import Dict from 'dict-lib';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import {Grid, Row, Col, Button} from 'react-bootstrap';
import {LexemeUtilities, SoundableEntities} from './../../editor/LexemeHelper';
import {getLexemeProperties} from './../../LexemePage.js';
import {SCSimpleSoundPlayer} from './../../../../dict-components/components';
import './../../editor/root-entry/RootEntry.css';

export class EntryAddSound extends React.Component {

    constructor(props) {
        super(props);

        this.dropInfoMsg = Dict.t('MSG_DROP_FILE_HERE');
        this.dropOverMsg = Dict.t('MSG_ACCEPTED_FILE');
        this.dropLoadedMsg = Dict.t('MSG_LOADED_FILE');
        this.dropRejectMsg = Dict.t('MSG_REJECTED_FILE');

        this.state = {
            files: [],
            dropText: this.dropInfoMsg,
            soundItem: {},
            identifiableId: "",
            serviceType: "",
            additionalParam: ""
        };

        this._onDrop = this._onDrop.bind(this);
        this._onDragOver = this._onDragOver.bind(this);
        this._onDragLeave = this._onDragLeave.bind(this);
    }

    componentWillMount() {
        const soundType = this.props.additionalData.soundType;
        const item = this.props.item;
        var soundItem = "";
        var identifiableId = "";
        var serviceType = "";
        var additionalParam = "";

        if (soundType == SoundableEntities.ROOT_ENTRY) {
            soundItem = item.title;
            identifiableId = getLexemeProperties('root_entry_id');
            serviceType = "entry";
        } else if (soundType == SoundableEntities.LOC_VAR) {
            soundItem = item;
            identifiableId = item.id;
            serviceType = "entry";
        } else if (soundType == SoundableEntities.PHRASEOLOGY) {
            soundItem = this.props.additionalData.phraseItem;
            identifiableId = this.props.additionalData.phraseItem.id;
            serviceType = "phrases";
            additionalParam = LexemeUtilities.encodeUrl(JSON.stringify(this.props.item)) + "/";
        }

        this.setState({soundItem, identifiableId, serviceType, additionalParam});
    }

    componentDidMount() {
    }

    _onDrop(files) {
        this.setState({
                files, dropText: files.length > 0 ? this.dropLoadedMsg : this.dropRejectMsg
            }, function () {
                setTimeout(
                    function () {
                        this.setState({dropText: this.dropInfoMsg});
                    }.bind(this), 3000)
            }
        )
    }

    _onDragOver() {
        /*if (document.getElementsByClassName("add-sound-reject-drop-zone").length > 0) {
            this.setState({
                dropText: this.dropRejectMsg
            });
        } else {
            this.setState({
                dropText: this.dropOverMsg
            });
        }*/
    }

    _onDragLeave() {
        this.setState({
            dropText: this.dropInfoMsg
        });
    }

    _removeFile() {
        this.setState({files: []}, function () {
            this._saveFile();
        }.bind(this));
    }

    _resetFile() {
        this.setState({files: [], dropText: this.dropInfoMsg});
    }

    _saveFile() {
        if (this.state.files.length > 0) {
            var data = new FormData();
            data.append('file', this.state.files[0]);

            Dict.call('/entry/retrieveMediaData', {
                method: 'POST',
                data: data,
                headers: {}
            }).then(function (result) {
                if (typeof this.state.soundItem.multimedia_file !== 'undefined')
                    window.URL.revokeObjectURL(this.state.soundItem.multimedia_file);
                window.URL.revokeObjectURL(this.state.files[0].preview);

                LexemeUtilities.call('/' + this.state.serviceType + '/saveMultimedia/' + this.state.additionalParam + this.state.identifiableId + '/' + this.state.files[0].name + '/' + result.filename + '/')

            }.bind(this));
        } else {
            LexemeUtilities.call('/' + this.state.serviceType + '/removeMultimedia/' + this.state.additionalParam + this.state.identifiableId);
        }
    }

    render() {

        let dropzoneRef;

        const selectedFilesFound = this.state.files.length > 0;
        const f = this.state.files[0];
        const hasSavedFile = typeof this.state.soundItem.multimedia_file !== 'undefined' && Object.keys(this.state.soundItem.multimedia_file).length > 0;

        return <Grid className="grid-dropzone" fluid>
            <Col lg={6} md={6}><Row className="div-dropzone">
                <Dropzone
                    accept={"audio/*, audio/mpeg"}
                    rejectClassName={"add-sound-reject-drop-zone"}
                    acceptClassName="accept-drop-zone"
                    ref={(node) => {
                        dropzoneRef = node;
                    }} className="area-dropzone"
                    onDrop={acceptedFiles => this._onDrop(acceptedFiles)}
                    onDragOver={this._onDragOver}
                    onDragLeave={this._onDragLeave}
                    /*onDropRejected={this._onDropRejected}*/>
                    <p>{this.state.dropText}</p>
                </Dropzone>
            </Row>
                <Row className="div-dropzone-button">
                    <Button type="button" onClick={() => {
                        dropzoneRef.open()
                    }}>{Dict.t('OPEN_FILE_DIALOG')}</Button>
                    {hasSavedFile ? <Button style={{marginLeft: '3px'}} type="button" onClick={() => {
                        this._removeFile()
                    }}>{Dict.t('REMOVE')}</Button> : ""}
                    {selectedFilesFound ?
                        <Button style={{marginLeft: '3px'}} type="button" onClick={() => {
                            this._resetFile()
                        }}>{Dict.t('RESET')}</Button> : ""}
                    {selectedFilesFound ?
                        <Button style={{marginLeft: '3px'}} type="button" onClick={() => {
                            this._saveFile()
                        }}>{Dict.t('SAVE')}</Button> : ""}
                </Row>
            </Col>
            <Col lg={6} md={6}>
                {hasSavedFile ? <Row className="div-container-saved-file">
                    <Col lg={10} md={10}>{Dict.t('SAVED_FILE')}</Col><SCSimpleSoundPlayer
                    src={this.state.soundItem.multimedia_file}/>
                </Row> : ""}
                {selectedFilesFound ? <aside className={"entry-add-sound-dropped-file"}>
                    <Row className="div-dropped-files-label">{Dict.t('DROPPED_FILE') + ":"}</Row>
                    <Row className="div-container-dropped-file">
                        <div>{f.name + ' - ' + f.size + ' bytes'}</div>
                        <div><SCSimpleSoundPlayer
                            src={f.preview}/></div>
                    </Row>
                </aside> : ""}
            </Col>
        </Grid>
    }


}
