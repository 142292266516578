import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes  from 'prop-types';
import {deleteLocVar} from './LocVarActions';
import Dict from 'dict-lib';
import {LocVarPage} from './LocVarPage';
import {Button, Row, Col} from 'react-bootstrap';
import './LocVars.css';
import './../../../../_css/lexeme.css';
import {LexemeEditingLevel} from "../LexemeHelper";


export class LocVarDelete extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id
        };

        this._handleRemove = this._handleRemove.bind(this);
    }


    _handleRemove() {
        deleteLocVar(this.state.id);
    }


    render() {

        return <div className="locVar-delete">
            <Row>
                <Col lg={6} md={6} className="popup-label">{Dict.t('ASK_DELETE_LOC_VAR')}</Col>
                <Col lg={6} md={6}>
                    <Button onClick={this._handleRemove}>{Dict.t('YES')}</Button>
                </Col>
            </Row>
            { this.props.item.type === 'MAIN' ? <LocVarPage item={this.props.item} waitmsg={true}
                            full={false}/> : ""}
        </div>;
    }
}


