import React from "react";
import Dict from 'dict-lib';
import update from 'immutability-helper';
import {SCComponents} from '../../../dict-components/components';


export class LanguageOptionType extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            descriptionLanguages: this.props.descriptionLanguages,
            language: this.props.language,
            languageOptions: [],
            serviceComponents: [],
            collapseRadio: {}
        });

        this.initOptions = Dict.getLanguageOptionsById(this.state.language);

        this._handleLanguageOptions = this._handleLanguageOptions.bind(this);
        this._handleCheckRadioLanguageOptions = this._handleCheckRadioLanguageOptions.bind(this);
        this._handleCheckCheckLanguageOptions = this._handleCheckCheckLanguageOptions.bind(this);
        this._initComponents = this._initComponents.bind(this);
        this._initValue = this._initValue.bind(this);

        this._buildDescriptionLanguages = this._buildDescriptionLanguages.bind(this);
        this._buildDictionaryTypesLemmata = this._buildDictionaryTypesLemmata.bind(this);
        this._buildEntries = this._buildEntries.bind(this);
        this._buildSenses = this._buildSenses.bind(this);
        this._buildLinkBetweenSenses = this._buildLinkBetweenSenses.bind(this);
        this._buildLinkBetweenEntries = this._buildLinkBetweenEntries.bind(this);
        this._buildPageManager = this._buildPageManager.bind(this);
        this._buildEditPageManager = this._buildEditPageManager.bind(this);
        this._buildAnomalies = this._buildAnomalies.bind(this);
        this._buildPrintExport = this._buildPrintExport.bind(this);
    }

    componentWillMount() {
        if (this.props.group == 'descriptionLanguages') {
            this._buildDescriptionLanguages();
        } else if (this.props.group == 'dictionaryTypesLemmata') {
            this._buildDictionaryTypesLemmata();
        } else if (this.props.group == 'entries') {
            this._buildEntries();
        } else if (this.props.group == 'senses') {
            this._buildSenses();
        } else if (this.props.group == 'activeLinkBetweenSensesType') {
            this._buildLinkBetweenSenses();
        } else if (this.props.group == 'activeLinkBetweenEntriesType') {
            this._buildLinkBetweenEntries();
        } else if (this.props.group == 'pageManager') {
            this._buildPageManager();
        } else if (this.props.group == 'editPageManager') {
            this._buildEditPageManager();
        } else if (this.props.group == 'importManager') {
            this._buildImportManager();
        } else if (this.props.group == 'anomalies') {
            this._buildAnomalies();
        } else if (this.props.group == 'printExport') {
            this._buildPrintExport();
        }
    }

    _initValue(item) {

        var id = item.id;
        var retVal = this.initOptions[this.props.group + "->" + id];

        if (retVal == "true")
            retVal = true;
        else if (retVal == "false")
            retVal = false;
        else if (typeof retVal == "undefined")
            retVal = item.type == 'select' ? item.options[0].value : item.value;

        return retVal;
    }

    _initComponents(toLoad) {
        var collapseRadio = {};
        for (var lo in toLoad) {
            var component = toLoad[lo];

            component.value = this._initValue(component);

            if (component.type === 'checkbox-radio' && component.value !== false) {
                collapseRadio[component.id] = true;
                component.radio.value = component.value;
            }

            if (component.type === 'checkbox-checkbox') {
                collapseRadio[component.id] = component.value;
                component.checkbox.map(checkbox => {
                    checkbox.value = this._initValue(checkbox);
                    this._handleLanguageOptions(checkbox.id, checkbox.value, true);
                });
            }

            if (component.type === 'checkboxes-radio' && component.value !== false) {
                collapseRadio[component.id] = component.value;
                component.activeItems.forEach((checkbox, index) => {
                    checkbox.value = this._initValue(checkbox);
                    component.radio.radioItems[index].disabled = !checkbox.value;
                    this._handleLanguageOptions(checkbox.id, checkbox.value, true);
                });
            }

            this._handleLanguageOptions(component.id, component.value, true);
        }

        this.setState({
            languageOptions: toLoad,
            collapseRadio: collapseRadio
        });
    }

    _buildDescriptionLanguages() {
        /*var radioItems = [];
        var toLoad = [];*/

        /*for (var key in this.state.descriptionLanguages) {
            const obj = this.state.descriptionLanguages[key];
            toLoad.push({
                id: 'DESCR_LANGUAGE_ACTIVE_' + obj.id,
                type: 'checkbox',
                label: this.state.descriptionLanguages[key].label,
                value: false
            });
            radioItems.push({
                code: this.state.descriptionLanguages[key].id,
                label: this.state.descriptionLanguages[key].label
            })
        }

        toLoad.push({id: 'DESCR_LANGUAGE', type: 'radio', value: 1, items: radioItems});*/

        var activeItems = this.state.descriptionLanguages.map((item) => {
            return {id: ('DESCR_LANGUAGE_ACTIVE_' + item.id), label: item.label, value: false}
        });

        var radioItems = this.state.descriptionLanguages.map((item) => {
            return {code: item.id, label: item.label, disabled: false};
        });

        var toLoad = [{
            id: 'DESCR_LANGUAGE', type: 'checkboxes-radio', value: false,
            activeItems,
            radio: {
                radioItems
            }
        }];

        this._initComponents(toLoad);
    }

    _buildDictionaryTypesLemmata() {
        var collapseRadio = {};
        var toLoad = [
            {
                id: 'SHOW_LEXEME_IN_ALT_FORMS', type: 'checkbox-radio', value: false,
                radio: {
                    id: 'NO_ALT_FORMS',
                    collapse: 'SHOW_LEXEME_IN_ALT_FORMS',
                    items: [{
                        code: 'ALT_FORMS_NOT_AS_ENTRY',
                        label: Dict.t('ALT_FORMS_NOT_AS_ENTRY')
                    }, {
                        code: 'ALT_FORMS_LINKED_TO_MAIN',
                        label: Dict.t('ALT_FORMS_LINKED_TO_MAIN')
                    }]
                }
            }
        ];

        if (this.props.isLangIn) {
            toLoad.unshift({
                id: 'SHOW_LEXEME_IN_VAR_LOC', type: 'checkbox-radio', value: false,
                radio: {
                    id: 'NO_VAR_LOC',
                    collapse: 'SHOW_LEXEME_IN_VAR_LOC',
                    items: [{
                        code: 'VAR_LOC_NOT_IN_LEMMATA',
                        label: Dict.t('VAR_LOC_NOT_IN_LEMMATA')
                    }, {
                        code: 'VAR_LOC_AS_SEE',
                        label: Dict.t('VAR_LOC_AS_SEE')
                    }]
                }
            });
        }

        if (this.props.isLangIn) {
            toLoad.unshift({id: 'EXCLUDE_LEXO_IN_TYPE_4_5_6_7_8', type: 'checkbox', value: false});
        }

        toLoad.filter(function (item) {
            return item.type === 'checkbox-radio';
        }).map((item) => {
            collapseRadio[item.id] = item.value;
        });

        Dict.call('service/getDictionaryTypes/' + this.state.language).then(result => {
            var toLoadService = [];
            result.map(item => {
                toLoadService.push({
                    id: item,
                    value: false,
                    label: Dict.t(item) + " (" + item.replace('TYPE_', '') + ")",
                    type: 'checkbox'
                });
            });

            toLoadService.push(...toLoad);

            this._initComponents(toLoadService);
        });
    }

    _buildEntries() {
        var toLoad = [
            {
                id: 'ALLOW_LEXO_ROOT',
                type: 'checkbox-checkbox',
                value: false,
                checkbox: [
                    {
                        id: 'LEXOTYPE_HAS_ALT_FORM', type: 'checkbox', value: false
                    },
                    {
                        id: 'NEW_ROOT_IS_LEXOTYPE', type: 'checkbox', value: false
                    },
                    {
                        id: 'NEW_AREA_IS_LEXOTYPE', type: 'checkbox', value: false
                    }]
            },
            {id: 'ALLOW_EDIT_NOT_ATTESTED_ROOT', type: 'checkbox', value: false},
            {id: 'POLIES_ORDER_BY_CGR', type: 'checkbox', value: false},
            {id: 'LANGUAGE_POLIES_ALPHABETIC_ORDER', type: 'checkbox', value: false},
            {
                id: 'LANGUAGE_POLY_ARE_LEXO', type: 'radio', value: false, label: 'LANGUAGE_POLY_ARE_LEXO',
                items: [
                    {code: 'ALWAYS', label: Dict.t('ALWAYS')},
                    {code: 'IN_AREA_AND_MULTI', label: Dict.t('IN_AREA_AND_MULTI')},
                    {code: 'IN_MULTI', label: Dict.t('IN_MULTI')},
                    {code: 'NEVER', label: Dict.t('NEVER')}
                ]
            },
            {id: 'CGR_ALPHABETIC_ORDER', type: 'checkbox', value: false},
            {
                id: 'TRANSLATIONS_ALPHABETIC_ORDER',
                type: 'checkbox-checkbox',
                value: false,
                checkbox: [{id: 'ORDER_TRANSLATIONS_BY_CATEGORY', type: 'checkbox', value: false}]
            },
            {id: 'SHOW_SCROLLABLE_KEYS', type: 'checkbox', value: false},
            {id: 'SORT_BY_NUMBERING', type: 'checkbox', value: false},
            {id: 'FIRST_TR_IN_ENTRY_ABS', type: 'checkbox', value: false}
        ];

        Dict.call('service/getCgrs/' + this.state.language).then(result => {
            var optionsService = [];
            if (typeof result.items === 'undefined')
                result = {items: []};

            result.items.map(item => {
                optionsService.push({value: item.id + '', text: item.label});
            });

            //optionsService.push(...toLoad);

            var cgrSelect = {
                id: 'POLY_DEC_GRAM_CAT',
                type: 'select',
                label: Dict.t('POLY_DEC_GRAM_CAT'),
                options: optionsService
            };

            toLoad.push(cgrSelect);

            this._initComponents(toLoad);
        });
    }

    _buildSenses() {
        var toLoad = [
            {id: 'SHOW_TOPICS', value: false, type: 'checkbox'},
            {id: 'DEFINITION', value: false, type: 'checkbox'},
            {
                id: 'SENSE_DEFAULT_ABS_SYMBOL',
                type: 'select',
                label: Dict.t('SENSE_DEFAULT_ABS_SYMBOL'),
                options: [{value: '(-)', text: '(-)'}, {value: '-', text: '-'}],
            },
            {id: 'FIRST_SENSE_CHAR_TO_UPPER', value: false, type: 'checkbox'},
            {
                id: 'PHRASE_ORDER', type: 'radio', value: false, label: 'PHRASE_ORDER',
                items: [
                    {code: 'ALPHABETIC', label: Dict.t('ALPHABETIC')},
                    {code: 'MANUAL', label: Dict.t('MANUAL')},
                    {code: 'BY_DATING', label: Dict.t('BY_DATING')}
                ]
            },
            {id: 'PHRASE_ORIGINAL_SCRIPT', value: false, type: 'checkbox'},
            {id: 'BIBLIOGRAPHICAL_REFERENCE', value: false, type: 'checkbox'},
        ];

        this._initComponents(toLoad);
    }

    _buildLinkBetweenSenses() {
        var toLoad = [
            //{id: 'LINK_DEPRECATED_LOANWORD_CORRECTION', value: false, type: 'checkbox'},
            {id: 'LINK_DEPRECATED', value: false, type: 'checkbox'},
            {id: 'LINK_SYNONYM', value: false, type: 'checkbox'},
            {id: 'LINK_ANTONYM', value: false, type: 'checkbox'},
            {id: 'LINK_GENDER', value: false, type: 'checkbox'},
            {id: 'LINK_GOVERNMENT', value: false, type: 'checkbox'},
            {id: 'LINK_HYPERONYM', value: false, type: 'checkbox'},
            {id: 'LINK_REGARD', value: false, type: 'checkbox'},
            {id: 'LINK_SEE_ALSO', value: false, type: 'checkbox'},
            //{id: 'LINK_LEXICAL_AFFINITY', value: false, type: 'checkbox'}
            {id: 'LINK_LEXAFF', value: false, type: 'checkbox'}

        ];

        this._initComponents(toLoad);
    }

    _buildLinkBetweenEntries() {
        var toLoad = [];

        var introducers = [
            {value: 'U2192', text: '→'},
            {value: 'U2190', text: '←'},
            {value: 'U21A6', text: '↦'},
            {value: 'U21A4', text: '↤'},
            {value: 'U21E2', text: '⇢'},
            {value: 'U21E0', text: '⇠'},
            {value: 'U21D2', text: '⇒'},
            {value: 'U21D0', text: '⇐'},
            {value: 'U25BA', text: '►'},
            {value: 'U25C0', text: '◀'},
            {value: 'U25B7', text: '▷'},
            {value: 'U25C1', text: '◁'},
            {value: 'U263A', text: '☺'},
            {value: 'U2639', text: '☹'},
            {value: 'U2711', text: '✑'},
            {value: 'U2712', text: '✒'}
        ];

        var links = ['SEE_ENTRY', 'SEE_ALSO_ENTRY', 'DIASYNONYM', 'EXTERNAL_POLY', 'TO_DIMINUITIVE', 'SUGGESTED_REF_FORM'];

        for (var i = 0; i < links.length; i++) {
            var name = links[i];
            toLoad.push({id: name, value: false, type: 'checkbox'});
            toLoad.push({id: name + "_INTRODUCER", label: Dict.t('DIRECT'), type: 'select', options: introducers});
            toLoad.push({
                id: "INVERSE_" + name + "_INTRODUCER",
                label: Dict.t('INVERSE'),
                type: 'select',
                options: introducers
            });
        }

        toLoad.push({text: Dict.t('LBTE_ADDITIONAL_OPTIONS_WARNING'), type: 'label', messageType: 'warning'});

        toLoad.push({id: 'SHOW_DIASYNONYM_LOC_VARS', value: false, type: 'checkbox'});
        toLoad.push({id: 'SHOW_SUGGESTED_FORM_LOC_VARS', value: false, type: 'checkbox'});
        toLoad.push({id: 'EXTERNAL_POLY_AS_INTERNAL', value: false, type: 'checkbox'});

        this._initComponents(toLoad);
    }

    _buildPageManager() {
        var toLoad = [
            {
                id: 'SHOW_CGR_IN_TRANSLATION_PREVIEW',
                type: 'radio',
                value: false,
                label: 'SHOW_CGR_IN_TRANSLATION_PREVIEW',
                items: [
                    {code: 'ALWAYS', label: Dict.t('ALWAYS')},
                    {code: 'NEVER', label: Dict.t('NEVER')},
                    {code: 'ONLY_IF_DIFFERENT', label: Dict.t('ONLY_IF_DIFFERENT')},
                ]
            },
            {
                id: 'HYPHENATION_CONTAINER',
                type: 'select',
                label: Dict.t('HYPHENATION_CONTAINER'),
                options: [{value: '', text: Dict.t('NOTHING')}, {value: '8249^8250', text: '‹ ›'}, {
                    value: '//',
                    text: '/ /'
                }],
            },
            {
                id: 'ETYMO_CONTAINER',
                type: 'select',
                label: Dict.t('ETYMO_CONTAINER'),
                options: [{value: '', text: Dict.t('NOTHING')}, {value: '[]', text: '[ ]'}],
            },
            {
                id: 'MORPHO_CONTAINER',
                type: 'select',
                label: Dict.t('MORPHO_CONTAINER'),
                options: [{value: '', text: Dict.t('NOTHING')}, {value: '()', text: '( )'}],
            },
            {
                id: 'IPA_CONTAINER',
                type: 'select',
                label: Dict.t('IPA_CONTAINER'),
                options: [{value: '', text: Dict.t('NOTHING')}, {value: '//', text: '/ /'}],
            },
            {
                id: 'SIMPLIFY_IPA_CONTAINER',
                type: 'select',
                label: Dict.t('SIMPLIFY_IPA_CONTAINER'),
                options: [{value: '', text: Dict.t('NOTHING')}, {value: '8249^8250', text: '‹ ›'}, {
                    value: '//',
                    text: '/ /'
                }],
            },
            {id: 'SHOW_PARADIGM', type: 'checkbox', value: false},
            {id: 'SHOW_LOCAL_NOTES', type: 'checkbox', value: false},
            {id: 'LEXOTYPE_IN_VAR_LOC', type: 'checkbox', value: false},
            {id: 'TRANSLATION_REPRESENTER_IS_ROOT_ENTRY', type: 'checkbox', value: false},
            {id: 'MORPHOLOGY_SEPARATED', type: 'checkbox', value: false},
            {id: 'PRINT_TR_DEF_ABS', type: 'checkbox', value: false},
            {id: 'PRINT_ROOT_LANGUAGE_IN_TITLE', type: 'checkbox', value: false},
        ];

        this._initComponents(toLoad);
    }

    _buildEditPageManager() {
        var toLoad = [
            {id: 'SHOW_TRANSLATIONS_IN_PREVIEW', value: false, type: 'checkbox'},
            {id: 'SHOW_USAGE_IN_TRANSLATION', value: false, type: 'checkbox'},
            {id: 'SHOW_NOTES_IN_TRANSLATION', value: false, type: 'checkbox'},
            {id: 'SHOW_SUP_IN_TRANSLATION', value: false, type: 'checkbox'},
            {id: 'SHOW_INVERSE_SEE_ENTRY_IN_PREVIEW', value: false, type: 'checkbox'},
            {id: 'SHOW_PREVIEW_FOOTER', value: false, type: 'checkbox'},
            {id: 'SHOW_CGR_POLY_IN_PREVIEW', value: false, type: 'checkbox'},
            {id: 'SHOW_POLY_NUMBERING', value: false, type: 'checkbox'},
            {id: 'SHOW_PHRASEOLOGY_NUMBERING', value: false, type: 'checkbox'},
            {id: 'SHOW_SENSE_NUMBERING', value: false, type: 'checkbox'},
            {id: 'SHOW_SUBENTRY_NUMBERING', value: false, type: 'checkbox'},
            {
                id: 'SHOW_SENSE_LANG_DESCRIPTION',
                value: false,
                type: 'checkbox-checkbox',
                checkbox: [{id: 'SHOW_ALL_SENSE_DESCRIPTIONS', type: 'checkbox', value: false}]
            },
            {id: 'SHOW_ALT_FORMS_NEXT_TO_MAIN', value: false, type: 'checkbox'},
            {id: 'SHOW_MAIN_FORM_IN_ALL_FORMS_OF', value: false, type: 'checkbox'},
            {id: 'SHOW_HIDDEN_LEXEMES', value: false, type: 'checkbox'},
            {id: 'SHOW_MAIN_IN_ALT_FORM_LEXEME', value: false, type: 'checkbox'},
            {id: 'SHOW_UNTRANSLATED_SENSES', value: false, type: 'checkbox'},
            {id: 'SHOW_UNTRANSLATED_PHRASES', value: false, type: 'checkbox'},
            {id: 'SHOW_SUBENTRY_WITHOUT_SENSES', value: false, type: 'checkbox'},
            {id: 'SHOW_NOTES', value: false, type: 'checkbox'},
            {id: 'SHOW_SENSE_NOTES', value: false, type: 'checkbox'},
            {id: 'HIDE_HYPHENATION', value: false, type: 'checkbox'},
            {id: 'SUBENTRY_FORM_BEFORE_CGR', value: false, type: 'checkbox'},
            {id: 'GENERAL_PHRASE_AT_FIRST_SENSE', value: false, type: 'checkbox'},
            {id: 'SHOW_TRANSLATING_IMAGES', value: false, type: 'checkbox'}
        ];

        this._initComponents(toLoad);
    }

    _buildImportManager() {
        var toLoad = [
            {
                id: 'DEFAULT_PREFIX_MERGE',
                type: 'select',
                label: Dict.t('DEFAULT_PREFIX_MERGE'),
                options: [{value: '_', text: '_'}, {value: '###', text: '###'}]
            }
        ];

        Dict.call('service/getCgrs/' + this.state.language).then(result => {
            var optionsService = [];
            result.items.map(item => {
                optionsService.push({value: item.id + '', text: item.label});
            });

            //optionsService.push(...toLoad);

            var cgrSelect = {
                id: 'GROUPING_MERGE_DEC_GRAM_CAT',
                type: 'select',
                label: Dict.t('GROUPING_MERGE_DEC_GRAM_CAT'),
                options: optionsService
            };

            toLoad.push(cgrSelect);

            this._initComponents(toLoad);
        });

        //this._initComponents(toLoad);
    }

    _buildAnomalies() {
        var toLoad = [
            {id: 'HOMOGRAPHIC_ALTF_INDEX', type: 'checkbox', value: false},
            {id: 'ROOT_NOT_ATTESTED_NEED_IDENTICAL_VAR_LOC', type: 'checkbox', value: false},
            {id: 'PUNCTUAL_CAN_BE_LEXO', type: 'checkbox', value: false},
        ];

        this._initComponents(toLoad);
    }

    _buildPrintExport() {
        var toLoad = [
            {
                id: 'POLY_INTRODUCER',
                type: 'select',
                label: Dict.t('POLY_INTRODUCER'),
                options: [
                    {value: '', text: Dict.t('NOTHING')},
                    {value: '9830', text: ' ♦ '},
                    {value: '9826', text: ' ♢ '},
                    {value: '~', text: ' ~ '},
                ],
            },
            {
                id: 'PHRASEOLOGY_INTRODUCER',
                type: 'select',
                label: Dict.t('PHRASEOLOGY_INTRODUCER'),
                options: [
                    {value: '', text: Dict.t('NOTHING')},
                    {value: '9830', text: ' ♦ '},
                    {value: '9826', text: ' ♢ '}
                ],
            },
            {
                id: 'TRANSLATION_SEPARATOR',
                type: 'select',
                label: Dict.t('TRANSLATION_SEPARATOR'),
                options: [
                    {value: '', text: Dict.t('NOTHING')},
                    {value: '44', text: ',[NO_SPACE]'},
                    {value: '44^32', text: ', [SPACE]'},
                    {value: '32^45^32', text: ' - '},
                    {value: '9632', text: ' ■ '},
                    {value: '9633', text: ' □ '},
                    {value: '9679', text: ' ● '},
                    {value: '9675', text: ' ○ '},
                ],
            },
            {
                id: 'POLY_SEPARATOR',
                type: 'select',
                label: Dict.t('POLY_SEPARATOR'),
                options: [
                    {value: '', text: Dict.t('NOTHING')},
                    {value: '44', text: ',[NO_SPACE]'},
                    {value: '44^32', text: ', [SPACE]'},
                    {value: '59', text: ';[NO_SPACE]'},
                    {value: '59^32', text: '; [SPACE]'},
                    {value: '9632', text: ' ■ '},
                    {value: '9633', text: ' □ '},
                    {value: '9679', text: ' ● '},
                    {value: '9675', text: ' ○ '},
                ],
            },
            {
                id: 'SENSE_SEPARATOR',
                type: 'select',
                label: Dict.t('SENSE_SEPARATOR'),
                options: [
                    {value: '', text: Dict.t('NOTHING')},
                    {value: '44', text: ',[NO_SPACE]'},
                    {value: '44^32', text: ', [SPACE]'},
                    {value: '59', text: ';[NO_SPACE]'},
                    {value: '59^32', text: '; [SPACE]'},
                    {value: '9632', text: ' ■ '},
                    {value: '9633', text: ' □ '},
                    {value: '9679', text: ' ● '},
                    {value: '9675', text: ' ○ '},
                ],
            },
            {
                id: 'PHRASEOLOGY_SEPARATOR',
                type: 'select',
                label: Dict.t('PHRASEOLOGY_SEPARATOR'),
                options: [
                    {value: '', text: Dict.t('NOTHING')},
                    {value: '44', text: ',[NO_SPACE]'},
                    {value: '44^32', text: ', [SPACE]'},
                    {value: '59', text: ';[NO_SPACE]'},
                    {value: '59^32', text: '; [SPACE]'},
                    {value: '9632', text: ' ■ '},
                    {value: '9633', text: ' □ '},
                    {value: '9679', text: ' ● '},
                    {value: '9675', text: ' ○ '},
                ],
            }
        ];

        this._initComponents(toLoad);
    }


    _handleLanguageOptions(id, value, isInit) {
        if (typeof isInit == 'undefined')
            isInit = false;
        this.props.callbackParent(this.props.group, id, value, isInit);
    }

    _handleCheckRadioLanguageOptions(id, value) {
        const upd = update(this.state.collapseRadio, {$merge: {[id]: !this.state.collapseRadio[id]}});

        this.setState({collapseRadio: upd});
        this._handleLanguageOptions(id, value);
    }

    _handleCheckItemsRadio_checkBoxes(itemId, id, value) {
        var languageOptions = this.state.languageOptions;
        var actives = languageOptions[0].radio.radioItems.filter((item) => {
            return !item.disabled;
        });

        var isLast = actives.length === 1 && (actives[0].code + '') === id.replace('DESCR_LANGUAGE_ACTIVE_', '');

        if (!isLast) {
            for (const languageOption of languageOptions) {
                if (languageOption.id === itemId) {
                    var radioItems = languageOption.radio.radioItems;
                    for (const radioItem of radioItems) {
                        if ((radioItem.code + '') === id.replace('DESCR_LANGUAGE_ACTIVE_', ''))
                            radioItem.disabled = !value;
                    }
                }
            }

            this._handleLanguageOptions(id, value);
            this.setState({languageOptions});
        }
    }

    _handleCheckItemsRadio_radio(id, value) {
        this._handleLanguageOptions(id, value);
        var languageOptions = this.state.languageOptions;
        languageOptions[0].value = value;
        this.setState({languageOptions});
    }

    _handleCheckCheckLanguageOptions(id, value) {
        const upd = update(this.state.collapseRadio, {$merge: {[id]: !this.state.collapseRadio[id]}});

        this.setState({collapseRadio: upd});
        this._handleLanguageOptions(id, value);
    }

    render() {
        const components = this.state.languageOptions.map(item => {
                const callback = item.type === 'radio' || item.type === 'select' ? (newState) => this._handleLanguageOptions(item.id, newState) : (id, newState) => this._handleLanguageOptions(item.id, newState);
                if (item.type === 'checkbox-checkbox') {
                    /*const nestedcallback = (newState) => this._handleLanguageOptions(item.checkbox.id, newState);*/

                    var nestedcallback = [];
                    item.checkbox.map((checkbox) => nestedcallback.push((id, newState) => this._handleLanguageOptions(checkbox.id, newState)));

                    return SCComponents.getSCCheckboxWithCheckboxByItem(item, item.checkbox, this.state.language, (id, newState) => this._handleCheckCheckLanguageOptions(item.id, newState), nestedcallback, this.state.collapseRadio[item.id]);
                }
                if (item.type === 'checkbox-radio')
                    return SCComponents.getSCCheckboxWithRadioByItem(item, this.state.language, (newState) => this._handleCheckRadioLanguageOptions(item.id, newState), callback, this.state.collapseRadio[item.id]);
                if (item.type === 'checkboxes-radio')
                    return SCComponents.getSCCheckboxItemsWithRadio(item, this.state.language, (chkId, newState) => this._handleCheckItemsRadio_checkBoxes(item.id, chkId, newState), (newState) => this._handleCheckItemsRadio_radio(item.id, newState));
                else if (item.type === 'label') {
                    return <div key={'key-option-label-' + this.state.language}
                                className={'optionLabel btn-' + item.messageType}>{item.text}</div>;
                } else {
                    return SCComponents.getSCComponentItem(item, this.state.language, callback);
                }
            }
        );

        return <div>
            {components}
        </div>
    }
}