import React from "react";
import ReactDOM from "react-dom";
import update from 'immutability-helper';
import Dict from 'dict-lib';
import {LanguageOptionType} from './LanguageOptionType';
import {
    Button,
    Collapse,
    Well,
    ButtonToolbar,
    ButtonGroup,
    Glyphicon,
    FormGroup
} from 'react-bootstrap';
import './../Options.css';

export class LanguageOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            isLangIn: true,
            language: this.props.language,
            descriptionLanguages: this.props.descriptionLanguages,
            languageOptions: {},
            optionGroups: [
                {flag: 'descriptionLanguages', name: 'DESCRIPTION_LANGUAGES'},
                {flag: 'dictionaryTypesLemmata', name: 'DICTIONARY_TYPES_LEMMATA'},
                {flag: 'entries', name: 'ENTRIES'},
                {flag: 'senses', name: 'SENSES'},
                {flag: 'activeLinkBetweenSensesType', name: 'ACTIVE_LINK_BETWEEN_SENSES_TYPE'},
                {flag: 'activeLinkBetweenEntriesType', name: 'ACTIVE_LINK_BETWEEN_ENTRIES_TYPE'},
                {flag: 'pageManager', name: 'PAGE_MANAGER'},
                {flag: 'editPageManager', name: 'EDIT_PAGE_MANAGER'},
                {flag: 'importManager', name: 'IMPORT_MANAGER'},
                {flag: 'anomalies', name: 'ANOMALIES'},
                {flag: 'printExport', name: 'PRINT_EXPORT'}
            ],
            saveLanguageOptions: 'primary',
            saveLanguageOptionsGlyph: '',
            activeGroup: '',
            collapseIn: false
        });

        this.additionalWellClass = '';

        this._handleLanguageOptions = this._handleLanguageOptions.bind(this);
        this._saveLanguageOptions = this._saveLanguageOptions.bind(this);
        this._handleChangeText = this._handleChangeText.bind(this);
        this._updateLanguageOptionsState = this._updateLanguageOptionsState.bind(this);
        this._openGroup = this._openGroup.bind(this);
        this._resetCollapse = this._resetCollapse.bind(this);
        this._isChecked = this._isChecked.bind(this);
    }

    componentWillMount() {
        Dict.call('/service/isLangIn/' + this.props.language).then((result) => {
            this.setState({isLangIn: JSON.parse(result)});
        })
    }

    _handleLanguageOptions(id, value) {
        this._updateLanguageOptionsState(id, value);
    }


    _handleChangeText(evt, item) {
        item.value = evt.target.value;
        this.setState(this.state);
    }


    _openGroup(item) {
        var obj = ReactDOM.findDOMNode(this.refs['optionWellRef-' + this.state.language]);
        obj.scrollTop += 600;

        this.setState({
            activeGroup: item.name,
            collapseIn: false,
        }, function () {
            this._resetCollapse(item);
        }.bind(this));
    }

    _isChecked(languageOptions, prop) {
        if (languageOptions[prop] == "true")
            return true;
        else if (languageOptions[prop] == "false")
            return false;
        else {
            return false;
        }
    }


    _updateLanguageOptionsState(group, property, value, isInit) {
        var upd = this.state.languageOptions;
        upd[group + '->' + property] = value;
        const saveLanguageOptions = isInit ? "primary" : "warning";
        const saveLanguageOptionsGlyph = isInit ? "" : "hand-right";

        this.setState({
            languageOptions: upd,
            saveLanguageOptions: saveLanguageOptions,
            saveLanguageOptionsGlyph: saveLanguageOptionsGlyph
        }, function () {
            if (false)
                console.log(this.state.languageOptions);
        });
    }


    _resetCollapse(item) {
        setTimeout(function () {
            this.setState({
                languageOptions: {},
                collapseIn: true,
                saveLanguageOptions: "primary",
                saveLanguageOptionsGlyph: ""
            }, function () {
                const key = 'key-' + item.flag + "-" + this.state.language;
                const LanguageGroup = <LanguageOptionType key={key} language={this.state.language} group={item.flag}
                                                          isLangIn={this.state.isLangIn}
                                                          descriptionLanguages={this.state.descriptionLanguages}
                                                          callbackParent={(group, id, newState, isInit) => this._updateLanguageOptionsState(group, id, newState, isInit)}/>;

                ReactDOM.render(LanguageGroup, document.getElementById('lang-options-components-' + this.state.language));
                this.additionalWellClass = item.flag;
            });
        }.bind(this), 300);
    }

    _saveLanguageOptions() {
        Dict.call('admin/options/updateLanguageOptions/' + this.state.language + '/' + JSON.stringify(this.state.languageOptions)).then(result => {
            var bsStyle = result == 'OK' ? 'success' : 'danger';
            var glyph = result == 'OK' ? 'thumbs-up' : 'thumbs-down';
            Dict.loadProperties();

            this.setState({
                saveLanguageOptions: bsStyle,
                saveLanguageOptionsGlyph: glyph
            });

            setTimeout(function () {
                this.setState({
                    saveLanguageOptions: 'primary',
                    saveLanguageOptionsGlyph: ''
                });
            }.bind(this), 3000);
        });
    }


    render() {

        const optionGroupButtons = this.state.optionGroups.map((item) =>
            <Button key={'key-' + this.state.language + '-bt-' + item.flag} onClick={ ()=> this._openGroup(item)}
                    active={item.flag == this.state.activeGroup}>
                {Dict.t(item.name)}
            </Button>
        );


        return <div className="container-fluid languageOptionsButtonBar">
            <ButtonToolbar>
                <ButtonGroup>
                    {optionGroupButtons}
                </ButtonGroup>
            </ButtonToolbar>
            <Collapse in={this.state.collapseIn}>
                <div>
                    <Well ref={"optionWellRef-" + this.state.language} className={this.additionalWellClass}>
                        <div id={"lang-options-components-" + this.state.language}></div>
                        <FormGroup id="option-tab-language-group-button">
                            <Button bsStyle={this.state.saveLanguageOptions}
                                    onClick={this._saveLanguageOptions}><Glyphicon style={{paddingRight: '4px'}}
                                                                                   glyph={this.state.saveLanguageOptionsGlyph}/>{Dict.t('SAVE')}
                            </Button>
                        </FormGroup>
                    </Well>
                </div>
            </Collapse>
        </div>

    }
}

