import React from 'react';
import ReactDOM from 'react-dom';
import {AdministrationUtilities} from './../administration/AdministrationHelper';
import {DictionarySettings} from "../utilities/DictUtilities";
import {LanguageTypeahead} from '../dict-components/typeahead/LanguageTypeahead';
import {EntryTypeahead, SCCheckbox} from '../dict-components/components';
import {SCRadiobutton} from '../dict-components/components';
import {
    Form,
    FormControl,
    FormGroup,
    InputGroup,
    Grid,
    Col,
    Row,
    ControlLabel,
    Button,
    Well,
    Glyphicon,
    Collapse,
    ButtonToolbar,
    ToggleButtonGroup,
    ToggleButton
} from 'react-bootstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import update from 'immutability-helper';
import {performSearch} from "./lib/search";
import Dict from 'dict-lib';
import {CgrTypeahead} from "../dict-components/components";
import {AsyncTypeahead, Typeahead} from "react-bootstrap-typeahead";
import {EditingStatusTypes, StatusType, LexemeStatusTypeSearchItemsArray} from "../lexeme/page/editor/LexemeHelper";
import {userService} from "../_redux/authentication/services/user.services";

import stylesSearchMenu from './../_css/dictionary/searchMenu.module.css';
import stylesSearchPage from './../_css/dictionary/searchPage.module.css';
import BUtils from './../utilities/BaseUtilities';
import stylesScNavbar from "../dict-components/navbar/ScNavbar.module.css";
import stylesSCTree from "../dict-components/tree/SCTree.module.css";

export class SearchMenu extends React.Component {
    constructor(props) {
        super(props);

        this.enableDebugMode = false;
        this.maxFormsLength = 3;
        this.additionalForms = [];
        this.optimisticStatusText = '';
        this.isTreeModelDictionary = Dict.getVarious('is_tree_model_dictionary');
        this.isToponym = this.props.module.code === 'TOPONOMASTIC';
        this.useToponomastic = DictionarySettings.useToponomastic();

        this.state = {
            searchParams: {
                forms: [''],
                operator: 'OR',
                className: 'Entry',
                specialChars: false,
                toponym: this.isToponym ? 'ONLY' : 'INCLUDE',
                nPage: 1,
                langId: AdministrationUtilities.getSearchLangItem().id,
                specialSearch: {
                    revisionStatus: '',
                    lexemeHaving: 'ROOT_ENTRY',
                    status: '',
                    cgrs: []
                }
            },
            revisionStatusItems: {},
            statusTypeItems: {},
            collapseSearchMenu: true,
            searchWrapperOverflow: 'unset',
            buttonMenuCollapse: "menu-open",
            buttonMenuGlyph: "triangle-top",
            result: [{type: "", fields: {id: '', status: '', entryForm: '', language: '', entryAbstr: ''}}],
            isLoading: false,
            isButtonStateDisabled: true,
            showSpecialSearch: false
        };

        this.rootLanguages = Dict.getVarious('root_languages');

        this._handleSearchFormChange = this._handleSearchFormChange.bind(this);
        this._handleCheckboxChange = this._handleCheckboxChange.bind(this);
        this._handleRadioChange = this._handleRadioChange.bind(this);
        this._handleRadioLangChange = this._handleRadioLangChange.bind(this);
        this._handleLanguage = this._handleLanguage.bind(this);

        /*this._handleKeyUp = this._handleKeyUp.bind(this);*/
        this._handlePreventRefresh = this._handlePreventRefresh.bind(this);
        this._search = this._search.bind(this);
        this._addField = this._addField.bind(this);
        this._removeField = this._removeField.bind(this);
        this._resetStateByForms = this._resetStateByForms.bind(this);

        this._revisionStatusHandleChange = this._revisionStatusHandleChange.bind(this);
        this._cgrsHandleChange = this._cgrsHandleChange.bind(this);
        this._handleLexemeHavingRadioChange = this._handleLexemeHavingRadioChange.bind(this);
        this._statusTypeHandleChange = this._statusTypeHandleChange.bind(this);
        this._updateSpecialSearchParamsState = this._updateSpecialSearchParamsState.bind(this);

    }


    componentWillMount() {
        this._resetSearch();
    }

    componentDidMount() {
        this._focusOnMainForm();

        var revisionStatusItems = [];
        for (const editingStatusType in EditingStatusTypes) {
            revisionStatusItems.push({id: editingStatusType, label: Dict.t(editingStatusType)});
        }

        var statusTypeItems = [];
        for (const statusType in StatusType) {
            statusTypeItems.push({id: statusType, label: Dict.t(statusType)});
        }

        this.setState({revisionStatusItems, statusTypeItems});

        this.optimisticStatusText = AdministrationUtilities.printOptimisticStatus();
    }

    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.searchParams.langId !== nextState.searchParams.langId;
    }*/

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.searchParams.langId !== this.state.searchParams.langId) {
            this._resetSearch();
        }
    }

    _resetSearch() {
        const urls = [
            'service/search/getSearchClassesByLanguage/' + this.state.searchParams.langId + '/',
            'service/search/getStringFormsOption'
            /*,'service/isTreeModelDictionary'*/
        ];

        Promise.all(urls.map(url => Dict.call(url).then((result) => {
                this.setState(result);
            })
        ));
    }

    _focusOnMainForm() {
        setTimeout(() => {
            document.getElementById('dict-menu-forms-container').getElementsByTagName('input')[0].focus();
            //ReactDOM.findDOMNode(this.mainForm).focus();
            /*const ref = React.createRef();
            ref.current.focus();*/
        }, 10);
    }

    _collapseOpen() {
        var collapseSearchMenu = !this.state.collapseSearchMenu;

        this.setState({
            collapseSearchMenu,
            searchWrapperOverflow: 'hidden'
        }, function () {
            ReactDOM.findDOMNode(this.buttonMenuCollapse).blur();
            /*var resultContent = document.getElementById('dict-result-content');
            var fullClass = stylesSearchPage['dict-result-content--full'];
            resultContent.classList.toggle(fullClass);*/
            setTimeout(function () {
                if (collapseSearchMenu)
                    this.setState({searchWrapperOverflow: 'unset'})
            }.bind(this), 500);
        });
    }

    _collapseOnExited() {
        this.setState({
            buttonMenuCollapse: "menu-close",
            buttonMenuGlyph: "triangle-bottom"
        });
    }

    _collapseOnEntered() {
        this.setState({
            buttonMenuCollapse: "menu-open",
            buttonMenuGlyph: "triangle-top"
        });
    }

    _updateSearchParamsState(property, value, fromSmartCombo) {
        const upd = update(this.state.searchParams, {$merge: {[property]: value}});
        this.setState({searchParams: upd}, function () {
            if (this.enableDebugMode)
                console.log(this.state.searchParams);
            if (typeof fromSmartCombo !== 'undefined' && fromSmartCombo === true) {
                ReactDOM.findDOMNode(this.searchButton).click();
            }
        });
    }

    _handlePreventRefresh(e) {
        e = e || window.event;
        var key = e.charCode;
        if (key === 13) //Enter
        {
            e.preventDefault();
            ReactDOM.findDOMNode(this.searchButton).click();
            //do you task here...
            //return false; //return true to submit, false to do nothing
        }
    }

    /*_handleKeyUp(event) {
        if (event.keyCode === 13) {
            ReactDOM.findDOMNode(this.searchButton).click();
            //this.searchButton.click();
        }
    }*/

    _handleCheckboxChange(id, newState) {
        this._updateSearchParamsState(id, newState);
    }

    /*_handleSearchFormChange(nForm, event) {
        var newArray = this.state.searchParams.forms;
        newArray[nForm > 0 ? nForm - 1 : 0] = event.target.value;
        this._updateSearchParamsState("forms", newArray);
    }*/

    _handleSearchFormChange(nForm, text, fromSmartCombo) {
        var newArray = this.state.searchParams.forms;
        if (fromSmartCombo) {
            newArray[0] = text.label;
        } else {
            newArray[nForm > 0 ? nForm - 1 : 0] = text;
        }
        this._updateSearchParamsState("forms", newArray, fromSmartCombo);
    }

    _handleRadioChange(newState) {
        this._updateSearchParamsState("className", newState);
    }

    _handleToponymRadioChange(newState) {
        this._updateSearchParamsState("toponym", newState);
    }

    _handleRadioLangChange(newState) {
        this._updateSearchParamsState("langId", newState);
        this._focusOnMainForm();
        setTimeout(function () {
            AdministrationUtilities.resetSkin(newState);
        }, 100);
    }

    _handleLanguage(newState) {
        this._updateSearchParamsState("langId", newState);
        this._focusOnMainForm();
        AdministrationUtilities.resetSkin(newState);
    }

    _handleLogicSearch(newState) {
        this._updateSearchParamsState("operator", newState);
        Object.keys(this.refs).filter(key => key.substr(0, 5) === 'addon').forEach(key => {
            ReactDOM.findDOMNode(this.refs[key]).innerText = newState;
        })
    }

    _search() {
        this._updateSearchParamsState("nPage", 1);
        this.setState(
            {
                isLoading: true,
            }, function () {
                performSearch(this.state.searchParams).then(() => {
                    this.setState({
                        isLoading: false
                    })
                });
            });
    }

    _addField() {
        this.state.searchParams.forms.push('');
        this._resetStateByForms(false);
    }

    _removeField() {
        this.state.searchParams.forms.pop();
        this.additionalForms.pop();

        this._resetStateByForms(this.additionalForms.length === 0);
    }

    _resetStateByForms(isMinusButtonDisabled) {
        const upd = update(this.state.searchParams, {$merge: {forms: this.state.searchParams.forms}});
        this.setState({
            searchParams: upd,
            isButtonStateDisabled: isMinusButtonDisabled,
            in: !this.state.in
        });
    }

    _renderMenuRevisionStatusItems(option, props, index) {
        return [
            <div key={"revision-status-item-" + index}
                 className={BUtils.joinClasses(stylesSearchMenu[option.id + 'RevisionStatusItem'], stylesSearchMenu['revisionStatusItem'])}>{option.label}</div>,
        ];
    }

    _revisionStatusHandleChange(item) {
        this._updateSpecialSearchParamsState('revisionStatus', item.length > 0 ? item[0].id : '');
    }

    _cgrsHandleChange(items) {
        var cgrs = [];

        for (const item of items) {
            cgrs.push(item.id);
        }

        this._updateSpecialSearchParamsState('cgrs', cgrs);
    }

    _handleLexemeHavingRadioChange(item) {
        this._updateSpecialSearchParamsState('lexemeHaving', item);
    }

    _statusTypeHandleChange(item) {
        this._updateSpecialSearchParamsState('status', item.length > 0 ? item[0].id : '');
    }

    _updateSpecialSearchParamsState(property, value) {
        const upd = update(this.state.searchParams.specialSearch, {$merge: {[property]: value}});
        var searchParams = JSON.parse(JSON.stringify(this.state.searchParams));
        searchParams.specialSearch = upd;

        this.setState({searchParams}, function () {
            this._focusOnMainForm();
            if (this.enableDebugMode)
                console.log(this.state.searchParams);
        });
    }


    render() {

        userService.unloggedReload();
        const showHide = (this.state.showSpecialSearch ? 'show' : 'hide');

        var revisionStatusItemChildrenProps = {};
        revisionStatusItemChildrenProps.renderMenuItemChildren = this._renderMenuRevisionStatusItems;

        var formsLength = this.state.searchParams.forms.length;

        if (this.additionalForms.length !== (formsLength - 1) && formsLength <= this.maxFormsLength) {
            var keyPrefix = "dict-form-" + (formsLength - 1);
            var refAddon = "addon" + (formsLength - 1);
            const AdditionalForm = <InputGroup key={keyPrefix}>
                <InputGroup.Addon key={refAddon} ref={refAddon}>{this.state.searchParams.operator}</InputGroup.Addon>
                <FormControl type="text" placeholder={Dict.t('ENTRY') + "..."}
                             value={this.state.form}
                             onChange={(evt) => this._handleSearchFormChange(formsLength, evt.target.value)}
                             onKeyUp={this._handleKeyUp}/>
            </InputGroup>;

            this.additionalForms.push(AdditionalForm);
        }

        var buttonOrientation = this.state.showSpecialSearch ? 'right' : 'left';

        const SpecialMenuButton = () => {
            return <div id={"special-search-menu-glyph"}
                        className={BUtils.joinClasses(stylesSearchMenu['special-search-menu-glyph'], stylesSearchMenu['special-search-menu-glyph--' + buttonOrientation])}>
                <Button
                    ref={(button) => {
                        this.buttonSpecialSearch = button;
                    }}
                    onClick={() => {
                        ReactDOM.findDOMNode(this.buttonSpecialSearch).blur();
                        this.setState({showSpecialSearch: !this.state.showSpecialSearch})
                    }}><Glyphicon glyph={'triangle-' + buttonOrientation}/>
                </Button>
            </div>
        };


        return <div id="dict-menu-container">
            <Row className={stylesSearchMenu['dict-menu-container__row-bt-open-close']}><Button
                id="bt-hide-show-searchmenu"
                ref={(button) => {
                    this.buttonMenuCollapse = button;
                }}
                onClick={() => this._collapseOpen()}><Glyphicon
                glyph={this.state.buttonMenuGlyph}/></Button></Row>
            <Collapse in={this.state.collapseSearchMenu} onExited={() => this._collapseOnExited()}
                      onEntered={() => this._collapseOnEntered()}>
                <div id="well-dict-menu-wrapper" style={{overflow: this.state.searchWrapperOverflow}}>
                    <Well id="well-dict-menu"
                          className={BUtils.joinClasses(stylesSearchMenu['dict-menu__well'], 'collapse-' + this.state.buttonMenuCollapse)}>
                        <Form inline id="dict-menu">
                            <Grid fluid>
                                <Row id="dict-menu-forms" className={stylesSearchMenu['dict-menu-forms']}>
                                    <Col lg={12} md={12} id="dict-menu-forms-container"
                                         className={stylesSearchMenu['dict-menu-forms-container']}>
                                        <ControlLabel>{Dict.t('SEARCH_STRING')}</ControlLabel>
                                        {this.state.searchParams.className === 'Entry' || this.state.searchParams.className === 'DeclinedForm' ?
                                            <EntryTypeahead key={"key-eth-navigator"}
                                                            languageId={this.state.searchParams.langId}
                                                            searchParams={this.state.searchParams}
                                                            useCache={false}
                                                            enableEnter={true}
                                                            className={stylesScNavbar['entry-typeahead-search']}
                                                            placeholder={AdministrationUtilities.getLangItemByIdAndCollection(this.state.searchParams.langId, this.rootLanguages).label}
                                                            callbackParent={(newState) => {
                                                                if (typeof newState.id !== 'undefined') {
                                                                    var searchParams = JSON.parse(JSON.stringify(this.state.searchParams));
                                                                    searchParams.forms = [newState];
                                                                    performSearch(searchParams, true, function () {
                                                                        document.getElementById("dict-result-item-" + newState.id).click();
                                                                    });
                                                                } else {
                                                                    const hasObj = typeof newState.id !== 'undefined';
                                                                    this._handleSearchFormChange(0, newState, hasObj);
                                                                }
                                                            }}
                                                            callbackParentSearchOn={(create, homographic, text) => {
                                                                //text = text.endsWith("*") ? text : text + '*';
                                                                text = text.indexOf('*') < 0 ? text + '*' : text;
                                                                var searchParams = this.state.searchParams;
                                                                searchParams.nPage = 1;
                                                                this.setState({searchParams}, function () {
                                                                    this._handleSearchFormChange(0, text);
                                                                }.bind(this));
                                                            }}
                                                            callbackParentPressEnter={() => {
                                                                this._search();
                                                            }}
                                            /> :
                                            <FormGroup>
                                                {/*<input type={'text'}/>*/}
                                                <FormControl id={"dict-form-main"} key={"dict-form-main"} type="text"
                                                             className={stylesSearchMenu['dict-menu__main-form']}
                                                             placeholder={AdministrationUtilities.getLangItemByIdAndCollection(this.state.searchParams.langId, this.rootLanguages).label/*Dict.t(this.isToponym ? 'TOPONYM' : 'ENTRY') + "..."*/}
                                                             ref={(input) => {
                                                                 this.mainForm = input;
                                                             }}
                                                             value={this.state.form}
                                                             onSubmit={() => {

                                                             }}
                                                             onChange={(evt) => this._handleSearchFormChange(0, evt.target.value)}
                                                             onKeyPress={this._handlePreventRefresh}
                                                    /*onKeyUp={this._handleKeyUp}*//>
                                            </FormGroup>}
                                        {/*<Button onClick={this._addField}>+</Button>
                                        <Button onClick={this._removeField}
                                                disabled={this.state.isButtonStateDisabled}>-</Button>*/}
                                        <FormGroup id="dict-menu-additional-forms"
                                                   className={stylesSearchMenu['dict-menu-additional-forms']}>
                                            <ReactCSSTransitionGroup transitionName="toggle"
                                                                     transitionEnterTimeout={100}
                                                                     transitionLeaveTimeout={100}>
                                                {this.additionalForms}
                                            </ReactCSSTransitionGroup>
                                        </FormGroup>
                                        <div
                                            className={BUtils.joinClasses(stylesSearchMenu['dict-logic-options__container'], stylesSearchMenu['dict-logic-options__container--' + (this.state.searchParams.forms.length > 1 ? '' : 'not-') + 'visible'])}>
                                            <SCRadiobutton key={"key-radio-specialChars"} id="dict-logic-options"
                                                           label={Dict.t('DICT_LOGIC_OPTIONS_LABEL') + ':'}
                                                           additionalClass={stylesSearchMenu['dict-logic-options']}
                                                           items={this.state.generalSearch}
                                                           default={this.state.searchParams.operator}
                                                           callbackParent={(newState) => this._handleLogicSearch(newState)}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                            <Grid fluid>
                                <Row id="dict-menu-basic" className={stylesSearchMenu['dict-menu-basic']}>
                                    <Col
                                        className={BUtils.joinClasses(stylesSearchMenu['dict-menu-basic__div'], stylesSearchMenu['dict-menu-basic__div-type-label'])}>
                                        <label id='search-type-label'>{Dict.t('SEARCH')}:</label>
                                    </Col>
                                    {!this.isToponym ? <Col
                                        className={BUtils.joinClasses(stylesSearchMenu['dict-menu-basic__div'], stylesSearchMenu['dict-menu-basic__div-type-radio'])}>
                                        <SCRadiobutton key={"key-radio-identifiableTypes"} bsStyle="primary"
                                                       id={'dict-identifiable-types'}
                                                       items={this.state.identifiableTypes}
                                                       default={this.state.searchParams.className}
                                                       callbackParent={(newState) => this._handleRadioChange(newState)}/>
                                    </Col> : ''}
                                    <Col
                                        className={BUtils.joinClasses(stylesSearchMenu['dict-menu-basic__div'], stylesSearchMenu['dict-menu-basic__div-spec-chars'])}>
                                        <SCCheckbox label={Dict.t("USE_SPECIAL_CHARS")} id="specialChars"
                                                    callbackParent={(id, newState) => this._handleCheckboxChange(id, newState)}/>
                                    </Col>
                                    <Col
                                        className={BUtils.joinClasses(stylesSearchMenu['dict-menu-basic__div'], stylesSearchMenu['dict-menu-basic__div-th-language'])}>
                                        {this.isTreeModelDictionary ? <LanguageTypeahead ref="typehead"
                                                                                         callbackParent={(newState) => this._handleLanguage(newState)}
                                                                                         selected={AdministrationUtilities.getSearchLangItem()}/> :

                                            /*<><LanguageTypeahead ref="typehead"
                                                                 callbackParent={(newState) => this._handleLanguage(newState)}
                                                                 selected={AdministrationUtilities.getSearchLangItem()}/>*/
                                            <SCRadiobutton key={"key-radio-languages"} bsStyle="primary"
                                                           id={'dict-languages'}
                                                           items={this.rootLanguages.filter((langItem) => {
                                                               return langItem.is_active === true;
                                                           }).map((item) => {
                                                               return {label: item.label, code: item.id + ''};
                                                           })}
                                                           additionalClass={stylesSearchMenu['dict-languages-radio']}
                                                           default={this.state.searchParams.langId + ''}
                                                           callbackParent={(newState) => this._handleRadioLangChange(parseInt(newState))}/>/*</>*/
                                        }
                                    </Col>
                                    {!this.isToponym && this.useToponomastic ? <Col
                                        className={BUtils.joinClasses(stylesSearchMenu['dict-menu-basic__div'], stylesSearchMenu['dict-menu-basic__div-toponym-radio'], stylesSearchMenu['dict-menu-basic__div-toponym-radio--' + (this.state.searchParams.className === 'Entry')])}>
                                        <SCRadiobutton
                                            key={"key-radio-toponymchoice"} bsStyle="default"
                                            items={[{
                                                label: Dict.t('INCLUDE_TOPONYMS'),
                                                code: 'INCLUDE'
                                            }, {
                                                label: Dict.t('EXCLUDE_TOPONYMS'),
                                                code: 'EXCLUDE'
                                            }, {label: Dict.t('ONLY_TOPONYMS'), code: 'ONLY'}]}
                                            callbackParent={(newState) => this._handleToponymRadioChange(newState)}
                                        />
                                    </Col> : ''}
                                    <Col
                                        className={BUtils.joinClasses(stylesSearchMenu['dict-menu-basic__div'], stylesSearchMenu['dict-menu-basic__div-enter'])}>
                                        <Button id="dict-search-button"
                                                className={stylesSearchMenu['dict-search-button']}
                                                onClick={this._search} ref={(input) => {
                                            this.searchButton = input;
                                        }}
                                                dangerouslySetInnerHTML={{__html: this.state.isLoading ? '<i><small>' + Dict.t('SEARCHING') + '</small></i>' : Dict.t('ENTER')}}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </Form>
                    </Well>
                </div>
            </Collapse>
            <div id="special-search-menu"
                 className={BUtils.joinClasses(stylesSearchMenu['special-search-menu'], stylesSearchMenu['special-search-menu--' + showHide], stylesSearchMenu['special-search-menu-display--' + !this.isToponym])}>
                {this.state.showSpecialSearch ? '' : <SpecialMenuButton/>}
                {/* specialSearch */}
                <div id={"special-search-menu-settings"} className={stylesSearchMenu['special-search-menu-settings']}>
                    <label id={"special-search-menu-settings-vertical-label"}
                           className={BUtils.joinClasses(stylesSearchMenu['special-search-menu-settings__label'], stylesSearchMenu['special-search-menu-settings__label--' + showHide])}
                    >{Dict.t('BO_OTHER_SEARCH_OPTIONS')}</label>
                    {this.state.revisionStatusItems.length > 0 ? <div id="special-search-menu-settings-controls"
                                                                      className={BUtils.joinClasses(stylesSearchMenu['special-search-menu-settings-controls'], stylesSearchMenu['special-search-menu-settings-controls--' + showHide])}>
                        <fieldset id={"special-search-revision-status"}
                                  className={BUtils.joinClasses(stylesSearchMenu['special-search__fieldset'], stylesSearchMenu['special-search__fieldset--' + showHide])}>
                            <legend
                                className={stylesSearchMenu['special-search__fieldset-legend']}>{Dict.t('REVISION_STATUS')}</legend>
                            <Typeahead
                                id={'search-menu-th'}
                                className={''}
                                clearButton={this.state.showSpecialSearch}
                                autoFocus={false /*true*/}
                                ref={"revisionStatusTypeahead"}
                                align={"justify"}
                                onChange={item => this._revisionStatusHandleChange(item)}
                                options={this.state.revisionStatusItems}
                                placeholder={Dict.t('NO_CHOICE')}
                            />
                        </fieldset>
                        <fieldset id={"special-search-lexeme-having"}
                                  className={BUtils.joinClasses(stylesSearchMenu['special-search__fieldset'], stylesSearchMenu['special-search__fieldset--' + showHide])}>
                            <legend
                                className={stylesSearchMenu['special-search__fieldset-legend']}>{Dict.t('LEXEMES_HAVING')}
                            </legend>
                            <SCRadiobutton key={"key-radio-special-search-menu-controls-lexeme-having"}
                                           bsStyle="primary"
                                           id={"sc-radio-special-search-lexeme-having"}
                                           additionalClass={BUtils.joinClasses(stylesSearchMenu['special-search__radio-lexeme-having'], stylesSearchMenu['special-search__radio-lexeme-having--' + showHide])}
                                           items={LexemeStatusTypeSearchItemsArray}
                                           default={this.state.searchParams.specialSearch.lexemeHaving}
                                           i18nLabel={true}
                                           label={""}
                                           callbackParent={(newState) => this._handleLexemeHavingRadioChange(newState)}/>
                            <Typeahead
                                id={'revisione-status-th'}
                                className={stylesSearchMenu['special-search__revision-status-typeahead']}
                                {...revisionStatusItemChildrenProps}
                                clearButton={this.state.showSpecialSearch}
                                autoFocus={false/*true*/}
                                ref={"statusTypeahead"}
                                align={"justify"}
                                onChange={item => this._statusTypeHandleChange(item)}
                                options={this.state.statusTypeItems}
                                placeholder={Dict.t('NO_CHOICE')}
                                /*highlightOnlyResult={true}*/
                            />
                        </fieldset>
                        <fieldset id={"special-search-cgr"}
                                  className={BUtils.joinClasses(stylesSearchMenu['special-search-cgr'], stylesSearchMenu['special-search__fieldset'], stylesSearchMenu['special-search__fieldset--' + showHide])}>
                            <legend className={stylesSearchMenu['special-search__fieldset-legend']}>c.gr.</legend>
                            <CgrTypeahead ref={"cgrtSpecialSearch"} multiple={true}
                                          additionalClass={BUtils.joinClasses(stylesSearchMenu['special-search__cgr-typeahead'], stylesSearchMenu['special-search__cgr-typeahead--' + showHide])}
                                          languageId={this.state.searchParams.langId} maxHeight={'150px'}
                                          callbackParent={(items) => this._cgrsHandleChange(items)}/>
                        </fieldset>
                    </div> : ''}
                </div>
                {this.state.showSpecialSearch ? <SpecialMenuButton/> : ''}
            </div>
        </div>
    }
}


