import React from 'react';
import ReactDom from 'react-dom';
import Dict from 'dict-lib';
import {LexemeUtilities, getContextTypes, LexemeEditingLevel, LexemePageInfos} from './../../../editor/LexemeHelper';
import {setPolyForm} from './../../../editor/entry/EntryActions';
import {Button, Glyphicon, Popover, OverlayTrigger, InputGroup, FormControl, Col} from 'react-bootstrap';
import {openModal} from './../LexemePolyrhematics';
import './../LexemePolyrhematics.css';
import './../../../../../_css/lexeme.css';
import {LexemeSubentries} from './../../03_Subentries/LexemeSubentries';
import {LexemeReferences} from './../../05_References/LexemeReferences';
import {getLexemeOptions} from './../../../LexemePage';
import {LexemePageContext} from "../../../LexemePageContext";

import stylesPolyrhematic from './../../../../../_css/dictionary/lexemeParts/lexemePolyrhematic.module.css';
import stylesLexemeEditor from './../../../../../_css/dictionary/lexemeEditor.module.css';
import BUtils from './../../../../../utilities/BaseUtilities';

export class LexemePolyrhematic extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            name: "POLYRHEMATIC_TITLE",
            item: "POLYRHEMATIC",
            ids: this.props.ids,
            isActive: false
        };

        this.polyForms = [];

        this._openModalPoly = this._openModalPoly.bind(this);
        this._isActive = this._isActive.bind(this);
        this._handleClick = this._handleClick.bind(this);
    }

    _openModalPoly() {
        this.props.callbackParent(this.props.order);
    }

    _polyFormClick(item) {
        var id = "id-popover-edit-polies";
        if (LexemeUtilities.isPageEditable(this)) {
            var myDefabs = this.polyForms.slice(0);
            return <Popover /*{...this.props}*/ className={"popover-edit-text"} id={id}
                                                key={"key-popover-edit-polies"}>
                <EditPoly defabs={myDefabs} item={item}
                          callbackParent={(newState) => this.props.callbackParent()}/>
            </Popover>;
        } else
            return LexemeUtilities.getHiddenPopover(id);
    }

    _isActive() {
        return this.state.isActive ? "lexTranslatableActive" : "";
    }

    _handleClick(isTranslatorSelector) {
        if (isTranslatorSelector) {
            LexemeUtilities.handleClick(this);
            this.setState({isActive: !this.state.isActive});
        }
    }

    _isEmpty(description) {
        return description.replace(/-/g, '').replace('(', '').replace(')', '').trim().length === 0 ? 'emptyAbs' : "";
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }


    render() {

        const isTranslatorSelector = LexemeUtilities.isTranslationSelector(this);
        var polyStyle = LexemePageInfos.getLexemeOptions(this.context,this.props.hasDash === true ? "poly_style_in_xml" : 'poly_bracket_style_in_xml');
        const additionalPolyClass = typeof polyStyle !== 'undefined' ? polyStyle.toLowerCase() : '';

        return <>
            <Col lg={1} md={1} className={stylesPolyrhematic['lexeme-poly-edit']}>
                {isTranslatorSelector ? "" : <Button
                    bsStyle="primary"
                    bsSize="xsmall"
                    bsClass={LexemeUtilities.isReadOnly(this) ? "lexemeVisibilityHidden" : "btn btn-xs btn-primary"}
                    onClick={this._openModalPoly}
                >
                    <Glyphicon glyph="new-window"/>
                    <Glyphicon glyph="triangle-right"/>
                    <Glyphicon glyph="triangle-right"/>
                </Button>}
            </Col>
            <Col lg={11} md={11}
                 className={BUtils.joinClasses(stylesPolyrhematic['lexeme-polyrhematic'], stylesPolyrhematic['lexeme-polyrhematic--' + (this.props.is_hidden ? "hidden" : "show")])}>
                <Col lg={2} md={2}>
                    <OverlayTrigger
                        rootClose={true}
                        key={"key-overlay-sense-" + this.props.ids}
                        trigger={"click"}
                        placement="right"
                        /*container={document.getElementById("modal-poly-edit")}*/
                        overlay={isTranslatorSelector ? LexemeUtilities.createButtonBarTooltip(this) : this._polyFormClick(this.props)}
                    >
                        <div ref="polyFormRef" data-has-dash={this.props.hasDash}
                             onClick={(event) => {
                                 this._handleClick(isTranslatorSelector)
                             }}
                             className={'lexHighlightable ' + stylesPolyrhematic['lexeme-poly-form'] + ' ' + (isTranslatorSelector ? LexemeUtilities.getLexClass(this) + " " + this._isActive() : LexemeUtilities.getLexTextClass(this)) + " " + additionalPolyClass + " " + this._isEmpty(this.props.html_form)}
                             dangerouslySetInnerHTML={{__html: this.props.html_form}}/>
                    </OverlayTrigger>
                </Col>
                <Col lg={10} md={10}>
                    <div className={stylesPolyrhematic['lexeme-polyrhematic-subentry']}>
                        <LexemeSubentries isPolyFromLexeme={true} page={this.props}/>
                    </div>
                    {Object.keys(this.props.references).length > 0 ?
                        <LexemeReferences isPolyFromLexeme={true} direct={true} page={this.props}/> : ""}
                    {Object.keys(this.props.inverse_references).length > 0 ?
                        <LexemeReferences isPolyFromLexeme={true} direct={false} page={this.props}/> : ""}
                </Col>
            </Col>
        </>;
    }
}

class EditPoly extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            description: this.props.item.striked_form,
            showComponent: true,
            defabs: this.props.defabs
        };

        this.textExist = false;

        this._handlePolyForm = this._handlePolyForm.bind(this);
        this._handleKeyUp = this._handleKeyUp.bind(this);
    }

    componentWillMount() {
        if (document.getElementById("lex-sense-button-bar")) {
            document.getElementById("lex-sense-button-bar").classList.remove("in");
        }
    }

    componentDidMount() {
        var x = window.scrollX, y = window.scrollY;
        ReactDom.findDOMNode(this.senseAbs).focus();
        ReactDom.findDOMNode(this.senseAbs).value = "";
        ReactDom.findDOMNode(this.senseAbs).value = this.state.description;
        window.scrollTo(x, y);
        /*setTimeout(function () {
         document.getElementById("lex-sense-button-bar").classList.remove("in");
         }, 0);*/
    }


    _handlePolyForm(evt) {
        this.textExist = evt.target.value.trim().length > 0 && (evt.target.value.toLowerCase() !== this.props.item.striked_form.toLowerCase() && this.props.defabs.indexOf(evt.target.value.toLowerCase()) > -1);
        this.setState({description: evt.target.value});
    }

    _handleKeyUp(evt) {
        if (evt.keyCode === 13) {
            /*if (this.textExist) {
                LexemeUtilities.showModalMessage("error", Dict.t('SENSE_ABS_FOUND'), 2000);
            } else {*/
            setPolyForm(this.props.item.ids, this.state.description);
            this._handleClose();
            //}
        } else if (evt.keyCode === 27) {
            //this.props.callbackParent();
            this._handleClose();
        }
    }

    _handleClose() {
        var el = document.getElementsByClassName("popover-edit-text")[0];
        el.classList.add("popover-edit-text-fade-out");

        setTimeout(function () {
            document.getElementsByClassName('lexemeSenses')[0].click();
        }.bind(this), 400);
    }

    render() {

        var length = (this.state.description + "").length;
        var width = 200 * (parseInt(length / 26) + 1) + 20;

        return <div ref={"poliEditContainer"} id="poli-edit-container-id"
                    className={this.state.showComponent ? "sense-edit-container " : "sense-edit-hide"}
        >
            <InputGroup className={"sense-edit " + ""}><FormControl style={{width: width}}
                                                                    type="text"
                                                                    placeholder={this.props.description}
                                                                    ref={(input) => {
                                                                        this.senseAbs = input;
                                                                    }}
                                                                    value={this.state.description}
                                                                    onChange={(evt) => this._handlePolyForm(evt)}
                                                                    onKeyUp={this._handleKeyUp}/>
            </InputGroup>
        </div>
    }
}

LexemePolyrhematic.contextType = LexemePageContext;

