import React from 'react';
import Dict from 'dict-lib';
import {SCCheckbox, SCSubmitButton} from '../../../../dict-components/components';
import {Button, Row, Col, Glyphicon} from 'react-bootstrap';
import {removeAltForm, promoteAltForm} from './EntryActions';
import './../../editor/root-entry/RootEntry.css';

export class EntryAltFormRemove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            idsToRemove: [],
            idToPromote: ''
        };

        this._handleRemove = this._handleRemove.bind(this);
        this._handlePromote = this._handlePromote.bind(this);
        this._handleCheckboxRemove = this._handleCheckboxRemove.bind(this);
        this._handleCheckboxPromote = this._handleCheckboxPromote.bind(this);
    }

    _handleRemove() {
        if ((this.state.idsToRemove).length > 0)
            removeAltForm(this.state.idsToRemove);
    }

    _handlePromote() {
        if ((this.state.idToPromote) !== '') {
            var update = this.props.additionalData.isLocVar ? ['SET_LOC_VARS'] : ['SET_ALL_FORMS', 'SET_ROOT_ENTRY_FORM'];
            promoteAltForm(this.state.idToPromote, update);
        }
    }

    _handleCheckboxRemove(id, newState) {
        var idsToRemove = this.state.idsToRemove;
        var idToPromote = '';
        if (newState) {
            idsToRemove.push(id.replace('alt-form-id-', ''));
        } else {
            idsToRemove.splice(idsToRemove.indexOf(id.replace('alt-form-id-'), 1));
        }

        this.setState({idsToRemove, idToPromote});
    }

    _handleCheckboxPromote(idToPromote) {
        var idsToRemove = [];

        if (idToPromote === this.state.idToPromote)
            idToPromote = '';

        this.setState({idsToRemove, idToPromote});
    }

    render() {

        var hasSelectItems = (this.state.idsToRemove).length > 0;

        var rowVarLocCounter = 1;

        const isLocVar = typeof this.props.additionalData !== 'undefined' && this.props.additionalData.isLocVar;

        const all_forms = isLocVar ? this.props.item.variants : this.props.item.lexeme_data.all_forms;

        var altForms = all_forms.map((item) =>
            <Row key={"key-row-altform-" + rowVarLocCounter++}>
                <Col lg={5} md={5}>
                    <span
                        className={"alt-form-remove-lang " + (item.type === 'MAIN' ? 'main-form-remove-lang' : '')}>{item.form}</span>
                </Col>
                <Col className={"alt-form-remove-lang-chk"} lg={7} md={7}>
                    <>
                        {item.type === 'MAIN' || isLocVar ? '' : <span title={'baddibox'}>
                            <SCCheckbox id={"alt-form-id-" + item.id}
                                        checked={this.state.idsToRemove.indexOf(item.id) >= 0}
                                        callbackParent={(id, newState) => this._handleCheckboxRemove(id, newState)}/>
                            </span>}
                        {item.type === 'MAIN' ? '' : <span title={'baddicon'}
                                                           className={this.state.idToPromote === item.id ? "alt-form-promote-selected" : ''}
                                                           onClick={() => this._handleCheckboxPromote(item.id)}><Glyphicon
                            glyph={'arrow-up'}/></span>}
                    </>
                </Col>
            </Row>
        );

        var hasAltForms = altForms.length > 0;
        var hasIdToPromote = this.state.idToPromote !== '';
        var askMsg = '';

        if (hasSelectItems || hasIdToPromote) {
            askMsg = hasSelectItems ? 'ASK_DELETE_SELECTED_ALT_FORM' : 'ASK_PROMOTE_SELECTED_ALT_FORM';
        }

        return <div className="alt-form-remove">
            <Row className={'alt-form-to-remove-list'}>{hasAltForms ? altForms : Dict.t('ALT_FORMS_NOT_FOUND')}</Row>
            {hasSelectItems || hasIdToPromote ?
                <Row className={'ask-alt-form'}>
                    <Col className={"ask-alt-form-text"} lg={10} md={10}>
                        <Row>{Dict.t(askMsg)}</Row>
                    </Col>
                    <Col lg={2} md={2}>
                        <SCSubmitButton onClick={hasSelectItems ? this._handleRemove : this._handlePromote}
                                        text={Dict.t('YES')}/>
                    </Col>
                </Row> : ""}
        </div>;
    }


}
