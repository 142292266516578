import React from 'react';
import Dict from 'dict-lib';
import ReactDom from 'react-dom';
import {getLexemeProperties} from './../../LexemePage';
import {Grid, Row, InputGroup, FormControl, Button} from 'react-bootstrap';
import {LexemeEditingLevel, LexemeUtilities, translate} from './../LexemeHelper';
import {addNewPoly} from './EntryActions';
import {LanguageTreeViewPlus, SCSubmitButton} from './../../../../dict-components/components';
import './../../../../_css/lexeme.css';
import './../root-entry/RootEntry.css';


export class EntryAddPoly extends React.Component {

    constructor(props) {
        super(props);

        const isTranslatorSelector = typeof this.props.context !== 'undefined' && this.props.context.pageStatus == LexemeEditingLevel.TRANSLATION_SELECTOR;

        this.state = {
            call: 'service/getLanguageTreeViewForPolys/' + getLexemeProperties('root_entry_id') + '/',
            isTranslationSelector: isTranslatorSelector,
            isTreeMdel: isTranslatorSelector ? false : getLexemeProperties("is_tree_model"),
            text: "",
        };

        this.defabs = [];
        this.textExist = false;

        this._handleAdd = this._handleAdd.bind(this);
        this._handlePolyForm = this._handlePolyForm.bind(this);
    }

    componentWillMount() {
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.polyAdd).focus();
    }

    _handlePolyForm(evt) {
        var text = evt.target.value;
        this.textExist = evt.target.value.trim().length > 0 && (evt.target.value.toLowerCase() != this.state.text.toLowerCase() && this.defabs.indexOf(evt.target.value.toLowerCase()) > -1);
        this.setState({text});
    }

    _handleAdd(rootId, addList) {
        if ((this.state.text + "").length > 0) {
            const addPolyPromise = addNewPoly(rootId, this.state.text, addList, this.state.isTreeMdel, this.state.isTranslationSelector);
            if (this.state.isTranslationSelector) {
                addPolyPromise.then(result => {
                    if (result.result === 'OK') {
                        var newLexSenseIds = JSON.parse(result.additionalInfos).newLexSenseIds;
                        translate(this.props.context.startItem, newLexSenseIds);
                    }
                });
            }
        }
    }

    render() {

        return <Grid className={'grid-poly-add'} fluid>
            <Row className={"row-poly-add"}><InputGroup className={"poly-add"}>
                <InputGroup.Addon>{Dict.t('FORM')}</InputGroup.Addon>
                <FormControl type="text" placeholder={Dict.t('ADD_POLY') + "..."}
                             ref={(input) => {
                                 this.polyAdd = input;
                             }}
                             value={this.state.text}
                             onChange={(evt) => this._handlePolyForm(evt)}
                             onKeyUp={(evt) => LexemeUtilities.handleKeyUp(evt, () => {
                                 this._handleAdd(this.props.context.rootEntryId, this.props.context.languageId)
                             })}/>
            </InputGroup></Row>
            {this.state.isTreeMdel ?
                <LanguageTreeViewPlus forceCall={true} item={this.props.item} call={this.state.call}
                                      btName={Dict.t('ADD')}
                                      callbackParent={(rootId, addList) => this._handleAdd(rootId, addList)}
                />
                : <Row className={"row-poly-add-button"}>
                    <SCSubmitButton onClick={() => this._handleAdd(this.props.context.rootEntryId, this.props.context.languageId)} text={Dict.t(this.state.isTranslationSelector ? 'ADD_AND_TRANSLATE' : 'SAVE')}/>
                </Row>}
        </Grid>;
    }


}
