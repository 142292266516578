import React from "react";
import Dict from 'dict-lib';
//import Dict from './../../../_dict-modules/dict-lib/main';
import {Grid, Col, Row, FormControl, ControlLabel, FormGroup, Button, Glyphicon} from 'react-bootstrap';
import './../Options.css';
import {SCCheckbox} from './../../../dict-components/components';
import {SCCombo} from './../../../dict-components/components';

export class SharedOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            sharedOptions: [
                {id: 'HIDE_HIDDEN_ITEMS_TO_USERS', type: 'checkbox', value: false},
                {id: 'ALLOW_REVERSE_TRANSLATION', type: 'checkbox', value: false},
                {id: 'FORCE_POLY_START_UPPER_CASE', type: 'checkbox', value: false},
                {id: 'LOC_VARS_GEOGRAPHIC_ORDER', type: 'checkbox', value: false},
                {id: 'HOMOGRAPHY_RECKON_WITH_UPPER_LOWER_CASE', type: 'checkbox', value: false},
                {id: 'HYPO_LEXO_PART_OPEN', type: 'checkbox', value: false},
                {id: 'USE_ALPHABETIC_ALTFORMS', type: 'checkbox', value: false},
                {id: 'DISTINGUISH_TRANSLATIONS_BY_MULTIWORD', type: 'checkbox', value: false},
                {id: 'USE_ENCYCLOPEDIC_ENTRIES', type: 'checkbox', value: false},
                {id: 'PHRASES_ARE_QUOTES', type: 'checkbox', value: false},
                {id: 'USE_LEXEME_ISSUES', type: 'checkbox', value: false},
                {id: 'HIDE_SUBENTRY_FORM', type: 'checkbox', value: false},
                {id: 'USE_OPTIMISTIC_STATUS', type: 'checkbox', value: false},
                {id: 'TRANSLATED_GET_TOPIC', type: 'checkbox', value: false},
                {id: 'USE_ENTRY_NOTEPAD', type: 'checkbox', value: false},
                {id: 'MAX_LOC_VARS', type: 'input', inputType: 'number', placeholder: 'Num', value: false},
                {
                    id: 'QDA_SEPARATOR',
                    type: 'select',
                    label: Dict.t('QDA_SEPARATOR'),
                    options: [{value: ';', text: ';'}, {value: ':', text: ':'}, {
                        value: ',',
                        text: ','
                    }, {value: '-', text: '-'}],
                    value: ''
                },
                {
                    id: 'POLY_STYLE_IN_XML',
                    type: 'select',
                    label: Dict.t('POLY_STYLE_IN_XML'),
                    options: [{value: 'POLY_STYLE_BOLD', text: Dict.t('POLY_STYLE_BOLD')}, {
                        value: 'POLY_STYLE_STRIKED',
                        text: Dict.t('POLY_STYLE_STRIKED')
                    }],
                    value: ''
                },
                {
                    id: 'TR_POLY_STYLE_IN_XML',
                    type: 'select',
                    label: Dict.t('TR_POLY_STYLE_IN_XML'),
                    options: [{
                        value: 'POLY_STYLE_NO_STYLE_BRACKET',
                        text: Dict.t('TR_POLY_STYLE_BOLD')
                    }, {value: 'TR_POLY_STYLE_STRIKED', text: Dict.t('TR_POLY_STYLE_STRIKED')}],
                    value: ''
                },
                {
                    id: 'POLY_BRACKET_STYLE_IN_XML',
                    type: 'select',
                    label: Dict.t('POLY_BRACKET_STYLE_IN_XML'),
                    options: [{
                        value: 'POLY_STYLE_NO_STYLE_BRACKET',
                        text: Dict.t('POLY_STYLE_NO_STYLE_BRACKET')
                    }, {value: 'POLY_STYLE_BOLD_BRACKET', text: Dict.t('POLY_STYLE_BOLD_BRACKET')}],
                    value: ''
                },
                {
                    id: 'TR_POLY_BRACKET_STYLE_IN_XML',
                    type: 'select',
                    label: Dict.t('TR_POLY_BRACKET_STYLE_IN_XML'),
                    options: [{
                        value: 'POLY_TR_STYLE_NO_STYLE_BRACKET',
                        text: Dict.t('POLY_TR_STYLE_NO_STYLE_BRACKET'),
                    }, {value: 'POLY_TR_STYLE_BOLD_BRACKET', text: Dict.t('POLY_TR_STYLE_BOLD_BRACKET')}],
                    value: ''
                }
            ]
            ,
            saveSharedOptions: 'primary',
            saveSharedOptionsGlyph: '',
        });

        this._handleSharedOptions = this._handleSharedOptions.bind(this);
        this._saveSharedOptions = this._saveSharedOptions.bind(this);
        this._handleChangeText = this._handleChangeText.bind(this);
        this._updateSharedOptionsState = this._updateSharedOptionsState.bind(this);
        this._exportProperties = this._exportProperties.bind(this);
    }

    componentWillMount() {
        this._exportProperties();
    }


    _exportProperties() {
        var sharedOptions = Dict.getSharedOptions();
        var toLoad = this.state.sharedOptions;
        for (var prop in sharedOptions) {
            var sharedOption = toLoad.filter(function (item) {
                return item.id == prop;
            })[0];
            sharedOption.value = sharedOptions[prop] == "true" ? true : false;
            if (sharedOptions[prop] == "true")
                sharedOption.value = true;
            else if (sharedOptions[prop] == "false")
                sharedOption.value = false;
            else {
                sharedOption.value = sharedOptions[prop];
            }
        }
    }

    _handleSharedOptions(id, value) {
        this._updateSharedOptionsState(id, value);
    }

    _handleChangeText(evt, item) {
        item.value = evt.target.value;
        this.setState(this.state);
    }

    _saveSharedOptions() {
        var bsStyle = 'OK' == 'OK' ? 'success' : 'danger';
        var glyph = 'OK' == 'OK' ? 'thumbs-up' : 'thumbs-down';

        var entityItem = {};
        for (var i = 0; i < this.state.sharedOptions.length; i++) {
            var prop = this.state.sharedOptions[i].id;
            entityItem[prop] = this.state.sharedOptions[i].value;
        }

        Dict.call('admin/options/updateSharedOptions/' + JSON.stringify(entityItem)).then(result => {
            Dict.loadProperties();
        });

        this.setState({
            saveSharedOptions: bsStyle,
            saveSharedOptionsGlyph: glyph
        });

        setTimeout(function () {
            this.setState({
                saveSharedOptions: 'primary',
                saveSharedOptionsGlyph: ''
            });
        }.bind(this), 3000);
    }

    _updateSharedOptionsState(property, value) {
        var item = this.state.sharedOptions.filter(function (item) {
            return item.id == property;
        })[0];
        item.value = value + '';
        this.setState({
            saveSharedOptions: 'warning',
            saveSharedOptionsGlyph: 'hand-right'
        });
    }

    render() {

        const chkSharedOptions = this.state.sharedOptions.filter(function (item) {
            return item.type === 'checkbox';
        }).map((item) =>
            <SCCheckbox key={"key-chk-shared-" + item.id}
                        checked={item.value}
                        label={Dict.t(item.id)}
                        tooltip={item.id}
                        id={item.id}
                        callbackParent={(id, newState) => this._handleSharedOptions(item.id, newState)}/>);

        const selectSharedOptions = this.state.sharedOptions.filter(function (item) {
            return item.type === 'select';
        }).map((item) =>
            <SCCombo key={"key-select-shared-" + item.id}
                     id={item.id}
                     label={item.label}
                     tooltip={item.id}
                     value={item.value}
                     options={item.options}
                     callbackParent={(value) => this._handleSharedOptions(item.id, value)}/>);

        const inputSharedOptions = this.state.sharedOptions.filter(function (item) {
            return item.type == 'input';
        }).map((item) =>
            <FormGroup id="formControlsSelect" key={"key-input-shared-" + item.id}>
                <ControlLabel title={item.id} >{Dict.t(item.id)}</ControlLabel>
                <FormControl id={item.id}
                             ref={"ref_" + item.id}
                             bsSize="small"
                             type={item.inputType}
                             /*value={item.value}*/
                             value={item.value}
                             placeholder={item.placeholder}
                             onChange={evt => this._handleChangeText(evt, item)}
                />
            </FormGroup>);

        return <Grid id="options-tab-shared" fluid>
            <Row>
                <Col id="options-tab-shared-left" lg={6}>{chkSharedOptions}</Col>
                <Col id="options-tab-shared-right" lg={6}>{inputSharedOptions}{selectSharedOptions}</Col>
            </Row>
            <Row>
                <FormGroup id="option-tab-shared-group-button">
                    <Button bsStyle={this.state.saveSharedOptions}
                            onClick={this._saveSharedOptions}><Glyphicon style={{paddingRight: '4px'}}
                                                                         glyph={this.state.saveSharedOptionsGlyph}/>{Dict.t('SAVE')}
                    </Button>
                </FormGroup>
            </Row>
        </Grid>
    }
}