import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {getLexemeProperties} from './../../LexemePage';
import {ToolbarTypes, getContextTypes, LexemeUtilities, LexemeEditingLevel} from "../../editor/LexemeHelper";
import {LexemeEditor} from './../../editor/LexemeEditor';
import './LexemePolyrhematics.css';
import {LexemePageContext} from "../../LexemePageContext";

import stylesPolyrhematic from './../../../../_css/dictionary/lexemeParts/lexemePolyrhematic.module.css';
import BUtils from './../../../../utilities/BaseUtilities';

export class LexemePolyrhematicTitle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: 'POLYRHEMATIC_TITLE',
            id: ""
        };
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _isActive(id) {
        return LexemeUtilities.isActive(this, id);
    }

    _handleClick(id) {
        if (this.context.pageStatus === LexemeEditingLevel.EDITABLE || this.context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR) {
            const active = document.getElementsByClassName(LexemeUtilities.getLexClass(this) + "Active");

            if (active.length > 0) {
                active[0].click();
            }

            this.setState({
                id: (id === this.state.id ? '' : id)
            });
        }
    }


    render() {

        const poly = this.props.page;
        const title = poly.html_form;

        return <div className={BUtils.joinClasses(stylesPolyrhematic['poly-title'], stylesPolyrhematic['poly-title--' + (poly!=null && poly.is_hidden ? 'hidden' : 'show')])}>
            <OverlayTrigger delay={8000} key={"key-lexEntry"} rootClose={false}
                            trigger={"click"}
                            placement="top"
                            container={document.getElementById("modal-poly-edit")}
                            overlay={this._createButtonBarTooltip()}>
                <div className={BUtils.joinClasses(stylesPolyrhematic['lexPolyTitle'], stylesPolyrhematic[LexemeUtilities.getLexClass(this)], this._isActive(getLexemeProperties("root_entry_id")))}
                     onClick={() => {
                         this._handleClick(getLexemeProperties("root_entry_id"))
                     }} dangerouslySetInnerHTML={{__html: title}}/>
            </OverlayTrigger>
        </div>;
    }
}

LexemePolyrhematicTitle.contextType = LexemePageContext;