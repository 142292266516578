import React from 'react';
import ReactDOM from 'react-dom';
import {Grid, Col, Row, Button, ButtonToolbar, ButtonGroup, Glyphicon} from 'react-bootstrap';
import Dict from 'dict-lib';
import {SCRadiobutton} from './../../../../dict-components/components';
import {getDictionaryProperties} from '../../LexemePage';
import {getLexemeOptions, getLexemeProperties} from '../../LexemePage';
import {LexemeSorter} from './../LexemeSorter';

import stylesSorter from './../../../../_css/sorter.module.css';

export class SenseSort extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            senseSortType: [],
            choosenType: "",
            key: Math.random()
        };

        this.colLabel = 3;
        this.colRadio = 9;

        this._renderSenseSorter = this._renderSenseSorter.bind(this);
        this._renderSenseSorterRadio = this._renderSenseSorterRadio.bind(this);
    }

    componentWillMount() {
        var senseSortType = [];
        var phraseOrderType = getLexemeOptions('phrase_order');

        if (Object.keys(this.props.item.phraseologies).length > 1) {
            senseSortType.push({
                code: "PHRASES",
                label: Dict.t("PHRASES"),
                permission: {
                    isAllowed: phraseOrderType === 'MANUAL',
                    msg: phraseOrderType === 'MANUAL' ? "" : Dict.t('PHRASE_SORTING_IS') + " " + Dict.t(phraseOrderType)
                }
            });
        }

        for (var key in this.props.item.translations) {
            if (Object.keys(this.props.item.translations[key]).length > 1) {
                senseSortType.push({
                    code: "TRANSLATIONS_" + key,
                    label: (Dict.t("TRANSLATIONS") + " (" + key + ")"),
                    variant: key,
                    permission: {
                        isAllowed: true,
                        msg: ''
                    }
                });
            }
        }

        var choosenType = '';
        const isPoly = this.props.additionalData.isPolyEdit;

        if (senseSortType.length === 1) {
            choosenType = senseSortType[0].code;
            this.colLabel = 0;
            this.colRadio = 12;
        } else if(senseSortType.length > 1 && isPoly) {
            this.colLabel = 5;
            this.colRadio = 7;
        }

        this.setState({senseSortType, choosenType}, () => {
            if (senseSortType.length === 1)
                this._renderSenseSorter(choosenType);
        });
    }

    componentDidMount() {
        this._renderSenseSorterRadio(this.state.choosenType);
    }

    componentWillUnmount() {
    }

    _handleRadioItemSortChange(choosenType) {
        this._renderSenseSorter(choosenType);
    }

    _handleSortEnd(choosenType) {
        this._renderSenseSorter(choosenType);
        this._renderSenseSorterRadio(choosenType);
    }

    _renderSenseSorter(choosenType) {
        var type = this.state.senseSortType.filter(function (item) {
            return item.code === choosenType;
        })[0];

        const Sorter = <LexemeSorter type={choosenType} parentItem={this.props.item} notAllowedMsg={type.permission.msg}
                                     isPoly={this.props.additionalData.isPolyEdit}
                                     polyItem={this.props.additionalData.polyItem}
                                     callbackParent={(newState) => this._handleSortEnd(newState)}/>;
        ReactDOM.render(Sorter, document.getElementById('lexeme-sorter-container-id'));
    }

    _renderSenseSorterRadio(choosenType) {
        const Radio = this.state.senseSortType.length > 0 ?
            <SCRadiobutton key={"key-radio-entry-sort-types-" + this.state.key}
                           bsStyle="default"
                           className="col-lg-4-35"
                           items={this.state.senseSortType}
                           default={choosenType === '' ? '' : choosenType.code}
                           callbackParent={(newState) => this._handleRadioItemSortChange(newState)}/> :
            <div>{Dict.t('NO_SORTABLE_OBJECT_FOUND')}</div>;

        ReactDOM.render(Radio, document.getElementById('lexeme-sorter-radio-id'));
    }

    render() {
        return <Grid className={stylesSorter['grid-entry-sort']} fluid>
            <Row className={stylesSorter['grid-entry-sort-choose']}>
                <Col lg={this.colLabel} md={this.colLabel}>{this.state.senseSortType.length > 1 ? Dict.t("CHOOSE_ITEMS_TO_SORT") : ''}:</Col>
                <Col lg={this.colRadio} md={this.colRadio} id={"lexeme-sorter-radio-id"}/>
            </Row>
            <Row
                id="lexeme-sorter-container-id" key={"key-lexeme-sorter-container-id"}
                className={this.props.additionalData.isPolyEdit ? stylesSorter['poly-sorter-container'] : ""}>
            </Row>
        </Grid>;
    }
}
