import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import {Grid, Col, Row} from 'react-bootstrap';
import Dict from 'dict-lib';
import {SCRadiobutton} from './../../../../dict-components/components';
import {getRootEntryId} from '../../LexemePage';
import {LexemeSorter} from './../LexemeSorter';

import stylesSorter from './../../../../_css/sorter.module.css';

export class EntrySort extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            entrySortType: [],
            choosenType: ""
            /*key: Math.random()*/
        };

        this.isPoly = this.props.additionalData.isPoly;

        this._renderLexemeSorter = this._renderLexemeSorter.bind(this);
        this._renderLexemeSorterRadio = this._renderLexemeSorterRadio.bind(this);
    }

    componentWillMount() {
        var entrySortType = [];
        entrySortType.push({code: "GRAMMATICAL_CATEGORIES", label: Dict.t("GRAMMATICAL_CATEGORIES")});
        entrySortType.push({code: "POLYRHEMATICS", label: Dict.t("POLYRHEMATICS")});
        //entrySortType.push({code: "ALTERNATIVE_FORMS", label: Dict.t("ALTERNATIVE_FORMS")});

        this.setState({entrySortType});
    }

    componentDidMount() {
        if (this.props.additionalData.isPoly)
            this._renderLexemeSorter('GRAMMATICAL_CATEGORIES');
        else {
            this._renderLexemeSorterRadio('');
            /*this._renderLexemeSorterRadio('GRAMMATICAL_CATEGORIES');
            this._renderLexemeSorter('GRAMMATICAL_CATEGORIES');*/
        }
    }

    _handleRadioItemSortChange(choosenType) {
        this._renderLexemeSorter(choosenType);
    }

    _handleSortEnd(choosenType) {
        //this._renderLexemeSorter(choosenType);
        //this._renderLexemeSorterRadio(choosenType);
    }

    _renderLexemeSorter(choosenType) {
        const Sorter = <LexemeSorter type={choosenType} parentId={this.isPoly ? this.props.item.ids : getRootEntryId()}
                                     isPoly={this.isPoly}
                                     polyItem={this.props.additionalData.polyItem}
                                     callbackParent={(newState) => this._handleSortEnd(newState)}/>;

        ReactDOM.render(Sorter, document.getElementById('lexeme-sorter-container-id'));
    }

    _renderLexemeSorterRadio(choosenType) {
        const Radio = <SCRadiobutton key={"key-radio-entry-sort-types-" + this.state.key} bsStyle="default"
                                     className="col-lg-4-35"
                                     items={this.state.entrySortType}
                                     default={choosenType}
                                     callbackParent={(newState) => this._handleRadioItemSortChange(newState)}/>;

        ReactDOM.render(Radio, document.getElementById('lexeme-sorter-radio-id'));
    }

    render() {

        return <Grid className={"grid-entry-sort"} fluid>
            {this.isPoly ? "" : <Row className={stylesSorter['grid-entry-sort-choose']}>
                <Col lg={3} md={3}>{Dict.t("CHOOSE_ITEMS_TO_SORT")}:</Col>
                <Col lg={9} md={9} id={"lexeme-sorter-radio-id"}/>
            </Row>}
            <Row id="lexeme-sorter-container-id" className={this.isPoly ? stylesSorter['poly-sorter-container'] : ""}/>
        </Grid>;
    }
}
