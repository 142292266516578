import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {LexemeUtilities} from './../../editor/LexemeHelper';
import {transformPolyInPhrase} from './EntryActions';
import {Button, Row, Col} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {getLexemeProperties} from './../../LexemePage';
import {SubEntryCombo} from './../../../../dict-components/components';

export class EntryPolyTransformInPhrase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            senseToAssign: {}
        };

        this._handleTransform = this._handleTransform.bind(this);
        this._handleAssignSense =  this._handleAssignSense.bind(this);
    }

    _handleTransform() {
        this.setState({btDisabled: true});
        transformPolyInPhrase(this.props.item.ids, this.state.senseToAssign).then(result => {
            if(result.result==='OK') {
                LexemeUtilities.closeModalPoly();
            }

            /*this.setState({btSaveState: "success", btDisabled: false}, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });*/
        });
    }

    _handleAssignSense(newState) {
        this.setState({senseToAssign: newState});
    }

    render() {

        const foundSense = typeof this.state.senseToAssign !== 'undefined' && Object.keys(this.state.senseToAssign).length > 0;

        return <div className="">
            <Row>
                <SubEntryCombo languageId={getLexemeProperties("language_id")} onlyRoot={true}
                               callbackParent={(newState) => this._handleAssignSense(newState)}/>

            </Row>
            <Row className={"poly-transform-phrase-row-button"}>
                { foundSense ?
                    <Col lg={11} md={11} className="popup-label">{Dict.t('ASK_TRANSFORM_POLY_PHRASE')}</Col>
                    : "" }
                { foundSense ?
                    <Col lg={1} md={1}>
                        <Button onClick={this._handleTransform}>{Dict.t('YES')}</Button>
                    </Col>
                    : "" }
            </Row>
        </div>;
    }

}
