import React from 'react';
import Dict from 'dict-lib';
import ReactDOM from 'react-dom';
import {getLexemeProperties, getPage, removeTab, setPolies} from './../../LexemePage';
import {LexemeUtilities, } from './../LexemeHelper';
import {performSearchByInitialState} from './../../../../search/lib/search';

const updateEntry = ['SET_ROOT_ENTRY_FORM'];
var updateClasses = ['lexDataLocVars', 'lexeme-polyrhematic-list'];

const getRootEntryId = () => {
    return getLexemeProperties('root_entry_id');
};

export const deleteEntry = (id, isPoly) => {
    var call = '/entry/deleteEntry/' + id + '/' + updateEntry;

    if (isPoly) {
        updateClasses = ['lexeme-polyrhematic-list'];
        LexemeUtilities.callByClassName(call, updateClasses, true).then(result => {
            if (result.result === 'OK') {
                LexemeUtilities.closeModalPoly();
                LexemeUtilities.resetTabPages();
            }
        });
    } else {
        updateClasses = [];
        LexemeUtilities.callSimple(call, 'MSG_LEXEME_DELETING').then(result => {
            if (result.result === 'OK_DELETING') {
                LexemeUtilities.showModalSpinner(Dict.t('MSG_LEXEME_DELETED'), 'delete-lexeme-spinner');
                //LexemeUtilities.emptyLexemePage();

                setTimeout(function () {
                    var page = getPage();

                    /*var involvedIds = JSON.parse(result.additionalInfos) === null ? undefined : JSON.parse(result.additionalInfos).involvedLexemeIds;
                    if (typeof involvedIds !== 'undefined')
                        LexemeUtilities.resetTabInvolvedItems(page, involvedIds);*/

                    //TODO: è stato fatto il "chiudi tab" che, se avviene in un tab centrale, scala senza problemi. Con la cancellazione di tab "centrali", sembra
                    // avere qualche problema. Provare un pò, poi casomai meglio fare un riavvio.
                    // Ok resetTabPages quando modifico qualcosa in altri tab


                    LexemeUtilities.closeModalSpinner();
                    setTimeout(function () {

                        page.tabs.splice(page.tabIndex, 1);
                        if (page.tabIndex > 0)
                            page.tabIndex--;
                        removeTab(page);
                        performSearchByInitialState();
                        LexemeUtilities.resetTabPages();
                        //window.location.reload();

                    }, 500);
                }, 1000);
            } else if (result.abstract === 'conflict 409') {
                LexemeUtilities.callSystemError(call, result.message);
            } else {
                LexemeUtilities.closeModalSpinner();
                setTimeout(function () {
                    LexemeUtilities.showErrorModalMessage(Dict.t(result.message), 3000);
                }, 1000);
            }
        }).catch(function (error) {
            LexemeUtilities.closeModalSpinner();
            setTimeout(function () {
                LexemeUtilities.showErrorModalMessage(error, 3000);
            }, 1000);
        });
    }
};

export const rebuildEntry = (id) => {
    return LexemeUtilities.callSpinner('/entry/rebuildEntry/' + id + '/' + updateEntry, 'REBUILDING_LEXEME');
};

export const setEditingStatus = (id, editingStatus) => {
    return LexemeUtilities.call('/entry/setEditingStatus/' + id + '/' + editingStatus + '/');
};

export const saveEntryForm = (form) => {
    var update = ['SET_ALL_FORMS', 'SET_ROOT_ENTRY_FORM'];
    return LexemeUtilities.call('/entry/saveEntryForm/' + getRootEntryId() + '/' + form + '/' + update);
};

export const saveAdvancedField = (item, isRoot) => {
    var update = isRoot ? ['SET_HYPHENATION', 'SET_SIMPLIFY_IPA', 'SET_ETYMOLOGY', 'SET_NOTES', 'SET_IPA', 'SET_HYPOTIZED_IPA'] : [];

    return LexemeUtilities.call('/entry/saveAdvancedField/' + item + '/' + update);
};

export const showHideEntry = (ids) => {
    return LexemeUtilities.call('/entry/showHideEntry/' + ids + '/');
};

export const changeEntryTypology = (id, typology, mantainMorpho) => {
    var update = id === getRootEntryId() ? ['SET_ROOT_ENTRY_FORM', 'SET_LOC_VARS'] : ['SET_LOC_VARS'];
    var classReload = id === getRootEntryId() ? ['lexDataRootEntry', 'lexDataLocVars'] : ['lexDataLocVars'];

    return LexemeUtilities.callByClassName('/entry/changeEntryTypology/' + id + '/' + typology + '/' + mantainMorpho + '/' + update, classReload, true);
};

export const saveDating = (item) => {
    return LexemeUtilities.call('/entry/saveDating/' + getRootEntryId() + '/' + item + '/SET_DATING');
};

export const addAltForm = (id, newForm, isRoot) => {
    var update = isRoot ? 'SET_ALL_FORMS' : ['SET_LOC_VARS', 'SET_POLYS'];
    var updateClasses = isRoot ? [''] : ['lexDataLocVars', 'lexeme-polyrhematic-list'];

    LexemeUtilities.call('/entry/addAltForm/' + id + '/' + newForm + '/' + update, updateClasses, true);
};

export const removeAltForm = (ids) => {
    ids = ids.toString().replace(/,/g, '_');
    LexemeUtilities.call('/entry/removeAltForm/' + ids + '/SET_ALL_FORMS', [''], true);
};

export const promoteAltForm = (id, update) => {
    LexemeUtilities.call('/entry/promoteAltForm/' + id + '/' + update);
};

export const saveNotes = (variants) => {
    variants = LexemeUtilities.encodeUrl(variants);
    return LexemeUtilities.call('/entry/saveNotes/' + variants + '/', ['lexeme-polyrhematic-list'], true);
};

export const addNewSubentry = (rootId, idCgr, cgrForm, senseText, addList, isTree, isPoly, isTranslation) => {
    var ids = "";
    if (isTree) {
        addList.map((item) => {
            ids += item.id_lang + "_";
        });

        ids = ids.substring(0, ids.length - 1);
    } else
        ids = addList;

    if (senseText.length === 0) {
        senseText = "-";
    }

    var update = ['SET_SUBENTRIES'];

    const path = '/entry/addNewSubentry/' + rootId + '/' + idCgr + '/' + cgrForm + '/' + ids + '/' + senseText + '/' + update;

    return typeof isTranslation !== 'undefined' && isTranslation ? LexemeUtilities.callSimple(path, "SPINNER_MSG_CGR_CREATING") : LexemeUtilities.callHighlight(path, 'div-subentry-');
};

export const addNewPoly = (rootId, polyForm, addList, isTree, isTranslation) => {
    var ids = "";

    if (isTree) {
        addList.map((item) => {
            ids += item.id_lang + "_";
        });

        ids = ids.substring(0, ids.length - 1);
    } else
        ids = addList;

    const path = '/entry/addNewPoly/' + rootId + '/' + polyForm + '/' + ids + '/SET_POLYS';

    return typeof isTranslation !== 'undefined' && isTranslation ? LexemeUtilities.callSimple(path, "SPINNER_MSG_POLY_CREATING") : LexemeUtilities.callHighlightWithModalSpinner(path, Dict.t('ADDING_POLY'), 'div-poly-');
};

export const addReference = (entryIdStart, entryIdToLink, type, isPolyStart) => {
    var updateClasses = isPolyStart ? ['lexeme-polyrhematic-references'] : ['lexeme-references'];

    LexemeUtilities.callByClassNameResetTabPages('/entry/addReference/' + entryIdStart + '/' + entryIdToLink + '/' + type + '/SET_REFERENCES/SET_REFERENCES', [], false);
};

export const deleteReference = (ids, direct, isPolyEdit) => {
    var updateClasses = isPolyEdit ? ['lexeme-polyrhematic-references'] : (direct ? ['lexeme-references'] : ['lexeme-inverse_references']);
    LexemeUtilities.callByClassNameResetTabPages('/entry/deleteReference/' + ids + '/' + direct + '/SET_REFERENCES/SET_REFERENCES', [], true);
};

export const transformPolyInPhrase = (polyIds, senseIds) => {
    return LexemeUtilities.callByClassName('/entry/transformPolyInPhrase/' + polyIds + '/' + senseIds + '/SET_POLYS', updateClasses, false).then(result => {
        if (result.result === 'OK') {
            LexemeUtilities.closeModalPoly();
            LexemeUtilities.resetTabPages();
        }
    });
};

export const movePoly = (polyIds, newRootId) => {
    return LexemeUtilities.callByClassName('/entry/movePolythematic/' + polyIds + '/' + newRootId + '/false/SET_POLYS', updateClasses, false).then(result => {
        if (result.result === 'OK') {
            LexemeUtilities.closeModalPoly();
            LexemeUtilities.resetTabPages();
        }
    });
};

export const setPolyForm = (ids, form) => {
    return LexemeUtilities.call('/entry/setPolyForm/' + ids + '/' + form + '/SET_POLYS');
};

export const setPoliesOrder = (ids) => {
    //setPolies(items);
    return LexemeUtilities.callNoClose('/entry/setPoliesOrder/' + ids + '/SET_POLYS').then(result => {

    })
};

//TODO: serve?
export const setAltFormsOrder = (items) => {
    var ids = "";
    items.map((item) => {
        ids += item.id + "_";
    });
    ids = ids.substring(0, ids.length - 1);

    return Dict.call('/entry/setAltFormsOrder/' + ids + '/').then(result => {

    })
};

export const setPlace = (id, idPlace) => {
    return LexemeUtilities.call('/entry/setPlace/' + id + '/' + idPlace + '/');
};


/*export const addNewLocVar = (rootId, langList) => {
 var myClasses = ['lexDataLocVars', 'lexeme-polyrhematic-list'];

 var ids = "";
 var form = "";

 langList.map((item) => {
 form = item.form;
 ids += item.id_lang + "_";
 });

 ids = ids.substring(0, ids.length - 1);

 LexemeUtilities.callByClassName('/locVars/addNewLocVar/' + rootId + '/' + form + '/' + ids, myClasses, true)
 };*/
