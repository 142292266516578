import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import update from 'immutability-helper';
import ReactTooltip from 'react-tooltip';
import 'tinymce/themes/modern';
import {Editor} from '@tinymce/tinymce-react';
import '../../dict-components/tinymce/plugin.min';
import {TreeItemMenu} from './components/TreeItemMenu';
import {TreeUtilities, NodeOperations, TreeDOMHelper, TreeNodeHelper} from './components/TreeHelper';
import {TreeElementBuilder} from './components/TreeElementBuilder';
import {Notifications} from './../../utilities/DictUtilities';
import BUtils from './../../utilities/BaseUtilities';
import {
    Grid,
    Row,
    Col,
    ButtonToolbar,
    ButtonGroup,
    Button,
    FormControl,
    InputGroup,
    Modal,
    Glyphicon
} from 'react-bootstrap';
import Tree, {TreeNode} from 'draggable-react-tree-component';
import 'react-contexify/dist/ReactContexify.min.css';
import 'draggable-react-tree-component/lib/styles.css';
import FontAwesome from 'react-fontawesome';
import cssAnimation from 'css-animation';
import stylesSCTree from './SCTree.module.css';
import './SCTree.css';
import {userService} from "../../_redux/authentication/services/user.services";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {TreeItemSearch} from "./components/TreeItemSearch";
import {LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";
import {TreeIndexedSearch} from "./components/TreeIndexedSearch";
import {TreeContext} from "../../corpora/TreeContext";
import {SCCheckbox} from "../components";


function animate(node, show, done) {
    let height = node.offsetHeight;

    return cssAnimation(node, 'collapse', {
        start() {
            if (!show) {
                node.style.height = height + 'px';
            } else {
                height = node.offsetHeight;
                node.style.height = 0;
            }
        },
        active() {
            node.style.height = show ? height + 'px' : 0;
        },
        end() {
            node.style.height = '';
            done();
        },
    })
}

export class SCTree extends React.Component {

    constructor(props) {
        super(props);

        this.isLogged = userService.isLogged();
        this.isAdministrator = userService.isAdministrator();
        this.role = null;
        this.isInserter = null;
        this.isModifier = null;
        this.isModifierOrAdministrator = null;

        this.state = {
            projectName: "",
            autoincrement: "0",
            newProjectName: "",
            newProjectActive: false,
            treeStructure: [],
            composedTreeItems: [],
            composedCollections: [],
            itemsToIcon: {FOLDER: 'folder-open', DOCUMENT_CONTAINER: 'book'},
            itemsToPaste: '',
            savingInfos: {fileName: "", comment: ""},
            treeFilterItems: [],
            backuptreeStructure: [],
            expandedKeys: [],
            selectedKeys: [],
            selectedTreeFilterItems: [],
            autoExpandParent: true,
            dragExpandDelay: 2,
            /*nodeStart: "",*/
            nodeName: "",
            filterString: "",
            loadingNodeKey: null,
            modalProps: {
                showModal: false,
                modalOperation: "",
                hasAnomaly: false,
                anomalyMessage: "",
                additionalData: {}
            },
            nodeOperationProps: {},
            showTreeElementBuilder: false,
            creatingObject: {},
            quickSaving: false,
            resultSelected: [],
            textSearch: '',
            simpleSearch: []
        };

        this.nodeStart = "";
        this.nodeStartItem = "";
        this.nodeDropItem = "";

        [
            'onDragStart',
            'onDragOver',
            'onDragEnter',
            'onDrop',
            'onLoadData',
            'onExpand',
            'onSelect',
            'handleDragExpandDelayChanged',
            '_handleKeyDown',
            '_closeProjectName',
            '_showModal',
            '_autoSaveTimer',
            '_isProjectOpen',
            '_closeModal',
            '_addComposedItem',
            '_resetCutPaste'
        ].forEach((name) => (this[name] = this[name].bind(this)));
    }

    componentWillMount() {
        this.role = this.isLogged ? userService.getRoleByContext(this.context) : null;
        this.isInserter = this.isLogged && !this.isAdministrator && this.role === 'inseritore';
        this.isModifier = this.isLogged && !this.isAdministrator && this.role === 'modificatore';
        this.isModifierOrAdministrator = this.isModifier || this.isAdministrator;

        Dict.call('/admin/i18n/getCodedLanguages/').then((i18nlangs) => {
            Dict.call('admin/i18n/getInterfaceLanguage/').then((interfaceLang) => {

                var simpleSearch = this._populateSimpleSearch();

                this.setState({i18nlangs, interfaceLang: interfaceLang.code, simpleSearch});
                //Notifications.showModalSpinner(Dict.t('PROJECT_LOADING'), 'delete-lexeme-spinner');
                TreeUtilities.loadDefaultQuickSave().then((result) => {
                    try {
                        if (result !== '') {
                            var treeStructure = result.treeStructure;
                            result = {...JSON.parse(result.text)};
                            result.treeStructure = treeStructure;
                            this._loadProject(result);
                        }

                        /*document.getElementById("sc-tree").style.display = 'initial';*/
                        document.getElementById("sc-tree").style.display = 'block';
                        //document.getElementById("sc-tree").style.height = (window.innerHeight - 230) + 'px';
                        TreeDOMHelper.resizeTreeHeight();

                    } catch (e) {
                        TreeDOMHelper.callNotification({result: 'error', msg: 'DROPPED_FILE_NOT_ACCEPTED'});
                    }
                });
            });
        });
    }

    _populateSimpleSearch() {
        var simpleSearchFieldsByProp = Dict.getGlobalProperty('ADVANCED_SEARCH_FIELDS');
        if(simpleSearchFieldsByProp === null) {
            simpleSearchFieldsByProp = 'ALL::TITLE';
        } else {
            simpleSearchFieldsByProp = 'DOCS_SEARCH::true|' + simpleSearchFieldsByProp;
        }
        var checked = true;
        var simpleSearch = [];

        simpleSearchFieldsByProp.length > 0 ? simpleSearchFieldsByProp.split('|').forEach((optItem) => {
            const item = optItem.split('::')[0];
            var fieldsToSplit = optItem.split('::')[1];

            if (item === 'DOCS_SEARCH') {
                checked = false;
                const textSearch = fieldsToSplit === 'true';
                const label = Dict.t('DOCS_SEARCH');
                simpleSearch.push({item, textSearch, checked, label});
            } else {
                checked = true;
                if (typeof fieldsToSplit !== 'undefined') {
                    const fields = fieldsToSplit.split(',');
                    fields.forEach((field) => {
                        const label = Dict.t(field);
                        simpleSearch.unshift({item, field, checked, label});
                    });
                }
            }
        }) : '';

        return simpleSearch;
    }

    componentDidMount() {

        TreeDOMHelper.setSkin();

        if (typeof this.props.containerId !== 'undefined') {
            document.addEventListener('contextmenu', function (event) {
                if (event.target.id === this.props.containerId || event.target.classList.contains("react-tree") || event.target.classList.contains("react-tree-item-label") || event.target.classList.contains("react-tree-expander") || event.target.tagName === 'LI') {
                    event.preventDefault();
                }
            }.bind(this));
        }

        setTimeout(function () {
            var elem = document.getElementById("div-init-tinymce");
            if (elem !== null)
                elem.parentNode.removeChild(elem);
        }.bind(this), 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /*this._dragElement('node-page', 'title-viewer');
        this._dragElement('node-operations-container', 'input-add-node-container');*/
    }

    // per velocizzare il rendering delle icone
    _buildItemsToIcon() {
        var tbi = TreeNodeHelper.treeBasicItems;
        var cti = this.state.composedTreeItems;
        var cni = TreeNodeHelper.treeContainersItems;

        var itemsToIcon = this.state.itemsToIcon;
        var tot;

        if (cti.length > 0)
            tot = tbi.concat(cti);
        else
            tot = tbi;

        tot = tot.concat(cni);

        cni.forEach((cniItem) => {
            cniItem.subItems.forEach((subItem) => {
                tot = tot.concat(subItem);
            });
        });

        tot.forEach((item) => {
            if (typeof itemsToIcon[item.code] === 'undefined')
                itemsToIcon[item.code] = item.icon;
        });

        this.setState({itemsToIcon});
    }

    onDragStart(event) {
        /*this.nodeStart = event.node;
        NodeOperations.traverseToKey([...this.state.treeStructure], event.node.props.eventKey, (dragObj) => {
            this.nodeStartItem = dragObj;
        });


        TreeDOMHelper.resetItemSelection();*/
    }

    onDragOver(event) {
        /*if (!event.node.li.classList.contains('drag-over') && this.nodeStart !== event.node) {
            event.node.li.classList.add('drag-over');
            event.node.li.classList.remove('drag-not-over');
        }

        const nodeStartIsEventNode = this.nodeStart === event.node;
        const eventNodeHasNoDragOverClass = event.node.li.classList.contains('drag-over');
        const eventNodeIsPureNode = event.node.li.querySelectorAll('.' + stylesSCTree['tree-node-context-menu'])[0].classList.contains('pure-node');
        const eventNodeHasNoDragOverNotClass = event.node.li.classList.contains('drag-over-not');


        if (!nodeStartIsEventNode && eventNodeHasNoDragOverClass) {
            event.node.li.classList.add('drag-over');
            event.node.li.classList.remove('drag-not-over');
        }

        if (!eventNodeIsPureNode) {
            event.node.li.classList.add('drag-not-over');
            event.node.li.classList.remove('drag-over');
        }

        const foundTitle = typeof this.nodeDropItem.children !== 'undefined' && this.nodeDropItem.children.some((child) => {
            return child.title === this.nodeStartItem.title;
        });

        if (foundTitle) {
            event.node.li.classList.add('drag-not-over');
            event.node.li.classList.add('drag-not-over-child-exists');
            event.node.li.classList.remove('drag-over');
        }*/
    }

    onDragLeave(info) {
        /*info.node.li.className = "";*/
    }

    onDragEnter(info) {
        /*NodeOperations.traverseToKey([...this.state.treeStructure], info.node.props.eventKey, (dropObj) => {
            this.nodeDropItem = dropObj;
        });

        this.setState({
            expandedKeys: info.expandedKeys,
        })*/
    }

    onDrop(info) {
        /*console.log('drop', info);*/

        /*if (info.node.li.classList.contains('drag-not-over')) {
            var treeStructure = this.state.treeStructure;
            this.setState({treeStructure})
        } else {

            const dropKey = info.node.props.eventKey;
            const dragKey = info.dragNode.props.eventKey;


            info.node.li.className = "";
            const data = [...this.state.treeStructure];
            let dragObj;

            NodeOperations.traverseToKey(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item
            });

            if (info.dropToGap) {
                NodeOperations.traverseToKey(data, dropKey, (item) => {
                    item.children = item.children || [];

                    let index = info.dropPosition +
                        (info.dropPositionOnNode > 0 ? 1 : 0);

                    if (info.isSameLevel && info.dragPosition < info.dropPosition) {
                        index -= 1
                    }
                    // where to insert
                    item.children.splice(index, 0, dragObj)
                })
            } else {
                NodeOperations.traverseToKey(data, dropKey, (item) => {
                    item.children = item.children || [];
                    // where to insert


                    dragObj.parentKey = item.key;
                    dragObj.ancestorsKeys = (item.ancestorsKeys + "").length === 0 ? item.key : item.ancestorsKeys + "^" + item.key;

                    item.children.push(dragObj)
                })
            }

            this.setState({
                treeStructure: data,
                expandedKeys: info.rawExpandedKeys.concat([dropKey]),
            })
        }*/
    }

    onExpand(expandedKeys, treeNode) {
        //const item = treeNode.node.props.children.props.item;

        this.setState({
            expandedKeys,
            autoExpandParent: false,
        }, function () {
            TreeDOMHelper.resetItemSelection();
        })
    }

    onLoadData(treeNode) {
        const item = treeNode.props.children.props.item;
        this._performLoadData(item, item.code === 'FOLDER');
    }

    _performLoadData(item, useSpinner, callback) {
        if (!item.children) {
            const key = item.key;
            if (useSpinner && false) {
                var spinnerElement = document.getElementById('loading-key-spin-' + item.key);
                if (spinnerElement !== null)
                    spinnerElement.style.display = 'inline-block';
            }
            TreeUtilities.loadNode(this.state, key).then((result) => {
                var treeStructure = this.state.treeStructure;
                item.children = result;
                var lastRenderKey = item.children.length > 0 ? item.children[item.children.length - 1].key : this.state.lastRenderKey;

                this.setState({treeStructure, lastRenderKey}, () => {
                    if (typeof callback !== 'undefined')
                        callback();
                });
            });
        } else if (typeof callback !== 'undefined') {
            callback();
        }
    }

    _loadChain(itemStart, itemStop, callback) {
        this._performLoadData(itemStart, false, () => {
            var keys = itemStop.ancestors.split('_');
            var child = itemStart.children.find((child) => {
                return child.key === itemStop.key || keys.indexOf(child.key) >= 0;
            });

            if (child.key === itemStop.key)
                callback();
            else
                this._loadChain(child, itemStop, callback);

        })
    };

    onSelect(selectedKeys, info) {
        /*console.log('onSelect', selectedKeys, info);*/
        /*this.setState({
            selectedKeys,
        })*/
    }

    handleDragExpandDelayChanged({target: {value}}) {
        this.setState({
            dragExpandDelay: value
        })
    }

    _autoSaveTimer() {
        if (false && this._isProjectOpen()) {
            TreeUtilities.quickSave(this.state);

            const AutoSave = <span id={"sc-tree-automatic-save"} className={stylesSCTree['tree-automatic-save']}>
                <FontAwesome className={stylesSCTree['tree-automatic-save__span']}
                             name={"floppy-o"}/>{Dict.t('AUTOSAVE')}
                </span>;

            this._writeOnSaveMessage(AutoSave);
        }
    }

    _writeOnSaveMessage(element) {
        if (userService.isLogged()) {
            ReactDOM.render(element, document.getElementById("sc-tree-msg-save"));
            setTimeout(function () {
                ReactDOM.render(<span/>, document.getElementById("sc-tree-msg-save"));
            }, 2000);
        }
    }

    _openProjectName() {
        this.setState({newProjectActive: !this.state.newProjectActive}, function () {
            if (this.state.newProjectActive) {
                setTimeout(function () {
                    ReactDOM.findDOMNode(this.formControl).focus();
                }.bind(this), 500);
            } else {
                ReactDOM.findDOMNode(this.formControl).blur();
            }
        });
    }

    _closeProjectName(ask) {
        if (ask) {

        } else {
            this.setState({
                modalProps: {showModal: false, modalOperation: ""},
                projectName: "",
                autoincrement: 0,
                newProjectName: "",
                newProjectActive: false,
                treeStructure: [],
                backuptreeStructure: [],
                composedTreeItems: [],
                composedCollections: [],
            });
        }
    }

    _isProjectOpen() {
        return Object.keys(this.state.projectName).length > 0;
    }

    _handleProjectName(evt) {
        this.setState({newProjectName: evt.target.value});
    }

    _handleKeyDown(evt) {
        if (evt.keyCode === 27) {
            this.setState({newProjectName: "", autoincrement: 0}, function () {
                this._openProjectName();
            }.bind(this));
        } else if (evt.keyCode === 13) {
            const projectName = this.state.newProjectName;
            Dict.call('/tree/createProject/' + projectName).then((result) => {
                if (result.result === 'OK') {
                    this.setState({newProjectName: ""}, function () {
                        this._openProjectName();
                        setTimeout(function () {
                            this.setState({projectName}, function () {
                                TreeUtilities.quickSave(this.state);
                            });
                        }.bind(this), 700);
                    }.bind(this));
                } else {
                    TreeDOMHelper.callNotification(result);
                }
            });
        }
    }

    _openItem(item, container) {
        const itemRetrieve = typeof container === 'undefined' ? item : container;

        var selectedKeys = [];
        if (selectedKeys.indexOf(item.key) === -1)
            selectedKeys.push(item.key);

        var expandedKeys = this.state.expandedKeys;
        const chainKeys = item.ancestors.split('_');

        const treeStructure = this.state.treeStructure;

        var itemStart = NodeOperations.retrieveRoot(treeStructure, itemRetrieve);
        this._loadChain(itemStart, item, () => {
            chainKeys.forEach((key) => {
                if (expandedKeys.indexOf(key) === -1)
                    expandedKeys.push(key);

                this.setState({
                    selectedKeys,
                    expandedKeys,
                    autoExpandParent: true,
                }, function () {
                    setTimeout(function () {
                        var el = document.getElementById('item-click-' + item.key);
                        var evt = document.createEvent("MouseEvents");
                        evt.initMouseEvent("mouseup", true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null);
                        el.dispatchEvent(evt);

                        document.getElementById('node-page').style.top = 250 + "px";

                    }, 500);
                });
            });
        });
    }

    _filterSearch(evt) {
        this.setState({filterString: evt.target.value});

        if (Object.keys(evt.target.value).length > 0) {

            let dragObj = [];

            NodeOperations.traverseToTitle([...this.state.treeStructure], evt.target.value, (item, index, arr) => {
                dragObj.push(item);
            });

            if (dragObj.length > 0) {
                var selectedKeys = [];

                dragObj.forEach((item) => {
                    if (selectedKeys.indexOf(item.key) === -1)
                        selectedKeys.push(item.key);
                });

                var expandedKeys = JSON.parse(JSON.stringify(selectedKeys));

                this.setState({selectedKeys, expandedKeys, autoExpandParent: true,}, function () {
                });
            } else {
                this.setState({selectedKeys: []});
            }

        } else {
            this.setState({selectedKeys: [], expandedKeys: []});
        }
    }

    _showModal(operation, focusOn, additionalData) {
        this.setState({
            nodeName: "",
            modalProps: {
                showModal: true,
                modalOperation: operation,
                additionalData: additionalData
            }
        }, function () {
            if (typeof focusOn !== 'undefined') {
                setTimeout(function () {
                    ReactDOM.findDOMNode(this[focusOn]).focus();
                }.bind(this), 500);
            }
        });
    }

    _clickOpenExportCsv() {
        this._showModal('EXPORT_CSV', undefined);
    }

    _clickImport() {
    }

    _closeModal() {
        this.setState({modalProps: {showModal: false}});
    }

    _getModalContentByOperation(operation, additionalData) {
        if (operation === "ASK_CLOSE_PROJECT") {
            return <span id="tree-modal-ask-close-project">
                <span style={{'paddingRight': '20px'}}>{Dict.t('ASK_CLOSE_PROJECT')}</span>
                <Button onClick={() => this._closeProjectName()}>{Dict.t('YES')}</Button>&nbsp;
                <Button onClick={() => this._closeModal()}>{Dict.t('NO')}</Button>
            </span>;
        } else if (operation === "ADD_ROOT_NODE") { //"input-group-add-root-node"

            const hasAnomaly = typeof this.state.modalProps.hasAnomaly !== 'undefined' && this.state.modalProps.hasAnomaly;

            return <InputGroup className={stylesSCTree["add-root-node"]}>
                <FormControl
                    type="text"
                    placeholder={Dict.t("ADD_ROOT_NODE")}
                    ref={(input) => {
                        this.rootNodeFormControl = input;
                    }}
                    value={this.state.nodeName}
                    onChange={(evt) => {

                        const hasAnomaly = this.state.treeStructure.some((item) => {
                            return item.title === evt.target.value;
                        });

                        const anomalyMessage = hasAnomaly ? Dict.t('NODE_EXISTS_BY_TITLE') : "";

                        const upd = update(this.state.modalProps, {$merge: {hasAnomaly, anomalyMessage}});
                        this.setState({nodeName: evt.target.value, modalProps: upd})
                    }}
                    onKeyDown={function (evt) {
                        if (evt.keyCode === 27) {
                            this._closeModal();
                        } else if (evt.keyCode === 13 && !this.state.modalProps.hasAnomaly) {
                            var treeStructure = this.state.treeStructure;

                            var autoincrement = this.state.autoincrement;
                            autoincrement = parseFloat(autoincrement) + parseFloat(1);

                            var itemNode = {
                                key: autoincrement + "",
                                ancestors: '',
                                type: 'folder',
                                code: 'FOLDER',
                                isLeaf: true,
                                nodeOrder: (parseFloat(treeStructure.length) + 1),
                                projectName: this.state.projectName,
                                data: [],
                                hidden: false,
                                hiddenBranch: false,
                                title: this.state.nodeName
                            };

                            treeStructure.push(itemNode);

                            this.setState({treeStructure, autoincrement}, () => {
                                this._closeModal();
                                //TreeUtilities.quickSave(this.state);
                                TreeUtilities.saveItemData(this.state, itemNode);
                            });
                        }
                    }.bind(this)}
                />
                <span
                    className={BUtils.joinClasses(stylesSCTree["add-root-node__span-alert"], stylesSCTree["add-root-node__span-alert--" + hasAnomaly])}>
                    <Glyphicon glyph={"alert"} data-tip data-for='happyFace'></Glyphicon>
                    <ReactTooltip id='happyFace' type='error' className={"tree-tool-tip"}>
                        <span>{this.state.modalProps.anomalyMessage}
                        </span>
                    </ReactTooltip>
                </span>
            </InputGroup>
        } else if (operation === "EXPORT_CSV") {
            var treeItems = TreeNodeHelper.treeBasicItems.concat(this.state.composedTreeItems);
            var titolo = Dict.t('TITLE');
            titolo = titolo.charAt(0).toUpperCase() + titolo.substr(1);
            var elements = [titolo];

            const TitleExport = <Row
                className={stylesSCTree['tree-modal-title']}>{Dict.t('CHOOSE_MODEL_TO_EXPORT')}</Row>;

            const ElementsToExport = treeItems.map((item, index) => {
                return <div
                    className={BUtils.joinClasses(stylesSCTree['export-csv-item'], stylesSCTree['export-csv-item--first-composed-' + (index === TreeNodeHelper.treeBasicItems.length)])}
                    key={"key-contextmenu-" + item.code}
                    onClick={() => {
                        var itemCode = item.isBasic ? Dict.t(item.code) : item.code;
                        var fullElements = elements.concat(item.isBasic ? itemCode : item.basicItems.map(basicItem => basicItem.i18nLabels.it));
                        var text = fullElements.join('\t');
                        TreeUtilities.exportCSV(Dict.t('MODEL') + " " + itemCode, text);
                    }}>
                    <FontAwesome className={stylesSCTree["export-csv-fa"]}
                                 name={item.icon}/>{item.isBasic ? Dict.t(item.code) : item.code}
                </div>
            });

            return <Grid id='tree-modal-restore-project' fluid={true}>
                {TitleExport}
                {ElementsToExport}
            </Grid>
        }
    }

    _resetCutPaste() {
        this.setState({itemsToPaste: ''});
    }

    _resetCurrentOperations(evt) {

        const evtFound = typeof evt !== 'undefined';

        if (evtFound) {
            var inNodePage = false;

            var isInNode = function (elem) {
                for (; elem && elem !== document; elem = elem.parentNode) {
                    if ('node-page' === elem.id || 'node-operations-container' === elem.id || 'node-import-container' === elem.id)
                        inNodePage = true;
                }
            };

            isInNode(evt.target);

            if (!inNodePage)
                TreeDOMHelper.hideNodePageContainer();
        }
    }

    _loadProject(jsonProject) {
        const projectName = jsonProject.projectName;
        const autoincrement = jsonProject.autoincrement;
        var treeStructure = jsonProject.treeStructure;

        const composedTreeItems = jsonProject.composedTreeItems;
        const composedCollections = jsonProject.composedCollections;
        const newProjectName = "";
        const newProjectActive = false;

        this._resetCurrentOperations();
        this.setState({
            projectName,
            autoincrement,
            treeStructure,
            composedTreeItems,
            composedCollections,
            newProjectName,
            newProjectActive
        }, () => {
            Notifications.showToastNotification(this.state.projectName + " " + Dict.t("LOADED"), 5000, 2000);
            this._buildItemsToIcon();
        });
    }

    _addComposedItem(composed, isEdit) {
        var errorMessage = '';
        var composedTreeItems = this.state.composedTreeItems;
        if (errorMessage.length === 0) {
            if (isEdit) {
                composedTreeItems.some((item, index) => {
                    if (composed.id === item.id) {
                        composedTreeItems[index] = {...composed}; // clona
                        return true;
                    }
                });

            } else {
                composed.id = composedTreeItems.length === 0 ? 1 : parseInt(composedTreeItems[composedTreeItems.length - 1].id) + parseInt(1);
                composedTreeItems.push(composed);
            }

            this.setState({composedTreeItems});

        } else {
            Notifications.showErrorModalMessage(errorMessage, 3000);
        }
    }

    _addComposedCollections(composedCollections) {
        this.setState({composedCollections});
    }

    _unmountSearch() {
        ReactDOM.unmountComponentAtNode(document.getElementById('tree-indexed-search-container'));
        ReactDOM.unmountComponentAtNode(document.getElementById('tree-indexed-doc-search-container'));
    }

    _createTreeIndexedSearch(id, left, docsSearch, simpleSearch, isAdvancedSearch, indexedSearchOpen, searchOpen, indexedSearchQuery) {
        return <TreeIndexedSearch
            id={id}
            left={left}
            docsSearch={docsSearch}
            key={'key-re-IndexedSearch-' + id}
            projectName={this.state.projectName}
            composedTreeItems={this.state.composedTreeItems}
            indexedSearchOpen={indexedSearchOpen}
            itemsToIcon={this.state.itemsToIcon}
            indexedSearchQuery={indexedSearchQuery}
            simpleSearch={simpleSearch}
            isAdvancedSearch={isAdvancedSearch}
            callbackCloseIndexedSearch={() => {
                this._unmountSearch();
            }}

            callbackIndexedSearch={(selectedTreeFilterItems) => {
                this.setState({
                    selectedTreeFilterItems,
                    searchOn: true
                }, function () {
                    this.setState({searchOn: false})
                }.bind(this));
            }}

            callbackOpenTextIndexedSearch={(item, page) => {

                var parentSharedResourceName = TreeNodeHelper.getContainerSharedResourceName(item.code);
                var itemToSend = item.data.find((datum) => {
                    return datum.code === parentSharedResourceName.code;
                }).value[0];
                var data = {folder: item.key/*NodeOperations.retrieveParentKey(item)*/};

                var src = TreeUtilities.buildMultimediaSrc(this.state.projectName, itemToSend, data);
                TreeDOMHelper.performOpenDocumentPreview(src, null, null, this.state.projectName, data.folder, 'tree-modal-doc-body-indexed-search', itemToSend.filename, itemToSend.extension, page, item.key);
            }}

            callbackOpenItem={(item, container) => {
                this._openItem(item, container);
            }}

            callbackOpenEditItem={(item, container) => {

            }}
        />
    }

    _updateSubItems(item, state) {
        Notifications.showModalSpinner(Dict.t('UPDATE_SUB_ITEMS'), '');
        TreeUtilities.checkContainerAndResetChildrenData(item, state, function () {
            var treeStructure = state.treeStructure;
            item.children = undefined;
            this.setState({treeStructure}, function () {
                setTimeout(function () {
                    this._performLoadData(item, false, function () {
                        Notifications.closeModalSpinner();
                    })
                }.bind(this), 1000);
            });
        }.bind(this));
    }

    _performSimpleSearch(textSearch) {
        var simpleField = this.state.simpleSearch.find((item) => {
            return item.field !== 'TITLE' && item.item !== 'DOCS_SEARCH'
        });

        var simpleSearchListItems = [];
        this.setState({simpleSearchListItems});


        Dict.call('/tree/simpleOnSearchIndexed/' + this.state.projectName + '/1/' + textSearch + '/' + Dict.encodeUrl(JSON.stringify(this.state.simpleSearch)) + '/title/false/' + userService.isLogged() + '/').then((result) => {
            result.listItems.forEach((item) => {
                var valueField = { value : ''};
                if(typeof simpleField !== 'undefined') {
                    valueField = item.data.find((itemData) => {
                        return itemData.label === simpleField.field;
                    });
                }

                var label = item.title + ' {' + valueField.value + '}';
                var value = valueField.value;
                var code = item.key;

                simpleSearchListItems.push({label, code, value})
            });

            this.setState({simpleSearchListItems});
        });
    }

    _performKeyDownSimpleSearch(text) {
        var openSimpleSearch = false;
        var openSimpleSearchDocs = false;
        this.state.simpleSearch.forEach((item) => {
            if (item.item !== 'DOCS_SEARCH' && item.checked)
                openSimpleSearch = true;
            if (item.item === 'DOCS_SEARCH' && item.checked)
                openSimpleSearchDocs = true;
        });

        if (openSimpleSearch)
            this._renderSearch('tree-indexed-simple-search', 'tree-indexed-search-container', '10%', false, false, true, false, text);
        if (openSimpleSearchDocs)
            this._renderSearch('tree-indexed-doc-search', 'tree-indexed-doc-search-container', '48%', true, false, true, true, text);

        document.getElementById("App-body").click();
    }

    _renderSearch(id, idContainer, left, docSearch, isAdvancedSearch, indexedSearchOpen, searchOpen, indexedSearchQuery) {
        var TreeIndexedSearch = this._createTreeIndexedSearch(id, left, docSearch, this.state.simpleSearch, isAdvancedSearch, indexedSearchOpen, searchOpen, indexedSearchQuery);
        ReactDOM.render(TreeIndexedSearch, document.getElementById(idContainer), () => {
            TreeDOMHelper._dragElement(id, id + '__header')
        });
    }

    render() {

        userService.unloggedReload();
        const isLogged = userService.isLogged();


        const animation = {
            enter(node, done) {
                return animate(node, true, done)
            },
            leave(node, done) {
                return animate(node, false, done)
            },
            appear(node, done) {
                return animate(node, true, done)
            },
        };

        const loop = data => (
            data.map((item) => {
                    const expandedKeys = this.state.expandedKeys;

                    var visibleChildren = [];
                    //var children = item.children;
                    const goLoop = (expandedKeys.indexOf(item.key) >= 0);

                    if (goLoop) {
                        /*if (!isLogged && typeof children !== 'undefined' && children.length) {
                            var childrenLength = children.length;

                            for (var j = 0; j < childrenLength; j++) {
                                var child = children[j];
                                if (typeof child.hidden === 'undefined' || !child.hidden)
                                    visibleChildren.push(child);
                            }
                        } else {*/
                        visibleChildren = item.children;
                        //}
                    }

                    return <TreeNode
                        className={"drag-not-over " + "tree-li"}
                        id={"id-" + item.title}
                        isLeaf={item.isLeaf}
                        key={item.key}
                        items={goLoop && (typeof visibleChildren !== 'undefined' && visibleChildren.length > 0) ? loop(visibleChildren) : null}
                        openAnimation={animation}
                    ><TreeItemMenu item={item}
                                   key={'tree-item-menu-key-' + item.key}
                                   projectName={this.state.projectName}
                                   treeStructure={this.state.treeStructure}
                                   composedTreeItems={this.state.composedTreeItems}
                                   composedCollections={this.state.composedCollections}
                                   itemsToIcon={this.state.itemsToIcon}
                                   itemsToPaste={this.state.itemsToPaste}
                                   cutPasteType={this.state.cutPasteType}
                                   autoincrement={this.state.autoincrement}
                                   interfaceLang={this.state.interfaceLang}
                                   lastUpdate={this.state.lastUpdate}
                                   roleProps={{role: this.role, isInserter: this.isInserter, isModifier: this.isModifier}}
                        /*expandedKeys={this.state.expandedKeys}*/
                                   lastRenderKey={this.state.lastRenderKey}
                                   callbackSort={(children, isRoot) => {
                                       var treeStructure = isRoot ? children : this.state.treeStructure;
                                       if (!isRoot) {
                                           item.children = children;
                                       }
                                       this.setState({treeStructure}, () => {
                                           var keys = children.map((child) => {
                                               return child.key
                                           });
                                           Dict.call('/tree/sortItems/' + this.state.projectName + '/' + JSON.stringify(keys) + '/');
                                       });
                                   }}

                                   callbackCut={(itemsToPaste, cutChildren) => {
                                       var cutPasteType = cutChildren ? "CUT_CHILDREN" : "CUT_SELF";
                                       this.setState({itemsToPaste, cutPasteType})
                                   }}

                                   callbackCopy={(itemsToPaste, cutChildren) => {
                                       var cutPasteType = cutChildren ? "COPY_CHILDREN" : "COPY_SELF";
                                       this.setState({itemsToPaste, cutPasteType})
                                   }}

                                   callbackResetCut={() => {
                                       this._resetCutPaste();
                                   }}

                                   callbackPaste={(item) => {
                                       var oldKey = NodeOperations.retrieveParentKey(this.state.itemsToPaste);
                                       const messageSpinner = '✂ ' + this.state.itemsToPaste.title + ' 🠞 ' + item.title;
                                       Notifications.showModalSpinner(messageSpinner, stylesSCTree['tree-modal-spinner-cut-copy']);
                                       var state = this.state;
                                       var itemsToPaste = this.state.itemsToPaste;

                                       Dict.call('/tree/pasteItems/' + this.state.projectName + '/' + item.key + '/' + this.state.itemsToPaste.key + '/' + this.state.cutPasteType + '/').then(result => {
                                           TreeUtilities.loadNode(this.state, oldKey).then((result) => {
                                               var treeStructure = JSON.parse(JSON.stringify(this.state.treeStructure));
                                               var keyToTraverse = oldKey;
                                               if (this.state.cutPasteType === 'COPY_CHILDREN') // per il refresh del vecchio padre
                                                   keyToTraverse = this.state.itemsToPaste.children[0].key;

                                               NodeOperations.traverseToKey(treeStructure, keyToTraverse, (found, index, arr) => {
                                                   found.children = result;
                                                   this.setState({treeStructure});
                                                   NodeOperations.traverseToKey(treeStructure, item.key, (found2, index, arr) => {
                                                       delete found2.children;

                                                       var callbackReset = function () {
                                                           this.setState({itemsToPaste: ''});
                                                           setTimeout(function () {
                                                               Notifications.closeModalSpinnerMsg(messageSpinner, stylesSCTree['tree-modal-spinner-cut-copy']);
                                                           }, 2000);
                                                       }.bind(this);

                                                       found2.isLeaf = false;
                                                       this._performLoadData(found2, false, callbackReset);
                                                   })
                                                   //setTimeout(function() { this._resetCutPaste()}.bind(this), 10000);
                                               });
                                           });
                                       })
                                   }}

                                   callbackRemove={(onlyChildren) => {
                                       var treeStructure = JSON.parse(JSON.stringify(this.state.treeStructure));

                                       if (onlyChildren) {
                                           NodeOperations.removeChildren(treeStructure, item);
                                       } else
                                           NodeOperations.removeNode(treeStructure, item);

                                       this.setState({treeStructure}, () => {
                                           if (item.code !== 'FOLDER' && !TreeNodeHelper.isContainerItem(item.code) && !onlyChildren) {
                                               Dict.call('/tree/removeItemData/' + this.state.projectName + '/' + item.key + '/').then(result => {
                                                   if (result.result === 'OK') {
                                                       TreeUtilities.quickSave(this.state);
                                                   } else if (result.result === 'error') {
                                                       Notifications.showErrorModalMessage('ignore:' + result.message, 3000);
                                                   }
                                               });
                                           } else {
                                               Dict.call('/tree/removeNode/' + this.state.projectName + '/' + item.key + '/').then(result => {
                                                   if (result.result === 'OK') {
                                                       TreeUtilities.quickSave(this.state);
                                                   } else if (result.result === 'error') {
                                                       Notifications.showErrorModalMessage('ignore:' + result.message, 3000);
                                                   }
                                               })
                                           }
                                       });
                                   }}

                                   callbackUpdateContainer={(addFile) => {
                                       var resource = TreeNodeHelper.getContainerSharedResource(item);
                                       var val = resource.value[0];
                                       if (addFile || (typeof val !== 'undefined' && typeof val.status !== 'undefined' && val.status === 'to_remove')) {
                                           var treeStructure = JSON.parse(JSON.stringify(this.state.treeStructure));
                                           NodeOperations.removeChildren(treeStructure, item);
                                           this.setState({treeStructure}, () => {
                                               Dict.call('/tree/removeChildrenData/' + this.state.projectName + '/' + item.key + '/').then(result => {
                                                   if (result.result === 'OK') {
                                                       TreeUtilities.quickSave(this.state);
                                                   } else if (result.result === 'error') {
                                                       Notifications.showErrorModalMessage('ignore:' + result.message, 3000);
                                                   }
                                               })
                                           })
                                       }
                                   }}

                                   callbackImport={(importItems, validFiles, expander) => {
                                       var autoincrement = this.state.autoincrement + '';
                                       autoincrement = parseFloat(autoincrement) + parseFloat(1);
                                       var tableOfData = [];
                                       importItems.forEach((importItem, index) => {
                                           autoincrement = (parseFloat(autoincrement) + parseFloat(1)) + "";
                                           importItem.key = autoincrement;

                                           importItem.data.forEach(dataItem => {
                                               if (dataItem.type === 'dropzone') {
                                                   dataItem.folder = autoincrement;
                                               }
                                           });

                                           tableOfData.push({
                                               key: importItem.key,
                                               title: importItem.title,
                                               code: importItem.code,
                                               data: importItem.data,
                                               ancestors: importItem.ancestors,
                                               nodeOrder: (index + 1),
                                               hidden: false,
                                               hiddenBranch: false
                                           });
                                           importItem.data = '';

                                           if (!item.children)
                                               item.children = [];
                                           item.children.push(importItem);
                                       });

                                       item.isLeaf = false;

                                       if (validFiles.length > 0) {
                                           item.children.forEach(child => {
                                               var files = validFiles.filter(file => {
                                                   return file.title === child.title;
                                               });

                                               if (typeof files !== 'undefined') {
                                                   files.forEach(file => {
                                                       var projectName = this.state.projectName;
                                                       var encode = LexemeUtilities.encodeUrl(file.filename + '.' + file.extension);
                                                       encode = encode.replace(/\./g, '^dot^');

                                                       Dict.call('/tree/importMultimedia/' + projectName + '/' + child.key + '/' + encode + '/' + file.type + '/').then(result => {

                                                       });
                                                   });
                                               }
                                           });
                                       }

                                       this.setState({
                                           autoincrement
                                       }, function () {
                                           TreeDOMHelper.hideAndClickExpander(expander);
                                           Notifications.showModalSpinner(Dict.t('TREE_IMPORT_IN_PROGRESS'), stylesSCTree['modal-import-message']);
                                           TreeUtilities.insertTableOfData(this.state, tableOfData, false, () => {
                                               TreeUtilities.emptyImportMultimediaResources();
                                               Notifications.closeModalSpinner();
                                               window.location.reload();
                                           });
                                       });
                                   }}

                                   callbackSave={(returnItem, operation, expander, parentElement) => {
                                       var retrieveItem = new Promise((resolve) => {
                                           if (!item.isLeaf && typeof item.children === 'undefined') {
                                               return resolve(TreeUtilities.loadNode(this.state, item.key))
                                           } else {
                                               return resolve(item.children);
                                           }
                                       });

                                       retrieveItem.then((children) => {
                                           var autoincrement = this.state.autoincrement;
                                           const isAdd = operation === TreeNodeHelper.treeNodeOperations.ADD;
                                           var lastUpdate;
                                           item.children = children;

                                           if (isAdd) {
                                               autoincrement = parseFloat(autoincrement) + parseFloat(1) + "";
                                               returnItem.key = autoincrement;
                                               lastUpdate = autoincrement;
                                               returnItem.nodeOrder = item.isLeaf ? 1 : (item.children.length + 1);
                                               if (item.isLeaf)
                                                   item.children = [];
                                               item.children.push(returnItem);
                                               item.isLeaf = false;

                                               returnItem.hidden = false;
                                               returnItem.hiddenBranch = false;
                                           } else {
                                               item.title = returnItem.title;
                                               lastUpdate = item.key;
                                           }

                                           this.setState({
                                               autoincrement, lastUpdate
                                           }, function () {
                                               //TreeDOMHelper.hideAndClickExpander(expander);
                                               //item.data = '';
                                               TreeUtilities.saveItemData(this.state, returnItem, item).then((result) => {
                                                   if (result.result === 'OK') {
                                                       if (isAdd) {
                                                           setTimeout(() => {
                                                               TreeUtilities.quickSave(this.state);
                                                               var childrenToScroll = parentElement.closest("li").getElementsByTagName("ul")[0].children;
                                                               var childToScroll = childrenToScroll[childrenToScroll.length - 1];
                                                               childToScroll.scrollIntoView({
                                                                   block: 'end',
                                                                   behavior: 'smooth'
                                                               });

                                                               TreeUtilities.loadNode(this.state, key).then((result) => {
                                                                   var treeStructure = this.state.treeStructure;
                                                                   item.children = result;
                                                                   this.setState({treeStructure})
                                                               });


                                                           }, 1000);
                                                       } else {
                                                           TreeUtilities.quickSave(this.state);
                                                           treeStructure = this.state.treeStructure;
                                                           item.data = returnItem.data;
                                                           this.setState({treeStructure}, () => {
                                                               if (TreeNodeHelper.isContainerItem(item.code)) {
                                                                   this._updateSubItems(item, this.state);
                                                               }
                                                           });

                                                       }
                                                   } else {
                                                       Notifications.showErrorModalMessage("ignore:" + result.message, 3000);
                                                       var treeStructure = NodeOperations.removeNode(this.state.treeStructure, returnItem);
                                                       this.setState({treeStructure});
                                                   }
                                               });
                                           });
                                       })
                                   }
                                   }

                                   callbackShowHideItem={(hidden) => {
                                       var treeStructure = this.state.treeStructure;
                                       if (item.code === 'FOLDER') {
                                           item.hiddenBranch = hidden;
                                           var divItems = document.body.querySelectorAll("#item-click-" + item.key + ", div[ancestors='" + item.key + "'],div[ancestors^='" + item.key + "^'], div[ancestors$='^" + item.key + "'], div[ancestors*='^" + item.key + "^']");

                                           /*NodeOperations.recursiveShowHideBranch(item, hidden, [], () => {
                                               console.log('');
                                               this.setState({treeStructure});
                                           });*/

                                           Dict.call('/tree/showHideBranch/' + this.state.projectName + '/' + item.key + '/' + hidden + '/').then((result) => {
                                               divItems.forEach((divItem) => {
                                                   divItem.classList.remove(stylesSCTree['react-tree-branch-item--' + !hidden]);
                                                   divItem.classList.add(stylesSCTree['react-tree-branch-item--' + hidden]);
                                               });
                                           });
                                       } else {
                                           item.hidden = hidden;
                                           this.setState({treeStructure});
                                           TreeUtilities.saveItemData(this.state, item).then((result) => {
                                               //console.log(result);
                                           })
                                       }


                                   }}

                                   callbackQuickSave={() => {
                                       TreeUtilities.quickSave(this.state);
                                   }}
                    />
                    </TreeNode>
                }
            )
        );

        const projectNameActive = Object.keys(this.state.projectName).length > 0;
        const modalOperationClass = typeof this.state.modalProps.modalOperation === 'undefined' ? '' :
            stylesSCTree["tree-modal__" + this.state.modalProps.modalOperation.toLowerCase()];

        const PopupNodePage = () => {
            return <div id="node-page"
                        className={BUtils.joinClasses(stylesSCTree['node-page'], stylesSCTree['node-page--inactive'], stylesSCTree['tree-main-skin'])}/>;
        };

        const PopupNodeOperationPage = () => {
            return <div id="node-operations-container"
                        className={BUtils.joinClasses(stylesSCTree['node-operations-container'], stylesSCTree['node-operations-container--inactive'])}/>
        };

        var docSearchChecked = false;
        const SimpleSearchFields = this.state.simpleSearch.length > 1 ? this.state.simpleSearch.map((item) => {
            if (item.item === 'DOCS_SEARCH')
                docSearchChecked = item.checked;

            return <SCCheckbox
                checked={item.checked}
                key={"key-chk-simply-search-" + item.field}
                additionalClass={stylesSCTree['chk-search-text-indexed']}
                label={item.label}
                tooltip={item.item}
                id={"chk-search-text-indexed-" + item.field}
                callbackParent={(id, newState) => {
                    var simpleSearch = this.state.simpleSearch;
                    simpleSearch[simpleSearch.indexOf(simpleSearch.find((simItem) => {
                        return simItem === item;
                    }))].checked = newState;

                    this.setState({simpleSearch});
                }}/>
        }) : '';

        const sizeColSearch = 10; //userService.isLogged() ? 8 : 9;

        return <div id="sc-tree" onClick={(evt) => this._resetCurrentOperations(evt)}>
            <div id="div-init-tinymce" style={{display: 'none'}}>
                <Editor
                    id={'init-tinymce'}
                    initialValue={""}
                    init={{
                        plugins: 'link image code sccharmap',
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | sccharmap',
                    }}
                    onChange={() => {
                    }}
                />
            </div>
            <Row id="tree-button-bar"
                 className={BUtils.joinClasses(stylesSCTree['tree-button-bar'], stylesSCTree['tree-minor-skin'])}>
                <Col lg={2} md={2}>
                    {isLogged ? <ButtonToolbar>
                        {Object.keys(this.state.projectName).length === 0 ?
                            <ButtonGroup>
                                <Button
                                    onClick={() => this._openProjectName()}>{Dict.t('CREATE_PROJECT')}
                                </Button>
                                <Button>{Dict.t('IMPORT_PROJECT')}</Button>
                            </ButtonGroup> :
                            <ButtonGroup>
                                {this.isModifierOrAdministrator ? <Button data-tip data-for='plus'
                                                                          onClick={() => this._showModal('ADD_ROOT_NODE', 'rootNodeFormControl')}>
                                    <FontAwesome
                                        name={"plus"}/>
                                    <ReactTooltip id='plus' type='info' delayShow={500}>
                                        <span>{Dict.t('ADD_ROOT_NODE')}</span>
                                    </ReactTooltip>
                                </Button> : ''}
                                {this.isModifierOrAdministrator ? <Button data-tip data-for='briefcase'
                                                                          onClick={() => this.setState({showTreeElementBuilder: true})}>
                                    <FontAwesome name={"briefcase"}/>
                                    <ReactTooltip id='briefcase' type='info' delayShow={500}>
                                        <span>{Dict.t('OPEN_TREE_ELEMENT_BUILDER')}</span>
                                    </ReactTooltip>
                                </Button> : ''}
                                {this.isModifierOrAdministrator || this.isInserter ?
                                    <Button data-tip data-for='exportCsv'
                                            className={stylesSCTree['tree-button-bar__button']}
                                            onClick={() => this._clickOpenExportCsv()}>
                                        <ReactTooltip id='exportCsv' type='info' delayShow={500}>
                                            <span>{Dict.t('EXPORT_CSV_EMPTY_MODEL')}</span>
                                        </ReactTooltip>
                                        <FontAwesome name={"table"}/>
                                    </Button> : ''}
                                {this.isModifierOrAdministrator ? <Button data-tip data-for='sign-out'
                                                                          onClick={() => this._showModal('ASK_CLOSE_PROJECT')}>
                                    <FontAwesome name={"sign-out"}/>
                                    <ReactTooltip id='sign-out' type='info' delayShow={500}>
                                        <span>{Dict.t('CLOSE')}</span>
                                    </ReactTooltip>
                                </Button> : ''}
                            </ButtonGroup>}
                        <InputGroup
                            className={BUtils.joinClasses(stylesSCTree['input-group-new-project'], stylesSCTree['input-group-new-project--' + (this.state.newProjectActive ? "" : "in") + 'active'])}>
                            <FormControl
                                type="text"
                                className={stylesSCTree['input-group-new-project__input']}
                                placeholder={(this.state.newProjectActive ? Dict.t("PROJECT_NAME") : "")}
                                ref={(input) => {
                                    this.formControl = input;
                                }}
                                value={this.state.newProjectName}
                                onChange={(evt) => this._handleProjectName(evt)}
                                onKeyDown={this._handleKeyDown}
                            />
                        </InputGroup>
                        {projectNameActive ?
                            <>
                                <small id={"sc-tree-msg-save"}/>
                            </>
                            : ""}
                    </ButtonToolbar> : ''}
                </Col>
                <Col lg={sizeColSearch} md={sizeColSearch}>
                    <AsyncTypeahead
                        id={'simple-search-typeahead'}
                        isLoading={true}
                        delay={300}
                        paginationText={Dict.t('DISPLAY_ADDITIONAL_RESULTS')}
                        ref={(ref) => this.simpleSearchRef = ref}
                        className={stylesSCTree["simple-search-typeahead"]}
                        maxResults={8}
                        minLength={typeof (this.state.simpleSearch.find((item) => {
                            return item.item !== 'DOCS_SEARCH' && item.checked
                        })) !== 'undefined' ? 2 : 1000}
                        clearButton
                        onChange={item => {
                            if (typeof item[0] !== 'undefined') {
                                NodeOperations.getItemById(this.state.projectName, item[0].code).then((retItem) => {
                                    this._openItem(retItem);
                                });
                            }
                        }}

                        onInputChange={textSearch => {
                            this.setState({textSearch});
                        }
                        }
                        onKeyDown={(evt) => {
                            if (evt.keyCode === 13 && evt.target.value.trim() !== '') {
                                const indexedSearchQuery = evt.target.value;
                                this._performKeyDownSimpleSearch(indexedSearchQuery);
                            }
                        }}
                        filterBy={() => true}
                        onSearch={(textSearch) => {
                            this._performSimpleSearch(textSearch);
                        }}
                        renderToken={(option, props, index) => {
                            var label = option.label.split('[')[0];
                            return <>
                                <div className={stylesSCTree['simple-search-th-token']}>{label}</div>
                            </>
                        }}
                        renderMenuItemChildren={(option, props) => {
                            var label = option.label.split('{')[0];
                            var text = props.text;
                            var split = text.trim().split(' ');
                            var value = option.value;


                            var regexPattern = '';
                            var regexReplacement = '$1';
                            var index = 2;
                            /*split.forEach((part, i) => {
                                regexPattern += '(.*' + (i === 0 ? '?' : '') + ')(' + part + ')';
                                regexReplacement += '<u style="background-color:yellow">$' + index + '</u>$' + (index + 1);
                                index = index + 2;
                            });*/

                            regexPattern = '(.*?)(' + text + ')';
                            regexReplacement += '<u style="background-color:yellow">$' + index + '</u>$' + (index + 1);
                            index = index + 2;


                            regexPattern = regexPattern + '(.*)';
                            regexPattern = new RegExp(regexPattern, 'gi');

                            value = value.replace(regexPattern, regexReplacement);
                            label = label.replace(regexPattern, regexReplacement);

                            return <>
                                <strong dangerouslySetInnerHTML={{__html: label}}/>
                                <div>
                                    <small dangerouslySetInnerHTML={{__html: value}}/>
                                </div>
                            </>
                        }}

                        options={this.state.simpleSearchListItems}
                        defaultSelected={[]}
                        placeholder={Dict.t('SEARCH')}
                        useCache={false}
                    />
                    <Button style={{paddingLeft: '12px'}}
                            className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-button'], stylesSCTree['tree-indexed-search-button__search'])}
                            onClick={() => {
                                var textSearch = this.state.textSearch;
                                this._performKeyDownSimpleSearch(textSearch);
                                //this._performKeyDownSimpleSearch(indexedSearchQuery);
                            }}><FontAwesome className={stylesSCTree['tree-automatic-save__span']}
                                            name={'search'}/></Button>
                    <Button style={{marginLeft: '0', paddingLeft: '10px', paddingRight: '10px'}} className={BUtils.joinClasses(stylesSCTree['tree-indexed-search-button'], stylesSCTree['tree-indexed-search-button__search'], stylesSCTree['tree-indexed-close-button'])} onClick={() => {
                        var selectedKeys = this.state.selectedKeys;
                        selectedKeys = [];
                        this.simpleSearchRef.getInstance().clear();
                        this.setState({selectedKeys});
                    }}>
                        <FontAwesome
                            name={'close'}/>
                    </Button>
                    <div
                        className={BUtils.joinClasses(stylesSCTree['simple-search-fields'], stylesSCTree['simple-search-fields--' + this.state.simpleSearch.length > 0])}>{SimpleSearchFields}</div>
                    <Button className={stylesSCTree['tree-indexed-search-button']}
                            onClick={() => {
                                this._unmountSearch();
                                this._renderSearch('tree-indexed-search', 'tree-indexed-search-container', '10%', false, true, true, false, undefined);
                            }}>{Dict.t('INDEX_ADVANCED_SEARCH')}</Button>
                </Col>
                {userService.isLogged() && false ? <Col lg={2} md={2}>
                    <div
                        className={stylesSCTree['tree-project-info--' + (projectNameActive ? "" : "in") + 'active']}>
                        {Dict.getGlobalProperty('TREE_TITLE')}
                    </div>
                </Col> : ''}
            </Row>
            <Row
                className={BUtils.joinClasses(stylesSCTree['tree-main-skin'], stylesSCTree['tree-structure'], stylesSCTree['tree-structure--' + (projectNameActive ? 'open' : 'close')])}>
                <Tree
                    expandedKeys={this.state.expandedKeys}
                    onExpand={this.onExpand}
                    loadData={this.onLoadData}
                    autoExpandParent={this.state.autoExpandParent}
                    onSelect={this.onSelect}
                    multiple={true}
                    composedTreeItems={this.state.composedTreeItems}
                    selectedKeys={this.state.selectedKeys}
                    onRightClick={(evt) => evt.node.selectHandle.children[1].classList.add('node-right-selected')}
                    openAnimation={animation}
                >
                    {loop(this.state.treeStructure)}
                </Tree>
            </Row>
            <Modal className={BUtils.joinClasses(stylesSCTree["tree-modal"], modalOperationClass)}
                   dialogClassName={stylesSCTree['tree-modal__dialog']}
                   key={"key-tree-props-"} show={this.state.modalProps.showModal}
                   onHide={this._closeModal} restoreFocus>
                <Modal.Body className={stylesSCTree["tree-modal__body"]}>
                    {this._getModalContentByOperation(this.state.modalProps.modalOperation, this.state.modalProps.additionalData)}
                </Modal.Body>
            </Modal>
            <PopupNodeOperationPage/>
            <PopupNodePage/>
            <div id='tree-indexed-search-container'/>
            <div id={'tree-indexed-doc-search-container'}/>
            <div id='node-import-container' className={stylesSCTree['node-import-container']}/>
            <div id='node-treelink-page'
                 className={BUtils.joinClasses(stylesSCTree['node-treelink-page'], stylesSCTree['node-treelink-page--inactive'])}/>
            <TreeElementBuilder show={this.state.showTreeElementBuilder}
                                interfaceLang={this.state.interfaceLang}
                                i18nlangs={this.state.i18nlangs}
                                composedTreeItems={this.state.composedTreeItems}
                                composedCollections={this.state.composedCollections}
                // callback non necessario? passa per reference l'oggetto
                                callbackParent={(composed, operation) => {
                                    if (operation === 'design-composed-item' || operation === 'edit-composed-item') {
                                        this._addComposedItem(composed, operation === 'edit-composed-item');
                                    } else if (operation === 'saving-composed-collections') {
                                        this._addComposedCollections(composed);
                                    } else
                                        this.setState({showTreeElementBuilder: false})
                                }}/>
        </div>;
    }
}

SCTree.contextType = TreeContext;