import React from 'react';
import {SCRadiobutton, SCCheckbox, SCSubmitButton} from './../../../../dict-components/components';
import {changeEntryTypology} from './EntryActions';
import {getLexemeOptions, getRootEntryId} from './../../LexemePage';
import Dict from 'dict-lib';
import {Button, Grid, Row, Col} from 'react-bootstrap';
import '../../../../_css/dictionary/lexemeParts/lexemeTitle.module.css';
import './../../../page/editor/root-entry/RootEntry.css';
import './../../../../_css/lexeme.css';

export class EntryChangeTypology extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            idEntry: "",
            isRoot: typeof this.props.item.title !== 'undefined',
            typologies: ['ATTESTED', 'LEXOTYPE', 'NOT_ATTESTED'],
            items: [],
            chosen: "",
            mantainMorpho: false
        };

        this._handleRadioChangeTypology = this._handleRadioChangeTypology.bind(this);
        this._handleCheckboxChangeTypology = this._handleCheckboxChangeTypology.bind(this);
        this._handleChangeTypology = this._handleChangeTypology.bind(this);
    }

    componentWillMount() {
        var typologies = this.state.typologies;
        var myTypology;
        var idEntry;
        var chosen;               

        if (this.state.isRoot) {
            myTypology = this.props.item.lexeme_data.root_typology;
            idEntry = getRootEntryId();
        } else {
            myTypology = this.props.item.typology;
            typologies.splice(typologies.indexOf('NOT_ATTESTED'), 1);
            idEntry = this.props.item.id;
        }

        typologies.splice(typologies.indexOf(myTypology.toUpperCase()), 1);
        if (!getLexemeOptions("allow_lexo_root"))
            typologies = typologies.splice(typologies.indexOf('LEXOTYPE'), 1);

        var items = [];
        typologies.map((item) => {
            items.push({code: item, label: Dict.t(item)});
        });

        chosen = items[0].code;

        this.setState({items, idEntry, chosen});
    }

    _handleCheckboxChangeTypology(id, mantainMorpho) {
        this.setState({mantainMorpho});
    }

    _handleRadioChangeTypology(chosen) {
        this.setState({chosen});
    }

    _handleChangeTypology() {
        changeEntryTypology(this.state.idEntry, this.state.chosen, this.state.mantainMorpho);
    }

    render() {

        var rowChangeTypologyChoose;

        if ((this.state.items).length > 1) {
            var rowChangeTypologyChoose = <SCRadiobutton key={"key-radio-entry-change-typology"} bsStyle="primary"
                                                         items={this.state.items}
                                                         default={this.state.chosen}
                                                         callbackParent={(newState) => this._handleRadioChangeTypology(newState)}/>;

            if (this.state.chosen == 'NOT_ATTESTED') {
                var itemsRowChk = <SCCheckbox key={"key-changeTypology-mantain-morpho"}
                                              label={Dict.t("MANTAIN_MORPHOLOGIES")} id="changeTypology-mantain-morpho"
                                              callbackParent={(id, newState) => this._handleCheckboxChangeTypology(id, newState)}/>;

                rowChangeTypologyChoose = <Row><Col lg={6} md={6}>{rowChangeTypologyChoose}</Col><Col lg={6} md={6} className={"changeTypology-chk"}>{itemsRowChk}</Col></Row>;
            }
        } else {
            rowChangeTypologyChoose = <Row className={"row-change-unique"}>
                {Dict.t('ASK_CHANGE_TYPOLOGY')}
                <span className={"row-change-unique-value"}>{this.state.items[0].label}</span>
                ?
            </Row>
        }


        return <Grid className={"grid-change-typology"} fluid>
            <Row className={'row-change-typology row-change-typology-radio'}>
                {rowChangeTypologyChoose}
            </Row>
            <Row className={'row-change-typology row-change-typology-bt'}>
                <SCSubmitButton onClick={this._handleChangeTypology} text={Dict.t((this.state.items).length > 1 ? 'CHANGE' : 'YES')}/>
            </Row>
        </Grid>;
    }

}
