import React from 'react';

import stylesLexemePage from './../../../../_css/dictionary/lexemePage.module.css';

export class LexemeDictionaryType extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const isTreeModel = this.props.page.lexeme_properties.is_tree_model;

        return <div className={stylesLexemePage['lexemeDictionaryType']}>{isTreeModel ? this.props.page.dictionary_properties.name :  (this.props.page.dictionary_properties.language_code).toLowerCase() }</div>;
    }
}
