import React from 'react';
import {transformPhraseInPoly} from './PhraseAction';
import Dict from 'dict-lib';
import {Button, Row, Col} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {getLexemeProperties} from './../../LexemePage';
import {EntryTypeahead, SCSubmitButton} from './../../../../dict-components/components';

export class PhraseTransformInPoly extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            entryToAssign: {}
        };

        this._handleTransform = this._handleTransform.bind(this);
    }

    _handleTransform() {
        var item = this.props.item;
        var entryId = this.state.entryToAssign.id;

        this.setState({btDisabled: true});
        //TODO: come mai è venuto a mancare?
        transformPhraseInPoly(item.ids, this.props.additionalData.phraseItem.id, entryId).then(result => {
            this.setState({btSaveState: "success", btDisabled: false}, function () {
                setTimeout(function () {
                    this.setState({btSaveState: "primary"});
                }.bind(this), 1000);
            });
        });
    }

    render() {

        const foundEntry = Object.keys(this.state.entryToAssign).length > 0;

        return <div className="">
            <Row>
                <Col lg={5} md={5}><EntryTypeahead languageId={getLexemeProperties("language_id")}
                                                   callbackParent={(newState) => this.setState({entryToAssign: newState})}/>
                </Col>
                {foundEntry ?
                    <Col lg={5} md={5} className="popup-label">{Dict.t('ASK_TRANSFORM_PHRASE_POLY')}</Col>
                    : ""}
                {foundEntry ?
                    <Col lg={2} md={2}>
                        <SCSubmitButton onClick={() => this._handleTransform()} text={Dict.t('YES')}/>
                    </Col>
                    : ""}
            </Row>
        </div>;
    }

}
