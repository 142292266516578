import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {Row} from 'react-bootstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {SearchPage} from './search/SearchPage';
import {CorporaTreeCatalog} from './corpora/CorporaTreeCatalog';
import {Router, Route, HashRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import './_css/App.css';
import './_css/search.css';
import stylesApp from './_css/app.module.css'
import {SCNavbar} from './dict-components/navbar/SCNavbar';
import {SCFooter} from './dict-components/navbar/SCFooter';
import {ToastContainer} from 'react-toastify';
import {LoginPage} from "./LoginPage";
import {history} from './_redux/authentication/history';
import {userService} from "./_redux/authentication/services/user.services";
import {AdministrationUtilities, PropertyTypeItems} from "./administration/AdministrationHelper";
import {NotFoundPage} from "./administration/NotFoundPage";
import {RequirementsPage} from "./administration/RequirementsPage";
import BUtils from './utilities/BaseUtilities';


class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: false
        };

        this._buildModuleOutline = this._buildModuleOutline.bind(this);
    }

    componentDidMount() {
        this.setState({
            mounted: true
        }, () => {
            this._buildModuleOutline();
        });
    }

    _buildModuleOutline() {
        const prom = new Promise((resolve, reject) => {
            var moduleByPath = AdministrationUtilities.getModuleByPathName(window.location.pathname);
            if (typeof moduleByPath === 'undefined') {
                history.listen((location, action) => {
                    moduleByPath = AdministrationUtilities.getModuleByPathName(location.pathname);
                    if (typeof moduleByPath !== 'undefined')
                        resolve(moduleByPath);
                    //console.log(action, location.pathname, location.state);
                });
            } else {
                resolve(moduleByPath);
            }
        });

        prom.then((module) => {
            var activeCode = module.code;
            this.setState({module, isActive: AdministrationUtilities.isModuleActive(activeCode)});
        });
    }


    render() {
        var child;
        const isLogged = userService.isLogged();
        const moduleCode =typeof this.state.module !== 'undefined' ? this.state.module.code : null;

        var requirementNotFound = PropertyTypeItems.RequirementsRoutes.filter((requirement) => {
            return requirement.module === 'ALL' && !requirement.check();
        });
        const globalRequirementFound = requirementNotFound.length === 0;
        const isNetworkAnomalies = Dict.networkAnomaliesFound();

        var ModuleRoutes = PropertyTypeItems.RootModules.map((module) => {
            var moduleRequirementNotFound = AdministrationUtilities.getModuleRequirements(module).filter((requirement) => {
                var req = Dict.getGlobalProperty(requirement);
                return Dict.getGlobalProperty(requirement) === null || Dict.getGlobalProperty(requirement) === '';
            }).map((notFoundRequirement) => {
                return {code: notFoundRequirement, type: 'properties', tab: module.code};
            });

            if (!isNetworkAnomalies) {
                var isActive = AdministrationUtilities.isModuleActive(module.code);
                if (!isActive)
                    moduleRequirementNotFound.push({code: 'MODULE_NOT_ACTIVE', type: 'properties', tab: 'modules'});

                var moduleRequiredFound = moduleRequirementNotFound.length === 0;
                var component = AdministrationUtilities.getModuleComponent(module);

                var retRoute = null;

                if ((!moduleRequiredFound || !globalRequirementFound)) {
                    retRoute = isLogged ? <Route exact path={module.path}>
                        <RequirementsPage globalRequirements={requirementNotFound}
                                          moduleRequirements={moduleRequirementNotFound}/>
                    </Route> : null;
                } else {
                    retRoute = <Route exact path={module.path} component={component}/>;
                }
            } else
                retRoute = <Route exact path={module.path}>
                    <Redirect to={AdministrationUtilities.objectRequiredRoutePath}/>
                </Route>

            return retRoute;
        }).filter(moduleRoute => {
            return moduleRoute !== null;
        });


        const firstStart = ModuleRoutes.length === 0 && !isLogged;
        const mainModulePath = firstStart ? '/login' : AdministrationUtilities.getMainModulePath();

        const RootPath = <Route exact path="/">
            <Redirect to={isLogged ? '/backoffice' : mainModulePath}/>
        </Route>;

        if (this.state.mounted)
            child = <div id="DictionaryApp" className={BUtils.joinClasses("App", stylesApp['DictionaryApp-' + moduleCode])}>
                {typeof this.state.module === 'undefined' ? '' :
                    <SCNavbar module={this.state.module} isActive={this.state.isActive}/>}
                <Row id="dict-modal-container"/>
                <div id="App-body" className={BUtils.joinClasses("container-fluid", stylesApp['App-body-' + moduleCode])}>
                    <Router history={history}>
                        <Switch>
                            {ModuleRoutes}
                            {RootPath}
                            <Route exact path="/login" component={LoginPage}/>
                            <Route exact path="/backoffice">
                                <Redirect
                                    to={isLogged ? (globalRequirementFound ? mainModulePath : AdministrationUtilities.objectRequiredRoutePath) : '/login'}/>
                            </Route>
                            <Route exact path={AdministrationUtilities.objectRequiredRoutePath}>
                                {globalRequirementFound && !isNetworkAnomalies ?
                                    RootPath :
                                    <RequirementsPage globalRequirements={requirementNotFound}/>}
                            </Route>
                            <Route component={NotFoundPage}/>
                        </Switch>
                    </Router>
                </div>
                <SCFooter/>
                <div id="app-top-right-toast-container"><ToastContainer/></div>
            </div>;

        return (
            <ReactCSSTransitionGroup transitionEnterTimeout={500} transitionLeaveTimeout={10} transitionName="app">
                {child}
            </ReactCSSTransitionGroup>
        )
    }
}

export default App;
