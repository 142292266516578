import React from 'react';
import Dict from 'dict-lib';
import {getLexemeOptions, setSenses, setPolies} from './../../LexemePage';
import {LexemeUtilities} from './../LexemeHelper';

const updateSenses = ['SET_SENSE'];
var updateClasses = ['subentries'];

export const setDefabs = (id, abstr, descrLanguage) => {
    /*item = LexemeUtilities.encodeUrl(item);*/
    abstr = LexemeUtilities.encodeUrl(abstr);
    if (abstr.length === 0)
        abstr = getLexemeOptions("SENSE_DEFAULT_ABS_SYMBOL");

    return LexemeUtilities.call('/senses/setDefabs/' + id + '/' + abstr + '/' + descrLanguage.replace('-', '^') + '/' + updateSenses);
};

export const changeStatus = (item, status) => {
    return LexemeUtilities.callResetLexemeStatus('/senses/changeStatus/' + item.ids + '/' + status);
};

export const deleteSense = (item) => {
    return LexemeUtilities.callResetTabPages('/senses/deleteSense/' + item.ids + '/' + updateSenses);
};

export const removeSenseVarLoc = (item, ids) => {
    ids = ids.toString().replace(/,/g, '_');
    return LexemeUtilities.callResetTabPages('/senses/removeSenseVarLoc/' + item.ids + '/' + ids + '/' + updateSenses);
};

export const deleteLink = (ids) => {
    return LexemeUtilities.callResetTabPages('/senses/deleteLink/' + ids + '/' + updateSenses);
};

export const hideShowLink = (item, ids) => {
    item = LexemeUtilities.encodeUrl(item);
    return LexemeUtilities.call('/senses/hideShowLink/' + item + '/' + ids + '/' + updateSenses);
}

export const saveNotes = (localSenses) => {
    localSenses = LexemeUtilities.encodeUrl(localSenses);
    return LexemeUtilities.call('/senses/saveNotes/' + localSenses + '/' + updateSenses);
};

export const saveUsages = (item) => {
    var cuId = item.chronological_usage.id;
    var duId = item.domain_usage.id;
    var fuId = item.frequency_usage.id;
    var ruId = item.register_usage.id;

    return LexemeUtilities.call('/senses/saveUsages/' + item.ids + '/' + cuId + '/' + duId + '/' + fuId + '/' + ruId + '/' + updateSenses);
};

export const saveLink = (item, idToLink, type) => {
    return LexemeUtilities.callResetTabPages('/senses/saveLink/' + item.ids + '/' + idToLink + '/' + type + '/' + updateSenses);
};

export const saveDating = (item) => {
    return LexemeUtilities.call('/senses/saveDating/' + item.ids + '/' + item.dating + '/' + updateSenses);
};

export const promoteSenses = (item, langList) => {
    return LexemeUtilities.call('/senses/promoteSenses/' + item.ids + '/' + langList[0].id_lang + '/' + updateSenses);
};

export const spreadSenses = (item, ids) => {
    return LexemeUtilities.call('/senses/spreadSenses/' + item.ids + '/' + ids + '/' + updateSenses);
};

export const addNewLocSense = (item, rootId, langList) => {
    var ids = "";
    var form = "";

    langList.map((item) => {
        form = item.form;
        ids += item.id_lang + "_";
    });

    ids = ids.substring(0, ids.length - 1);

    return LexemeUtilities.callMultipleHighlight('/senses/addNewLocSense/' + item.ids + '/' + rootId + '/' + ids + '/' + updateSenses, 'senseItemLang_');
};

export const savePhrase = (item, langId, phrase, isAssign, isTranslation) => {
    phrase = LexemeUtilities.encodeUrl(phrase);

    const path = '/senses/addEditPhrase/' + item.ids + '/' + phrase + '/' + langId + '/' + isAssign + "/" + updateSenses;

    return isTranslation ? LexemeUtilities.callSimple(path, 'SPINNER_MSG_TRANSLATING') : (isAssign ? LexemeUtilities.callResetTabPages(path) : LexemeUtilities.call(path));
};

export const saveTopic = (item, topic) => {
    if (topic.length === 0)
        topic = null;
    item = LexemeUtilities.encodeUrl(item);
    return LexemeUtilities.call('/senses/setTopic/' + item.ids + '/' + topic + '/' + updateSenses);
};

export const moveSense = (item, idToLink, mantain) => {
    return LexemeUtilities.callResetTabPages('/senses/moveSense/' + item.ids + '/' + idToLink + '/' + mantain + '/' + updateSenses);
};

export const showHideSense = (item) => {
    return LexemeUtilities.call('/senses/showHide/' + item.ids + '/' + updateSenses);
};

export const addTranslation = (item, translatingId, rootEntryId) => {
    item = LexemeUtilities.encodeUrl(item);

    return LexemeUtilities.call('/senses/addTranslation/' + item + '/' + translatingId + '/' + rootEntryId + '/' + updateSenses);
};

export const setSensesOrder = (ids, subentryIds, isPoly, polies) => {
    return LexemeUtilities.callNoClose('/senses/setSensesOrder/' + ids + '/' + subentryIds);
};

export const saveTitleAndCaption = (item, title, caption, languageDescr) => {
    item = LexemeUtilities.encodeUrl(item);

    if(title.length===0)
        title = "$_$";
    if(caption.length===0)
        caption = "$_$";

    return LexemeUtilities.callNoClose('/senses/saveTitleAndCaption/' + item + '/' + title + '/' + caption + '/' + languageDescr + '/');
};