exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\r\n.languageData-module__edit-language-element___PXbLr {\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.languageData-module__form-group___2H6ZV {\r\n\r\n}\r\n\r\n.languageData-module__textarea-group___13kWF textarea {\r\n    resize: vertical;\r\n    height: 500px;\r\n    font-size: 13px;\r\n}\r\n\r\n.languageData-module__checkbox___3BDSJ {\r\n    margin-top: 10px;\r\n}\r\n", ""]);

// Exports
exports.locals = {
	"edit-language-element": "languageData-module__edit-language-element___PXbLr",
	"form-group": "languageData-module__form-group___2H6ZV",
	"textarea-group": "languageData-module__textarea-group___13kWF",
	"checkbox": "languageData-module__checkbox___3BDSJ"
};