import React from 'react';
import Dict from 'dict-lib';
import ReactDom from 'react-dom';
import {getLexemeProperties} from '../../LexemePage';
import {savePhraseNotes} from './PhraseAction';
import {Grid, Row, InputGroup, FormControl, Button, Label} from 'react-bootstrap';
import './../../../../_css/lexeme.css';

import stylesSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import {LexemeUtilities} from "../LexemeHelper";
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";

export class PhraseNotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            id: this.props.additionalData.phraseItem.id,
            text: this.props.additionalData.phraseItem.notes
        };

        this._handlePhraseNote = this._handlePhraseNote.bind(this);
        this._savePhraseNotes = this._savePhraseNotes.bind(this);
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.translationNote).focus();
    }

    _handlePhraseNote(evt) {
        var text = evt.target.value;
        this.setState({text});
    }

    _savePhraseNotes() {
        this.setState({btDisabled: true});
        savePhraseNotes(this.state.item.ids, this.state.text, this.state.id).then(result => {
        });
    }

    render() {

        return <Grid className={stylesSenses['sense-notes-container']} fluid>
            <InputGroup className={stylesSenses['sense-notes']}>
                <FormControl type="text" placeholder={Dict.t('NOTES') + "..."}
                             ref={(input) => {
                                 this.translationNote = input;
                             }}
                             value={this.state.text}
                             onChange={(evt) => this._handlePhraseNote(evt)}
                             onKeyUp={(evt) => {
                                 LexemeUtilities.handleKeyUp(evt, () => {
                                     this._savePhraseNotes();
                                 })
                             }}
                />
            </InputGroup>
            <Row className={stylesSenses['sense-notes__row-bt']}>
                <SCSubmitButton onClick={() => this._savePhraseNotes()} text={Dict.t('SAVE')}/>
            </Row>
        </Grid>;
    }


}
