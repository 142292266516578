import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {TreeUtilities, NodeOperations, TreeDOMHelper, TreeNodeHelper} from './TreeHelper';
import {
    Grid,
    Row,
    Col,
    ButtonToolbar,
    ButtonGroup,
    Button,
    FormControl,
    InputGroup,
    Modal,
    Glyphicon,
    Label,
    Carousel,
    CarouselItem,
    Overlay,
    Tooltip
} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import ReactTooltip from 'react-tooltip';
import FontAwesome from 'react-fontawesome';
import {SCCheckbox} from '../../components';
import {Notifications} from './../../../utilities/DictUtilities';

import './../SCTree.css';
import BUtils from './../../../utilities/BaseUtilities';
import stylesSCTree from './../SCTree.module.css';

export class TreeElementCollectionBuilder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            composedCollections: typeof this.props.composedCollections === 'undefined' ? [] : this.props.composedCollections,
            dbCollections: [],
            selectedCollection: {},
            selectedDbCollection: {component: <div></div>, label: ''},
            status: 'close',
            insertCollection: false,
            overlay: {show: false},
            bsStyle: 'default',
            activateDbCollection: false,
            newCollectionData: [],
            useMultiInsert: false,
            useMultiInsertChecked: false
        }
    }

    componentWillMount() {
        var dbCollections = TreeNodeHelper.treeDbCollections();
        this.setState({dbCollections});

        this._resetSelectedCollection();
        this._resetOverlay();
    }

    _buildOverlay() {
        /*var cols = 12 / parseInt(this.state.overlay.buttons.length);*/
        var OverlayButtons = this.state.overlay.buttons.map((button, index) => {
            return <Button key={'overlay-bt-' + index}
                onClick={button.callback}>{button.text}</Button>;
        });

        return <Overlay
            className="tree-element-collection-builder-overlay"
            show={this.state.overlay.show}
            onHide={() => {
                var overlay = this.state.overlay;
                overlay.show = false;
                this.setState(overlay)
            }}
            placement={typeof this.state.overlay.placement === 'undefined' ? "right" : this.state.overlay.placement}
            container={this}
            target={() => ReactDOM.findDOMNode(this[this.state.overlay.target])}
        ><Tooltip id="builder-tooltip" className={stylesSCTree['tree-element-builder__tooltip']}>
            <Row><Col lg={8} md={8} className={stylesSCTree['tree-element-builder__tooltip-text']}>{this.state.overlay.message}</Col>
                <Col lg={4} md={4}>{OverlayButtons}</Col></Row>
        </Tooltip>
        </Overlay>;
    }

    _resetOverlay() {
        var overlay = {show: false, message: '', target: ''};
        this.setState({overlay});
    }

    _setToSave() {
        this.setState({bsStyle: "danger"});
    }

    _setSaved() {
        this.setState({bsStyle: "default"});
    }

    _resetSelectedCollection() {
        var status = 'close';
        var selectedCollection = {
            id: '',
            label: '',
            items: [],
            itemIdSelected: -1,
            tmpData: {startInsert: false, addAutocompleteText: ''}
        };

        this.setState({selectedCollection, status});
    }

    _resetNewDataCollection() {
        this.setState({newCollectionData: [], useMultiInsertChecked : false});
    }

    _saveCollection(selectedCollection) {
        var composedCollections = this.state.composedCollections;
        var status = 'edit';
        var isAdd = this.state.status === 'add';

        if (selectedCollection.label.trim().length === 0) {
            Notifications.showErrorModalMessage("COLLECTION_NAME_MISSING", 2000);
        } else if (selectedCollection.items.length === 0) {
            Notifications.showErrorModalMessage("COLLECTION_EMPTY", 2000);
        } else if (isAdd && composedCollections.filter((item) => {
                return item.label === selectedCollection.label;
            }).length > 0) {
            Notifications.showErrorModalMessage("celo!", 2000);
        } else {
            var bsStyle = 'default';

            if (isAdd) {
                var id = composedCollections.length === 0 ? 1 : parseFloat(composedCollections[composedCollections.length - 1].id) + parseFloat(1);
                selectedCollection.id = id;
                var newObj = {id, label: selectedCollection.label, items: selectedCollection.items};
                composedCollections.push(newObj);

                var insertCollection = true;
                this.setState({insertCollection});

                setTimeout(function () {
                    insertCollection = 'hidden';
                    this.setState({insertCollection});
                    setTimeout(function () {
                        insertCollection = false;
                        this.setState({insertCollection});
                    }.bind(this), 300);
                    this.setState({insertCollection});
                }.bind(this), 300);
            } else {
                bsStyle = 'success';
                composedCollections.some(function (item) {
                    if (item.id == selectedCollection.id) {
                        item.label = selectedCollection.label;
                        item.items = selectedCollection.items;
                        return true;
                    }
                });
            }

            this.setState({composedCollections, selectedCollection, status, bsStyle},
                function () {
                    setTimeout(function () {
                        this._setSaved();
                    }.bind(this), 1000)
                }
            );
            //this.props.callbackParent(composedCollections);
        }
    }

    _removeCollection(selectedCollection) {
        var composedCollections = this.state.composedCollections;

        composedCollections.some(function (item) {
            if (item.id == selectedCollection.id) {
                composedCollections.splice(composedCollections.indexOf(item), 1);
                return true;
            }
        });

        this['typahead-autocompletes'].getInstance().clear();
        this._resetOverlay();
        this._resetSelectedCollection();
    }

    _updateComposedCollections(selectedCollection) {
        var composedCollections = this.state.composedCollections;
        composedCollections.some(function (item) {
            if (item.id === selectedCollection.id) {
                item.items = JSON.parse(JSON.stringify(selectedCollection.items));
                item.itemIdSelected = -1;
                return true;
            }
        });

        this.setState({composedCollections});
    }

    _addAutocompleteItem(selectedCollection) {
        var items = selectedCollection.items;
        var newCollectionData = this.state.newCollectionData;
        var text = selectedCollection.tmpData.addAutocompleteText;

        var multiItemsText = this.state.useMultiInsert ? text.split(';') : [text + ''];

        var itemsFound = '';
        if (selectedCollection.tmpData.addAutocompleteText.trim().length > 0) {
            multiItemsText.forEach((multiItemsText) => {
                if (items.some(item => {
                        return item.label === multiItemsText;
                    }))
                    itemsFound += multiItemsText + ", ";
            });

            if (itemsFound.length > 0) {
                var msg = this.state.useMultiInsert ? "ignore:" + Dict.t("ITEMS_ALREADY_EXISTS_IN_COLLECTION").replace('__1', itemsFound.substring(0, itemsFound.length - 2)) : "ITEM_ALREADY_EXISTS_IN_COLLECTION";
                Notifications.showErrorModalMessage(msg, 3000);
            } else {
                this['typahead-autocomplete-items'].getInstance().clear();
                selectedCollection.tmpData.startInsert = true;
                var lastId = parseInt(items.length);

                var multiItems = multiItemsText.map((multiItemText) => {
                    lastId = parseInt(lastId) + 1;
                    return {id: lastId, label: multiItemText};
                });

                selectedCollection.items = items.concat(multiItems);
                this.state.newCollectionData = newCollectionData.concat(JSON.parse(JSON.stringify(multiItems)));

                this.setState({selectedCollection});
                setTimeout(function () {
                    selectedCollection.tmpData.startInsert = 'hidden';
                    selectedCollection.tmpData.addAutocompleteText = '';
                    setTimeout(function () {
                        selectedCollection.tmpData.startInsert = false;
                        selectedCollection.tmpData.addAutocompleteText = '';
                        this.setState({selectedCollection});
                    }.bind(this), 300);

                    this._updateComposedCollections(selectedCollection, newCollectionData);

                }.bind(this), 300);
            }
        }
    }

    render() {

        var selectedCollection = JSON.parse(JSON.stringify(this.state.selectedCollection));
        var composedCollections = this.state.composedCollections;
        var BuilderOverlay = this.state.overlay.show ? this._buildOverlay() : '';

        var selected = typeof selectedCollection.itemIdSelected !== 'undefined' && selectedCollection.itemIdSelected >= 0 ? [selectedCollection.items[selectedCollection.itemIdSelected]] : [];

        if (typeof selectedCollection.tmpData === 'undefined') {
            selectedCollection.tmpData = {startInsert: false, addAutocompleteText: ''};
        }

        var NewCollectionData = '';
        if (this.state.newCollectionData.length > 0) {
            const NewCollectionDataList = this.state.newCollectionData.map((item, index) => {
                return <Row key={'new-collectiondata-' + index}
                            className={'new-collectiondata-item'}>{item.label}</Row>;
            });

            NewCollectionData = <Row id="new-collectiondata">
                <Row
                    id="new-collectiondata-label">{Dict.t("NEW_DATA_ENTRIES") + ":"}</Row>
                {NewCollectionDataList}
            </Row>
        }

        return <Row id="tree-element-collection-builder" className={stylesSCTree['tree-element-collection-builder']}>
            <Col id="teb-column-collection-col" className={stylesSCTree['tree-element-collection-builder__teb-column-collection-col']} lg={7} md={7}>
                <Row id="tree-element-collection-row-composed-collections" className={stylesSCTree['tree-element-collection-builder___row-composed-collections']}>
                    <Typeahead
                        ref={(typeahead) => this['typahead-autocompletes'] = typeahead}
                        clearButton
                        className={BUtils.joinClasses(['teb-collection'], stylesSCTree['tree-element-collection-builder__autocomplete'], stylesSCTree['tree-element-collection-builder__autocomplete--' + selectedCollection.tmpData.startInsert])}
                        onChange={item => {
                            if (item.length > 0) {
                                var selectedCollection = item[0];
                                selectedCollection.itemIdSelected = -1;
                                var status = 'edit';
                                this['typahead-autocomplete-items'].getInstance().clear();
                                this.setState({selectedCollection, status});
                                this._resetNewDataCollection();
                            }
                        }}
                        onSearch={() => {
                            return;
                        }}
                        options={composedCollections}
                        selected={[]/*[selectedCollection.items[selectedCollection.itemIdSelected]]*/}
                        placeholder={'collezione'}
                    />
                    <Button className={"teb-button-add-collection"}
                            onClick={(evt) => {
                                this._resetSelectedCollection();
                                this['typahead-autocompletes'].getInstance().clear();
                                var status = 'add';
                                if (this.state.status != 'close')
                                    status = 'close';
                                this.setState({status}, function () {
                                    if (status != 'close') {
                                        setTimeout(function () {
                                            ReactDOM.findDOMNode(this['formControl-autocomplete-builder']).focus();
                                        }.bind(this), 100)
                                    }
                                });
                            }}>
                        <FontAwesome name={this.state.status != 'close' ? 'close' : 'plus'}/>
                    </Button>
                    <Button id="bt-remove-collection" className={stylesSCTree['tree-element-builder__button-add-collection']}
                            ref={button => {
                                this.targetRemoveCollection = button;
                            }}
                            onClick={(evt) => {
                                if (selectedCollection.id > 0) {
                                    var overlay = this.state.overlay;
                                    overlay.show = !overlay.show;
                                    overlay.target = 'targetRemoveCollection';
                                    overlay.message = 'vuoi rimuovere questo elemento?';
                                    overlay.placement = 'right';
                                    overlay.buttons = [{
                                        text: Dict.t('YES'), callback: () => {
                                            this._removeCollection(selectedCollection);
                                        }
                                    }, {
                                        text: Dict.t('NO'), callback: () => {
                                            this._resetOverlay();
                                        }
                                    }];
                                    this.setState({overlay});
                                }
                            }}>
                        <FontAwesome name={"trash"}/>
                    </Button>
                </Row>
                <Row id="tree-element-collection-row-add"
                     className={BUtils.joinClasses(stylesSCTree['tree-element-collection-builder___collection-row-add'], stylesSCTree['tree-element-collection-builder___collection-row-add--' + this.state.status])}>
                    <FormControl
                        id={"form-control-collection-builder"}
                        type="text"
                        className={BUtils.joinClasses(stylesSCTree['tree-element-collection-builder___input-data-autocomplete-custom-label'], stylesSCTree['tree-element-collection-builder___input-data-autocomplete-custom-label--' + this.state.insertCollection])}
                        placeholder={'etichetta viewer (' + (selectedCollection.id === '' ? Dict.t('NEW_COLLECTION') : composedCollections.filter((item) => {
                            return selectedCollection.id === item.id;
                        })[0].label) + ')'}
                        ref={(input) => {
                            this['formControl-autocomplete-builder'] = input;
                        }}
                        value={selectedCollection.label}
                        onChange={(evt) => {
                            composedCollections.some(function (item) {
                                if (item.id === selectedCollection.id) {
                                    if (item.label === evt.target.value)
                                        this._setSaved();
                                    else
                                        this._setToSave();
                                }
                            }.bind(this));

                            selectedCollection.label = evt.target.value;
                            this.setState({selectedCollection});
                        }}
                        onKeyDown={(evt) => {
                            if (evt.keyCode === '13') {
                                if (selectedCollection.items.length > 0)
                                    this._saveCollection(selectedCollection);
                                document.getElementById("input-data-collection-builder").focus();
                            }
                        }}
                    />
                    <Button className={"teb-button-add-collection"} bsStyle={this.state.bsStyle}
                            onClick={(evt) => this._saveCollection(selectedCollection)}>
                        <FontAwesome name={this.state.status === 'add' ? "plus" : "save"}/>
                    </Button>
                    <input id={"input-data-collection-builder"} type="text"
                           className={BUtils.joinClasses(stylesSCTree['tree-element-collection-builder___input-data-collection' + (this.state.useMultiInsert ? '--placeholder-extended' : '')], stylesSCTree['tree-element-builder__input-data-start-insert--' + selectedCollection.tmpData.startInsert])}
                           value={selectedCollection.tmpData.addAutocompleteText}
                           placeholder={Dict.t('ADD_TO_COLLECTION') + (this.state.useMultiInsert ? ' (' + Dict.t('MULTI_INSERT') + ')' : '')}
                           onKeyUp={(evt) => {
                               if(evt.target.value.endsWith(";") && !this.state.useMultiInsert && !this.state.useMultiInsertChecked) {
                                   Notifications.showToastNotification(Dict.t("ASK_FORGOT_MULTI_INSERT_ENABLING"), 8000, 2000);
                                   this.setState({ useMultiInsertChecked : true});
                               }

                               if (evt.keyCode === '13')
                                   this._addAutocompleteItem(selectedCollection);
                           }}
                           onChange={(evt) => {
                               selectedCollection.tmpData.addAutocompleteText = evt.target.value;
                               this.setState({selectedCollection});
                           }}/>
                    <Button className={"teb-button-add-collection"}
                            onClick={(evt) => this._addAutocompleteItem(selectedCollection)}>>></Button>
                    <div id="div-contrainer-sc-chk-multi-insert" className={stylesSCTree['tree-element-builder__div-contrainer-sc-chk-multi-insert']}>
                        <SCCheckbox label={""}
                                    id={"collection-chk-to-use-multi-insert"}
                                    additionalClass={stylesSCTree['tree-element-builder__collection-chk-to-use-multi-insert']}
                                    checked={this.state.useMultiInsert}
                                    callbackParent={() => {
                                        this.setState({useMultiInsert: !this.state.useMultiInsert});
                                    }
                                    }>
                        </SCCheckbox>
                        <FontAwesome name={"info-circle"} data-tip data-for='sc-info-multi-insert' />
                        <ReactTooltip id='sc-info-multi-insert' type='info' className={stylesSCTree['tree-element-builder__tool-tip']}>
                            <span>{Dict.t('ENABLE_DISABLE_MULTI_INSERT') + " " + Dict.t('USE_SEMICOLON_AS_SEPARATOR')}</span>
                        </ReactTooltip>
                    </div>
                </Row>
                { this.state.activateDbCollection ? <Row id="tree-element-collection-row-db-collections" className={stylesSCTree['tree-element-builder__row-db-collections']}>
                    <Row className="title-db-collection">{Dict.t('DB_COLLECTIONS')}</Row>
                    <Typeahead
                        ref={(typeahead) => this['typahead-db-autocompletes'] = typeahead}
                        clearButton
                        className={"teb-collection teb-autocomplete-db-collection-builder"}
                        onChange={item => {
                            if (item.length > 0) {
                                var selectedDbCollection = item[0];

                                this.setState({selectedDbCollection});
                            }
                        }}
                        onSearch={() => {
                            return;
                        }}
                        options={this.state.dbCollections}
                        placeholder={Dict.t('COLLECTION')}
                    />
                </Row> : ""}
            </Col>
            <Col
                id="teb-column-element-collection-builder"
                className={BUtils.joinClasses(stylesSCTree['tree-element-builder__column-element-collection-builder'], stylesSCTree['tree-element-builder__column-element--' + this.state.status])}
                lg={5}
                md={5}>
                <Row id={"collection-items-title"} className={stylesSCTree['tree-element-builder__collection-items-title']}> {selectedCollection.label.length > 0 ? selectedCollection.label : " - "
                }</Row>
                <Row>
                    <Typeahead
                        ref={(typeahead) => this['typahead-autocomplete-items'] = typeahead}
                        clearButton
                        className={BUtils.joinClasses(['teb-collection'], stylesSCTree['tree-element-collection-builder__autocomplete-collection-items'], stylesSCTree['tree-element-collection-builder__autocomplete'], stylesSCTree['tree-element-collection-builder__autocomplete--' + selectedCollection.tmpData.startInsert])}
                        onChange={item => {
                            selectedCollection.itemIdSelected = selectedCollection.items.indexOf(item[0]);
                            this.setState({selectedCollection});
                        }}
                        onSearch={() => {
                            return;
                        }}
                        options={selectedCollection.items}
                        selected={selected}
                        placeholder={'collezione'}
                    />
                    <Button className={stylesSCTree['tree-element-builder__button-remove-collection']}

                            ref={button => {
                                this.targetRemoveItemCollection = button;
                            }}

                            onClick={(evt) => {
                                if (this.state.status == 'add') {
                                    Notifications.showErrorModalMessage('ALERT_MAIN_COLLECTION_TO_ADD', 3000);
                                } else if (typeof selectedCollection.itemIdSelected !== 'undefined') {
                                    var overlay = this.state.overlay;
                                    overlay.show = !overlay.show;
                                    overlay.target = 'targetRemoveItemCollection';
                                    overlay.message = Dict.t('ASK_REMOVE_ELEMENT');
                                    overlay.placement = 'left';
                                    overlay.buttons = [{
                                        text: Dict.t('YES'), callback: () => {
                                            var idToCheckInNewData = selectedCollection.items[selectedCollection.itemIdSelected].id;

                                            selectedCollection.items.splice(parseInt(selectedCollection.itemIdSelected), 1);
                                            this._updateComposedCollections(selectedCollection);
                                            this._resetOverlay();
                                            this.setState({selectedCollection}, function () {
                                                this['typahead-autocomplete-items'].getInstance().clear();
                                                var newCollectionData = JSON.parse(JSON.stringify(this.state.newCollectionData));

                                                const foundInNewCollection = newCollectionData.some((item) => {
                                                    if (item.id == idToCheckInNewData) {
                                                        newCollectionData.splice(newCollectionData.indexOf(item), 1);
                                                        return true;
                                                    }
                                                });

                                                if (foundInNewCollection)
                                                    this.setState({newCollectionData});

                                            }.bind(this));
                                        }
                                    }, {
                                        text: Dict.t('NO'), callback: () => {
                                            this._resetOverlay();
                                        }
                                    }];
                                    this.setState({overlay});
                                }
                            }}>
                        <FontAwesome name={"trash"}/>
                    </Button>
                </Row>
                {NewCollectionData}
                {  this.state.activateDbCollection ? <Row id="db-collection" className={stylesSCTree['tree-element-builder__db-collection']}>
                    <Row className={stylesSCTree['tree-element-builder__title-db-collection']}>{this.state.selectedDbCollection.label}</Row>
                    {this.state.selectedDbCollection.component}
                </Row> : ''}
            </Col>
            {BuilderOverlay}
        </Row>;
    }
}
