import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import MicrolinkCard from '@microlink/react';
import stylesScFileViewer from "./SCFileViewer.module.css";
import {Button, FormControl, InputGroup, Label, OverlayTrigger, Popover, Row} from "react-bootstrap";
import BUtils from "../../utilities/BaseUtilities";
import FontAwesome from "react-fontawesome";
import {AdministrationUtilities} from "../../administration/AdministrationHelper";
import {LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";
import stylesLexemeSenses from "../../_css/dictionary/lexemeParts/lexemeSenses.module.css";


export class SCFileViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editId: '',
            editText: '',
            newLink: ''
        }
    }

    _openPreview(item, itemType) {
        if (itemType === 'toAdd') {

        } else {
            var id = this.props.item.id;
            var filename = AdministrationUtilities.encodeOSPath(item.fileUrl);
            var type = item.type;
            const isImage = type === 'img';
            const isUrl = type === 'url';

            var srcPrefix = BUtils.getServerUrl() + '/service/search/createGalleryMultimediaResource/' + this.props.itemType + '/' + id + '/' + filename + '/' + type + '/';
            var src = srcPrefix + isImage + '/';
            var componentPreview = {name: item.label, src, type};
            if (isImage) {
                componentPreview.srcOriginal = srcPrefix + 'false/';
            } else if (isUrl) {
                componentPreview.name = item.fileUrl;
            }

            this.setState({componentPreview});
        }
    }

    _editLink(evt) {
        this.setState({newLink: evt.target.value});
    }

    _handleNewLinkKeyUp(event) {
        if (event.keyCode === 13 && this.state.newLink.length > 0) {
            if (BUtils.isValidURL(this.state.newLink)) {
                this.props.callBackAddLink(this.state.newLink);
                this.setState({showAddLink: false, newLink: ''});
            } else
                alert('url non valido!');
        }
    }

    _editFilename(multimediaItem, evt) {
        this.setState({editText: evt.target.value, editId: multimediaItem.id});
    }

    _handleKeyUp(event) {
        if (event.keyCode === 13) {
            Dict.call('/service/search/storeBackofficeEntityMultimediaTitle/' + this.state.editId + '/' + this.state.editText + '/').then((result) => {
                this.props.callBackSetTitle();
                document.getElementById('button-open-edit-file-title-' + this.state.editId).click();
                this.setState({editId: '', editText: ''});
            });
        }
    }

    _createEditFile(item) {
        var id = 'popover-file-viewer-edit-' + item.id;
        setTimeout(() => {
            var edtiEl = document.getElementById(id);
            if (edtiEl !== null)
                edtiEl.focus();
        }, 300);

        return <Popover id="popover-file-viewer-positioned-right" className={stylesScFileViewer['popover-file-viewer']}>
            <InputGroup style={{width: '100%', paddingTop: '5px'}}>
                <FormControl style={{width: '100%'}}
                             id={id}
                             type="text"
                             placeholder={Dict.t('EDIT')}
                             value={this.state.editText}
                             ref={(input) => {
                                 this.newEntryForm = input;
                             }}
                             onChange={(evt) => {
                                 this._editFilename(item, evt)
                             }}
                             onKeyUp={(evt) => this._handleKeyUp(evt)}
                />
            </InputGroup>
        </Popover>
    }

    _createDelete(item) {
        return <Popover id="popover-file-viewer-positioned-right" className={stylesScFileViewer['popover-file-viewer-delete']}>
            <Button onClick={() => this._removeMultimedia(item)}>{Dict.t('CONFIRM')}</Button>
        </Popover>;
    }

    _buildListFilesItems(list, itemType) {
        if (typeof list !== 'undefined') {
            const isToAdd = itemType === 'toAdd';
            return list.map((item) => {
                const divCellId = 'file-viewer-cell-name-' + item.id;
                return <div className={stylesScFileViewer['backoffice-search-edit__list-files__row']}>
                    <div id={divCellId}
                         onMouseOver={() => {
                             this._showIfEllipsisActive(divCellId);
                         }}
                         onMouseOut={() => {
                             this._resetIfEllipsisActive(divCellId);
                         }}
                         className={stylesScFileViewer['backoffice-search-edit__list-files__cell-name']}
                         onClick={() => this._openPreview(item, itemType)}>{isToAdd ? item.name : item.label}</div>
                    <div
                        className={stylesScFileViewer['backoffice-search-edit__list-files__cell-type']}>{isToAdd ? BUtils.getFileType(item.name) : item.type}</div>
                    {this.props.editable ?
                        <div className={stylesScFileViewer['backoffice-search-edit__list-files__cell-btn']}>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={this._createEditFile(item)}
                                            rootClose>
                                <Button
                                    id={'button-open-edit-file-title-' + item.id}
                                    className={stylesScFileViewer['backoffice-search-edit__list-files__cell-btn-remove--' + !isToAdd]}
                                    onClick={() => {
                                        this.setState({editText: item.label});
                                    }}>
                                    <FontAwesome name={'edit'}/>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={this._createDelete(item)}
                                            rootClose>
                                <Button
                                    id={'button-open-delete-file-' + item.id}
                                    onClick={() => {
                                        if(isToAdd) {
                                            var filesToAdd = this.props.filesToAdd;
                                            BUtils.removeFromArray(item, filesToAdd);
                                            this.props.callBackResetFilesToAdd(filesToAdd);
                                        }
                                    }}
                                >
                                    <FontAwesome name={'trash'}/>
                                </Button>
                            </OverlayTrigger>
                        </div> : ''}
                </div>;
            });
        } else {
            return '';
        }
    }

    _showHideOriginalImage() {
        var img = document.getElementById('backoffice-search-original-image');
        var imgCrop = document.getElementById('backoffice-search-crop-image');
        const show = img.style.display === 'none' || img.style.display === '';
        img.style.display = show ? 'block' : 'none';
        imgCrop.style.cursor = show ? 'zoom-out' : 'zoom-in';
    }

    _removeMultimedia(multimediaItem) {
        this.setState({componentPreview: null}, () => {
            Dict.call('/service/search/removeBackOfficeEntityMultimedia/' + this.props.itemType + '/' + this.props.item.id + '/' + multimediaItem.id + '/').then(result => {
                this.props.callBackRemoveFile();
                document.getElementById('button-open-delete-file-' + multimediaItem.id).click();
            });
        })
    }

    _buildMultimediaPreview(componentPreview) {
        if (typeof componentPreview !== 'undefined' && componentPreview !== null) {
            const divTitle = <div
                className={stylesScFileViewer['backoffice-search-edit__preview-audio-title']}>{componentPreview.name}</div>;
            var component;
            if (componentPreview.type === 'audio') {
                component = <audio className={stylesScFileViewer['backoffice-search-edit__preview-audio']}
                                   src={componentPreview.src}
                                   controls={'controls'} autoPlay controlsList="nodownload"/>;
            } else if (componentPreview.type === 'img') {
                component = <>
                    <img id='backoffice-search-crop-image'
                         className={stylesScFileViewer['backoffice-search-edit__preview-crop-image']}
                         onClick={() => this._showHideOriginalImage()}
                         src={componentPreview.src} alt={null}/>
                    <img id='backoffice-search-original-image'
                         className={BUtils.joinClasses(stylesScFileViewer['backoffice-search-edit__preview-original-image'], stylesScFileViewer['backoffice-search-edit__preview-original-image--' + (this.props.editable ? 'editable' : 'read-only')])}
                         onClick={() => this._showHideOriginalImage()}
                         src={componentPreview.srcOriginal} alt={null}/>
                </>;
            } else if (componentPreview.type === 'video') {
                component = <video src={componentPreview.src}
                                   className={stylesScFileViewer['backoffice-search-edit__preview-original-video']}/*className="tree-playlist-audio-item"*/
                                   controls="controls" autoPlay controlsList="nodownload"/>;
            } else if (componentPreview.type === 'url') {
                component = <MicrolinkCard url={componentPreview.name} style={{margin: '5px'}}/>;

            }
            return <>{divTitle}{component}</>;
        } else
            return null;
    }

    _showIfEllipsisActive(id) {
        var element = document.getElementById(id);
        var overflow = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        if (overflow) {
            element.classList.remove(stylesScFileViewer['backoffice-search-edit__list-files__cell-name']);
            element.classList.add(stylesScFileViewer['backoffice-search-edit__list-files__cell-name--hover']);
        }
    }

    _resetIfEllipsisActive(id) {
        var element = document.getElementById(id);
        element.classList.add(stylesScFileViewer['backoffice-search-edit__list-files__cell-name']);
        element.classList.remove(stylesScFileViewer['backoffice-search-edit__list-files__cell-name--hover']);
    }


    render() {

        const editable = typeof this.props.editable !== 'undefined' && this.props.editable;
        const multimediaFiles = this._buildListFilesItems(this.props.item.multimediaFiles, 'stored')
        const multimediaFilesToAdd = this._buildListFilesItems(this.props.filesToAdd, 'toAdd')

        const componentElement = Object.keys(multimediaFiles).length > 0 ? this._buildMultimediaPreview(this.state.componentPreview) : <div style={{ fontWeight : 'bold', padding : '5px'}}>{Dict.t('NO_MULTIMEDIA_FOUND')}</div>;

        return <>
            <div
                className={BUtils.joinClasses(stylesScFileViewer['backoffice-search-edit__files'], this.props.additionalClass)}>
                {editable ? <Label>Files salvati</Label> : ''}
                <Row style={{margin: 0}}>{multimediaFiles}</Row>
                {editable ? <>
                    <Label>Files da aggiungere</Label>
                    <Row style={{margin: 0}}>{multimediaFilesToAdd}</Row>
                    <div className={stylesScFileViewer['backoffice-search-files-add-link-line']}>
                        <Label>Aggiungi link esterno </Label>
                        <Button className={stylesScFileViewer['backoffice-search-files-add-link']} onClick={() => {
                    this.setState({showAddLink: !this.state.showAddLink});
                    setTimeout(() => {
                        var edtiNewEl = document.getElementById('new-file-view-edit-link');
                        if (edtiNewEl !== null)
                            edtiNewEl.focus();
                    }, 300);
                    }}><FontAwesome name={'external-link'}/></Button></div>
                    {this.state.showAddLink ? <InputGroup style={{width: '90%', paddingTop: '5px'}}>
                        <FormControl style={{width: '100%'}}
                                     id={'new-file-view-edit-link'}
                                     type="text"
                                     placeholder={Dict.t('ADD_URL')}
                                     value={this.state.newLink}
                                     ref={(input) => {
                                         this.newLink = input;
                                     }}
                                     onChange={(evt) => {
                                         this._editLink(evt);
                                     }}
                                     onKeyUp={(evt) => {
                                         this._handleNewLinkKeyUp(evt)
                                     }}
                        />
                    </InputGroup> : ''}
                </> : ''}
            </div>
            <div
                className={BUtils.joinClasses(stylesScFileViewer['backoffice-search-edit__preview'], stylesScFileViewer['backoffice-search-edit__preview--' + ((true) || (typeof componentElement !== 'undefined' && componentElement !== null))])}>{componentElement}</div>
        </>
    }

}