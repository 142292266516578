import React from "react";
import ReactDOM from "react-dom";
import Dict from 'dict-lib';
import {Button, ButtonGroup, ButtonToolbar, Col, Grid, Label, Modal, Row} from 'react-bootstrap';
import {ModalOperations, Notifications} from "../../utilities/DictUtilities";

import stylesScNavbar from './../../dict-components/navbar/ScNavbar.module.css';
import BUtils from "../../utilities/BaseUtilities";
import stylesSearchPage from "../../_css/dictionary/searchPage.module.css";
import FontAwesome from "react-fontawesome";
import {LexemeDisplayContext, LexemeEditingLevel, LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";

export class LexemeErrorPages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
            checked: false
        };

        this._testEntry = this._testEntry.bind(this);
        this._resetErroneousPages = this._resetErroneousPages.bind(this);
    }

    componentDidMount() {
        this._resetErroneousPages();
    }

    _fillErrors(result, type) {
        return result.errors.filter(item => {
            return item.type === type;
        });
    }

    _resetErroneousPages() {
        Dict.call('page/retrieveErroneousPages/').then((result) => {
            const builderErrors = this._fillErrors(result, 'BUILDER_ERROR');
            const renderErrors = this._fillErrors(result, 'RENDER_ERROR');
            const systemErrors =  this._fillErrors(result, 'SYSTEM_ERROR');

            this.setState({builderErrors, renderErrors, systemErrors, checked: true});
        });
    }

    _tryResolveRender(id) {
        return Dict.call('/page/getPageById/' + id + '/').then(resultPage => {
            if(resultPage.result === "OK") {
                var res = LexemeUtilities.getLexemePage(id, LexemeEditingLevel.EDITABLE, resultPage.page, LexemeDisplayContext.DICTIONARY_SEARCH);
                try {
                    ReactDOM.render(res, document.getElementById('test_render'));
                    Dict.call('/page/resetErrors/' + id + '/');
                    return res;
                } catch (e) {
                    return {result: 'ERROR', lexemePageAnalysis: {message: e.message}}
                }
            } else
                return {result: 'ERROR', lexemePageAnalysis: {message: resultPage.message}}
        });
    }

    _tryResolveBuilder(id) {
        return Dict.call('page/tryCorrect/' + id + '/');
    }

    _closeSystemError(id) {
        return Dict.call('page/forceCorrect/' + id + '/');
    }

    _getOkMsg(errorList) {
        const isOk = this.state.checked === true && errorList.length === 0;
        return isOk ? 'Nessun errore riscontrato' : '';
    }

    _testEntry(id, type) {
        var erroneousPages = this.state[type + 'Errors'];

        var errorItem = erroneousPages.find((item) => {
            return item.id === id;
        });
        errorItem.message = <FontAwesome name={"spinner"}
                                         className={BUtils.joinClasses(stylesSearchPage['dict-result-list-buttons__spinner'], stylesSearchPage['dict-result-list-buttons__spinner--true'])}
                                         spin/>;
        this.setState({erroneousPages}, () => {
            setTimeout(() => {
                var resolveCorrection = new Promise((resolve) => {
                    if(type === 'system')
                        resolve(this._closeSystemError(id));
                    if (type === 'builder')
                        return resolve(this._tryResolveBuilder(id));
                    else
                        return resolve(this._tryResolveRender(id));
                });

                resolveCorrection.then((result) => {
                    if (result.result.toLowerCase() === 'error') {
                        errorItem.message = result.lexemePageAnalysis.message;
                        this.setState({erroneousPages});
                    } else {
                        errorItem.message = <>
                            <FontAwesome name={"smile-o"}
                                         className={BUtils.joinClasses(stylesScNavbar['lexeme-error-page-error-correct'])}
                            /><FontAwesome name={"hand-peace-o"}
                                           className={BUtils.joinClasses(stylesScNavbar['lexeme-error-page-error-correct'])}
                        /><span
                            className={stylesScNavbar['lexeme-error-page-error-correct']}>Problema risolto</span></>;

                        this.setState({erroneousPages}, () => setTimeout(() => {
                            this._resetErroneousPages();
                        }, 1000));
                    }
                }).catch((error) => {
                    this._resetErroneousPages();
                    console.log(error);
                });
            }, 1000);
        });
    }

    _retrieveErrorList(erroneousPages, type) {
        if (typeof erroneousPages !== 'undefined' && erroneousPages !== null) {
            return erroneousPages.map((item) => {
                return <Row key={'key-lexeme-error-pages-item-label-' + item.id}
                            className={stylesScNavbar['lexeme-error-pages-item-row']}>
                    <Col lg={2} md={2} className={stylesScNavbar['lexeme-error-pages-item-label']} onClick={() => {
                        this._testEntry(item.id, type)
                    }}>{item.label}</Col>
                    <Col id={'lexeme-error-pages-item-message-' + item.id} lg={10} md={10}
                         className={stylesScNavbar['lexeme-error-pages-item-message']}>{item.message}</Col>
                </Row>;
            });
        } else
            return [];
    }

    render() {

        const builderErrorList = this._retrieveErrorList(this.state.builderErrors, "builder");
        const renderErrorList = this._retrieveErrorList(this.state.renderErrors, "render");
        const systemErrorList = this._retrieveErrorList(this.state.systemErrors, "system");

        const msgSystemOk = this._getOkMsg(systemErrorList);
        const msgBuilderOk = this._getOkMsg(builderErrorList);
        const msgRenderOk = this._getOkMsg(renderErrorList);

        const msgSystemFix = systemErrorList.length >  0 ? <small style={{ marginLeft : '10px', fontWeight : 'bold'}}>(Attenzione: gli errori di sistema non vengono risolti qui, ma semplicemente chiusi una volta accertato e corretto il bugfix)</small> : '';

        return <Modal show={this.state.show} dialogClassName={stylesScNavbar['lexeme-pages-generator-dialog-modal']}
                      restoreFocus onHide={() => this.setState({show: false}, () => {
            ModalOperations._emptyModalContainer()
        })}>
            <Modal.Header closeButton><h4 className={'modal-title'}>Errori nei lessemi</h4></Modal.Header>
            <Modal.Body>
                <Grid className={stylesScNavbar['lexeme-error-pages-dialog-modal']} fluid>
                    <h4><Label>Errori di sistema</Label>{msgSystemFix}</h4>
                    {msgSystemOk}
                    {systemErrorList}
                </Grid>
                <Grid className={stylesScNavbar['lexeme-error-pages-dialog-modal']} fluid>
                    <h4><Label>Errori di builder</Label></h4>
                    {msgBuilderOk}
                    {builderErrorList}
                </Grid>
                <Grid className={stylesScNavbar['lexeme-error-pages-dialog-modal']} fluid>
                    <h4><Label>Errori di rendering</Label></h4>
                    {msgRenderOk}
                    {renderErrorList}
                </Grid>
                <div id={'test_render'} style={{display: 'none'}}/>
            </Modal.Body>
        </Modal>
    }
}