import React from 'react';
import Dict from 'dict-lib';
import {Col, Row, InputGroup, FormControl, Button} from 'react-bootstrap';
import ReactDom from 'react-dom';
import {getLexemeProperties} from './../../LexemePage';
import './../../editor/root-entry/RootEntry.css';
import {addAltForm} from './EntryActions';
import {LexemeUtilities} from "../LexemeHelper";
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";

export class EntryAddAltForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            form: "",
            homoMsg: "",
            entryId: "",
            checkHomo: false
        };

        this._handleAddALtForm = this._handleAddALtForm.bind(this);
        this._saveAltForm = this._saveAltForm.bind(this);
    }

    componentWillMount() {
        var entryId = typeof this.props.item.title !== 'undefined' ? getLexemeProperties('root_entry_id') : this.props.item.variants[0].id;
        this.setState({entryId});
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.altForm).focus();
    }

    _handleAddALtForm(evt) {
        var newForm = evt.target.value;
        this.setState({form: newForm, checkHomo: true}, function() {
            Dict.call('/entry/checkEntryForm/' + this.state.entryId + '/' + newForm + '/' + true).then(result => {
                var homoMsg = "";

                if ((result.thisEntry).length > 0)
                    homoMsg += "<div class='entry-add-alt-form-this-entry'>" + result.thisEntry + "</div>";

                if ((result.homos).length > 0) {
                    result.homos.map((item) => {
                        homoMsg += "<div class='entry-add-alt-form-list-item'>" + item + "</div>";
                    });
                }
                this.setState({homoMsg, checkHomo: false});
            });
        });
    }

    _saveAltForm() {
        addAltForm(this.state.entryId, this.state.form, this.props.item.title);
    }

    render() {

        return <div className="entry-add-alt-form">
            <Row className="entry-add-alt-form-input">
                <Col lg={9} md={9}>
                    <InputGroup className={"subentry-edit-group"}>
                        <InputGroup.Addon>{Dict.t('FORM')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('ADD_FORM') + "..."}
                                     ref={(input) => {
                                         this.altForm = input;
                                     }}
                                     value={this.state.form}
                                     onChange={(evt) => this._handleAddALtForm(evt)}
                                     onKeyUp={(evt) => LexemeUtilities.handleKeyUp(evt, () => {
                                         this._saveAltForm();
                                     })}/>
                    </InputGroup>
                </Col><Col lg={3} md={3}>
                <SCSubmitButton onClick={this._saveAltForm} text={Dict.t('SAVE')} disabled={this.state.checkHomo}/>
            </Col>
            </Row>
            <Row className="entry-add-alt-form-msg">
                <Col lg={11} md={11} dangerouslySetInnerHTML={{__html: this.state.homoMsg}} />
            </Row>
        </div>;
    }
}