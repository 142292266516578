import React from "react";
import Dict from 'dict-lib';
import Tree, {TreeNode} from 'draggable-react-tree-component';

import stylesBackOfficeTree from './BackOfficeTree.module.css';
import {TreeItemMenu} from "../../dict-components/tree/components/TreeItemMenu";


export class BackOfficeTree extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            load: false,
            tree: [],
            autoExpandParent: true,
            expandedKeys: [this.props.expandedKey],
            selectedKeys: [this.props.expandedKey]
        };

        [
            '_onDragStart',
            '_onDragEnter',
            '_onDrop',
            '_onExpand',
            '_onSelect'
        ].forEach((name) => (this[name] = this[name].bind(this)))
    }

    componentDidMount() {
        this._retrieveTree();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entity) {
            if ((this.props.updateTree || !this.state.load)) {
                this._retrieveTree();
            } else if (this.props.selectable && typeof this.props.expandedKey !== 'undefined' && this.props.expandedKey !== this.state.selectedKeys[0]) {
                var expandedKeys = this.state.expandedKeys;
                if (expandedKeys.indexOf(this.props.expandedKey) === -1)
                    expandedKeys.push(this.props.expandedKey);

                this.setState({
                    selectedKeys: [this.props.expandedKey],
                    expandedKeys: expandedKeys,
                    autoExpandParent: true
                });
                /*var selectedKeys = this.state.selectedKeys;
                if (selectedKeys.indexOf(this.props.expandedKey) === -1) {
                    selectedKeys = [];
                    selectedKeys.push(this.props.expandedKey);

                    var expandedKeys = JSON.parse(JSON.stringify(selectedKeys));

                    this.setState({
                        selectedKeys,
                        expandedKeys,
                        autoExpandParent: true,
                    });
                }*/
            }
        }
    }

    _retrieveTree() {
        Dict.call('/service/search/getRootTreeBackofficeEntry/' + this.props.entity + '/').then((tree) => {
            const load = true;
            this.setState({tree, load});
        });
    }

    _onSelect(keys) {
        if (keys.length > 0) {
            if (this.props.selectable === true) {
                var expandedKeys = this.state.expandedKeys;
                if (expandedKeys.indexOf(this.props.expandedKey) === -1)
                    expandedKeys.push(this.props.expandedKey);
                this.setState({
                    selectedKeys: [keys[0]],
                    expandedKeys,
                    autoExpandParent: true
                }, () => {
                    this.props.callbackSelect({id: keys[0], label: ""});
                });
            }
        }
        /*this._traverseToKey(this.state.tree, keys[0], (item) => {
            if (this.props.selectable === true) {
                var expandedKeys = this.state.expandedKeys;
                if (expandedKeys.indexOf(this.props.expandedKey) === -1)
                    expandedKeys.push(this.props.expandedKey);

                this.setState({
                    selectedKeys: [item.key],
                    expandedKeys,
                    autoExpandParent: true
                }, () => {
                    this.props.callbackSelect({id: item.key, label: item.title});
                });

            }
        });*/
    }

    _traverseToKey(data, key, callback) {
        data.forEach((item, index, arr) => {
            if (item.key === key) {
                return callback(item, index, arr)
            }
            if (item.children) {
                return this._traverseToKey(item.children, key, callback)
            }
            return null
        })
    }

    _onDragStart(info) {
        console.log('drop', info)
    }

    _onDragEnter(info) {
        // console.log('enter', info)
        this.setState({
            expandedKeys: info.expandedKeys,
        })
    }

    _onDrop(info) {
        console.log('drop', info)
    }

    _onExpand(expandedKeys, node) {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    }

    _traverseToKey(data, key, callback) {
        data.forEach((item, index, arr) => {
            if (item.key === key) {
                return callback(item, index, arr)
            }
            if (item.children) {
                return this._traverseToKey(item.children, key, callback)
            }
            return null
        })
    }

    render() {

        const tree = this.state.tree;

        const loop = data => (
            data.map((item) => {
                return <TreeNode
                    className={stylesBackOfficeTree['backoffice-tree-fo--' + this.props.frontend]}
                    key={item.key}
                    items={(item.children && item.children.length)
                        ? loop(item.children)
                        : null}
                >{item.title}</TreeNode>;
            }));

        return <Tree
            className={stylesBackOfficeTree['backoffice-tree']}
            isLoading={false}
            onExpand={this._onExpand}
            autoExpandParent={this.state.autoExpandParent}
            draggable
            selectable
            onSelect={this._onSelect}
            onDragStart={this._onDragStart}
            onDragEnter={this._onDragEnter}
            onDrop={this._onDrop}
            selectedKeys={this.state.selectedKeys}
            expandedKeys={this.state.expandedKeys}
        >{loop(tree)}</Tree>;
    }

}