import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import LexemePage, {getPage} from './page/LexemePage';
import {LanguageTypeahead} from './../dict-components/typeahead/LanguageTypeahead';
import {LanguageTypeAheadTypes, LexemeEditingLevel, LexemeUtilities} from './page/editor/LexemeHelper';
import {Modal, Button, Col, Row, InputGroup, FormControl} from 'react-bootstrap';
import {SearchResultButtonBar} from "../search/SearchResultButtonBar";

import styleCreateLexeme from './../_css/dictionary/createLexeme.module.css';
import styleSearchPage from './../_css/dictionary/searchPage.module.css';
import BUtils from './../utilities/BaseUtilities';
import stylesSearchPage from "../_css/dictionary/searchPage.module.css";

export class CreateLexeme extends React.Component {

    constructor(props) {
        super(props);

        this.state = ({
            showModal: true,
            newEntryForm: "",
            selectedLangId: this.props.language.id,
            isLexoRoot: Dict.getLanguageOptionsById(this.props.language.id)["entries->NEW_ROOT_IS_LEXOTYPE"],
            nextLexemeHomo: 0,
            nextHomo: 0,
            isPrimaryChoosen: false,
            bsStyle: "primary"
        });

        this._close = this._close.bind(this);
        this._handleCreateEntryForm = this._handleCreateEntryForm.bind(this);
        this._handleKeyUp = this._handleKeyUp.bind(this);
        this._createNewEntry = this._createNewEntry.bind(this);
    }

    componentDidMount() {
        setTimeout(function () {
            this._focusOnNewForm();
        }.bind(this), 500);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({showModal: true});
        setTimeout(function () {
            this._focusOnNewForm();
        }.bind(this), 500);
    }

    _close() {
        this.setState({showModal: false}, function () {
            this._emptyModalContainer();
        }.bind(this));
    }

    _focusOnNewForm() {
        document.getElementById('create-lexeme-input').focus();
    }

    _emptyModalContainer(callback) {
        if (typeof callback === 'function') {
            setTimeout(function () {
                ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
                callback();
            }, 1000);
        }
    }

    _handleKeyUp(event) {
        if (event.keyCode === 13) {
            ReactDOM.findDOMNode(this.createButton).click();
        }
    }

    _handleCreateEntryForm(evt) {
        this.setState({newEntryForm: evt.target.value}, function () {
            if (Object.keys(this.state.newEntryForm).length > 0 && this.state.selectedLangId.toString().length > 0) {
                Dict.call('/entry/checkNewLexemeForm/' + this.state.newEntryForm + '/' + this.state.selectedLangId).then(result => {
                    const nextLexemeHomo = result.nextLexemeHomo;
                    const nextHomo = result.nextHomo;
                    const isPrimaryChoosen = result.isPrimaryChoosen;
                    this.setState({nextLexemeHomo, nextHomo, isPrimaryChoosen});
                })
            } else if (Object.keys(this.state.newEntryForm).length === 0) {
                this.setState({nextLexemeHomo: 0, nextHomo: 0});
            }

        }.bind(this));
    }

    _handleLanguage(newState) {
        this.setState({selectedLangId: newState}, function () {
            this._focusOnNewForm();
        });
    }

    _createNewEntry() {
        if (Object.keys(this.state.newEntryForm).length > 0 && this.state.selectedLangId.toString().length > 0) {
            Dict.call('/entry/createLexeme/' + this.state.newEntryForm + '/' + this.state.selectedLangId + '/' + this.props.discriminator + '/').then(result => {
                this.setState({bsStyle: "success"});
                this._emptyModalContainer(function () {

                    var page = getPage();

                    /*var involvedIds = typeof result.additionalInfos === 'undefined' ? undefined : result.additionalInfos.involvedLexemeIds;
                    if (typeof involvedIds !== 'undefined')
                        LexemeUtilities.resetTabInvolvedItems(page, involvedIds);*/

                    var dicResultContent = document.getElementById("dict-result-content");
                    dicResultContent.classList.add(stylesSearchPage['dict-result-content--active']);

                    const SearchResultButtonBarConst = <SearchResultButtonBar/>;
                    ReactDOM.render(SearchResultButtonBarConst, document.getElementById("dict-result-container-buttonbar"));

                    var dictResultList = document.getElementById('dict-result-list');
                    if (Object.keys(page.tabs).length === 0 && dictResultList === null) {
                        var dictResultContainer = document.getElementById("dict-result-container");
                        dictResultContainer.className = BUtils.joinClasses(styleSearchPage['dict-result-container--hide'], 'col-lg-1');
                        document.getElementById('bt-resize-page').click();
                    }


                    LexemeUtilities.openLexemePage(result.id, result.id, true, LexemeEditingLevel.EDITABLE, this.state.newEntryForm, null, 'BAU', parseInt(result.langId), result.langCode);
                }.bind(this));
            });
        } else {
            this._focusOnNewForm();
            this.setState({bsStyle: "warning"}, function () {
                setTimeout(function () {
                    this.setState({bsStyle: "primary"});
                }.bind(this), 3000);
            });
        }
    }

    render() {

        return <Modal id="dict-create-lexeme" show={this.state.showModal} onHide={this._close}
                      className={BUtils.joinClasses(styleCreateLexeme[this.props.language.is_tree ? 'create-tree-model' : 'create-mono-lang-model'], styleCreateLexeme['dict-create-lexeme'])}
                      restoreFocus><Modal.Header closeButton>
            <Modal.Title>{Dict.t(this.props.discriminator === 'ENTRY' ? 'CREATE_LEXEME' : 'CREATE_TOPONYM') + " - "}<strong>{this.props.language.label}</strong></Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <Col id={'col-7-input-group-create-lexeme'} lg={6} md={6}>
                    <InputGroup>
                        {this.props.language.is_tree ? <InputGroup.Addon key={"key-ref-create-entry"}
                                                                         ref={"ref-create-entry"}>{Dict.t(this.state.isLexoRoot ? "LEXOTYPE_FORM" : "ATTESTED_FORM")}</InputGroup.Addon> : ""}
                        <FormControl type="text" placeholder={Dict.t('NEW_ENTRY_FORM') + "..."}
                                     id={'create-lexeme-input'}
                                     value={this.state.newEntryForm}
                                     ref={(input) => {
                                         this.newEntryForm = input;
                                     }}
                                     onKeyUp={this._handleKeyUp}
                                     onChange={(evt) => this._handleCreateEntryForm(evt)}
                        />
                        <div id='create-lexeme-div-sup' className={styleCreateLexeme['create-lexeme__div-sup']}><sup
                            className={BUtils.joinClasses(styleCreateLexeme['create-lexeme__sup'], styleCreateLexeme['create-lexeme__sup-first'], styleCreateLexeme['create-lexeme-lexhomo--' + this.state.nextLexemeHomo])}>{this.state.nextLexemeHomo}</sup><sup
                            className={BUtils.joinClasses(styleCreateLexeme[styleCreateLexeme['create-lexeme__sup-second'], this.state.isPrimaryChoosen ? 'create-lexeme__var-is-primary' : ''], styleCreateLexeme['create-lexeme-varhomo--' + this.state.nextHomo])}>{"(" + this.state.nextHomo + ")"}</sup>
                        </div>
                    </InputGroup>
                </Col>
                <Col lg={4} md={4}>
                    {this.props.language.is_tree ?
                        <LanguageTypeahead ref="typehead" type={LanguageTypeAheadTypes.TREE_LANGUAGE_HIERARCHY}
                                           selected={this.props.language}
                                           callbackParent={(newState) => this._handleLanguage(newState)}/> : ""}
                </Col>
                <Col lg={2} md={2}><Button bsStyle={this.state.bsStyle} onClick={() => this._createNewEntry()}
                                           ref={(input) => {
                                               this.createButton = input
                                           }}>{Dict.t('CREATE')}</Button></Col>
            </Modal.Body>
        </Modal>
    }
}
