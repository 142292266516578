import React from 'react';
import ReactDOM from 'react-dom';
import {Modal, Button, Label} from 'react-bootstrap';
import Dict from 'dict-lib';
import './Ipa.css';

export class Ipa extends React.Component {

    constructor(props) {
        super(props);

        this.state = {showModal: this.props.show};

        this._close = this._close.bind(this);
        this._copy = this._copy.bind(this);
    }

    componentDidMount() {
        setTimeout(function() {
            var textArea = this._getTextArea();
            textArea.value = this.props.text;
            textArea.focus();
        }.bind(this), 800);
    }

    _getTextArea() {
        var iframe = document.getElementById('ipa-iframe');
        var innerDoc = iframe.contentDocument || iframe.contentWindow.document;

        var iframe2 = innerDoc.getElementsByTagName('frame')[1];
        var innerDoc2 = iframe2.contentDocument || iframe2.contentWindow.document;
        return innerDoc2.body.getElementsByTagName("textArea")[0];
    }

    _close() {
        this.setState({showModal: false}, function () {
            this._emptyModalContainer();
            document.getElementsByClassName("react-draggable-translate")[0].classList.remove("react-draggable-translate");
        });
    }

    _copy() {
        var textArea = this._getTextArea();
        this.props.callbackParent(textArea.value, this.props.count, this.props.type);
        this._close();
    }

    _emptyModalContainer() {
        setTimeout(function () {
            ReactDOM.unmountComponentAtNode(document.getElementById("modal-ipa-container"));
        }, 1000);
    }

    render() {
        return <Modal className={"modal-content-ipa"} show={this.state.showModal} onHide={this._close} restoreFocus>
            <div className="div-ipa-container">
                <iframe id="ipa-iframe"
                        src={'./ipa/keyboard/'}
                        allowFullScreen
                />
            </div>
            <Modal.Footer>
                <Label className={"ipa-label"}>{Dict.t(this.props.type.toUpperCase())}</Label>
                <Button onClick={this._copy}>{Dict.t('UPDATE_AND_CLOSE')}</Button>
                <Button onClick={this._close}>{Dict.t('CLOSE')}</Button>
            </Modal.Footer></Modal>


    }

}
