import React from 'react'
import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';

const containerStyle = {
    width: '100%',
    height: '100%',
    position: 'relative',
    paddingRight: '5px'
};

const testCenter = {lat: 41.871941, lng: 12.567380};


export class SCMaps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMarker: {},
            showInfo: false
        }
    }

    _onMarkerClick(props, activeMarker, e) {
        this.setState({activeMarker, showInfo: !this.state.showInfo});
    }

    render() {

        return <div style={{width: '100%', height: '350px'}}>
            <Map
                containerStyle={containerStyle}
                google={window.google}
                zoom={12}
                onReady={() => this.props.callbackReady()}
                center={this.state.center}
                initialCenter={this.props.coord}
                onClick={(mapProps, map, e) => {
                    this.setState({center : e.latLng});
                }}
            >
                <Marker onClick={(props, marker, e) => this._onMarkerClick(props, marker, e)}
                        name={'Current baddone'}/>
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showInfo}
                    onClose={() => {
                        this.setState({showInfo: false})
                    }}
                >
                    <div>
                        <h2>{this.props.infoMapTitle}</h2>
                        <h1>{this.props.infoMap}</h1>
                    </div>
                </InfoWindow>
                <InfoWindow visible={true}>baddarone</InfoWindow>
            </Map>
        </div>
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBfOpCOJVag5P5e4j5ljM3SBhaaBsD821M'
})(SCMaps)