import React from "react";
import Dict from 'dict-lib';
import {Button, ButtonGroup, ButtonToolbar, Col, Grid, Modal, Row} from 'react-bootstrap';
import ReactDOM from "react-dom";

import stylesScNavbar from '../../../dict-components/navbar/ScNavbar.module.css';
import Dropzone from "react-dropzone";
import {DictionarySettings, Notifications} from "../../../utilities/DictUtilities";
import BUtils from '../../../utilities/BaseUtilities';
import {ImportManager} from "./ImportManager.js";
import {ImportStatus} from "../../../utilities/ImportUtilities";


export class SCImport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
            status: 'BEGIN',
            result: '',
            step: 1,
            indexing: 'waiting'
        };
    }

    componentDidMount() {
        Dict.call('/admin/checkDictionaryIndexActive').then((result) => {
            this.setState({indexing : (result.indexingDictActive + '')});
        })
    }

    _isBegin() {
        return this.state.status === ImportStatus.BEGIN
    }

    _isAnalysisStarted() {
        return this.state.status === ImportStatus.ANALYSIS_STARTED;
    }

    _isAnalysisFinished() {
        return this.state.status === ImportStatus.ANALYSIS_FINISHED;
    }

    _isReadyToLaunch() {
        return this._isAnalysisFinished() && this._isResultOK();
    }

    _foundAnalysisErrors() {
        this._isAnalysisFinished() && this._isResultError();
    }

    _isImportStarted() {
        return this.state.status === ImportStatus.IMPORT_STARTED;
    }

    _isImportFinished() {
        return this.state.status === ImportStatus.IMPORT_FINISHED;
    }

    _isResultOK() {
        return this.state.result === 'OK';
    }

    _isResultError() {
        return this.state.result === 'ERROR';
    }

    _isDbNotEmpty() {
        return this.state.status === ImportStatus.WAITING_DB_EMPTY;
    }


    _reset() {
        this.setState({status: ImportStatus.BEGIN, result: '', failedRows: []})
    }

    _createComponent() {
        var step = this.state.step;
        var status = this.state.status;
        var result = this.state.result;
        var type = this.props.type;

        var callbackSteps = (steps) => {
            this.setState({steps})
        };

        var callbackImportFinished = () => {
            this.setState({status: ImportStatus.IMPORT_FINISHED});
        };

        var callbackStartChecking = () => {
            this.setState({
                status: ImportStatus.ANALYSIS_STARTED
            })
        }

        var callbackDbNotEmpty = (failedRows) => {
            this.setState({
                status: ImportStatus.WAITING_DB_EMPTY,
                result: 'ERROR',
                failedRows
            })
        }

        var callbackAnalysisResult = (failedRows) => {
            this.setState({
                status: ImportStatus.ANALYSIS_FINISHED,
                result: failedRows.length > 0 ? 'ERROR' : 'OK',
                failedRows
            })
        };

        return React.cloneElement(<ImportManager/>, {
            step,
            status,
            result,
            type,
            callbackSteps,
            callbackImportFinished,
            callbackStartChecking,
            callbackAnalysisResult,
            callbackDbNotEmpty
        });
    }

    _close() {
        if (!this.state.importStarted) {
            this.setState({show: false}, () => {
                //this._stopImport();
                setTimeout(function () {
                    ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
                }, 200);
            });
        } else {
            alert('Prima di chiudere devi stoppare l\'importazione in corso');
        }
    }


    render() {


        var modalBody = <Modal.Body>'In attesa...'</Modal.Body>;

        if(this.state.indexing === 'false') {
            modalBody = <Modal.Body>
                <div>Server d'indicizzazione inesistente o inattivo.</div>
                <div>Avviare il server, altrimenti è necessario creare, se non presenti, i due core d'indicizzazione Solr, da linea di comando posizionandosi sulla cartella /bin dell'installazione di Solr:
                    <ul>
                        <li>./solr create -c 'nome_db'</li>
                        <li>./solr create -c 'nome_db_error_pages'</li>
                        <li>Copiare dalla cartella 'SOLR schema' i rispettivi 'managed-schema' e copiarli nelle rispettive cartelle \server\solr\<strong>nome_core</strong>\conf</li>
                    </ul>
                </div>
            </Modal.Body>;
        } else if(this.state.indexing === 'true') {
            const isBegin = this._isBegin();
            const isAnalysisStarted = this._isAnalysisStarted();
            const isAnalysisFinished = this._isAnalysisFinished();
            const isImportStarted = this._isImportStarted();
            const isImportFinished = this._isImportFinished();
            const isReadyToLaunch = this._isReadyToLaunch();
            const isResultError = this._isResultError();
            const isDbNotEmpty = this._isDbNotEmpty();

            var failedRows = '';
            if (isResultError) {
                failedRows = this.state.failedRows.map((item) => {
                    return <div className={stylesScNavbar['dict-import-error-list-row']}>
                        <div className={stylesScNavbar['dict-import-error-list-line']}>{item.line}</div>
                        <div className={stylesScNavbar['dict-import-error-list-error']}>{item.error}</div>
                    </div>;
                });

                failedRows = [<h4>Errori riscontrati</h4>].concat(failedRows);
            }

            var ImportModule = this._createComponent();

            modalBody = <>
                <Modal.Body>
                {ImportModule}
                <div
                    className={BUtils.joinClasses(stylesScNavbar['dict-import-error-list'], stylesScNavbar['dict-import-error-list-show--' + isReadyToLaunch])}>
                    <strong>Tutti gli elementi analizzati sono corretti</strong>
                </div>
                <div
                    className={BUtils.joinClasses(stylesScNavbar['dict-import-error-list'], stylesScNavbar['dict-import-error-list-show--' + isResultError])}>{failedRows}
                </div>
            </Modal.Body>
            <Modal.Footer>
            <div
            className={BUtils.joinClasses(stylesScNavbar['dict-import-footer__div'], stylesScNavbar['dict-import-started-show--' + (isAnalysisFinished || isDbNotEmpty)])}>
                <Button
            onClick={() => this._reset()}>{'Reset'} </Button>
        </div>
            <div className={BUtils.joinClasses(stylesScNavbar['dict-import-footer__div'])}>
                {isAnalysisStarted ? <div>Analisi degli elementi in corso...</div> : ''}
                {isReadyToLaunch ?
                    <div className={stylesScNavbar['dict-import-button-launch-import']}>
                        <Button
                            onClick={() => this.setState({status: ImportStatus.IMPORT_STARTED})}>{'Avvia importazione'}</Button>
                    </div> : ''}
            </div>
            <div
            className={BUtils.joinClasses(stylesScNavbar['dict-import-footer__div'], stylesScNavbar['dict-import-started'], stylesScNavbar['dict-import-started-show--' + isImportStarted])}>
                <div>
                <Button
            onClick={() => this._reset()}>{'Interrompi'} </Button>
        </div>
        </div>
        <div
            className={BUtils.joinClasses(stylesScNavbar['dict-import-footer__div'], stylesScNavbar['dict-import-step-show--' + (isBegin || isImportFinished || isDbNotEmpty)])}>
                <Button disabled={this.state.step === 1} onClick={() => {
                this.setState({step: this.state.step - 1})
            }}>{'<<'}</Button>
        <span style={{
                fontWeight: 'bold',
                    marginLeft: '5px',
                    marginRight: '5px'
            }}>{'Step ' + this.state.step + '/' + this.state.steps}</span>
        <Button disabled={this.state.step >= this.state.steps} onClick={() => {
                this.setState({
                    step: this.state.step + 1,
                    status: ImportStatus.BEGIN,
                    result: '',
                    failedRows: []
                })
            }}>{'>>'}</Button>
        </div>
        </Modal.Footer>
            </>
        }

        return <Modal show={this.state.show} dialogClassName={stylesScNavbar['dict-import-modal']}
                      restoreFocus onHide={() => this._close()}>
            <Modal.Header closeButton><h4 className={'modal-title'}>Importazione</h4></Modal.Header>
            {modalBody}
        </Modal>;
    }
}