import React from 'react';
import {getLexemeProperties, getDictionaryProperties} from '../../LexemePage';
import {showHideTranslation} from './TranslationAction';
import Dict from 'dict-lib';
import {Button, Row, Col, Grid} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class TranslationShowHide extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id
        };

        this._showHideTranslation = this._showHideTranslation.bind(this);
    }

    _showHideTranslation() {
        showHideTranslation(JSON.stringify(this.props.item), getLexemeProperties('root_entry_id'));
    }

    render() {

        const isInverse = getDictionaryProperties('is_inverse');
        const isHidden = (isInverse && this.props.item.is_hidden_out || this.props.item.is_hidden_in);

        return <Grid fluid>
            <Row>
                <Col lg={7} md={7} className="popup-label">{Dict.t(isHidden ? 'ASK_SHOW_TRANSLATION' : 'ASK_HIDE_TRANSLATION')}</Col>
                <Col lg={5} md={5}>
                    <SCSubmitButton onClick={this._showHideTranslation} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </Grid>;
    }

}
