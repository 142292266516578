import React from 'react';
import {saveSubentry} from './SubentryActions';
import './Subentries.css';
import {getLexemeProperties, getLexemeOptions} from './../../LexemePage';
import {CgrTypeahead, SCSubmitButton} from './../../../../dict-components/components';
import Dict from 'dict-lib';
import {Grid, Row, InputGroup, FormControl, Button, Label} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import ReactDom from "react-dom";
import {LexemeUtilities} from "../LexemeHelper";

export class SubEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            btDisabled: false,
            morphoSeparated: false
        };

        this._handleSearchCgr = this._handleSearchCgr.bind(this);
        this._handleMorphology = this._handleMorphology.bind(this);
        this._handleSeparatedMorphology = this._handleSeparatedMorphology.bind(this);
        this._updateState = this._updateState.bind(this);
        this._saveSubentryData = this._saveSubentryData.bind(this);
    }

    componentWillMount() {
        this.setState({
            morphoSeparated: getLexemeOptions("morphology_separated")
        })
    }

    _handleSearchCgr(newState) {
        ReactDom.findDOMNode(this.subentryForm).focus();
        this._updateState("cgr", newState);
    }

    _handleSubentryForm(evt) {
        this._updateState("subentry_form", evt.target.value);
    }

    _handleMorphology(evt) {
        var item = JSON.parse(JSON.stringify(this.state.item));
        item['morphologies'][0].morphology = evt.target.value;

        this.setState({item});
    }

    _handleSeparatedMorphology(evt, morhpoItem) {
        var item = JSON.parse(JSON.stringify(this.state.item));
        var morphos = item.morphologies;
        var index = this.state.item.morphologies.indexOf(morhpoItem);
        morphos[index].morphology = evt.target.value;

        this.setState({item}, function () {
            /*console.log(this.state.item.morphologies);*/
        });
    }

    _updateState(property, value) {
        var item = JSON.parse(JSON.stringify(this.state.item));
        item[property] = value;

        this.setState({item}, function () {
            /*console.log(item);*/
        });
    }

    _saveSubentryData() {
        var cloneItem = JSON.parse(JSON.stringify(this.state.item));
        cloneItem.senses = [];

        saveSubentry(JSON.stringify(cloneItem)).then(result => {

        });
    }

    render() {
        const item = this.state.item;

        var morphoCounter = 0;

        return <div className="subentry-edit-container">
            <Grid className="subentry-edit" fluid>
                <Row>
                    <CgrTypeahead callbackParent={(newState) => this._handleSearchCgr(newState)}
                                  languageId={getLexemeProperties("language_id")} selected={item.cgr.id}/>
                </Row>
                <Row>
                    <InputGroup className={"subentry-edit-group"}>
                        <InputGroup.Addon>{Dict.t('SUBENTRY_FORM')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('SUBENTRY_FORM') + "..."}
                                     ref={(input) => {
                                         this.subentryForm = input;
                                     }}
                                     value={item.subentry_form}
                                     onChange={(evt) => this._handleSubentryForm(evt)}
                                     onKeyUp={(evt) => { LexemeUtilities.handleKeyUp(evt, ()=> {
                                         ReactDom.findDOMNode(this.morphology).focus();
                                     }) }}
                        />
                    </InputGroup>
                </Row>
                { this.state.morphoSeparated ?
                    <h4 style={{textAlign: "left"}}><Label bsStyle={"info"}>{Dict.t("MORPHOLOGIES")}</Label></h4> : "" }
                { this.state.morphoSeparated ? this.state.item.morphologies.map(item =>
                    <Row key={"key-morphoCounter-" + morphoCounter++}>
                        <InputGroup className={"subentry-edit-group"}>
                            <InputGroup.Addon>{item.language}</InputGroup.Addon>
                            <FormControl type="text" placeholder={Dict.t('MORPHOLOGY') + "..."}
                                         ref={(input) => {
                                             this.morphology = input;
                                         }}
                                         value={item.morphology}
                                         onChange={(evt) => this._handleSeparatedMorphology(evt, item)}
                                         onKeyUp={(evt) => { LexemeUtilities.handleKeyUp(evt, ()=> {
                                             this._saveSubentryData();
                                         }) }}
                            />
                        </InputGroup>
                    </Row>
                )
                    : <Row>
                    <InputGroup className={"subentry-edit-group"}>
                        <InputGroup.Addon>{Dict.t('MORPHOLOGY')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('MORPHOLOGY') + "..."}
                                     ref={(input) => {
                                         this.morphology = input;
                                     }}
                                     value={item.morphologies[0].morphology}
                                     onChange={(evt) => this._handleMorphology(evt)}
                                     onKeyUp={(evt) => { LexemeUtilities.handleKeyUp(evt, ()=> {
                                         this._saveSubentryData();
                                     }) }}
                        />
                    </InputGroup>
                </Row>}
                <Row className={"row-bt-subentries "}>
                    <SCSubmitButton onClick={this._saveSubentryData} text={Dict.t('SAVE')} disabled={this.state.btDisabled}/>
                </Row>
            </Grid>
        </div>;
    }

}