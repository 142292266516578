window.tinymce.PluginManager.add('sccharmap', function (editor) {

    function showDialog() {
        var gridHtml, x, y, win;

        function getParentTd(elm) {
            while (elm) {
                if (elm.nodeName == 'TD') {
                    return elm;
                }

                elm = elm.parentNode;
            }
        }

        gridHtml = '<table role="presentation" cellspacing="0" class="mce-charmap"><tbody>';

        var charmap = "charmapDaRiempire";


        var width = 15;
        var height = Math.ceil(charmap.length / width);
        for (y = 0; y < height; y++) {
            gridHtml += '<tr>';

            for (x = 0; x < width; x++) {
                var index = y * width + x;
                if (index < charmap.length) {
                    var chr = charmap[index];

                    var charCode = chr;

                    /*if(chr[0].match("|")) {
                     charCode = String.fromCharCode(parseInt(chr[0].split('|')[0])) + String.fromCharCode(parseInt(chr[0].split('|')[1], 10));
                     } else
                     charCode = String.fromCharCode(parseInt(chr[0], 10));*/

                    gridHtml += '<td title=""><div tabindex="-1" title="" role="button">' +
                        charCode + '</div></td>';
                } else {
                    gridHtml += '<td />';
                }
            }

            gridHtml += '</tr>';
        }

        gridHtml += '</tbody></table>';

        var charMapPanel = {
            type: 'container',
            html: gridHtml,
            onclick: function (e) {
                var target = e.target;
                if (/^(TD|DIV)$/.test(target.nodeName)) {
                    if (getParentTd(target).firstChild) {
                        /*console.debug('target:' + target.innerText);*/
                        editor.execCommand('mceInsertContent', false, window.tinymce.trim(target.innerText || target.textContent));

                        /*if (!e.ctrlKey) {
                         win.close();
                         }*/
                    }
                }
            },
            onmouseover: function (e) {
                var td = getParentTd(e.target);

                if (td && td.firstChild) {
                    win.find('#preview').text(td.firstChild.firstChild.data);
                    win.find('#previewTitle').text(td.title);
                } else {
                    win.find('#preview').text(' ');
                    win.find('#previewTitle').text(' ');
                }
            }
        };

        win = editor.windowManager.open({
            title: "Special character",
            spacing: 10,
            padding: 10,
            items: [
                charMapPanel,
                {
                    type: 'container',
                    layout: 'flex',
                    direction: 'column',
                    align: 'center',
                    spacing: 5,
                    minWidth: 160,
                    minHeight: 160,
                    items: [
                        {
                            type: 'label',
                            name: 'preview',
                            text: ' ',
                            style: 'font-size: 70px; text-align: center;left: 25px',
                            border: 1,
                            minWidth: 150,
                            minHeight: 90
                        },
                        {
                            type: 'label',
                            name: 'previewTitle',
                            text: ' ',
                            style: 'text-align: center',
                            border: 1,
                            minWidth: 150,
                            minHeight: 90
                        }
                    ]
                }
            ],
            buttons: [
                {
                    text: "Close", onclick: function () {
                    win.close();
                }
                }
            ]
        });

        document.getElementsByClassName('span.mce-abs-layout-item.mce-first')[0].css('left', '50px').css('top', '15px');
    }

    editor.addCommand('mceShowCharmap', showDialog);

    editor.addButton('sccharmap', {
        text: 'SC Special character',
        icon: false,
        tooltip: 'Special character',
        cmd: 'mceShowCharmap'
    });

    editor.addMenuItem('sccharmap', {
        icon: false,
        text: 'SC Special character',
        cmd: 'mceShowCharmap',
        context: 'insert'
    });
});
