/**
 * Created by irisi on 06/12/2017.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {Typeahead,} from 'react-bootstrap-typeahead';
import {getLexemePolyrhematics, getRootEntryId} from './../../lexeme/page/LexemePage';
import './typehead.css';


export class PolyTypeahead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            align: 'justify',
            items: [],
            monoId: this.props.monoId,
            selected: this.props.selected,
            choosePoly: Dict.t("POLYRHEMATIC") + "...",
            options: [],
            dataReceived: false
        };

        this._handleChange = this._handleChange.bind(this);
    }

    componentWillMount() {
        var polys = "";

        if (this.state.monoId == getRootEntryId()) {
            polys = getLexemePolyrhematics();
            var options = [];
            polys.map((item) => {
                options.push({id: item.ids, label: item.form, formattedText: item.html_form});
            });

            this.setState({options, dataReceived: true});
        } else {
            Dict.call('/page/getLexemePolyrhematics/' + this.state.monoId + '/').then((result) => {
                this.setState({options: result.items, dataReceived: true});
            });
        }
    }

    _handleChange(item) {
        if (item[0]) {
            this.setState({
                value: item[0].id
            });
            this.props.callbackParent(item[0]);
        } else {
            this.props.callbackParent("");
        }
    }


    _renderMenuItemChildren(option, props, index) {
        return [
            <div key="population" dangerouslySetInnerHTML={{__html: option.formattedText}}/>,
        ];
    }


    render() {

        var props = {};
        props.renderMenuItemChildren = this._renderMenuItemChildren;
        props.renderMenuItem = this._renderToken;

        var className = this.state.dataReceived ? 'typeahead-additional-show' : 'typeahead-additional-hide';

        return (
            <Typeahead
                className={className}
                {...props}
                clearButton
                autoFocus={true}
                ref={"polytypeahead"}
                align={"justify"}
                onChange={item => this._handleChange(item)}
                options={this.state.options}
                placeholder={this.state.choosePoly}
                highlightOnlyResult={true}
            />
        );
    }
}



