import React from 'react';
import Dict from 'dict-lib';
import {ToolbarTypes, getContextTypes, LexemeUtilities, LexemePageInfos} from './../../editor/LexemeHelper';
import {LexemeEditor} from './../../editor/LexemeEditor';
import {OverlayTrigger, Popover, Glyphicon} from 'react-bootstrap';
import {getLexemeOptions, getLexemeProperties, getRootEntryId} from './../../LexemePage';
import './LexemeReferences.css';
import './../../../../_css/lexeme.css';
import {LexemePageContext} from "../../LexemePageContext";

import stylesLexemeTitle from '../../../../_css/dictionary/lexemeParts/lexemeTitle.module.css';
import {LexemeSenses} from "../03_Subentries/parts/LexemeSenses";


export class LexemeReferences extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "REFERENCE",
            item: ''
        };

        this._isActive = this._isActive.bind(this);
        this._handleClick = this._handleClick.bind(this);
        this._onExit = this._onExit.bind(this);
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _isActive(item) {
        return LexemeUtilities.isActive(this, item);
    }

    _handleClick(item) {
        this.setState({
            item: LexemeUtilities.handleClick(this, item)
        });
    }

    _onExit(item) {
        if (this.state.item === item) {
            this.setState({
                item: ''
            })
        }
    }

    render() {

        const isDirect = this.props.direct;

        const prefixLabel = isDirect ? "" : "inverse_";
        const lexRefs = isDirect ? this.props.page.references : this.props.page.inverse_references;
        const printRootLanguage = LexemePageInfos.getLexemeOptions(this.context, 'print_root_language_in_title');
        const rootLangId = LexemePageInfos.getLanguageId(this.context, 'language_id');

        var referenceGroup = [];
        var referenceLabel = "";
        var referenceList = "";
        var count = 1;

        for (var key in lexRefs) {
            if (lexRefs.hasOwnProperty(key) && (lexRefs[key]).length > 0) {
                referenceLabel = Dict.t((prefixLabel + key).toUpperCase());
                referenceList = lexRefs[key].map((item) => <div className={"reference-contents lexemeReferenceEdit "}
                                                                key={"key-contatiner-" + prefixLabel + "ref-" + key + "-item-" + count++}>
                    {this.props.isPolyFromLexeme ?
                        <div className={"ref-item ref-form"} dangerouslySetInnerHTML={{__html: item.form}}/> :
                        <div className={"reference-group ref-item"}>
                            {isDirect && LexemeUtilities.isPageEditable(this) ? <div
                                className={"ref-item lexeme-reference-status " + stylesLexemeTitle['lexeme-status--' + item.status]}>&nbsp;</div> : ""}
                            <OverlayTrigger className={"reference-contents"} delay={8000}
                                            key={"key-" + prefixLabel + "ref-" + key + "-item-" + count++}
                                            rootClose={false}
                                            trigger={"click"}
                                            onExit={() => this._onExit(item)}
                                            placement="right"
                                            container={document.getElementById("modal-poly-edit")}
                                            overlay={this._createButtonBarTooltip()}>
                                <div
                                    className={"ref-item ref-form " + LexemeUtilities.getLexClass(this) + " " + this._isActive(item)}
                                    onClick={() => {
                                        this._handleClick(item)
                                    }} dangerouslySetInnerHTML={{__html: item.form}}/>
                            </OverlayTrigger></div>}
                    {item.variants[0].language_id !== rootLangId || printRootLanguage ?
                        <div className={"ref-item ref-langs"}>{item.variants.map((refItemVariant) => <div
                            className={"ref-item ref-lang"}
                            key={"key-" + prefixLabel + "ref-item-var-" + refItemVariant.id}>{refItemVariant.language}</div>)}</div> : ""
                    }
                </div>);
                referenceGroup.push(<div className={"lexeme-reference"}
                                         key={"key-lexeme-" + prefixLabel + "references-" + key + "-group" + LexemePageInfos.getRootEntryId(this.context)}>
                    <div key={"key-lexeme-" + prefixLabel + "references-" + key + "-label" + LexemePageInfos.getRootEntryId(this.context)}
                         className={"lexeme-reference-label"}>{referenceLabel + ":"}</div>
                    <div key={"key-lexeme-" + prefixLabel + "references-" + key + "-list" + LexemePageInfos.getRootEntryId(this.context)}
                         className={"lexeme-reference-list"}>{referenceList}</div>
                </div>)
            }
        }

        return <div key={"key-lexeme-" + prefixLabel + "references-" + LexemePageInfos.getRootEntryId(this.context)}
                    className={"lexeme-" + prefixLabel + "references"}>
            {referenceGroup}
        </div>;
    }
}

LexemeReferences.contextType = LexemePageContext;
