import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {
    Navbar,
    NavDropdown,
    Nav,
    NavItem,
    MenuItem,
    Glyphicon,
    Dropdown,
    Button,
    ButtonToolbar,
    Modal
} from 'react-bootstrap';
import axios from 'axios';
import {AdministrationUtilities} from './../administration/AdministrationHelper';
import {CreateLexeme} from "../lexeme/CreateLexeme";
import {CreatePhrase} from "../lexeme/page/editor/phrases/CreatePhrase";
import {CommonNavToggleDropdown, NavToggleDropdown} from "../dict-components/navbar/SCNavbar";

import './../dict-components/navbar/ScNavbar.css';
import stylesScNavbar from './../dict-components/navbar/ScNavbar.module.css';
import {DictionarySettings, Notifications} from "../utilities/DictUtilities";
import FontAwesome from "react-fontawesome";
import {EditLanguage} from "../lexeme/languageSettings/EditLanguage";
import Tooltip from "react-bootstrap/es/Tooltip";
import ReactTooltip from "react-tooltip";
import {EditCgrs} from "../lexeme/languageSettings/EditCgrs";

import BUtils from './../utilities/BaseUtilities';
import {LexemeGenerator} from "./admin/LexemeGenerator";
import {LexemeErrorPages} from "./admin/LexemeErrorPages";
import {BackOfficeSearch} from "./backOfficeSearch/BackOfficeSearch";
import {SCImport} from "../administration/tools/import/SCImport";
import {AdministrationPage} from "../administration/AdministrationPage";
import {MorphoRuleSchema} from "../lexeme/languageSettings/morphoRules/MorphoRuleSchema";

const dictNavbarCollections = {
    language: [{name: 'GENERAL_DATA'}, {
        name: 'ALPHABET'
    }],
    cgr: [{name: 'CGR_LIST_EDIT'}],
    mainCreateableItems: ['LEXEME', 'PHRASE'],
    toponomasticItems: ['TOPONYM'],
    backOfficeSearchItems: {}
};


export class DictNavbarMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            this._openMorphoRuleSchema(13);
        }, 500);
    }

    _openEditLanguageMenuModal(data, language, editType) {
        ReactDOM.render(<ModalEditLanguage operation={data} editType={editType}
                                           language={language}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openMorphoRuleSchema(languageId) {
        ReactDOM.render(<MorphoRuleSchema show={true} languageId={languageId} />, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openCreate(entity, language) {
        if (entity === 'LEXEME')
            this._openCreateEntry(language);
        else if (entity === 'PHRASE')
            this._openCreatePhrase(language);
        else if (entity === 'TOPONYM')
            this._openCreateToponym(language);
    }

    _openCreateEntry(language) {
        ReactDOM.render(<CreateLexeme show={true} discriminator={'ENTRY'}
                                      language={language}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openCreatePhrase(language) {
        ReactDOM.render(<CreatePhrase show={true}
                                      language={language}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openCreateToponym(language) {
        ReactDOM.render(<CreateLexeme show={true} discriminator={'TOPONYM'}
                                      language={language}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openStaticPages() {
        ReactDOM.render(<LexemeGenerator show={true}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openImportDictionary() {
        ReactDOM.render(<SCImport show={true}
                                  type={this.props.module.code}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openErrorPages() {
        ReactDOM.render(<LexemeErrorPages show={true}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _openBackOfficeSearchModal(type) {
        ReactDOM.render(<BackOfficeSearch type={'Toponym'}
                                          show={true}/>, document.getElementById("dict-modal-container"));
        document.getElementsByTagName("body")[0].click();
    }

    _retrieveCreateItems(language) {
        var collectionItems = dictNavbarCollections.mainCreateableItems;
        if (DictionarySettings.useToponomastic())
            collectionItems = collectionItems.concat(dictNavbarCollections.toponomasticItems);

        return collectionItems.map((item) => {
            return <MenuItem key={'key-create-' + item.toLowerCase() + '-lang-' + language.id}
                             onClick={() => {
                                 this._openCreate(item, language)
                             }}>{Dict.t(item)}</MenuItem>
        });
    }

    render() {

        const rootLanguages = DictionarySettings.getRootLanguages();

        return <>
            <MenuItem href={AdministrationUtilities.getModulePathByCode('DICTIONARY')}>
                <Glyphicon glyph={"search"}/>{Dict.t('SEARCH')}
            </MenuItem>
            <NavToggleDropdown id="languages-dropdown" bsClass="nestedNavDropdown dropdown"
                               title={<span className={stylesScNavbar['nav-span-title']}><FontAwesome
                                   name={'language'}/>{Dict.t('LANGUAGE')}</span>}
                               pullRight={true}>
                {rootLanguages.map(item => {
                    return <NavToggleDropdown id="language-dropdown" bsClass="nestedNavDropdown dropdown"
                                              title={<span
                                                  className={stylesScNavbar['nav-span-title']}>{item.label}</span>}
                                              pullRight={true}>
                        <NavToggleDropdown id="general-data-dropdown" bsClass="nestedNavDropdown dropdown"
                                           title={<span className={stylesScNavbar['nav-span-title']}><Glyphicon
                                               glyph={"edit"}/>{Dict.t('EDIT')}</span>}
                                           pullRight={true}>
                            {dictNavbarCollections.language.map((dataType) => {
                                return <MenuItem key={'key-general-data-' + dataType.name + '-' + item.id}
                                                 onClick={() => this._openEditLanguageMenuModal(dataType.name, item, 'language')}>{Dict.t(dataType.name)}</MenuItem>
                            })}
                        </NavToggleDropdown>
                        <NavToggleDropdown id="create-item-dropdown" bsClass="nestedNavDropdown dropdown"
                                           title={<span className={stylesScNavbar['nav-span-title']}><Glyphicon
                                               glyph={"plus"}/>{Dict.t('CREATE')}</span>}
                                           pullRight={true}>
                            {this._retrieveCreateItems(item)}
                        </NavToggleDropdown>
                        <NavToggleDropdown id="general-data-dropdown" bsClass="nestedNavDropdown dropdown"
                                           title={<span className={stylesScNavbar['nav-span-title']}><Glyphicon
                                               glyph={"list-alt"}/>{Dict.t('GRAMMATICAL_CATEGORIES_ABBR')}</span>}
                                           pullRight={true}>
                            {dictNavbarCollections.cgr.map((dataType) => {
                                return <MenuItem key={'key-general-data-' + dataType.name + '-' + item.id}
                                                 onClick={() => this._openEditLanguageMenuModal(dataType.name, item, 'cgr')}>{Dict.t(dataType.name)}</MenuItem>
                            })}
                        </NavToggleDropdown>
                        <NavToggleDropdown id="general-data-dropdown" bsClass="nestedNavDropdown dropdown"
                                           title={<span className={stylesScNavbar['nav-span-title']}><Glyphicon
                                               glyph={"education"}/>{Dict.t('MORPHO_RULES')}</span>}
                                           pullRight={true}>
                            <MenuItem key={'key-schema-morpho-rule-' + item.id}
                                      onClick={() => {
                                          this._openMorphoRuleSchema(item.id)
                                      }}>{Dict.t('SCHEMA')}</MenuItem>
                        </NavToggleDropdown>
                    </NavToggleDropdown>
                })}
            </NavToggleDropdown>
            <NavToggleDropdown id="tools-dropdown" bsClass="nestedNavDropdown dropdown"
                               title={<span className={stylesScNavbar['nav-span-title']}><FontAwesome
                                   name={'briefcase'}/>{Dict.t('TOOLS')}</span>}
                               pullRight={true}>
                <MenuItem key={'key-tool-make-static-pages'}
                          onClick={() => this._openStaticPages()}>{Dict.t('PAGES_GENERATOR')}</MenuItem>
                <MenuItem key={'key-tool-make-static-pages'}
                          onClick={() => this._openImportDictionary()}>{Dict.t('IMPORT_DICTIONARY')}</MenuItem>
                <NavToggleDropdown id="tools-dropdown" bsClass="nestedNavDropdown dropdown"
                                   title={<span className={stylesScNavbar['nav-span-title']}><Glyphicon
                                       glyph={'book'}/>{Dict.t('PAGES_ANALYSIS')}</span>}
                                   pullRight={true}>
                    <MenuItem key={'key-tool-make-static-pages'}
                              onClick={() => this._openErrorPages()}>{Dict.t('ERROR_PAGES')}</MenuItem>
                </NavToggleDropdown>
            </NavToggleDropdown>
            <NavToggleDropdown id="languages-dropdown" bsClass="nestedNavDropdown dropdown"
                               title={<span className={stylesScNavbar['nav-span-title']}><FontAwesome
                                   name={'map-signs'}/>{Dict.t('TOPONOMASTIC')}</span>}>
                <MenuItem key={'key-tool-make-static-pages'}
                          onClick={() => this._openBackOfficeSearchModal('Toponym')}>{Dict.t('SEARCH')}</MenuItem>
            </NavToggleDropdown>
        </>;
    }
}

export class ModalEditLanguage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            operation: this.props.operation,
            editType: this.props.editType,
            collection: [],
            items: [],
            className: '',
            changedData: {},
            selectedId: -1, // id selezionato per la cancellazione (forse non serve più, verificare)
            saveStatus: {btn: 'primary', message: ''},
            savedData: false,// una volta fatto almeno un salvataggio è messo a true. In chiusura la pagina viene ricaricata. Per questo NON DEVE MAI ESSERE RESETTATO.
            savedInfos: {}, // dati aggiuntivi dopo un salvataggio. Si resetta dopo selezione di un item (o del salvataggio)
            somethingChanges: false, // attiva i pulsante "aggiorna". Si resetta dopo selezione di un item (o del salvataggio)
            create: false,
            reset: false,// chiamato per mettere id e orderfactor a -1 e attivare la creazione di un nuovo oggetto. Si resetta dopo selezione di un item (o del salvataggio)
            openWarning: false
        }

        this._close = this._close.bind(this);
        this._create = this._create.bind(this);
        this._remove = this._remove.bind(this);
        this._confirmDelete = this._confirmDelete.bind(this);
        this._rejectDelete = this._rejectDelete.bind(this);
        this._saveChanges = this._saveChanges.bind(this);
        this._reset = this._reset.bind(this);
        this._changeData = this._changeData.bind(this)
        this._launchButtonResult = this._launchButtonResult.bind(this);
        this._performGetData = this._performGetData.bind(this);
    }

    componentWillMount() {
        this._performGetData(this.props.operation, this.props.editType);
    }

    _reset() {
        var reset = {
            changeData: {},
            className: "",
            openWarning: false,
            saveData: false,
            savedInfos: {},
            selectedId: -1,
            somethingChanges: false,
            reset: true
        };
        this.setState({...reset}, () => {
            this.setState({reset: false});
        });
    }

    _performGetData(operation, editType) {
        if (editType === 'language') {
            Dict.call('/language/getLanguageData/' + this.props.language.id + '/' + operation + '/').then(data => {
                //const changedData = data.dataObject;
                const className = data.className;
                this.setState({operation, data, className}, () => {
                    if (this.state.show === false) {
                        this.setState({show: true, dataLoaded: true});
                    }
                });
            });
        } else if (editType === 'cgr') {
            Dict.call('/cgr/getCgrsList/' + this.props.language.id + '/' + 'true' + '/' + operation + '/').then(data => {
                //const changedData = {items: data};
                const className = data.className;
                const collection = dictNavbarCollections[editType];
                const items = data.items;
                this.setState({operation, className, /*changedData,*/ collection, items}, () => {
                    if (this.state.show === false) {
                        this.setState({show: true, dataLoaded: true});
                    }
                });
            });
        }
    }

    _buildShiftButtons(buttonType, collection) {
        var idButton = '';
        var idToolTip = '';
        var indexCollection = '';
        var glyph = '';
        var drawButton = true;

        const index = collection.findIndex((item) => {
            return item.name === this.state.operation;
        });

        if (buttonType === 'previous') {
            idButton = 'edit-previous-button';
            indexCollection = index - 1;
            idToolTip = 'dict-edit-previous-button';
            glyph = 'arrow-left';
            drawButton = index > 0;
        } else if (buttonType === 'next') {
            idButton = 'edit-next-button';
            indexCollection = index + parseInt(1);
            idToolTip = 'dict-edit-next-button';
            glyph = 'arrow-right';
            drawButton = index < collection.length - 1;
        }

        return drawButton ? <Button id={idButton} className={stylesScNavbar['edit-shift-button']}
                                    onClick={() => this._performGetData(collection[indexCollection].name, this.state.editType)}
                                    data-tip
                                    data-for={idToolTip}
        >
            <Glyphicon
                glyph={glyph}/>
            <ReactTooltip id={idToolTip} type='info' delayShow={350}>
                <span>{Dict.t(collection[indexCollection].name)}</span>
            </ReactTooltip>
        </Button> : ''
    }

    _saveChanges() {
        try {
            var changedData = JSON.parse(JSON.stringify(this.state.changedData));
            var id = this.props.language.id;
            var className = this.state.className + '';
            var service = 'saveChanges';

            if (typeof changedData.id !== 'undefined' && changedData.id > 0) {
                id = changedData.id;
            }

            if (typeof this.state.className === 'undefined') {
                className = changedData.className;
                delete changedData.className;
            }

            if (changedData.id < 0) {
                service = 'create';
            }

            Dict.call('/' + this.props.editType + '/' + service + '/' + id + '/' + Dict.encodeUrl(JSON.stringify(changedData)) + '/' + className + '/').then(data => {
                this._launchButtonResult(data);
            }).catch(err => {
                var data = {storeResult: 'ERROR'};
                this._launchButtonResult(data);
            });
        } catch (err) {

            var data = {storeResult: 'ERROR'};
            this._launchButtonResult(data);
        }
    }

    _confirmDelete() {

        var service = 'remove';
        var id = this.state.selectedId;
        // TODO: perchè cattura il catch ma non riceve il risultato (cosa che fa con SaveChanges)
        Dict.call('/' + this.props.editType + '/' + service + '/' + id + '/').then(data => {
            this._launchButtonResult(data);
            this._reset();
        }).catch((err) => {
            var data = {storeResult: 'ERROR'};
            this._launchButtonResult(data);
        });

    }

    _launchButtonResult(data) {
        var result = data.storeResult;
        var timeout = 2000;
        var saveStatus = {};
        var savedData = this.state.savedData;
        var savedInfos = {};
        var changedData = this.state.changedData;

        if (result === 'OK' || result === 'OK_UPDATE') {
            saveStatus.btn = 'success';
            if (Object.keys(data.additionalInformation).length > 0) {
                for (var key in data.additionalInformation) {
                    changedData[key] = data.additionalInformation[key];
                }
            }

            savedData = true;
            savedInfos = {...changedData};

            this._performGetData(this.props.operation, this.props.editType);
        } else {
            saveStatus.btn = 'danger';
            saveStatus.message = data.message.split(':')[1];
            timeout = 4000;
        }

        this.setState({saveStatus, savedData, changedData, savedInfos}, () => {
            setTimeout(() => {
                saveStatus.btn = 'primary';
                this.setState({saveStatus, somethingChanges: false});
            }, timeout);
        })
    }

    _remove() {
        this.setState({openWarning: true});
    }

    _rejectDelete() {
        this.setState({openWarning: false});
    }

    _create() {
        this.setState({create: true, somethingChanges: false, openWarning: false})/*, () => {
            this._performGetData(this.props.operation, this.props.editType)
        });*/
    }

    _close() {
        if (this.state.savedData)
            window.location.reload();
        else {
            this.setState({show: false}, function () {
                setTimeout(function () {
                    ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
                }, 200);
            });
        }
    }


    _changeData(changedData) {
        this.setState({changedData, somethingChanges: true});
    }


    render() {

        var component = null;
        const className = this.state.className;

        const language = this.props.language;
        const editType = this.props.editType;
        const operation = this.props.operation;

        if (this.state.dataLoaded) {
            if (editType === 'language') {
                component = <EditLanguage key={'key-' + operation} language={language} data={this.state.data}
                                          callBackChangeData={(changedData) => this._changeData(changedData)}/>;
            } else if (editType === 'cgr') {
                component = <EditCgrs key={'key-' + operation /*+ '-' + this.state.create*/} language={language}
                                      items={this.state.items}
                                      forQuotation={true}
                                      create={this.state.create}
                                      className={className}
                                      reset={this.state.reset}
                                      selectedId={this.state.selectedId}
                                      savedData={this.state.savedData}
                                      savedInfos={this.state.savedInfos}
                                      callBackChangeData={(changedData) => this._changeData(changedData)}
                                      callBackChangeCgr={(selectedId) => this.setState({
                                          selectedId,
                                          /*somethingChanges: false,*/
                                          openWarning: false,
                                          savedInfos: {},
                                          create: false
                                      })}
                />;
            }
        }

        const Previous = this._buildShiftButtons('previous', this.state.collection);
        const Next = this._buildShiftButtons('next', this.state.collection);

        return <Modal id={"modal-edit-language"}
                      dialogClassName={stylesScNavbar['dialog-modal-' + editType + '-' + operation]}
                      show={this.state.show} onHide={this._close}
                      restoreFocus>
            <Modal.Header className={stylesScNavbar['search-modal-navbar']}>
                <Modal.Title><span
                    style={{fontWeight: 'bold'}}>{language.label.toUpperCase()}</span>{' / ' + Dict.t(operation)}
                </Modal.Title>
                {Previous}
                {Next}
            </Modal.Header>
            <Modal.Body
                className={stylesScNavbar['search-modal-body-' + editType]}>{component === null ? '' : component}</Modal.Body>
            <Modal.Footer>
                {this.state.saveStatus.btn === 'danger' ? <span>
                    <Glyphicon glyph={'alert'} data-tip
                               data-for={'saveErrorTooltip'} className={stylesScNavbar['glyph-saveErrorTooltip']}/>
                    <ReactTooltip id={'saveErrorTooltip'} type='error' delayShow={20}>
                         <span>{this.state.saveStatus.message}</span>
                    </ReactTooltip>
                </span> : ''}
                <div
                    className={BUtils.joinClasses(stylesScNavbar['search-modal-warning'], stylesScNavbar['search-modal-warning--' + this.state.openWarning])}>
                    <div className={stylesScNavbar['search-modal-warning-msg']}>{Dict.t('ASK_REMOVE_ELEMENT')}</div>
                    <Button onClick={this._confirmDelete} bsStyle={this.state.saveStatus.btn}>{Dict.t('YES')}</Button>
                    <Button onClick={this._rejectDelete}>{Dict.t('NO')}</Button>
                </div>
                {this.state.selectedId > 0 ? <Button onClick={this._remove}>{Dict.t('REMOVE')}</Button> : ''}
                {editType !== 'language' ? <Button onClick={this._create}>{Dict.t('CREATE')}</Button> : ''}
                <Button onClick={this._close}>{Dict.t(this.state.savedData ? 'RELOAD' : 'CLOSE')}</Button>
                {this.state.somethingChanges ? <Button bsStyle={this.state.saveStatus.btn}
                                                       onClick={() => this._saveChanges()}>{Dict.t('UPDATE')}</Button> : ''}
            </Modal.Footer>
        </Modal>;
    }

}