import React from "react";
import Dict from 'dict-lib';
import {Button, ButtonGroup, ButtonToolbar, Col, Grid, Modal, Row} from 'react-bootstrap';
import ReactDOM from "react-dom";

import stylesScNavbar from './../../dict-components/navbar/ScNavbar.module.css';
import stylesSearchMenu from "../../_css/dictionary/searchMenu.module.css";
import {SCRadiobutton} from "../../dict-components/radiobutton/SCRadiobutton";
import {LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";
import {AdministrationUtilities} from "../../administration/AdministrationHelper";

export class LexemeGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
            lexemeGeneratorInfos: {status: 'RECUPERO STATUS'},
            erroneousPages: [],
            langAlphabet: {label: 'scegli una lingua', value: '', selected: [], count: ''},
            exportInfos: {status: 'STOP', percent: '', buttonName: 'AVVIA', zipFile: {name: '', path: ''}}
        };

        this.intervalPages = null;
        this.intervalErrors = null;

        this._getLexemeGeneratorInfos = this._getLexemeGeneratorInfos.bind(this);
        this._startStop = this._startStop.bind(this);
    }

    componentDidMount() {
        this._resetStatusInfo();
        this._startCheck();
    }

    _startCheck() {
        clearInterval(this.intervalPages);
        this.intervalPages = setInterval(() => {
            this._getLexemeGeneratorInfos();
        }, 600);
    }

    _stopCheck() {
        clearInterval(this.intervalPages);
    }

    _close() {
        this.setState({show: false}, () => {
            this._stopCheck();
            setTimeout(function () {
                ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
            }, 200);
        });
    }

    _startStop(toStart) {
        if (toStart) {
            Dict.call('concurrentServices/startLexemeGenerator/').then((result) => {
                this._startCheck();
            });
        } else {
            Dict.call('concurrentServices/stopLexemeGenerator/').then((result) => {
                this._stopCheck();
                setTimeout(() => {
                    this._getLexemeGeneratorInfos();
                }, 1000);
            });
        }
    }

    _resetExport() {
        var langAlphabet = {
            label: this.state.langAlphabet.label,
            id: this.state.langAlphabet.id,
            value: this.state.langAlphabet.value,
            selected: [],
            count: ''
        };
        var exportInfos = {status: 'STOP', percent: '', buttonName: 'AVVIA', filename: ''};

        this.setState({langAlphabet, exportInfos});
    }

    _startExport() {
        var exportInfos = this.state.exportInfos;
        exportInfos.status = 'START';
        exportInfos.buttonName = 'AVVIATO';
        this.setState({exportInfos}, () => {
            this._performStartExport(this.state.langAlphabet.id, this.state.langAlphabet.selected, 0, this.state.langAlphabet.count, (zipFile) => {
                exportInfos.status = 'FINISH';
                exportInfos.buttonName = 'RESET';
                exportInfos.zipFile = zipFile;
                this.setState({exportInfos});
            });
        });
    }

    _performStartExport(langId, chars, index, tot, callback) {
        Dict.call('concurrentServices/exportDictionary/' + langId + '/' + chars + '/' + index + '/' + tot + '/').then((result) => {
            var exportInfos = this.state.exportInfos;
            exportInfos.percent = result.percent + "%";
            if (result.percent === 100) {
                exportInfos.percent = 'completo - ';
                this.setState({exportInfos});
                const zipFilePath = result.zipFilePath;
                const zipFileName = result.zipFileName;
                callback({zipFilePath, zipFileName});
            } else {
                index = (parseFloat(index) + 500) + "";
                this.setState({exportInfos});
                return this._performStartExport(langId, chars, index, tot, callback);
            }
        });
    }

    _resetLexemePages() {
        this._stopCheck();
        Dict.call('concurrentServices/resetLexemePages/').then((result) => {
            this._getLexemeGeneratorInfos();
        })
    }

    _resetStatusInfo() {
        Dict.call('concurrentServices/resetStatusInfo/').then((result) => {
        })
    }

    _setLanguageAlphabet() {
        Dict.call('language/getLanguageData/' + this.state.langAlphabet.id + '/ALPHABET/').then((result) => {
            var langAlphabet = this.state.langAlphabet;
            langAlphabet.value = result.dataObject.alphabet;
            langAlphabet.selected = [];
            this.setState({langAlphabet});
        });
    }

    _setLanguageAlphabetCharToExport(char) {
        var langAlphabet = this.state.langAlphabet;
        var selected = langAlphabet.selected;
        var index = selected.indexOf(char);

        if (char === 'ALL') {
            if (selected.length > 0)
                langAlphabet.selected = [];
            else {
                langAlphabet.selected = (langAlphabet.value.split('-'));
            }
        } else {
            if (index === -1) {
                langAlphabet.selected.push(char);
            } else {
                langAlphabet.selected.splice(index, 1);
            }
        }

        langAlphabet.selected.sort();

        if (langAlphabet.selected.length > 0) {
            Dict.call('concurrentServices/countExportDictionary/' + langAlphabet.id + '/' + langAlphabet.selected + '/').then((result) => {
                langAlphabet.count = result;
                this.setState({langAlphabet});
            });
        } else {
            langAlphabet.count = 0
            this.setState({langAlphabet});
        }
    }

    _getLexemeGeneratorInfos() {
        Dict.call('concurrentServices/getLexemeGeneratorInfos/').then((result) => {
            const lexemeGeneratorInfos = result.lexemeGeneratorInfos;
            this.setState({lexemeGeneratorInfos});
            if (lexemeGeneratorInfos.status === 'STOP') {
                clearInterval(this.intervalPages);
            }
        }).catch(error => {
            const lexemeGeneratorInfos = {status: 'Il servizio non sembra attivo'};
            this.setState({lexemeGeneratorInfos});
        });
    }

    render() {
        const infos = this.state.lexemeGeneratorInfos;
        const erroneousPages = this.state.erroneousPages;
        const isStop = infos.status === 'STOP';
        const entriesToProcess = infos.totEntries - infos.entriesComplete;
        const isFinished = infos.status === 'COMPLETE' || entriesToProcess === 0;
        const retrievingData = infos.status === 'RECUPERO STATUS';
        const serviceNotActive = infos.status === 'Il servizio non sembra attivo';

        var exportButtons = '';
        if (Object.keys(this.state.langAlphabet.value).length > 0) {
            var allButton = [<Button onClick={() => {
                this._setLanguageAlphabetCharToExport('ALL');
            }
            }>{Dict.t('COMPLETE_EXPORT')}</Button>];
            exportButtons = this.state.langAlphabet.value.split('-').map((char) => {
                const style = this.state.langAlphabet.selected.indexOf(char) >= 0 ? 'success' : 'default';
                return <Button bsStyle={style} onClick={() => {
                    this._setLanguageAlphabetCharToExport(char);
                }
                }>{char.toUpperCase()}</Button>;
            });

            exportButtons = allButton.concat(exportButtons);
        }


        return <Modal show={this.state.show} dialogClassName={stylesScNavbar['lexeme-pages-generator-dialog-modal']}
                      restoreFocus onHide={() => this._close()}>
            <Modal.Header closeButton><h4 className={'modal-title'}>Generazione dei lessemi</h4></Modal.Header>
            <Modal.Body>
                <Row>
                    <ButtonToolbar>
                        <ButtonGroup className={stylesScNavbar['lexeme-pages-generator-buttongroup-modal']}>
                            <Button onClick={() => this._startStop(isStop)}
                                    style={{visibility: (isFinished || retrievingData || serviceNotActive ? 'hidden' : 'visible')}}>{isStop ? 'avvia staticizzazione' : 'ferma staticizzazione'}</Button>
                            <Button onClick={() => this._resetLexemePages()}>resetta tutti i lessemi</Button>
                            <Button>resetta </Button>
                            <Button>setta tutti uptodate</Button>
                            <Button>apri esportazione</Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Row>
                <Grid>
                    <Row
                        className={stylesScNavbar['lexeme-pages-generator-status-modal']}>Stato: <strong>{infos.status + (isStop && entriesToProcess === 0 ? '/COMPLETE' : '')}</strong></Row>
                    {Object.keys(infos).length > 0 && !retrievingData && !serviceNotActive ?
                        <Row className={stylesScNavbar['lexeme-pages-generator-row-cols-modal']}>
                            <Col lg={3}
                                 md={3}>{!isFinished ? 'Lemmi da processare: ' : 'Lemmi totali:  '}<strong>{(!isFinished ? entriesToProcess + '/' : '') + infos.totEntries}</strong>
                            </Col>
                            <Col lg={9} md={9} style={{visibility: (!isStop ? 'visible' : 'hidden')}}>Lemma
                                corrente: <strong>{typeof infos.currentLexemePage === 'undefined' ? '' : infos.currentLexemePage.label}</strong>
                            </Col>
                        </Row> : ''}
                    <Row className={stylesScNavbar['lexeme-pages-generator-export-row-cols-modal']}>
                        <div className={stylesScNavbar['lexeme-pages-generator-export-row-cols-title']}>Esportazione
                            lessemi
                        </div>
                        <SCRadiobutton key={"key-radio-languages"} bsStyle="primary"
                                       id={'dict-languages'}
                                       items={Dict.getVarious('root_languages').filter((langItem) => {
                                           return langItem.is_active === true;
                                       }).map((item) => {
                                           return {label: item.label, code: item.id + ''};
                                       })}
                                       additionalClass={stylesSearchMenu['dict-languages-radio']}
                                       default={[]}
                                       callbackParent={(newState) => {
                                           var langAlphabet = this.state.langAlphabet;
                                           langAlphabet.id = newState;
                                           this.setState({langAlphabet}, () => {
                                               this._setLanguageAlphabet();
                                           })
                                       }}/>
                        <div className={stylesScNavbar['lexeme-pages-generator-export-row-chars']}>
                            {typeof this.state.langAlphabet.id === 'undefined' ? this.state.langAlphabet.label : exportButtons}
                        </div>
                        <div className={stylesScNavbar['lexeme-pages-generator-export-row-chars-start']}>
                            {typeof this.state.langAlphabet.id === 'undefined' || this.state.exportInfos.status === 'START' ? '' :
                                <Button onClick={() => {
                                    var status = this.state.exportInfos.status;
                                    if (status === 'FINISH')
                                        this._resetExport();
                                    else
                                        this._startExport();
                                }}>{this.state.exportInfos.buttonName}</Button>}
                            {this.state.exportInfos.status === 'FINISH' ? <Button bsSize="large"
                                    onClick={() => {
                                        const zipFile = this.state.exportInfos.zipFile;
                                        LexemeUtilities.downloadTmpFile(AdministrationUtilities.encodeOSPath(zipFile.zipFileName));
                                    }
                                    }>{Dict.t('DOWNLOAD')}</Button> : ''}
                        </div>
                        <div className={stylesScNavbar['lexeme-pages-generator-export-row-chars-infos']}>
                            {typeof this.state.langAlphabet.id === 'undefined' ? '' : this.state.exportInfos.percent + ' lettere: ' + this.state.langAlphabet.selected.join(('-'))}
                        </div>
                        {this.state.exportInfos.status === 'FINISH' ? '' :
                            <div className={stylesScNavbar['lexeme-pages-generator-export-row-chars-infos']}>
                                {typeof this.state.langAlphabet.id === 'undefined' ? '' : 'tot:' + this.state.langAlphabet.count}
                            </div>}
                    </Row>
                </Grid>
            </Modal.Body>
            {Object.keys(infos).length > 0 && !retrievingData && !serviceNotActive ?
                <Modal.Footer>Errori trovati: <strong>{infos.errors}</strong></Modal.Footer> : ''}
        </Modal>;
    }
}