import React from 'react';
import ReactDom from 'react-dom';
import Dict from 'dict-lib';

import stylesSCAreaNotes from './SCAreaNotes.module.css';
import {DictionarySettings} from "../../utilities/DictUtilities";
import SCRadiobutton from "../radiobutton/SCRadiobutton";
import {ControlLabel, FormControl, FormGroup, Grid, Row,} from "react-bootstrap";

export class SCAreaNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choosenLanguage: '',
            languages: []
        };

        this.languages = DictionarySettings.getRootLanguages();
    }

    componentDidMount() {
        var choosenLanguage = this.languages[0];
        var languageAreaNote = {};
        for (const language of this.languages) {
            const hasDescr = typeof this.props.languageAreaNote !== 'undefined' && typeof this.props.languageAreaNote[language.id] !== 'undefined';
            languageAreaNote[language.id] = hasDescr ? this.props.languageAreaNote[language.id] : '';
        }

        this.setState({languageAreaNote, choosenLanguage});
    }

    render() {

        var text = this.state.choosenLanguage !== '' ? this.state.languageAreaNote[this.state.choosenLanguage.id] : '';

        return <Grid fluid>
            <Row>
                <SCRadiobutton items={this.languages} callbackParent={(value) => {
                    var choosenLanguage = this.languages.find((item) => {
                        return item.code === value;
                    });

                    this.setState({choosenLanguage}, () => {

                    });
                }}/>
            </Row>
            <Row>
                <FormGroup controlId="formControlsTextarea"
                           key={'key-scareanotes-' + this.props.name + '-' + this.state.choosenLanguage.code}>
                    <FormControl componentClass="textarea" placeholder="textarea"
                                 onChange={(evt) => {
                                     var languageAreaNote = this.state.languageAreaNote;
                                     var id = this.state.choosenLanguage.id;
                                     languageAreaNote[id] = evt.target.value;
                                     this.setState({languageAreaNote});
                                     this.props.callbackParent(languageAreaNote);
                                 }}
                    >{text}</FormControl>
                </FormGroup>
            </Row>
        </Grid>
    }

}