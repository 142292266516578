import React from 'react';
import {Grid, Col} from 'react-bootstrap';
import Dict from 'dict-lib';
import {SCCombo, EntryTypeahead} from './../components';
import './SCCombo.css';
import './SubEntryCombo.css';

export class SubEntryCombo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rootEntryId: "",
            subEntryId: "",
            optionsSubentry: [],
            optionsSense: [],
            checkAncestorSenses: (typeof this.props.checkAncestorSenses !== 'undefined' ? this.props.checkAncestorSenses : false),
            showSense: (typeof this.props.showSense !== 'undefined' ? this.props.showSense : true),
            semanticUnitId: this.props.semanticUnitId,
            noSubentryFound: false,
            noSenseFound: false
        };

        this._setRootEntryId = this._setRootEntryId.bind(this);
        this._setSubentry = this._setSubentry.bind(this);
        this._setSense = this._setSense.bind(this);
        this._handleOnFocus = this._handleOnFocus.bind(this);
    }

    _setRootEntryId(newState) {
        if (newState.length !== 0) {
            var service = (typeof this.props.onlyRoot == 'undefined') || (!this.props.onlyRoot) ? 'getSubentries' : 'getRootSubentries';
            Dict.call('service/' + service + '/' + newState.id).then(result => {
                this.setState({rootEntryId: newState.id, optionsSubentry: result, subEntryId: result[0]}, function () {
                    if (Object.keys(result).length > 0) {
                        this.setState({noSubentryFound: false});
                        this._setSubentry(result[0].value);
                    } else {
                        this.setState({noSubentryFound: true});
                        this.props.callbackParent(newState, "");
                    }
                }.bind(this));
            });
        } else {
            this.setState({rootEntryId: "", subEntryId: ""});
            this.props.callbackParent("");
        }
    }

    _setSubentry(newState) {
        var params = newState;
        if (this.state.checkAncestorSenses && typeof this.state.semanticUnitId !== 'undefined')
            params += "/" + this.state.semanticUnitId + "/" + this.state.checkAncestorSenses;

        if (this.state.showSense) {
            var service = (typeof this.props.onlyRoot == 'undefined') || (!this.props.onlyRoot) ? 'getSenses' : 'getRootSenses';
            Dict.call('service/' + service + '/' + params).then(result => {
                this.setState({optionsSense: []});
                this.setState({subEntryId: newState, optionsSense: result});
                if (result.length > 0)
                    this._setSense(result[0].value);
                else
                    this.setState({noSenseFound: true});

            });
        } else {
            this.props.callbackParent(this.state.rootEntryId, newState);
        }
    }

    _setSense(newState) {
        this.setState({noSenseFound: false});
        this.props.callbackParent(newState);
    }

    _handleOnFocus() {
        if (this.state.rootEntryId.length > 0)
            this.setState({rootEntryId: "", subEntryId: ""});
    }


    render() {

        const dim = this.state.showSense ? 4 : 6;
        const legend = this.state.showSense ? "sense selector" : "c.gr. selector";

        return (
            <Grid className={"container-subentry-combo"} fluid>
                <fieldset>
                    <legend>{legend}</legend>
                    <Col lg={dim} md={dim}>
                        <EntryTypeahead languageId={this.props.languageId} onFocus={this._handleOnFocus} forceFocus={this.props.forceFocus}
                                        callbackParent={(newState) => this._setRootEntryId(newState)}/>
                    </Col>
                    <Col lg={dim} md={dim}>
                        {typeof this.state.rootEntryId !== 'undefined' && this.state.rootEntryId > 0 && this.state.optionsSubentry.length > 0 ?
                            <SCCombo options={this.state.optionsSubentry}
                                     callbackParent={(newState) => this._setSubentry(newState)}/> : (this.state.noSubentryFound ? Dict.t("NO_SUBENTRY_FOUND") : "")}
                    </Col>
                    {this.state.showSense ?
                        <Col lg={4} md={4}>
                            {typeof this.state.subEntryId !== 'undefined' && this.state.subEntryId.length > 0 && this.state.optionsSense.length > 0 && !this.state.noSenseFound ?
                                <SCCombo options={this.state.optionsSense} onlyRoot={this.props.onlyRoot}
                                         callbackParent={(newState) => this._setSense(newState)}/> : (this.state.noSenseFound ? Dict.t("NO_SENSE_FOUND") : "")}
                        </Col>
                        : ""}
                </fieldset>
            </Grid>

        );
    }
}




