import React from 'react';
import {Modal} from 'react-bootstrap';
import './SCModalTopSpinner.css';
import scLogo from '../../../../public/img/scLogo.svg';

export class SCModalTopSpinner extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {

        return <Modal id={"top-spinner"} className={"loadSpinner " + (typeof this.props.additionalClass !== 'undefined' && (this.props.additionalClass + "").length > 0 ? this.props.additionalClass : "") } show={this.props.show} restoreFocus>
            <Modal.Body><img className="sc-logo" src={scLogo}/>
                <span id={"top-spinner-text"} className="spinner-text">{this.props.msg}</span>
            </Modal.Body>
        </Modal>;
    }

}
