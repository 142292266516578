import React from 'react';
import {getLexemeOptions} from './../../LexemePage';
import {OverlayTrigger} from 'react-bootstrap';
import './LexemeSubentries.css';
import './../../../../_css/lexeme.css';
import {LexemeSenses} from './parts/LexemeSenses';
import {LexemeUtilities, getContextTypes, LexemePageInfos} from "../../editor/LexemeHelper";
import './../../editor/LexemeEditor.css';
import {LexemePageContext} from "../../LexemePageContext";

import stylesSubentries from './../../../../_css/dictionary/lexemeParts/lexemeSubentries.module.css';
import BUtils from './../../../../utilities/BaseUtilities';


export class LexemeSubentries extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "SUBENTRY",
            item: {}, /*{showOverlay: true}*/
            useSortable: false
        };

        this.morphoOpen = "";
        this.morphoClose = "";

        this._isActive = this._isActive.bind(this);
        this._handleClick = this._handleClick.bind(this);
        this._onExit = this._onExit.bind(this);
    }

    componentWillMount() {
        var morphoContainer = LexemePageInfos.getLexemeOptions(this.context, "morpho_container");

        if (typeof morphoContainer !== 'undefined' && morphoContainer.length === 2) {
            this.morphoOpen = morphoContainer[0];
            this.morphoClose = morphoContainer[1];
        }
    }

    _isActive(item) {
        return LexemeUtilities.isActive(this, item);
        /*if (item.ids == this.state.item.ids)
         return LexemeUtilities.getLexClass(this) + "Active";
         else
         return "";*/
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _handleClick(item, isPolyFromLexeme) {
        if (!isPolyFromLexeme)
            this.setState({
                item: LexemeUtilities.handleClick(this, item)
            });
    }

    _onExiting() {
        document.getElementById("lex-subentry-button-bar").style.display = "none";
    }

    _onEntering() {
        document.getElementById("lex-subentry-button-bar").style.display = "block";
    }

    _onExit(item) {
        if (this.state.item === item) {
            this.setState({
                item: ''
            })
        }
    }

    _separatedHasMorpho(item) {
        return item.morphologies.filter(function (itemMorpho) {
            return itemMorpho.morphology.length > 0;
        }).length > 0
    }

    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextContext.pageStatus === 'EDITABLE' || nextContext.pageStatus === 'TRANSLATION_SELECTOR';
    }*/


    render() {
        const morphoSeparated = LexemePageInfos.getLexemeOptions(this.context, 'morphology_separated');
        const isPolyFromLexeme = this.props.isPolyFromLexeme;
        const isPolyEdit = typeof this.props.isPolyEdit !== 'undefined' && this.props.isPolyEdit;
        const lexSubentries = this.props.page.subentries;
        var additionalMainClass = isPolyFromLexeme ? "polySubFromLexeme" : "monoSubentries";
        var additionalClass = isPolyFromLexeme ? "polySubFromLexeme" : "";

        const showCgr = typeof isPolyFromLexeme === "undefined" || !isPolyFromLexeme || LexemePageInfos.getLexemeOptions(this.context, "show_cgr_poly_in_preview");

        var keyCounter = 1;

        var subentryList = lexSubentries.map((item) => {
                var isActive = this._isActive(item);
                var isActiveClass = BUtils.joinClasses(isActive, stylesSubentries[isActive]);

                return <div
                    className={BUtils.joinClasses(stylesSubentries[(isPolyFromLexeme ? "lexemePolySubentry" : "lexemeSubentry")], additionalClass)}
                    key={"subentry-" + item.ids} data-ids={item.ids}>
                    <div id={"div-subentry-" + item.ids}
                         className={stylesSubentries['lexemeSubentryEdit']}>
                        {isPolyFromLexeme ? <div
                                className={BUtils.joinClasses('lexHighlightable', stylesSubentries[isPolyFromLexeme ? 'cgrPolyDescr' : 'cgrDescr'], stylesSubentries[additionalClass], (showCgr ? '' : stylesSubentries['hidePolySub']))}>{item.cgr.description}</div>
                            :
                            <OverlayTrigger delay={8000} key={"key-lexSubentries" + keyCounter++}
                                            rootClose={false}
                                            trigger={"click"}
                                            onExit={() => isPolyFromLexeme ? "" : this._onExit(item)}
                                            onExiting={() => this._onExiting()} onEntering={() => this._onEntering()}
                                            placement={isPolyEdit ? "top" : (LexemeUtilities.isPageEditable(this) ? "top" : "right")}
                                            container={document.getElementById("modal-poly-edit")}
                                            overlay={this._createButtonBarTooltip()}>
                                <div
                                    className={BUtils.joinClasses(stylesSubentries['lexemeSubentryEditInlineBlock'], isActiveClass, LexemeUtilities.getLexClass(this))}
                                    onClick={() => {
                                        this._handleClick(item, isPolyFromLexeme)
                                    }}>
                                    <div
                                        className={BUtils.joinClasses('lexHighlightable', stylesSubentries['cgrDescr'], stylesSubentries[additionalClass], (showCgr ? '' : stylesSubentries['hidePolySub']))}>{item.cgr.description}</div>
                                </div>
                            </OverlayTrigger>}
                        <div
                            className={BUtils.joinClasses(stylesSubentries['morphologies'], (showCgr ? '' : stylesSubentries['hidePolySub']))}>
                            {(morphoSeparated && this._separatedHasMorpho(item)) || item.morphologies[0].morphology.length > 0 ? this.morphoOpen : ""}
                            {morphoSeparated ? (item.morphologies.map((itemMorpho) => itemMorpho.morphology.length > 0 ?
                                <div key={"key-lexSubentries-morphology" + keyCounter++}
                                     className={BUtils.joinClasses(stylesSubentries['morphology'], additionalClass)}><span
                                    className="morphologyLang">{itemMorpho.language + ":"}</span>{itemMorpho.morphology}
                                </div> : "")) : <div
                                className={BUtils.joinClasses(stylesSubentries['morphology'], stylesSubentries['notSeparated'], stylesSubentries[additionalClass])}>{item.morphologies[0].morphology}</div>}
                            {(morphoSeparated && this._separatedHasMorpho(item)) || item.morphologies[0].morphology.length > 0 ? this.morphoClose : ""}
                        </div>
                        {item.subentry_form.length > 0 ?
                            <div
                                className={BUtils.joinClasses(stylesSubentries['subentryForm'], stylesSubentries[additionalClass])}>{item.subentry_form}</div> : ""}
                    </div>
                    {item.inherited_senses.length > 0 ?
                        <LexemeSenses isInherited={true} isPolyFromLexeme={this.props.isPolyFromLexeme} {...item} /> : ""}
                    <LexemeSenses key={'key-senses-' + item.ids} isInherited={false} isPolyEdit={isPolyEdit}
                                  isPolyFromLexeme={this.props.isPolyFromLexeme} {...item}
                                  polyItem={isPolyEdit ? this.props.page : undefined}
                                  callbackParent={(newState) => this.setState(this.state)}/>
                </div>
            }
        );


        return <div
            className={BUtils.joinClasses(stylesSubentries['lexemeSubentries'], stylesSubentries[additionalMainClass], stylesSubentries[additionalClass], stylesSubentries[(isPolyEdit ? " poly-subs-edit " : "")])}>
            {subentryList}
        </div>;

    }
}

LexemeSubentries.contextType = LexemePageContext;