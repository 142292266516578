import React from "react";
import Dict from 'dict-lib';
import {
    Grid,
    Row,
    Col
} from 'react-bootstrap';
import {LexemePageContext} from "../../LexemePageContext";

import error from "../../../../../public/img/error.png";
import stylesPlace from './lexemePlace.module.css';
import SCMaps from "../../../../dict-components/googleMaps/SCMaps";


export class LexemePlace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            placeFound: true,
            entityFields: null,
            mapReady: false
        }
    }

    render() {
        var contents = '';
        var typology = '';
        const place = this.props.page.place;
        const foundPlace = typeof place !== 'undefined' && place !== null && typeof place.data !== 'undefined' && place.data !== null;
        var mapData = {};
        var mapReady = this.state.mapReady;

        if (foundPlace) {
            const exclude = ['uniqueName', 'notes', 'ctg', 'parent', 'toponyms', 'galleryMultimedia'];
            const data = this.props.page.place.data;
            const entityFields = data.entityFields;
            typology = entityFields.placeTypology.value;
            mapData = {lat : entityFields.glatitude.value, lng: entityFields.glongitude.value};

            contents = Object.keys(entityFields).filter((field) => {
                return !exclude.includes(field);
            }).map((field) => {
                var item = entityFields[field];
                const isInvolvedEntities = item.type === 'involvedEntities';
                const label = isInvolvedEntities ? item.label : Dict.t(item.label);

                if (typeof item.value === 'object') {
                    item.value = isInvolvedEntities ? item.value.map(item => item.label).join(', ') : item.value.label;
                }

                return <Row className={stylesPlace['entityFields__row']}>
                    <div className={stylesPlace['entityFields__col-label']}>{label}:</div>
                    <div className={stylesPlace['entityFields__col-descr']}>{item.value}</div>
                </Row>;
            });

        } else {
            mapReady = true;
            contents = <>
                <img style={{height: '24px', width: '24px'}} src={error}/>
                <span>{Dict.t('ALERT_NO_PLACE_FOUND')}</span>
            </>;
        }

        return <div className={stylesPlace['lexeme-place__div--' + (mapReady ? 'ready' : 'not-ready')]}>
            <div className={stylesPlace['pathToObject__row']}>{typology}</div>
            <div className={stylesPlace['place-container']}>
                <div className={stylesPlace['place-contents__div']}>
                    <div className={stylesPlace['place-contents__div-container']}>{contents}</div>
                </div>
                {foundPlace ? <div className={stylesPlace['place-map__div']}><SCMaps coord={mapData} infoMap={contents} infoMapTitle={this.props.page.title.entry_form} callbackReady={() => {
                    setTimeout( () => this.setState({mapReady : true}), 1000);
                }
                } /></div> : ''}
            </div>
        </div>;
    }
}

LexemePlace.contextType = LexemePageContext;