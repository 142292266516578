import React from 'react';
import Dict from 'dict-lib';
import {Modal, Row, Button, FormControl, InputGroup, Carousel, Glyphicon} from 'react-bootstrap';
import Grid from "react-bootstrap/es/Grid";
import {EditLanguage} from "../lexeme/languageSettings/EditLanguage";
import update from 'immutability-helper';
import BUtils from './../utilities/BaseUtilities';
import stylesDictionaryWizard from './../_css/dictionary/dictionaryWizard.module.css';
import {DictionarySettings} from "../utilities/DictUtilities";
import {SCRadiobutton} from "../dict-components/components";
import stylesSearchMenu from "../_css/dictionary/searchMenu.module.css";

export class DictionaryWizard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            accept: false,
            saved: false,
            useLanguage: 'NONE'
        }
    }

    _handleUseLang(useLanguage) {
        this.setState({useLanguage});
    }

    render() {

        var bodyContent = '';
        const rootLanguages = DictionarySettings.getRootLanguages();
        if (rootLanguages.length <= 1) {
            bodyContent = <DictionaryWizardCore
                useLanguage={this.state.useLanguage}
                rootLanguage={rootLanguages[0].label}
                accept={this.state.accept} callbackParentCloseAccept={() => {
                this.setState({accept: false})
            }}
                saved={this.state.saved} callbackParentSaved={() => {
                this.setState({saved: true})
            }}

            />
        } else {
            bodyContent = <div>Il dizionario non è vuoto ed ha più di una lingua installata</div>;
        }

        var radioButtons = '';
        if (rootLanguages.length <= 1 && !this.state.accept) {
            radioButtons =
                <div style={{marginLeft: '85px'}}><SCRadiobutton key={"key-radio-specialChars"} id="dict-logic-options"
                                                                 label={''}
                                                                 items={[{
                                                                     label: 'non usare ' + rootLanguages[0].label,
                                                                     code: 'NONE'
                                                                 }, {
                                                                     label: 'usare ' + rootLanguages[0].label + ' come lingua in',
                                                                     code: 'USE_AS_LANG_IN'
                                                                 }, {
                                                                     label: 'usare ' + rootLanguages[0].label + ' come lingua out',
                                                                     code: 'USE_AS_LANG_OUT'
                                                                 }]}
                                                                 default={this.state.useLanguage}
                                                                 callbackParent={(newState) => this._handleUseLang(newState)}/>
                </div>
        }


        return <Modal id={"dictionary-wizard"} className={stylesDictionaryWizard['dictionary-wizard__modal']}
                      dialogClassName={BUtils.joinClasses(stylesDictionaryWizard['dictionary-wizard__modal-dialog'], stylesDictionaryWizard['dictionary-wizard__modal-dialog-saved--' + this.state.saved])}
                      show={this.state.show}
                      restoreFocus>
            <Modal.Header>
                <Modal.Title>wizard per la creazione del dizionario</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className={BUtils.joinClasses(stylesDictionaryWizard['dictionary-wizard__modal-body'], stylesDictionaryWizard['dictionary-wizard__modal-body-saved--' + this.state.saved])}>
                {bodyContent}
                {radioButtons}
            </Modal.Body>
            <Modal.Footer>
                {this.state.saved || rootLanguages.length > 1 ? <Button onClick={() => {
                    window.location.reload();
                }}>{Dict.t('RELOAD')}</Button> : <>
                    <Button onClick={() => {
                        this.setState({show: false});
                    }}>{Dict.t('CLOSE')}</Button>
                    <Button bsStyle="primary" onClick={() => {
                        this.setState({accept: true});
                    }}>{Dict.t('ACCEPT')}</Button></>}
            </Modal.Footer>
        </Modal>;
    }
}

class DictionaryWizardCore extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                langIn: {value: 'langInVal', label: 'lingua in', carousel: 0},
                cgrIn: {value: 'cgrInVal', label: 'c.gr. in', carousel: 0},
                cgrLocIn: {value: 'cgrLocInVal', label: 'c.gr. loc. in', carousel: 0},
                langOut: {value: 'langOutVal', label: 'lingua out', carousel: 1},
                cgrOut: {value: 'cgrOutVal', label: 'c.gr. out', carousel: 1},
                cgrLocOut: {value: 'cgrLocOutVal', label: 'c.gr. loc. out', carousel: 1}
            },
            savedMessage: '',
            disabledField: 'NONE',
            cgrMultiInsert: false
        };

        this._handelText = this._handelText.bind(this);
        this._saveWizard = this._saveWizard.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof this.props.useLanguage !== 'undefined' && this.props.useLanguage !== prevProps.useLanguage) {
            if (this.props.useLanguage !== 'NONE') {
                var fields = this.state.fields;
                var field = Object.keys(fields).find((item) => {
                    return (item === 'langIn' && this.props.useLanguage.endsWith('LANG_IN')) || (item === 'langOut' && this.props.useLanguage.endsWith('LANG_OUT'));
                });

                var fieldElement = fields[field];
                var disabledField = field;
                fieldElement.value = this.props.rootLanguage;
                fields = update(fields, {$merge: {[field]: fieldElement}});

                this.setState({fields, disabledField});
            } else {
                var disabledField = 'NONE';
                this.setState({disabledField});
            }
        }
    }

    _handelText(evt, label) {
        var fields = JSON.parse(JSON.stringify(this.state.fields));
        fields[label].value = evt.target.value;

        this.setState({fields});
        if (this.props.accept === true) {
            this.props.callbackParentCloseAccept();
        }
    }

    _saveWizard() {
        var data = Object.keys(this.state.fields).map((item) => {
            return {name: item, value: this.state.fields[item].value};
        });

        data = Dict.encodeUrl(JSON.stringify(data));

        Dict.call('/admin/createDictionary/' + data + '/' + this.props.useLanguage + '/').then((result) => {
            this.props.callbackParentSaved();

            const savedMessage = <span>
                <strong>Il dizionario è stato creato!</strong>
                <br/>- La lingua in è stata impostata di default come&nbsp;
                <strong>lingua di ricerca</strong> e <strong>lingua di descrizione in/out</strong>, mentre l'italiano come&nbsp;
                <strong>lingua d'interfaccia</strong><br/>
                - Titolo e lingua d'interfaccia sono editabili in&nbsp;
                <strong>proprietà->base</strong>, mentre le opzioni globali di dizionario su <strong>proprietà->dizionario</strong><br/>
                - Per modificare le lingue di descrizione e settare le opzioni andare su <strong>opzioni->opzioni condivise</strong> oppure&nbsp;
                <strong>opzioni->"rispettiva lingua"</strong><br/>
                - Dal menu in alto a sinistra è possibile modificare ed editare le lingue appena create e creare frasi e lessemi ad esse associati
                <strong>Importazione/Creazione Lemmi</strong><br/>
                - Dal menu in alto a sinistra è possibile creare i lemmi scegliendo la lingua->crea. E' inoltre possibile eseguire
                un'importazione di massa da strumenti->importa, secondo le seguenti regole:
                        <br/>a) ogni riga deve contenere 4 colonne: nome del lemma, c.gr., definizione e  lingua
                        <br/>b) le colonne devono essere separate da un tab
                        <br/>c) c.gr. e lingua devono corrispondere esattamente con una delle lingue inserite o una c.gr. esistente (per quella lingua) altrimenti la riga verrà inserita nella lista
                        dei lemmi anomali
                - Prima di procedere con l'importazione è necessario creare, se non presenti, i due core d'indicizzazione Solr, da linea di comando posizionandosi sulla cartella /bin dell'installazione di Solr:
                        <br/>1) ./solr create -c 'nome_db'
                        <br/>2) ./solr create -c 'nome_db_error_pages'
                        <br/>3) Copiare dalla cartella 'SOLR schema' i rispettivi 'managed-schema' e copiarli nelle rispettive cartelle \server\solr\<strong>nome_core</strong>\conf
                - Ricorda infine di editare le lingue appena salvate aggiungendo i codici a due e tre lettere
                </span>;

            this.setState({savedMessage});
        })
    }

    render() {

        var Elements = [];
        var RecapInfo = '';
        var RecapDescr = [];
        var carouselCount = 1;

        Elements = Object.keys(this.state.fields).map((fieldName) => {

            var fieldObject = this.state.fields[fieldName];
            var fieldValue = fieldObject.value;
            var fieldLabel = fieldObject.label;
            var fieldCarousel = fieldObject.carousel;

            if (fieldCarousel > carouselCount) {
                carouselCount = fieldCarousel;
            }

            const isEmpty = (fieldValue + '').trim() === '';
            if (this.props.accept === true) {

                if (isEmpty) {
                    RecapInfo = <>
                        <Glyphicon glyph={'alert'}/>
                        <span
                            className={stylesDictionaryWizard['dict-wizard-recap-info__alert']}>{Dict.t('ALERT_DICT_WIZARD_EMPTY_FIELD_FOUND') + "!"}</span>
                    </>;
                } else {
                    RecapInfo = <><span
                        className={stylesDictionaryWizard['dict-wizard-recap-info__alert']}>{Dict.t('INFO_DICT_WIZARD_ACCEPT_SAVE')}</span><Button
                        onClick={() => this._saveWizard()}>Sì</Button></>;
                }

                RecapDescr.push(<div>
                    <span>{fieldLabel}: </span>
                    <span
                        className={BUtils.joinClasses(stylesDictionaryWizard['dict-wizard-recap-descr'], stylesDictionaryWizard['dict-wizard-recap-descr--' + (isEmpty ? 'empty' : 'value')])}>{fieldValue}
                    </span>
                </div>);
            }

            return <InputGroup key={"key-wizard-" + fieldName} carousel={fieldCarousel}>
                <InputGroup.Addon>{fieldLabel}</InputGroup.Addon>
                <FormControl type="text"
                             disabled={fieldName === this.state.disabledField}
                             value={fieldObject.value}
                             onChange={(evt) => {
                                 this._handelText(evt, fieldName)
                             }}
                />
            </InputGroup>;
        });

        var CarouselItems = Array(carouselCount + 1).fill().map((carousel, index) => {
            return <Carousel.Item className={stylesDictionaryWizard['dictionary-wizard__carousel-item']}>
                {Elements.filter((element) => {
                    return element.props.carousel === index;
                })}
            </Carousel.Item>
        });


        return <Grid fluid>
            {this.props.saved ? '' : <>
                <Row className={stylesDictionaryWizard['dictionary-wizard-info']}>E' possibile inserire più di una c.gr.
                    (in/out), separando le descrizioni da <h3>;</h3><br/>In ogni caso la c.gr.loc <u>deve</u> restare
                    fuori
                    dalla lista, poichè verrà creata (e settata) separatamente nel campo sottostante</Row>
                <Row className={stylesDictionaryWizard['dictionary-wizard-row-carousel']}>
                    <Carousel interval={null} indicators={false}
                              className={stylesDictionaryWizard['dictionary-wizard__carousel']}>
                        {CarouselItems}
                    </Carousel>
                </Row>
                <Row
                    className={BUtils.joinClasses(stylesDictionaryWizard['dict-wizard-recap-info'], stylesDictionaryWizard['dict-wizard-recap'])}>
                    {RecapInfo}
                </Row>
            </>}
            <Row
                className={BUtils.joinClasses(stylesDictionaryWizard['dict-wizard-recap-descr'], stylesDictionaryWizard['dict-wizard-recap-descr--' + this.props.accept], stylesDictionaryWizard['dict-wizard-recap'])}>
                <div
                    className={BUtils.joinClasses(stylesDictionaryWizard['dict-wizard-recap-descr__div--' + this.props.accept])}>{RecapDescr}</div>
            </Row>
            {this.props.saved ?
                <Row className={stylesDictionaryWizard['dict-wizard-saved-info']}>{this.state.savedMessage}</Row> : ''}
        </Grid>
    }
}