import React from 'react';
import {OverlayTrigger, Tooltip, Popover} from 'react-bootstrap';
import {LexemeUtilities, getContextTypes, LexemePageInfos} from './../../../../editor/LexemeHelper';
import {LexemeEditor} from './../../../../editor/LexemeEditor';
import Dict from 'dict-lib';
import {getLexemeOptions} from './../../../../LexemePage';
import './../../LexemeData.css';
import './../../../../editor/LexemeEditor.css';
import './../../../../../../_css/lexeme.css';
import {ToolbarTypes} from "../../../../editor/LexemeHelper";
import {LexemePageContext} from "../../../../LexemePageContext";
import stylesLexemeData from './../../../../../../_css/dictionary/lexemeParts/lexemeData.module.css';


export class LexemeLocVars extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "LOC_VARS",
            item: {}/*{showOverlay: true}*/
        };

        this.x = '';
        this.y = '';
        this.offsetLeft = 0;
        this.isGeo = LexemePageInfos.getLexemeOptions(this.props.context, "loc_vars_geographic_order");

        this._isActive = this._isActive.bind(this);
        this._isLangActive = this._isLangActive.bind(this);
        this._createButtonBarTooltip = this._createButtonBarTooltip.bind(this);
        this._handleClick = this._handleClick.bind(this);
        this._onExit = this._onExit.bind(this);
    }

    _isActive(item) {
        if (this.isGeo && item.form==this.state.item.form && item.language == this.state.item.language)
            return "lexEditableActive";
        else if (!this.isGeo && (item.form == this.state.item.form))
            return "lexEditableActive";
        else
            return "";
    }

    _isLangActive(itemLang) {
        return LexemeUtilities.isActive(this, itemLang);
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _onExit(item) {
        if (this.state.item === item) {
            this.setState({
                item: ''
            })
        }
    }

    _handleClick(item) {
        this.setState({
            item: LexemeUtilities.handleClick(this, item)
        })
    }

    render() {

        var locVarsProps = this.props.locVars;
        var keyCounter = 1;
        const geoAdditionalClass = this.isGeo ? "is-geo" : "";

        var locVarsList = locVarsProps.map((item) =>
            <div key={"key-lexDataVarLoc" + keyCounter++}
                 className={"lexDataVarLoc " + geoAdditionalClass + " " + LexemeUtilities.getLexClass(this) + " "}>
                <OverlayTrigger delay={8000} key={"key-lexDataVarLoc" + keyCounter++} rootClose={false}
                                trigger={"click"}
                                onExit={() => this._onExit(item)}
                                placement="bottom" overlay={this._createButtonBarTooltip()}>
                    <div className={(this.isGeo ? "lexDataVarLocLang" : "lexDataVarLocForm") + " " + geoAdditionalClass + " " + this._isActive(item)}
                        onClick={() => {
                            this._handleClick(item)
                        }}><span>{this.isGeo ? item.language : item.form}</span></div>
                </OverlayTrigger>
                <div className={(this.isGeo ? "lexDataVarLocForms" : "lexDataVarLocLangs") + " " + geoAdditionalClass}>{
                    item.variants.map((itemLang) => <OverlayTrigger key={"key-lexDataVarLoc" + keyCounter++}
                                                                    rootClose={false} trigger={"click"}
                                                                    placement="bottom"
                                                                    onExited={() => this._onExit(itemLang)}
                                                                    overlay={this._createButtonBarTooltip()}>
                        <div key={"key-variants-" + itemLang.id}
                             className={(this.isGeo ? "lexDataVarLocForm" : "lexDataVarLocLang") + " " + geoAdditionalClass + " " + this._isLangActive(itemLang) + " loc-var-type-" + (itemLang.type).toLowerCase()}
                             onClick={() => {
                                 this._handleClick(itemLang)
                             }}><span id={'lexDataVarLocForm_' + itemLang.id} className={'lexHighlightable'}>{this.isGeo ? (itemLang.form) : itemLang.language}</span></div>
                    </OverlayTrigger>)
                }</div>
            </div>
        );


        return <div className={"lexDataLocVars"}>
            <div className={"lexDataLabel"}>{Dict.t('LOCAL_VARIANTS') + ":"}</div>
            {locVarsList}
        </div>;
    }
}

LexemeLocVars.contextType = LexemePageContext;
