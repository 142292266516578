import React from 'react';
import {Grid, Glyphicon} from 'react-bootstrap';
import Dict from 'dict-lib';
import {setSubentriesOrder} from './../editor/subentries/SubentryActions';
import {setPoliesOrder, setAltFormsOrder} from './../editor/entry/EntryActions';
import {setSensesOrder} from './../editor/senses/SenseAction';
import {setPhrasesOrder} from './../editor/phrases/PhraseAction';
import {setTranslationsOrder} from './../editor/translations/TranslationAction';
import {getPage} from '../LexemePage';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';

import stylesSorter from './../../../_css/sorter.module.css';
import BUtils from './../../../utilities/BaseUtilities';
import {LexemePageContext} from "../LexemePageContext";
import {LexemeSenses} from "../parts/03_Subentries/parts/LexemeSenses";
import {AdministrationUtilities} from "../../../administration/AdministrationHelper";


export class LexemeSorter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type,
            items: [],
            itemList: [],
            parentItem: this.props.parentItem,
            entry: "",
            noSortMsg: ""
        };

        //this._retrieveSense = this._retrieveSense().bind(this);
    }

    componentWillMount() {
        this._fillList(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this._fillList(nextProps);
    }

    _fillList(props) {
        var type = props.type;
        var items = this.props.items;
        var noSortMsg = "";
        var itemList = [];
        var keyCounter = 1;
        var page = getPage().page;
        var isPoly = this.props.isPoly;
        var entry = isPoly ? page.polyrhematics.filter((item) => {
            return item.ids === this.props.polyItem.ids
        })[0] : page;

        if (typeof props.notAllowedMsg !== 'undefined' && props.notAllowedMsg.length > 0) {
            noSortMsg = props.notAllowedMsg;
        } else if (type === 'GRAMMATICAL_CATEGORIES') {
            items = JSON.parse(JSON.stringify(entry.subentries));
            itemList = items.map(sub =>
                <div key={"key-lex-item-sortable-" + keyCounter++}
                     data-ids={sub.ids}>{sub.cgr.description + ((sub.subentry_form + "").length > 0 ? " - " : "")}
                    <small>{(sub.subentry_form + "").length > 10 ? sub.subentry_form.substring(0, 10) + "..." : sub.subentry_form}</small>
                </div>
            );
        } else if (type === 'POLYRHEMATICS') {
            items = JSON.parse(JSON.stringify(page.polyrhematics));
            itemList = items.map(poly =>
                <div key={"key-lex-item-sortable-" + keyCounter++}
                     data-ids={poly.ids} dangerouslySetInnerHTML={{__html: poly.html_form}}/>
            );
        } else if (type === 'ALTERNATIVE_FORMS') {
            items = JSON.parse(JSON.stringify(page.lexeme_data.all_forms));
            itemList = items.map(alt =>
                <div key={"key-lex-item-sortable-" + keyCounter++}
                     data-ids={alt.id}>{alt.form}</div>
            );
        } else if (type === 'SENSES') {
            var parentIds = this.props.parentItem.ids;
            var newParent = this._retrieveSubentry(entry, parentIds, isPoly);
            items = JSON.parse(JSON.stringify(newParent.senses));
            var langDescr = AdministrationUtilities.getDescriptionLanguage(this.props.context.languageId).code;//AdministrationUtilities.getDescriptionLanguage(this.context.languageId)

            itemList = items.map((sense) => {

                var translationGroup = sense.translation_group;
                var translationsItem = translationGroup.find((item) => {
                    return item.translations.length > 0;
                });

                var descSense = sense.description.find((item) => {
                    return item.language === langDescr;
                });

                //TODO: provvisorio
                if(typeof descSense === 'undefined')
                    descSense = sense.description[0];

                return <div className={stylesSorter['sort-senses-item']} key={"key-senses-item-sortable-" + keyCounter++}
                     data-ids={sense.ids}>{descSense.description}
                    <div
                        className={stylesSorter['sort-sense-item-additional-descr']}>{typeof translationsItem !== 'undefined' && translationsItem !== null ? " - trad. " + translationsItem.translations[0].description : ""}</div>
                </div>}
            );
        } else if (type === 'PHRASES') {
            var newParent = this._retrieveSense(entry);
            items = JSON.parse(JSON.stringify(newParent.phraseologies));

            itemList = items.map(phrase =>
                <div className={stylesSorter['sort-phrases-item']} key={"key-phrases-item-sortable-" + keyCounter++}
                     data-ids={phrase.id}>{phrase.text}
                </div>
            );
        } else if (type.startsWith('TRANSLATIONS')) {
            var newParent = this._retrieveSense(entry);
            var lang = type.split("_")[1];
            items = JSON.parse(JSON.stringify(newParent.translations[lang]));

            itemList = items.map(translation =>
                <div className={stylesSorter['sort-translations-item']} key={"key-translations-item-sortable-" + keyCounter++}
                     data-ids={translation.id}>{translation.description}
                </div>
            );
        }

        this.setState({type, items, itemList, entry, noSortMsg});
    }

    _retrieveSense(entry) {
        var parentIds = this.props.parentItem.ids;
        var newParent = "";
        entry.subentries.map(item => {
            item.senses.map(item2 => {
                if (item2.ids === parentIds) {
                    newParent = item2;
                }
            });
        });

        return newParent;
    }

    _retrieveSubentry(entry, subentryIds,) {
        return entry.subentries.filter(function (item) {
            return item.ids === subentryIds;
        })[0];
    }

    _retrieveSubentryBySense(entry, senseIds) {
        var subentry;

        subentry_block : {
            for (var i = 0; i < entry.subentries.length; i++) {
                for (var j = 0; j < entry.subentries[i].senses.length; j++) {
                    if (entry.subentries[i].senses[j].ids === senseIds) {
                        subentry = entry.subentries[i];
                        break subentry_block;
                    }
                }
            }
        }

        return subentry;
    }

    _onSortStart(node) {
    }

    _onSortEnd(obj) {
        if (obj.oldIndex !== obj.newIndex) {
            var page = getPage().page;
            var items = JSON.parse(JSON.stringify(this.state.items));
            var itemList = this.state.itemList;
            const isPoly = this.props.isPoly;

            itemList = arrayMove(itemList, obj.oldIndex, obj.newIndex);
            items = arrayMove(items, obj.oldIndex, obj.newIndex);

            this.setState({items, itemList});
            if (this.props.type === 'GRAMMATICAL_CATEGORIES') {
                if (isPoly) {
                    var poly = this.state.entry;
                    var index = page.polyrhematics.indexOf(poly);
                    poly.subentries = items;
                    page.polyrhematics[index] = poly;
                }

                setSubentriesOrder(items.map((item) =>  {return item.ids}), isPoly, page.polyrhematics).then(result => {
                    //this.props.callbackParent('GRAMMATICAL_CATEGORIES');
                });
            } else if (this.props.type === 'POLYRHEMATICS') {
                setPoliesOrder(items.map((item) => {return item.ids}));
                this.props.callbackParent('POLYRHEMATICS');
            } else if (this.props.type === 'ALTERNATIVE_FORMS') {
                setAltFormsOrder(items);
                this.props.callbackParent('ALTERNATIVE_FORMS');
            } else if (this.props.type === 'SENSES') {
                if (isPoly) {
                    var poly = this.state.entry;
                    var indexSub = poly.subentries.indexOf(this.state.parentItem);
                    var subentry = poly.subentries[indexSub];
                    subentry.senses = items;
                }

                setSensesOrder(items.map((item) =>  {return item.ids}), this.state.parentItem.ids, isPoly, page.polyrhematics);
                /*this.props.callbackParent('SENSES');*/
            } else if (this.props.type === 'PHRASES') {
                var subentry = this._retrieveSubentryBySense(page, this.state.parentItem.ids);
                var sense = this._retrieveSense(page);
                sense.phraseologies = items;
                setPhrasesOrder(items, subentry.senses, this.state.parentItem.ids, subentry.ids);
                this.props.callbackParent('PHRASES');
            } else if (this.props.type.startsWith('TRANSLATIONS')) {
                var subentry = this._retrieveSubentryBySense(this.state.entry, this.state.parentItem.ids);
                var sense = this._retrieveSense(this.state.entry);

                var lang = this.state.type.split("_")[1];
                sense.translations[lang] = items;
                setTranslationsOrder(items, subentry.senses, this.state.parentItem.ids, subentry.ids, page.polyrhematics, isPoly);
                this.props.callbackParent('TRANSLATIONS_' + lang);
            }
        }
    }

    render() {
        var itemList = this.state.itemList;
        var sortableItemList;

        if (Object.keys(itemList).length <= 1) {
            sortableItemList = (this.state.noSortMsg + "").length > 0 ? this.state.noSortMsg + "." : Dict.t('NO_SORT_COLLECTION_FOUND') + ".";
        } else {
            const DragHandle = SortableHandle(() => <div className={BUtils.joinClasses(stylesSorter['items-sortable-handle'], stylesSorter['sortable-handle'])}>
                <Glyphicon glyph="sort"/>
            </div>);

            const SortableItem = SortableElement(({value}) => {
                return (
                    <div className={BUtils.joinClasses(stylesSorter['items-sortable-item'], stylesSorter['sortable-item'])}>
                        <DragHandle/>
                        {value}
                    </div>
                );
            });

            const SortableList = SortableContainer(({items}) => {
                return (
                    <div className={BUtils.joinClasses(stylesSorter['div-sortable-container'], stylesSorter['sortable-type--' + this.props.type])}>
                        {items.map((value, index) => (
                            <SortableItem key={'sortable-item-' + index} index={index} value={value}/>
                        ))}
                    </div>
                );
            });

            sortableItemList = <SortableList key={"key-lex-items-sortable"}
                                             helperClass={stylesSorter['sortableHelper']} pressDelay={0} lockAxis={"y"}
                                             useDragHandle={false}
                                             items={itemList}
                                             onSortStart={(obj) => this._onSortStart(obj)}
                                             onSortEnd={(obj) => this._onSortEnd(obj)}/>;
        }

        return <Grid className={stylesSorter['grid-sortable-container']} fluid>{sortableItemList}</Grid>;
    }
}
