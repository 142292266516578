import React from 'react';
import {Grid, Row, Button} from 'react-bootstrap';
import {LexemeEditingLevel} from './../../editor/LexemeHelper';
import {LexemeNavigator} from './../../../../dict-components/components';
import {getLexemeProperties} from './../../LexemePage';
import {SubEntryCombo} from './../../../../dict-components/components';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css'
import './../../../../_css/lexeme.css';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';


export class SemanticUnitAddTranslation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            translationgId: ""
        };

        //this._selectTranslating = this._selectTranslating.bind(this);
    }

    /*_selectTranslating() {
        if (this.state.translationgId.length > 0) {
            this.setState({btDisabled: true});
            addTranslation(JSON.stringify(this.props.item), this.state.translationgId).then(result => {
                this.setState({btSaveState: "success", btDisabled: false}, function () {
                    setTimeout(function () {
                        this.setState({btSaveState: "primary"});
                    }.bind(this), 1000);
                });
            });
        }
    }*/

    render() {

        const item = typeof this.props.additionalData !== 'undefined' && typeof this.props.additionalData.phraseItem !== 'undefined' ? this.props.additionalData.phraseItem : this.props.item;

        return <Grid className={stylesLexemeSenses['sense-move-grid']} fluid>
            <LexemeNavigator pageStatus={LexemeEditingLevel.TRANSLATION_SELECTOR}
                             startItem={item}
                             context={this.props.context}
                             callbackParent={(selectableItem) => console.log(selectableItem)}/>
        </Grid>
    }
}