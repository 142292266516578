import React from 'react';
import Dict from 'dict-lib';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {userActions} from './_redux/authentication/actions/user.action';
import {history} from './_redux/authentication/history';
import {AdministrationUtilities} from './administration/AdministrationHelper';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        var goBackOffice = window.location.pathname === '/login' || window.location.pathname === '/backoffice' || typeof this.props.history.location.state === 'undefined';
        var from = goBackOffice ? AdministrationUtilities.getMainModulePath() : this.props.history.location.state.from.pathname

        this.state = {
            username: '',
            password: '',
            from,
            submitted: false,
            loginFailed: false
        };

        history.listen((location, action) => {
            this.setState({loginFailed: true}, () => {
                setTimeout(() => {
                    this.setState({loginFailed: false})
                }, 5000);
            });
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {username, password, from} = this.state;
        const {dispatch} = this.props;
        if (username && password) {
            dispatch(userActions.login(username, password, from));
        }
    }

    render() {
        const {username, password, submitted} = this.state;
        return (
            <div className="col-md-6 col-md-offset-3">
                <h2>Login</h2>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" name="username" value={username}
                               onChange={this.handleChange}/>
                        {submitted && !username &&
                        <div className="help-block">Username is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password}
                               onChange={this.handleChange}/>
                        {submitted && !password &&
                        <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <span><button className="btn btn-primary">Login</button></span>
                        {this.state.loginFailed ? <span style={{
                            paddingLeft: '8px',
                            color: 'darkred',
                            fontWeight: 'bold'
                        }}>{Dict.t('LOGIN_FAILED')}</span> : ''}
                    </div>
                </form>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return state.authentication;
};

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export {connectedLoginPage as LoginPage};