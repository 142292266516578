import React from 'react';
import Dict from 'dict-lib';
import ReactDom from 'react-dom';
import {getLexemeProperties} from '../../LexemePage';
import {saveTranslationNotes} from './TranslationAction';
import {Grid, Row, Col, InputGroup, FormControl, Button, Label} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {LexemeUtilities} from "../LexemeHelper";
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";

export class TranslationNotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item
        };

        this._handleTranslationNote = this._handleTranslationNote.bind(this);
        this._saveTranslationNotes = this._saveTranslationNotes.bind(this);
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.translationNote).focus();
    }

    _handleTranslationNote(evt) {
        var item = JSON.parse(JSON.stringify(this.state.item));
        item.notes = evt.target.value;
        this.setState({item});
    }

    _saveTranslationNotes() {
        this.setState({btDisabled: true});
        saveTranslationNotes(JSON.stringify(this.state.item), getLexemeProperties('root_entry_id')).then(result => {

        });
    }

    render() {

        return <Grid className="sense-notes-container" fluid>
            <Col lg={9} md={9}>
                <InputGroup className={"sense-notes"}>
                    <FormControl type="text" placeholder={Dict.t('NOTES') + "..."}
                                 ref={(input) => {
                                     this.translationNote = input;
                                 }}
                                 value={this.state.item.notes}
                                 onChange={(evt) => this._handleTranslationNote(evt)}
                                 onKeyUp={(evt) => {
                                     LexemeUtilities.handleKeyUp(evt, () => {
                                         this._saveTranslationNotes();
                                     })
                                 }}

                    />
                </InputGroup>
            </Col>
            <Col lg={3} md={3} className={"row-bt-sense-notes"}><SCSubmitButton
                onClick={() => this._saveTranslationNotes()} text={Dict.t('SAVE')}/>
            </Col>
        </Grid>;
    }

}
