import React from 'react';
import ReactDom from 'react-dom';
import Dict from 'dict-lib';
import {Typeahead} from 'react-bootstrap-typeahead';
import './typehead.css';
import stylesSearchMenu from "../../_css/dictionary/searchMenu.module.css";
import stylesLexemeSubentries from "../../_css/dictionary/lexemeParts/lexemeSubentries.module.css";
import {LexemeUtilities} from "../../lexeme/page/editor/LexemeHelper";

export class CgrTypeahead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            align: 'justify',
            items: [],
            languageId: this.props.languageId,
            selected: [], //parseInt(this.props.selected),
            multiple: typeof this.props.multiple === 'undefined' ? false : this.props.multiple,
            additionalClass: typeof this.props.additionalClass === 'undefined' ? '' : this.props.additionalClass
        };

        this.isUnmounting = false;

        this._handleSearch = this._handleSearch.bind(this);
        this._handleChange = this._handleChange.bind(this);
    }

    componentWillMount() {
        var callSearch = 'service/getCgrs/' + this.state.languageId;
        Dict.call(callSearch).then(result => {
            if (!this.isUnmounting) {
                var selected = typeof this.props.selected === 'undefined' ? [] : parseInt(this.props.selected);
                var items = typeof result.items === 'undefined' ? [] : result.items;
                this.setState({items, selected});
            }
        });
    }

    componentWillUnmount() {
        this.isUnmounting = true;
    }

    _handleSearch(query) {
        if (!query) {
            return;
        }
    }

    _handleChange(item) {
        if (this.state.multiple) {
            var selected = item;
            /*selected.push(item[0]);*/
            this.setState({selected});

            this.props.callbackParent(item);
        } else if (item[0]) {
            this.setState({
                selected: item[0].id
            }, function () {
                //console.log(this.state);
            });
            this.props.callbackParent(item[0]);
        }
    }

    render() {
        const {align} = this.state;
        const chooseLanguage = "cgr...";

        const selected = typeof this.props.multiple !== 'undefined' && this.props.multiple ? this.state.selected : LexemeUtilities.getTypeaheadItemById(this.state.items, this.state.selected);

        return (
            <Typeahead
                id={'cgr-th'}
                className={stylesLexemeSubentries['lexemeSubentryEdit__cgr--' + (this.state.items.length > 0 ? 'show' : 'hide')] + ' ' + this.state.additionalClass}
                ref={"cgrtypeahead"}
                clearButton={!this.props.multiple}
                align={align}
                maxHeight={typeof this.props.maxHeight === 'undefined' ? '300px' : this.props.maxHeight}
                maxResult={typeof this.props.maxResult === 'undefined' ? 100 : this.props.maxResult}
                multiple={this.state.multiple}
                onChange={item => this._handleChange(item)}
                onSearch={this._handleSearch}
                options={this.state.items}
                onFocus={this.props.onFocus}
                onInputChange={this.props.onInputChange}
                onBlur={this.props.onBlur}
                placeholder={chooseLanguage}
                selected={selected/*this.state.items.slice(0, 5)*/}
            />
        );
    }
}

export default CgrTypeahead;


