import React from 'react';
import {saveSubentry} from './SubentryActions';
import './Subentries.css';
import {getLexemeProperties, getLexemeOptions} from './../../LexemePage';
import {CgrTypeahead, SCCheckbox, SCSubmitButton} from './../../../../dict-components/components';
import Dict from 'dict-lib';
import {Grid, Row, Col, InputGroup, FormControl, Button, Label} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import ReactDom from "react-dom";
import {LexemeUtilities} from "../LexemeHelper";
import stylesLexemeSubentries from "../../../../_css/dictionary/lexemeParts/lexemeSubentries.module.css";
import BUtils from './../../../../utilities/BaseUtilities';
import ButtonGroup from "react-bootstrap/es/ButtonGroup";
import {Notifications} from "../../../../utilities/DictUtilities";
import stylesSCTree from "../../../../dict-components/tree/SCTree.module.css";

export class SubForms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            chunks: [],
            doNotDecline: false,
            rulePath: ''
        };
    }

    componentWillMount() {
        let colunns = 3;
        Dict.call('/subentries/retrieveForms/' + this.props.item.ids + '/').then((result) => {
            const length = result.forms.length;
            var chunkSize = Math.round(length / colunns);
            var chunks = [];
            for (let i = 0; i < length; i += chunkSize) {
                const chunk = result.forms.slice(i, i + chunkSize);
                chunks.push(chunk);
            }

            const doNotDecline = result.doNotDecline;
            const rulePath = Dict.t('APPLIED_RULE') + ':&nbsp;<strong>' + result.rulePath + '</strong>';

            this.setState({chunks, doNotDecline, rulePath});
        });
    }

    _handleSubentryForm(evt, id_form, chunkNumber) {
        var chunks = this.state.chunks;
        var formItem = chunks[chunkNumber].find((item) => {
            return item.id_form === id_form;
        });

        formItem.form = evt.target.value;

        this.setState({chunks});
    }

    _prepareSaveAll() {
        Notifications.showModalSpinner(Dict.t('OPERATION_IN_PROFRESS'), '');
        var saveChunk = [];
        this.state.chunks.forEach((partChunk) => {
            saveChunk = saveChunk.concat(partChunk.map((rowChunk) => {
                return {id: rowChunk.id_form, form: rowChunk.form};
            }));
        });

        var saveChunks = this._sliceIntoSaveChunks(saveChunk, 10);
        this._saveAll(0, saveChunks);
    }

    _sliceIntoSaveChunks(arr, chunkSize) {
        const res = [];

        var i = 0;
        while (i < arr.length) {
            var realChunkSize = (i + chunkSize) > arr.length ? arr.length - i : chunkSize;
            const chunk = arr.slice(i, i + realChunkSize);
            res.push(chunk);
            i = i + realChunkSize;
        }


        return res;
    }

    _saveAll(indexChunk, saveChunks) {
        Dict.call('/subentries/saveForms/' + this.props.item.ids + '/' + JSON.stringify(saveChunks[indexChunk]) + '/' + this.state.doNotDecline + '/').then((result) => {
            if (result === 'OK') {
                if (indexChunk === (saveChunks.length - 1)) {
                    Notifications.closeModalSpinner();
                } else {
                    this._saveAll(++indexChunk, saveChunks);
                }
            } else {
                Notifications.showErrorModalMessage("Errore nel salvataggio delle forme", 2000);
            }
        })
    }

    _empty() {
        var chunks = JSON.parse(JSON.stringify(this.state.chunks));
        this.state.chunks.forEach((partChunk, indexChunk) => {
            partChunk.forEach((itemPartChunk, indecPartChunk) => {
                var obj = chunks[indexChunk][indecPartChunk];
                obj.form = '';
                obj.form_for_searching = ';'
            })
        });

        this.setState({chunks});
    }

    render() {
        var chunks = this.state.chunks.map((chunk, index) => {
            var perc = parseInt((100 / this.state.chunks.length) * (12 / 100));
            var rowChunks = chunk.map((rowChunk) => {
                return <Row id={'subentry-forms-row--' + rowChunk.id_form}
                            className={stylesLexemeSubentries['subentry-forms-row']}>
                    <div className={stylesLexemeSubentries['subentry-forms-row__cgr']}><span>{rowChunk.cgr}</span></div>
                    <div className={stylesLexemeSubentries['subentry-forms-row__form']}>
                        <FormControl type="text" placeholder={Dict.t('FORMS') + "..."}
                                     className={BUtils.joinClasses(stylesLexemeSubentries['subentry-forms-row__input'])}
                                     ref={(input) => {
                                         this.subentryForm = input;
                                     }}
                                     value={rowChunk.form}
                                     onChange={(evt) => this._handleSubentryForm(evt, rowChunk.id_form, index)}
                                     on
                                     onKeyUp={(evt) => {
                                         LexemeUtilities.handleKeyUp(evt, () => {
                                             var ele = document.getElementById('subentry-forms-row--' + rowChunk.id_form);
                                             var changeObj = [{id: rowChunk.id_form, form: rowChunk.form}];
                                             ele.style.outlineColor = '#f89700';
                                             Dict.call('/subentries/saveForms/' + this.props.item.ids + '/' + JSON.stringify(changeObj) + '/' + this.state.doNotDecline + '/').then((result) => {
                                                 if (result === 'OK') {
                                                     ele.style.outlineColor = '#00833d';
                                                     setTimeout(function () {
                                                         ele.style.outlineColor = '#989898';
                                                     }, 500);
                                                 } else {
                                                     ele.style.outlineColor = '#a50400';
                                                 }
                                             })
                                         })
                                     }}
                        />
                    </div>
                </Row>;
            });

            return <Col lg={perc} md={perc}>{rowChunks}</Col>;
        });

        return <>
            <div style={{
                height: '30px',
                border: '1px solid #dcdcdc',
                borderRadius: '3px',
                paddingTop: '4px',
                marginBottom: '6px'
            }} dangerouslySetInnerHTML={{__html: this.state.rulePath}}/>
            <div id="subentry-forms" style={{overflowY: 'auto', height: '85%'}}>
                <Grid fluid>
                    {chunks}
                </Grid>
            </div>
            <div className={stylesLexemeSubentries['subentry-forms-button-bar']}>
                <div style={{display: 'inline-block', marginRight: '20px'}}>
                    <SCCheckbox id={'chk-do-not-decline-forms'} checked={this.state.doNotDecline}
                                label={Dict.t('DO_NOT_DECLINE_FORMS')}
                                additionalClass={stylesSCTree['teb-checkbox']}
                                callbackParent={(id, doNotDecline) => {
                                    this.setState({doNotDecline});
                                }}/>
                </div>
                <Button onClick={() => this._empty()}>{Dict.t('EMPTY')}</Button>
                <Button onClick={() => this._prepareSaveAll()}>{Dict.t('SAVE')}</Button>
            </div>
        </>;
    }

}