import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {Modal, Button, Col, InputGroup, FormControl} from 'react-bootstrap';
import {PhraseEditor} from "./PhraseEditor";
import {SearchResultButtonBar} from "../../../../search/SearchResultButtonBar";

import stylesSearchPage from './../../../../_css/dictionary/searchPage.module.css';
import stylesCreateEntity from './../../../../_css/dictionary/createEntity.module.css';
import BUtils from './../../../../utilities/BaseUtilities';

export class CreatePhrase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: true,
            phraseText: "",
            selectedLangId: this.props.language.id
        }

        this.phraseRef = React.createRef();

        this._close = this._close.bind(this);
        this._createNewPhrase = this._createNewPhrase.bind(this);
    }

    componentDidMount() {
        setTimeout(function () {
            //this.phraseRef.current.focus();
            document.getElementById('create-phrase-input').focus();
        }.bind(this), 500);
    }

    _close() {
        this.setState({showModal: false}, function () {
            this._emptyModalContainer();
        }.bind(this));
    }

    _handleCreatePhraseText(evt) {
        this.setState({phraseText: evt.target.value});
    }

    _handleKeyUp(event) {
        if (event.keyCode === 13) {
            document.getElementById('create-phrase-button').click();
        }
    }

    _createNewPhrase() {
        if (Object.keys(this.state.phraseText).length > 0 && this.state.selectedLangId.toString().length > 0) {
            var phraseItem = {text: this.state.phraseText, language_id: this.state.selectedLangId}

            Dict.call('/phrases/savePhrase/' + JSON.stringify(phraseItem)).then(result => {
                this.setState({bsStyle: "success"});
                this._emptyModalContainer(function () {
                    var dicResultContent = document.getElementById("dict-result-content");
                    if (!dicResultContent.classList.contains(stylesSearchPage['dict-result-content--active']))
                        dicResultContent.classList.add(stylesSearchPage['dict-result-content--active']);

                    var lexemePageContainer = document.getElementById("lexeme-page-container");
                    if (!lexemePageContainer.classList.contains(stylesSearchPage['lexeme-page-container-full'])) {
                        lexemePageContainer.className = stylesSearchPage['lexeme-page-container-full'];

                        var dictResultContainer = document.getElementById("dict-result-container");
                        dictResultContainer.classList.add(stylesSearchPage['dict-result-container--hide']);

                        document.getElementById('bt-hide-show-searchmenu').click();
                        const SearchResultButtonBarConst = <SearchResultButtonBar/>;
                        ReactDOM.render(SearchResultButtonBarConst, document.getElementById("dict-result-container-buttonbar"));
                    }

                    const LexemePageConst = <PhraseEditor key="key-lexeme-page" phraseId={result.phraseItem.id}
                                                          langId={this.state.selectedLangId + ""}
                                                          container={"phrase-editor-lexeme-container"}/>;
                    ReactDOM.render(LexemePageConst, document.getElementById("lexeme-page-container"), function () {
                        document.getElementById("phrase-editor-right-col").className = "col col-md-6 col-xs-6";
                    });
                    document.getElementById("dict-form-main").value = "";

                }.bind(this));
            });
        } else {
            this._focusOnNewForm();
            this.setState({bsStyle: "warning"}, function () {
                setTimeout(function () {
                    this.setState({bsStyle: "primary"});
                }.bind(this), 3000);
            });
        }
    }

    _emptyModalContainer(callback) {
        setTimeout(function () {
            ReactDOM.unmountComponentAtNode(document.getElementById("dict-modal-container"));
            if (typeof callback === 'function')
                callback();
        }, 1000);
    }

    render() {

        return <Modal id="dict-create-phrase" show={this.state.showModal} onHide={this._close}
                      className={BUtils.joinClasses(stylesCreateEntity['dict-create-phrase'], stylesCreateEntity[this.props.language.is_tree ? 'create-tree-model' : 'create-mono-lang-model'])}
                      restoreFocus><Modal.Header closeButton>
            <Modal.Title>{Dict.t('CREATE_PHRASE') + " - "}<strong>{this.props.language.label}</strong></Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <Col lg={9} md={9}>
                    <InputGroup>
                        <FormControl type="text" placeholder={Dict.t('TEXT') + "..."}
                                     value={this.state.phraseText}
                                     id={'create-phrase-input'}
                                     ref={this.phraseRef}
                                     onKeyUp={this._handleKeyUp}
                                     onChange={(evt) => this._handleCreatePhraseText(evt)}
                        />
                    </InputGroup>
                </Col>
                <Col lg={2} md={2}><Button id='create-phrase-button' bsStyle={this.state.bsStyle} onClick={() => this._createNewPhrase()}>{Dict.t('CREATE')}</Button></Col>
                <Col lg={1} md={1}/>
            </Modal.Body>
        </Modal>

    }
}