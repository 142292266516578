import React from "react";

export class NotFoundPage extends React.Component {


    render() {
        return (
            <div>
                { typeof this.props.message === 'undefined' ? 'PAGE NOT FOUND!' : this.props.message}
            </div>
        );
    }

}