import React from 'react';
import {AdvancedFields} from './../root-entry/AdvancedFields';
import {getLexemeProperties} from './../../LexemePage';
import './../../editor/root-entry/RootEntry.css';


export class EntryAdvancedFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return <div className="loc-var-advanced-fields-container"><AdvancedFields id={getLexemeProperties('root_entry_id')}/></div>
    }
}