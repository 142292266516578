import React from 'react';
import {getLexemeProperties, getDictionaryProperties} from '../../LexemePage';
import {forceDefAbs} from './TranslationAction';
import Dict from 'dict-lib';
import {Button, Row, Col, Grid} from 'react-bootstrap';
import './../../../../_css/lexeme.css';
import {SCSubmitButton} from "../../../../dict-components/submitButton/SCSubmitButton";


export class TranslationForceDefAbs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id
        };

        this._forceDefaAbs = this._forceDefaAbs.bind(this);
    }

    _forceDefaAbs() {
        forceDefAbs(JSON.stringify(this.props.item), getLexemeProperties('root_entry_id'));
    }

    render() {

        const isInverse = getDictionaryProperties('is_inverse');
        const isForced = (isInverse && this.props.item.is_defabs_out || this.props.item.is_defabs_in);

        return <Grid fluid>
            <Row>
                <Col lg={7} md={7} className="popup-label">{Dict.t(isForced ? 'ASK_HIDE_FORCE_DEF_ABS' : 'ASK_FORCE_DEF_ABS')}</Col>
                <Col lg={5} md={5}>
                    <SCSubmitButton onClick={this._forceDefaAbs} text={Dict.t('YES')}/>
                </Col>
            </Row>
        </Grid>;
    }

}
