import React from 'react';
import Dict from 'dict-lib';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {Grid, Row, Col, Button, Image, InputGroup, FormControl, FormGroup, ControlLabel} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css';
import {LexemeUtilities} from './../../editor/LexemeHelper';
import {getDescriptionLanguage} from './../../LexemePage';
import {saveTitleAndCaption} from './SenseAction';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';
import BUtils from './../../../../utilities/BaseUtilities';

export class SenseAddImage extends React.Component {
    constructor(props) {
        super(props);

        this.dropInfoMsg = Dict.t('MSG_DROP_IMG_FILE_HERE');
        this.dropOverMsg = Dict.t('MSG_ACCEPTED_FILE');
        this.dropLoadedMsg = Dict.t('MSG_LOADED_FILE');
        this.dropRejectMsg = Dict.t('MSG_REJECTED_FILE');

        this.state = {
            files: [],
            dropText: this.dropInfoMsg,
            soundItem: {},
            serviceType: "senses",
            title: "",
            caption: "",
            btSaveState: "primary",
            saveText: Dict.t('SAVE_ONLY_TITLE_AND_CAPTION')
        };

        this.firstOpen = true;

        this._onDrop = this._onDrop.bind(this);
        this._onDragOver = this._onDragOver.bind(this);
        this._onDragLeave = this._onDragLeave.bind(this);
        this._handleTitle = this._handleTitle.bind(this);
        this._saveTitleCaption = this._saveTitleCaption.bind(this);
        this._emptyTitleCaption = this._emptyTitleCaption.bind(this);
    }

    componentWillMount() {
        const descrLang = getDescriptionLanguage();
        Dict.call('/senses/retrieveTitleAndCaption/' + LexemeUtilities.encodeUrl(JSON.stringify(this.props.item)) + '/' + descrLang + '/').then(result => {
            const title = result.title;
            const caption = result.caption;
            this.setState({title, caption});
        });

        /*var title = "";
        if (Object.keys(this.props.item.imgTitle).length > 0) {
            title = (this.props.item.imgTitle.filter(item => {
                return item.language == descrLang;
            })[0]).description;
        }

        var caption = "";
        if (Object.keys(this.props.item.imgCaption).length > 0) {
            caption = (this.props.item.imgCaption.filter(item => {
                return item.language == descrLang;
            })[0]).description;
        }

        this.setState({title, caption});*/
    }

    _onDrop(files) {
        this.setState({
            files, dropText: this.dropLoadedMsg
        });
    }

    _onDragOver() {
        /*var dropText = document.getElementById('add-img-dropzone').classList.contains('accept-drop-zone') ? this.dropOverMsg : this.dropRejectMsg;   */
    }

    _handleTitle(evt) {
        var title = evt.target.value;
        this.setState({title});
    }

    _handleCaption(evt) {
        var caption = evt.target.value;
        this.setState({caption});
    }

    _onDragLeave() {
        this.setState({
            dropText: this.dropInfoMsg
        });
    }

    _resetFile() {
        this.setState({files: [], dropText: this.dropInfoMsg});
    }

    _removeFile() {
        this.setState({files: []}, function () {
            this._saveFile();
        }.bind(this));
    }

    _emptyTitleCaption() {
        const title = "";
        const caption = "";
        this.setState({title, caption});
    }

    _saveTitleCaption() {
        this.setState({btSaveState: "success", saveText: Dict.t('RESET')}, function () {
            setTimeout(function () {
                this.setState({btSaveState: "primary", saveText: Dict.t('SAVE_ONLY_TITLE_AND_CAPTION')});
                saveTitleAndCaption(JSON.stringify(this.props.item), this.state.title, this.state.caption, getDescriptionLanguage())
            }.bind(this), 1000);
        });
    }

    _saveFile() {
        var item = LexemeUtilities.encodeUrl(JSON.stringify(this.props.item));

        if (this.state.files.length > 0) {
            var file = this.state.files[0];

            Dict.call('/entry/retrieveMediaData', {
                method: 'POST',
                data: file,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': file.type,
                }
            }).then(function (result) {
                if (typeof this.props.item.multimedia_file !== 'undefined')
                    window.URL.revokeObjectURL(this.props.item.multimedia_file);
                window.URL.revokeObjectURL(this.state.files[0].preview);
                LexemeUtilities.callSpinner('/' + this.state.serviceType + '/saveMultimedia/' + item + '/' + this.state.files[0].name + '/' + result.filename + '/', 'UPLOADING_IMAGE');
            }.bind(this));
        } else {
            LexemeUtilities.call('/' + this.state.serviceType + '/removeMultimedia/' + item + "/");
        }
    }

    render() {

        let dropzoneRef;

        const selectedFilesFound = this.state.files.length > 0;
        const f = this.state.files[0];
        const hasSavedFile = typeof this.props.item.multimedia_file !== 'undefined' && Object.keys(this.props.item.multimedia_file).length > 0;

        return <Grid className="grid-dropzone" fluid>
            <Col lg={5} md={5}
                 className={BUtils.joinClasses(stylesLexemeSenses['add-image-dropzone__col'], 'drop')}><Row
                className="div-dropzone">
                <Dropzone
                    id={'add-img-dropzone'}
                    accept={"image/jpeg,image/png,image/gif"}
                    rejectClassName={"add-img-reject-drop-zone"}
                    acceptClassName={"accept-drop-zone"}
                    ref={(node) => {
                        dropzoneRef = node;
                    }} className="area-dropzone"
                    onDrop={acceptedFiles => this._onDrop(acceptedFiles)}
                    onDragOver={this._onDragOver}
                    onDragLeave={this._onDragLeave}
                    /*onDropRejected={this._onDropRejected}*/>
                    <p className="p-dropzone">{this.state.dropText}</p>
                    <div className={stylesLexemeSenses['add-dropped-image-container']}>
                        {selectedFilesFound ? <Image src={f.preview} responsive/> : ""}
                        {selectedFilesFound ? <div>{f.name + ' - ' + f.size + ' bytes'}</div> : ""}
                    </div>
                </Dropzone>
            </Row>
                <Row className="div-dropzone-button">
                    <Button type="button" onClick={() => {
                        dropzoneRef.open()
                    }}>{Dict.t('OPEN_FILE_DIALOG')}</Button>
                    {hasSavedFile ? <Button style={{marginLeft: '3px'}} type="button" onClick={() => {
                        this._removeFile()
                    }}>{Dict.t('REMOVE')}</Button> : ""}
                    {selectedFilesFound ?
                        <Button style={{marginLeft: '3px'}} type="button" onClick={() => {
                            this._resetFile()
                        }}>{Dict.t('RESET')}</Button> : ""}
                    {selectedFilesFound ?
                        <Button style={{marginLeft: '3px'}} type="button" onClick={() => {
                            this._saveFile()
                        }}>{Dict.t('UPLOAD')}</Button> : ""}
                </Row>
            </Col>
            <Col lg={6} md={6}
                 className={BUtils.joinClasses(stylesLexemeSenses['add-image-dropzone__col'], stylesLexemeSenses['add-image-dropzone__col--loaded'])}>
                <Row
                    className={BUtils.joinClasses(stylesLexemeSenses['add-image-dropzone-saved-row'], stylesLexemeSenses['add-image-dropzone-saved-row--image-container'])}>
                    <p>{Dict.t('LOADED_IMAGE')}:</p>
                    {hasSavedFile ? <Image src={this.props.item.multimedia_file} responsive/> :
                        <div className={stylesLexemeSenses['add-image-no-image-loaded']}>{Dict.t('NONE_F') + "."}</div>}
                </Row>
                <Row
                    className={BUtils.joinClasses(stylesLexemeSenses['add-image-dropzone-saved-row'], stylesLexemeSenses['add-image-dropzone-saved-row--input-group-title'])}>
                    <InputGroup className={""}>
                        <InputGroup.Addon>{Dict.t('TITLE')}</InputGroup.Addon>
                        <FormControl type="text" placeholder=""
                                     ref={(input) => {
                                         this.imgTitle = input;
                                     }}
                                     value={this.state.title}
                                     onChange={(evt) => this._handleTitle(evt)}/>
                    </InputGroup>
                </Row>
                <Row className={stylesLexemeSenses['add-image-dropzone-saved-row']}>
                    <FormGroup controlId="formControlsTextarea">
                        <ControlLabel>{Dict.t('CAPTION')}</ControlLabel>
                        <FormControl componentClass="textarea" placeholder="" value={this.state.caption}
                                     onChange={(evt) => this._handleCaption(evt)}/>
                    </FormGroup>
                </Row>
                <Row
                    className={BUtils.joinClasses(stylesLexemeSenses['add-image-dropzone-saved-row'], stylesLexemeSenses['add-image-dropzone-saved-row--button-save-title-caption'])}>
                    <Button bsStyle="primary" onClick={() => this._emptyTitleCaption()}>{Dict.t('EMPTY')}</Button>
                    <Button bsStyle={this.state.btSaveState}
                            className={stylesLexemeSenses['add-image-dropzone-saved__btn-save-title-caption']}
                            onClick={() => this._saveTitleCaption()}>{this.state.saveText}</Button>
                </Row>
            </Col>
        </Grid>
    }
}