import React from 'react';
import Dict from 'dict-lib';
import {getLexemeProperties} from './../../LexemePage';
import {Grid, Row, Button, InputGroup, FormControl} from 'react-bootstrap';
import {LexemeUtilities, getContextTypes, LexemeEditingLevel, translate} from './../../editor/LexemeHelper';
import {addNewSubentry} from './EntryActions'
import {SenseAdd} from './../senses/SenseAdd';
import {CgrTypeahead, SCSubmitButton} from './../../../../dict-components/components';
import './../../../../_css/lexeme.css';
import './../../editor/root-entry/RootEntry.css';
import ReactDom from "react-dom";
import BUtils from './../../../../utilities/BaseUtilities';


export class EntryAddSubentry extends React.Component {

    constructor(props) {
        super(props);

        const isTranslatorSelector = typeof this.props.context !== 'undefined' && this.props.context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR;

        this.state = {
            idcgr: "",
            subentryForm: "",
            firstTimeChoosen: false,
            senseText: "",
            saving: false,
            isTranslationSelector: isTranslatorSelector,
            isTreeMdel: isTranslatorSelector ? false : getLexemeProperties("is_tree_model"),
        };

        this._handleAdd = this._handleAdd.bind(this);
        this._handleSelectCgr = this._handleSelectCgr.bind(this);
        this._handleOnChange = this._handleOnChange.bind(this);
        this._handleSubentryForm = this._handleSubentryForm.bind(this);
        this._handleSenseText = this._handleSenseText.bind(this);
        this._handleSenseKeyUp = this._handleSenseKeyUp.bind(this);
        this._handleSubentryKeyUp = this._handleSubentryKeyUp.bind(this);
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    _handleSubentryForm(evt) {
        var subentryForm = evt.target.value;
        this.setState({subentryForm});
    }

    _handleSenseText(evt) {
        var senseText = evt.target.value;
        this.setState({senseText});
    }

    _handleSelectCgr(cgr) {
        setTimeout(function () {
            var firstTimeChoosen = true;
            var idcgr = cgr.id;
            this.setState({idcgr, firstTimeChoosen});
            ReactDom.findDOMNode(this.subentryForm).focus();
        }.bind(this), 50);
    }

    _handleOnChange() {
        this.setState({idcgr: ""});
    }

    _handleOnBlur() {
        setTimeout(function () {
            if ((this.state.idcgr + "").length === 0) {
                this.refs.cgrthaddsub.refs.cgrtypeahead.getInstance().clear();
            }
        }.bind(this), 160);
    }

    _handleAdd(senseText, addList) {
        if ((this.state.idcgr + "").length > 0) {
            this.setState({saving: true}, () => {
                    const addPromiseSubentry = addNewSubentry(this.props.additionalData.isPoly ? this.props.item.ids : this.props.context.rootEntryId, this.state.idcgr, this.state.subentryForm, senseText, addList, this.state.isTreeMdel, this.props.additionalData.isPoly, this.state.isTranslationSelector);
                    addPromiseSubentry.then(result => {
                        if (this.state.isTranslationSelector) {
                            if (result.result === 'OK') {
                                var newLexSenseIds = JSON.parse(result.additionalInfos).newLexSenseIds;
                                translate(this.props.context.startItem, newLexSenseIds);
                            }
                            this.setState({saving: false});
                        }
                    });
                }
            )
        } else {
            LexemeUtilities.showModalMessage("warning", Dict.t('WARNING_CHOOSE_CGR'), 2000)
        }
    }

    _handleSubentryKeyUp(evt) {
        LexemeUtilities.handleKeyUp(evt, () => {
            ReactDom.findDOMNode(this.senseText).focus();
        });
    }

    _handleSenseKeyUp(evt) {
        LexemeUtilities.handleKeyUp(evt, () => {
            this._handleAdd(this.state.senseText, this.props.context.languageId);
        });
    }

    render() {

        return <Grid className={'grid-cgr-add'} fluid>
            <Row className={"row-cgr-add-typeahead"}>
                <CgrTypeahead ref={"cgrthaddsub"} callbackParent={(newState) => this._handleSelectCgr(newState)}
                              languageId={this.props.context.languageId}
                              onInputChange={() => this._handleOnChange()} onBlur={() => this._handleOnBlur()}/>
            </Row>
            <Row className={"row-cgr-add-form"}>
                <InputGroup className={"subentry-edit-group"}>
                    <InputGroup.Addon>{Dict.t('FORM')}</InputGroup.Addon>
                    <FormControl type="text" placeholder={Dict.t('FORM') + "..."}
                                 ref={(input) => {
                                     this.subentryForm = input;
                                 }}
                                 value={this.state.subentryForm}
                                 onChange={(evt) => this._handleSubentryForm(evt)}
                                 onKeyUp={this._handleSubentryKeyUp}
                    />
                </InputGroup>
            </Row>
            {this.state.isTreeMdel ?
                <Row className={"row-cgr-add-sense " + (this.state.firstTimeChoosen ? '' : 'row-cgr-add-sense-hidden')}>
                    <SenseAdd item={{senses: []}} additionalData={{isNew: true}} asComponent={true}
                              context={this.props.context}
                              callbackParent={(senseText, addList) => this._handleAdd(senseText, addList)}/>
                </Row> :
                <Row
                    className={"row-cgr-add-sense-text " + (this.state.firstTimeChoosen ? '' : 'row-cgr-add-sense-hidden')}>
                    <InputGroup className={"add-sub-sense-group"}>
                        <InputGroup.Addon>{Dict.t('SENSE')}</InputGroup.Addon>
                        <FormControl type="text" placeholder={Dict.t('SENSE') + "..."}
                                     ref={(input) => {
                                         this.senseText = input;
                                     }}
                                     value={this.state.senseText}
                                     onChange={(evt) => this._handleSenseText(evt)}
                                     onKeyUp={this._handleSenseKeyUp}
                        />
                    </InputGroup>
                </Row>
            }
            {this.state.isTreeMdel ? "" :
                <Row
                    className={BUtils.joinClasses("row-cgr-add-button", "row-cgr-add-button-saving--" + this.state.saving)}
                    style={{display: ''}}>
                    <SCSubmitButton onClick={() => this._handleAdd(this.state.senseText, this.props.context.languageId)}
                                    text={Dict.t(this.state.isTranslationSelector ? 'ADD_AND_TRANSLATE' : 'SAVE')}/>
                </Row>}
        </Grid>;
    }
}