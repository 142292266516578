import React from 'react';
import {promoteLocVar} from './LocVarActions';
import Dict from 'dict-lib';
import {Button, Grid, Row, Col} from 'react-bootstrap';
import './LocVars.css';


export class LocVarPromoteToRootEntry extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id : ""
        };

        this._handleRemove = this._handleRemove.bind(this);
    }

    componentWillMount() {
        this.setState({id : (typeof this.props.item.variants=='undefined' ? this.props.item.id : this.props.item.variants[0].id)});
    }

    _handleRemove() {
        promoteLocVar(this.state.id);
    }


    render() {

        return <Grid fluid>
            <Row>
                <Col lg={10} md={10} className="popup-label">{Dict.t('ASK_PROMOTE_LOC_VAR')}</Col><Col lg={2} md={2}><Button onClick={this._handleRemove}>{Dict.t('YES')}</Button></Col>
            </Row>
        </Grid>;
    }


}
