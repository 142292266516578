import React from 'react';
import Dict from 'dict-lib';
import {Col, Row, Button} from 'react-bootstrap';
import {spreadSenses} from './SenseAction';
import './../../../../lexeme/page/parts/03_Subentries/parts/LexemeSenses.css';
import './../../../../_css/lexeme.css';

import stylesLexemeSenses from './../../../../_css/dictionary/lexemeParts/lexemeSenses.module.css';


export class SenseSpread extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
            childrenList: "",
            detachingPhrases: [],
            extendingPhrases: [],
            childrenFound: false,
            callResult: false
        };

        this._handleSpread = this._handleSpread.bind(this);
    }

    componentWillMount() {
        Dict.call('service/senses/getImmediateChildren/' + this.props.item.ids).then(result => {
            this.setState({
                callResult: true,
                childrenList: result.spread_lang_list,
                detachingPhrases: result.detaching_phrases,
                extendingPhrases: result.extending_phrases,
                childrenFound: typeof result.spread_lang_list !== 'undefined'
            });
        });
    }

    _handleSpread() {
        const ids = (this.state.item.local_senses).length > 0 ? this.state.item.local_senses.map(function (elem) {
            return elem.language_id;
        }).join("_") : "";

        return spreadSenses(this.props.item, ids);
    }

    render() {

        const childrenFound = this.state.childrenFound;

        var childrenList = childrenFound ? this.state.childrenList.map(function (elem) {
            return elem.label;
        }).join(", ") : "";


        const extendingPhrases = typeof this.state.extendingPhrases === 'undefined' ? "" : this.state.extendingPhrases.map((item) =>
            <Row className={stylesLexemeSenses['sense-spread-phrase__item']}>- {item}</Row>);
        const detachingPhrases = typeof this.state.detachingPhrases === 'undefined' ? "" : this.state.detachingPhrases.map((item) =>
            <Row className={stylesLexemeSenses['sense-spread-phrase__item']}>- {item}</Row>);

        return <div className={stylesLexemeSenses['sense-spread']}>{ this.state.callResult ?
            <Row className={stylesLexemeSenses['row-sense-ask-spread']}>
                <Col lg={6} md={6} className="popup-label">
                    <Row
                        className={stylesLexemeSenses['sense-ask-spread']}>{Dict.t(childrenFound ? 'ASK_SPREAD_SENSE' : 'SPREAD_SENSES_NOT_FOUND')}
                    </Row>
                </Col>
                { childrenFound ?
                    <Col lg={1} md={1}>
                        <Button onClick={this._handleSpread}>{Dict.t('YES')}</Button>
                    </Col> : ""}
                { childrenFound ?
                    <Col lg={5} md={5}>
                        <Row className={stylesLexemeSenses['sense-ask-spread__children-list']}>{childrenList}</Row>
                    </Col> : "" }
            </Row> : ""}
            { childrenFound && ((extendingPhrases).length > 0 || (detachingPhrases).length > 0) ?
                <Row className={stylesLexemeSenses['sense-spread-phrases']}>
                    {(extendingPhrases).length > 0 ?
                        <Row className={stylesLexemeSenses['sense-spread-phrase__label']}>{Dict.t('EXTENDING_PHRASES_LABEL')}</Row> : ""}
                    {extendingPhrases}
                    {(detachingPhrases).length > 0 ?
                        <Row className={stylesLexemeSenses['sense-spread-phrase__label']}>{Dict.t('DETACHING_PHRASES_LABEL')}</Row> : ""}
                    {detachingPhrases}
                </Row> : "" }
        </div>;
    }


}
