import React from 'react';
import ReactDOM from 'react-dom';
import Dict from 'dict-lib';
import {OverlayTrigger, Button, Image, Modal, Carousel, CarouselItem, Glyphicon, Tooltip} from 'react-bootstrap';
//import {getLexemeProperties, getDescriptionLanguage} from './../../LexemePage';
import {
    getContextTypes,
    LexemeUtilities,
    LexemeEditingLevel,
    EditingStatusTypes,
    LexemePageInfos,
    LexemeDisplayContext
} from "../../editor/LexemeHelper";
import {SCSimpleSoundPlayer} from './../../../../dict-components/components';
import {LexemePageContext} from "../../LexemePageContext";

import stylesLexemeTitle from '../../../../_css/dictionary/lexemeParts/lexemeTitle.module.css';
import BUtils from './../../../../utilities/BaseUtilities';
import {SCFileViewer} from "../../../../dict-components/fileViewer/SCFileViewer";
import stylesScNavbar from "../../../../dict-components/navbar/ScNavbar.module.css";
import {BackOfficeTree} from "../../../../search/backOfficeSearch/BackOfficeTree";

export class LexemeTitle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: 'TITLE',
            id: "",
            audioSrc: this.props.page.title.multimedia_file,
            //descrLang: LexemePageInfos.getDescriptionLanguage(this.context),
            showGallery: false,
            showPlaceFiles: false,
            defaultActiveImageIndex: 0,
        };
    }

    componentWillMount() {
        //this.setState({descrLang: LexemePageInfos.getDescriptionLanguage(this.context)});
    }

    componentDidMount() {
        if(LexemePageInfos.isToponym(this.context) && typeof this.props.page.place !== 'undefined' && this.props.page.place.id !== null) {
            Dict.call('service/search/retrieveBackOfficeEntityFields/Place/' + this.props.page.place.id + '/').then((result) => {
                var placeData = JSON.parse(JSON.stringify(this.props.page.place));
                placeData.multimediaFiles = BUtils.sortMultimediaFiles(result.entityFields.galleryMultimedia.value);

                this.setState({placeData});
            });
        }
    }

    _createButtonBarTooltip() {
        return LexemeUtilities.createButtonBarTooltip(this);
    }

    _isActive(id) {
        return LexemeUtilities.isActive(this, id);
    }

    _highlightSense(senseIds) {
        var senseObj = document.getElementById("su-ids-" + senseIds).parentElement;
        senseObj.classList.add("hightlight-image");
    }

    _removeHighlightSense(senseIds) {
        var senseObj = document.getElementById("su-ids-" + senseIds).parentElement;
        senseObj.classList.remove("hightlight-image");
    }

    _openImageGallery(index) {
        this.setState({showGallery: true, defaultActiveImageIndex: index});
    }

    _handleClick(id) {
        if (this.context.pageStatus === LexemeEditingLevel.EDITABLE || this.context.pageStatus === LexemeEditingLevel.TRANSLATION_SELECTOR) {
            const active = document.getElementsByClassName(LexemeUtilities.getLexClass(this) + "Active");

            if (active.length > 0) {
                active[0].click();
            }

            this.setState({
                id: (id === this.state.id ? '' : id)
            });
        }
    }

    render() {

        const title = this.props.page.title;
        const dictProps = this.props.page.dictionary_properties;
        const isLexeme = LexemePageInfos.isLexeme(this.context);
        const hasMultimedia = typeof title.multimedia_file !== 'undefined' && Object.keys(title.multimedia_file).length > 0;
        const place = this.props.page.place;
        const isHidden = this.props.page.lexeme_properties.is_hidden;

        const entryForm = title.entry_form; // === 'weilen' ? baddarone.title.entry_form : title.entry_form;
        const homo = title.homographic;
        const langList = isLexeme ? title.language_list : "";

        var dictWay = dictProps.is_inverse ? "Inverse" : "Direct";

        const isToponym = LexemePageInfos.isToponym(this.context);
        const hasPlace = isToponym && typeof place !== 'undefined'
        const printPlace = hasPlace ? <>
            <Glyphicon className={stylesLexemeTitle['lex-title-place-button']} onClick={() => {this.setState({showPlaceFiles : true})}} glyph={'briefcase'} />
            <Glyphicon className={stylesLexemeTitle['lex-title-place-button']} onClick={() => {this.setState({showPlaceTree : true})}} glyph={'tree-conifer'} />
        </> : '';

        const images = this.props.page.title.images.map((item, index) => {
            return <div className={stylesLexemeTitle['title-image-list__item']} key={"key-title-image-" + item.senseIds}
                        onMouseOver={() => {
                            this._highlightSense(item.senseIds)
                        }}
                        onMouseOut={() => {
                            this._removeHighlightSense(item.senseIds)
                        }}
                        onClick={() => this._openImageGallery(index)}
            ><Image src={item.multimedia_crop_image}/></div>;
        });

        const galleryImages = this.props.page.title.images.map((item) => {
            return <CarouselItem key={"key-carousel-item-" + item.senseIds}>
                <div className={stylesLexemeTitle['gallery-carousel__div']}>
                    <img src={item.multimedia_image} className={stylesLexemeTitle['gallery-carousel__img']} alt={''}/>
                </div>
                <Carousel.Caption>
                    <h3>{item.title}</h3>
                    <p>{item.caption}</p>
                </Carousel.Caption>
            </CarouselItem>
        });

        const editingStatusTooltip = <Tooltip
            placement="left" className="in" id="tooltip">
            {Dict.t(this.props.page.title.editing_status)}
        </Tooltip>;

        var editingStatus = typeof this.props.page.title.editing_status !== 'undefined' ? this.props.page.title.editing_status : 'ANY';


        return <div
            className={BUtils.joinClasses(stylesLexemeTitle['lexeme-title'], stylesLexemeTitle['lexeme-title--' + dictWay], stylesLexemeTitle['lexeme-hidden--' + isHidden])}>
            {this.context.pageStatus === LexemeEditingLevel.EDITABLE ?
                <div
                    className={BUtils.joinClasses(stylesLexemeTitle['lexeme-status'], stylesLexemeTitle['lexeme-status--' + this.props.page.title.lexeme_status])}>&nbsp;</div> : ""}
            <OverlayTrigger delay={8000} key={"key-lexEntry"} rootClose={false}
                            trigger={"click"}
                            placement="right"
                            overlay={this._createButtonBarTooltip()}>
                <div
                    className={BUtils.joinClasses(stylesLexemeTitle['lex-title-entryForm'], LexemeUtilities.getLexClass(this), this._isActive(LexemePageInfos.getLexemeProperties(this.context,"root_entry_id")))}
                    onClick={() => {
                        this._handleClick(LexemePageInfos.getLexemeProperties(this.context,"root_entry_id"))
                    }}>{entryForm}</div>
            </OverlayTrigger>
            <div className={BUtils.joinClasses(stylesLexemeTitle['lextTitleHomo'], stylesLexemeTitle['lextTitleHomo--' + homo])}>{homo}</div>
            {isLexeme && langList.length > 0 ? <div className={stylesLexemeTitle["lexTitleLangList"]}>{langList}</div> : ""}
            {hasMultimedia && this.context.displayContext === LexemeDisplayContext.DICTIONARY_SEARCH ?
                <div className={stylesLexemeTitle['div-entry-audio-container']}><SCSimpleSoundPlayer
                    src={this.props.page.title.multimedia_file /*+ '?v=' + new Date().getMilliseconds()*/}/>
                </div> : ""}
            {images.length > 0 ? <div className={stylesLexemeTitle['title-image-list']}>{images}</div> : ""}
            {images.length > 0 ?
                <Modal className={stylesLexemeTitle['modal-title-gallery']} show={this.state.showGallery}
                       onHide={() => this.setState({showGallery: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{Dict.t('IMAGE_GALLERY_OF')}&nbsp;
                            <strong>{title.entry_form}</strong></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Carousel slide={true} interval={null}
                                  defaultActiveIndex={this.state.defaultActiveImageIndex}>
                            {galleryImages}
                        </Carousel>
                    </Modal.Body>
                </Modal> : ""}
            {this.context.pageStatus === LexemeEditingLevel.EDITABLE ?
                <div
                    className={BUtils.joinClasses(stylesLexemeTitle['lexeme-editing-status'], stylesLexemeTitle['lexeme-editing-status--' + editingStatus])}>
                    <OverlayTrigger placement="left" overlay={editingStatusTooltip} delayShow={300}
                                    delayHide={150}><Glyphicon
                        glyph={EditingStatusTypes[editingStatus].glyphicon} /></OverlayTrigger>
                </div> : ""}
            {printPlace}
            {isToponym ?
                <><Modal className={stylesLexemeTitle['modal-title-place']} show={this.state.showPlaceFiles}
                       dialogClassName={stylesLexemeTitle['modal-title-place__dialog-modal']}
                       onHide={() => this.setState({showPlaceFiles: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <strong>{hasPlace ? place.description : 'nisba'}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body bsClass={stylesLexemeTitle['modal-title-place__body']}>
                        <SCFileViewer
                            item={this.state.placeData}
                            itemType={'Place'}
                            editable={false}
                            additionalClass={stylesLexemeTitle['modal-title-place-viewer-additional']}
                        />
                    </Modal.Body>
                </Modal>
                    <Modal className={stylesLexemeTitle['modal-title-place']} show={this.state.showPlaceTree}
                           dialogClassName={stylesLexemeTitle['modal-title-place__dialog-modal']}
                           onHide={() => this.setState({showPlaceTree: false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <strong>{Dict.t('PLACE_TREE')}</strong>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body bsClass={stylesLexemeTitle['modal-title-place-tree__body']}>
                            <BackOfficeTree entity={'Place'}
                                            expandedKey={hasPlace ? place.id : ''}
                                            selectable={false}
                                            frontend={true}
                                            callbackSelect={(item) => {
                                            }}
                            />
                        </Modal.Body>
                    </Modal>
                </> : ""}
        </div>;
    }
}

LexemeTitle.contextType = LexemePageContext;
