exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\r\n.CgrData-module__column-list-cgrs-grid___2ZlnO {\r\n    height: 500px;\r\n}\r\n\r\n.CgrData-module__column-list-cgrs___tftuY {\r\n    height: 500px;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.CgrData-module__column-list-cgr-button___1c8i2 {\r\n    background-color: #dcdcdc;\r\n    border: 2px solid #adadad;\r\n    border-radius: 5px;\r\n    padding: 6px 3px;\r\n    margin-top: 6px;\r\n    /*min-width: 200px;*/\r\n    width: 95%;\r\n    text-align: center;\r\n    font-weight: bold;\r\n    color: #353535;\r\n}\r\n\r\n.CgrData-module__column-list-cgr-button___1c8i2:hover {\r\n    background-color: #e4e4e4;\r\n    border: 2px solid #bcbcbc;\r\n}\r\n\r\n.CgrData-module__column-list-cgr-button--selected___3Ko_y {\r\n    background-color: #bdbdbd;\r\n    border: 2px solid #8c8c8c;\r\n    color: black;\r\n}\r\n\r\n.CgrData-module__column-list-cgr-edit-container___m-jsi {\r\n    margin-left: 5px;\r\n    border: 1px solid #adadad;\r\n    padding-bottom: 5px;\r\n    border-radius: 4px;\r\n}\r\n\r\n.CgrData-module__column-list-cgr-edit-bar___2J3uC {\r\n    border: 2px solid #adadad;\r\n    border-top-left-radius: 2px;\r\n    border-top-right-radius: 2px;\r\n    width: 100%;\r\n    padding: 5px 15px;\r\n    background-color: #dcdcdc;\r\n    margin-right: 0;\r\n    margin-left: 0px;\r\n    margin-bottom: 5px;\r\n    font-weight: bold;\r\n}\r\n\r\n.CgrData-module__column-list-cgr-edit-row___pQ_ga {\r\n    width: 80%;\r\n    margin-left: 25px;\r\n}", ""]);

// Exports
exports.locals = {
	"column-list-cgrs-grid": "CgrData-module__column-list-cgrs-grid___2ZlnO",
	"column-list-cgrs": "CgrData-module__column-list-cgrs___tftuY",
	"column-list-cgr-button": "CgrData-module__column-list-cgr-button___1c8i2",
	"column-list-cgr-button--selected": "CgrData-module__column-list-cgr-button--selected___3Ko_y",
	"column-list-cgr-edit-container": "CgrData-module__column-list-cgr-edit-container___m-jsi",
	"column-list-cgr-edit-bar": "CgrData-module__column-list-cgr-edit-bar___2J3uC",
	"column-list-cgr-edit-row": "CgrData-module__column-list-cgr-edit-row___pQ_ga"
};