exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".rootEntry-module__lexData-simplify-ipa___14UqN {\r\n\r\n}\r\n\r\n.rootEntry-module__lexData-simplify-ipa___14UqN {\r\n    color: #0b77b7;\r\n}\r\n\r\n.rootEntry-module__lexData-hyphenation___1GmcM {\r\n\r\n}\r\n\r\n.rootEntry-module__lexData-etymology___39gdO:before {\r\n    content: \"[\";\r\n}\r\n\r\n.rootEntry-module__lexData-etymology___39gdO {\r\n    color: #0b77b7;\r\n}\r\n\r\n.rootEntry-module__lexData-etymology___39gdO:after {\r\n    content: \"]\";\r\n}\r\n\r\n.rootEntry-module__lexData-notes___1lZka {\r\n    font-style: italic;\r\n    font-size: 10px;\r\n    font-weight: bold;\r\n    border: 1px solid #989898;\r\n    color: #4f4f4f;\r\n    border-radius: 3px;\r\n    padding: 2px 6px 0 6px;\r\n    margin-top: 4px;\r\n    display: inline-flex;\r\n\r\n}\r\n", ""]);

// Exports
exports.locals = {
	"lexData-simplify-ipa": "rootEntry-module__lexData-simplify-ipa___14UqN",
	"lexData-hyphenation": "rootEntry-module__lexData-hyphenation___1GmcM",
	"lexData-etymology": "rootEntry-module__lexData-etymology___39gdO",
	"lexData-notes": "rootEntry-module__lexData-notes___1lZka"
};